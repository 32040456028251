<div class="ss-playlist" [ngClass]="{'no-timestamp': noTime}">
  <div class="ss-playlist--cover">
    <img *ngIf="!playlist.cover.length" src="https://dev-singsnap.imgix.net/placeholders/placeholder_logo.png?h=100" />
    <div *ngFor="let cover of playlist.cover">
      <img [src]="cover + '?h=100'" />
    </div>
    <div *ngFor="let item of getMissingCovers()">
      <img src="https://dev-singsnap.imgix.net/placeholders/placeholder_logo.png?h=100" />
    </div>
  </div>
  <div class="ss-playlist--info">
    <div>
      <div class="info-title ss-truncate">{{playlist.title}}</div>
      <div class="info-songs">{{playlist.stats.recordings}} Recordings</div>
      <div class="info-description" *ngIf="showDescription" [innerHtml]="playlist.description"></div>
    </div>
    <div class="info-date" *ngIf="!noTime">
      <app-time-stamp [time]="playlist.updated_at" [needWatch]="false" [format]="'MMM d, yyyy hh:mma'">
      </app-time-stamp>
    </div>
  </div>
</div>
