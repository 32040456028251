import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    AfterViewInit,
    Output,
    ViewChild, SimpleChanges, OnChanges
} from '@angular/core';
import {Subject} from 'rxjs';
import {SsEditorComponent} from '@app/shared/comments/ss-editor/ss-editor.component';
import {Data} from '@app/core';
import { EmailNotificationsService } from '@app/core/services/email-notifications.service';

@Component({
    selector: 'app-reply-comment',
    templateUrl: './reply-comment.component.html',
    styleUrls: ['./reply-comment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReplyCommentComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @Input('recordingId') recordingId;
    @Input('commentId') commentId;
    @Input('replyDisabled') replyDisabled = false;
    @Input('customClass') customClass = '';
    @Input('editorContent') editorContent: any = '';
    @Input('editorContentEdit') editorContentEdit: any = '';
    @Input('placeholder') placeholder;
    @Input('isEdit') isEdit = false;
    @Input('showPostButton') showPostButton = true;
    @Input('elId') elId = 'mainEditor';
    @Input('state') state = 'close';
    @Input('autofocus') autofocus = false;

    @Output() editorFocus = new EventEmitter<string>();
    @Output() editorBlur = new EventEmitter<string>();
    @Output('onCommentCreate') onCommentCreate: EventEmitter<any> = new EventEmitter();
    @Output('onCommentEdit') onCommentEdit: EventEmitter<any> = new EventEmitter();
    @Output('onCommentReply') onCommentReply: EventEmitter<any> = new EventEmitter();
    //CANCEL COMMENT
   @Output('onCancelCommentEdit') onCancelCommentEdit: EventEmitter<any> = new EventEmitter();
    
    @ViewChild('editor', { static: true }) editor: SsEditorComponent;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    commentPosting = false;
    lastCommentRecordingId = 0;

    constructor(
        private cdr: ChangeDetectorRef, 
        private data: Data,
        private emailNotificationsService: EmailNotificationsService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        if (this.editorContentEdit) {
            console.log('am it trying to set content ?', this.editorContentEdit);
            this.editor.setEditContent(this.editorContentEdit);
        }
    }


    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.cdr.detach();
    }

    onEditorFocused(e) {
        if (this.editorFocus) {
            this.editorFocus.emit(e);
        }
    }

    onEditorBlurred(e) {
        if (this.editorBlur) {
            this.editorBlur.emit(e);
        }
    }

    postComment() { 

        this.data.commentSentCount = this.data.commentSentCount + 1;
        if (this.data.commentSentCount == 1){
        this.data.commentText = this.data.truncateComment(this.editorContent);
        }
        
        if( this.data.commentSentCount > 1 && !this.data.commentText.includes('...')){
            this.data.commentText += ' ...';
        }

        if (this.editorContent.length) {
            this.commentPosting = true;
            if (this.commentId) { // comment reply
                this.onCommentReply.emit({
                    content: this.editorContent,
                    recordingId: this.recordingId,
                    commentId: this.commentId,
                    onComplete: () => {
                        this.editorContent = '';
                        this.commentPosting = false;
                        this.cdr.detectChanges();
                        this.data.commentSent = true;
                    }
                });
            } else { // root comment
                this.onCommentCreate.emit({
                    content: this.editorContent,
                    recordingId: this.recordingId,
                    onComplete: () => {
                        this.editorContent = '';
                        this.commentPosting = false;
                        this.cdr.detectChanges();
                        this.data.commentSent = true;
                    }
                });
            }
        }
    }

    editComment() {
        console.log(this.editorContent);
        if (this.editorContent.length) {
            console.log('edit?', this.commentId);
            this.commentPosting = true;
            this.onCommentEdit.emit({
                content: this.editorContent,
                commentId: this.commentId,
                onComplete: () => {
                    this.commentPosting = false;
                    this.cdr.detectChanges();
                }
            });
        }
    }

    onCommentPostHandle(event) {
        this.editorContent = this.isEmptyComment(event) ? '' : event;
        if (this.isEdit) {
            this.editComment();
        } else {
            this.postComment();
        }

    }

    onCancelCommentPostHandle(event) {
        this.onCancelCommentEdit.emit();
    }
  
    onUploadImageHandle(event) {
        this.commentPosting = !event.complete;
    }

    insertQuote(body) {
        this.editor.quote(body);
    };

    isEmptyComment(commentBody) {
        if (typeof commentBody === 'string') {
            commentBody = commentBody.replace('<p>', '')
                .replace('</p>', '');

            return commentBody.trim().length === 0;
        } else {
            return false;
        }
    }
}
