<div id='seeMoreContainer'>
  <div class='headerContainer'>
    <div class='title'>
      <app-icon-button color="white" (click)="back()" >
      <app-icon-arrow-left color="gray"></app-icon-arrow-left>
      </app-icon-button>
      <h4>Sing - Duets</h4>
    </div>
    <div class='moreFilters'>
      <span>Exclude Demos:
        <input type="checkbox"  #filterByDemoCheckBox [checked]="filterByDemo" (change)="demoClick(filterByDemoCheckBox.checked)"  >
      </span>
      <span>Decade:
        <select  style="width: 4.5rem;" name="decade-dropdown" id="decade-dropdown" (change)="decadeClick($event.target.value)"> 
        <option *ngFor="let item of decadesArray;let e = index" [selected]= "e==selectedDecadeIndex" [value]="e">{{item}}</option>
        </select>
      </span>
      <span *ngIf='genresArray.length != 1'>Genre:
        <select  name="genre-dropdown" id="genre-dropdown" (change)="genreClick( $event.target.value)">
        <option *ngFor="let item of genresArray;let e = index" [selected]= "e==selectedGenreIndex" [value]="e">{{item.name}}</option>
        </select>
      </span>
    </div>
  </div>
  <ul class='letterList' >
    <li *ngFor='let letter of letters'>
      <button *ngIf='selectedLetter == letter' class='letterButton selectedLetter' (click)='handleFilter(letter)'>{{letter}}</button>
      <button *ngIf='selectedLetter != letter' class='letterButton' (click)='handleFilter(letter)'>{{letter}}</button>
    </li>
  </ul>

  <app-ss-featured-grid 
  [scrollable]="false"
  title="Sing - Duets"
  seeMorePage="SingDuets"
  [hasSeeMore] = "false"
  [cols]="3"
  [colsLg]="3"
  [colsMd]="2"
  [colsSm]="1"
  [extendedSize] = "true"
  [checkOwnRecording]="true"
  [userId]="profileService.userId"
  [sortOptions]="songSortOptions"
  [list]="duetsData.items"
  [loading]="duetsData.loading"
  [freeSongFilter]="false"
  (freeSongChanged)="handleFreeSongsChange(duetsData, $event)"
  (onOrderBy)="resetDataObj(duetsData);setSortFields($event);onScrollEndHandle()"
  >
  <ng-template #itemContent let-item>
    <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
      <a (click)="handleSing(item)">
        <app-ss-song [linkActive]="false" [song]="item"></app-ss-song>
      </a>
      <div class="song-actions">
        <app-icon-button style="margin-right: -0.2rem" (click)="handleSing(item)" [shadow]="true" role="button" size="40px" color="#58c7c9" title="Record">
          <app-icon-mic color="white" size="16"></app-icon-mic>
        </app-icon-button>
        <app-more-dot-menu>
          <div class="dropdown-menu-content">
            <div (click)="favoriteSong(item)" *ngIf="!item.favorited">Add to Favorites</div>
            <div (click)="unfavoriteSong(item)" *ngIf="item.favorited">Remove from Favorites</div>
            <div (click)="onArtist(item.artist)">View Artist Profile</div>
            <div (click)="howOthersSingThis(item.id)">How Others Sing This</div>
          </div>
        </app-more-dot-menu>
      </div>
    </div>
    <!-- <app-recording-stat [recording]="item"></app-recording-stat> -->
  </ng-template>
  </app-ss-featured-grid>
  <app-vert-space height="40"></app-vert-space>

  <div  id="pageNavContainer"  style="margin-bottom: 13px;">
    <button *ngIf='currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
    <p >{{currentPage}}</p>
    <button *ngIf='duetsData.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
  </div>


  </div>