import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  NgZone,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {
  GenresService,
  UsersService,
  RecordingService,
  UtilService,
  ProfileService,
  EventService,
  HelperService,
} from '@app/core';

@Component({
  selector: 'app-listen-inner-circles',
  templateUrl: './listen-inner-circles.component.html',
  styleUrls: ['./listen-inner-circles.component.scss']
})
export class ListenInnerCirclesComponent implements OnInit, OnDestroy {
  @ViewChild('playlist') private playlistElem;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  recording;
  trendingRecordings$: any;
  recommendedRecordings$;
  trendingRecordings = [];
  recordingSortOptions = [];
  genreSortOptions = this.util.recordingSortOptions;
  @ViewChild('goldcontent') private goldModal;
  currentPage = 1;
  itemsPerPage = 30;

  byICData: any = {
    ...this.util.generateGridData(),
    perPage: this.itemsPerPage,
    fetch: this.recordingService.getRecordingsFromIC.bind(
      this.recordingService
    ),
  };

   constructor(
    private recordingService: RecordingService,
    private genreService: GenresService,
    public profileService: ProfileService,
    private usersService: UsersService,
    private helperService: HelperService,
    private eventService: EventService,
    private toastService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private util: UtilService,
    private ngZone: NgZone,
    private location: Location
  ) {
    this.recordingSortOptions = this.util.recordingSortOptions;
    this.byICData.sort = this.util.recordingSortOptions[0];
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if(typeof params.page != 'undefined') {
        this.currentPage = params.page
      }
    });
    this.onScrollEndHandle(this.byICData, null);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkMine(item){
    return item.favorited;
  }

  back(){
    window.history.back();
  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  navListen(id){
    this.navigate([`/d/listen/${id}`]);
  }

  navEditRec(id){
    this.navigate([`/d/my-recordings/${id}`]);
  }

  navProfile(id){
    this.navigate([`/d/profile/${id}`]);
  }

  handleDuet(duet) {
    console.log(duet);
    if (duet.song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    this.router.navigate(['/duet-recorder', duet.id]);
  }

  favRecording(recording) {
    this.recordingService.fav(recording.id).subscribe(response => {
      if (response.success) {
        recording.favorited = true;
        this.toastService.success('Recording was added to favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  unFavRecording(recording) {
    this.recordingService.unfav(recording.id).subscribe(response => {
      if (response.success) {
        recording.favorited = false;
        this.toastService.success('Recording was removed from favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  setPageParam() {
    this.location.replaceState(this.location.path().split('?')[0], '');
    this.location.replaceState(this.location.path() + `?page=${this.currentPage}`);
  }  

  onScrollEndHandle(data, sort) {
    if (!data.fetch) {
      return;
    }
    if (sort) {
      data.sort = sort;
    }

    if (!data.loading && data.lastPage) {
      data.loading = true;
      // data.currentPage++;
      this.detectChanges();
      data.fetch(this.currentPage, this.itemsPerPage, data.sort.field).subscribe(
        response => {
          data.currentPage =  this.currentPage;
          data.items = data.items.concat(response.data);
          data.lastPage = response.next_page_url;
          data.loading = false;
          this.detectChanges();
        },
        error => {
          this.toastService.error(error.message);
        }
      );
    }
  }

  getNextSongs() {
    let data = this.byICData;
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }

    if (!data.loading && data.lastPage) {
      data.loading = true;
      data.currentPage++;
      console.log(data.currentPage)
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field)
        .subscribe(
          response => {
            this.currentPage++
            data.items = response.data;
            this.setPageParam();
            data.lastPage = response.next_page_url;
            data.loading = false;
            window.scrollTo(0, 0);
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  getPrevSongs() {
    let data = this.byICData;
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }

    if (!data.loading && data.currentPage != 1) {
      data.loading = true;
      data.currentPage--;
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field)
        .subscribe(
          response => {
            this.currentPage--
            data.items = response.data;
            this.setPageParam()
            data.lastPage = response.next_page_url;
            data.loading = false;
            window.scrollTo(0, 0);
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  resetDataObj(data) {
    data.currentPage = 0;
    data.loading = false;
    data.items = [];
    data.lastPage = 1;
    return data;
  }

  recordingToLegacy(recording) {
    this.recordingService.appearOnLegacy(recording.id).subscribe(
      response => {
        this.toastService.success('Recording will appear on legacy');
      },
      () => {
        this.toastService.success("There's an error, please try again later.");
      }
    );
  }

  onAddtoPlaylist(id) {
    this.playlistElem.open(id);
  }

  async subscribeUser(recording) {
    const recordingList = [
      this.byICData.items,
    ];
    this.helperService.subscribeUser(recording, recordingList);
  }

  async unsubscribeUser(recording) {
    const recordingList = [
      this.byICData.items,
    ];
    this.helperService.unsubscribeUser(recording, recordingList);
  }

  shareRecording(recording) {
    this.eventService.onSocialShare({ recording });
  }

  howOthersSingThis(id){
    this.navigate([`/d/ListenOthersSing/${id}`]);
  }
}
