import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {SharedModule} from '../shared';
import { NotificationsComponent } from './notifications.component';


@NgModule({
    imports: [
        NgbModule,
        NgbModule,
        CommonModule,
        SharedModule,
        RouterModule
    ],
    declarations: [
        NotificationsComponent,
    ]
})
export class NotificationsModule {
}
