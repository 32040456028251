<div class="comment comment--depth-{{depth}}"
     [class.comment--is-editing]="isEditing()"
     [class.comment--gold]="comment?.user?.gold"
     [class.comment__reply]="isReply"
     [class.comment--last]="isLast"
     [class.comment--expanded]="repliesOpen"
     [class.comment--own]="isOwnComment"
     [class.comment-submission]="comment?.recording">
  <div class="comment-content">
    <div class="comment-header">
      <div class="comment__user">
        <app-ss-user [user]="comment.user"  title="{{comment.user.id}}"></app-ss-user>
       
       
       
        <div class="user-bio-comment" title="{{comment.user}}">
         
       </div>
   
     
        <app-tags class="contest-entry-tag" tag="Contest Entry" [pill]="true" *ngIf="comment?.recording"></app-tags>
      </div>
      <div class='top-right'>
        <div class="comment-time">
          <app-time-stamp [needWatch]="true"
                          [time]="comment.created_at"
                          [format]="'MMM d, yyyy hh:mma'"
                          
                          
                          >
          </app-time-stamp>
        </div>
        <div class='trash-report-container'>
          <app-icon-trash *ngIf="comment.can_delete" class="comment-trash" size="12" color="#F94678" (click)="deleteComment(comment)" ></app-icon-trash>
          <!-- <span *ngIf="comment.user.id != loggedUserId" class="fa fa-exclamation-circle report-link" title = "Report {{ownerUser}}"
          (click)="report()"></span> -->
    
          <span *ngIf="comment.user.id != loggedUserId" class="fa fa-exclamation-circle report-link" title = "Report {{ownerUser}}"
                (click)="report()"></span> <!-- Exclamation REPORT button   -->
        </div>
      </div>
    </div>
    <div class="comment-body ql-editor-styles" [innerHTML]="comment.body | safeHtml"></div>
    <div class="video-submission" *ngIf="comment?.recording">
      <video poster="{{comment?.recording?.cover}}" controls controlsList="nodownload">
        <source src="{{comment?.recording?.uri}}" type="video/mp4">
      </video>
    </div> 
    <app-reply-comment
      *ngIf="comment?.can_edit && isEditing()"
      [recordingId]="parentId"
      [commentId]="commentId"
      [editorContent]="comment.body"
      [editorContentEdit]="comment.body"
      [isEdit]="true"
      (onCommentEdit)="onCommentEditHandle($event)"
      (onCancelCommentEdit)="onCancelCommentEditHandle($event)"
      [customClass]="'reply-comment--edit'">
    </app-reply-comment>
    <div class="comment-footer">
      <div class="comment-actions">
            <span class="comment-action">
                <app-love-button
                  [replyUser]="comment?.user"
                  [enabled]="!(favoriting || isOwnComment)"
                  [counter]="comment?.stats.love"
                  [loved]="comment?.loved"
                  [type]="commentType"
                  [id]="commentId"></app-love-button>
            </span>
        <span class="comment-action">
                <app-reaction #listenCommentReaction
                              [reactionList]="reactionList"
                              [reactions]="reactions"
                              [placement]="placement">
                </app-reaction>
            </span>
        <span class="comment-action">
                <!-- <span class="comment-footer-label"
                      (click)="reply()" *ngIf="type !== 'comments'">REPLY</span> -->
                    <span class="comment-footer-label"
                          (click)="quote()" *ngIf="type !== 'comments'">QUOTE</span>
                <span class="comment-footer-label" *ngIf="type === 'comments' && depth < 5"
                      (click)="toggleReplyBox()">{{getReplyText()}}</span>
                <span class="comment-footer-label" *ngIf="comment?.can_edit" (click)="toggleEditBox()">
                    <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink">
                        <defs>
                            <path
                              d="M3,17.25 L3,21 L6.75,21 L17.81,9.94 L14.06,6.19 L3,17.25 L3,17.25 Z M20.71,7.04 C21.1,6.65 21.1,6.02 20.71,5.63 L18.37,3.29 C17.98,2.9 17.35,2.9 16.96,3.29 L15.13,5.12 L18.88,8.87 L20.71,7.04 L20.71,7.04 Z"
                              id="path-edit"></path>
                        </defs>
                        <g id="ic_create" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g>
                                <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                                <use id="edit-mask" fill="#000000" xlink:href="#path-edit"></use>
                            </g>
                        </g>
                    </svg>
                    &nbsp;{{getEditText()}}</span>
            </span>
        <div class="comment-action" *ngIf="type === 'comments' && comment?.stats?.replies > 0 && depth < 5">
                    <span class="comment-footer-label" *ngIf="!repliesOpen">
                        <a (click)="loadRepliesOnToggle()">
                            {{comment?.stats?.replies}} replies <i class="fa fa-chevron-down"></i>
                        </a>
                    </span>
          <span class="comment-footer-label" *ngIf="repliesOpen">
                        <a (click)="repliesOpen = false">
                            {{comment?.stats?.replies}} replies <i class="fa fa-chevron-up"></i>
                        </a>
                    </span>
        </div>
      </div>
    </div>
  </div>
  <hr *ngIf="isReply">

  <app-reply-comment
    *ngIf="isReplying()"
    [recordingId]="parentId"
    [commentId]="commentId"
    [autofocus]="true"
    state="open"
    (onCommentReply)="onCommentReplyHandle($event)">
  </app-reply-comment>
</div>
<!-- replies comments -->
<div class="comment__replies" *ngIf="type === 'comments' && comment?.replies?.length > 0"
     [class.d-none]="!(repliesOpen && depth <= 5)">
  <app-comment *ngFor="let commentReply of comment.replies;trackBy: trackByName; let last = last;"
               [comment]="commentReply"
               [type]="type"
               [userId]="userId" [isReply]="true" [depth]="depth + 1" [isLast]="last">
  </app-comment>
  <div class="comment__replies-loadmore" *ngIf="comment?.stats?.replies - comment?.replies?.length > 0">
    <a (click)="loadMoreReplies()">{{comment?.stats?.replies - comment?.replies?.length}} more replies</a>
  </div>
</div>
<!-- end replies comments -->
