import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor,HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import {ActivatedRoute, ActivatedRouteSnapshot, RouterState, RouterStateSnapshot, Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class GlobalHttpInterceptorService implements HttpInterceptor{
   toGoToURL: string;
  constructor(    
    public router: Router, 
    private route: ActivatedRoute,
  
    ){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
        catchError((error) => {
          if (error.error.reason === 'UNAUTHENTICATED'){
            this.toGoToURL =  this.router.url ; 
            if ( this.toGoToURL.includes('/login') || this.toGoToURL === '/' || this.toGoToURL.includes('/home')){
            } else  {
              window.location.href = '/#/login?url=' + this.router.url;   
            }
          }
          return throwError(error);
        })
      )
    }
}

