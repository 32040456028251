import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UtilService } from './util.service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GenresService extends HttpService {

    constructor(
        private http: HttpClient,
        private util: UtilService
    ) {
        super();
    }

    addPicsToGenres(data, hcPic) {
        const newData = data.data;
        let current;
        let item = newData.length;
        let hcData;
        while (item) {
            item--;
            current = newData[item];
            hcData = this.getGenresPhoto(current.name, hcPic);
            current.photo = hcData.photo;
            current.icon = hcData.icon;
        }
        return newData;
    }

    getGenresPhoto(genre, hcPic) {
        let photo = '';
        let icon = '';
        switch (genre) {
            case 'Alternative/Folk/Punk':
                photo = hcPic.alternative.img;
                icon = hcPic.alternative.icon;
                break;
            case 'Pop':
                photo = hcPic.pop.img;
                icon = hcPic.pop.icon;
                break;
            case 'Country':
                photo = hcPic.country.img;
                icon = hcPic.country.icon;
                break;
            case 'Dance/Electronic/Club':
                photo = hcPic.dance.img;
                icon = hcPic.dance.icon;
                break;
            case 'Film/Stage/TV':
                photo = hcPic.film.img;
                icon = hcPic.film.icon;
                break;
            default:
                break;
        }
        return { photo: photo, icon: icon };
    }
  //hard coded genres 22 per page 
    getGenres(page = 1, perPage = 10, sort = 'popularity') {
        const url = this.makeUrl(`/genres/?page=${page}&per_page=22&sorters={"${sort}": "DESC"}`);
        const options = this.getHeaders();
        return this.http.get(url, options)
            .pipe(map((res) => JSON.parse(JSON.stringify(res))));
}

    getSongs(id, page = 1, perPage = 10, sort = 'created_at', asc = true, query = null, freeSongs = false) {
        const params = this.util.buildParamsAdvanced(page, perPage, sort, asc, query, 'title', freeSongs);
        const url = this.makeUrl(`/genres/${id}/songs`);
        const options = this.getHeaders();
        options['params'] = params;
        return this.http.get(url, options)
            .pipe(map((res) => JSON.parse(JSON.stringify(res))));
    }

    getSongsWithLetter(id, page = 1, perPage = 10, sort = 'created_at', asc = true, query = null, freeSongs = false) {
        const params = this.util.buildParamsAdvanced(page, perPage, sort, asc, query, 'title', freeSongs);
        const url = this.makeUrl(`/genres/${id}/songs`);
        const options = this.getHeaders();
        options['params'] = params;
       
        if (query) {
            params.q = `{"title":"${query}"}`;
          }
       
        return this.http.get(url, options)
            .pipe(map((res) => JSON.parse(JSON.stringify(res))));
    }

    getSongsWithLetterFilter(id, page = 1, perPage = 10, sort = 'created_at', asc = true, query = null, freeSongs = false, decade) {
        const params = this.util.buildParamsAdvanced(page, perPage, sort, asc, query, 'title', freeSongs);
        //const url = this.makeUrl(`/genres/${id}/songs`);
       let url;
        if(decade != '') {
            url = this.makeUrl('/genres/${id}/songs?decade=' + decade);
          } else {
            url = this.makeUrl(`/genres/${id}/songs`);
          }

        const options = this.getHeaders();
        options['params'] = params;
       
        if(typeof query == 'object') {
            if(Object.keys(query).length != 0) {
              params.q = '{'
              Object.keys(query).forEach((key) => {
                let value = query[key]
                params.q += `"${key}":"${value}",`
              })
              params.q = params.q.substring(0, params.q.length - 1) + '}'
              console.log(params.q)
            }
          }
       
        return this.http.get(url, options)
            .pipe(map((res) => JSON.parse(JSON.stringify(res))));
    }






    getRecordings(id, page = 1, perPage = 10, sort = 'created_at', asc = false, query = null, letter = '') {
        const params = this.util.buildParams(page, perPage, sort, asc , query, 'title');
        const url = this.makeUrl(`/genres/${id}/recordings`);
        const options = this.getHeaders();
        options['params'] = params;
            if(letter.length){
              params.q = `{"title":"${letter}"}`;
            }
        return this.http.get(url, options)
            .pipe(map((res) => JSON.parse(JSON.stringify(res))));
    }
}
