var __defProp = Object.defineProperty;
var __publicField = (obj, key, value) => {
  if (typeof key !== "symbol")
    key += "";
  if (key in obj)
    return __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value});
  return obj[key] = value;
};
export default class Song {
  constructor(data) {
    __publicField(this, "duet", false);
    __publicField(this, "fillable", ["id", "title", "duet", "uri"]);
    this.jam = data.jam;
    if (this.jam) {
      this.recordingData = data.recordingData;
      this.fillable.forEach((f) => this[f] = data.song[f]);
      this.lyrics = data.recordingData.lyrics.lyrics;
      this.isCS = !data.recordingData.word_timings;
      if (data.recordingData.duet && data.recordingData.parts) {
        this.duet = true;
        this.parts = data.recordingData.parts;
      }
      this.layer = data.song.users.length + 1;
    } else {
      this.fillable.forEach((f) => this[f] = data[f]);
      this.lyrics = data.lyrics.lyrics;
      this.isCS = !data.word_timings;
      if (data.duet && data.parts) {
        this.duet = true;
        this.parts = data.parts;
      }
    }
  }
  isDuet() {
    this.duet;
  }
  isJam() {
    this.jam;
  }
  getSelectableParts() {
    Object.keys(this.parts).filter((p) => this.parts[p]);
  }
  getTogetherParts() {
    Object.keys(this.parts).filter((p) => !this.parts[p]);
  }
}
