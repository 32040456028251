<div class="container-fluid slideshow-container">
    <div class="row">
        <div class="col-lg-4 col-md-4" [@routeAnimation]="">
            <span class="slideshow-headline">Shop</span>
            <span class="slideshow-blank"></span>
            <span class="slideshow-category" *ngFor="let category of categories" [routerLink]="['../category', category.id]">{{category.name}}</span>
        </div>
        <div class="col-lg-8 col-md-8" [@routeAnimation]="">
            <div class="slideshow-image">
                <img src="../../../../assets/pictures/shop/slider-image.jpg" alt="slider-image" width="80%">
            </div>
        </div>
    </div>
</div>

<div class="container-fluid main-container">
    <div class="row main-category" *ngIf="categories.length > 0" [@rollIn]="shopAnimations.secondBlock.currentState">

        <div class="col-lg-4 col-md-4 category-content home-category-one first-category cl_animate" [routerLink]="['../category', 17]" >
            <div class="image"></div>
            <div class="text">
                <span class="title">Patch</span><br>
                <span class="link">Discovery now <i class="fa fa-angle-right" aria-hidden="true"></i></span>
            </div>
        </div>

        <div class="col-lg-4 col-md-4 category-content">
            <div class="col-lg-12 home-category-two second-category cl_animate" [routerLink]="['../category', 16]">
                <div class="image"></div>
                <div class="text">
                    <span class="title">Caps</span><br>
                    <span class="link">Discovery now <i class="fa fa-angle-right" aria-hidden="true"></i></span>
                </div>
            </div>
            <div class="col-lg-12 category-content home-category-two third-category cl_animate" [routerLink]="['../category', 18]">
                <div class="image"></div>
                <div class="text">
                    <span class="title">Pins</span><br>
                    <span class="link">Discovery now <i class="fa fa-angle-right" aria-hidden="true"></i></span>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-4 category-content home-category-one fourth-category cl_animate" [routerLink]="['../category', 15]">
            <div class="image"></div>
            <div class="text">
                <span class="title">T-shirt</span><br>
                <span class="link">Discovery now <i class="fa fa-angle-right" aria-hidden="true"></i></span>
            </div>
        </div>
    </div>

    <span class="popular-articles"><h3 class="text-center">Popular</h3></span>

    <div class="row articles-popular" *ngIf="popular.length > 0" [@rollIn]="shopAnimations.thirdBlock.currentState">
        <div class="col-lg-3 col-md-3 popular-category cl_animate" *ngFor="let pop of popular" [routerLink]="['../product', pop.id]" >
            <div class="popural-image"><img src="{{pop.images[0].src}}" height="70%" alt=""></div>
            <div class="popural-name">{{pop.name}}</div>
            <div class="popural-price">${{pop.price}}</div>
        </div>
    </div>

    <div class="row sales-articles">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 latest cl_animate" *ngIf="latest.length > 0" [routerLink]="['../product', latest[0].id]" [@flipX]="shopAnimations.forthBlock.currentState">
            <div class="latest-product-left">
                <div class="latest-product-name">{{latest[0].name}}</div>
                <div class="latest-product-price">${{latest[0].price}}</div>
                <div class="latest-product-shop">shop now</div>
                <div class="latest-image"><img src="{{latest[0].images[0].src}}" height="100%" alt=""></div>
            </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 latest cl_animate" *ngIf="latest.length > 1" [routerLink]="['../product', latest[1].id]" [@flipXReverse]="shopAnimations.forthBlock.currentState">
            <div class="latest-product-right">
                <div class="latest-product-name">{{latest[1].name}}</div>
                <div class="latest-product-price">${{latest[1].price}}</div>
                <div class="latest-product-shop">shop now</div>
                <div class="latest-image"><img src="{{latest[1].images[0].src}}" height="100%" alt=""></div>
            </div>
        </div>
    </div>

</div>
