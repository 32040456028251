<div class='desktop'>
    <app-top-message [system]='true'></app-top-message>
    <div id='loggedInAs' *ngIf='loggedInAs != ""'>Logged in as: {{loggedInAs}}</div>
    <nav class="menu-top menu-top-desktop navbar navbar-expand d-flex" (blur)="onBlurDropdownMenu()">
        <div [routerLinkActive]="'active'" (click)='setSearchStatus(false)' [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/d/home']" class="navbar-brand">
            <img src="/assets/pictures/sing-snap-logo.png" class="img-fluid" alt="Sing Snap Logo">
        </div>
        <div class="" id="menu-top-primary" [ngClass]="{'d-none': searchActive}">
            <div class="navbar-nav mr-auto">
                <a class="nav-item nav-links"  [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/d/home']">
                    <a class="nav-link top-links">Home <span class="sr-only">(current)</span></a>
                </a>
                <a class="nav-item nav-links" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: false}" [routerLink]="['/d/listen']">
                    <a class="nav-link top-links">Listen</a>
                </a>
                <a class="nav-item nav-links" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: false}" [routerLink]="['/d/sing']">
                    <a class="nav-link top-links">Sing</a>
                </a>
                <a class="nav-item nav-links" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: false}" [routerLink]="['/d/songbook']">
                    <a class="nav-link top-links">Upload</a>
                </a>
                <a class="nav-item nav-links" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: false}" [routerLink]="['/d/message-board/categories']">
                    <a class="nav-link top-links">Message Board</a>
                </a>
                <!-- <a class="nav-item nav-links"  [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: false}" [routerLink]="['/d/contests']">
                    <a class="nav-link top-links">Contests</a>
                </a> -->
                <a class="nav-item nav-links" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: false}" [routerLink]="['/d/payments/'+storeLandingPage]">
                    <a class="nav-link top-links">SingSnap Store</a>
                </a>
                <!-- <div class="nav-item">
                    <a class="nav-link">Help</a>
                </div> -->
                <!-- <a class="nav-item nav-links" [routerLink]="['/d/chat']">
                    <a class="nav-link top-links" >Chat</a>
                </a> -->

                <a class="nav-item nav-links" [routerLink]="['/d/help']">
                    <a class="nav-link top-links" >Help</a>
                </a>
    
                <!-- <div class="nav-item nav-links" >
                    <a class="nav-link top-links" href="/help"  >Help</a>
    
                </div> -->
            </div>
        </div>
        <div class="top-search" [ngClass]="{'d-block': searchActive, 'd-none': !searchActive}">
            <input type="text" #searchInput class="ss-input" placeholder="Search..." (keydown)="handleSearchKeydown($event)" (keyup)="searchkeyUp.next($event)">
            <div class="search-options">
    
                <div class="search-clear" (click)="handleClearSearch()">Clear</div>
                <span (click)="changeSearchType('sing')" [ngClass]="{'selected': searchType === 'sing'}">SING</span>
                <span (click)="changeSearchType('listen')" [ngClass]="{'selected': searchType === 'listen'}">LISTEN</span>
                <span (click)="changeSearchType('tags')" [ngClass]="{'selected': searchType === 'tags'}">TAGS</span>
            </div>
            <!-- <i class="fa fa-search" (click)="setSearchStatus(false)"></i> -->
            <app-icon-close id='cancelSearch' (click)="setSearchStatus(false)"></app-icon-close>
        </div>
        <div id="menu-top-profile">
            <div class="navbar-nav align-items-center">
                <!-- <div
                    [class.nightmode-switch-off]="!(nightmode$ | async)"
                    [ngClass]="{'d-none': searchActive}"
                    class="nav-item nav-item-search nightmode-switch"
                    (click)="toggleNightMode()"
                >
                    <i class="fa fa-moon-o" aria-hidden="true"></i>
                </div> -->
                <div class="top-search" style="width: 203px;" [ngClass]="{'d-none': searchActive}" (click)='setSearchStatus(true)'>
                    <input type="text" class="ss-input" placeholder="Search...">
                    <!-- <i class="fa fa-search" (click)="setSearchStatus(false)"></i> -->
                    <i class="fa fa-search"  id='cancelSearch' style='top: 25px;' title="Search"></i>
    
                </div>
                <div class="nav-item" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: false}">
                    <a class="nav-link" [routerLink]="['/d/private-message/inbox/all']">
                        <app-icon-notification *ngIf='!youveGotMail'  color='white' size="18" title="Messages"></app-icon-notification>
                        <app-icon-notification (click)="turnOffMailIcon()" *ngIf='youveGotMail' color='white' size="18" notify='true' title="Messages"></app-icon-notification>
                    </a>
                </div>
                <div class="nav-item" >
                    <span class="cl_bell"  style="color: white;" title="Notifications">
                        <app-notifications-dropdown *ngIf='!notify'></app-notifications-dropdown>
                        <app-notifications-dropdown (click)="turnOffNotifyIcon()" *ngIf='notify' notify='true'></app-notifications-dropdown>
                    </span>
                </div>
                <div class="nav-item" style="color: white;" *ngIf="user"  >
                    <app-profile-dropdown></app-profile-dropdown>
                </div>
            </div>
        </div>
    </nav>
    </div>
    <nav class="menu-top menu-top-mobile navbar navbar-dark navbar-expand-xl d-none" (blur)="onBlurDropdownMenu()">
        <app-pm-header *ngIf="isPM"></app-pm-header>
        <div class="menu-top-mobile-left d-flex">
            <a class="navbar-brand" [routerLink]="['/d/home']" [ngClass]="{'hide-on-pm': isPM}">
                <img src="/assets/pictures/sing-snap-logo.png" class="img-fluid" alt="Sing Snap Logo">
            </a>
    
            <div class="" id="menu-top-primary-mobile" [ngbCollapse]="isNavbarCollapsed">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}">
                        <a class="nav-link" [routerLink]="['/d/home']">Home <span class="sr-only">(current)</span></a>
                    </li>
                    <li class="nav-item" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: false}">
                        <a class="nav-link" [routerLink]="['/d/listen']">Listen</a>
                    </li>
                    <li class="nav-item" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: false}">
                        <a class="nav-link" [routerLink]="['/d/sing']">Sing</a>
                    </li>
                    <li class="nav-item" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: false}">
                        <a class="nav-link" [routerLink]="['/d/message-board/categories']">Message Board</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Chat</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/d/help']">Help</a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link"  href="/help">Help</a>
                    </li> -->
                </ul>
            </div>
            <div id="menu-top-profile" [ngClass]="{'hide-on-pm': isPM}">
                <ul class="navbar-nav align-items-center">
                    <li class="nav-item nav-item-search" [ngClass]="{'d-none': searchActive}" (click)="setSearchStatus(true)">
                        <i class="fa fa-search"></i>
                    </li>
                    <li class="" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: false}">
                        <a class="nav-link" [routerLink]="['/d/private-message/inbox/all']">
                            <app-icon-notification color="#90919D" size="18"></app-icon-notification>
                        </a>
                    </li>
                    <li class="nav-item">
                        <div class="cl_bell">
                            <app-notifications-dropdown></app-notifications-dropdown>
                        </div>
                    </li>
                    <!-- <li class="nav-item dropdown" id="dropdown-profile" ngbDropdown *ngIf="user">
                        <app-profile-dropdown></app-profile-dropdown>
                    </li> -->
                </ul>
            </div>
        </div>
        <button class="navbar-toggler"
                type="button"
                (click)="isNavbarCollapsed = !isNavbarCollapsed"
                data-toggle="collapse"
                data-target="#menu-top-primary-mobile"
                aria-controls="menu-top-primary-mobile" aria-expanded="false" aria-label="Toggle navigation"
                style="align-self: center;">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" width="18"><defs _ngcontent-c12=""><path _ngcontent-c12="" d="M0,2 C0,1.44771525 0.444946289,1 0.993397683,1 L17.0066023,1 C17.5552407,1 18,1.44386482 18,2 C18,2.55228475 17.5550537,3 17.0066023,3 L0.993397683,3 C0.444759292,3 0,2.55613518 0,2 Z M0,9 C0,8.44771525 0.444946289,8 0.993397683,8 L17.0066023,8 C17.5552407,8 18,8.44386482 18,9 C18,9.55228475 17.5550537,10 17.0066023,10 L0.993397683,10 C0.444759292,10 0,9.55613518 0,9 Z M0,16 C0,15.4477153 0.446311399,15 0.997544646,15 L8.00245535,15 C8.55338405,15 9,15.4438648 9,16 C9,16.5522847 8.5536886,17 8.00245535,17 L0.997544646,17 C0.446615951,17 0,16.5561352 0,16 Z" id="path-mobile-menu"></path></defs><g _ngcontent-c12="" fill="none" fill-rule="evenodd" id="Icons/Menu" stroke="none" stroke-width="1"><mask _ngcontent-c12="" fill="white"><use _ngcontent-c12="" xlink:href="#path-mobile-menu"></use></mask><use _ngcontent-c12="" xlink:href="#path-mobile-menu" id="Combined-Shape" fill="white"></use></g></svg>
        </button>
    </nav>
    <div *ngIf="searchActive && showSearchOverlay" class="search-overlay">
        <!-- <div class="search-close" (click)="handleClose()">
            <app-icon-close></app-icon-close>
        </div> -->
        <h4 *ngIf="!showTagResult">Results for <span> {{searchTerm}}</span></h4>
        <h4 *ngIf="showTagResult" class="search-overlay-title">
            <app-icon-button size="40px" color="white" border="#909090" (click)="showTagResult=false">
                <app-icon-arrow-left></app-icon-arrow-left>
            </app-icon-button>
            Tag <span> #{{currentTag}}</span>
        </h4>
        <div *ngIf="searchType === 'sing'">
            <div   class="serachFilterHeader"> 
                <span>Decade:</span>
                    <div><select  [(ngModel)]="resetDecade" class="filterDropdowns" id="decade-dropdown" (change)="decadeClick($event.target.value)"> <!--  (change)="selectChangeHandler($event)"> -->
                    <option *ngFor="let item of decadesArray;let e = index" [selected]="e==0" [value]="e">{{item}}</option>
                    </select></div>
                <span>Genre:</span>
                    <div>
                        <select [(ngModel)]="resetGenre" class="filterDropdowns"  (change)="genreClick($event.target.value)">
                            <option *ngFor="let item of genreArray; let e = index" [value]="e">{{item}}</option>
                        </select></div>
                    <!-- Sort by:
                        <select [(ngModel)]="resetSort" class="filterDropdowns"  (change)="sortbyDirectionClick($event.target.value)">
                            <option  value="ASC" >Title A-Z</option>
                            <option  value="DESC">Title Z-A</option>
                        </select> -->
                    Duet:     
                        <input type="checkbox" [(ngModel)]="searchDuet" class="filterByDuetCheckBox" #filterByDuetCheckBox [checked]="filterByDuet" (change)="duetClick(filterByDuetCheckBox.checked)"  >
 
                        <button class="resetBtn" (click)="clearFilters()">Clear Filters</button>
            </div>

            <div class="row">
                <div class="col-12 col-md-4" >
                    <h5>Songs</h5>
                    <app-ss-featured-grid
                        [list]="songsData.items"
                        [loading]="songsData.loading"
                        [cols]="1"
                        [showHeader]="false"
                        [hasSeeMore]="false"
                        (onScrollEnd)="onSongsScrollEndHandle($event)"
                     >
                        <ng-template #itemContent let-item>
                            <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
                                <div class="song-actions-clickable" (click)="onSing(item)">
                                    <app-ss-song [song]="item"></app-ss-song>
                                    <app-icon-button size="40px" color="#58c7c9">
                                        <app-icon-mic color="white"></app-icon-mic>
                                    </app-icon-button>
                                </div>
                                <div class="song-actions">
                                    <app-more-dot-menu title="Options">
                                        <div class="dropdown-menu-content">
                                            <div (click)="favoriteSong(item)" *ngIf="!item.favorited">Add to Favorites</div>
                                            <div (click)="unfavoriteSong(item)" *ngIf="item.favorited">Remove from Favorites</div>
                                            <div (click)="howOthersSingThis(item.id)">How Others Sing This</div>
                                        </div>
                                    </app-more-dot-menu>
                                </div>
                            </div>
                        </ng-template>
                    </app-ss-featured-grid>
                </div>
                <div class="col-12 col-md-4">
                    <h5>Artists</h5>
                     <!-- added hasSeeMore for main search pages TONY -->
                    <app-ss-featured-grid
                        [list]="artistsData.items"
                        [loading]="artistsData.loading"
                        [cols]="1"
                        [showHeader]="false"
                        [hasSeeMore]="false"
                        (onScrollEnd)="onArtistsScrollEndHandle($event)"
                    >
                        <ng-template #itemContent let-item>
                            <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
                                <div class="song-actions-clickable" (click)="onArtist(item)">
                                    <app-ss-artist [artist]="item"></app-ss-artist>
                                    <app-icon-button size="40px" color="white" border="#909090">
                                        <app-icon-arrow-right></app-icon-arrow-right>
                                    </app-icon-button>
                                </div>
                                <div class="song-actions">
                                    <app-more-dot-menu title="Options">
                                        <div class="dropdown-menu-content">
                                            <div (click)="helper.favoriteArtist(item)" *ngIf="!item.favorited">Add to Favorites</div>
                                            <div (click)="helper.unfavoriteArtist(item)" *ngIf="item.favorited">Remove from Favorites</div>
                                        </div>
                                    </app-more-dot-menu>
                                </div>
                            </div>
                        </ng-template>
                    </app-ss-featured-grid>
                </div>
                <div class="col-12 col-md-4">
                    <h5>Members</h5>
                     <!-- added hasSeeMore for main search pages TONY -->
                    <app-ss-featured-grid
                        [list]="membersData.items"
                        [loading]="membersData.loading"
                        [cols]="1"
                        [showHeader]="false"
                        [hasSeeMore]="false"
                        (onScrollEnd)="onMembersScrollEndHandle($event)"
                    >
                        <ng-template #itemContent let-item>
                            <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
                                <div class="song-actions-clickable" (click)="onMemberProfile(item)">
                                    <app-ss-user [user]="item"></app-ss-user>
                                    <app-icon-button size="40px" color="white" border="#909090">
                                        <app-icon-arrow-right></app-icon-arrow-right>
                                    </app-icon-button>
                                </div>
                                <div class="song-actions">
                                    <app-more-dot-menu title="Options">
                                        <div class="dropdown-menu-content">
                                            <div (click)="helper.favoriteMemberWithFrom(item, user)" *ngIf="!item.favorited">Add to Favorites</div>
                                            <div (click)="helper.unfavoriteMember(item)" *ngIf="item.favorited">Remove from Favorites</div>
                                        </div>
                                    </app-more-dot-menu>
                                </div>
                            </div>
                        </ng-template>
                    </app-ss-featured-grid>
                </div>
            </div>
        </div>
        <div *ngIf="searchType === 'listen'">
            <div class="row">
                <div class="col-12 col-md-4">
                     <!-- added hasSeeMore for main search pages TONY -->
                    <h5>Recordings</h5>
                    <app-ss-featured-grid
                        [list]="recordingData.items"
                        [loading]="recordingData.loading"
                        [cols]="1"
                        [checkOwnRecording]="true"
                        [userId]="profileService.userId"
                        [showHeader]="false"
                        [hasSeeMore]="false"
                        (onScrollEnd)="onRecordingsScrollEndHandle($event)"
                    >
                        <ng-template #itemContent let-item>
                            <div class="ssc-list-item"  class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
                                <div class="song-actions-clickable" (click)="onListen(item)">
                                    <app-ss-recording [recording]="item"></app-ss-recording>
                                    <app-icon-button size="40px" color="#58c7c9">
                                        <app-icon-play color="white"></app-icon-play>
                                    </app-icon-button>
                                </div>
                                <div class="song-actions">
                                    <app-more-dot-menu title="Options">
                                        <div class="dropdown-menu-content">
                                            <div (click)="helper.favoriteRecording(item)" *ngIf="!item.favorited">Add to Favorites</div>
                                            <div (click)="helper.unfavoriteRecording(item)" *ngIf="item.favorited">Remove from Favorites</div>
                                            <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                                            <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                                            <div (click)="onMemberProfile(item)">Member Profile</div>
                                            <div *ngIf="profileService.userId == item.user_id" [routerLink]="['/d/my-recordings', item.id]">Edit Recording Info</div>
                                            <div (click)="shareRecording(item)">Share this recording</div>
                                            <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                                        </div>
                                    </app-more-dot-menu>
                                </div>
                            </div>
                            <app-recording-stat [recording]="item"></app-recording-stat>
                        </ng-template>
                    </app-ss-featured-grid>
                </div>
                <div class="col-12 col-md-4">
                    <h5>Artists</h5>
                     <!-- added hasSeeMore for main search pages TONY -->
                    <app-ss-featured-grid
                        [list]="artistsData.items"
                        [loading]="artistsData.loading"
                        [cols]="1"
                        [showHeader]="false"
                        [hasSeeMore]="false"
                        (onScrollEnd)="onArtistsScrollEndHandle($event)"
                    >
                        <ng-template #itemContent let-item>
                            <div class="ssc-list-item"  class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
                                <div class="song-actions-clickable" (click)="onArtist(item)">
                                    <app-ss-artist [artist]="item"></app-ss-artist>
                                    <app-icon-button size="40px" color="white" border="#909090">
                                        <app-icon-arrow-right></app-icon-arrow-right>
                                    </app-icon-button>
                                </div>
                                <div class="song-actions">
                                    <app-more-dot-menu title="Options">
                                        <div class="dropdown-menu-content">
                                            <div (click)="helper.favoriteArtist(item)" *ngIf="!item.favorited">Add to Favorites</div>
                                            <div (click)="helper.unfavoriteArtist(item)" *ngIf="item.favorited">Remove from Favorites</div>
                                        </div>
                                    </app-more-dot-menu>
                                </div>
                            </div>
                        </ng-template>
                    </app-ss-featured-grid>
                </div>
                <div class="col-12 col-md-4">
                    <h5>Members</h5>
                     <!-- added hasSeeMore for main search pages TONY -->
                    <app-ss-featured-grid
                        [list]="membersData.items"
                        [loading]="membersData.loading"
                        [cols]="1"
                        [showHeader]="false"
                        [hasSeeMore]="false"
                        (onScrollEnd)="onMembersScrollEndHandle($event)"
                    >
                        <ng-template #itemContent let-item>
                            <div class="ssc-list-item"  class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
                                <div class="song-actions-clickable" (click)="onMemberProfile(item)">
                                    <app-ss-user [user]="item"></app-ss-user>
                                    <app-icon-button size="40px" color="white" border="#909090">
                                        <app-icon-arrow-right></app-icon-arrow-right>
                                    </app-icon-button>
                                </div>
                                <div class="song-actions">
                                    <app-more-dot-menu title="Options">
                                        <div class="dropdown-menu-content">
                                            <div (click)="helper.favoriteMemberWithFrom(item, user)" *ngIf="!item.favorited">Add to Favorites</div>
                                            <div (click)="helper.unfavoriteMember(item)" *ngIf="item.favorited">Remove from Favorites</div>
                                        </div>
                                    </app-more-dot-menu>
                                </div>
                            </div>
                        </ng-template>
                    </app-ss-featured-grid>
                </div>
            </div>
        </div>
        <div *ngIf="searchType === 'tags'">
             <!-- added hasSeeMore for main search pages TONY -->
            <div class="tags-list" *ngIf="!showTagResult">
                <app-tag
                    *ngFor="let tag of tagsData"
                    [tag]="'#' + tag"
                    color="faint"
                    [noRemove]="true"
                    (click)="onTag(tag)"
                ></app-tag>
            </div>
            <div class="tags-result" *ngIf="showTagResult">
                <div class="row">
                    <div class="col-12 col-md-5">
                        <h5>Recordings</h5>
                        <app-ss-featured-grid
                            [list]="tagRecordingData.items"
                            [loading]="tagRecordingData.loading"
                            [cols]="1"
                            [checkOwnRecording]="true"
                            [userId]="profileService.userId"
                            [showHeader]="false"
                            [hasSeeMore]="false"
                            (onScrollEnd)="onTagRecordingScrollEndHandle($event)"
                        >
                            <ng-template #itemContent let-item>
                                <div class="ssc-list-item"  class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
                                    <div class="song-actions-clickable" (click)="onListen(item)">
                                        <app-ss-recording [recording]="item"></app-ss-recording>
                                        <app-icon-button size="40px" color="#58c7c9">
                                            <app-icon-play color="white"></app-icon-play>
                                        </app-icon-button>
                                    </div>
                                    <div class="song-actions">
                                        <app-more-dot-menu title="Options">
                                            <div class="dropdown-menu-content">
                                                <div (click)="helper.favoriteRecording(item)" *ngIf="!item.favorited">Add to Favorites</div>
                                                <div (click)="helper.unfavoriteRecording(item)" *ngIf="item.favorited">Remove from Favorites</div>
                                                <div (click)="onMemberProfile(item)">Member Profile</div>
                                                <div *ngIf="profileService.userId == item.user_id" [routerLink]="['/d/my-recordings', item.id]">Edit Recording Info</div>
                                                <div (click)="shareRecording(item)">Share this recording</div>
                                            </div>
                                        </app-more-dot-menu>
                                    </div>
                                </div>
                                <app-recording-stat [recording]="item"></app-recording-stat>
                            </ng-template>
                        </app-ss-featured-grid>
                    </div>
                    <div class="col-12 col-md-5">
                        <h5>Message Board Threads</h5>
                         <!-- added hasSeeMore for main search pages TONY -->
                        <app-ss-featured-grid
                            [list]="tagMessageData.items"
                            [loading]="tagMessageData.loading"
                            [cols]="1"
                            [showHeader]="false"
                            [hasSeeMore]="false"
                            (onScrollEnd)="onTagMessageScrollEndHandle($event)"
                        >
                            <ng-template #itemContent let-item>
                                <div class="ssc-list-item">
                                    <div class="song-actions-clickable" (click)="onMessageBoard(item)">
                                        <app-message-board-item [message]="item"></app-message-board-item>
                                        <app-icon-button size="40px" color="white" border="#909090">
                                            <app-icon-arrow-right></app-icon-arrow-right>
                                        </app-icon-button>
                                    </div>
                                </div>
                            </ng-template>
                        </app-ss-featured-grid>
                    </div>
                </div>
            </div>
        </div>
        <app-purchase-gold-modal #goldcontent type="2"></app-purchase-gold-modal>
    </div>
    