<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-body snap-credit" *ngIf="!sent && !notEnough">
    <span class="close-modal" (click)="c('ok')" >
      <app-icon-close size="18"></app-icon-close>
    </span>
    <div class="snap-image">
      <img src="/assets/pictures/ic_gold_snap.png" />
    </div>
    <h4>Snap a tip to {{user.screen_name}}!</h4>
    <app-ss-user [user]="user" [isSmall]="true" [showStatus]="false" [showLevel]="false"></app-ss-user>
    <div class="snap-settings">
      <div class="ss-form-dropdown-block fontStyle"  ngbDropdown #countDrop="ngbDropdown">
        <button class="btn" id="dropdownSnapCount"  ngbDropdownToggle (click)="countDrop.open()">
          {{count}} SingSnap Credit{{ count > 1 ? "s" : ""}}
        </button>
        <div ngbDropdownMenu  style="box-shadow: 0 3px 3px rgb(0 0 0 / 30%);" aria-labelledby="dropdownSnapCount">
          <button ngbDropdownItem class="fontStyle" *ngFor="let item of counts" (click)="changeCount(item)">{{item}} SingSnap Credit{{ item > 1 ? "s" : ""}}</button>
        </div>
      </div>
      <app-form-switch 
        label="Anonymous" 
        [value]="anonymous"
        (changed)="changeAnonymous($event)"
      ></app-form-switch>
    </div>
    <div class="snap-message" *ngIf="!anonymous">
      <textarea class="form-control" placeholder="Message..." rows="5" [(ngModel)]="message"></textarea>
    </div>
    <div class="modal-actions">
      <button class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray"
        (click)="c('Cross click')">CANCEL</button>
      <button class="btn btn-primary" (click)="handleSubmit()" [disabled]="isSending">
        <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="isSending"></i>
        SEND
      </button>
    </div>
  </div>
  <div class="modal-body snap-credit-sent snap-credit" *ngIf="sent">
    <div class="icon-inner circle-ripple circle-green">
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <div class="icon-holder">
        <app-icon-check color="white" size="36"></app-icon-check>
      </div>
    </div>
    <h4>You've snapped a tip to {{user.screen_name}}!</h4>
    <div class="modal-close-button">
      <button class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray"
        (click)="close()">CLOSE</button>
    </div>
  </div>
  <div class="modal-body snap-credit-enough snap-credit" *ngIf="notEnough">
    <div class="icon-inner circle-ripple">
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <div class="icon-holder">
        <app-icon-close color="white" size="36"></app-icon-close>
      </div>
    </div>
    <h5>Oops!</h5>
    <p>Oh oh! You don't have enough credits!</p>
    <div class="modal-actions">
      <button class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray"
        (click)="c('Cross click')">CANCEL</button>
      <button class="btn btn-primary" (click)="handlePurchase()" [disabled]="isSending">
        PURCHASE
      </button>
    </div>
  </div>
</ng-template>