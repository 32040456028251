import { Component, OnInit, HostBinding } from '@angular/core';
import { trigger, state, style, animate, transition, keyframes, query, stagger } from '@angular/animations';
//import { ScrollimateService } from "ng2-scrollimate";
import {CategoriesService} from '@app/core';

@Component({
  selector: 'app-shop-home',
  templateUrl: './shop-home.component.html',
  styleUrls: ['./shop-home.component.scss'],
  animations: [
    trigger('routeAnimation', [
      state('*', style({transform: 'translateY(0)', opacity: 1})),
      transition('void => *', [
        style({transform: 'translateY(100px)', opacity: 0}),
        animate('0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000)')
      ]),
      transition('* => void',
          animate('0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000)', style({
            transform: 'translateX(100px)',
            opacity: 0
          }))
      )
    ]),
    trigger("rollIn", [
      transition('inactive => active', [
        query('.cl_animate', style({ opacity: 0 }), {optional: true}),
        query('.cl_animate', stagger('100ms', [
          animate('400ms', keyframes([
            style({ opacity: 1, transform: 'translateX(-100%)  rotate(-120deg)', offset: 0 }),
            style({ opacity: 1, transform: 'translateX(0px) rotate(0deg)', offset: 1.0 })
          ]))]), { optional: true })
      ]),

      transition('active => inactive', [
        query('.cl_animate', style({ opacity: 1 }), {optional: true}),
        query('.cl_animate', stagger('100ms', [
          animate('400ms', keyframes([
            style({ opacity: 1, transform: 'translateX(0)  rotate(0deg)', offset: 0 }),
            style({ opacity: 0, transform: 'translateX(-100%) rotate(-120deg)', offset: 1.0 })
          ]))]), { optional: true })
      ]),

    ]),
    trigger("flipX", [
      state("inactive", style({
        opacity: 0,
      })),
      state("active", style({
        opacity: 1,
      })),
      transition('inactive => active', [
        animate(800, keyframes([

          style({ opacity: 0, transform: 'perspective(400px) rotateX(90deg)', offset: 0 }),
          style({ opacity: 1, transform: 'perspective(400px) rotateX(-10deg)', offset: 0.4 }),
          style({ opacity: 1, transform: 'perspective(400px) rotateX(10deg)', offset: 0.7 }),
          style({ opacity: 1, transform: 'perspective(400px) rotateX(0deg)', offset: 1 }),

        ]))
      ]),
      transition('active => inactive', [
        animate(800, keyframes([
          style({ opacity: 1, transform: 'perspective(400px) rotateX(0deg)', offset: 0 }),
          style({ opacity: 1, transform: 'perspective(400px) rotateX(10deg)', offset: 0.4 }),
          style({ opacity: 1, transform: 'perspective(400px) rotateX(-10deg)', offset: 0.7 }),
          style({ opacity: 0, transform: 'perspective(400px) rotateX(90deg)', offset: 1 }),
        ]))
      ])
    ]),
    trigger("flipXReverse", [
      state("inactive", style({
        opacity: 0,
      })),
      state("active", style({
        opacity: 1,
      })),
      transition('inactive => active', [
        animate(800, keyframes([
          style({ opacity: 0, transform: 'perspective(400px) rotateX(-90deg)', offset: 0 }),
          style({ opacity: 1, transform: 'perspective(400px) rotateX(10deg)', offset: 0.4 }),
          style({ opacity: 1, transform: 'perspective(400px) rotateX(-10deg)', offset: 0.7 }),
          style({ opacity: 1, transform: 'perspective(400px) rotateX(0deg)', offset: 1 }),

        ]))
      ]),
      transition('active => inactive', [
        animate(800, keyframes([
          style({ opacity: 1, transform: 'perspective(400px) rotateX(0deg)', offset: 0 }),
          style({ opacity: 1, transform: 'perspective(400px) rotateX(-10deg)', offset: 0.4 }),
          style({ opacity: 1, transform: 'perspective(400px) rotateX(10deg)', offset: 0.7 }),
          style({ opacity: 0, transform: 'perspective(400px) rotateX(-90deg)', offset: 1 }),
        ]))
      ])
    ])

  ],
})
export class ShopHomeComponent implements OnInit {

  categories: any[] = [];
  popular: any[] = [];
  latest: any[] = [];

  categoriesResponse: any;
  popularResponse: any;
  latestResponse: any;

  //animations
  @HostBinding('@routeAnimation') routeAnimation = true;
  shopAnimations: any = {};

  constructor(private categoriesService: CategoriesService) {

    let is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
    const is_explorer = navigator.userAgent.indexOf('MSIE') > -1;
    const is_firefox = navigator.userAgent.indexOf('Firefox') > -1;
    let is_safari = navigator.userAgent.indexOf("Safari") > -1;
    const is_opera = navigator.userAgent.toLowerCase().indexOf("op") > -1;
    if ((is_chrome) && (is_safari)) {
        is_safari = false;
    }
    if ((is_chrome) && (is_opera)) {
        is_chrome = false;
    }
    // if (!is_safari) {
      this.shopAnimations = {
        defaultStates: [
          {
            method: "pxElement",
            value: 200,
            state: "active",
          },
          {
            method: "default",
            state: "inactive",
          }
        ],
        secondBlock: {
          currentState: "inactive",
          states: [
            {
              method: "percentElement",
              value: 75,
              state: "active",
              class: 'cl_visible'
            },
            {
              method: "default",
              state: "inactive"
            }
          ]
        },
        thirdBlock: {
          currentState: "inactive",
          states: [
            {
              method: "percentElement",
              value: 75,
              state: "active",
              class: 'cl_visible'
            },
            {
              method: "default",
              state: "inactive"
            }
          ]
        },
        forthBlock: {
          currentState: "inactive",
          states: [
            {
              method: "percentElement",
              value: 75,
              state: "active",
              class: 'cl_visible'
            },
            {
              method: "default",
              state: "inactive"
            }
          ]
        }
      };
    // }
    //scrollimateService.setDefaultStates(this.shopAnimations.defaultStates);
  }

  ngOnInit() {
    this.getCategories();
    this.getPopularProducts();
    this.getLatestProducts();
  }

  getCategories() {
    this.categoriesService.getCategories(6)
        .subscribe(
            response => this.categoriesResponse = response,
            err => console.error(err),
            () => this.obtainedCategories()
        )
  }

  obtainedCategories() {
    // if(this.categoriesResponse.content) {
    //   this.categories = this.categoriesResponse.content; ????
    // }
    if(this.categoriesResponse) {
      this.categories = this.categoriesResponse;
    }
  }

  getPopularProducts() {
    this.categoriesService.getPopularProducts(4)
        .subscribe(
            response => this.popularResponse = response,
            err => console.error(err),
            () => this.obtainedPopular()
        )
  }

  obtainedPopular() {
    if(this.popularResponse) {
      this.popular = this.popularResponse;
    }
  }

  getLatestProducts() {
    this.categoriesService.getLatestProducts(2)
        .subscribe(
            response => this.latestResponse = response,
            err => console.error(err),
            () => this.obtainedLatest()
        )
  }

  obtainedLatest() {
    if(this.latestResponse) {
      this.latest = this.latestResponse;
    }
  }

}
