<ng-template #content let-modal let-c="close" let-d="dismiss">
  <form [formGroup]="group">
    <div class="modal-body">
      <div class="audition-title">
        <h4>Post Next Round</h4>
      </div>
      <hr>
      <p class="text-left">Upload thumbnail</p>
      <div class="row">
        <div class="col-4">
          <app-contest-drop-image (onSelected)="onImageSelected($event)"></app-contest-drop-image>
        </div>
        <div class="col-8">
          <div class="form-group row">
            <div class="col-12">
              <app-ss-input [error]="isFieldValid('name')">
                <input name="name" type="text" placeholder="Name" formControlName="name">
                <app-char-counter [total]="50" [count]="group.controls['name'].value?.length">
                </app-char-counter>
              </app-ss-input>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12">
              <app-ss-input [area]="true" [error]="isFieldValid('description')">
                <textarea
                  formControlName="description"
                  class="form-control"
                  placeholder="Description"
                  rows="6"></textarea>
                <app-char-counter [total]="255" [count]="group.controls['description'].value?.length">
                </app-char-counter>
              </app-ss-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary btn-lg btn-block" [disabled]="" (click)="onSubmit()">Post
      </button>
    </div>
  </form>
</ng-template>
<app-splash-modal #splashModal></app-splash-modal>
