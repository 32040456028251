<div class="body_home cl_fullWidth">
    <div class="main">
        <div class="ssb-navbar">
            <span class="singsnap_logo">
                <img src="../../../../assets/pictures/logo.png" alt="logo" class="logo"><span class="_singsnap">SingSnap</span>
            </span>
            <!-- <span class="ssb-actions pull-right"><img (click)="isHamburgerVisible = !isHamburgerVisible"
                                                      [src]="isHamburgerVisible ? '../../../../assets/pictures/webSite/menu_black.png' : '../../../../assets/pictures/webSite/menu.png'"
                                                      alt="burger" class="burger_menu"></span> -->
            <span class="ssb-actions pull-right"><a
                [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                [routerLink]="['/sign-up']">SIGN UP</a></span>
            <span class="ssb-actions--login pull-right"><b
                [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                [routerLink]="['/login']">LOGIN</b></span>
            <a href="#" class="social-nav smb-actions pull-right">
                <img src="/assets/pictures/icon-appstore.png" />
            </a>
            <a href="#" class="social-nav smb-actions pull-right">
                <img src="/assets/pictures/icon-playstore.png" />
            </a>
        </div>
        <!-- <div (mouseout)="hideHamburger($event)" class="hamburger" *ngIf="isHamburgerVisible">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="ssb-navbar">

                    <span class="ssb-actions pull-right"><img (click)="isHamburgerVisible = !isHamburgerVisible"
                                                              [src]="isHamburgerVisible ? '../../../../assets/pictures/webSite/menu_black.png' : '../../../../assets/pictures/webSite/menu.png'"
                                                              alt="burger" class="burger_menu"></span>
                        <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a
                            [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                            [routerLink]="['/sign-up']">SIGN UP</a></span>
                        <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a
                            [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                            [routerLink]="['/login']">LOGIN</a></span>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 menu_content">
                    <span [routerLink]="['/']"><span class="fat_line"></span> home</span><br>
                    <span [routerLink]="['/aboutUs']"><span class="fat_line"></span> about us</span><br>
                    <span [routerLink]="['/pricing']"><span class="fat_line"></span> pricing</span><br>
                    <span [routerLink]="['/blog']"><span class="fat_line"></span> blog</span><br>

                    <span [routerLink]="['/main/support']"><span class="fat_line"></span> support</span><br>
                    <span [routerLink]="['/main/influencer']"><span class="fat_line"></span> influencer</span><br>
                    <span (click)="onLogOut()" *ngIf="userIsLoged"><span class="fat_line"></span> log out</span><br>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 social-links">
                    <img src="../../../../assets/pictures/webSite/hamburger_facebook.png" alt="">
                    <img src="../../../../assets/pictures/webSite/hamburger_youtube.png" alt="">
                    <img src="../../../../assets/pictures/webSite/hamburger_twitter.png" alt="">
                    <img src="../../../../assets/pictures/webSite/hamburger_instagram.png" alt="">
                </div>
            </div>
        </div> -->
        <div class="main_content" id="first_div">
          <app-session-carousel [sideBySide]="true" [hideArrows]="false"></app-session-carousel>
        </div>
    </div>
    <div class="container first" id="second_div">
        <!-- <div class="body" [@routeAnimation]=""> -->
        <div class="body">
            <div class="row">
                <!-- <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12" [@fadeInUp]="homeAnimations.firstBlock.currentState"> -->
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="left_text">
                        <div>
                            <span class="they_sang_it">Feel Famous</span>
                            <span class="your_turn">Every Day. 🎶</span>
                            <span class="singSnappers">Use your phone, <br> tablet or computer to share <br> your voice with the world!<br>Kick it up a notch <span
                                class="yellow_thunder">⚡</span></span>
                            <span class="be_extra">SingSnap Is Your Stage!</span>
                            <span class="yellow_arrow"><img
                                src="../../../../assets/pictures/webSite/home/yellowArrowDown.png" alt=""></span>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 phone" [@slideFromLeft]="homeAnimations.firstBlock.currentState"> -->
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 phone">
                    <img src="../../../../assets/pictures/webSite/home/secondDivGirl.png" alt="">
                </div>
            </div>
        </div>
        <!-- <div class="navigation" [@pulse]="homeAnimations.firstBlock.currentState"> -->
            <div class="navigation">
                <span class="circle first_dot"></span>
                <span class="circle second_dot"></span>
                <span class="circle third_dot"></span>
                <span class="circle fourth_dot"></span>
                <span class="circle fifth_dot"></span>
                <span class="circle sixth_dot"></span>
            </div>

    </div>
    <div class="container second" id="third_div">

        <div class="body">
            <div class="row">

                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-lg-push-6">
                    <div class="right_text">
                        <div>
                            <!--<span class="mood">MOOd:</span>-->
                            <span class="they_sang_it">Get Your Vibe On!😎</span>
                            <span class="be_extra">Feelin' Drake? Madonna?<br>Maybe a little Blake Shelton?.</span>
                            <span class="be_extra">Your talent knows no <br> boundaries, so <br>we've got it all</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-lg-pull-6">
                    <img src="../../../../assets/pictures/webSite/home/drakeAndMadonna.png" alt=""
                         class="drake_and_maddona">
                    <img class="green_half_circle" src="../../../../assets/pictures/webSite/home/greenHalfCicrcle.png"
                         alt="">
                </div>

            </div>
        </div>
        <!-- <div class="navigation" [@pulse]="homeAnimations.secondBlock.currentState"> -->
            <div class="navigation">
                <span class="circle first_dot"></span>
                <span class="circle second_dot"></span>
                <span class="circle third_dot"></span>
                <span class="circle fourth_dot"></span>
                <span class="circle fifth_dot"></span>
                <span class="circle sixth_dot"></span>
            </div>

    </div>
    <div class="container third" id="fourth_div">
        <div class="body">
            <div class="row">
                <!-- <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12" [@fadeInUp]="homeAnimations.thirdBlock.currentState"> -->
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div class="left_text">
                        <div>
                            <!--<span class="your_turn">Fun and Fabulous Features</span>-->
                            <span class="they_sang_it">Fun and Fabulous Features 👀</span>
                            <span class="be_extra">Are you ready to step it up</span>
                            <span class="yellow_arrow"><img
                                src="../../../../assets/pictures/webSite/home/dots.png" alt=""></span>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 right_png" [@slideFromLeft]="homeAnimations.thirdBlock.currentState"> -->
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 right_png">
                    <img src="../../../../assets/pictures/webSite/home/phone.png" alt="">
                </div>
            </div>
        </div>
        <!-- <div class="navigation" [@pulse]="homeAnimations.thirdBlock.currentState"> -->
            <div class="navigation">
                <span class="circle first_dot"></span>
                <span class="circle second_dot"></span>
                <span class="circle third_dot"></span>
                <span class="circle fourth_dot"></span>
                <span class="circle fifth_dot"></span>
                <span class="circle sixth_dot"></span>
            </div>

    </div>
    <div class="container fourth" id="fifth_div">

        <div class="body">
            <div class="row">

                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-lg-push-6">
                    <div class="right_text">
                        <div>
                            <span class="irl_rewards">Funny lookin' Faces?</span>
                            <span class="they_sang_it">Challenge Yourself! 😍</span>
                            <span class="be_extra">Be a winner all day long!<br>Check out the Contests on SingSnap for your chance to shine and show off your talent!</span>
                            <!-- <img src="../../../../assets/pictures/webSite/home/influencer.png" alt=""
                                    class="influencer_png" [routerLink]="['/main/influencer']"> -->
                        </div>
                    </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-lg-pull-6">
                    <img src="../../../../assets/pictures/webSite/home/cartoons.png" alt=""
                         class="drake_and_maddona">
                </div>

            </div>
        </div>
        <!-- <div class="navigation" [@pulse]="homeAnimations.fourthBlock.currentState"> -->
            <div class="navigation">
                <span class="circle first_dot"></span>
                <span class="circle second_dot"></span>
                <span class="circle third_dot"></span>
                <span class="circle fourth_dot"></span>
                <span class="circle fifth_dot"></span>
                <span class="circle sixth_dot"></span>
            </div>

    </div>
    <div class="container fifth" id="sixth_div">
        <!-- <div class="left_text" [@fadeInUp]="homeAnimations.fifthBlock.currentState"> -->
        <div class="left_text">
            <div>
                <span class="stage_is_waiting">Your stage is waiting</span>
                <span class="missing_is_you">All that's missing is you</span>
                <p class="be_extra">Sing your <span class="bold_everyone">first songs free</span> right now</p>
                <div class="google_and_apple_container">
                    <img src="../../../../assets/pictures/webSite/home/appStore.png" alt="">
                    <img src="../../../../assets/pictures/webSite/home/googlePlay.png" alt="">
                </div>
            </div>
        </div>
        <!-- <div class="navigation" [@pulse]="homeAnimations.fifthBlock.currentState"> -->
            <div class="navigation">
                <span class="circle first_dot"></span>
                <span class="circle second_dot"></span>
                <span class="circle third_dot"></span>
                <span class="circle fourth_dot"></span>
                <span class="circle fifth_dot"></span>
                <span class="circle sixth_dot"></span>
            </div>
    </div>
</div>
