<div class="container-fluid main-payment" *ngIf="step === 1">
    <div class="row">

        <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
            <form [formGroup]="shippingOrder" (ngSubmit)="submitOrder(shippingOrder)">
                <h2>Shipping Address</h2>
                <span *ngIf="errorMsg && errorMsg.cartItems" class="text-danger">{{errorMsg.cartItems}}</span>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group custom-input">
                            <label for="name">FIRST NAME:</label>
                            <input type="text" class="form-control" id="name" [formControl]="shippingOrder.controls['name']" autocomplete="off">
                            <span *ngIf="shippingOrder.controls['name'].hasError('required') && submitAttempt == true" class="text-danger">First name is required.</span>
                            <span *ngIf="errorMsg && errorMsg.name" class="text-danger">{{errorMsg.name}}</span>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group custom-input">
                            <label for="lastname">LAST NAME:</label>
                            <input type="text" class="form-control" id="lastname" [formControl]="shippingOrder.controls['lastname']" autocomplete="off">
                            <span *ngIf="shippingOrder.controls['lastname'].hasError('required') && submitAttempt == true" class="text-danger">Lastname is required.</span>
                            <span *ngIf="errorMsg && errorMsg.lastname" class="text-danger">{{errorMsg.lastname}}</span>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group custom-input">
                            <label for="phone">PHONE:</label>
                            <input type="text" class="form-control" id="phone" [formControl]="shippingOrder.controls['phone']" autocomplete="off">
                            <span *ngIf="shippingOrder.controls['phone'].hasError('required') && submitAttempt == true" class="text-danger">Phone is required.</span>
                            <span *ngIf="errorMsg && errorMsg.phone" class="text-danger">{{errorMsg.phone}}</span>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group custom-input">
                            <label for="email">E-MAIL:</label>
                            <input type="text" class="form-control" id="email" [formControl]="shippingOrder.controls['email']" autocomplete="off">
                            <span *ngIf="shippingOrder.controls['email'].hasError('required') && submitAttempt == true" class="text-danger">Email is required.</span>
                            <span *ngIf="errorMsg && errorMsg.email" class="text-danger">{{errorMsg.email}}</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="form-group custom-input">
                            <label for="country">COUNTRY:</label>
                            <select id="country" class="form-control" [formControl]="shippingOrder.controls['country']">
                                <option value="" selected="selected">-- Select --</option>
                                <option value="USA">USA</option>
                            </select>
                            <span *ngIf="shippingOrder.controls['country'].hasError('required') && submitAttempt == true" class="text-danger">Country is required.</span>
                            <span *ngIf="errorMsg && errorMsg.country" class="text-danger">{{errorMsg.country}}</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="form-group custom-input">
                            <label for="city">CITY:</label>
                            <input type="text" class="form-control" id="city" [formControl]="shippingOrder.controls['city']" autocomplete="off">
                            <span *ngIf="shippingOrder.controls['city'].hasError('required') && submitAttempt == true" class="text-danger">City is required.</span>
                            <span *ngIf="errorMsg && errorMsg.city" class="text-danger">{{errorMsg.city}}</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="form-group custom-input">
                            <label for="zip">ZIP CODE:</label>
                            <input type="text" class="form-control" id="zip" [formControl]="shippingOrder.controls['zip']" autocomplete="off">
                            <span *ngIf="shippingOrder.controls['zip'].hasError('required') && submitAttempt == true" class="text-danger">Zip is required.</span>
                            <span *ngIf="errorMsg && errorMsg.zip" class="text-danger">{{errorMsg.zip}}</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="form-group custom-input">
                            <label for="state">STATE:</label>
                            <select id="state" class="form-control" [formControl]="shippingOrder.controls['state']">
                                <option value="" selected="selected">-- Select --</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </select>
                            <span *ngIf="shippingOrder.controls['state'].hasError('required') && submitAttempt == true" class="text-danger">State is required.</span>
                            <span *ngIf="errorMsg && errorMsg.state" class="text-danger">{{errorMsg.state}}</span>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <div class="form-group custom-input">
                            <label for="address">ADDRESS:</label>
                            <input type="text" class="form-control" id="address" [formControl]="shippingOrder.controls['address']" autocomplete="off">
                            <span *ngIf="shippingOrder.controls['address'].hasError('required') && submitAttempt == true" class="text-danger">Address is required.</span>
                            <span *ngIf="errorMsg && errorMsg.address" class="text-danger">{{errorMsg.address}}</span>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <!--<div class="payment-back">-->
                            <!--<button type="button" class="btn cl_sing_now" (click)="backStep()"><i class="fa fa-angle-left cart-arrow" aria-hidden="true"></i> BACK</button>-->
                        <!--</div>-->
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="payment-fw">
                            <button type="submit" class="btn cl_sing_now">PAYMENT<i class="fa fa-angle-right cart-arrow" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </form>

        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 offset-lg-1 offset-md-1 my-order">
            <h2>Your order</h2>
            <div class="row" *ngIf="cartItems.length > 0">
                <div class="col-lg-12 my-order-product" *ngFor="let item of cartItems; let e = index">
                    <span class="delete-product" (click)="removeFromCart(e)"><i class="fa fa-times" aria-hidden="true"></i></span>
                    <div class="row">
                        <div class="col-lg-4 my-order-image">
                            <img src="{{item.images[0].src}}" alt="" height="100px">
                        </div>
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 my-order-name">
                                    {{item.name}}
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 my-order-price">
                                    ${{item.price}} x{{item.ammount}}
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 my-order-color">
                                <i class="fa fa-circle" aria-hidden="true" [style.color]="item.color"></i> | <span *ngIf="item.size">{{item.size}}</span><span *ngIf="!item.size">42</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 my-order-product">
                    <div class="row my-order-total">
                        <div class="table-responsive">
                            <table class="table my-order-table">
                                <tbody>
                                <tr>
                                    <td>TOTAL</td>
                                    <td class="text-right">${{totalPrice}}</td>
                                </tr>
                                <!--<tr>-->
                                    <!--<td>SHIPPING</td>-->
                                    <!--<td class="text-right">$14</td>-->
                                <!--</tr>-->
                                <!--<tr>-->
                                    <!--<td class="my-order-total">SUBTOTAL</td>-->
                                    <!--<td class="my-order-total-price">$334</td>-->
                                <!--</tr>-->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>

            <div class="your-order-container total-price" *ngIf="cartItems.length == 0">
                <h4 class="title">You don't have any order</h4>
            </div>

        </div>
    </div>
</div>

<div class="container-fluid main-payment" *ngIf="step === 2">
    <div class="row payment">
        <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
            <h1 class="title">Payment</h1>
            <div class="row payment-method-row">
                <div class="col-md-6">
                    <div class="stripe">
                        <img class="img-fluid" src="../../../../assets/pictures/shop/payment/stripe.png" alt="">
                    </div>
                </div>
                <div class="col-md-6 paypall-col">
                    <div class="paypall">
                        <img class="img-fluid" src="../../../../assets/pictures/shop/payment/paypall.png" alt="">
                        <div class="soon">SOON</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="payment-back">
                        <button type="button" class="btn cl_sing_now" (click)="backStep()"><i class="fa fa-angle-left cart-arrow" aria-hidden="true"></i> BACK</button>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="payment-fw">
                        <button type="submit" class="btn cl_sing_now" (click)="checkStripe()">PAY ${{totalPrice}}<i class="fa fa-angle-right cart-arrow" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="stripeOverlay == true">
                <div class="col-lg-12">
                    <h1 class="title">Payment Details</h1>
                    <span class="text-danger">{{message}}</span>
                    <div class="row">
                        <form action="" method="POST" id="payment-form" (submit)="getToken()">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>CARD NUMBER</label>
                                    <div class="input-group">
                                        <input name="cardNumber" class="form-control" name="cardNumber" placeholder="Valid Card Number" required />
                                        <span class="input-group-addon"><i class="fa fa-credit-card"></i></span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>EXPIRATION (MM/YY)</label>
                                    <div class="input-group">
                                        <input name="expiryMonth" name="expiry-month" type="text" size="2" data-stripe="exp_month" class="form-control"
                                               placeholder="MM">&nbsp;&nbsp;&nbsp;
                                        <input name="expiryYear" name="expiry-year" type="text" size="2" data-stripe="exp_year" class="form-control"
                                               placeholder="YY">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>CVC</label>
                                    <div class="input-group">
                                        <input name="cvc" class="form-control" name="cardCVC" placeholder="CVC" size="4" required/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <button class="subscribe btn btn-success btn-lg btn-block" type="submit">Confirm</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>


        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 offset-lg-1 offset-md-1 my-order">
            <h2>Your order</h2>
            <div class="row" *ngIf="cartItems.length > 0">
                <div class="col-lg-12 my-order-product" *ngFor="let item of cartItems; let e = index">
                    <span class="delete-product" (click)="removeFromCart(e)"><i class="fa fa-times" aria-hidden="true"></i></span>
                    <div class="row">
                        <div class="col-lg-4 my-order-image">
                            <img src="{{item.images[0].src}}" alt="" height="100px">
                        </div>
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 my-order-name">
                                    {{item.name}}
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 my-order-price">
                                    ${{item.price}} x{{item.ammount}}
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 my-order-color">
                                <i class="fa fa-circle" aria-hidden="true" [style.color]="item.color"></i> | <span *ngIf="item.size">{{item.size}}</span><span *ngIf="!item.size">42</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 my-order-product">
                    <div class="row my-order-total">
                        <div class="table-responsive">
                            <table class="table my-order-table">
                                <tbody>
                                <tr>
                                    <td>TOTAL</td>
                                    <td class="text-right">${{totalPrice}}</td>
                                </tr>
                                <!--<tr>-->
                                    <!--<td>SHIPPING</td>-->
                                    <!--<td class="text-right">$14</td>-->
                                <!--</tr>-->
                                <!--<tr>-->
                                    <!--<td class="my-order-total">SUBTOTAL</td>-->
                                    <!--<td class="my-order-total-price">$334</td>-->
                                <!--</tr>-->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>

            <div class="your-order-container total-price" *ngIf="cartItems.length == 0">
                <h4 class="title">You don't have any order</h4>
            </div>

        </div>
    </div>

</div>

<div class="container-fluid main-container" *ngIf="step === 3">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 payment-icon">
            <img src="../../../../assets/pictures/shop/payment/denied.png" alt="" height="100%">
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 payment-headline">
            <p>Sorry, Your payment was denied!</p>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 payment-message">
            <p>{{stripeResponse.message}}</p>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 payment-button">
            <div class="payment-button-fails">
                <button type="button" class="btn cl_sing_now" (click)="paymentDeclined()">BACK</button>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid main-container" *ngIf="step === 4">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 payment-icon">
            <img src="../../../../assets/pictures/shop/payment/accepted.png" alt="" height="100%">
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 payment-headline">
            <p>Congratulations. Your order is complete</p>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 payment-message">
            <p>{{stripeResponse.message}}</p>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 payment-button">
            <div class="payment-button-accepted">
                <button type="button" class="btn cl_sing_now" (click)="myOrders()">MY ORDERS</button>
            </div>
        </div>
    </div>
</div>




