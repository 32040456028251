import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { AuthenticationService, UtilService } from '@app/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import Bugsnag from '@bugsnag/js';
declare var gtag;
@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
})
export class SignUpFormComponent implements OnInit {
  formGroup: UntypedFormGroup;
  genders = ['Prefer not to say', 'Male', 'Female', 'Other'];
  gender = '';
  submitting = false;
  screenNameError = '';
  Date;
  emailError = ''
  signUpErrors = []
  maxDate;
  startDate;
  currentPassword = '';
  confirmPassword = '';
  checkPassword = false;
  hasFocus = false;
  //samePassword = false;
  focusedOnce = false;
  showPasswordError = false;
  fieldTextType: boolean;
  fieldTextTypeTwo: boolean;
  wantsNewsLetter = false;

  constructor(
    private service: AuthenticationService,
    private util: UtilService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private toast: ToastrService,
  ) {}

  ngOnInit() {
    this.Date = new Date();
    this.maxDate = {year: this.Date.getFullYear() - 13, month: this.Date.getMonth() + 1 , day: this.Date.getDate()};
    this.startDate = {year: this.Date.getFullYear() - 14, month: this.Date.getMonth() + 1 , day: this.Date.getDate()};
    this.createForm();
    this.changeGender('Prefer not to say');
  }

  isValidBirthday(input: AbstractControl) {
    let Date2 = new Date();
    if (input.value) {
      const dateInput = moment(input.value);
      const cutOffDate = moment({year: Date2.getFullYear() - 13, month: Date2.getMonth() + 1 , day: Date2.getDate()});
      if (dateInput.isAfter(cutOffDate) ) {
       return { 'invalidDate': true }
      } else {
       return null;
      }
    }
    return null;
  }

  passwordValue(input: AbstractControl) {
    this.currentPassword = input.value;
    return null;
  }

  isPasswordSame(input: AbstractControl) {
    this.confirmPassword = input.value;
    return null;
  }

  createForm() {
    this.formGroup = this.fb.group({
      name: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.minLength(5),
          Validators.maxLength(320),
          //this.isValidEmail.bind(this),

        ],
      ],
      screen_name: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20),
          this.isValidScreenName.bind(this),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
          this.passwordValue.bind(this),
        ],
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
          this.isPasswordSame.bind(this),
          
        ],
      ],
      gender: ['', []],
      birthday: [
        '',
        [
         Validators.required,
          this.isValidBirthday.bind(this),
        ]
      ],
    });
  }

  changeGender(value) {
    this.gender = value;
  }

  async onSubmit() {

    if(this.showPasswordError){
      this.toast.error('Please verify your information.');
      return;

    }

    this.formGroup.controls.name.markAsDirty();
    this.formGroup.controls.email.markAsDirty();
    this.formGroup.controls.screen_name.markAsDirty();
    this.formGroup.controls.password.markAsDirty();
    this.formGroup.controls.birthday.markAsDirty();

    if (this.formGroup.valid) {
      this.signUpErrors = [];
      const data = Object.assign({}, this.formGroup.value);
      data.dob = `${data.birthday.month}/${data.birthday.day}/${data.birthday.year}`;
      if (this.gender) {
        data.gender = this.gender.substr(0, 1);
      }
      delete data.birthday;
      data.password_confirmation = data.password;
      data.newsletter = this.wantsNewsLetter;
      this.submitting = true;
      try {
        const result = await this.service.onRegister(data).toPromise();
        this.util.setloggedUserData(result.user);
        let today = new Date();
        let expire = new Date();
        expire.setTime(today.getTime() + 3600000*24*14);
        document.cookie = `AuthToken=${result['token']};expires=${expire.toUTCString()}`
        //Cookie.set('AuthToken', result.token);
        localStorage.setItem('avatar', result.user.photo);
        //localStorage.setItem('token', result.token);
        if (result.user && result.user.id) {
          //Cookie.set('UserId', result.user.id);
          document.cookie = `UserId=${result['user']['id']};expires=${expire.toUTCString()}`

        }
        this.sendGtag(result.user.id, 'success');
        this.router.navigate(['/d/signup-complete']);
      } catch (err) {
        console.log(err)
        this.sendGtag(0, 'failure');
        if (typeof err.error.errors === 'object') {
          Object.keys(err.error.errors).forEach(key => {
          this.signUpErrors.push(err.error.errors[key]);
          
        });
        let errMsg = this.signUpErrors.toString();
        Bugsnag.notify('Signup error: ' + errMsg);
      }
      } finally {
        this.submitting = false;
      }
    } else {
      this.toast.error('Please verify your information.');
    }
  }

  checkUniqueScreenName() {
    if(this.formGroup.value.screen_name == ''){
      return
    }
    console.log(this.formGroup.value.screen_name)
    this.service.checkUnique({screen_name: this.formGroup.value.screen_name}).subscribe(result => {
      this.screenNameError = "";
      console.log('no error')
      if(this.formGroup.controls['screen_name'].valid) {
        this.formGroup.controls.screen_name.setErrors(null)
      }
    }, error => {
      console.log('error')
      this.screenNameError = 'This screen name is already taken! Please try another one'
      this.formGroup.controls.screen_name.setErrors({'incorrect': true})
    });
  }

  checkUniqueEmail() {
    if(this.formGroup.value.email== ''){
      return
    }
    console.log(this.formGroup.value.email)
    this.service.checkUnique({email: this.formGroup.value.email}).subscribe(result => {
      this.emailError = "";
      console.log('no error')
      if(this.formGroup.controls['email'].valid) {
        this.formGroup.controls.email.setErrors(null)
      }
    }, error => {
      console.log('error')
      this.emailError = 'This email is already taken! Please try another one'
      this.formGroup.controls.email.setErrors({'incorrect': true})
    });
  }

  isValidScreenName(input: AbstractControl) {
    console.log(input)
    const screenNameArray = input.value.split(/(?![A-Za-z0-9\-\_]+)/);
    if (screenNameArray.length > 1 && screenNameArray.length < 19) {
      return {
        isValid: true,
      };

    } else {
      return null;
    }
  }


  onClickOutside(){ 
    this.hasFocus = false;
    this.checkPassword = true;
   if(this.confirmPassword && this.confirmPassword!= this.currentPassword ){
    this.showPasswordError = true;
   } else {
    this.showPasswordError = false;
   }
  }

  onFocus(){
    this.focusedOnce = true;
    this.hasFocus = true;
    this.checkPassword = false;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextTypeTwo() {
    this.fieldTextTypeTwo = !this.fieldTextTypeTwo;
  }

  onDateSelect(dt){
    this.startDate = dt;
  }

  setWantsNewsLetter(value){
    this.wantsNewsLetter = value;
  }

  sendGtag(userID = 0, state){
    gtag("event", "new_signup", {
    method: "password",
    signupState: state.toString(),
    user_id: userID.toString()
    });
  }
}
