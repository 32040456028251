<div class="container-fluid px-0" [class.night-theme]="nightmode$ | async">
  <div class="main" id="mainContainer" >
  <div class="main" >

    <div class="col-12 px-0">
      <app-menu-main></app-menu-main>
    </div>
    <div class="col-12 px-0">
      <router-outlet></router-outlet>
    </div>

    <!-- bottom ad-->
    <!-- <div style="text-align:center;height:100px;width:450px;">
      <ng-adsense
        [adClient]="'ca-pub-4776037543768273'"
        [adSlot]="9989671476"
        [display]="'block; height:100px;width:450px;'"
        [adFormat]="'auto'"
      ></ng-adsense>
    </div> -->

  </div>
  <app-upload-failed-list></app-upload-failed-list>
  <app-main-footer></app-main-footer>
  <app-snap-credit-modal #snap></app-snap-credit-modal>
  <app-send-gift-modal #gift></app-send-gift-modal>
  <app-blocked-notify-modal #blocked></app-blocked-notify-modal>
  <app-social-share #socialShare></app-social-share>
</div>


