<div class="app-icon">
  <svg id="fb_messanger" data-name="fb messanger" xmlns="http://www.w3.org/2000/svg" [style.width]="size" [attr.height.px]="size"
    viewBox="0 0 58 58">
    <circle id="Oval" cx="29" cy="29" r="29" fill="#2296f3" />
    <g id="messenger" transform="translate(15 23)">
      <path id="Path"
        d="M26.83.345A.835.835,0,0,0,25.75.1l-8.775,4.86L11.516.209A.834.834,0,0,0,10.37.254L.245,10.539a.867.867,0,0,0,0,1.212.834.834,0,0,0,1,.146l8.775-4.86,5.464,4.757a.834.834,0,0,0,1.146-.045L26.756,1.464A.867.867,0,0,0,26.83.345Z"
        fill="#fafafa" />
    </g>
  </svg>
</div>