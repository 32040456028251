<div class="app-icon" (mouseover)="onHover()" (mouseleave)="onLeave()">
    <svg    [attr.width] = 'size'
            [attr.height] = 'size'
            *ngIf='size'
            viewBox="0 0 22 22"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <polygon id="path-icon-star" points="18.18 21 16.545 13.971 22 9.244 14.809 8.627 12 2 9.191 8.627 2 9.244 7.455 13.971 5.82 21 12 17.272"></polygon>
        </defs>
        <g id="ic_stars" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon-24px">
                <g>
                    <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                    <mask id="mask-2" fill="white">
                        <use xlink:href="#path-icon-star"></use>
                    </mask>
                    <use id="Mask" [attr.fill]="mainColor" xlink:href="#path-icon-star"></use>
                </g>
            </g>
        </g>
    </svg>
</div>
