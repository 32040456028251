<div *ngIf="recording" class="recording-stats">
  <span class="recording-stats-time">
    {{recording?.created_at | dateFormat | date:'MMM d, yyyy h:mma' | dateAmpm}}
  </span>
  <div class="recording-stats-detail">
    <div>
      <app-icon-heart size="10px" [color]="recording?.allow_loves ? 'rgb(95, 191, 196)' : '#D0D0D0'"></app-icon-heart>
      <div *ngIf="recording?.allow_loves; else track_loves_na">{{formatLove(recording?.stats?.love)}}</div>
      <ng-template #track_loves_na></ng-template>
    </div>
    <div>
      <app-icon-comment size="10px" [color]="recording?.allow_comments ? 'rgb(95, 191, 196)' : '#D0D0D0'"></app-icon-comment>
      <div *ngIf="recording?.allow_comments; else track_comments_na">{{formatComments(recording?.stats?.comments)}}</div>
      <ng-template #track_comments_na></ng-template>
    </div>
    <div>
      <app-icon-eye size="12px" [color]="recording?.track_views ? 'rgb(95, 191, 196)' : '#D0D0D0'"></app-icon-eye>
      <div *ngIf="recording?.track_views; else track_views_na">{{formatViews(recording?.stats?.views)}}</div>
      <ng-template #track_views_na></ng-template>
    </div>
  </div>

      <!-- REMOVE Privacy ICON -->
  <!-- <div class="recording-stats-privacy">
    <span class="recording-privacy-badge" title="Members only" *ngIf="icons.members">
      <app-icon-singsnap size="20"></app-icon-singsnap>
    </span>
    <span class="recording-privacy-badge" title="Private" *ngIf="icons.private">
      <app-icon-lock size="20"></app-icon-lock>
    </span>

    <span class="recording-privacy-badge" title="Public" *ngIf="icons.public">
      <app-icon-globe size="20"></app-icon-globe>
    </span>
    <span class="recording-privacy-badge" title="Inner Circle" *ngIf="icons.ic">
      <app-icon-ic size="20"></app-icon-ic>
    </span>
    <span class="recording-privacy-badge" title="Contest" *ngIf="icons.contest">
      <app-icon-trophy size="20"></app-icon-trophy>
    </span>
  </div>
-->
</div>