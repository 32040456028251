import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { AuthenticationService, UtilService } from '@app/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  formGroup: UntypedFormGroup;

  constructor(
    private service: AuthenticationService,
    private util: UtilService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private toast: ToastrService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.formGroup = this.fb.group({
      password: ['', [
        Validators.required
      ]],
      confirmPassword: ['', [
        Validators.required
      ]],
    });
  }

  onSubmit() {
    this.formGroup.controls.password.markAsDirty();
    if (this.formGroup.valid) {
      console.log(this.formGroup.value);
      // submit
      // redirect to login
    }
  }
}
