import { settings } from './../components/shop/settings';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  //NgbDateCustomParserFormatter,
  ProfileService,
  EventService,
  RecordingService,
  UtilService,
} from '@app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
//import * as moment from 'moment';
import { BlockModalComponent } from '@app/shared/block-modal/block-modal.component';
import { ReportModalContentComponent } from '@app/shared/report-modal-content/report-modal-content.component';
import { EmailNotificationsService } from '@app/core/services/email-notifications.service';
import { formatDate } from "@angular/common";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  // providers: [
  //   { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  // ],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  @ViewChild('updateStatus') private updateStatusElem;
  @ViewChild('blockModal', { static: true }) blockModal: BlockModalComponent;
  @ViewChild('snap', { static: true }) private snapModal;
  @ViewChild('showcase', { static: true }) private showcaseModal;
  routeSubscritpion;
  public user;
  public userId;
  model;
  minDate;
  me;
  public coverFiles: NgxFileDropEntry[] = [];
  countries;
  modalEditRef = null;
  editGroup: UntypedFormGroup;
  coverFile = null;
  coverChanged = false;
  coverTempPath = '';
  userInfo = {};
  status = '';
  publicFavorites = false;
  showFeaturedOn = true;
  addBotText = false;
  accountAge = 0;
  isGold = false;
  genders = [
    {
      name: 'Male',
      value: 'M',
    },
    {
      name: 'Female',
      value: 'F',
    },
  ];

  privacies = [
    {
      name: 'Public (anyone can view)',
      value: 'public',
    },
    {
      name: ' Private',
      value: 'private',
    },
  ];

  @ViewChild('content', { static: true }) content;
  loggedInIsGold = true;
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private service: ProfileService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private utilService: UtilService,
    private profileService: ProfileService,
    private cdr: ChangeDetectorRef,
    private toast: ToastrService,
    private events: EventService,
    private modalService: NgbModal,
    private emailNotificationsService: EmailNotificationsService
  ) {
    this.routeSubscritpion = this.activatedRoute.params.subscribe(params => {
      this.userInfo = {};
      this.countries = null;
      this.modalEditRef = null;
      this.coverFile = null;
      this.coverChanged = false;
      this.coverTempPath = '';
      this.status = '';
      this.publicFavorites = false;
      this.showFeaturedOn = true;

      this.genders = [
        {
          name: 'Male',
          value: 'M',
        },
        {
          name: 'Female',
          value: 'F',
        },
      ];

      this.privacies = [
        {
          name: 'Public (anyone can view)',
          value: 'public',
        },
        {
          name: ' Private',
          value: 'private',
        },
      ];
      this.userId = params.id;

      this.loadUser();

      this.userInfo = this.profileService.user;
      this.showFeaturedOn = this.userInfo['settings']['featured_on'];
      
      let me = this.profileService.userId;
      if(this.userId == me) {
        this.me = true;
      } else {
        this.me = false;
      }
      console.log('user changed', params);
      
    });
  }

  ngOnInit() {
    this.minDate = {year: 1920, month: 1, day: 1};
    this.loggedInIsGold = this.userInfo['gold'];
  }

  ngOnDestroy() {
    this.routeSubscritpion.unsubscribe();
  }

  loadUser() {

    if (this.userId) {
                 
      this.service.getProfile(this.userId).subscribe(
        user => {
          this.user = user;
          this.status = this.user.status;
          this.publicFavorites = this.user.public_favorites;  
        },
        err => {
          if (err.status === 403) {
            if (window.history.length > 2) {
              this.location.back();
              this.events.onShowBlockedNotify();
            } else {
              this.router.navigate(['/home']);
            }
          }
        }
      );
      this.service.getUserProfile().subscribe(user => {
        this.accountAge = user['account_age'];
        this.isGold = user['gold'];
      });


    } else {
      this.service.getUserProfile().subscribe(user => {
        this.user = user;
        this.userInfo = user;
        this.showFeaturedOn = this.userInfo['settings']['featured_on'];
        this.status = this.user.status;
        this.publicFavorites = this.user.settings.public_favorites;
        this.accountAge = user['account_age'];
        this.isGold = user['gold'];
      });

      // this.service.getUserProfile().subscribe(
      //   user => {
      //     this.userInfo = user;
      //     this.showFeaturedOn = this.userInfo['settings']['featured_on'];
      //   },
      //   err => {
      //     console.log(err)
      //   }); 
    }
    this.utilService.getCountries().then(countries => {
      this.countries = countries;
    });
  }

  getSnapicons() {
    if (!this.user) {
      return [];
    }
    const { snapicons = {} } = this.user;
    return Object.keys(snapicons);
  }

  addClick(message) {
    this.toastr.success('clicked: ' + message);
  }

  gotoPM() {
    this.router.navigate(['/d/private-message/', 'inbox', this.user.id]);
  }

  openEditModal(content) {
    this.createEditForm();
    this.modalEditRef = this.modalService.open(content, {
      windowClass: 'edit-profile-modal',
      size: 'lg',
    });
    //console.log(this.userInfo);
    //setTimeout(()=>{(<HTMLInputElement>document.getElementById('name')).value= this.userInfo['name']},1000)

    console.log(this.modalEditRef)

  }

  notify() {
    if (this.user.notified) {
      this.unNotifyMe();
    } else {
      this.notifyMe();
    }
  }

  notifyMe() {
    this.service.notifyOnNextUserRecording(this.userId).subscribe(
      response => {
        this.toastr.success(response.message);
        this.user.notified = true;
        this.user.favorited = true;
      },
      response => {
        this.toastr.error(response.message);
      }
    );
  }

  unNotifyMe() {
    this.service.unnotifyOnNextUserRecording(this.userId).subscribe(
      response => {
        this.toastr.success(response.message);
        this.user.notified = false;
        //this.user.favorited = false;
      },
      response => {
        this.toastr.error(response.message);
      }
    );
  }

  createEditForm() {
    this.editGroup = this.fb.group({
      photo: [null, []],
      cover: ['', []],
      name: ['', [Validators.required]],
      privacy: [this.privacies, []],
      dob: [null, [Validators.required]],
      birthPrivacy: [this.privacies, []],
      gender: [this.genders, []],
      country: [this.countries, [Validators.required]],
      bio: ['', []],
    });
    //console.log(this.userInfo['dob'])
    //const dobMoment = moment(this.userInfo['dob'], 'YYYY-MM-DD hh:mm:ss');
    const dobMoment = new Date(this.userInfo['dob']);

    // month 0 based
    
    const dob = {
      year: dobMoment.getFullYear(),
      day: dobMoment.getDate(),
      month: dobMoment.getMonth() + 1,
    };
    console.log("userInfo", this.userInfo)

    const strippedString = this.user.bio.replace(/(<([^>]+)>)/gi, "");

    this.editGroup.patchValue({
      photo: this.user.photo,
      cover: this.user.cover,
      name: this.userInfo['name'],
      gender: this.genders.find(g => g.value === this.userInfo['gender']),
      dob: dob,
      country: this.countries.find(p => p.code === this.userInfo['country']),
      bio: strippedString,
      privacy: this.privacies.find(p => p.value === this.userInfo['privacy']),
      birthPrivacy: this.privacies.find(
        p => p.value === this.userInfo['birthPrivacy']
      ),
    });
  }

  public dropped($event) { 
    this.profileService.updateCover($event.file).subscribe(
      response => {
        this.toastr.success('Cover updated successfully.');
        this.user = response.user;
      },
      response => {
        this.toastr.error(
          "There's a problem updating your cover, please try again."
        );
      }
    );
  }

  openAvatarFilePicker() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    this.coverChanged = true;
    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0];
      // file type is only image.
      if (/^image\//.test(file.type)) {
        this.profileService.updateProfilePhoto(file).subscribe(
          response => {
            this.toastr.success('Cover updated successfully.');
            this.user = response.user;
          },
          response => {
            this.toastr.error(
              "There's a problem updating your cover, please try again."
            );
          }
        );
      } else {
        this.toastr.warning('You could only upload images.');
      }
    };
  }

  openFilePicker($event) {
    //remove cover picture
    if($event.file == null){
      this.removeCoverPic();
      return;
    }
    const file = $event.file;
    if (/^image\//.test(file.type)) {
      this.profileService.updateCover(file).subscribe(
        response => {
          this.toastr.success('Cover updated successfully.');
          this.user = response.user;
        },
        response => {
          this.toastr.error(
            "There's a problem updating your cover, please try again."
          );
        }
      );
    } else {
      this.toastr.warning('You could only upload images.');
    }
  }

  
  removeProfilePic(){
    delete this.user.photo;
    this.coverChanged = true;
    this.profileService.removeProfilePicture().subscribe(
      response => {
        this.toastr.success('Profile picture updated successfully.');
        this.user = response.user;
      },
      response => {
        this.toastr.error(
          "There's a problem updating your profile picture, please try again."
        );
      }
    );
  }


  removeCoverPic(){
    delete this.user.cover;
    this.coverChanged = true;
    this.profileService.removeCoverPicture().subscribe(
      response => {
        this.toastr.success('Cover updated successfully.');
        this.user = response.user;
      },
      response => {
        this.toastr.error(
          "There's a problem updating your cover, please try again."
        );
      }
    );
  }


  updatedStatusMessage(value) {
    console.log(value);
    this.status = value;
  }

  onEditStatus() {
    this.updateStatusElem.open(this.status);
  }

  submitEdit() {
     if(this.editGroup.value.bio == null) {
       this.editGroup.value.bio = "";
    }
    

    if(this.addBot()){
      this.editGroup.value.bio = "_";
    }

    if (this.editGroup.valid) {
      const user = Object.assign({}, this.editGroup.value);
      delete user.cover;
      delete user.photo;

      if (user.dob && user.dob.year) {
        const dob = `${user.dob.year}-${user.dob.month}-${user.dob.day}`;
        user.dob = dob;
      }

      if (user.gender && user.gender.value) {
        user.gender = user.gender.value;
      }

      if (user.country && user.country.code) {
        user.country = user.country.code;
      }

      if (user.privacy && user.privacy.value) {
        user.privacy = user.privacy.value;
      }

      if (user.birthPrivacy && user.birthPrivacy.value) {
        user.birthPrivacy = user.birthPrivacy.value;
      }

      this.profileService.updateUser(user).subscribe(
        response => {
          this.toastr.success('Your information was updated successfully.');
          this.user = Object.assign(this.user, response.user);
          this.modalEditRef.close();
          //this.loadUser();
          setTimeout(()=>{document.location.reload()}, 2000)
        },
        response => {
          this.toastr.error(
            "There's a problem updating your information, please try again."
          );
        }
      );
    } else {
      console.log(this.editGroup)
      this.toastr.error("Error updating profile. Make sure required fields are not blank.");
   }
  }

  toggleFavorite() { 
    if (!this.user.favorited) {
      this.profileService.favoriteMember(this.userId).subscribe(response => {
        if (response.success) {
          this.user.favorited = true;
          this.toast.success(
            response.model.screen_name + ' added to favorites.'
          );
          if (this.user.newsletter){
            //this.emailNotificationsService.emailFavoriteNotification(this.user.email, this.user['screen_name'], this.userInfo['screen_name'])
            this.emailNotificationsService.emailFavoriteNotificationV2(this.user.email, this.user['screen_name'], this.userInfo['screen_name'], this.userInfo['id'], this.userInfo['photo'].replace('https://', ''))
          }
        } else {
          this.toast.error(response.message);
        }
      });
    } else {
      this.profileService.unfavoriteMember(this.userId).subscribe(response => {
        if (response.success) {
          this.user.favorited = false;
          this.user.notified = false;
          this.toast.success(
            response.model.screen_name + ' removed from favorites.'
          );
        } else {
          this.toast.error(response.message);
        }
      });
    }
  }

  report() {
    const modalRef = this.modalService.open(ReportModalContentComponent, {
      windowClass: 'report-actions-modal',
    });
    modalRef.componentInstance.type = 'user';
    modalRef.componentInstance.id = this.userId;
  }

  block() {
    this.blockModal.open('Block User', this.user);
  }

  openSnapModal() {
    this.snapModal.open(this.user);
  }

  openShowcaseModal() {
    this.showcaseModal.open(this.user);
  }

  sendGift() {
    //this.events.onSendGift(this.userInfo);
    this.events.onSendGift(this.user);
  }

  showBio(){


    if(this.isGold){
      return true;
    } else if( this.accountAge > 10){
      return true;
    } else {
      return false;
    }


  }

  addBot(){
    if(!this.isGold && this.accountAge <= 1){
      return true;
    } else {
      return false;
    }
  }

}
