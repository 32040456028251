
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
// import {
//   Response,
//   Headers,
//   RequestOptions,
//   URLSearchParams,
// } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { settings } from '../../components/shop/settings';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { environment } from '../../../environments/environment';

@Injectable()
export class OrdersService {
  // headers: any;
  // options: any;
  // params: URLSearchParams;
  // requestOptions: RequestOptions;

  constructor(private http: HttpClient, private httpService: HttpService) {
    // this.headers = new Headers({ 'Content-Type': 'application/json' });
    // this.options = new RequestOptions({ headers: this.headers });
  }

  // getHeaders() {
  //   const headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   headers.append('Authorization', Cookie.get('Authorization'));
  //   const options = new RequestOptions({ headers: headers });
  //   return options;
  // }

  submitOrder(order, cartItems) {
    const options = this.httpService.getHeaders();
    order.cartItems = cartItems;
    return this.http
      .post(settings.host + 'order', JSON.stringify(order), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      catchError(this.handleError));
  }

  getOrders(id) {
    const options = this.httpService.getHeaders();
    return this.http
      .get(settings.host + 'orders?id=' + id, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      catchError(this.handleError));
  }

  getOrdersDetails(id) {
    const options = this.httpService.getHeaders();
    return this.http
      .get(settings.host + 'order?id=' + id, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      catchError(this.handleError));
  }

  checkStripe() {
    const options = this.httpService.getHeaders();
    return this.http
      .get(environment.baseAPIUrl + '/user/settings', options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      catchError(this.handleError));
  }

  chargeStripe(amount, idOrder) {
    const options = this.httpService.getHeaders();
    const body = {
      amount: amount * 100,
      description: 'Order payment',
      order_id: idOrder,
    };
    return this.http
      .post(environment.baseAPIUrl + '/user/charge', body, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      catchError(this.handleError));
  }

  private handleError(error: any) {
    return observableThrowError(error.json());
  }
}
