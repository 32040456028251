<div class="ss-sync-holder">
  <input type="range" class="ss-sync-input" step="0.1" min="0" [max]="duration" [(ngModel)]="current"
    (change)="onSeek($event)" 
    (input)="onSeek($event)" 
    />
  <div class="ss-sync-actual">
    <div class="ss-sync-bar" #progressBar>
      <span class="barline" [style.width]="getProgress() + '%'"></span>
    </div>
  </div>
  <span class="ss-seeker" #seeker [style.left]="getProgress() + '%'"></span>
</div>