import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {AppState} from './app.model';
import {map, startWith, tap} from 'rxjs/operators';
import {UtilService} from './core/services/util.service';

@Injectable({
  providedIn: 'root'
})
export class AppFacade {
  private state = new AppState();
  private dispatch = new BehaviorSubject<AppState>(this.state);

  nightmode$: Observable<boolean> = this.dispatch.asObservable().pipe(
    map(state => state.nightmode),
    startWith(this.util.getNightmode()),
    tap(nightmode => console.log('nightmode', nightmode))
  );

  constructor(private util: UtilService) {
  }

  updateNightmode(nightmode: boolean) {
    this.dispatch.next(
      (this.state = {
        ...this.state,
        nightmode
      })
    );
  }
}
