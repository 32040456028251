import { Component, Input, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, } from '@angular/core';

@Component({
  selector: 'app-top-horizontal-list',
  templateUrl: './top-horizontal-list.component.html',
  styleUrls: ['./top-horizontal-list.component.scss']
})
export class TopHorizontalListComponent implements OnInit {
  @Input() title = 'Recommended for you';
  @Input() items = [];
  @Input() amount = 2;
  @Input() mode = 1;
  @Input() showcase = false;
  @Input() showStats = false; 
  @Input() pageType = "" ;
  @Input() loading = false ;






  constructor() { }

  ngOnInit(): void {
    
  

  }

}
