import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  NgZone,
  EventEmitter,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import {
  SongService,
  RecorderService,
  RecordingService,
  EventService,
} from '@app/core/services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-other-singers',
  templateUrl: './other-singers.component.html',
  styleUrls: ['./other-singers.component.scss'],
})
export class OtherSingersComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  @ViewChild('playlist') private playlistElem;
  @Output() updated: EventEmitter<any> = new EventEmitter();
  @Output() addToPlaylist = new EventEmitter();
  modalReference: any;
  recordings = [];
  loading = false;
  id: any;

  constructor(
    private modalService: NgbModal,
    private songService: SongService,
    private eventService: EventService,
    private router: Router,
    private recordingService: RecordingService,
    private toastr: ToastrService,
    private ngZone: NgZone
  ) {}

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  ngOnInit() {}

  open(id) {
    this.id = id;
    this.loading = true;
    this.songService.getRecordingsV2(id, 1, 7).subscribe(result => {
      this.recordings = result.data;
      this.loading = false;
    });
    this.modalReference = this.modalService.open(this.contentElem, {
      windowClass: 'ssc-modal ssc-modal-sm',
      backdrop: 'static',
    });
  }

  closeModal() {
    this.modalReference.close();
  }

  showRecording(recording) {
    this.router.navigate(['/d/listen/', recording.id]);
    this.closeModal();
  }

  favRecording(recording) {
    this.recordingService.fav(recording.id).subscribe(response => {
      if (response.success) {
        recording.favorited = true;
        this.toastr.success('Recording was added to favorites.');
      } else {
        this.toastr.error('There was an error, try again later.');
      }
    });
  }

  unFavRecording(recording) {
    this.recordingService.unfav(recording.id).subscribe(response => {
      if (response.success) {
        recording.favorited = false;
        this.toastr.success('Recording was removed from favorites.');
      } else {
        this.toastr.error('There was an error, try again later.');
      }
    });
  }

  onAddtoPlaylist(id) {
    this.closeModal();
    if (this.addToPlaylist) {
      this.addToPlaylist.emit(id);
    }
  }

  shareRecording(recording) {
    this.eventService.onSocialShare({ recording });
  }

  OthersSingSeeMore(){ 
    this.closeModal();
    this.navigate([`/d/ListenOthersSing/${this.id}`]);
   
  }
}
