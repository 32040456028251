
import {takeUntil, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, EventEmitter, OnDestroy, OnInit, Output, Input, ViewChild, SimpleChanges} from '@angular/core';
import {Subject,  Observable } from 'rxjs';
import { UntypedFormControl, FormControlName, FormControlDirective, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
    selector: 'app-search-bar-input',
    templateUrl: './search-bar-input.component.html',
    styleUrls: ['./search-bar-input.component.scss']
})
export class SearchBarInputComponent implements OnInit, OnDestroy  {
    ngUnsubscribe: Subject<void> = new Subject();
    debouncer: Subject<any> = new Subject();
    showSearchIcon = true;
    group: UntypedFormGroup;
    @Output('onSearch') onSearch: EventEmitter<any> = new EventEmitter();
    @Output('onClear') onClear: EventEmitter<any> = new EventEmitter();
    @Input('query') query = '';
    @ViewChild('input', { static: true }) input;
    @Input() resetSearch: any;
 
    constructor(private fb: UntypedFormBuilder) {
        this.group = this.fb.group({
            searchBarInput: []
        });
    }

    ngOnInit() {
        this.debouncer.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            takeUntil(this.ngUnsubscribe),)
            .subscribe(event => {
                if (event.target.value && event.target.value.length > 0) {
                    this.onSearch.emit(event.target.value);
                    this.showSearchIcon = false;
                } else {
                    this.clear();
                }
            });

         if(this.query.length){
            this.showSearchIcon = false;
         }       
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    clear() {
        this.input.nativeElement.value = '';
        this.onSearch.emit('');
        this.onClear.emit();
        this.showSearchIcon = true;
    }

    getSearchInput(): UntypedFormControl {
        console.log('getSearchInput');
        return this.group.controls.searchBarInput as UntypedFormControl;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['resetSearch'] && changes['resetSearch']?.currentValue === true) {
            this.clear();
            this.resetSearch=false;
        }
      }
}
