import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-check',
  templateUrl: './form-check.component.html',
  styleUrls: ['./form-check.component.scss']
})
export class FormCheckComponent implements OnInit {
  @Input() label = '';
  @Input() value = false;
  @Input() disabled = false;
  @Input() square = false;
  @Output() changed = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  handleChange(event) {
    this.changed.emit(this.value);
  }

}
