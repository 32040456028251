<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <polygon id="path-angle-down" points="1.41 0.84 6 5.42 10.59 0.84 12 2.25 6 8.25 0 2.25"></polygon>
    </defs>
    <g id="ic_keyboard_arrow_down" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g>
        <g id="Icon-24px" transform="translate(6.000000, 8.000000)">
          <mask fill="white">
            <use xlink:href="#path-angle-down"></use>
          </mask>
          <use [attr.fill]="color" xlink:href="#path-angle-down"></use>
        </g>
        <polygon id="Shape" points="0 0.25 24 0.25 24 24.25 0 24.25"></polygon>
      </g>
    </g>
  </svg>
</div>
