<div class="ss-progress-holder">
  <div *ngIf='!noTicks' class="step-ticks">
    <div *ngFor="let step of steps">
      {{getStepValue(step)}}
    </div>
  </div>
  <input type="range" [ngClass]="getClasses()" [step]="step" [min]="min" [max]="max" name="value"
    (change)="onSeek($event)" 
    (input)="onSeek($event)" 
    />
  <div class="ss-progress-actual ss-progress-step">
    <div class="ss-progress-bar" #progressBar>
      <span class="barline" [style.width]="getProgress() + '%'"></span>
    </div>
  </div>
  <span class="ss-seeker ss-seeker-step" #seeker [style.left]="getProgress() + '%'"></span>
</div>
