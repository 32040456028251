<div class="container container-sm user-settings">
  <div class="ssc-list-header">
    <h1>Settings</h1>
  </div>
  <div class="row">
    <div class="col-md-9 col-12">
      <section class="settings-section">
        <div class="settings-item" [routerLink]="['/d/settings/subscription']">
          <span>Subscriptions & Payments</span>
          <i class="fa fa-caret-right"></i>
        </div>
        <div class="settings-item" [routerLink]="['/d/settings/profile']">
          <span>Update email/password</span>
          <i class="fa fa-caret-right"></i>
        </div>
        <!-- Hide Lyrics Color selection option
        <div class="settings-item" [routerLink]="['/d/settings/lyrics']">
          <span>Lyrics color</span>
          <i class="fa fa-caret-right"></i>
        </div> -->
        <div class="settings-item" [routerLink]="['/d/settings/blocked']">
          <span>Blocked members</span>
          <i class="fa fa-caret-right"></i>
        </div>
      </section>
      <section class="settings-section settings-grid" *ngIf="settings">
        <div class="settings-item">
          <span>Profile visibility</span>
          <div class="chk-group">
            <app-form-check [value]="settings?.profile_visibility" label="Public" (changed)="handleChangeVisibility($event)"></app-form-check>
            <app-form-check [value]="!settings?.profile_visibility" label="Members only" (changed)="handleChangeVisibility(!$event)"></app-form-check>
          </div>
        </div>
        <div class="settings-item" [ngClass]="item.class" *ngFor="let item of settingsGroup">
          <app-form-switch
            [label]="item.label"
            [showGold]="item.gold"
            [value]="item.key ? settings[item.key] : false"
            [disabled]="item.disabled"
            (changed)="handleChangeSetting(item.key, $event)"
          ></app-form-switch>
        </div>
      </section>
      <h4 class="settings-subtitle">NOTIFICATIONS</h4>
      <section class="settings-section settings-grid">
        <div class="settings-item" [ngClass]="item.class" *ngFor="let item of notificationsGroup">
          <app-form-switch
            [label]="item.label"
            [value]="settings?.notifications"
            (changed)="handleChangeNotification($event)"
          ></app-form-switch>
        </div>
      </section>
    </div>
    <!-- <div class="col-md-3 col-12">
      <h4 class="settings-subtitle">LYRICS COLOR</h4>
      <app-settings-lyric [colors]="colors" (changed)="handleColorChange($event)"></app-settings-lyric>
    </div> -->
  </div>
  <div class="page-actions">
    <button class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray"
      (click)="handleCancel()">CANCEL</button>
    <button class="btn btn-primary" (click)="handleSave()" [disabled]="saving">
      <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="saving"></i>
      SAVE
    </button>
  </div>
</div>
