import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb-item.component.html',
    styleUrls: ['./breadcrumb-item.component.scss']
})
export class BreadcrumbItemComponent implements OnInit {

    @Input() title = '';
    @Input() link = '';

    constructor() {
    }

    ngOnInit() {
    }

}
