<div class="ss-search-users">
  <div class="ss-glow-box ss-form-dropdown">
    <label>{{ label }}</label>
    <div class="ss-form-dropdown-block" ngbDropdown #myDrop="ngbDropdown">
      <button
        class="btn"
        id="dropdownUserType"
        ngbDropdownAnchor
        (focus)="myDrop.open()"
      >
        {{ userType == 'me' ? userOwnText : 'Another Member' }}
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownUserType">
        <button ngbDropdownItem (click)="changeUserType('me')">
          {{ userOwnText }}
        </button>
        <button ngbDropdownItem (click)="changeUserType('other')">
          Another Member
        </button>
      </div>
    </div>
  </div>

  <div class="ss-glow-box recipient-list-box" *ngIf="userType === 'other'">
    <div class="recipient-box-header">
      <span>Recipients</span>
      <span class="recipient-add" (click)="openSearch()"
        ><i class="fa fa-plus"></i>Add member</span
      >
    </div>
    <div class="recipient-box-content">
      <div class="recipient-empty" *ngIf="!selectedUsers.length">
        No members selected
      </div>
      <div
        class="user-list"
        *ngIf="selectedUsers.length"
        [perfectScrollbar]="{}"
      >
        <div
          *ngFor="let user of selectedUsers"
          class="user-info"
          [ngClass]="{ 'user-invalid': isInvalidUser(user) }"
        >
          <app-ss-user
            [user]="user"
            [showStatus]="false"
            [showLevel]="false"
            [noLink]="true"
            [isSmall]="true"
            [disabled]="isInvalidUser(user)"
          >
          </app-ss-user>
          <app-icon-trash
            color="#F94678"
            size="18"
            (click)="deleteUser(user)"
          ></app-icon-trash>
        </div>
      </div>
    </div>
  </div>

  <app-search-member-modal    
    #searchModal
    [noRecurring]="noRecurring"
    [noColors]="noColors"
    [single]="single"
    [planType]=planType
    [selectedUsers]="selectedUsers"
    (selectionChanged)="handleUserSelected($event)"
  ></app-search-member-modal>
</div>
<app-warning-modal #confirmation></app-warning-modal>
