import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-mobile-menu',
  templateUrl: './icon-mobile-menu.component.html',
  styleUrls: ['./icon-mobile-menu.component.scss']
})
export class IconMobileMenuComponent implements OnInit {
  @Input() color: string = '#262628';
  @Input() size: number = 18;

  constructor() { }

  ngOnInit() {
  }

}
