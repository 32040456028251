<div class="container">
<div class="favorite-page ssc-wrapper">
  <div class="row">
    <div class="col-12 col-md-6">
      <app-ss-featured-grid 
        title="Favorite Members"
        cols="1"
        [hasSeeMore]="true"
        seeMorePage="MyListenMore:profile-fav-mem"
        [userIdFromProfile]="userId"
        [sortOptions]="memberSortOptions"
        [list]="data.member.items"
        [loading]="data.member.loading"
        (onScrollEnd)="loadData('member', $event)"
        (onOrderBy)="resetDataObj('member');loadData('member', $event)"
      >
        <ng-template #itemContent let-item>
          <div class="ssc-list-item" class="ss-list--item {{ customClass }}">
            <app-ss-user [user]="item" title="Go to Profile Page: {{item.screen_name}}"></app-ss-user>
            <div class="song-actions">
              <app-icon-button (click)="onProfile(item.id)" size="40px" color="white" border="#909090" title="Go to Profile Page: {{item.screen_name}}">
                <app-icon-arrow-right></app-icon-arrow-right>
              </app-icon-button>
              <app-more-dot-menu title="Options">
                <div class="dropdown-menu-content">
                  <div (click)="favoriteMember(item)"  *ngIf="!item.favorited">Add to 'My Favorite Members'</div>
                  <div (click)="unfavoriteMember(item)" *ngIf="item.favorited">Remove from 'My Favorite Members'</div>
                  <!-- <div [routerLink]="['/d/private-message/inbox', item?.id]">Message User</div> -->
                  <div (click)="privateMessage(item?.id)">Message User</div>
                </div>
              </app-more-dot-menu>
            </div>
          </div>
        </ng-template>
      </app-ss-featured-grid>
      <app-vert-space height="40"></app-vert-space>
    </div>
    <div class="col-12 col-md-6">
      <app-ss-featured-grid 
        title="Favorite Recordings"
        [hasSeeMore] = "true"
        seeMorePage="MyListenMore:profile-fav-rec"
        [userIdFromProfile]="userId"
        [cols]="1"
        [checkOwnRecording]="true"
        [sortOptions]="playlistSortOptions"
        [list]="data.recording.items"
        [loading]="data.recording.loading"
        (onScrollEnd)="loadData('recording', $event)"
        (onOrderBy)="resetDataObj('recording');loadData('recording', $event)"
      >
        <ng-template #itemContent let-item>
          <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
            <app-ss-recording [recording]="item" (click)="onRecording(item)" title="Play"></app-ss-recording>
            <div class="song-actions">
              <app-icon-button (click)="onRecording(item)" size="40px" color="#58c7c9" title="Play">
                <app-icon-play color="white"></app-icon-play>
              </app-icon-button>
              <app-more-dot-menu title="Options">
                <div class="dropdown-menu-content">
                  <div (click)="unfavoriteRecording(item)" *ngIf="item.favorited">Remove from 'My Favorite Recordings'</div>
                  <div (click)="favoriteRecording(item)" *ngIf="!item.favorited">Add to 'My Favorite Recordings'</div>
                  <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                  <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                  <div (click)="handleSing(item.song)">Sing This Song</div>
                  <!-- <div (click)="handleDuet(item)">Duet / Jam</div> -->
                  <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                  <!-- <div *ngIf="profileService.userId == item.user_id" [routerLink]="['/d/my-recordings', item.id]">Edit Recording Info</div> -->
                  <div (click)="shareRecording(item)">Share this recording</div>
                  <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                </div>
              </app-more-dot-menu>
            </div>
          </div>
          <app-recording-stat [recording]="item"></app-recording-stat>
        </ng-template>
      </app-ss-featured-grid>
      <app-vert-space height="40"></app-vert-space>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-4">
      <app-ss-featured-grid 
        title="Favorite Playlists"
        cols="1"
        [sortOptions]="playlistSortOptions"
        [list]="data.playlist.items"
        [hasSeeMore]="true"
        seeMorePage="MyListenMore:profile-fav-pl"
        [userIdFromProfile]="userId"
        [loading]="data.playlist.loading"
        (onScrollEnd)="loadData('playlist', $event)"
        (onOrderBy)="resetDataObj('playlist');loadData('playlist', $event)"
      >
        <ng-template #itemContent let-item>
          <div class="ssc-list-item ssc-list-item-narrow">
            <app-ss-playlist [playlist]="item" (click)="onPlaylist(item)"></app-ss-playlist>
            <div class="ssc-list-actions">
              <app-icon-button (click)="onPlaylist(item)" size="40px" color="#58c7c9">
                <app-icon-play color="white"></app-icon-play>
              </app-icon-button>
              <app-more-dot-menu title="Options">
                <div class="dropdown-menu-content">
                  <div (click)="unfavoritePlaylist(item)" *ngIf="item.favorited">Remove from 'My Favorite Playlists'</div>
                  <div (click)="favoritePlaylist(item)" *ngIf="!item.favorited">Add to 'My Favorite Playlists'</div>
                  <div (click)="onEditPlaylist(item.id)" *ngIf="user && item.user_id == user.id">Edit</div>
                  <div (click)="onDeletePlaylist(item.id)" *ngIf="user && item.user_id == user.id">Delete</div>
                </div>
              </app-more-dot-menu>
            </div>
          </div>
        </ng-template>
      </app-ss-featured-grid>
      <app-vert-space height="40"></app-vert-space>
    </div>
    <div class="col-12 col-md-4">
      <app-ss-featured-grid 
        title="Favorite Artists"
        cols="1"
        [hasSeeMore]="true"
        seeMorePage="MyListenMore:profile-fav-artist"
        [userIdFromProfile]="userId"
        [sortOptions]="artistSortOptions"
        [list]="data.artist.items"
        [loading]="data.artist.loading"
        (onScrollEnd)="loadData('artist', $event)"
        (onOrderBy)="resetDataObj('artist');loadData('artist', $event)"
      >
        <ng-template #itemContent let-item>
          <div class="ssc-list-item">
            <app-ss-artist [artist]="item" (click)="onArtist(item)"></app-ss-artist>
            <div class="song-actions">
              <app-icon-button (click)="onArtist(item)" size="40px" color="white" border="#909090">
                <app-icon-arrow-right></app-icon-arrow-right>
              </app-icon-button>
              <app-more-dot-menu title="Options">
                <div class="dropdown-menu-content">
                  <div (click)="favoriteArtist(item)" *ngIf="!item.favorited">Add to 'My Favorite Artists'</div>
                  <div (click)="unfavoriteArtist(item)" *ngIf="item.favorited">Remove from 'My Favorite Artists'</div>
                </div>
              </app-more-dot-menu>
            </div>
          </div>
        </ng-template>
      </app-ss-featured-grid>
      <app-vert-space height="40"></app-vert-space>
    </div>
    <div class="col-12 col-md-4">
      <app-ss-featured-grid 
        title="Favorite Songs"
        cols="1"
        [sortOptions]="playlistSortOptions"
        [hasSeeMore]="true"
        seeMorePage="MySingMore:profile-fav-songs"
        [userIdFromProfile]="userId"
        [list]="data.song.items"
        [loading]="data.song.loading"
        (onScrollEnd)="loadData('song', $event)"
        (onOrderBy)="resetDataObj('song');loadData('song', $event)"
      >
        <ng-template #itemContent let-item>
          <div class="ssc-list-item">
            <app-ss-song [song]="item" (click)="handleSing(item)"></app-ss-song>
            <div class="song-actions">
              <app-icon-button (click)="handleSing(item)" size="40px" color="#58c7c9">
                <app-icon-mic color="white" size="16"></app-icon-mic>
              </app-icon-button>
              <app-more-dot-menu title="Options">
                <div class="dropdown-menu-content">
                  <div (click)="unfavoriteSong(item)">Remove from Favorites</div>
                  <div>View Recordings</div>
                  <div (click)="onArtist(item.artist)">View Artist Profile</div>
                  <div (click)="howOthersSingThis(item.id)">How Others Sing This</div>
                </div>
              </app-more-dot-menu>
            </div>
          </div>
        </ng-template>
      </app-ss-featured-grid>
      <app-vert-space height="40"></app-vert-space>
    </div>
  </div>
  <app-add-to-playlist #playlist></app-add-to-playlist>
  <app-purchase-gold-modal #goldcontent type="2"></app-purchase-gold-modal>
  <app-confirmation-modal #confirmation></app-confirmation-modal>
</div>

</div>