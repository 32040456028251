import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class InnercircleDataService {
    search$ = new Subject<string>();
    membersInvited$ = new Subject<any>();
    adminsInvited$ = new Subject<any>();
    searchAdmin$ = new Subject<string>();
    addIcUsers$ = new Subject<any>();
    private sideBarOpts$ = new Subject();

    constructor() {
    }

    getSideOpts() {
        return this.sideBarOpts$;
    }

    getAddIcUsers() {
        return this.addIcUsers$;
    }

    updateSidebarOpts(data: any) {
        this.sideBarOpts$.next(data);
    }

    updateAddIcUsers(data: any) {
        this.addIcUsers$.next(data);
    }

}
