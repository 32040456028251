<div id='seeMoreContainer'>
  <div class='headerContainer'>
    <div class='title'>
      <app-icon-button color="white" (click)="back()" >
      <app-icon-arrow-left color="gray"></app-icon-arrow-left>
      </app-icon-button>
      <h4>Sing - Open Duets</h4>
    </div>
    <div class='moreFilters'>
      <span>Video / Audio:
        <!-- <input style="margin-left: 10px;" type="checkbox"  #filterByVideoCheckBox [checked]="filterByVideo" (change)="videoClick(filterByVideoCheckBox.checked)"  > -->
        <select style="width: 6.5rem;"  name="video-dropdown" id="video-dropdown" (change)="videoClick($event.target.value)"> 
          <option *ngFor="let item of videoOptions;let e = index" [selected]= "e==selectedVideoIndex" [value]="e">{{item}}</option>
          </select>
      </span>
    </div>
  </div>

  <app-ss-featured-grid
  [scrollable]="false"
  title="Sing - Open Duets"
  seeMorePage="SingOpenDuets"
  [hasSeeMore] = "false"
  [cols]="3"
  [colsLg]="3"
  [colsMd]="2"
  [colsSm]="1"
  [extendedSize] = "true"
  [checkOwnRecording]="true"
  [userId]="profileService.userId"
  [sortOptions]="recordingSortOptions"
  [list]="openDuetsData.items"
  [loading]="openDuetsData.loading"
  (onOrderBy)="resetDataObj(openDuetsData);setSortFields($event);onScrollEndHandle()"
  >
  <ng-template #itemContent let-item>
    <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
      <app-ss-recording [linkActive]="false" [recording]="item"></app-ss-recording>
      <div class="song-actions">
        <app-icon-button style="margin-right: -0.2rem" (click)="handleDuet(item)" [shadow]="true" role="button" size="40px" color="#58c7c9"
          title="Record">
          <app-icon-mic color="white" size="16"></app-icon-mic>
        </app-icon-button>
        <app-more-dot-menu>
          <div class="dropdown-menu-content">
            <div *ngIf="!item.favorited" (click)="favRecording(item)">Add to Favorites</div>
            <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Favorites</div>
            <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
            <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
            <div [routerLink]="['/d/profile/', item?.users[0]?.id]">Member Profile</div>
            <div [routerLink]="['/d/listen/', item?.id]">Listen to Recording</div>
            <div *ngIf="profileService.userId == item.user_id" [routerLink]="['/d/my-recordings', item.id]">Edit Recording Info</div>
            <!-- <div (click)="shareRecording(item)">Share this recording</div> -->
            <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
          </div>
        </app-more-dot-menu>
      </div>
    </div>
    <!-- <app-recording-stat [recording]="item"></app-recording-stat> -->
  </ng-template>
  </app-ss-featured-grid>
  <app-vert-space height="40"></app-vert-space>
  <div  id="pageNavContainer"  style="margin-bottom: 13px;">
    <button *ngIf='currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
    <p >{{currentPage}}</p>
    <button *ngIf='openDuetsData.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
  </div>
</div>