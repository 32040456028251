import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-fullscreen',
  templateUrl: './icon-fullscreen.component.html',
  styleUrls: ['./icon-fullscreen.component.scss']
})
export class IconFullscreenComponent implements OnInit {
  @Input() color = '#262628';
  @Input() size = 18;

  constructor() { }

  ngOnInit() {
  }

}
