<div class="card">
    <div class="simple-list--title" *ngIf="title">
        <h3>{{title}}</h3>
    </div>
    <ul class="simple-list list-group list-group-flush">
        <li class="list-group-item" *ngFor="let listItem of list">
            <span class="simple-list__text">
                {{listItem.text}}
            </span>
            <span class="simple-list__secondary pull-right">
                {{listItem.secondaryText}}
            </span>
        </li>
    </ul>
</div>
