import { Component, OnInit, NgZone, HostListener, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Location } from '@angular/common';
import { Data, SongService, ProfileService, SingService, RecordingService, UtilService } from '@app/core';
import { environment } from '@env/environment.staging';
import { ToastrService } from 'ngx-toastr';
//import * as rec from '../../../../recorder-lib/start.js';
//import { initiateRecorder } from "../../../../recorder-lib/start"
import Bugsnag from '@bugsnag/js';

declare function initiateRecorder(  workerPath,
  videoRecorder,
  videoReviewer,
  videoDuet,
  canvasLyric,
  canvasMeter,
  audioMusic,
  audioVoice,
  base,
  songData,
  videoDimensions,
  colors,
  config,
  isJam)

  declare var Done;
let workerPath = '/assets/scripts/soundtouch-worklet.js';

@Component({
  selector: 'app-sing-recorder',
  templateUrl: './sing-recorder.component.html',
  styleUrls: ['./sing-recorder.component.scss']
})
export class SingRecorderComponent implements OnInit, OnDestroy {
  @HostListener('window:keydown', ['$event'])
  keyEvent(e: KeyboardEvent) {
    if(e.keyCode == 32) {
      console.log(document.activeElement)
      if(document.activeElement.classList[0] != 'ss-input') {
        e.preventDefault();
        this.togglePlay();
      }
      // e.stopImmediatePropagation();
      // e.preventDefault();

    }
    if (e.keyCode == 76 && e.ctrlKey) { //toggle lyrics player if ctl + l is pressed
      this.lyricsToggle = !this.lyricsToggle
      if(this.lyricsToggle == true) {
        console.log('turning lyrics back on')
        localStorage.removeItem("noLyrics")
        this.tryAgain() //reload the page to reinitiate the lyrics canvas
      } else {
        console.log('turning lyrics off')

        localStorage.setItem("noLyrics", "true")
      }
    }
  }

  @Input() backgroundColor = '#21243C';
  @ViewChild('videoRecorder') private videoRecorder: ElementRef;
  @ViewChild('videoReviewer') private videoReviewer: ElementRef;
  @ViewChild('videoDuet') private videoDuet: ElementRef;
  @ViewChild('videoHolder') private videoHolder: ElementRef;
  @ViewChild('audioMusic') private audioMusic: ElementRef;
  @ViewChild('audioVoice') private audioVoice: ElementRef;
  @ViewChild('lyric', { static: true }) private lyricDom: ElementRef;
  @ViewChild('micMeter', { static: true }) private micDom: ElementRef;
  @ViewChild('otherSingers', { static: true }) private otherSingersElem;
  @ViewChild('syncBar', { static: true }) private syncBarElem;
  @ViewChild('goldcontent', { static: true }) private goldModal;
  noCam = false;
  errorMessage;
  blueLimitReached = false;
  buttonReset = true;
  destroyed  = false;
  lyricsToggle = true;
  notLoaded = false;
  layer = 0;
  pitching = false;
  urlChanges = false;
  pitchReady = false;
  legacyDuet = false;
  authroized = true;
  recorder: any;
  song: any = null;
  recordingData: any = null;
  songId: any = 1;
  duration: any = 0;
  currentTime: any = 0;
  showLyricSpeedBar = false;
  lyricSpeed = 0;
  showControls = true;
  duet = false;
  showVolumeBar = false;
  showSyncBar = false;
  showPitchBar = false;
  UUID: any = null;
  pitch: any = 0;
  volume: any = 0.5;
  micVolume: any = 0.5;
  sync = 0;
  mmsb = true;
  loaded = false;
  subtitle = '';
  temp: any = {};
  recMode = '';
  audioSources = [];
  videoSources = [];
   reviewerLoaded = false;
   reviewerLoading = false;
  refreshing = false;
  countdown: any;
  countdownTimer: any;
  uploaded = false;
  part = null;
  paramData;
  recorderVersion = 4.0;
  soxProcessing = false;
  thePitch;
  noPermissions = false;
  restarting = false;
  startRecorder;
  scriptTag;
  pitchDisabled = false;
  grainSize = 0.14;
  overlap = 0.10;
  playerLoaded = false;


  // REVIEWER
  reviewer = false;

  finished = false;     // Review Mode

  buttons: any = {};
  params;
  constructor(
    private songService: SongService,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private singService: SingService,
    private toastr: ToastrService,
    private recordingService: RecordingService,
    private profileService: ProfileService,
    private location: Location,
    private router: Router,
    private data: Data
  ) {
   }
  loadScripts() {
      this.scriptTag = document.createElement('script');
      //this.scriptTag.addEventListener('onload', this.start());
      this.scriptTag.src = '../../../assets/scripts/start.js' + '?v=' + Date.now();
      this.scriptTag.id = 'start';
      this.scriptTag.type = 'text/javascript';
      this.scriptTag.async = false;
      this.scriptTag.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(this.scriptTag);

      return new Promise((res, rej) => {
        this.scriptTag.onload = function() {
          res(true);
        }
        this.scriptTag.onerror = function () {
          rej();
        }
      });
    // setTimeout(() => this.start(), 1000)

  }

  ngOnInit() {


    // setTimeout(()=>{
    //   if(!this.loaded) {
    //     this.notLoaded = true;
    //   }
    // },35000)
    // document.addEventListener('keyup', event => {
    //   if (event.code === 'Space') {
    //     event.preventDefault()
    //     this.togglePlay();
    //   }
    // })

    this.loadScripts().then(() => {
      let scriptCheck = setInterval(()=>{
        if(Done){
          console.log('scripts are loaded!')
          this.start();
          clearInterval(scriptCheck);
        }
      }, 500)

    })
    .catch(() => {
      let msg = 'Script loading failed!';
      console.error(msg);
      Bugsnag.notify('sing-recorder error: ' + msg);
    });
  }

  start(){

      this.activatedRoute.data.subscribe(data => {
        console.log(data)
        this.paramData = data;
      });
      this.activatedRoute.queryParams.subscribe(params => {
        this.params = params;
        console.log(params)
        if(typeof params.pitch != 'undefined') {
          this.pitch = params.pitch
          this.urlChanges = true;
        }
      });
      this.activatedRoute.params.subscribe(params => {

        if (this.recorder) {
          console.log('*** close recorder');
          this.recorder.closeRecorder();
        }
        this.refreshing = true;
        setTimeout(() => {
          this.refreshing = false;
          this.refresh();
          this.resetButtons();
          this.showDefaultButtons();
          this.songId = params.id;
          this.loadSong();
        });
      });
  }
  ngOnDestroy() {
    console.log('destroying recorder')
    this.destroyed = true;
    if (this.authroized && this.recorder) {
      this.recorder.closeRecorder();
      this.pauseReviewing();
    }
    this.recorder = {};

    if(!this.uploaded) {
      window.location.reload();
    }
  }

  get disableMMSB() {
    return this.recordingWIP() ||
      this.practicingWIP() ||
      !this.recorder.micOn && !this.finished;
      // this.recorder.reviewer && this.recorder.reviewer.playing;
  }

  refresh() {
    this.buttonReset = true;
    this.duration = 0;
    this.currentTime = 0;
    this.showControls = true;
    this.showVolumeBar = false;
    this.showSyncBar = false;
    this.showPitchBar = false;
    this.loaded = false;
    this.subtitle = '';
    this.recMode = '';
    this.finished = false;
    this.mmsb = true;
    this.reviewer = false;
  }

  resetButtons() {

    this.temp.pitch = this.pitch;
    this.buttons = {
      record: false,
      practice: false,
      volume: false,
      pitch: false,
      pause: false,             // Pause All
      resume: false,            // Resume Practicing
      resumeRecording: false,   // Resume Recording
      review: false,
      sync: false,
      monitoring: false,
    };
  }

  showDefaultButtons() {
    this.buttons.record = true;
    this.buttons.practice = true;
    this.buttons.volume = true;
    if (this.layer <= 1) {
      this.buttons.pitch = true;
    }
    this.buttons.monitoring = true;
  }

  tryAgain() {
    let currentUrl;
    //window.location.href = `/?pitch=${this.pitch}&volume=${this.volume}&micVolume=${this.micVolume}`
    if(this.router.url.includes('?')) {
      currentUrl = this.router.url.substr(0, this.router.url.indexOf('?'))
    } else {
      currentUrl = this.router.url;
    }
    this.router.navigate([currentUrl], {queryParams: { pitch: this.pitch }});
    setTimeout(()=>{
      window.location.reload()
    }, 500)
  }

  async loadSong() {
    console.log('*** user', this.profileService.user);
    if(!this.profileService.user.gold) {
      console.log('user is not gold')
      this.pitchDisabled = true;
    }
    if (this.paramData.duet || this.paramData.jam) {
      this.recordingService.getDuetRecording(this.songId).subscribe(result => {
        this.duet = true;
        console.log('grabbing duet')
        this.song = result['song'];
        console.log(this.song)
        // if (this.song.song.gold && !this.profileService.user.gold ) {
        //   this.goldModal.open();
        //   this.authroized = false;
        //   this.resetButtons();
        //   return;
        // }

        this.recordingData = result['record'];
        this.initRecorder();
      }, error => {
        if(error.status == 503) {
          console.log('if works')
          this.legacyDuet = true;
        }
        if(error.status == 412) {
          console.log('if works')
          this.blueLimitReached = true;
        }
      });
    } else {
      this.songService.singASong(this.songId).subscribe(result => {
        this.song = result;
        console.log(this.song)
        // if (this.song.gold && !this.profileService.user.gold) {
        //   this.goldModal.open();
        //   this.authroized = false;
        //   return;
        // }
        this.initRecorder();

      }, error => {
        console.log(error.status)
        if(error.status == 412) {
          console.log('if works')
          this.blueLimitReached = true;
        }
        console.log(error)
      })

    }
  }
  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }
  purchaseGold(){
    this.navigate(['/d/payments/membership'])
  }
  changeGrainSize(value) {
    this.recorder.player.changeGrainSize(value)
    this.grainSize = value;
  }
  changeOverLap(value) {
    this.recorder.player.changeOverLap(value);
    this.overlap = value;
  }
  initRecorder() {
    const config: any = {};
    const videoDeviceId = Cookie.get('recorder-video-device-id');
    const audioDeviceId = Cookie.get('recorder-audio-device-id');
    console.log("THIS IS IT: " + videoDeviceId);
    if (videoDeviceId) {
      config.videoDeviceId = videoDeviceId;
    }
    if (audioDeviceId) {
      config.audioDeviceId = audioDeviceId;
    }
    let colors = Cookie.get('lyric-colors');
    if (colors) {
      colors = JSON.parse(colors);
    } else {
      colors = undefined;
    }

    if (this.song.duet) {
      this.buttons.record = false;
      this.buttons.practice = false;
    }

    let song = this.song;
    if (this.paramData.duet || this.paramData.jam) {
      song = [this.song, this.recordingData];
    }
    console.log(song)

    const height = this.videoHolder.nativeElement.clientHeight;
    const width = this.videoHolder.nativeElement.clientWidth;
    const videoDimensions = {Width: width, Height: height};
    console.log('*** width', width, height);
    // this.videoHolder.nativeElement.style.width = height + 'px';
    // this.videoRecorder.nativeElement.width = height;
    // this.videoRecorder.nativeElement.height = height;
    // this.videoReviewer.nativeElement.width = height;
    // this.videoReviewer.nativeElement.height = height;
    // this.videoDuet.nativeElement.width = height;
    // this.videoDuet.nativeElement.height = height;
    console.log(environment.baseAPIUrl, environment.converterApiUrl);
    if (this.recorder) {
      this.recorder = {};
      console.log('recorder unloaded')
    }
    console.log(song)
    this.recorder = initiateRecorder(
      workerPath,
      this.videoRecorder.nativeElement,
      this.videoReviewer.nativeElement,
      this.videoDuet.nativeElement,
      (<HTMLInputElement>document.getElementById('lyricsCanvas')),
      this.micDom.nativeElement,
      this.audioMusic.nativeElement,
      this.audioVoice.nativeElement,
      environment.baseAPIUrl,
      song,
      videoDimensions,
      colors,
      config,
      this.paramData.jam
    );
    // Check the user's mic monitor preference

    this.recorder.addEventListener('timeupdate', (event, seconds) => {
      this.currentTime = parseFloat(seconds);
    });
    this.recorder.addEventListener('no_permissions', () => {
      console.log(this.recorder.errorMessage)
      this.errorMessage = this.recorder.errorMessage;
      this.loaded = true;
      this.noPermissions = true;
    });
    this.recorder.addEventListener('show_message', (event, message) => {
      console.error(message)
      if(message.type == 'error') {

        // this.toastr.error(message.message, 'Error',{
        //   timeOut: 10000,
        // });
      this.errorMessage = message.message;
      this.noPermissions = true;
      this.loaded = true;


      } else if(message.type == 'info'){
        this.toastr.info(message.message);
      }
    });
    this.recorder.addEventListener('parts_selected', (event) => {
      console.log(event, this.song);
      this.part = true;
      this.buttons.record = true;
      this.buttons.practice = true;
    });

    this.recorder.addEventListener('input_sources_ready', (event, data) => {
      if(this.recorder.noCam) {
        this.noCam = true;
      }
      this.audioSources = data.audio;
      this.videoSources = data.video;

      console.log('source', data);

    });

    this.recorder.addEventListener('ready', (event, duration) => {
      if(this.urlChanges) {
        this.onPitchChange(this.pitch)
      }
      if(localStorage.getItem('micVolume')) {
        console.log(localStorage.getItem('micVolume'))
        this.onMicVolumeChange(parseFloat(localStorage.getItem('micVolume')))
      }
      if(localStorage.getItem('volume')) {
        console.log(localStorage.getItem('volume'))
        this.onVolumeChange(parseFloat(localStorage.getItem('volume')))
      }
            //sticky camera toggle
            if(typeof localStorage.getItem('toggleCamera') != 'undefined') {
              console.log('setting sticky camera settings')
              console.log(localStorage.getItem('toggleCamera'))
              if (localStorage.getItem('toggleCamera') == 'false') {
                console.log('setting false')
                this.recorder.audioOnly();
              }
            }
      console.log(localStorage.getItem("noLyrics"))
      if(localStorage.getItem("noLyrics")) {
        this.lyricsToggle = false;
      }
      if (this.recorder.base.layer >= 0) {
        this.layer = this.recorder.base.layer;
        this.buttons.pitch = false;
      }
      if(this.lyricSpeed) {
        this.onLyricSpeedChange(this.lyricSpeed)
      }
      console.log("layer is " + this.layer);
      console.log('*** recorder ready', duration);
      if (!this.duration) {
        this.onSongLoaded(duration);
      }
    });
    this.recorder.addEventListener('mic_ready', () => {
      // console.log("the current local storage value for the mic is " + localStorage.getItem("micMonitor"))
      // console.log("checking user config")
      // if (localStorage.getItem("micMonitor") == "true") {
      //   console.log('the microphone is now turning on')
      //   this.recorder.startMic()
      // } else if (localStorage.getItem("micMonitor") == "false"){
      //   console.log('the microphone is now turning off')

      //   console.log(this.recorder.micOpen)
      //   console.log(this.recorder.micOn)
      //   this.recorder.pauseMic()
      // }
      // console.log(this.recorder.micOn);
      // if (!this.finished) {
      //   this.onChangeMMSB();
      // }
    });
    this.recorder.player.addEventListener('grainplayer_loaded', (event) => {
      if (this.thePitch) {
        this.recorder.changePitch(this.thePitch);
      }
      this.playerLoaded = true;
    });

    this.recorder.addEventListener('practice_finished', (event) => {
      this.restart();
    });

    this.recorder.addEventListener('recording_finished', (duration) => {

      this.stopRecording(false);
    });

    this.recorder.addEventListener('REVIEWER_CREATED', (event, payload) => {

      this.reviewer = true;
      this.recorder.reviewer.changeMicVolume(this.micVolume)
      this.recorder.reviewer.changeMusicVolume(this.volume)

      if (this.recorder.noCam){
        document.getElementById('reviewHolder').classList.add("reviewAudioOnly");
        this.videoReviewer.nativeElement.style.display = "none"
      }
     // this.onChangeMMSB();
      //this.recorder.pauseMic();
      console.log('--- reviewer created', payload);
      //this.reviewer = payload;
      // load previous user sync value
      let sync = parseInt(localStorage.getItem("sync"))
      if (sync) {
        this.sync = sync
        this.recorder.reviewer.sync(this.sync);
        this.syncBarElem.changeValue(this.sync);
      } else {
        this.recorder.reviewer.sync(-150);
        this.syncBarElem.changeValue(-150);
      }
      this.recorder.player.addEventListener('sox_loaded', () => {
        this.soxProcessing = false
        this.recorder.reviewer.pause();
      });
      this.recorder.reviewer.addEventListener('REVIEWER_READY', (event, duration) => {
        if (this.finished) {
          this.buttons.resume = true;
          // if (this.thePitch) {
          //   if (this.recorder.player.sox) {
          //     this.soxProcessing = false;
          //   } else {
          //     this.soxProcessing = true;
          //   };
          //   }
          }
      });
    });



    this.videoReviewer.nativeElement.addEventListener('timeupdate', e => {
      this.currentTime = e.target.currentTime;
      if (this.currentTime > this.duration) {
        this.currentTime = this.duration;
      }
    });

    // this.videoReviewer.nativeElement.addEventListener('play', ev => {
    //   this.recorder.reviewer.playStarted();
    // });

    this.videoReviewer.nativeElement.addEventListener('ended', ev => {
      console.log('ended', ev);
      this.buttons.pause = false;
      this.buttons.resume = true;
    });
  }

  enableTestSound() {
    if (this.recorder.reviewer) {
      this.recorder.reviewer.setTestVolume();
    }
  }

  onChangeMMSB(event?) {
    console.log('*** mmsb changed');
    if (this.finished) {
      this.recorder.reviewer.toggleMMSB();
      this.mmsb = this.recorder.reviewer.mmsb;
    }
  }

  getPoster() {
    if (this.song) {
      return this.song.photo;
    }
    return 'https://dev-singsnap.imgix.net/placeholders/placeholder_logo.png';
  }

  partsSelected() {
    return this.song && (!this.song.duet || this.song.duet && this.part);
  }

  toggleCamera() {
    if (this.recorder && !this.recorder.camOpen) {
      this.recorder.videoOn();
      localStorage.setItem('toggleCamera', 'true')
    } else {
      this.recorder.audioOnly();
      localStorage.setItem('toggleCamera', 'false')
    }
  }

  toggleMic(flag) {
    // if (this.recording()) {
    //   return;
    // }

    // if (flag) {
    //   localStorage.setItem("micMonitor", "true")
    //   console.log('mic has been currently set to turn on');
    //   this.recorder.startMic();
    // } else {
    //   localStorage.setItem("micMonitor", "false")
    //   console.log('mic has been currently set to turn off');
    //   this.recorder.pauseMic();
    // }
  }

  changeMicSource(deviceId) {
    if (this.recordingWIP()) {
      return;
    }
    Cookie.set('recorder-audio-device-id', deviceId);
    this.recorder.changeAudioDevice(deviceId);
  }

  changeCamSource(deviceId) {
    Cookie.set('recorder-video-device-id', deviceId);
    this.recorder.changeVideoDevice(deviceId);
  }
  togglePlay() {
    if(this.buttons.pause){
      this.pause();
    }
    else if (this.buttons.resume) {
      this.resume();
    }
  }
  pause() {
    if (this.finished) {
      this.pauseReviewing();
      this.buttons.pause = false;
      this.buttons.resume = true;
    } else if (this.recorder.practicing) {
      this.recorder.pausePracticing("e");
      this.resetButtons();
      this.buttons.restart = true;
      this.buttons.record = true;
      this.buttons.resume = true;
    } else if (this.recorder.recording){
      this.recorder.pauseRecording();
      this.resetButtons();
      this.buttons.resumeRecording = true;
      this.buttons.restart = true;
      this.buttons.review = true;
    }
  }

  resume() {
    if (this.finished) {
      this.startReviewing();
      this.buttons.resume = false;
      this.buttons.pause = true;
    } else if (!this.recorder.isPracticing()) {
      this.recorder.resumePracticing();
      this.resetButtons();
      this.buttons.pause = true;
      this.buttons.volume = true;
      if (this.layer <= 1) {
        this.buttons.pitch = true;
      }
      this.buttons.monitoring = true;
    }
  }

  recording() {
    return this.recorder && this.recorder.isRecording();
  }

  recordingWIP() {
    return this.recorder && this.recorder.isRecordingWIP();
  }

  practicingWIP() {
    return this.recorder && this.recorder.isPracticing();
  }

  resumeRecording() {
    this.recorder.resumeRecording();
    this.buttons.pause = true;
    this.buttons.resumeRecording = false;
    this.buttons.restart = false;
    this.buttons.review = false;
  }

  startRecording() {
    this.buttonReset = false;
    this.recMode = '';
    if (!this.loaded) {
      return;
    }
    this.resetButtons();
    this.countdown = 5;
    this.countdownTimer = setInterval(() => {
      this.countdown --;
      if (this.countdown === 0) {
        clearInterval(this.countdownTimer);
        this.countdownTimer = undefined;
        this.initiateRecording();
      }
    }, 1000);
  }

  initiateRecording() {
    this.recorder.startRecording();
    this.singService.startRecording(this.songId, this.paramData.duet || this.paramData.jam).subscribe(result => {
      this.UUID = result;
    });
    this.buttons.pause = true;
  }

  stopRecording(internal = true) {
    this.reviewerLoading = true;
    setTimeout(()=>{
      this.reviewerLoading = false;
      this.reviewerLoaded = true;
      setTimeout(()=>{this.reviewerLoaded = false;}, 2000)
    }, 1000)
    if (internal) {
      this.recorder.stopRecording();
      this.duration = this.currentTime;
    }
    this.finished = true;
    this.currentTime = 0;
    this.singService.stopRecording(this.songId, this.UUID, this.paramData.duet || this.paramData.jam).subscribe((result) => {
      console.log(result);
    });
    this.resetButtons();
    this.buttons.sync = true;
    // this.buttons.pitch = true;
    this.buttons.volume = true;
    this.buttons.resume = false;
  }

  restart() {
    this.pause()
    this.pauseReviewing()
    document.querySelector('.parts__selector').innerHTML = '';
    document.getElementById('reviewHolder').classList.remove("reviewAudioOnly");
    this.recorder.closeRecorder();
    this.reviewer = false;

    this.restarting = true;
    setTimeout(() => {
      this.refresh();
      this.resetButtons();
      this.showDefaultButtons();
      this.refreshing = true;
      setTimeout(() => {
        this.recorder.reviewer = {};
        this.recorder.player = {};
        this.refreshing = false;
        setTimeout(() => {
          this.initRecorder();
          this.restarting = false;

        });
      });
    }, 2000);
  }

  startPractice() {
    this.resetButtons();
    this.buttonReset = false;
    // this.countdown = 5;
    // this.countdownTimer = setInterval(() => {
    //   this.countdown --;
    //   if (this.countdown === 0) {
    //     clearInterval(this.countdownTimer);
    //     this.countdownTimer = undefined;
        if (this.currentTime === 0) {
          this.recorder.startPracticing();
        } else {
          this.recorder.resumePracticing();
        }

        this.buttons.volume = true;
        if (this.layer <= 1) {
          this.buttons.pitch = true;
        }
        this.buttons.monitoring = true;
        this.buttons.pause = true;
        this.recMode = 'practice mode';
    //   }
    // }, 1000);

  }

  startReviewing() {
    if (this.recorder.reviewer) {
      this.recorder.reviewer.play();
    }
  }

  pauseReviewing() {
    if (this.recorder.reviewer) {
      this.recorder.reviewer.pause();
    }
  }

  onSongLoaded(duration) {
    console.log('song loaded', duration);
    this.duration = parseFloat(duration.duration);
    this.loaded = true;
  }

  getDuration() {
    return this.utilService.formatDuration(this.duration);
  }

  getCurrentTime() {
    return this.utilService.formatDuration(this.currentTime);
  }

  onSeek(value) {
    this.pause();
    value = parseInt(value)
    value = Math.max(value, 1);
    if (this.recorder.isPracticing()) {
      this.resetButtons();
      this.buttons.restart = true;
      this.buttons.record = true;
      console.log('*** seeked');
      this.buttons.resume = true;
    }
    if (!this.finished) {
      this.recorder.seek(value);
    } else {
      this.recorder.reviewer.seek(value);
      this.buttons.pause = false;
      console.log('*** seek resumed');
      this.buttons.resume = true;
    }
    this.currentTime = parseFloat(value);
  }

  onVolumeClick() {
    this.showControls = false;
    this.showVolumeBar = true;
    this.subtitle = 'volume';
    this.temp.volume = this.volume;
    this.temp.micVolume = this.micVolume;
  }

  onVolumeChange(value) {
    value = parseFloat(value);
    this.volume = value
    this.recorder.changeMusicVolume(value);
    if (this.recorder.reviewer) {
      this.recorder.reviewer.changeMusicVolume(value);
    }
    localStorage.setItem('volume', value.toString())
  }

  onVolumeAccept() {
    this.showControls = true;
    this.showVolumeBar = false;
  }

  onVolumeCancel() {
    this.showControls = true;
    this.showVolumeBar = false;
    this.volume = this.temp.volume;
    this.micVolume = this.temp.micVolume;
    if (!this.finished) {
      this.recorder.changeMusicVolume(this.volume);
      this.recorder.changeMicVolume(this.micVolume);
    } else {
      this.recorder.reviewer.changeMicVolume(this.micVolume);
      this.recorder.reviewer.changeMusicVolume(this.volume);
    }
    localStorage.setItem('volume', this.volume.toString())
    localStorage.setItem('micVolume', this.micVolume.toString())


  }

  onSyncClick() {
    this.showControls = false;
    this.showSyncBar = true;
    this.subtitle = 'sync';
    this.temp.sync = this.sync;
    if (this.recorder.reviewer) {
      this.buttons.pause = false;
      this.buttons.resume = true;
    }
  }

  onSyncAccept() {
    this.showControls = true;
    this.showSyncBar = false;
    localStorage.setItem("sync", String(this.sync))
    if (this.recorder.reviewer) {
      this.buttons.pause = false;
      this.buttons.resume = true;
    }
  }

  onSyncCancel() {
    this.showControls = true;
    this.showSyncBar = false;
    this.buttons.pause = false;
    this.buttons.resume = true;
    if (this.sync !== this.temp.sync) {
      this.sync = this.temp.sync;
      this.syncBarElem.changeValue(this.sync);
      this.recorder.reviewer.sync(this.sync);
    }
  }

  onPitchClick() {
    this.showControls = false;
    this.showPitchBar = true;
    this.subtitle = 'pitch';
    this.temp.pitch = this.pitch;
  }

  onPitchAccept() {
    this.showControls = true;
    this.showPitchBar = false;

  }

  onPitchCancel() {
    this.showControls = true;
    this.showPitchBar = false;
  }

  onPitchChange(value) {

    if(this.recorder.practicing) {
      this.pause();
    }
    this.thePitch = value;
    this.pitch = value;
    this.temp.pitch = value;

    if (this.temp.pitch !== 0) {
      this.recorder.changePitch(this.temp.pitch);
      //this.pitching = true;
    }
  }
  onLyricSpeedClick() {
    this.showControls = false;
    this.showLyricSpeedBar = true;
    this.subtitle = 'lyric speed';
  }


  onLyricSpeedAccept() {
    this.showControls = true;
    this.showLyricSpeedBar = false;
  }

  onLyricSpeedChange(value) {
    value =  Math.round((value * 0.6) * 10) / 10 //rounds to one digit place
    //value = -value
    this.pause();
    this.lyricSpeed = value / 0.6;
    if(this.recorder.reviewer) {
      this.recorder.reviewer.changeLyricSpeed(value)
    } else {
      this.recorder.changeLyricSpeed(value)
    }

  }
  onMicVolumeChange(value) {
    value = parseFloat(value)
    this.micVolume = value;
    if (!this.finished) {
      this.recorder.changeMicVolume(value);
    } else {
      this.recorder.reviewer.changeMicVolume(value);
    }
    localStorage.setItem('micVolume', value.toString())
  }

  backClicked() {
    this.location.back();
  }

  onSyncChange(value) {
    value = parseInt(value)
    this.pause();
    this.sync = value;
    this.recorder.reviewer.sync(this.sync);
    this.buttons.pause = false;
    console.log('*** sync changed');
    this.buttons.resume = true;
  }

  upload() {
    if(this.uploaded) {
      return
    }
    const payload = this.recorder.reviewer.finishReview();
    console.log('payload', payload);
    payload.uuid = this.UUID;
    payload.song = this.song;
    payload.mmsb = this.mmsb;
    console.log(payload)
    // payload.payload.meta.latency -= 150;
    this.data.storage = payload;
    this.uploaded = true;
    this.router.navigate(['/d/sing-uploader']);
  }

  onOtherSingers() {
    this.otherSingersElem.open(this.songId);
  }
}
