import { Component, NgZone, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ss-song',
  templateUrl: './ss-song.component.html',
  styleUrls: ['./ss-song.component.scss']
})
export class SsSongComponent implements OnInit {
  @Input() song: any = {};
  @Input() noArtist = false;
  @Input() linkActive=true;
  @Input() loading = false;
  constructor(
    private router: Router,
    private ngZone: NgZone
    ) { }

  ngOnInit() {
    console.log(this.song)
  }

  public navigate(commands: any[]): void {
    if(this.linkActive){
      this.ngZone.run(() => this.router.navigate(commands)).then();
    }
  }

  navSong(id){
    this.navigate([`/recorder/${id}`]);
  }

  navArtist(id){
    this.navigate([`/d/artists/${id}`]);
  }

}
