import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ProfileService, UsersService } from '@app/core';
import { tap, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ss-search-and-block-users',
  templateUrl: './ss-search-and-block-users.component.html',
  styleUrls: ['./ss-search-and-block-users.component.scss'],
})
export class SsSearchAndBlockUsersComponent implements OnInit {
  @Output() searchChanged = new EventEmitter();
  @Output() blocked = new EventEmitter();

  search$ = new Subject<string>();
  page = 1;
  total = 1;
  perPage = 10;
  searchTerm = '';

  users$ = this.users.searchUsers(this.search$).pipe(
    tap(response => {
      this.page = response.current_page;
      this.total = response.total;
    }),
    map(response => response.data)
  );

  constructor(
    private users: UsersService,
    private profiles: ProfileService,
    private toast: ToastrService
  ) {}

  ngOnInit() {}

  handleSearchInput() {
    this.searchChanged.emit(this.searchTerm);
  }

  unblock(member) {
    this.profiles.unblock(member.id).subscribe(
      response => {
        if (response.success) {
          member.blocked = false;
          this.toast.success(response.message);
        } else {
          this.toast.warning(response.message);
        }
      },
      error => {
        this.toast.error(error.message);
      }
    );
  }

  block(member) {
    this.profiles.block(member.id).subscribe(
      response => {
        if (response.success) {
          member.blocked = true;
          this.toast.success(response.message);
        } else {
          this.toast.warning(response.message);
        }
      },
      error => {
        this.toast.error(error.message);
      }
    );
  }
}
