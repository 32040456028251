<div class="ss-user">
  <div class="ss-user--profile" [class.ss-user--profile-xs]="false">
    <img [src]="user.photo">
    <app-user-snapicon-carousel size="small" [icons]="getSnapicons()" *ngIf="getSnapicons().length"></app-user-snapicon-carousel>
    <!-- <app-user-online-icon size="small"></app-user-online-icon>  -->
  </div>
  <div class="ss-user--info">
    <app-ss-user-name [user]="user" [showStatus]="false" [showLevel]="showLevel"></app-ss-user-name>
  </div>
</div>
