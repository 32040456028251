import { Injectable } from '@angular/core';

import { Cookie } from 'ng2-cookies/ng2-cookies';


//import { Http } from '@angular/http';
import { HttpService } from './http.service';
import { environment } from '../../../environments/environment';

declare const Pusher: any;

@Injectable()
export class PusherService extends HttpService {
  pusher = null;
  userChannel = null;
  recordingId = null;

  constructor() {
    super();
    this.pusher = new Pusher('ac87a1b1bfc2b40c2625', {
      cluster: 'us2',
      authEndpoint: environment.baseAPIUrl + '/auth/pusher',
      auth: {
        headers: this.getRawJsonHeaders(),
      },
    });
    console.log('after');
    const userId = Cookie.get('UserId');
    this.userChannel = this.pusher.subscribe('private-user.' + userId);
  }

  subChannel(name) {
    return this.pusher.subscribe(name);
  }

  unsubChannel(name) {
    return this.pusher.unsubscribe(name);
  }

  getPrivateRecordingChannel(recordingId) {
    return this.pusher.subscribe(`private-recording-${this.recordingId}`);
  }
}
