<div class="body_home cl_boxCont">
    <div class="main">
        <div class="ssb-navbar">
                <span class="singsnap_logo" [routerLink]="['']">
                        <img src="../../../../assets/pictures/logo.png" alt="logo" class="logo"><span class="_singsnap">SingSnap</span>
                    </span>
            <span class="ssb-actions pull-right"><img (click)="isHamburgerVisible = !isHamburgerVisible"
                                                      [src]="isHamburgerVisible ? '../../../../assets/pictures/webSite/menu_black.png' : '../../../../assets/pictures/webSite/menu.png'"
                                                      alt="burger" class="burger_menu"></span>
            <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                                                                         [routerLink]="['/sign-up']">SIGN UP</a></span>
            <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                                                                         [routerLink]="['/login']">LOGIN</a></span>
        </div>
        <div (mouseout)="hideHamburger($event)" class="hamburger" *ngIf="isHamburgerVisible">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="ssb-navbar">

                    <span class="ssb-actions pull-right"><img (click)="isHamburgerVisible = !isHamburgerVisible"
                                                              [src]="isHamburgerVisible ? '../../../../assets/pictures/webSite/menu_black.png' : '../../../../assets/pictures/webSite/menu.png'"
                                                              alt="burger" class="burger_menu"></span>
                        <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                                                                                     [routerLink]="['/sign-up']">SIGN UP</a></span>
                        <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                                                                                     [routerLink]="['/login']">LOGIN</a></span>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 menu_content">
                    <span [routerLink]="['/main/home']"><span class="fat_line"></span> home</span><br>
                    <span [routerLink]="['/main/aboutUs']"><span class="fat_line"></span> about us</span><br>
                    <span [routerLink]="['/main/pricing']"><span class="fat_line"></span> pricing</span><br>
                    <span [routerLink]="['/main/blog']"><span class="fat_line"></span> blog</span><br>
                    <span [routerLink]="['/dashboard']"><span class="fat_line"></span> app</span><br>
                    <!-- hidding store from website -->
                    <!-- <span [routerLink]="['/main/shop']"><span class="fat_line"></span> store</span><br> -->
                    <span [routerLink]="['/main/support']"><span class="fat_line"></span> support</span><br>
                    <span [routerLink]="['/main/influencer']"><span class="fat_line"></span> influencer</span><br>
                    <span (click)="onLogOut()" *ngIf="userIsLoged"><span class="fat_line"></span> log out</span><br>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 social-links">
                    <img src="../../../../assets/pictures/webSite/hamburger_facebook.png" alt="">
                    <img src="../../../../assets/pictures/webSite/hamburger_youtube.png" alt="">
                    <img src="../../../../assets/pictures/webSite/hamburger_twitter.png" alt="">
                    <img src="../../../../assets/pictures/webSite/hamburger_instagram.png" alt="">
                </div>
            </div>
        </div>
        <div class="main_content">
            <div>
                <span class="the_songs_that">The stage is waiting,</span>
                <span class="sets_your_stage">Are you coming on?</span>
                <span class="scroll_main"><i class="fa fa-long-arrow-down" aria-hidden="true"></i></span>
            </div>

        </div>
    </div>
    <div class="second">
        <div>
            <span class="start_now">Start now to sing and share</span>
            <span class="songs_free">your first five songs free</span>
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div class="card text-center pricing_card_left">
                        <div class="card_header">
                            <div>
                                <div class="start_here">Start Here</div>
                                <div class="free">Free</div>
                            </div>
                        </div>
                        <div class="card_block">
                            <div class="card_block_text">

                                <div class="black">1 free song unlock</div>
                                <div class="black">Access up to 1500 songs</div>
                                <div class="black">Record and store up to five songs</div>
                                <div>Original content - upload only</div>
                                <div>Key changer</div>
                                <div>Studio sound mixer</div>
                                <div>Split Screen duet</div>
                                <button type="submit"
                                        class="btn start_with_this" [routerLink]="['/sign-up']">START WITH THIS
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div class="card text-center pricing_card_right">
                        <div class="card_header">
                            <div>
                                <div class="sing_star">Be a Sing Star</div>
                                <div class="price_per_month"><span class="dollar">$</span>9.99<span
                                    class="month">/MONTH</span></div>
                            </div>
                        </div>
                        <div class="card_block">
                            <div class="card_block_text">
                                <div>1 free song unlock</div>
                                <div>Access up to 1500 songs</div>
                                <div>Record and store up to five songs</div>
                                <div>Original content - upload only</div>
                                <div>Key changer</div>
                                <div>Studio sound mixer</div>
                                <div>Split Screen duet</div>
                                <button type="submit"
                                        class="btn be_sing_star" [routerLink]="['/sign-up']">BE A SING STAR
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
