import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  NgZone,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {
  GenresService,
  UsersService,
  RecordingService,
  UtilService,
  ProfileService,
  EventService,
  HelperService,
  Data,
  PlaylistService
} from '@app/core';
import { timeout } from 'rxjs-compat/operator/timeout';
import { EmailNotificationsService } from '@app/core/services/email-notifications.service';

@Component({
  selector: 'app-my-listen-more',
  templateUrl: './my-listen-more.component.html',
  styleUrls: ['./my-listen-more.component.scss']
})
export class MyListenMoreComponent implements OnInit, OnDestroy {
  @ViewChild('playlist') private playlistElem;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  recording;
  trendingRecordings$: any;
  recommendedRecordings$;
  trendingRecordings = [];
  genreSortOptions = this.util.recordingSortOptions;
  @ViewChild('goldcontent') private goldModal;
  currentPage = 1;
  itemsPerPage = 30;
  pageTitle="";
  pageHeader="";
  getDataAPI:any ;
  showSeeMorePageType="";
  user: any;
  profileFavsType = "";
  userIDfromProfieFavs = 0;
  userNamefromProfieFavs = "";
  memberSortOptions = this.util.memberSortOptions;
  recordingSortOptions = this.util.recordingSortOptions;
  playlistSortOptions = this.util.playlistSortOptions;
  artistSortOptions = this.util.artistSortOptions;
  profileUserName="";
  query = '';
  isPrivate = false;
  isProfilePlaylist = false;
  isProfileFans = false;
  isPlaylistTypePublic = false;
  isPrivatePlaylist = false;
  
  //Profile Favs
  profileFavMember: any = {
    ...this.util.generateGridData(),
    sort: this.memberSortOptions[0],
    fetch: this.profileService.getUserFavoriteUsers.bind(this.profileService),
  };

  profileFavRec: any = {
    ...this.util.generateGridData(),
    sort: this.playlistSortOptions[0],
    fetch: this.profileService.getUserFavorites.bind(this.profileService),
  };

  profileFavPlaylist: any = {
    ...this.util.generateGridData(),
    sort: this.playlistSortOptions[0],
    fetch: this.profileService.getUserFavorites.bind(this.profileService),
  };

  profileFavArtist: any = {
    ...this.util.generateGridData(),
    sort: this.artistSortOptions[0],
    fetch: this.profileService.getUserFavorites.bind(this.profileService),
  };

  //My Favs
  favoriteData: any = {
    ...this.util.generateGridData(),
    fetch: this.profileService.getFavoriteRecordings.bind(this.profileService),
    sort: this.recordingSortOptions[0],
  };

  artists: any = {   
    ...this.util.generateGridData(),
    fetch: this.profileService.getFavoriteArtists.bind(this.profileService),
    sort: this.artistSortOptions[0],
  };

  members: any = {   
    fetch: this.profileService.getFavoriteUsers.bind(this.profileService),
    ...this.util.generateGridData(),
    sort: this.memberSortOptions[0],
  };

  playlist: any = {   
    ...this.util.generateGridData(),
    fetch: this.profileService.getFavoritePlaylists.bind(this.profileService),
    sort: this.playlistSortOptions[0],
  };

  playlistPublic: any = {   
    ...this.util.generateGridData(),
    fetch: this.playlistService.getPlaylists.bind(this.profileService),
    sort: this.playlistSortOptions[0],
  };

  playlistPrivate: any = {   
    ...this.util.generateGridData(),
    fetch: this.playlistService.getPlaylists.bind(this.profileService),
    sort: this.playlistSortOptions[0],
  };

  profilePlaylist: any = {   
    ...this.util.generateGridData(),
    fetch: this.playlistService.getUserPlaylists.bind(this.profileService),
    sort: this.playlistSortOptions[0],
  }; 

  fans: any = {   
    ...this.util.generateGridData(),
    fetch: this.profileService.getUserFans.bind(this.profileService),
    sort: this.memberSortOptions[0],
  };

  data = {
    artist: {
      ...this.util.generateGridData(),
      sort: this.artistSortOptions[0],
    },
    member: {
      ...this.util.generateGridData(),
      sort: this.memberSortOptions[0],
      fetch: this.profileService.getUserFavorites.bind(this.profileService),
    },
    song: {
      ...this.util.generateGridData(),
      sort: this.playlistSortOptions[0],
    },
    recording: {
      ...this.util.generateGridData(),
      sort: this.playlistSortOptions[0],
    },
    playlist: {
      ...this.util.generateGridData(),
      sort: this.playlistSortOptions[0],
    },
    fans: {
      ...this.util.generateGridData(),
      sort: this.memberSortOptions[0],
    }, 
  };

  constructor(
    private recordingService: RecordingService,
    private genreService: GenresService,
    public profileService: ProfileService,
    private usersService: UsersService,
    private helperService: HelperService,
    private eventService: EventService,
    private toastService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private util: UtilService,
    private ngZone: NgZone,
    private location: Location,
    private dataService: Data,
    private playlistService: PlaylistService,
    private emailNotificationsService: EmailNotificationsService,
  ) {
    this.recordingSortOptions = this.util.recordingSortOptions;
    this.favoriteData.sort = this.util.recordingSortOptions[0];
  }

  ngOnInit() {
    this.profileUserName = this.dataService.profileUserName;
    this.route.queryParams.subscribe(params => {
      if(typeof params.u != 'undefined') {
        this.userIDfromProfieFavs = params.u;
      }
    });

      this.route.queryParams.subscribe(params => {
        if(typeof params.p != 'undefined') {

        switch (params.p)
        {
          case 'fav-mem':
            this.pageTitle = "My Favorites - Favorite Members";
            this.getDataAPI = this.members;
          break;    
          case 'profile-fav-mem':
            this.pageTitle = "Profile - Favorite Members";
            this.pageHeader = "(" + this.profileUserName +  ") - Favorite Members";
            this.getDataAPI = this.profileFavMember;
            this.profileFavsType = "member";
          break;       
        
          case 'fav-rec':
            this.pageTitle = "My Favorites - Favorite Recordings";
            this.getDataAPI = this.favoriteData;
          break;
          case 'profile-fav-rec':
            this.pageTitle = "Profile - Favorite Recordings";
            this.pageHeader = "(" + this.profileUserName +  ") - Favorite Recordings";
            this.getDataAPI = this.profileFavRec;
            this.profileFavsType = "recording";
          break;

          case 'fav-artist':
            this.pageTitle = "My Favorites - Favorite Artists";
            this.getDataAPI = this.artists;
          break;
          case 'profile-fav-artist':
            this.pageTitle = "Profile - Favorite Artists";
            this.pageHeader = "(" + this.profileUserName +  ") - Favorite Artists";
            this.getDataAPI = this.profileFavArtist;
            this.profileFavsType = "artist";
          break;       

          case 'profile-pl':
            this.pageTitle = "Profile - Playlists";
            this.getDataAPI = this.profilePlaylist;
            this.isProfilePlaylist = true;
          break;

          case 'fav-pl':
            this.pageTitle = "My Favorites - Favorite Playlists";
            this.getDataAPI = this.playlist;
          break;

          case 'pl-fav':
            this.pageTitle = "My Playlists - Favorites";
            this.getDataAPI = this.playlist;
          break;

          case 'pl-public':
            this.pageTitle = "My Playlists - Public";
            this.getDataAPI = this.playlistPublic;
            this.isPlaylistTypePublic = true;
            this.isPrivatePlaylist = false;
          break;

          case 'pl-private':
            this.pageTitle = "My Playlists - Private";
            this.getDataAPI = this.playlistPublic;
            this.isPlaylistTypePublic = true;
            this.isPrivatePlaylist = true;
          break;          

          case 'profile-fav-pl':
            this.pageTitle = "Profile - Favorite Playlists";
            this.pageHeader = "(" + this.profileUserName +  ") - Favorite Playlists";
            this.getDataAPI = this.profileFavPlaylist;
            this.profileFavsType = "playlist";
          break;  

          case 'fans':
            this.pageTitle = "Fans";
            this.pageHeader = "(" + this.profileUserName +  ") - Fans";
            this.getDataAPI = this.fans;
            this.isProfileFans = true;
          break;


        }
        this.showSeeMorePageType = params.p;
        }
      });




      this.route.queryParams.subscribe(params => {
        if(typeof params.page != 'undefined') {
          this.currentPage = params.page
        }
      });
    
      if (this.profileFavsType.length){
        if(this.profileFavsType === 'member'){
          this.onScrollEndHandleProfileFavsMember(this.getDataAPI, null);  
        } else {
         this.onScrollEndHandleProfileFavs(this.getDataAPI, null);
        }
      } else if (this.isProfilePlaylist){
          this.onScrollEndHandleProfilePlaylists(this.getDataAPI, null);
      } else if (this.isProfileFans){
          this.onScrollEndHandleProfilePlaylists(this.getDataAPI, null);
      } else if (this.isPlaylistTypePublic){
          this.onScrollEndHandlePlaylistPublic(this.getDataAPI, null);
      }else {
          this.onScrollEndHandle(this.getDataAPI, null); 
      }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkMine(item){
    return item.favorited;
  }

  back(){
    window.history.back();
  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  navListen(id){
    this.navigate([`/d/listen/${id}`]);
  }

  navEditRec(id){
    this.navigate([`/d/my-recordings/${id}`]);
  }

  navProfile(id){
    this.navigate([`/d/profile/${id}`]);
  }

  navArtists(id){
    this.navigate([`/d/artists/${id}`]);
   }

   navMessage(id){
    this.navigate([`/d/private-message/inbox/${id}`]);
  }

  onPlayPlaylist(playlist) {
    if (!playlist.recordings_count) {
      this.toastService.error('No recordings!');
      return;
    }
    this.playlistService.playPlaylist(playlist.id).subscribe(() => { });
    this.router.navigate(['/d/play/', `${playlist.id}`]);
  }

  handleDuet(duet) {
    console.log(duet);
    if (duet.song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    this.router.navigate(['/duet-recorder', duet.id]);
  }

  favRecording(recording) {
    this.recordingService.fav(recording.id).subscribe(response => {
      if (response.success) {
        recording.favorited = true;
        this.toastService.success('Recording was added to favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  unFavRecording(recording) {
    this.recordingService.unfav(recording.id).subscribe(response => {
      if (response.success) {
        recording.favorited = false;
        this.toastService.success('Recording was removed from favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  unfavoriteMember(profile) {
    this.profileService.unfavoriteMember(profile.id).subscribe(response => {
      if (response.success) {
         profile.favorited = true;
         this.toastService.success(
          response.model.screen_name + ' removed from favorites.'
        );
      } else {
        this.toastService.error(response.message);
      }
    });
  }
  
  favoriteMember(profile) {
    this.profileService.favoriteMember(profile.id).subscribe(response => {
      if (response.success) {
        profile.favorited = true;
        this.toastService.success(
         response.model.screen_name + ' added to favorites.'
       );
       if (profile.newsletter){
         // this.emailNotificationsService.emailFavoriteNotification(profile.email, profile.screen_name, this.user.screen_name);
          this.emailNotificationsService.emailFavoriteNotificationV2(profile.email, profile.screen_name, this.user.screen_name, this.user.id, this.user.photo.replace('https://', ''))
        }
     } else {
       this.toastService.error(response.message);
     }
    });
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  setPageParam() {
    this.location.replaceState(this.location.path().split('?')[0], '');
    this.location.replaceState(this.location.path() + `?page=${this.currentPage}`);
    this.location.replaceState(this.location.path() + `&p=${this.showSeeMorePageType}`);
    if(this.userIDfromProfieFavs > 0){
      this.location.replaceState(this.location.path() + `&u=${this.userIDfromProfieFavs}`);
    }
  }

  onScrollEndHandle(dataMyFav, sort) {
    if (!dataMyFav.fetch) {
      return;
    }
    if (sort) {
      dataMyFav.sort = sort;
    }

    if (!dataMyFav.loading && dataMyFav.lastPage) {
     dataMyFav.loading = true;
      this.detectChanges();
      dataMyFav.fetch(this.currentPage, this.itemsPerPage, dataMyFav.sort.field, dataMyFav.sort.asc).subscribe(
        response => {
          dataMyFav.currentPage =  this.currentPage;
          dataMyFav.items = dataMyFav.items.concat(response.data);
          dataMyFav.lastPage = response.next_page_url;
          dataMyFav.loading = false;
          this.setPageParam();
          this.detectChanges();
        },
        error => {
          this.toastService.error(error.message);
        }
      );
    }
  }

  getNextSongs() {
    let dataMyFav = this.getDataAPI;
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.filter = {};
    }
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.decade = '';
    }

    if (!dataMyFav.loading && dataMyFav.lastPage) {
      dataMyFav.loading = true;
      this.currentPage++;
      this.detectChanges();
      dataMyFav.fetch(this.currentPage, this.itemsPerPage, dataMyFav.sort.field, dataMyFav.sort.asc).subscribe(
          response => {
            dataMyFav.currentPage = this.currentPage;
            dataMyFav.items = response.data;
            dataMyFav.lastPage = response.next_page_url;
            dataMyFav.loading = false;
            window.scrollTo(0, 0);
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  getPrevSongs() {
    let dataMyFav = this.getDataAPI;
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.filter = {};
    }
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.decade = '';
    }

    if (!dataMyFav.loading && dataMyFav.currentPage != 1) {
      dataMyFav.loading = true;
      this.currentPage--;
      this.detectChanges();
      dataMyFav.fetch(this.currentPage, this.itemsPerPage, dataMyFav.sort.field, dataMyFav.sort.asc).subscribe(
          response => {
            dataMyFav.currentPage = this.currentPage;
            dataMyFav.items = response.data;
            dataMyFav.lastPage = response.next_page_url;
            dataMyFav.loading = false;
            window.scrollTo(0, 0);
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  onScrollEndHandleProfileFavs(data, sort) { 
    if (!data.fetch) {
      return;
    }
    if (sort) {
      data.sort = sort;
    }  
    if (!data.loading && data.lastPage) {
      data.loading = true;
      this.detectChanges(); 
      data.fetch(this.userIDfromProfieFavs, this.profileFavsType, this.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc).subscribe(
        response => {
          data.currentPage =  this.currentPage;
          data.items = data.items.concat(response.data);
          data.lastPage = response.next_page_url;
          data.loading = false;
          this.setPageParam();
          this.detectChanges();
        },
        error => {
          this.toastService.error(error.message);
        }
      );
    }
  }

  getNextSongsProfileFavs() {
    let dataMyFav = this.getDataAPI;
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.filter = {};
    }
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.decade = '';
    }

    if (!dataMyFav.loading && dataMyFav.lastPage) {
      dataMyFav.loading = true;
      this.currentPage++;
      this.detectChanges();
      dataMyFav.fetch(this.userIDfromProfieFavs, this.profileFavsType, this.currentPage, this.itemsPerPage, dataMyFav.sort.field, dataMyFav.sort.asc).subscribe(
          response => {
            dataMyFav.currentPage = this.currentPage;
            dataMyFav.items = response.data;
            dataMyFav.lastPage = response.next_page_url;
            dataMyFav.loading = false;
            window.scrollTo(0, 0);
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  getPrevSongsProfileFavs() {
    let dataMyFav = this.getDataAPI;
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.filter = {};
    }
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.decade = '';
    }

    if (!dataMyFav.loading && dataMyFav.currentPage != 1) {
      dataMyFav.loading = true;
      this.currentPage--;
      this.detectChanges();
      dataMyFav.fetch(this.userIDfromProfieFavs, this.profileFavsType, this.currentPage, this.itemsPerPage, dataMyFav.sort.field, dataMyFav.sort.asc).subscribe(
          response => {
            dataMyFav.currentPage = this.currentPage;
            dataMyFav.items = response.data;
            dataMyFav.lastPage = response.next_page_url;
            dataMyFav.loading = false;
            window.scrollTo(0, 0);
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  onScrollEndHandleProfileFavsMember(data, sort) {
    if (!data.fetch) {
      return;
    }
    if (sort) {
      data.sort = sort;
    }  
    if (!data.loading && data.lastPage) {
      data.loading = true;
      this.detectChanges(); 
      data.fetch(this.userIDfromProfieFavs, this.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc).subscribe(
        response => {
          data.currentPage =  this.currentPage;
          data.items = data.items.concat(response.data);
          data.lastPage = response.next_page_url;
          data.loading = false;
          this.setPageParam();
          this.detectChanges();
        },
        error => {
          this.toastService.error(error.message);
        }
      );
    }
  }

  getNextSongsProfileFavsMember() {
    let dataMyFav = this.getDataAPI;
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.filter = {};
    }
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.decade = '';
    }

    if (!dataMyFav.loading && dataMyFav.lastPage) {
      dataMyFav.loading = true;
      this.currentPage++;
      this.detectChanges();
      dataMyFav.fetch(this.userIDfromProfieFavs, this.currentPage, this.itemsPerPage, dataMyFav.sort.field, dataMyFav.sort.asc).subscribe(
          response => {
            dataMyFav.currentPage = this.currentPage;
            dataMyFav.items = response.data;
            dataMyFav.lastPage = response.next_page_url;
            dataMyFav.loading = false;
            window.scrollTo(0, 0);
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  getPrevSongsProfileFavsMember() {
    let dataMyFav = this.getDataAPI;
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.filter = {};
    }
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.decade = '';
    }

    if (!dataMyFav.loading && dataMyFav.currentPage != 1) {
      dataMyFav.loading = true;
      this.currentPage--;
      this.detectChanges();
      dataMyFav.fetch(this.userIDfromProfieFavs,  this.currentPage, this.itemsPerPage, dataMyFav.sort.field, dataMyFav.sort.asc).subscribe(
          response => {
            dataMyFav.currentPage = this.currentPage;
            dataMyFav.items = response.data;
            dataMyFav.lastPage = response.next_page_url;
            dataMyFav.loading = false;
            window.scrollTo(0, 0);
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  onScrollEndHandleProfilePlaylists(data, sort) { 
    let playlists = data;
    if (!playlists.fetch) {
      return;
    }
    if (sort) {
      playlists.sort = sort;
    }  
    if (!playlists.loading && playlists.lastPage) {
      playlists.loading = true;
      this.detectChanges(); 
      playlists.fetch(        this.userIDfromProfieFavs,
        this.currentPage, this.itemsPerPage, playlists.sort.field,
        playlists.sort.asc, this.query, this.isPrivate).subscribe(
        response => {
          playlists.currentPage =  this.currentPage;
          playlists.items = data.items.concat(response.data);
          playlists.lastPage = response.next_page_url;
          playlists.loading = false;
          this.setPageParam();
          this.detectChanges();
        },
        error => {
          this.toastService.error(error.message);
        }
      );
    }
  }

  getNextSongsProfilePlaylists() {
    let playlists = this.getDataAPI;
    if(typeof playlists.filter == 'undefined') {
      playlists.filter = {};
    }
    if(typeof playlists.filter == 'undefined') {
      playlists.decade = '';
    }

    if (!playlists.loading && playlists.lastPage) {
      playlists.loading = true;
      this.currentPage++;
      this.detectChanges();
      playlists.fetch(        this.userIDfromProfieFavs,
        this.currentPage, this.itemsPerPage, playlists.sort.field,
        playlists.sort.asc, this.query, this.isPrivate).subscribe(
          response => {
            playlists.currentPage = this.currentPage;
            playlists.items = response.data;
            playlists.lastPage = response.next_page_url;
            playlists.loading = false;
            window.scrollTo(0, 0);
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  getPrevSongsProfilePlaylists() {
    let playlists = this.getDataAPI;
    if(typeof playlists.filter == 'undefined') {
      playlists.filter = {};
    }
    if(typeof playlists.filter == 'undefined') {
      playlists.decade = '';
    }

    if (!playlists.loading && playlists.currentPage != 1) {
      playlists.loading = true;
      this.currentPage--;
      this.detectChanges();
      playlists.fetch(
        
        this.userIDfromProfieFavs,
        this.currentPage, this.itemsPerPage, playlists.sort.field,
        playlists.sort.asc, this.query, this.isPrivate
        
        
        
        
        
        
        ).subscribe(
          response => {
            playlists.currentPage = this.currentPage;
            playlists.items = response.data;
            playlists.lastPage = response.next_page_url;
            playlists.loading = false;
            window.scrollTo(0, 0);
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }


  onScrollEndHandleProfileFans(data, sort) {
    if (!data.fetch) {
      return;
    }
    if (sort) {
      data.sort = sort;
    }   
    if (!data.loading) {
      data.loading = true;
     // this.detectChanges();
      data.fetch(this.userIDfromProfieFavs, this.currentPage, this.itemsPerPage, "screen_name", data.sort.asc, this.query).subscribe(
        response => {
          data.currentPage =  this.currentPage;
          data.items = response.data;
          data.lastPage = response.next_page_url;
          data.loading = false;
          this.setPageParam();
          this.detectChanges();
        },
        error => {
          this.toastService.error(error.message);
        }
      );
    }
  }

  getNextSongsProfileFans() {
    let dataMyFav = this.getDataAPI;
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.filter = {};
    }
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.decade = '';
    }

    if (!dataMyFav.loading && dataMyFav.lastPage) {
      dataMyFav.loading = true;
      this.currentPage++;
      this.detectChanges();
      dataMyFav.fetch(this.userIDfromProfieFavs, this.currentPage, this.itemsPerPage, "screen_name", dataMyFav.sort.asc,  this.query).subscribe(
          response => {
            dataMyFav.currentPage = this.currentPage;
            dataMyFav.items = response.data;
            dataMyFav.lastPage = response.next_page_url;
            dataMyFav.loading = false;
            window.scrollTo(0, 0);
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  getPrevSongsProfileFans() {
    let dataMyFav = this.getDataAPI;
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.filter = {};
    }
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.decade = '';
    }

    if (!dataMyFav.loading && dataMyFav.currentPage != 1) {
      dataMyFav.loading = true;
      this.currentPage--;
      this.detectChanges();
      dataMyFav.fetch(this.userIDfromProfieFavs,  this.currentPage, this.itemsPerPage, "screen_name", dataMyFav.sort.asc, this.query).subscribe(
          response => {
            dataMyFav.currentPage = this.currentPage;
            dataMyFav.items = response.data;
            dataMyFav.lastPage = response.next_page_url;
            dataMyFav.loading = false;
            window.scrollTo(0, 0);
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  onScrollEndHandlePlaylistPublic(dataMyFav, sort) {
    if (!dataMyFav.fetch) {
      return;
    }
    if (sort) {
      dataMyFav.sort = sort;
    }

    if (!dataMyFav.loading && dataMyFav.lastPage) {
     dataMyFav.loading = true;
      this.detectChanges();
      dataMyFav.fetch(this.currentPage, this.itemsPerPage, dataMyFav.sort.field, dataMyFav.sort.asc, '', this.isPrivatePlaylist).subscribe(
        response => {
          dataMyFav.currentPage =  this.currentPage;
          dataMyFav.items = dataMyFav.items.concat(response.data);
          dataMyFav.lastPage = response.next_page_url;
          dataMyFav.loading = false;
          this.setPageParam();
          this.detectChanges();
        },
        error => {
          this.toastService.error(error.message);
        }
      );
    }
  }

  getNextSongsPlaylistPublic() {
    let dataMyFav = this.getDataAPI;
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.filter = {};
    }
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.decade = '';
    }

    if (!dataMyFav.loading && dataMyFav.lastPage) {
      dataMyFav.loading = true;
      this.currentPage++;
      this.detectChanges();
      dataMyFav.fetch(this.currentPage, this.itemsPerPage, dataMyFav.sort.field, dataMyFav.sort.asc, '', this.isPrivatePlaylist).subscribe(
          response => {
            dataMyFav.currentPage = this.currentPage;
            dataMyFav.items = response.data;
            dataMyFav.lastPage = response.next_page_url;
            dataMyFav.loading = false;
            window.scrollTo(0, 0);
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  getPrevSongsPlaylistPublic() {
    let dataMyFav = this.getDataAPI;
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.filter = {};
    }
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.decade = '';
    }

    if (!dataMyFav.loading && dataMyFav.currentPage != 1) {
      dataMyFav.loading = true;
      this.currentPage--;
      this.detectChanges();
      dataMyFav.fetch(this.currentPage, this.itemsPerPage, dataMyFav.sort.field, dataMyFav.sort.asc, '', this.isPrivatePlaylist).subscribe(
          response => {
            dataMyFav.currentPage = this.currentPage;
            dataMyFav.items = response.data;
            dataMyFav.lastPage = response.next_page_url;
            dataMyFav.loading = false;
            window.scrollTo(0, 0);
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  resetDataObj(data) {
    this.currentPage = 1;
    data.currentPage = 1;
    data.loading = false;
    data.items = [];
    data.lastPage = 1;
    return data;
  }

  recordingToLegacy(recording) {
    this.recordingService.appearOnLegacy(recording.id).subscribe(
      response => {
        this.toastService.success('Recording will appear on legacy');
      },
      () => {
        this.toastService.success("There's an error, please try again later.");
      }
    );
  }

  onAddtoPlaylist(id) {
    this.playlistElem.open(id);
  }

  async subscribeUser(recording) {
    const recordingList = [
      this.favoriteData.items,
    ];
    this.helperService.subscribeUser(recording, recordingList);
  }

  async unsubscribeUser(recording) {
    const recordingList = [
      this.favoriteData.items,
    ];
    this.helperService.unsubscribeUser(recording, recordingList);
  }

  shareRecording(recording) {
    this.eventService.onSocialShare({ recording });
  }

  howOthersSingThis(id){
    this.navigate([`/d/ListenOthersSing/${id}`]);
  }

  onSearch(query) {
    this.query = query;
    //this.data.sort =  this.memberSortOptions[0]
    this.onScrollEndHandleProfileFans(this.fans, this.memberSortOptions[0]);
  }
}
