<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 485 485" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M242.5,306.315789 C285.85,306.315789 319,273.131579 319,229.736842 L319,76.5789474 C319,33.1842105 285.85,0 242.5,0 C199.15,0 166,33.1842105 166,76.5789474 L166,229.736842 C166,273.131579 199.15,306.315789 242.5,306.315789 Z M377.65,229.736842 C377.65,306.315789 313.9,359.921053 242.5,359.921053 C171.1,359.921053 107.35,306.315789 107.35,229.736842 L64,229.736842 C64,316.526316 132.85,388 217,400.763158 L217,485 L268,485 L268,400.763158 C352.15,388 421,316.527317 421,229.736842 L377.65,229.736842 Z"
        id="path-icon-mic"></path>
    </defs>
    <g id="microphone-of-voice" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlink:href="#path-icon-mic"></use>
      </mask>
      <use id="Mask" [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-icon-mic"></use>
    </g>
  </svg>
</div>