<div class="ss-search-users ps">
  <div
    class="input-box"
    style="background: rgb(255, 255, 255);padding: 20px; max-height: 400px; overflow: auto;"
  >
    <div class="search-input form-row">
      <label>
        Search Members
      </label>
      <input
        type="text"
        placeholder="search"
        class="form-control"
        (input)="handleSearchInput"
        (keyup)="search$.next($event?.target.value); searchTerm = $event?.target.value"
      />
    </div>
    <ul class="users-list">
      <li *ngFor="let user of users$ | async">
        <div class="user-info">
          <app-ss-user [user]="user" [showStatus]="false" [showLevel]="false">
          </app-ss-user>
          <button
            class="btn btn-primary"
            *ngIf="user.blocked"
            (click)="unblock(user)"
          >
            UNBLOCK
          </button>
          <button
            class="btn btn-danger"
            *ngIf="!user.blocked"
            (click)="block(user)"
          >
            BLOCK
          </button>
        </div>
      </li>
    </ul>
  </div>
</div>
