import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-ic',
  templateUrl: './icon-ic.component.html',
  styleUrls: ['./icon-ic.component.scss']
})
export class IconIcComponent implements OnInit {
  @Input() color: string = 'rgb(208, 208, 208)';
  @Input() size: number = 18;

  constructor() { }

  ngOnInit() {
  }

}
