import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DropzoneComponent} from '@app/shared/dropzone/dropzone.component';
import {ContestService} from '@app/core';

@Component({
  selector: 'app-contest-drop-image',
  templateUrl: './contest-drop-image.component.html',
  styleUrls: ['./contest-drop-image.component.scss']
})
export class ContestDropImageComponent implements OnInit, OnChanges {

  @ViewChild('dropzone', { static: true }) dropzone: DropzoneComponent;
  cover = null;
  @Input() coverUrl = '';
  @Output('onSelected') onSelected: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['coverUrl'].currentValue !== changes['coverUrl'].previousValue) {
      console.log('coverurl', this.coverUrl);
      this.dropzone.coverUrlTemp = this.coverUrl;
      this.dropzone.showPreview();
    }
  }

  fileSelected($event) {
    this.cover = $event.file;
    this.coverUrl = $event.fileUrl;
    if ($event.file !== null && $event.fileUrl !== null) {
      this.dropzone.coverUrlTemp = this.cover;
      this.dropzone.showPreview();
      this.onSelected.emit(this.cover);
    } else {
      this.clearCover();
    }
  }

  clearCover() {
    this.cover = null;
    this.coverUrl = '';
  }
}

