<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-body snap-credit" *ngIf="!sent && !notEnough && errorMessage == null">
    <span class="close-modal" (click)="c('ok')" >
      <app-icon-close size="18"></app-icon-close>
    </span>

    <div class="showcaseUser">
      <app-ss-user [user]="user" [isSmall]="false" [showStatus]="showStats" [showLevel]="false" [showTrinkets]="false"></app-ss-user>
      </div>
  
      <div class="showcaseTitle">{{recordingObject.song.title}}</div>
  
    <h4>Showcase this recording for 100 SingSnap Credits?</h4>

  <!-- <div class="modal-actions" *ngIf="mode==2">
    <app-featured-image [item]="items[j]" [showStats]="showStats"></app-featured-image>
    </div> -->


   

    <div class="modal-actions">
      <button class="other"
        (click)="c('Cross click')">CANCEL</button>
      <button class="ShowcaseButton" (click)="addShowcaseRecording(recordingObject)" [disabled]="isSending">
        <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="isSending"></i>
        SHOWCASE
      </button>
    </div>
  </div>
  <div class="modal-body snap-credit-sent snap-credit box" *ngIf="sent">
    <div class="icon-inner">
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <div class="icon-holder">
        <img src="/assets/images/showcase.png" style="width: 200px;">
      </div>
    </div>
    <h4>You've showcased {{user.screen_name}}'s recording!</h4>
    <div class="modal-close-button">
      <button class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray"
        (click)="close()">CLOSE</button>
    </div>
  </div>
  <div class="modal-body snap-credit-enough snap-credit" *ngIf="notEnough">
    <div class="icon-inner circle-ripple" style="margin-left: 37.5%; margin-bottom: -15%">
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <div class="icon-holder">
        <app-icon-close color="white" size="36"></app-icon-close>
      </div>
    </div>
    <h5>Oops!</h5>
  <h4>Oh oh! You don't have enough credits!</h4>
    <div class="modal-actions">
      <button class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray"
        (click)="c('Cross click')">CANCEL</button>
      <button class="btn btn-primary" (click)="handlePurchase()" [disabled]="isSending">
        PURCHASE
      </button>
    </div>
  </div>

  <div class="modal-body snap-credit-sent snap-credit box" *ngIf="errorMessage != null">
    <h4>{{errorMessage}}</h4>
    <div class="modal-close-button">
      <button class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray"
        (click)="close()">CLOSE</button>
    </div>
  </div>


</ng-template>