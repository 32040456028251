<ng-template #content let-modal let-c="close" let-d="dismiss" class="share-content-border">
  <div class="modal-body">
    <div class="share-header">
      <h3>SHARE {{title}}</h3>
      <span class="share-close" (click)="c('ok')">
        <app-icon-close size="18"></app-icon-close>
      </span>
    </div>
    <div class="share-content">
      <app-ss-recording *ngIf="data.recording" [recording]="data.recording"></app-ss-recording>
      <app-ss-user *ngIf="data.user" [user]="data.user"></app-ss-user>
      <app-ss-thread *ngIf="data.thread" [thread]="data.thread" [type]="data.type"></app-ss-thread>
    </div>
    <div class="share-buttons">
      <div class='shareLine'>
        <input id='shareLink' [value]="data.recording.share_url">
        <button id='clipboard' *ngIf="data.recording.share_url" (click)="copyToClipboard(data.recording.share_url)" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg></button>
      </div>
      <div class='shareLine'>
        <a target="popup" href="https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u={{replaceShareWithWWW(data.recording.share_url)}}&display=popup&src=share_button"><app-icon-social-facebook ></app-icon-social-facebook></a>
        <a target='popup' href="https://twitter.com/intent/tweet?url={{data.recording.share_url}}"><app-icon-social-twitter></app-icon-social-twitter></a>
        <!-- <app-icon-social-fb-messenger></app-icon-social-fb-messenger> -->
        <a *ngIf='mobile' target='popup' href="sms:?&body={{data.recording.share_url}}"><app-icon-social-messenger></app-icon-social-messenger></a>
        <a target='popup' href="mailto:?subject=Check%20out%20this%20cool%20recording%20on%20SingSnap!&body={{data.recording.share_url}}"><app-icon-social-email></app-icon-social-email></a>
      </div>

    </div>

    <!--  -->

  </div>
</ng-template>
