<ng-template #content let-modal let-c="close" let-d="dismiss" >
    <div style="border-radius: 20px;">
    <div class="modal-body">
        <h4 class="inner-circle-title">Add Member</h4>
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true" [inlineSVG]="'/assets/svgs/close.svg'"></span>
        </button>

        <div class="search-input form-row" style="margin-top: 20px;">
            <input type="text" placeholder="Search..." class="form-control"
                (keyup)="icData.search$.next($event.target.value);searchTerm = $event.target.value;">
        </div>
        <div  *ngIf="foundMembers.length > 0 || stickyMembers.length > 0 ; else noResults"> 
        <ul class="inner-circle-members-list" >
            <li *ngFor="let member of stickyMembers">
                <div class="inner-circle-user-info">
                    <app-ss-user [user]="member" class="col"></app-ss-user>
                    <span *ngIf="member.gold && belongsToInviteList(member)" class="make-me-gold">
                        <span>
                            Add to <br> Admins
                        </span>
                        <label class="switch small">
                            <input type="checkbox" class="primary" [checked]="member.makeAdmin"
                                (change)="addToAdminList(member)">
                            <span class="slider round"></span>
                        </label>
                    </span>
                    <label class="check-circle">
                        <span class="round" [ngClass]="{'active': belongsToInviteList(member)}"
                            (click)="addToInviteList(member)"></span>
                    </label>
                </div>
            </li>
            <li *ngFor="let member of foundMembers" [style.display]="belongsToInviteList(member) ? 'none' : 'block'">
                <div class="inner-circle-user-info">
                    <app-ss-user [user]="member" class="col"></app-ss-user>
                    <span *ngIf="member.gold && belongsToInviteList(member)" class="make-me-gold">
                        <span>
                            Add to <br> Admins
                        </span>
                        <label class="switch small">
                            <input type="checkbox" class="primary" [checked]="member.makeAdmin"
                                (change)="addToAdminList(member)">
                            <span class="slider round"></span>
                        </label>
                    </span>
                    <label class="check-circle">
                        <span class="round" [ngClass]="{'active': belongsToInviteList(member)}"
                            (click)="addToInviteList(member)"></span>
                    </label>
                </div>
            </li>
        
        </ul>
        </div>
        <ng-template #noResults>
            <div class="no-results">
                No results found.
            </div>
        </ng-template>
        <div class="mx-auto mt-2">
            <ngb-pagination *ngIf="!!totalAllMembers" [maxSize]="3" [collectionSize]="totalAllMembers"
                [pageSize]="pageSize" size="sm" [ellipses]="false"
                (pageChange)="onPageAddNonMembersChangeHandle($event)">
            </ngb-pagination>
        </div>
    </div>
    <div class="modal-footer" style="border: none;">
        <button class="btn btn-primary btn-lg btn-block" [disabled]="inviteList.length === 0"
            (click)="inviteMembers()">Add members</button>
    </div>
    </div>
</ng-template>
<app-splash-modal #splashModal></app-splash-modal>
