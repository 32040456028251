<div class="session-page">
  <div class="session-form">
    <h3>Set new password</h3>
    <div class="session-help-text">
      <span>Old password reset successfully. Set a new password now.</span>
    </div>
    <form [formGroup]="formGroup">
        <div class="form-group">
          <label for="password">NEW PASSWORD</label>
          <input name="password" type="password" class="form-control" formControlName="password" required>
          <span class="input-error" *ngIf="!formGroup.controls['password'].pristine && !formGroup.controls['password'].valid">Password is required.</span>
        </div>
        <div class="form-group">
          <label for="confirmPassword">CONFIRM NEW PASSWORD</label>
          <input name="confirmPassword" type="password" class="form-control" formControlName="password" required>
          <span class="input-error"
            *ngIf="!formGroup.controls['password'].pristine && !formGroup.controls['password'].valid">Confirm password is
            required.</span>
        </div>
      <button type="submit" class="btn btn-primary" (click)="onSubmit()">DONE</button>
    </form>
  </div>
  <div class="site-info-slider">
    <app-session-carousel></app-session-carousel>
  </div>
</div>
