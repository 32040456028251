import { EventEmitter, Injectable } from '@angular/core';
import { map ,  tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { HttpService } from './http.service';
import { UtilService } from './util.service';

@Injectable()
export class ProfileService extends HttpService {
  realodProfilePicture = new EventEmitter();
  reloadHeader = new EventEmitter();
  openDuetsAction = new EventEmitter();
  userUpdated = new EventEmitter();
  notificationsData = new EventEmitter();

  openDuetsActionType = 'play';

  constructor(private http: HttpClient, private utilService: UtilService) {
    super();
    this.openDuetsAction.subscribe(data => {
      this.openDuetsActionType = data.actionType;
    });
  }

  get userId() {
    return this.utilService.getloggedUserData().id;
  }

  get user() {
    return this.utilService.getloggedUserData();
  }

  isMe(otherUserId) {
    return this.user.id == otherUserId;
  }

  getOpenDuetsActionType() {
    return this.openDuetsActionType;
  }

  getMemberProfile(url) {
    const options = this.getHeaders();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getSettings() {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl('/user/settings');
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getUserProfile() {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl('/user');
    return this.http
      .get(url, options)
      .pipe(
        tap(user => {
          this.utilService.setloggedUserData(user);
        })
      );
  }

  getProfile(id) {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl('/users/' + id);
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }


  getDistributableList(page = 1, perPage = 10) {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl(`/user/distribute/users?page=${page}&per_page=${perPage}`);
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }




  getFans(page = 1, perPage = 10) {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl(`/user/fans?page=${page}&per_page=${perPage}`);
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  // getUserFans(id, page = 1, perPage = 15) {
  //   const options = this.getHeadersForAuth();
  //   // const url = this.makeUrl('/users/' + id + '/fans' + '?page=${page}&per_page=${perPage}');
  //   const url = this.makeUrl(`/users/${id}/fans?page=${page}&per_page=${perPage}`);
  //   return this.http
  //     .get(url, options)
  //     .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  // }

  getUserFans(id, page = 1, perPage = 10, sorter = 'screen_name', asc = true, query = '') {
    const options = this.getHeadersForAuth();
    let url =
      `/users/${id}/fans` +
      this.utilService.buildUrlParams(page, perPage, sorter, asc);
      if (query) {
        const q = {
          name: query,
        };
        url += '&q=' + JSON.stringify(q); 
      }
    
      
  
    return this.http
      .get(this.makeUrl(url), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }


  getUserFavoriteUsers(id, page = 1, perPage = 10, sorter = null, asc = true) {
    const options = this.getHeaders();
    const url =
      `/users/${id}/following` +
      this.utilService.buildUrlParams(page, perPage, sorter, asc);
    return this.http
      .get(this.makeUrl(url), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getUserFavorites(
    id,
    type,
    page = 1,
    perPage = 10,
    sorter = null,
    asc = true
  ) {
    const options = this.getHeaders();
    const url =
      `/users/${id}/favorites/${type}` +
      this.utilService.buildUrlParams(page, perPage, sorter, asc);
    return this.http
      .get(this.makeUrl(url), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getFavoriteUsers(page = 1, perPage = 10, sorter = null, asc = true) {
    const options = this.getHeaders();
    const url =
      `/user/following` +
      this.utilService.buildUrlParams(page, perPage, sorter, asc);
    return this.http
      .get(this.makeUrl(url), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getFavoriteArtists(page = 1, perPage = 10, sorter = null, asc = true) {
    const options = this.getHeaders();
    const url =
      `/user/favorites/artists` +
      this.utilService.buildUrlParams(page, perPage, sorter, asc);
    return this.http
      .get(this.makeUrl(url), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getFavoriteSongs(page = 1, perPage = 10, sorter = null, asc = true) {
    const options = this.getHeaders();
    const url =
      `/user/favorites/song` +
      this.utilService.buildUrlParams(page, perPage, sorter, asc);
    return this.http
      .get(this.makeUrl(url), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getFavoritePlaylists(
    page = 1,
    perPage = 10,
    sorter = null,
    asc = true,
    query = ''
  ) {
    const options = this.getHeaders();
    let url =
      `/user/favorites/playlists` +
      this.utilService.buildUrlParams(page, perPage, sorter, asc);
    if (query) {
      const q = {
        title: query,
      };
      url += '&q=' + JSON.stringify(q);
    }
    return this.http
      .get(this.makeUrl(url), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getFavoriteRecordings(page = 1, perPage = 10, sorter = null, asc = false) {
    const options = this.getHeaders();
    const url =
      `/user/favorites/recording` +
      this.utilService.buildUrlParams(page, perPage, sorter, asc);
    return this.http
      .get(this.makeUrl(url), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getUserBadges(id) {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl('/users/' + id + '/badges');
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getBadges() {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl('/user/badges');
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getUsersOpenDuets(id) {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl('/users/' + id + '/duets?q={"open":0}');
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getActivity() {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl('/user/activity');
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getSubscriptions() {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl('/user/subscriptions');
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getSubscriptionHistory(id) {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl(`/user/subscriptions/${id}/history`);
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  cancelSubscription(id) {
    console.log(id)
    const options = this.getHeadersForAuth();
    const url = this.makeUrl(`/user/subscriptions/${id}`);
    return this.http
      .delete(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getSubscriptionsReceived() {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl('/user/subscriptions/received');
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getOrdersMemberhips() {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl('/user/orders/memberships');
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getOrdersMemberhipsReceived() {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl('/user/orders/memberships/received');
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getOrders() {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl('/user/orders');
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getRefunded() {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl('/user/orders/refunds');
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getOpenDuets() {
    const options = this.getHeaders();
    options.headers.append('Site-Host', 'As)_(ko;8as^65r');
    const url = this.makeUrl('/user/duets');
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  deleteNotification(id) {
    const options = this.getHeaders();
    const url = this.makeUrl('/user/notifications/' + id);
    return this.http
      .delete(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getFollowers() {
    return this.http
      .get('../../../assets/api/followers.json', {})
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  deleteUserRecordings(recordingId: string, userId: number = this.userId) {
    const options = this.getHeaders();
    return this.http
      .delete(
        this.makeUrl(`/users/${userId}/recordings/${recordingId}`),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  favouriteUser(url) {
    const options = this.getHeaders();
    return this.http.post(url, {}, options);
  }

  alterSettings(body) {
    const options = this.getHeadersForAuth();
    options.headers.append('Content-Type', 'application/json');
    const url = this.makeUrl('/user/settings');
    return this.http
      .post(url, body, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  alterUserSettings(body) {
    const options = this.getHeaders();
    options.headers.append('Content-Type', 'application/json');
    const url = this.makeUrl('/user');
    return this.http
      .post(url, body, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  updateUser(user) {
    const options = this.getHeaders();
    options.headers.append('Content-Type', 'application/json');
    const url = this.makeUrl('/user');
    return this.http
      .post(url, user, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  updateProfilePhoto(file) {
    const options = this.getHeaders();
    const fd = new FormData();
    fd.append('photo', file);
    const url = this.makeUrl('/user');
    return this.http
      .post(url, fd, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  updateCover(file) {
    const options = this.getHeaders();
    const fd = new FormData();
    fd.append('cover', file);
    const url = this.makeUrl('/user');
    return this.http
      .post(url, fd, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }


  removeCoverPicture(){
    const options = this.getHeaders();
    const payload = {} as any;
    payload.cover = null;
    const url = this.makeUrl('/user');
    return this.http
      .post(url, payload, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  updatePassword(body) {
    const options = this.getHeaders();
    const url = this.makeUrl('/user');
    return this.http.post(url, body, options);
  }

  updateEmail(body) {
    const options = this.getHeaders();
    const url = this.makeUrl('/user');
    return this.http.post(url, body, options);
  }


  startSubscription(url) {
    const options = this.getHeaders();
    // options.headers.append('Site-Host', 'As)_(ko;8as^65r');
    options.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  isUserAlreadyFavorited(memberId) {
    const options = this.getHeaders();
    return this.http
      .get(this.makeUrl(`/users/${memberId}/favorited`), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  unfavoriteMember(id) {
    const options = this.getHeaders();
    return this.http
      .delete(this.makeUrl(`/users/${id}/favorite`), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  favoriteMember(id) {
    const options = this.getHeaders();
    return this.http
      .post(this.makeUrl(`/users/${id}/favorite`), {}, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  changeProfilePicture(file: File) {
    const options = this.getHeaders();
    const url = this.makeUrl('/user');
    const formData = new FormData();
    formData.append('photo', file);
    return this.http
      .post(url, formData, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  removeProfilePicture() {
    const options = this.getHeaders();
    const url = this.makeUrl('/user');
    const payload = {} as any;
    payload.photo = null;
    return this.http
      .post(url, payload, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  sendRequest(user: any) {
    const body = 'email=federico@singsnap.com&password=d3velopment';
    const headers = this.getHeaders();
    return this.http
      .post(this.makeUrl('/auth/login'), body, headers)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))))
  }

  getUser(id) {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl(`/users/${id}`);
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  reportUser(id, reason) {
    const options = this.getHeaders();
    return this.http
      .post(this.makeUrl(`/users/${id}/report`), reason, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))))
  }

  notifyOnNextUserRecording(id) {
    const options = this.getHeaders();
    return this.http
      .post(this.makeUrl(`/users/${id}/notify`), {}, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))))
  }

  unnotifyOnNextUserRecording(id) {
    const options = this.getHeaders();
    return this.http
      .delete(this.makeUrl(`/users/${id}/notify`), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))))
  }

  checkUser(){
    const options = this.getHeaders();
    options['observe'] = 'response';
    return this.http.post(this.makeUrl(`/user/check`), {}, options);
  }
  getCheck(){
    const options = this.getHeaders();
    return this.http.get(this.makeUrl(`/check`), options);
  }
  getInvites() {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl(`/user/invites`);
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  acceptInvite(id) {
    const options = this.getHeaders();
    return this.http
      .post(this.makeUrl(`/user/invites/${id}/accept`), {}, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))))
  }

  declineInvite(id) {
    const options = this.getHeaders();
    return this.http
      .post(this.makeUrl(`/user/invites/${id}/decline`), {}, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))))
  }

  block(id) {
    const options = this.getHeaders();
    return this.http
      .post(this.makeUrl(`/users/${id}/block`), {}, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))))
  }

  unblock(id) {
    const options = this.getHeaders();
    return this.http
      .delete(this.makeUrl(`/users/${id}/block`), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))))
  }

  blockedMembers() {
    const options = this.getHeadersForAuth();
    const url = this.makeUrl('/user/blocking?perPage=100');
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
}
