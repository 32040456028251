import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sort-arrow',
  templateUrl: './sort-arrow.component.html',
  styleUrls: ['./sort-arrow.component.scss']
})
export class SortArrowComponent implements OnInit {
  @Input() active = true;

  constructor() { }

  ngOnInit() {
  }

}
