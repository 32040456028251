import { Component, OnInit, ChangeDetectorRef, NgZone, HostListener, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { initiateRecorder } from '../../assets/build-recorder/super-start26829.js'
import { ActivatedRoute, Router } from '@angular/router';
import { Data, SongService, ProfileService, SingService, RecordingService, UtilService, UserService } from '@app/core';
import { ToastrService } from 'ngx-toastr';
import { NgxGlideComponent } from 'ngx-glide';
import Bugsnag from '@bugsnag/js';
import {environment} from './../../environments/environment';
import { now } from 'lodash';
declare var gtag;
@Component({
  selector: 'app-superrecorder',
  templateUrl: './superrecorder.component.html',
  styleUrls: ['./superrecorder.component.scss']
})
export class SuperrecorderComponent implements OnInit, OnDestroy {
  @HostListener('window:click', ['$event'])
  clickout(event) {
    if(event.target.tagName != 'svg' && event.target.tagName != 'use') {
      if(this.micDropDown) {
        this.micDropDown = false
      }
      if(this.videoDropDown) {
        this.videoDropDown = false
      }
    }
  }
  @HostListener('window:keydown', ['$event'])
  keyEvent(e: KeyboardEvent) {
    if(e.keyCode == 32) {
      if(this.loaded = true && !this.uploaded) {
        console.log('something')
        switch(this.UIState) {
          case 'recording':
            this.stopRecording();
            break
          case 'practicing':
            this.pause()
            break
          case 'practicingPaused':
            this.play()
            break
          case 'review':
            this.pause()
            break
          case 'reviewPaused':
            this.play()
            break
          case 'default':
            this.play()
            break
        }
        e.stopImmediatePropagation();
        e.preventDefault();
      }

    } else if ( e.code == 'ControlLeft' || e.code == 'ControlRight') {
      e.stopImmediatePropagation();
      this.isControlPressed = true;
     e.preventDefault();
    } else {
      this.isControlPressed = false;
    }
  }
  @ViewChild('recVideo') private recVideo: ElementRef;
  @ViewChild('duetVideo') private duetVideo: ElementRef;
  @ViewChild('topUI') private topUI: ElementRef;
  @ViewChild('bottomUI') private bottomUI: ElementRef;
  @ViewChild('countdown') private countdown: ElementRef;
  @ViewChild('errorMessage') private errorMessage: ElementRef;
  @ViewChild('errorMessageContainer') private errorMessageContainer: ElementRef;
  //@ViewChild('lyricsCanvas') private canvas: ElementRef;
  @ViewChild('filterForm') private filterForm: ElementRef;
  @ViewChild('filterUI') private filterUI: ElementRef;
  @ViewChild('framesUI') private framesUI: ElementRef;
  @ViewChild('filterCanvas') private filterCanvas: ElementRef;
  @ViewChild('otherSingers', { static: true }) private otherSingersElem;
  @ViewChild('recorderContainer', { static: true }) private recorderContainer;
  @ViewChild(NgxGlideComponent, { static: false }) ngxGlide: NgxGlideComponent;


  id;
  wasmPath = '/assets/superpowered.wasm';
  workletPath = '/assets/scripts/workletProcessor.js';

  isControlPressed = false;
  blueLimitReached = false;
  pitchDisabled = false;
  song;
  recorder;
  loaded = false;
  duration = 0;
  title;
  partSelector = false
  playhead = 0;
  parts
  interval = null;
  uuid;
  framesPaths = [
                {name: 'Fine Art', path: '../../assets/images/frames/frame1.png', promo: false},
                {name: 'Dressing Room', path: '../../assets/images/frames/frame2.png', promo: false},
                {name: "Film '76", path: '../../assets/images/frames/frame3.png', promo: false},
                {name: "Film '84", path: '../../assets/images/frames/frame4.png', promo: false},
                {name: 'Warm Roses', path: '../../assets/images/frames/frame5.png', promo: false},
                {name: 'Cool Roses', path: '../../assets/images/frames/frame6.png', promo: false},
                {name: 'Family Photo', path: '../../assets/images/frames/frame7.png', promo: false},
                {name: 'Nebula', path: '../../assets/images/frames/frame8.png', promo: false},
                {name: 'Space', path: '../../assets/images/frames/frame9.png', promo: false},
                {name: 'Chalkboard', path: '../../assets/images/frames/frame10.png', promo: false},
                {name: 'Hearts', path: '../../assets/images/frames/frame11.png', promo: false},
                {name: 'Autumn', path: '../../assets/images/frames/frame12.png', promo: false},
                {name: 'Winter', path: '../../assets/images/frames/frame13.png', promo: false},
                {name: 'Stars', path: '../../assets/images/frames/frame14.png', promo: false},
                {name: 'Happy Birthday', path: '../../assets/images/frames/frame15.png', promo: false},
                {name: 'Snapiversary', path: '../../assets/images/frames/frame16.png', promo: false},
                {name: 'Easter Eggs', path: '../../assets/images/frames/frame17.png', promo: false},
                {name: "Saint Patrick's Day", path: '../../assets/images/frames/frame18.png', promo: false},
                {name: 'Spring Flowers', path: '../../assets/images/frames/frame19.png', promo: false},
                {name: 'Cherry Blossom', path: '../../assets/images/frames/frame20.png', promo: false},
                {name: 'Sunset', path: '../../assets/images/frames/frame21.png', promo: false},
                {name: 'Lavender', path: '../../assets/images/frames/frame22.png', promo: false},
                {name: 'Cowboy', path: '../../assets/images/frames/frame23.png', promo: false},
                {name: 'Thunder', path: '../../assets/images/frames/frame24.png', promo: false},
              ]
  UIState = 'default'
  containerOpen = false;
  sources = {}
  step = 'Ready to Record'
  filterIndex;
  videoDropDown = false;
  micDropDown = false;
  finished = false;
  scrubberOn = false;
  volumeBar = false;
  filters = true;
  filterName = 'none';
  filterItems = [
    { nameStart: 'blur(',
      value: '0',
      nameEnd: 'px) ',
      id: 'blur'
    },
    { nameStart: 'brightness(',
      value: '1',
      nameEnd: ') ',
      id: 'brightness'
    },
    { nameStart: 'contrast(',
      value: '100',
      nameEnd: '%) ',
      id: 'contrast'
    },
    { nameStart: 'saturate(',
      value: '100',
      nameEnd: '%)',
      id: 'saturate'
    },
    { nameStart: 'hue-rotate(',
      value: '0',
      nameEnd: 'deg) ',
      id: 'hue-rotate'
    },
    { nameStart: 'grayscale(',
      value: '0',
      nameEnd: '%) ',
      id: 'grayscale'
    },
    { nameStart: 'sepia(',
      value: '0',
      nameEnd: '%) ',
      id: 'sepia'
    },
    { nameStart: 'invert(',
      value: '0',
      nameEnd: '%) ',
      id: 'invert'
    },
  ]
  pitchBar = false;
  syncBar = false;
  lyricBar = false;
  lyricSpeed = 0;
  duet = false;
  uploaded = false
  cam = true
  sync = 0;
  mmsb = true;
  pitch = 0
  micVolume = 1;
  count = 5;
  musicVolume = 1;
  params;
  part;
  jam = false;
  urlChanges = false;
  version = '7.0'
  fullscreen = false;
  restarting = false;
  filterMenu = false;
  framesMenu = false;
  framesLoaded = false;
  UI = {
    recordButton: true,
    filterButton: true,
    framesButton: true,
    practiceButton: true,
    reviewButton: false,
    restartButton: false,
    pitchButton: true,
    volumeButton: true,
    lyricButton: false,
    syncButton: false,
    mmsbButton: false,
    cameraButton: true,
    micButton: true,
    saveOrTryAgainButtons: false,
    pauseButton: false,
    stopRecordingButton: false,
    resumePracticeButton: false
  }

  constructor(    
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private singService: SingService,
    private songService: SongService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private recordingService: RecordingService,
    private profileService: ProfileService,
    private data: Data,
    private router: Router,
    private userService: UserService,) { 
    
  }

  ngOnInit() {
    this.singService.getFrames().subscribe(result => {

      let frames = this.framesPaths;
      result.forEach(frame => {
        frames.unshift({name: frame.title, path: frame.uri, promo: true})
      })
      frames.unshift({name: 'None', path: 'nadda', promo: false})
      this.framesPaths = frames;
      console.log('frames will follow:')
      console.log(this.framesPaths)
      this.framesLoaded = true;
      this.activatedRoute.params.subscribe(params => {
        this.id = parseInt(params.id)
        this.loadSong()
        
      });
    });
    this.activatedRoute.data.subscribe(data => {
      if(typeof data.jam != 'undefined'){
        if(data.jam == true) {
          this.jam = true;
        }
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.params = params;
      console.log(params)
      if(typeof params.pitch != 'undefined') {
        this.pitch = params.pitch
        this.urlChanges = true;
      }
      if(typeof params.volume != 'undefined') {
        this.musicVolume = params.volume
        this.urlChanges = true;
      }
      if(typeof params.filterIndex != 'undefined') {
        this.filterIndex = params.filterIndex
        this.filterName = params.filterName;
        this.urlChanges = true;
      }
    });
    if(!this.profileService.user.gold) {
      console.log('user is not gold')
      this.UI.pitchButton = false;
    }
  }
  
  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }
  initPartSelector(song) {
      //lets sort out the part colours for the part colour selector
      Object.keys(song.parts).forEach((partName) => {
        console.log(song.parts[partName], partName)
        if(!song.parts[partName] || song.parts[partName] == '' || song.parts[partName] == ' ') {
          delete song.parts[partName]
        }
        if((partName.toUpperCase()).includes('FE') || (partName.toUpperCase()).includes('WO') || (partName.toUpperCase()).includes('GIRL')) {
          song.parts[partName] = 'Orange'
        } else if((partName.toUpperCase()).includes('MAL') || (partName.toUpperCase()).includes('BOY') || (partName.toUpperCase()).includes('MEN') || (partName.toUpperCase()).includes('GUY')) {
          song.parts[partName] = 'SlateBlue'
        } else if ((partName.toUpperCase()).includes('SINGER 1')){
          song.parts[partName] = 'rgb(49, 49, 255)'
        } else if ((partName.toUpperCase()).includes('SINGER 2')){
          song.parts[partName] = 'Salmon'
        } else if ((partName.toUpperCase()).includes('SINGER 3')){
          song.parts[partName] = 'Red'
        } else if ((partName.toUpperCase()).includes('SINGER 4')){
          song.parts[partName] = 'Purple'
        } 
      })
      this.parts = song.parts
      if(Object.keys(song.parts).length > 0) {
        this.partSelector = true
      }

  }
  async loadSong() {
    if(!this.profileService.user.gold) {
      console.log('user is not gold')
      this.pitchDisabled = true;
    }
    if(!this.jam) {
      this.songService.singASong(this.id).subscribe(result => {
        this.song = result;
        if(this.song.parts) {
          console.log(this.song.parts)
          this.initPartSelector(this.song)
        }
        this.song.jam = this.jam; //recorder needs to know whether or not to load in duet mode
        console.log(this.song)
        this.title = this.song.title;
        this.sendGtagLoad(this.id, this.profileService.user.gold, 'true', 'none' );
        this.initRecorder();

      }, error => {
        if(error.status == 412) {
          this.blueLimitReached = true;
          this.loaded = true;
          this.UIState = 'noPermissions'
          this.sendGtagLoad(this.id, this.profileService.user.gold, 'true', 'blueLimitReached' );
          this.changeUIState();
          let errMsg = error.toString();
          Bugsnag.notify('Recorder loadsong error: ' + errMsg);
        }
      })
    } else {
      //this.UI.volumeButton = false;
      this.recordingService.getDuetRecording(this.id).subscribe(result => {
        this.duetVideo.nativeElement.style.removeProperty('display')
        this.song = {song: result['song'], recordingData: result['record']}
        this.title = this.song.recordingData.title

        //build duet layers for email
        let loggedInUserId = this.profileService.user.id;
        if(this.song.song.users.length ){
          for(var i=0;i<this.song.song.users.length;i++) { 
            let idFound = this.data.recordingDuetLayers.find((value) => {if (value.id === this.song.song.users[i].id) return true;});
            if (!idFound){ 
              let isMe = false;
              if (this.song.song.users[i].id === loggedInUserId){
                isMe = true;
              }
              this.data.recordingDuetLayers.push(
                {
                    'id': this.song.song.users[i].id,
                    'name': this.song.song.users[i].screen_name,
                    'email': this.song.song.users[i].email,
                    'newsletter': this.song.song.users[i].newsletter,
                    'sender_name': this.profileService.user.screen_name,
                    'sender_id': this.profileService.user.id,
                    'isMe': isMe,
                    'title': this.title
                });                     
            }  
          }
        }

        if(this.song.recordingData.parts) {
          this.initPartSelector(this.song.recordingData)
        }
        this.song.jam = this.jam; //recorder needs to know whether or not to load in duet mode
        this.sendGtagLoad(this.id, this.profileService.user.gold, 'false', 'none' );
        this.initRecorder();
      }, error => {
        if(error.status == 503) {
          //this.legacyDuet = true;
          this.sendGtagLoad(this.id, this.profileService.user.gold, 'false', '503' );
          let errMsg = error.toString();
          Bugsnag.notify('Recorder loadduet error: ' + errMsg);
        }
        if(error.status == 412) {
          this.blueLimitReached = true;
          this.sendGtagLoad(this.id, this.profileService.user.gold, 'false', 'blueLimitReached' );
          let errMsg = error.toString();
          Bugsnag.notify('Recorder load error: ' + errMsg);

        }
      });
    }


    }
    selectPart(part) {
      this.part = part
      this.partSelector = false;
    }
  initRecorder() {
    this.recorder = initiateRecorder(this.workletPath, this.wasmPath, this.recVideo.nativeElement, this.duetVideo.nativeElement, this.song, false, this.filterCanvas.nativeElement, this.framesPaths)
    if(typeof this.recorder == 'string') {
      this.errorMessageContainer.nativeElement.style.display = 'flex'
      this.errorMessage.nativeElement.innerText = 'This song has no lyrics! Please try a different one.'
      this.UIState = 'noPermissions'
      this.loaded = true;
      this.changeUIState();
      Bugsnag.notify('Recorder load error: no lyrics');
      return
    }
    this.hookUpEvents()
    this.recorder.init()
  }
  hookUpEvents(){
    let userAgent = window.navigator.userAgent.toLowerCase()
    if (userAgent.indexOf('firefox',0) !== -1) {
      this.recorder.filters.splice(1,2)
      
    }
    if(this.song.duet) {
      this.containerOpen = true;
    }
    //song is loaded!
    this.recorder.addEventListener('initDom', ()=>this.initDom())
    this.recorder.addEventListener('parts_selected', ()=>this.partsSelected())
    this.recorder.addEventListener('playheadUpdate', ()=>this.playheadUpdate()) // playhead is in a new spot, update the scrubber
    this.recorder.addEventListener('input_sources_ready', (r)=>{
      this.sources = this.recorder.sources;
      console.log(this.recorder.recorder.constraints)
      if(!this.recorder.recorder.noCam) {
        this.recVideo.nativeElement.style.removeProperty('background-image')
      } else {
        this.recVideo.nativeElement.style.backgroundImage = 'url(../../assets/images/audio_only.png)'

      }
      this.detectChanges()
    })
    this.recorder.addEventListener('show_message', (event, error)=>{
      this.toastr.info(error)
      this.detectChanges()
    })
    this.recorder.addEventListener('hide_canvas', ()=>{
      // this.recorder.recorder.videoOff();
      // this.cam = false
      // this.recVideo.nativeElement.style.opacity = 10
    })
    this.recorder.addEventListener('no_permissions', (event, error)=>{
      let msg = error.toString()
      this.errorMessageContainer.nativeElement.style.display = 'flex'
      console.log(typeof msg)
      if (msg.toUpperCase().includes('PERMISSION DENIED')) {
        msg = 'Permission Denied by Operating System. Please check that your system is allowing mic and camera access for your browser.'
      }
      this.errorMessage.nativeElement.innerText = msg
      // this.UIState = 'noPermissions'
      // this.changeUIState()
      Bugsnag.notify('Recorder: ' + msg);
    })


    //this.recorder.addEventListener('startScrubber', ()=>this.startScrubber())
    this.recorder.addEventListener('durationUpdate', ()=>this.durationUpdate())

  }
  initDom() {
    console.log('init dom')
    this.duration = this.recorder.superPlayer.duration
   this.loaded = true;
    if(this.pitch) {
      this.onPitchChange(this.pitch)
    }
    if(typeof this.filterIndex != 'undefined') {
      this.changeFilter(this.filterIndex, this.filterName)
    }
    if(typeof this.params.frameName != 'undefined') {
      this.changeFrame(this.params.frameName)
    }
    if(this.musicVolume != 1) {
      this.recorder.changeMusicVolume(Number(this.musicVolume));
    }
    if(typeof localStorage.getItem('toggleCamera') != 'undefined') {
      if (localStorage.getItem('toggleCamera') == 'false') {
        this.recorder.recorder.videoOff();
        this.cam = false
        this.recVideo.nativeElement.style.opacity = 10
      }
    }

    this.detectChanges()
    if(this.jam) {
      this.changeDuetStyles()
      // console.log('we have ' + this.song.layers + ' layers')
      console.log(this.recorder.song)
    }
    if(!this.recorder.recorder.noCam){
      //this.recorder.startCanvas()

    }
  }
  getIndex() {
    if(typeof this.ngxGlide != 'undefined') {
      return this.ngxGlide.getIndex()
    } else {
      return 1
    }
  }
  changeFilter(j, name){
    this.recorder.filterIndex = j
    this.recorder.filters.forEach((filter, i)=>{
      this.recorder.filters[i].selected = '#292C3E'
    })
    this.recorder.filters[j].selected = '#4FEAAE'
    this.filterName = name;
    this.filterIndex = j;
    this.detectChanges()
  }
  openFilterMenu(){
    this.filterMenu = true;
    this.containerOpen = true;
    this.detectChanges()
  }
  closeFilterMenu(){
    this.filterMenu = false;
    this.containerOpen = false;
    this.detectChanges()
  }

  changeFrame(name){
    let oldName = this.recorder.selectedFrame.name
    if(this.recorder.frames[oldName].promo == true) {
      this.recorder.frames[oldName].selected = '#7E7558'
    } else {
      this.recorder.frames[oldName].selected = '#292C3E'
    }
    this.recorder.selectedFrame = this.recorder.frames[name]
    if(this.recorder.frames[name].promo == true) {
      this.recorder.frames[name].selected = '#FFDD5B'
    } else {
      this.recorder.frames[name].selected = '#4FEAAE'
    }
    this.detectChanges()
  }
  openFramesMenu(){
    this.framesMenu = true;
    this.containerOpen = true;
    this.detectChanges()
  }
  closeFramesMenu(){
    this.framesMenu = false;
    this.containerOpen = false;
    this.detectChanges()
  }

  nextSlide(){
    let maxPage = Math.round(this.framesPaths.length / 5) * 5
    if(this.framesMenu) {
      if(this.getIndex() >= maxPage) {
        this.ngxGlide.go('=0')
      } else {
        this.ngxGlide.go(`=${this.getIndex() + 5}`)
      }
    } else {
      if(this.getIndex() >= 5) {
        this.ngxGlide.go('=0')
      } else {
        this.ngxGlide.go(`=${this.getIndex() + 5}`)
      }
    }

  }
  prevSlide(){
    let maxPage = Math.round(this.framesPaths.length / 5) * 5
    if(this.framesMenu) {
      if(this.getIndex() <= 0) {
        this.ngxGlide.go(`=${maxPage}`)
      } else {
        this.ngxGlide.go(`=${this.getIndex() - 5}`)
      }
    } else {
      if(this.getIndex() <= 0) {
        this.ngxGlide.go('=5')
      } else {
        this.ngxGlide.go(`=${this.getIndex() - 5}`)
      }
    }

  }
  changeFilterItems() {
    console.log('changes')
    this.recorder.filterString = ''
    this.filterItems.forEach((item)=>{
      let value = (<HTMLInputElement>document.getElementById(item.id)).value
      if(typeof value != 'undefined') {
        item.value = value
      }
      this.recorder.filterString += item.nameStart + item.value + item.nameEnd;
    })
    
  }
  changeDuetStyles(){
    if(this.song.song.webcam){
      this.duetVideo.nativeElement.poster = '';
    }
    // this.canvas.nativeElement.style.left = '14%';
    // this.canvas.nativeElement.style.bottom = '28%';
    // this.canvas.nativeElement.style.width = '101vh';
    this.topUI.nativeElement.style.width = '136vh'
    this.duetVideo.nativeElement.style.height = '68vh'
    this.bottomUI.nativeElement.style.width = '136vh';
    this.detectChanges()
  }
  partsSelected() {
    if(typeof this.recorder.payload != 'undefined') {
      this.part = this.recorder.payload.part
    }
    this.containerOpen = false;
  }
  purchaseGold() {
    this.router.navigate(['/d/payments/membership'])
  }
  toggleCamera(){
    if(this.recorder.recorder.noCam) {
      this.recorder.recorder.videoOn()
      this.recVideo.nativeElement.style.opacity = 0
      this.filterCanvas.nativeElement.style.opacity = 10

      this.cam = true
      localStorage.setItem('toggleCamera', 'true')

    } else {
      this.recorder.recorder.videoOff()
      this.filterCanvas.nativeElement.style.opacity = 0
      this.recVideo.nativeElement.style.opacity = 10
      this.cam = false;
      localStorage.setItem('toggleCamera', 'false')
    }
    this.detectChanges()
  }
  changeCamSource(deviceId){
    this.detectChanges()

    this.recorder.recorder.changeVideoDevice(deviceId)
    this.videoDropDown = false;
    this.detectChanges();
  }
  changeMicSource(deviceId){
    this.recorder.recorder.changeAudioDevice(deviceId)
    this.micDropDown = false;
    this.detectChanges();
  }
  toggleVideoDropDown() {
    this.videoDropDown = !this.videoDropDown
    this.detectChanges()
  }
  toggleMicDropDown() {
    if(this.UIState == 'recording' || this.UIState == 'countdown') return
    this.micDropDown = !this.micDropDown
    this.detectChanges()
  }
  backClicked(){
    this.router.navigate(['/d/sing']);
  }
  playheadUpdate(){4
    if(!this.scrubberOn) {
      return
    }
    this.playhead = this.recorder.superPlayer.playhead;
    console.log(this.playhead, this.duration )
    if(this.playhead >= (this.duration - 500)) {
      if(this.UIState == 'recording') {
        console.log('stopping recording')
        this.stopRecording();
      } else {
        this.pause()
        this.recorder.seek(0)
        this.playhead = 0
      }
    }
    this.detectChanges()
  }
  msToTime(s) {
    let pad = (n, z = 2) => ('00' + n).slice(-z);
    return pad((s%3.6e6)/6e4 | 0) + ':' + pad((s%6e4)/1000|0);
  }
  startScrubber(){

    this.scrubberOn = true;
      // if(typeof this.interval != 'undefined') {
      //   this.stopScrubber()
      // }
      this.interval = setInterval(()=>{
        this.recorder.superPlayer.getPosition();
      }, 100);
      console.log('starting scrubber')
  }
  stopScrubber(){
    console.log('scrubber off')
      this.scrubberOn = false;
      clearInterval(this.interval);
      this.interval = null;
  }
  changeRangeTime(e) {
    console.log('change range time')
    if(this.scrubberOn) {
      this.stopScrubber()

    }
    this.playhead = parseFloat(e)
    this.detectChanges()
}
  seek(e) {
    if(this.UIState == 'review') {
      this.pause();
    }
    e = parseFloat(e)
    this.recorder.seek(e)
    this.playhead = e
    if(this.recorder.superPlayer.playing && !this.scrubberOn) {
      this.startScrubber()
    }
    this.detectChanges()
  }
  tryAgain() {
    gtag('event', 'sing', {
      action: 'Try Again',
      songId: this.id.toString()
    });
    this.restarting = true;
    let currentUrl;
    //window.location.href = `/?pitch=${this.pitch}&volume=${this.volume}&micVolume=${this.micVolume}`
    if(this.router.url.includes('?')) {
      currentUrl = this.router.url.substr(0, this.router.url.indexOf('?'))
    } else {
      currentUrl = this.router.url;
    }
    this.router.navigate([currentUrl], {queryParams: { pitch: this.pitch, volume: this.musicVolume, ...(typeof this.filterIndex != 'undefined' && {filterName: this.filterName, filterIndex: this.filterIndex}), ...(typeof this.recorder.selectedFrame.name != 'undefined' && {frameName: this.recorder.selectedFrame.name}) }});
    setTimeout(()=>{
      window.location.reload()
    }, 500)
  }
  startCountdown(){
    this.countdown.nativeElement.style.display = 'flex'
    this.UIState = 'countdown'
    this.changeUIState()
    let interval = setInterval(()=>{
      if(this.count == 1) {
        clearInterval(interval)
        this.countdown.nativeElement.style.display = 'none'
        this.startRecording()
        return
      }
      this.count--
      this.detectChanges()

    }, 1000)
  }
  startRecording() {
    gtag('event', 'sing', {
      action: 'Record Song',
      songId: this.id.toString()
    });
    this.recorder.record()
    this.playhead = 0
    this.singService.startRecording(this.id, this.jam).subscribe(result => {
      this.uuid = result;
    });
    this.UIState = 'recording'
    this.startScrubber()
    this.changeUIState()
  }
  stopRecording() {
    this.singService.stopRecording(this.id, this.uuid, this.jam).subscribe((result) => {
      console.log(result);
    });
    this.pause()
    this.recorder.stopRecording();
    this.stopScrubber()
    this.finished = true;
    this.UIState = 'reviewPaused'
    this.recVideo.nativeElement.style.opacity = 10
   // this.filterCanvas.nativeElement.style.opacity = 0

    this.seek(0)
    this.changeUIState()
  }
    pause(){
      this.recorder.pause()
      if (this.recorder.mode == 'review'){
        this.UIState = 'reviewPaused'
      } else {
        this.UIState = 'practicingPaused'
      }
      this.stopScrubber();
      this.changeUIState()
    }
    play(){
      this.recorder.play()
      if(this.recorder.recording){
        this.UIState = 'recording'
      } else if (this.recorder.mode == 'review'){
        gtag('event', 'sing', {
          action: 'Review Recording',
          songId: this.id.toString()
        });
        this.UIState = 'review'
      } else {
        gtag('event', 'sing', {
          action: 'Preview Song',
          songId: this.id.toString()
        });
        this.UIState = 'practicing'
      }
      this.changeUIState()
      this.startScrubber()
  }
  durationUpdate() {
    this.duration = this.recorder.superPlayer.duration
    this.seek(0)
    this.playhead = 0
    let sync = parseInt(localStorage.getItem("sync"))
    if (sync) {
      this.sync = sync
      this.recorder.sync(this.sync);
    }
    this.toastr.success('Review loaded!')
    this.detectChanges()
  }
  onPitchClick() {
    this.containerOpen = true;
    this.pitchBar = true;
    this.detectChanges()
  }
  onPitchChange(e) {
    console.log(e)
    this.pitch = e
    this.recorder.changePitch(e)
  }
  onPitchClose() {
    this.pitchBar = false;
    this.containerOpen = false;
    this.detectChanges()
  }
  onSyncClick() {
    this.containerOpen = true;
    this.syncBar = true;
    this.detectChanges()
  }
  onSyncChange(e) {
    console.log('sync changed to ' + e)
    if(this.recorder.superPlayer.playing) {
      this.pause()
      this.UIState = 'reviewPaused'
      this.changeUIState()
    }
    this.sync = e
    localStorage.setItem("sync", String(this.sync))
    this.recorder.sync(e)
  }
  onSyncClose() {
    this.containerOpen = false;
    this.syncBar = false;
    this.detectChanges()
  }
  onVolumeClick() {
    this.containerOpen = true;
    this.volumeBar = true;
    this.detectChanges()
  }
  onMicVolumeChange(e) {
    let value = parseFloat(e)
    this.micVolume = value;
    this.recorder.changeVocalVolume(value)
  }
  onMusicVolumeChange(e) {
    let value = parseFloat(e)
    this.musicVolume = value;
    this.recorder.changeMusicVolume(value)
  }
  onVolumeClose() {
    this.containerOpen = false;
    this.volumeBar = false;
    this.detectChanges()
  }
  onLyricSpeedClick() {
    this.containerOpen = true;
    this.lyricBar = true;
    this.detectChanges()
  }
  onLyricSpeedChange(e) {
    let value =  Math.round((e * 0.6) * 10) / 10 //rounds to one digit place
    if(this.recorder.superPlayer.playing) {
      this.pause();
      if(this.recorder.mode == 'review') {
        this.UIState = 'reviewPaused'
      } else if (this.UIState != 'default'){
        this.UIState = 'practicePaused'
      }
      this.changeUIState()
    }
    this.lyricSpeed = value / 0.6;
    this.recorder.lyricsPlayer.changeSpeed(value)
  }
  onLyricSpeedClose() {
    this.lyricBar = false;
    this.containerOpen = false;
    this.detectChanges()
  }

  onChangeMMSB() {
    if(this.mmsb) {
      this.recorder.superPlayer.mmsbOn()
    } else {
      this.recorder.superPlayer.mmsbOff()
    }
    console.log('changing MMSB')
    //this.mmsb = !this.mmsb

    this.detectChanges()
  }
  upload() {
    if(this.uploaded) {
      return
    }
    let song = this.jam ? this.song.song : this.song
    let payload = {
      recording: this.recorder.recorder.blob,
      payload: { meta: {latency: this.sync, mic_volume: this.micVolume, base_volume: this.musicVolume, pitch: this.pitch.toString()}, part: this.part},
      uuid: this.uuid,
      mmsb: this.mmsb,
      song: song
    }
    if (payload.payload.meta.pitch == '0' || this.jam) {
      delete payload.payload.meta.pitch;
    } else {
      payload.payload.meta.pitch = (this.pitch > 0 ? 'RAISE_0' : 'LOWER_0') + Math.abs(this.pitch);       
    }
    console.log(payload)
    this.data.storage = payload;
    this.uploaded = true;
    gtag('event', 'sing', {
      action: 'Proceed To Save',
      songId: this.id.toString()
    });
   // this.sendGtagFilters();
    this.router.navigate(['/d/sing-uploader']);
  }
  ngOnDestroy() {
    if(typeof this.recorder != 'undefined' && typeof this.recorder != 'string') {
      this.pause()
      this.recorder = null;
    }
    if(this.fullscreen && !this.restarting) {
      document.exitFullscreen()
    } 
    if(!this.uploaded || typeof this.recorder == 'string') {
      window.location.reload();
    }

  }
  onOtherSingers() {
    if(this.jam) {
      this.otherSingersElem.open(this.song.song.song.id);
    } else {
      this.otherSingersElem.open(this.id);
    }
  }
  toggleFullscreen() {
    if(this.fullscreen) {
      document.exitFullscreen()
      this.fullscreen = false;
    } else {
      this.recorderContainer.nativeElement.requestFullscreen()
      this.fullscreen = true;
    }
  }
changeUIState() {
  //behold, the grand switch statement!
  switch(this.UIState) {
    case 'default':
      this.UI.recordButton = true;
      this.UI.filterButton = true;
      this.UI.framesButton = true;
      this.UI.practiceButton = true;
      this.UI.restartButton = false;
      this.UI.reviewButton = false;
      this.UI.pitchButton = true;
      this.UI.volumeButton = true;
      this.UI.lyricButton = false;
      this.UI.syncButton = false;
      this.UI.mmsbButton = false;
      this.UI.cameraButton = true;
      this.UI.micButton = true;
      this.UI.saveOrTryAgainButtons = false;
      this.UI.pauseButton = false;
      this.UI.resumePracticeButton = false;
      this.UI.stopRecordingButton = false;
      break;
    case 'countdown':
        this.UI.recordButton = false;
        this.UI.filterButton = false;
        this.UI.framesButton = false;
        this.UI.practiceButton = false;
        this.UI.restartButton = false;
        this.UI.reviewButton = false;
        this.UI.pitchButton = false;
        this.UI.volumeButton = true;
        this.UI.lyricButton = false;
        this.UI.syncButton = false;
        this.UI.mmsbButton = false;
        this.UI.cameraButton = false;
        this.UI.micButton = true;
        this.UI.saveOrTryAgainButtons = false;
        this.UI.pauseButton = false;
        this.UI.resumePracticeButton = false;
        this.UI.stopRecordingButton = false;
        break;
    case 'noPermissions':
          this.UI.recordButton = false;
          this.UI.filterButton = false;
          this.UI.framesButton = false;
          this.UI.practiceButton = false;
          this.UI.restartButton = false;
          this.UI.reviewButton = false;
          this.UI.pitchButton = false;
          this.UI.volumeButton = false;
          this.UI.lyricButton = false;
          this.UI.syncButton = false;
          this.UI.mmsbButton = false;
          this.UI.cameraButton = false;
          this.UI.micButton = false;
          this.UI.saveOrTryAgainButtons = false;
          this.UI.pauseButton = false;
          this.UI.resumePracticeButton = false;
          this.UI.stopRecordingButton = false;
          break;
    case 'recording':
      this.UI.recordButton = false;
      this.UI.filterButton = false;
      this.UI.framesButton = false;
      this.UI.practiceButton = false;
      this.UI.restartButton = false;
      this.UI.reviewButton = false;
      this.UI.pitchButton = false;
      this.UI.volumeButton = true;
      this.UI.lyricButton = false;
      this.UI.syncButton = false;
      this.UI.mmsbButton = false;
      this.UI.cameraButton = false;
      this.UI.micButton = true;
      this.UI.saveOrTryAgainButtons = false;
      this.UI.pauseButton = false;
      this.UI.resumePracticeButton = false;
      this.UI.stopRecordingButton = true;
      this.step = 'Recording'
      break;
    case 'practicing':
      this.UI.recordButton = false;
      this.UI.filterButton = true;
      this.UI.framesButton = true;
      this.UI.practiceButton = false;
      this.UI.restartButton = false;
      this.UI.reviewButton = false;
      this.UI.pitchButton = true;
      this.UI.volumeButton = true;
      this.UI.lyricButton = false;
      this.UI.syncButton = false;
      this.UI.mmsbButton = false;
      this.UI.cameraButton = true;
      this.UI.micButton = true;
      this.UI.saveOrTryAgainButtons = false;
      this.UI.pauseButton = true;
      this.UI.resumePracticeButton = false;
      this.UI.stopRecordingButton = false;
      this.step = 'Practice Mode'
      break;
    case 'practicingPaused':
      this.UI.recordButton = true;
      this.UI.filterButton = true;
      this.UI.framesButton = true;
      this.UI.practiceButton = false;
      this.UI.restartButton = true;
      this.UI.reviewButton = false;
      this.UI.pitchButton = true;
      this.UI.volumeButton = true;
      this.UI.lyricButton = false;
      this.UI.syncButton = false;
      this.UI.mmsbButton = false;
      this.UI.cameraButton = true;
      this.UI.micButton = true;
      this.UI.saveOrTryAgainButtons = false;
      this.UI.pauseButton = false;
      this.UI.resumePracticeButton = true;
      this.UI.stopRecordingButton = false;
      this.step = 'Practice Mode'
      break;
    case 'review':
      this.filters = false;
      this.UI.recordButton = false;
      this.UI.filterButton = false;
      this.UI.framesButton = false;
      this.UI.practiceButton = false;
      this.UI.restartButton = false;
      this.UI.reviewButton = false;
      this.UI.pitchButton = false;
      this.UI.volumeButton = true;
      this.UI.lyricButton = false;
      this.UI.syncButton = true;
      this.UI.mmsbButton = true;
      this.UI.cameraButton = false;
      this.UI.micButton = false;
      this.UI.saveOrTryAgainButtons = true;
      this.UI.pauseButton = true;
      this.UI.resumePracticeButton = false;
      this.UI.stopRecordingButton = false;
      this.step = 'Review'
      break;
    case 'reviewPaused':
        this.UI.recordButton = false;
        this.UI.filterButton = false;
        this.UI.framesButton = false;
        this.UI.practiceButton = false;
        this.UI.restartButton = false;
        this.UI.reviewButton = false;
        this.UI.pitchButton = false;
        this.UI.volumeButton = true;
        this.UI.lyricButton = false;
        this.UI.syncButton = true;
        this.UI.mmsbButton = true;
        this.UI.cameraButton = false;
        this.UI.micButton = false;
        this.UI.saveOrTryAgainButtons = true;
        this.UI.pauseButton = false;
        this.UI.resumePracticeButton = true;
        this.UI.stopRecordingButton = false;
        this.step = 'Review'
        break;
  }
  // if(this.jam && (this.UIState == 'default' || this.UIState == 'recording' || this.UIState == 'countdown' || this.UIState == 'practicing' || this.UIState == 'practicingPaused')) {
  //   this.UI.volumeButton = false;
  // }
  if(!this.profileService.user.gold) {
    console.log('user is not gold')
    this.UI.filterButton = false
    this.UI.framesButton = false
    this.UI.pitchButton = false;
  }
  if(this.recorder.noCam) {
    this.UI.cameraButton = false;
  }
  console.log(this.UIState)
 // if(this.jam && this.loaded) {
  //  this.changeDuetStyles()
  //} else {
    this.detectChanges();
  // }
  }

  toAdmin(ref){
    if (this.isControlPressed){
      this.userService.getIsAdmin().subscribe(response => {
        if (response.success) {
          let url: string = environment.baseAPIUrl + '/admin/music/songs/' + ref + '/lyrics';
          window.open(url)
          this.isControlPressed = false;
        }    
      }, error => {
      });
    }
    this.isControlPressed = false;
  }
  sendGtagFilters(){
    let filterName: string = this.filterName;
    let selectedFrameName: string = this.recorder.selectedFrame.name
    if (!filterName.length){
      filterName = 'none';
    }
    if (!selectedFrameName.length){
      selectedFrameName = 'none';
    }
    gtag('event', 'filters', {
      filter: filterName,
      frame: selectedFrameName   
    });
  }

  sendGtagLoad(recID, gold, solo, err ){
    if (err === 'none'){
      gtag('event', 'sing', {
        action: 'Load Song',
        songId: recID.toString(),
        solo: solo,
        goldMember: gold.toString()
        });
    } else{
      gtag('event', 'sing', {
        action: 'Load Song',
        songId: recID.toString(),
        solo: solo,
        error: err,
        goldMember: gold.toString()
        });
    }
  }

}