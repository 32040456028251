<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-body" *ngIf="user && !giftItem">
    <div class="modal-body-title">
      <h4>Send {{user.screen_name}} a gift</h4>
      <div class="close-modal" (click)="c('ok')">
        <app-icon-close size="18"></app-icon-close>
      </div>
    </div>
    <div class="gift-items">
      <div class="gift-item ss-glow-box" (click)="handleGoldMembership()">
        <label>Gold Membership</label>
        <img src="/assets/pictures/goldmembership.png" />
      </div>
      <div class="gift-item ss-glow-box" (click)="handleSnapCredits()">
        <label>Snap a Tip!</label>
        <img src="/assets/pictures/ic_gold_snap.png" />
      </div>
      <div class="gift-item ss-glow-box" (click)="handleSnapicon()">
        <label>Snapicon</label>
        <img src="/assets/pictures/snapicons.png" />
      </div>
      <!-- LIST TRINKETS, USERNAME, GMG -->
      <div class="gift-item ss-glow-box" *ngFor="let gift of trinkets" (click)="handleDistribute(gift)">
        <label>{{gift.product.name}} <span>{{gift.quantity}}</span></label>
        <img [src]="gift.product.photo" />
      </div>
      <div class="gift-item ss-glow-box" *ngFor="let gift of coloredUsernames" (click)="handleDistribute(gift)">
        <label>{{gift.product.name}} <span>{{gift.quantity}}</span></label>
        <img [src]="gift.product.photo" />
      </div>
      <div class="gift-item ss-glow-box" *ngFor="let gift of gmgs" (click)="handleDistribute(gift)">
        <label>{{gift.product.name}} <span>{{gift.quantity}}</span></label>
        <img [src]="gift.product.photo" />
      </div>
      <div class="gift-item ss-glow-box" (click)="handleCurrentPromotions()">
        <label>Current Promotions</label>
        <img src="/assets/pictures/promotions.png" />
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="giftItem && !giftSent">
    <div class="gift-details">
      <h4>Send {{user.screen_name}} a gift</h4>
      <div class="close-modal2" (click)="c('ok')">
        <app-icon-close size="18"></app-icon-close>
      </div>
      <div class="gift-item ss-glow-box">
        <label>{{giftItem.product.name}} <span>{{giftItem.quantity}}</span></label>
        <img [src]="giftItem.product.photo" />
      </div>
      <div class="ss-glow-box ss-form-dropdown">
        <label>Gift Number</label>
        <div class="ss-form-dropdown-block" ngbDropdown #numberDrop="ngbDropdown">
          <button class="btn" id="sendGiftNumber" ngbDropdownAnchor (focus)="numberDrop.open()">
            {{giftNumber}}
          </button>
          <div ngbDropdownMenu aria-labelledby="sendGiftNumber">
            <button ngbDropdownItem *ngFor="let quantity of quantities" (click)="changeGiftNumber(quantity)">{{quantity}}</button>
          </div>
        </div>
      </div>
      <div class="ss-glow-box ss-form-dropdown">
        <label>Anonymous</label>
        <app-form-switch
          label=" "
          [value]="anonymous"
          (changed)="changeAnonymous($event)"
        ></app-form-switch>
      </div>
      <div class="ss-glow-box" style="height: auto" *ngIf="showCommentBox">
        <app-ss-editor (editorContentChange)="setMessage($event)" [showSetting]="false" [hideToolbar]="true" [showPostButton]="false"></app-ss-editor>
      </div>
      <!-- <div class="snap-message" *ngIf="!anonymous">
        <textarea class="form-control" placeholder="Message..." rows="5" [(ngModel)]="message"></textarea>
      </div> -->


      <div class="modal-actions">
        <button class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray"
          (click)="c('Cross click')">CANCEL</button>
        <button class="btn btn-primary" (click)="handleSubmit()" [disabled]="isSending">
          <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="isSending"></i>
          SEND
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body gift-sent-success" *ngIf="giftSent">
    <div class="icon-inner circle-ripple circle-green">
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <div class="icon-holder">
        <app-icon-check color="white" size="36"></app-icon-check>
      </div>
    </div>
    <h4>You've sent {{user.screen_name}} a gift!</h4>
    <div class="modal-close-button">
      <button class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray"
        (click)="close()">CLOSE</button>
    </div>
  </div>
</ng-template>
