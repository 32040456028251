<div class="app-icon" (mouseover)="onHover()" (mouseleave)="onLeave()">
    <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 52.1 (67048) - http://www.bohemiancoding.com/sketch -->
    <title>Archive this conversation</title>
    <desc>Created with Sketch.</desc>
    <defs>
        <path d="M16,5 L16,2 L2,2 L2,5 L6,5 C6,6.65685425 7.34314575,8 9,8 C10.6568542,8 12,6.65685425 12,5 L16,5 Z M16,0 C17.1045695,0 18,0.8954305 18,2 L18,9 C18,10.1045695 17.1045695,11 16,11 L2,11 C0.8954305,11 0,10.1045695 0,9 L0,2 C0,0.8954305 0.8954305,0 2,0 L16,0 Z M0,12 L6,12 C6,13.6568542 7.34314575,15 9,15 C10.6568542,15 12,13.6568542 12,12 L18,12 L18,16 C18,17.1045695 17.1045695,18 16,18 L2,18 C0.8954305,18 0,17.1045695 0,16 L0,12 Z"
            id="path-icon-archive"></path>
    </defs>
    <g id="inbox-multiple" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g>
            <mask id="mask-2" fill="white">
                <use xlink:href="#path-icon-archive"></use>
            </mask>
            <use id="Mask" [attr.fill]="mainColor" fill-rule="nonzero" xlink:href="#path-icon-archive"></use>
        </g>
    </g>
    </svg>
</div>