import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-message-date-line',
  templateUrl: './message-date-line.component.html',
  styleUrls: ['./message-date-line.component.scss']
})
export class MessageDateLineComponent implements OnInit {
  @Input() date: string;

  constructor() { }

  ngOnInit() {
  }

}
