import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
//import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

// 3rd party
//import { NguCarouselModule } from '@ngu/carousel';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QuillModule } from 'ngx-quill';
 import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
 import { VgCoreModule } from '@videogular/ngx-videogular/core';
 import { VgControlsModule } from '@videogular/ngx-videogular/controls';
 import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { MomentModule } from 'ngx-moment';
import { SharedIconsModule } from './icons/icons.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { PipesModule } from './pipes/pipes.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxFileDropModule } from 'ngx-file-drop';
import { WebcamModule } from 'ngx-webcam';

import { CommentsHeaderComponent } from './comments/comments-header/comments-header.component';
import { ReplyCommentComponent } from './comments/reply-comment/reply-comment.component';
import { CommentComponent } from './comments/comment/comment.component';
import { CommentsComponent } from './comments';
import { FeaturedListComponent } from './featured-list';

import { CheckboxComponent } from './checkbox/checkbox.component';
import { AvatarThumbComponent } from './avatar-thumb/avatar-thumb.component';
import { RecordingPlayerComponent } from './recording-player/recording-player.component';
import { ReactionComponent } from './reaction/reaction.component';
import { TimeStampComponent } from './time-stamp/time-stamp.component';
import { StatusBadgeComponent } from './status-badge/status-badge.component';
import { UserMessageInfoComponent } from './user-message-info/user-message-info.component';
import { SsEditorComponent } from './comments/ss-editor/ss-editor.component';
import { RecommendedCarouselComponent } from './recommended-carousel/recommended-carousel.component';
import { FeaturedGridComponent } from './featured-grid/featured-grid.component';
import { FeaturedImageComponent } from './featured-image/featured-image.component';
import { UserBadgeLevelComponent } from './user-badge-level/user-badge-level.component';
import { SimpleListComponent } from './simple-list/simple-list.component';
import { ContentBoxComponent } from './content-box/content-box.component';
import { ActionsButtonComponent } from './actions-button/actions-button.component';
import { ScrollableContainerDirective } from './directives/scrollable-container.directive';
import { ReportModalContentComponent } from './report-modal-content/report-modal-content.component';
import { NotificationsDropdownComponent } from './notifications-dropdown/notifications-dropdown.component';
import { SearchBarInputComponent } from './search-bar-input/search-bar-input.component';
import { StepNavComponent } from './step-nav/step-nav.component';
import { TermsModalComponent } from './terms-modal/terms-modal.component';
import { NewPlaylistComponent } from './new-playlist/new-playlist.component';
import { UpdateUserStatusComponent } from './update-user-status/update-user-status.component';
import { AddToPlaylistComponent } from './add-to-playlist/add-to-playlist.component';
import { DropzoneComponent } from './dropzone/dropzone.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { AdjustBarComponent } from './adjust-bar/adjust-bar.component';
import { SyncBarComponent } from './sync-bar/sync-bar.component';
import { ListPaginationComponent } from './list-pagination/list-pagination.component';
import { MessageBoardListComponent } from './message-board-list/message-board-list.component';
import { MessageBoardThumbComponent } from './message-board-thumb/message-board-thumb.component';
import { ListHeaderComponent } from './featured-list/list-header/list-header.component';
import { TagComponent } from './tag/tag.component';
import { RecorderSyncBarComponent } from './recorder-sync-bar/recorder-sync-bar.component';
import { BadgeComponent } from './badge/badge.component';
import { SsUserComponent } from './ss-user/ss-user.component';
import { SsUserNameComponent } from './ss-user-name/ss-user-name.component';
import { SsRecordingComponent } from './ss-recording/ss-recording.component';
import { SsArtistComponent } from './ss-artist/ss-artist.component';
import { SsSongComponent } from './ss-song/ss-song.component';
import { SsFeaturedListComponent } from './ss-featured-list/ss-featured-list.component';
import { PriceBoxComponent } from './price-box/price-box.component';
import { SelectBoxComponent } from './select-box/select-box.component';
import { MoreDotMenuComponent } from './more-dot-menu/more-dot-menu.component';
import { SsPlaylistComponent } from './ss-playlist/ss-playlist.component';
import { TagsComponent } from './tags/tags.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { OtherSingersComponent } from './other-singers/other-singers.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { SsImageComponent } from './ss-image/ss-image.component';
import { CharCounterComponent } from './char-counter/char-counter.component';
import { SsReportModalComponent } from './ss-report-modal/ss-report-modal.component';
import { SsWebcamPhotoComponent } from './ss-webcam-photo/ss-webcam-photo.component';
import { UserSnapiconCarouselComponent } from './user-snapicon-carousel/user-snapicon-carousel.component';
import { AddIcUsersComponent } from './add-ic-users/add-ic-users.component';
import { SplashModalComponent } from './splash-modal/splash-modal.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import {
  SnapiconCarouselComponent,
  CarouselItemElement,
} from './custom-snapicon-carousel/snapicon-carousel.component';
import { CarouselItemDirective } from './directives/carousel-item.directive';
import { SsUserPhotoComponent } from './ss-user-photo/ss-user-photo.component';
import { MessageDateLineComponent } from './message-date-line/message-date-line.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BreadcrumbItemComponent } from './breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { LoveButtonComponent } from './love-button/love-button.component';
import { SsInputComponent } from './ss-input/ss-input.component';
import { VertSpaceComponent } from './vert-space/vert-space.component';
import { SsFeaturedGridComponent } from './ss-featured-grid/ss-featured-grid.component';
import { GenreItemComponent } from './genre-item/genre-item.component';
import { SsAnnouncementComponent } from './ss-announcement/ss-announcement.component';
import { SortIconFilterComponent } from './sort-icon-filter/sort-icon-filter.component';
import { RecordingSnappeninItemComponent } from './recording-snappenin-item/recording-snappenin-item.component';
import { SnappeninCarouselComponent } from './snappenin-carousel/snappenin-carousel.component';
import { SsContestComponent } from './ss-contest/ss-contest.component';
import { SsRoundComponent } from './ss-round/ss-round.component';
import { YesNoInlineComponent } from './yes-no-inline/yes-no-inline.component';
import { AuditionRecordingToContestComponent } from './audition-recording-to-contest/audition-recording-to-contest.component';

import { ManageCohostComponent } from './manage-cohost/manage-cohost.component';
import { ContestCreateNextRoundComponent } from './contest-create-next-round/contest-create-next-round.component';
import { ContestDropImageComponent } from './contest-create-next-round/contest-drop-image/contest-drop-image.component';
import { DatetimePickerComponent } from './datetime-picker/datetime-picker.component';
import { FormSwitchComponent } from './form/form-switch/form-switch.component';
import { FormCheckComponent } from './form/form-check/form-check.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentErrorComponent } from './payment-error/payment-error.component';
import { SsStoreBoxComponent } from './ss-store-box/ss-store-box.component';
import { SsPriceComponent } from './ss-price/ss-price.component';
import { SsStoreOptionComponent } from './ss-store-option/ss-store-option.component';
import { PpCheckoutComponent } from './pp-checkout/pp-checkout.component';
//import { CreditCardDirectivesModule } from 'angular-cc-library';
import { CcCheckoutComponent } from './cc-checkout/cc-checkout.component';
import { SubmitRecordingToContestComponent } from './submit-recording-to-contest/submit-recording-to-contest.component';
import { SubscriptionStatusComponent } from './subscription-status/subscription-status.component';
import { SubscriptionAmountComponent } from './subscription-amount/subscription-amount.component';
import { MessageBoardItemComponent } from './message-board-item/message-board-item.component';
import { PurchaseGoldModalComponent } from './purchase-gold-modal/purchase-gold-modal.component';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { FullscreenModalComponent } from './fullscreen-modal/fullscreen-modal.component';
import { PlaylistModalComponent } from './playlist-modal/playlist-modal.component';
import { ProfileDropdownComponent } from './profile-dropdown/profile-dropdown.component';
import { OkModalComponent } from './ok-modal/ok-modal.component';
import { RecordingStatComponent } from './recording-stat/recording-stat.component';
import { SsSearchUsersComponent } from './ss-search-users/ss-search-users.component';
import { SsSnapiconPreviewComponent } from './ss-snapicon-preview/ss-snapicon-preview.component';
import { PaypalButtonCheckoutComponent } from './paypal-button-checkout/paypal-button-checkout.component';
import { LoadingStateComponent } from './loading-state/loading-state.component';
import { CreditsCheckoutComponent } from './credits-checkout/credits-checkout.component';
import { BlockModalComponent } from './block-modal/block-modal.component';
import { SsSearchAndBlockUsersComponent } from '@app/shared/ss-search-and-block-users/ss-search-and-block-users.component';
import { WarningModalComponent } from '@app/shared/warning-modal/warning-modal.component';
import { ExplicitTagComponent } from './explicit-tag/explicit-tag.component';
import { SnapCreditModalComponent } from './snap-credit-modal/snap-credit-modal.component';
import { ShowcaseModalComponent } from './showcase-modal/showcase-modal.component';
import { SendGiftModalComponent } from './send-gift-modal/send-gift-modal.component';
import { SearchMemberModalComponent } from './search-member-modal/search-member-modal.component';
import { BlockedNotifyModalComponent } from './blocked-notify-modal/blocked-notify-modal.component';
import { SsConfirmModalComponent } from './ss-confirm-modal/ss-confirm-modal.component';
import { SsPaypalConfirmModalComponent } from './ss-paypal-confir-modal/ss-paypal-confirm-modal.component';
import { SocialShareComponent } from './social-share/social-share.component';
import { SsThreadComponent } from './ss-thread/ss-thread.component';
import { SortArrowComponent } from './sort-arrow/sort-arrow.component';
import { TopMessageComponent } from '../top-message/top-message.component';
import { NgxGlideModule } from 'ngx-glide';
import { RosesComponent } from './roses/roses.component';
import { UserOnlineIconComponent } from './user-online-icon/user-online-icon.component';
import { TopHorizontalListComponent } from './top-horizontal-list/top-horizontal-list.component';
import { AdsenseModule } from 'ng2-adsense';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    // angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // 3rd party
    //NguCarouselModule,
    NgxGlideModule,
    InfiniteScrollModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    MomentModule,
    WebcamModule,
    QuillModule.forRoot(),
    NgbModule,
    InlineSVGModule.forRoot(),
    //ScrollToModule.forRoot(),
    PipesModule,
    SharedIconsModule,
    ClickOutsideModule,
    PerfectScrollbarModule,
    NgxFileDropModule,
    //CreditCardDirectivesModule,
    AdsenseModule.forRoot({
      // adClient: 'ca-pub-4776037543768273',
      // adSlot: 9989671476,
     }),
  ],
  declarations: [
    CommentsComponent,
    CommentComponent,
    ReplyCommentComponent,
    CommentsHeaderComponent,
    FeaturedListComponent,
    //NotificationIndicatorComponent,
    CheckboxComponent,
    AvatarThumbComponent,
    RecordingPlayerComponent,
    ReactionComponent,
    TimeStampComponent,
    StatusBadgeComponent,
    UserMessageInfoComponent,
    SsEditorComponent,
    RecommendedCarouselComponent,
    FeaturedGridComponent,
    FeaturedImageComponent,
    UserBadgeLevelComponent,
    SimpleListComponent,
    ContentBoxComponent,
    ActionsButtonComponent,
    ScrollableContainerDirective,
    ReportModalContentComponent,
    NotificationsDropdownComponent,
    SearchBarInputComponent,
    StepNavComponent,
    TermsModalComponent,
    NewPlaylistComponent,
    UpdateUserStatusComponent,
    DropzoneComponent,
    ListPaginationComponent,
    ProgressBarComponent,
    MessageBoardListComponent,
    MessageBoardThumbComponent,
    ListHeaderComponent,
    AdjustBarComponent,
    SyncBarComponent,
    TagComponent,
    RecorderSyncBarComponent,
    BadgeComponent,
    SsUserComponent,
    SsUserNameComponent,
    SsRecordingComponent,
    SsArtistComponent,
    SsSongComponent,
    SsFeaturedListComponent,
    SsFeaturedGridComponent,
    PriceBoxComponent,
    SelectBoxComponent,
    MoreDotMenuComponent,
    SsPlaylistComponent,
    AddToPlaylistComponent,
    PlaylistModalComponent,
    TagsComponent,
    NotificationModalComponent,
    ConfirmationModalComponent,
    InfiniteScrollComponent,
    OtherSingersComponent,
    SsImageComponent,
    CharCounterComponent,
    SsReportModalComponent,
    SsWebcamPhotoComponent,
    UserSnapiconCarouselComponent,
    AddIcUsersComponent,
    SplashModalComponent,
    OkModalComponent,
    SignUpFormComponent,
    CarouselItemDirective,
    CarouselItemElement,
    SnapiconCarouselComponent,
    SsUserPhotoComponent,
    MessageDateLineComponent,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    LoveButtonComponent,
    SsInputComponent,
    VertSpaceComponent,
    GenreItemComponent,
    SsAnnouncementComponent,
    SortIconFilterComponent,
    RecordingSnappeninItemComponent,
    SnappeninCarouselComponent,
    SsContestComponent,
    SsRoundComponent,
    YesNoInlineComponent,
    AuditionRecordingToContestComponent,
    SubmitRecordingToContestComponent,
    ManageCohostComponent,
    ContestCreateNextRoundComponent,
    ContestDropImageComponent,
    DatetimePickerComponent,
    FormSwitchComponent,
    FormCheckComponent,
    SubscriptionStatusComponent,
    SubscriptionAmountComponent,
    MessageBoardItemComponent,
    PurchaseGoldModalComponent,
    PaymentSuccessComponent,
    PaymentErrorComponent,
    SsStoreBoxComponent,
    SsPriceComponent,
    SsStoreOptionComponent,
    PpCheckoutComponent,
    CcCheckoutComponent,
    ConfirmModalComponent,
    FullscreenModalComponent,
    ProfileDropdownComponent,
    RecordingStatComponent,
    SsSearchUsersComponent,
    SsSnapiconPreviewComponent,
    PaypalButtonCheckoutComponent,
    LoadingStateComponent,
    CreditsCheckoutComponent,
    BlockModalComponent,
    WarningModalComponent,
    SsSearchAndBlockUsersComponent,
    ExplicitTagComponent,
    SnapCreditModalComponent,
    ShowcaseModalComponent,
    SendGiftModalComponent,
    SearchMemberModalComponent,
    BlockedNotifyModalComponent,
    SsConfirmModalComponent,
    SsPaypalConfirmModalComponent,
    SocialShareComponent,
    SsThreadComponent,
    SortArrowComponent,
    TopMessageComponent,
    RosesComponent,
    UserOnlineIconComponent,
    TopHorizontalListComponent,

  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  exports: [
    PipesModule,
    CommentsComponent,
    CommentComponent,
    ReplyCommentComponent,
    CommentsHeaderComponent,
    FeaturedListComponent,
    //NotificationIndicatorComponent,
    CheckboxComponent,
    AvatarThumbComponent,
    RecordingPlayerComponent,
    ReactionComponent,
    TimeStampComponent,
    StatusBadgeComponent,
    UserMessageInfoComponent,
    SsEditorComponent,
    RecommendedCarouselComponent,
    FeaturedGridComponent,
    FeaturedImageComponent,
    UserBadgeLevelComponent,
    SimpleListComponent,
    ContentBoxComponent,
    ActionsButtonComponent,
    ScrollableContainerDirective,
    NotificationsDropdownComponent,
    SearchBarInputComponent,
    StepNavComponent,
    TermsModalComponent,
    NewPlaylistComponent,
    UpdateUserStatusComponent,
    DropzoneComponent,
    ListPaginationComponent,
    ProgressBarComponent,
    MessageBoardListComponent,
    MessageBoardThumbComponent,
    ListHeaderComponent,
    AdjustBarComponent,
    SyncBarComponent,
    TagComponent,
    RecorderSyncBarComponent,
    BadgeComponent,
    SsUserComponent,
    SsUserNameComponent,
    SsRecordingComponent,
    SsArtistComponent,
    SsSongComponent,
    SsFeaturedListComponent,
    SsFeaturedGridComponent,
    PriceBoxComponent,
    SelectBoxComponent,
    MoreDotMenuComponent,
    SsPlaylistComponent,
    AddToPlaylistComponent,
    SnapCreditModalComponent,
    ShowcaseModalComponent,
    PlaylistModalComponent,
    TagsComponent,
    NotificationModalComponent,
    ConfirmationModalComponent,
    InfiniteScrollComponent,
    OtherSingersComponent,
    SsImageComponent,
    CharCounterComponent,
    SsReportModalComponent,
    SsWebcamPhotoComponent,
    UserSnapiconCarouselComponent,
    AddIcUsersComponent,
    SplashModalComponent,
    OkModalComponent,
    SignUpFormComponent,
    SnapiconCarouselComponent,
    SsUserPhotoComponent,
    MessageDateLineComponent,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    LoveButtonComponent,
    SsInputComponent,
    VertSpaceComponent,
    GenreItemComponent,
    SsAnnouncementComponent,
    SortIconFilterComponent,
    RecordingSnappeninItemComponent,
    SnappeninCarouselComponent,
    SsContestComponent,
    SsRoundComponent,
    YesNoInlineComponent,
    AuditionRecordingToContestComponent,
    SubmitRecordingToContestComponent,
    ManageCohostComponent,
    ContestCreateNextRoundComponent,
    ContestDropImageComponent,
    DatetimePickerComponent,
    FormCheckComponent,
    FormSwitchComponent,
    SubscriptionStatusComponent,
    SubscriptionAmountComponent,
    MessageBoardItemComponent,
    PurchaseGoldModalComponent,
    PaymentSuccessComponent,
    PaymentErrorComponent,
    SsStoreBoxComponent,
    SsStoreOptionComponent,
    CcCheckoutComponent,
    PpCheckoutComponent,
    ConfirmModalComponent,
    FullscreenModalComponent,
    ProfileDropdownComponent,
    RecordingStatComponent,
    SsSearchUsersComponent,
    SearchMemberModalComponent,
    SsSnapiconPreviewComponent,
    SsPriceComponent,
    PaypalButtonCheckoutComponent,
    LoadingStateComponent,
    CreditsCheckoutComponent,
    BlockModalComponent,
    WarningModalComponent,
    SsSearchAndBlockUsersComponent,
    SendGiftModalComponent,
    BlockedNotifyModalComponent,
    SsConfirmModalComponent,
    SsPaypalConfirmModalComponent,
    SocialShareComponent,
    SsThreadComponent,
    SortArrowComponent,
    TopMessageComponent,
    UserOnlineIconComponent,
    TopHorizontalListComponent,

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  //entryComponents: [ReportModalContentComponent, DatetimePickerComponent],
})
export class SharedModule {}
