import { ChangeDetectorRef, Component, OnInit, ViewChild, NgZone} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import {
  GenresService,
  UtilService,
  EventService,
  RecordingService,
  SongService,
  ProfileService,
  HelperService,
} from '@app/core';
import { ArtistService } from '@app/core/services/artist.service';

declare var $: any;

@Component({
  selector: 'app-sing-featured-songs',
  templateUrl: './sing-featured-songs.component.html',
  styleUrls: ['./sing-featured-songs.component.scss']
})
export class SingFeaturedSongsComponent implements OnInit {
  recording;
  titleLength = 24;
  duets = [];
  artists = [];
  currentPage = 1;
  trending$;
  selectedLetter = '';
  lastSelectedLetter;
  recordingSortOptions = [];
  songSortOptions = [];
  songSortOptionsFavoritesFirst = [];
  totalPages = 0;
  artistSortOptions = [];
  genreSortOptions = this.util.recordingSortOptions;
  @ViewChild('goldcontent') private goldModal;
  itemsPerPage = 30;
  filterByDuet = false;
  selectedGenreIndex = 0;
  selectedDecadeIndex = 0;
  decadesArray = [];
  letters = [];
  genresArray = [{name: 'All', id: 90000}]

  genresData: any = {
    ...this.util.generateGridData(),
    sort: this.genreSortOptions[0],
    fetch: this.genreService.getGenres.bind(this.genreService),
  };


  featuredData: any = {
    ...this.util.generateGridData(),
    perPage: this.itemsPerPage,
    fetch: this.songService.getFeaturedSongs.bind(this.songService),
  };

  
  constructor(
    private recordingService: RecordingService,
    private songService: SongService,
    private genreService: GenresService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private artistService: ArtistService,
    private toastService: ToastrService,
    public profileService: ProfileService,
    private helperService: HelperService,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    private util: UtilService,
    private ngZone: NgZone
  ) {
    this.songSortOptionsFavoritesFirst = this.util.songSortOptionsFavoritesFirst;
    this.featuredData.sort = this.util.songSortOptionsFavoritesFirst[0];
  }

  ngOnInit() {
    this.genresData.fetch().subscribe((response)=>{
      console.log(response)
      response.data.forEach(item => {
        this.genresArray.push(item)
      })
    })

    this.decadesArray = this.util.getDecadeArray();
    this.letters = this.util.getLettersArray();

    this.route.queryParams.subscribe(params => {
      if(typeof params.page != 'undefined') {
        this.currentPage = params.page
      }
    });

    this.route.queryParams.subscribe(params => {
      if(typeof params.letter != 'undefined') {
        this.selectedLetter = '';
        this.handleFilter(params.letter);
      }
    });


    this.route.queryParams.subscribe(params => {
      if(typeof params.decade != 'undefined') {
        this.featuredData.decade = params.decade;
        this.selectedDecadeIndex = this.decadesArray.findIndex(element => element.includes(params.decade))
      }
    });

    this.route.queryParams.subscribe(params => {
      if(typeof params.genre != 'undefined') {
       this.selectedGenreIndex = params.genre;
       this.genreClick(params.genre);
      }
    });   

    this.route.queryParams.subscribe(params => {
      if(typeof params.duet != 'undefined') {
       this.filterByDuet = params.duet;
       this.duetClick(true);
      }
    });   

    this.onScrollEndHandle();
  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  back(){
    window.history.back(); 
  }
  setPageParam() {
    this.location.replaceState(this.location.path().split('?')[0], '');
    this.location.replaceState(this.location.path() + `?page=${this.currentPage}`);
    if(this.selectedLetter.length >0){
     this.location.replaceState(this.location.path() + `&letter=${this.selectedLetter}`);
    }
    if(this.featuredData.decade && this.featuredData.decade.length>0){
      this.location.replaceState(this.location.path() + `&decade=${this.featuredData.decade}`);
    }
    if(this.featuredData.filter.genre_id && this.featuredData.filter.genre_id>0){
      this.location.replaceState(this.location.path() + `&genre=${this.featuredData.filter.genre_id}`);
    }

    if(this.featuredData.filter.duet && this.featuredData.filter.duet === true){
      this.location.replaceState(this.location.path() + `&duet=true`);
    }


  }

  decadeClick(event) {
    this.featuredData.decade = this.decadesArray[event].substring(0, this.decadesArray[event].length - 2)
    if(event == 0) {
      this.featuredData.decade = '';
    }
    this.resetDataObj(this.featuredData);
    this.onScrollEndHandle();
  }

  genreClick(event) {
    if(this.genresArray[event].name == 'All' && typeof this.featuredData.filter.genre_id != 'undefined') {
      delete this.featuredData.filter.genre_id;
    } else {
      this.featuredData.filter.genre_id = this.genresArray[event].id
    }
    this.resetDataObj(this.featuredData);
    this.onScrollEndHandle();
  }

  handleFilter(filter) {
    if(typeof this.featuredData.filter == 'undefined') {
      this.featuredData.filter = {};
    }
    if(this.featuredData.filter.title == filter) {
      delete this.featuredData.filter.title
      this.selectedLetter = '';
    } else {
      this.selectedLetter = filter;
      this.featuredData.filter.title = filter
    }
    this.resetDataObj(this.featuredData);
    this.onScrollEndHandle();
  }

  handleSing(song) {
    if (song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
   // this.router.navigate(['/recorder', song.id]);
    this.navigate([`/recorder/${song.id}`]);
  }

  handleDuet(duet) {
    if (duet.song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
   // this.router.navigate(['/duet-recorder', duet.id]);
    this.navigate([`/duet-recorder/${duet.id}`]);
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  setSortFields(sort){
    if (sort) {
      this.featuredData.sort = sort;
    }
  }

  onScrollEndHandle() {
    let data = this.featuredData;
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }
   
    
    if (!data.loading) {
      data.loading = true;
      this.detectChanges();
      data
        .fetch(this.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade)
        .subscribe(
          response => {
            data.currentPage = this.currentPage;
            console.log(response);
            console.log(this.totalPages);
            this.setPageParam();
            data.items = response.data;
            data.lastPage = response.next_page_url;
            data.loading = false;
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }
  getNextSongs() {
    let data = this.featuredData
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }

    if (!data.loading && data.lastPage) {
      data.loading = true;
      data.currentPage++;
      console.log(data.currentPage)
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade)
        .subscribe(
          response => {
            this.currentPage++
            data.items = response.data;
            this.setPageParam();
            data.lastPage = response.next_page_url;
            data.loading = false;
            window.scrollTo(0, 0);
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }
  getPrevSongs() {
    let data = this.featuredData;
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }

    if (!data.loading && data.currentPage != 1) {
      data.loading = true;
      data.currentPage--;
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade)
        .subscribe(
          response => {
            this.currentPage--
            data.items = response.data;
            this.setPageParam()
            data.lastPage = response.next_page_url;
            data.loading = false;
            window.scrollTo(0, 0);
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }
  
  handleFreeSongsChange(data, gold) {
    data.freeSongsFirst = gold;
    this.resetDataObj(data);
   // this.onScrollEndHandle(data, { field: 'gold', asc: gold }, true);
  }


  resetDataObj(data) {
    this.currentPage = 1;
    data.currentPage = 1;
    data.loading = false;
    data.items = [];
    data.lastPage = 1;
  }

  favoriteSong(song) {
    this.songService.fav(song.id).subscribe(() => {
      song.favorited = true;
      this.toastService.success('Added song to favorites');
    });
  }

  unfavoriteSong(song) {
    this.songService.unfav(song.id).subscribe(() => {
      song.favorited = false;
      this.toastService.success('Removed song from favorites');
    });
  }

  checkMine(item){
    return item.favorited;
  }

  openLink(url) {
    window.open(window.location.origin + '/#' + url);
  }

  onArtist(artist) {
    //this.router.navigate(['/d/artists/', artist.id]);
    this.navigate([`/d/artists/${artist.id}`]);
  }

  shareRecording(recording) {
    this.eventService.onSocialShare({ recording });
  }

  duetClick(value){
    this.filterByDuet=value;
    this.featuredData.filter.duet = this.filterByDuet;
    this.resetDataObj(this.featuredData);
    this.onScrollEndHandle();
  }

  howOthersSingThis(id){
    this.navigate([`/d/ListenOthersSing/${id}`]);
  }

}
