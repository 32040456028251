import { Component, Input, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, } from '@angular/core';
//import { NguCarouselConfig, NguCarousel, NguCarouselStore } from '@ngu/carousel';
import { NgxGlideComponent } from 'ngx-glide';


@Component({
  selector: 'app-snappenin-carousel',
  templateUrl: './snappenin-carousel.component.html',
  styleUrls: ['./snappenin-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnappeninCarouselComponent implements OnInit {
  @Input() title = 'Recommended for you';
  @Input() items = [];
  //@ViewChild('carousel', {static: false}) carousel: NguCarousel<any>;
  @Input() showStats = false;
  @ViewChild(NgxGlideComponent, { static: false }) ngxGlide: NgxGlideComponent;

  currentItemPage = 0;
  totalItemPage = 0;
  carouselToken;
  // public carouselTileItems: Array<any> = [];
  // public carouselTile: NguCarouselConfig = {
  //   grid: { xs: 2, sm: 4, md: 4, lg: 5, all: 0 },
  //   gridBreakpoints: {sm: 1240, md: 1685, lg: 1800, xl: 1800},

  //   speed: 600,
  //   point: {
  //     visible: true,
  //   },
  //   load: 2,
  //   touch: false
  // };

  constructor(private cdr: ChangeDetectorRef) {
  }

  
  ngOnInit() {
    this.totalItemPage = this.items.length;


   
  }
  toPrevSlide() {
    this.ngxGlide.go('<')
  }
  getIndex() {
    if(typeof this.ngxGlide != 'undefined') {
      return this.ngxGlide.getIndex()
    } else {
      return 1
    }
  }
  toNextSlide() {
    this.ngxGlide.go('>')
  }
  detectChanges(){
    this.cdr.markForCheck()
    this.cdr.detectChanges()
  }
}
