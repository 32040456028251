<div class="container container-ss ngucarousel-custom snappenin-carousel">
  <div class="top-controls">
    <h1>What's Snappenin'!</h1>
    <div class="carousel-controls">
      <app-icon-angle-down size="18" NguCarouselPrev (click)="toPrevSlide()" class="page-left"></app-icon-angle-down>
      <div class="carousel-page">
        <span class="active">{{getIndex() + 1}}</span>/<span>{{totalItemPage}}</span>
      </div>
      <app-icon-angle-down size="18" NguCarouselNext  (click)="toNextSlide()" class="page-right"></app-icon-angle-down>
    </div>
  </div>
  <ngx-glide [perView]='4' [showArrows]='false' [showBullets]='false' [listenToEvents]='true' (moved)='detectChanges()'>

    <div *ngFor="let item of items; let j = index">
      <div class="recommended-item">
        <app-recording-snappenin-item [item]="items[j]" [showStats]="showStats" title="Song: {{item.song.title}}&#013;Artist: {{item.song.artist_name}}">
        </app-recording-snappenin-item>
      </div>
    </div>

  </ngx-glide>
</div>
