
import { WebsocketService } from './../../core/services/websocket.service';
import {takeUntil, debounceTime,  merge, tap } from 'rxjs/operators';
import {
  NgZone,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs'; 
import { ToastrService } from 'ngx-toastr';
import { Cookie } from 'ng2-cookies';
import {
  UsersService,
  UtilService,
  SongService,
  ArtistService,
  HelperService,
  RecordingService,
  ProfileService,
  AuthenticationService,
  MessageBoardService,
  EventService,
} from '@app/core';
import { Location } from '@angular/common';
//import { SocialAuthService } from 'angularx-social-login';
import { AppFacade } from '@app/app.facade.service';
import { ShopService } from '@app/core/services/shop.service';
import Bugsnag from '@bugsnag/js';
import { EmailNotificationsService } from '@app/core/services/email-notifications.service';
declare var gtag;
@Component({
  selector: 'app-menu-main',
  templateUrl: './menu-main.component.html',
  styleUrls: ['./menu-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuMainComponent implements OnInit {
  @ViewChild('searchInput', { static: true }) searchInput;
  @ViewChild('goldcontent') private goldModal;
  isNavbarCollapsed = true;
  loggedInAs = '';
  ignoreDebounce = false;
  showTagResult = false;
  youveGotMail = false;
  notify = false;
  currentTag = '';
  public searchString = '';
  dropDownMenuDisplay;
  searchActive = false;
  userInfo: any;
  showSearchOverlay = false;
  searchTerm = '';
  searchType = 'sing';
  isPM = false;
  searchFreeSongsFirst = false;
  public searchkeyUp = new Subject<any>();
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @ViewChild('dropdown') el;
  userSocket: any;

  resetDecade = 0;
  resetGenre = 0;
  resetSort = 'ASC';
  searchDuet = false;
  private decadeFirstYear=0;
  private genreSelect=0;
  sortBySearch = 'created_at';
  sortBySearchDirection = 'ASC';
  decadesArray = ["All","1950's","1960's","1970's","1980's","1990's","2000's","2010's","2020's"];
  decade='All';
  sortByArray = ["Popularity","Recent","A-Z","Z-A"];
  genreArray = ["All","Rap/HipHop","Latin","Pop","Rnb","Alternative/Folk/Punk","Bluegrass","Blues","Country","Dance/Electronic/Club","Film/Stage/TV","Gospel/Worship","International/Ethnic","Jazz/Big Band","Novelty/Comedy","Oldies","Rock","Traditional/Classical","Holiday","Children","Acoustic"];

  messageFromSocket: string;
  socketValues: any;
//id: 1"Rap/Hip-Hop"id: 2"Latin"id: 3"Pop"id: 4"Rnb"id: 5"Alternative/Folk/Punk"id: 6"Bluegrass"id: 7"Blues"id: 8"Country"id: 9"Dance/Electronic/Club"id: 10"Film/Stage/TV"id: 11"Gospel/Worship"id: 12"International/Ethnic"id: 13"Jazz/Big Band"id: 14"Novelty/Comedy"id: 15"Oldies"id: 16"Rock"id: 17"Traditional/Classical"id: 18"Holiday"id: 19"Children"id: 20"Acoustic"

  distributableItems$ = this.shop.getDistributables();
  hasDistributableItems = true;
  storeLandingPage = 'membership';

  //used to receive infor from distribute for Store landing page
  messageReceived: any;
  private subscriptionName: Subscription; //important to create a subscription 

  songsData = {
    ...this.util.generateGridData(),
    sort: 'title',
    sortBySearchDirection: 'ASC',
    sortDirection: 'ASC',
    perPage:10,
    decade: this.decadeFirstYear,
    fetch: this.songService.searchSongs.bind(this.songService),
  };
  recordingData = {
    ...this.util.generateGridData(),
    sort: 'created_at',
    fetch: this.recordingService.searchRecordings.bind(this.recordingService),
  };
  duetsData = {
    ...this.util.generateGridData(),
    sort: 'created_at',
    fetch: this.recordingService.searchOpenDuets.bind(this.recordingService),
  };
  myRecordingsData = {
    ...this.util.generateGridData(),
    sort: 'created_at',
    fetch: this.recordingService.searchMyRecordings.bind(this.recordingService),
  };
  artistsData = {
    ...this.util.generateGridData(),
    sort: 'created_at',
    fetch: this.artistService.getArtists.bind(this.artistService),
  };
  membersData = {
    ...this.util.generateGridData(),
    sort: 'created_at',
    fetch: this.userService.getMembers.bind(this.userService),
  };
  tagRecordingData = {
    ...this.util.generateGridData(),
    sort: 'created_at',
    fetch: this.recordingService.getRecordingByTag.bind(this.recordingService),
  };
  tagMessageData = {
    ...this.util.generateGridData(),
    sort: 'created_at',
    fetch: this.messageBoardService.getByTags.bind(this.messageBoardService),
  };
  tagsData = [];

  nightmode$ = this.appFacade.nightmode$;
  user;



  constructor(
    private ngZone: NgZone,
    private artistService: ArtistService,
    public profileService: ProfileService,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private recordingService: RecordingService,
    private songService: SongService,
    private userService: UsersService,
    private cdr: ChangeDetectorRef,
    private toastService: ToastrService,
    private util: UtilService,
    public helper: HelperService,
    private location: Location,
    //private authService: SocialAuthService,
    private messageBoardService: MessageBoardService,
    private appFacade: AppFacade,
    private wsService: WebsocketService,
    private eventService: EventService,
    private shop: ShopService,
    private emailNotificationsService: EmailNotificationsService,
  ) {
    this.getRouteLink();

    router.events.subscribe(val => {
      this.dropDownMenuDisplay = false;
      this.getRouteLink();
    });

    this.profileService.userUpdated.subscribe(data => {
      this.getUserSettings();
    });

 
 
  // debounces
  this.searchkeyUp.pipe(debounceTime(300)).subscribe(event => {
    console.log('debounce', this.ignoreDebounce);
    // if (this.ignoreDebounce) {
    //     this.ignoreDebounce = false;
    //     return;
    // }
    if(event.key =="Enter"){
      if( 
        event.target.value !== this.searchTerm &&
        event.target.value.length >= 1 && event.key =="Enter"
        ){
          this.searchTerm = event.target.value;
          this.performSearch();
        }
    } else{
      if (
        event.target.value !== this.searchTerm &&
        event.target.value.length > 2
      ) {
        this.searchTerm = event.target.value;
        this.performSearch();
      } 
    }
  });

    this.eventService.evtUpdateUserProfile
      .pipe(tap(() => this.detectChanges()))
      .subscribe(user => {
        this.user = user;
      });

    //UPdate to reduce requests  
    this.profileService
      .getUserProfile()
      .pipe(tap(() => this.detectChanges()))
      .subscribe(user => {
        console.log('user profile update detected');
        this.user = user;
        Bugsnag.setUser(this.user.id, '', this.user.name)
        this.userSocket = user;
        if(!this.notify && user['has_unread_notifications']){
          this.notify = user['has_unread_notifications'];
         }
         if(!this.youveGotMail && user['has_unread_messages']){
         this.youveGotMail = user['has_unread_messages'];
         }
        this.checkNotificationsWS();





      });

    // set initial nightmode state
    const nightmode = this.util.getNightmode();
    this.util.setNightmode(nightmode);
    this.appFacade.updateNightmode(nightmode);

    // subscribe to distribute sender component messages from distribute
    this.subscriptionName= this.eventService.getUpdate().subscribe
    (message => { //message contains the data sent from service
    this.messageReceived = message;
    this.storeLandingPage = this.messageReceived.text;
    });
  }

  ngOnInit() {

    this.distributableItems$.subscribe(
      (data) => {
        if(data['total'] == 0) {
          this.hasDistributableItems = false;
        }
      }
    )

    this.route.queryParams.subscribe(params => {
      console.log(params)
      if(typeof params.searchOn != 'undefined') {
        if(params.searchOn == 'true') {
          this.setSearchStatus(true)
        }
      }
      if(typeof params.searchTerm != 'undefined') {
        this.searchTerm = params.searchTerm
        this.performSearch();
        this.searchInput.nativeElement.value = this.searchTerm
      }
    });
    if(Cookie.get('LoggedInAs')) {
      this.loggedInAs = Cookie.get('LoggedInAs')
      console.log(this.loggedInAs)

   
      gtag("event", "user_properties", {
        user_id: this.user.id.toString()

      });  
   
      //key dev
      gtag('config', 'G-4ENR0DBLLZ', {
        'user_id': this.user.id.toString()

      }); 

    }

    //Removed to reduce requests  
    //this.getUserSettings();
   
   
    /*  REMOVE continuous 30 second call to BE for websockets */
    /* this.checkNotifications();
    //check for notifications every 30 seconds
    setInterval(()=>{
      this.checkNotifications();
    }, 30000)
   */
   
    //setup WS
    //moved to constructor
    //this.getUserId();
  
    //check notificaions first time then websockets
    //Removed to reduce requests  ...removed and moved code to this.getUserId();
    // this.checkNotifications();
    
  }
  checkNotifications(){
    this.profileService.getUserProfile().pipe(
      takeUntil(this.ngUnsubscribe)).subscribe(data => {
      console.log('you have ', data['has_unread_notifications'], ' notifications and ', data['has_unread_messages'], ' unread mail')
      if(!this.notify && data['has_unread_notifications']){
       this.notify = data['has_unread_notifications'];
      }
      if(!this.youveGotMail && data['has_unread_messages']){
      this.youveGotMail = data['has_unread_messages'];
      }
    });
  }


  getUserId(){
    this.profileService.getUserProfile().subscribe(data => {
      this.userSocket = data;
      if(!this.notify && data['has_unread_notifications']){
        this.notify = data['has_unread_notifications'];
       }
       if(!this.youveGotMail && data['has_unread_messages']){
       this.youveGotMail = data['has_unread_messages'];
       }
      this.checkNotificationsWS();
    });
  }
  
  checkNotificationsWS(){
    this.wsService.createObservableSocketNotifications(`users:${this.userSocket.id}`)
    .subscribe(data => {
      this.detectChanges();
      this.messageFromSocket = data;
      this.socketValues = JSON.parse(this.messageFromSocket);
      if(this.socketValues['has_unread_messages']){
        this.youveGotMail = true;
      }
      if( this.socketValues['has_unread_notifications']){
        this.notify = true;
      }
      this.detectChanges();
    });
  }


  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  handleSearchKeydown(event) {
    if (event.code === 'Escape') {
      this.handleClose();
    }
  }

  checkMine(item){
    return item.favorited;
  }

  setStoreLandingPage(){
    if (this.hasDistributableItems == false && this.userInfo.gold == true){
      this.storeLandingPage = 'promos';
    } else if ( this.hasDistributableItems == false && this.userInfo.gold == false){
      this.storeLandingPage = 'membership';
    }else if ( this.hasDistributableItems == true && this.userInfo.gold == true){
      this.storeLandingPage = 'distribute';
    } else if ( this.hasDistributableItems == true && this.userInfo.gold == false ){
      this.storeLandingPage = 'distribute';
    }
  }
  
  getRouteLink() {
    this.isPM = this.router.url.toLowerCase().indexOf('private-message') !== -1;
  }

  getUserSettings() {
    this.profileService
      .getUserProfile().pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.userInfo = data;
        this.searchFreeSongsFirst = !this.userInfo.gold;
        this.detectChanges();
        this.setStoreLandingPage();
      });
    this.dropDownMenuDisplay = false;
  }

  showMore() {}

  setSearchStatus(value, close = false) {
    if(!close) {
      //url params to save search input
      this.location.replaceState(this.location.path().split('?')[0], '');
      if(value) {
        console.log(this.searchTerm)
        if(this.searchTerm != '') {
          this.location.replaceState(this.location.path() + `?searchOn=${value}&searchTerm=${this.searchTerm}`);
        } else {
          this.location.replaceState(this.location.path() + `?searchOn=${value}`);
        }
      }

    }


    if (this.router.url.slice(-6) === 'listen') {
      this.changeSearchType('listen');
    } else if (this.router.url.slice(-4) === 'sing') {
      this.changeSearchType('sing');
    }

    this.searchActive = value;
    this.detectChanges();
    if (!value) {
      this.clearSearch();
      this.showTagResult = false;
    } else {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      });
    }
  }

  handleClose() {
    this.searchActive = false;
    this.showSearchOverlay = false;
    this.clearSearch();
  }

  handleClearSearch() {
    if (this.searchTerm) {
      this.ignoreDebounce = true;
    }
    this.showSearchOverlay = false;
    this.clearSearch();
  }

  clearSearch() {
    this.searchInput.nativeElement.value = '';
    this.searchTerm = '';
    this.resetDataObj(this.songsData);
    this.resetDataObj(this.artistsData);
    this.resetDataObj(this.recordingData);
    this.resetDataObj(this.membersData);
    this.resetDataObj(this.tagRecordingData);
    this.resetDataObj(this.tagMessageData);
    this.tagsData = [];
    this.showTagResult = false;
    this.sortBySearch = 'created_at';
    this.sortBySearchDirection = 'ASC';
  }

  onBlurDropdownMenu() {
    this.dropDownMenuDisplay = false;
  }

  onLogOut() {
    this.authenticationService.onLogOut();
    try {
      //this.authService.signOut();
    } catch (err) {
      console.log(err);
    }
  }

  mapRecordingsData(songs: any) {
    let item = songs.length;
    const newArray = [];
    while (item) {
      item--;
      newArray.push(songs[item].song);
    }
    return newArray;
  }

  changeSearchType(type) {
    if (type !== 'tags') {
      this.showTagResult = false;
    }
    this.searchType = type;
    this.performSearch();
  }

  performSearch() {
    if(this.searchTerm == '') {
      return
    }
    this.location.replaceState(this.location.path().split('?')[0], '');
    this.location.replaceState(this.location.path() + `?searchOn=true&searchTerm=${this.searchTerm}`);
    this.showSearchOverlay = true;
    this.detectChanges();

    // reset for new search terms
    this.resetDataObj(this.songsData);
    this.resetDataObj(this.artistsData);
    this.resetDataObj(this.recordingData);
    this.resetDataObj(this.membersData);
    this.tagsData = [];

    if (this.searchType === 'sing') {
      this.searchSongs();
      this.searchArtists();
      this.searchMembers();
    } else if (this.searchType === 'listen') {
      this.searchRecordings();
      this.searchArtists();
      this.searchMembers();
    } else if (this.searchType === 'tags') {
      this.searchTags();
    }
  }

  searchTags() {
    this.recordingService.getTags(this.searchTerm).subscribe(result => {
      this.tagsData = result;
      this.detectChanges();
    });
  }


  //Original Search songs
//   searchSongs() {
//     this.songService
//     .searchSongs(
//       this.songsData.currentPage,
//       this.songsData.perPage,
//       this.searchTerm,
//       'created_at',
//       this.decadeFirstYear,
//       this.searchFreeSongsFirst
//     )
//     .subscribe(recordingsResponse => {
//       this.songsData.items = recordingsResponse.data;
//       this.songsData.lastPage = recordingsResponse.next_page_url;
//       this.songsData.currentPage = recordingsResponse.current_page;
//       this.detectChanges();
//     });
// }


  /* New search Songs*/
  searchSongs() {
      this.songService
      .searchSongs(
        this.songsData.currentPage,
        this.songsData.perPage,
        this.searchTerm,
        this.sortBySearch,
        this.sortBySearchDirection,
        this.decadeFirstYear,
        this.searchFreeSongsFirst,
        this.genreSelect,
        this.searchDuet
      )
      .subscribe(recordingsResponse => {
        this.songsData.items = recordingsResponse.data;
        this.songsData.lastPage = recordingsResponse.next_page_url;
        this.songsData.currentPage = recordingsResponse.current_page;
        this.detectChanges();
      });

  }



  searchArtists() {
    this.artistService
      .getArtists(
        this.artistsData.currentPage,
        this.artistsData.perPage,
        this.artistsData.sort,
        true,
        this.searchTerm
      )
      .subscribe(result => {
        this.artistsData.items = result.data;
        this.artistsData.lastPage = result.next_page_url;
        this.artistsData.currentPage = result.current_page;
        this.detectChanges();
      });
  }

  searchMembers() {
    this.userService
      .getMembers(
        this.membersData.currentPage,
        this.membersData.perPage,
        this.membersData.sort,
        false,
        this.searchTerm
      )
      .subscribe(result => {
        this.membersData.items = result.data;
        this.membersData.lastPage = result.next_page_url;
        this.membersData.currentPage = result.current_page;
        this.detectChanges();
      });
  }

  searchRecordings() {
    this.recordingService
      .searchRecordings(
        this.recordingData.currentPage,
        this.recordingData.perPage,
        this.searchTerm,
        this.sortBySearch
      )
      .subscribe(recordingsResponse => {
        this.recordingData.items = recordingsResponse.data;
        this.recordingData.lastPage = recordingsResponse.next_page_url;
        this.recordingData.currentPage = recordingsResponse.current_page;
        this.detectChanges();
      });
  }

  searchTagRecordings() {
    this.recordingService
      .getRecordingByTag(
        this.currentTag,
        this.tagRecordingData.currentPage,
        this.tagRecordingData.perPage
      )
      .subscribe(recordingsResponse => {
        this.tagRecordingData.items = recordingsResponse.data;
        this.tagRecordingData.lastPage = recordingsResponse.next_page_url;
        this.tagRecordingData.currentPage = recordingsResponse.current_page;
        this.detectChanges();
      });
  }

  searchTagMessages() {
    this.messageBoardService
      .getByTags(
        this.currentTag,
        this.tagMessageData.currentPage,
        this.tagMessageData.perPage
      )
      .subscribe(response => {
        this.tagMessageData.items = response.data;
        this.tagMessageData.lastPage = response.next_page_url;
        this.tagMessageData.currentPage = response.current_page;
        this.detectChanges();
      });
  }

  async onTag(tag) {
    this.currentTag = tag;
    this.showTagResult = true;
    this.resetDataObj(this.tagRecordingData);
    this.resetDataObj(this.tagMessageData);
    this.searchTagRecordings();
    this.searchTagMessages();
  }

  onSongsScrollEndHandle() {
    this.onScrollEndHandle(this.songsData);
  }

  onArtistsScrollEndHandle() {
    this.onScrollEndHandle(this.artistsData, 'artist');
  }

  onMembersScrollEndHandle() {
    this.onScrollEndHandle(this.membersData, 'member');
  }

  onRecordingsScrollEndHandle() {
    this.onScrollEndHandle(this.recordingData);
  }

  onTagRecordingScrollEndHandle() {
    this.onScrollEndHandle(this.tagRecordingData, 'tag');
  }

  onTagMessageScrollEndHandle() {
    this.onScrollEndHandle(this.tagMessageData, 'tag');
  }

  onDuetsScrollEndHandle() {
    this.onScrollEndHandle(this.duetsData);
  }

  onMyRecordingsEndHandle() {
    this.onScrollEndHandle(this.myRecordingsData);
  }

  onSing(item) {
    if (item.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    this.navigate(['/recorder', item.id]);
    this.setSearchStatus(false, true);
  }

  onListen(item) {
    this.navigate(['/d/listen/', item.id]);
    this.setSearchStatus(false, true);
  }

  onMessageBoard(item) {
    this.navigate(['/d/message-board/topics', item.id]);
    this.setSearchStatus(false, true);
  }

  onArtist(item) {
    this.navigate(['/d/artists/', item.id]);
    this.setSearchStatus(false, true);
  }

  onMemberProfile(item) {
    this.navigate(['/d/profile/', item.id, 'info']);
    this.setSearchStatus(false, true);
  }

  favoriteSong(song) {
    this.songService.fav(song.id).subscribe(() => {
      song.favorited = false;
    });
  }

  unfavoriteSong(song) {
    this.songService.unfav(song.id).subscribe(() => {
      song.favorited = true;
    });
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  async onScrollEndHandle(data, type = null) {
    if (!data.loading && data.lastPage) {
      data.loading = true;
      data.currentPage++;
      //this.detectChanges();
      try {
        let request;
        if (type !== 'tag') {
          request = data.fetch(
            //REPLACE to show recording pages
            //this.songsData.currentPage,
            //this.songsData.perPage,
            data.currentPage,
            data.perPage,
            this.searchTerm,
            this.sortBySearch,
            this.sortBySearchDirection,
            this.decadeFirstYear,
            this.searchFreeSongsFirst,
            this.genreSelect
          );
        } else {
          request = data.fetch(this.currentTag, data.currentPage, data.perPage);
        }
        if (type === 'artist' || type === 'member') {
          request = data.fetch(
            data.currentPage,
            data.perPage,
            data.sort,
            false,
            this.searchTerm
          );
        }
        const response = await request.toPromise();
        data.items = data.items.concat(response.data);
        data.lastPage = response.next_page_url;
        data.loading = false;
        this.detectChanges();
      } catch (err) {
        this.toastService.error(err.message);
      }
    }
  }

  resetDataObj(data) {
    data.currentPage = 1;
    data.loading = false;
    data.items = [];
    return data;
  }

  cleanSort(sort) {
    if (typeof sort === 'string') {
      if (sort === 'popular') {
        return 'popularity';
      } else {
        return 'created_at';
      }
    } else {
      return 'created_at';
    }
  }

  handleFreeSongsSearch(freeSongFirst = false) {
    this.searchFreeSongsFirst = freeSongFirst;
    this.resetDataObj(this.songsData);
    this.songsData.currentPage = 0;
    this.onSongsScrollEndHandle();
    this.searchSongs(); //added for decades
  }

  logout() {
    this.authenticationService.onLogOut();
  }

  toggleNightMode() {
    const nightmode = this.util.getNightmode();
    this.util.setNightmode(!nightmode);
    this.appFacade.updateNightmode(!nightmode);
  }

  async subscribeUser(recording) {
    const recordingList = [this.recordingData.items];
    this.helper.subscribeUser(recording, recordingList);
  }

  async unsubscribeUser(recording) {
    const recordingList = [this.recordingData.items];
    this.helper.unsubscribeUser(recording, recordingList);
  }

  shareRecording(recording) {
    this.eventService.onSocialShare({ recording });
  }

  decadeClick(index){
    this.decade= this.decadesArray[index];
    switch (index) {
      case '0':
        this.decadeFirstYear = 0;
        this.searchSongs();
        break;
      case '1':
        this.decadeFirstYear = 1950;
        this.searchSongs();
        break;
      case '2':
        this.decadeFirstYear = 1960;
        this.searchSongs();
        break;
      case '3':
        this.decadeFirstYear = 1970;
        this.searchSongs();
        break;
      case '4':
        this.decadeFirstYear = 1980;
        this.searchSongs();
        break;
      case '5':
        this.decadeFirstYear = 1990;
        this.searchSongs();
        break;
      case '6':
        this.decadeFirstYear = 2000;
        this.searchSongs();
        break;
      case '7':
        this.decadeFirstYear = 2010;
        this.searchSongs();
        break;
      case '8':
        this.decadeFirstYear = 2020;
        this.searchSongs();
        break;
      default:
        this.decadeFirstYear = 0;
        this.searchSongs();
        break;
    }
  }

  genreClick(index){
    this.genreSelect = index;
    this.songsData.currentPage = 1;
   this.searchSongs();
  }

  sortbyClick(term){
    console.log("sorting", this.sortBySearch,this.sortBySearchDirection ) ;
    this.sortBySearch = term;
    this.songsData.currentPage = 1;
    this.searchSongs();
  }

  sortbyDirectionClick(term){
    //console.log("sorting", this.sortBySearch,this.sortBySearchDirection ) ;
    this.sortBySearchDirection = term;
    this.songsData.currentPage = 1;
    this.searchSongs();
  }

  changeSortDirection(){
    if (this.sortBySearchDirection === 'ASC'){
      this.sortBySearchDirection = 'DESC';
    } else {
      this.sortBySearchDirection = 'ASC';
    }
    this.songsData.currentPage = 1;
    this.searchSongs();
  }


  duetClick(term){
    this.searchDuet = term;
    this.searchSongs();

  }

  clearFilters(){
   // this.songsData.currentPage = 1;
 //   this.songsData.perPage = 10;
 //   this.searchTerm,

    this.resetDecade = 0;
    this.resetGenre = 0;
    this.resetSort = 'ASC';
    this.searchDuet = false;
    this.sortBySearchDirection = "ASC";
    this.decadeFirstYear = 0;
    this.genreSelect = 0;
    this.detectChanges();
    this.searchSongs();
   
  }

  turnOffMailIcon(){
    this.youveGotMail = false;
   }

   turnOffNotifyIcon(){
    this.notify = false;
   }

  howOthersSingThis(id){
   this.navigate(['/d/ListenOthersSing/',id]);
   this.setSearchStatus(false, true);
  }

  emailFav(fav_member){
   if (fav_member.newsletter){
   // this.emailNotificationsService.emailFavoriteNotification(fav_member.email, fav_member.screen_name, this.user.screen_name)
    this.emailNotificationsService.emailFavoriteNotificationV2(fav_member.email, fav_member.screen_name, this.user.screen_name, this.user.id, this.user.photo.replace('https://', ''))
  }
  } 

}
