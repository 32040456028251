import {Component, OnInit, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  title = '';
  description = '';
  acceptText = 'Ok';

  constructor(
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
  }

  open(title = 'Confirm', description = '') {
    this.title = title;
    this.description = description;
    return this.modalService.open(this.contentElem, {
      windowClass: 'ss-modal-warning',
      backdrop: 'static',
    }).result.then((result) => {
      this.title = '';
      this.description = '';
      return result;
    }, (reason) => {
      this.title = '';
      this.description = '';
      return reason;
    });
  }
}
