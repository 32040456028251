import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-atm',
  templateUrl: './icon-atm.component.html',
  styleUrls: ['./icon-atm.component.scss']
})
export class IconAtmComponent implements OnInit {
  @Input() color = '#262628';
  @Input() size = 18;

  constructor() { }

  ngOnInit() {
  }

}
