import {Component, OnInit} from '@angular/core';
import {Charge} from '@app/models/charge.model';
import {ProfileService} from '@app/core';
import {ShopService} from '@app/core/services/shop.service';

@Component({
  selector: 'app-credits-checkout',
  templateUrl: './credits-checkout.component.html',
  styleUrls: ['./credits-checkout.component.scss']
})
export class CreditsCheckoutComponent implements OnInit {

  constructor(private profiles: ProfileService,
              private shop: ShopService) {
  }

  ngOnInit() {
  }

  public async chargeCredits(charge: Charge) {
    const token = 'token';
    const processor = 'credits';
    const anonymous = false;
    try {
      await this.profiles.getUserProfile().toPromise();

      charge = {
        ...charge,
        processor,
        token,
        anonymous,
      };

      const orderPaid = await this.shop.createAndChargeOrder(charge).toPromise() as any;

      if (orderPaid.success) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      // if(e.error.message.search("Insufficient credits") != -1) {
      //   console.log('not enough credits')
      // }
      return false;

    }

  }
}
