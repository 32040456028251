<app-ss-store-box (click)="btnClicked($event)">
  <div class="ss-store-box-header">
    <h5>{{title}}</h5>
    <p>{{description}}</p>
  </div>
  <div class="ss-store-box-body">
    <div class="plan-price">
      <app-ss-price [price]="price" [period]="period"></app-ss-price>
    </div>
  </div>
  <div class="ss-store-box-footer">
    <button class="btn btn-primary" (click)="btnClicked($event)">{{btnText}}</button>
  </div>
</app-ss-store-box>
