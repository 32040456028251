import { Component, OnInit } from '@angular/core';
import { ProfileService } from '@app/core';
import { OrdersService } from '@app/core';

@Component({
  selector: 'app-shop-orders',
  templateUrl: './shop-orders.component.html',
  styleUrls: ['./shop-orders.component.scss'],
})
export class ShopOrdersComponent implements OnInit {
  user: any;
  orders: any;
  allOrders: any[] = [];

  constructor(
    private profileService: ProfileService,
    private ordersService: OrdersService
  ) {}

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.profileService
      .getUserProfile()
      .subscribe(
        response => (this.user = response),
        err => console.error(err),
        () => this.getOrders()
      );
  }

  getOrders() {
    this.ordersService
      .getOrders(this.user.id)
      .subscribe(
        response => (this.orders = response),
        err => console.error(err),
        () => this.getOrdersDetails()
      );
  }

  getOrdersDetails() {
    let count = this.orders.length;
    if (count > 0) {
      let i = 0;
      for (i; i < count; i++) {
        this.ordersService
          .getOrdersDetails(this.orders[i].order_id)
          .subscribe(
            response => this.allOrders.push(response),
            err => console.error(err)
          );
      }
    }
  }
}
