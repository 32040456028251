import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { Cookie } from 'ng2-cookies/ng2-cookies';
import swal from 'sweetalert2';
import { HttpService } from './http.service';

@Injectable()
export class UploadService extends HttpService {
  uploadDetails;

  constructor(private http: HttpClient) {
    super();
  }

  setUploadDetails(uploadDetails) {
    uploadDetails = this.uploadDetails;
  }

  uploadSong(data) {
    const headers = this.getHeaders();
    const body = data;
    swal({
      position: 'top-right',
      type: 'warning',
      title:
        'Please do not log out until you receive notification that you song has been uploaded successfully!',
      showConfirmButton: false,
      timer: 2500,
    });
    return this.http
      .post(this.root + '/user/songs/', body, headers)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
}
