<!-- <div class="row">
    <div class="col-12 col-sm-8">
        <app-featured-list 
         [scrollable]="false"
        [isInfiniteScroll]="false" 
        [noRow]="true" 
        title="Fans" 
        
        [showSort]="true" 
        [featuredList]="fans">
            <ng-template #itemContent let-item>
                <div class="recording-line">
                    <div class="featured-list--item--poster rounded-circle" [ngStyle]="{'background-image': 'url('+item?.photo+')'}">
                        <img [src]="item?.photo" alt="" class="img-fluid">
                    </div>
                    <div class="col featured-item--body align-items-center" title="Go to Profile Page: {{item.screen_name}}" [routerLink]="['/d/profile/', item?.id]">
                        <app-user-badge-level [user]="item"></app-user-badge-level>
                        <span class="user-bio-stautsMessage"> {{item?.status}}</span>
                    </div>
                    <div class="col-auto featured-list--item-action align-items-center">
                        <app-more-dot-menu>

                            <div class="dropdown-menu-content">
                                <div (click)="favoriteMember(item)"  *ngIf="!item.favorited">Add to 'My Favorite Members'</div>
                                <div (click)="unfavoriteMember(item)" *ngIf="item.favorited">Remove from 'My Favorite Members'</div>
                                <div (click)="navMessage(item?.id)">Message User</div>
                                <div (click)="navProfile(item.user_id)">Go to Profile Page</div>
                            </div>


                        </app-more-dot-menu>
                    </div>
                </div>
            </ng-template>
        </app-featured-list>
        <div  id="pageNavContainer" style="margin-bottom: 13px;"><button (click)="getPrevFans()">&lt; PREV</button><p>Page: {{currentPage}} / {{setTotalPages()}}</p><button (click)="getNextFans()">NEXT &gt;</button></div>
    </div>
</div> -->




<div class="ssc-wrapper">
    <div class="ssc-list-content">
        <div class="ssc-search-holder">
            <app-search-bar-input (onSearch)="onSearch($event)"></app-search-bar-input>
        </div>
      <app-ss-featured-grid 
        [scrollable]="false"
        title="Fans"
        seeMorePage="MyListenMore:fans"
        [userIdFromProfile]="userId"
        [sortOptions]="memberSortOptions"
        [hasSeeMore] = "true"
        [list]="fans" 
        [loading]="data.rqing" 
        [cols]="1"
        [colsLg]="1"
        [colsMd]="1"
        [colsSm]="1" 
        (onScrollEnd)="getItems(null)" 
        (onOrderBy)="getItems($event)"
          >
        >
        <ng-template #itemContent let-item>
          <div class="ssc-list-item" class="ss-list--item {{ customClass }}" >

           <app-ss-user [user] = "item"></app-ss-user>

            <div class="song-actions">

              <app-more-dot-menu  title="Options">
                <div class="dropdown-menu-content">
                    <div (click)="favoriteMember(item)"  *ngIf="!item.favorited">Add to 'My Favorite Members'</div>
                    <div (click)="unfavoriteMember(item)" *ngIf="item.favorited">Remove from 'My Favorite Members'</div>
                    <div (click)="navMessage(item?.id)">Message User</div>
                    <div (click)="navProfile(item?.id)">Go to Profile Page</div>
                </div>
              </app-more-dot-menu>
            </div>
          </div>
          
        </ng-template>
      </app-ss-featured-grid>
      <!-- <div  id="pageNavContainer" style="margin-bottom: 13px;"><button (click)="getPrevFans()">&lt; PREV</button><p>Page: {{currentPage}} / {{setTotalPages()}}</p><button (click)="getNextFans()">NEXT &gt;</button></div> -->
      <app-vert-space height="40"></app-vert-space>
    </div>