import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ShopService, ProfileService, UtilService } from '@app/core/services';
import { EmailNotificationsService } from '@app/core/services/email-notifications.service';

@Component({
  selector: 'app-snap-credit-modal',
  templateUrl: './snap-credit-modal.component.html',
  styleUrls: ['./snap-credit-modal.component.scss'],
})
export class SnapCreditModalComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  modalRef = null;
  user: any = null;
  count = 5;
  counts = [1, 5, 10, 20, 50, 100, 500, 1000];
  anonymous = false;
  message = '';
  isSending = false;
  sent = false;
  notEnough = false;

  constructor(
    private modalService: NgbModal,
    private shopService: ShopService,
    private toastr: ToastrService,
    private router: Router,
    private profileService: ProfileService,
    private emailNotificationsService: EmailNotificationsService,
    private utilService: UtilService
  ) {}

  ngOnInit() {}

  open(user) {
    this.user = user;
    this.anonymous = false;
    this.message = '';
    this.count = 5;
    this.sent = false;
    this.notEnough = false;
    this.openModal();
  }

  close() {
    this.modalRef.close();
  }

  changeCount(value) {
    this.count = value;
  }

  changeAnonymous(value) {
    this.anonymous = value;
    if (value) {
      this.message = '';
    }
  }

  handlePurchase() {
    this.router.navigate(['/d/payments/credits']);
    this.modalRef.close();
  }

  openModal() {
    this.modalRef = this.modalService.open(this.contentElem, {
      windowClass: 'ssc-modal snap-credit-modal',
      backdrop: 'static',
    });

    this.modalRef.result.then(result => {}, async reason => {});
  }

  async handleSubmit() {
    if (this.profileService.user.credits < this.count) {
      this.notEnough = true;
      return;
    }
    try {
      this.isSending = true;
      await this.shopService
        .sendCredits(this.user.id, this.count, this.anonymous, this.message)
        .toPromise();
      this.sent = true;
      await this.profileService.getUserProfile().toPromise();
    } catch (err) {
      this.toastr.error('Failed to send $nap to ' + this.user.screen_name);
    } finally {
      this.isSending = false;
      if(this.user.newsletter){
        let senderUser = this.utilService.getloggedUserData()
        this.emailNotificationsService.emailSentSnaps(this.user.email, this.user.screen_name, senderUser.screen_name, senderUser.id, this.count, this.anonymous)
      }
    }
  }
}
