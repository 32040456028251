import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '@app/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-block-modal',
  templateUrl: './block-modal.component.html',
  styleUrls: ['./block-modal.component.scss'],
})
export class BlockModalComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  @Output('success') success = new EventEmitter<any>();
  title = '';
  user = null;
  cancelText = 'Cancel';
  acceptText = 'Ok';
  blocking = false;
  reportThankyouModalRef = null;
  modalRef = null;

  constructor(
    private modalService: NgbModal,
    private profiles: ProfileService,
    private toast: ToastrService
  ) {}

  ngOnInit() {}

  open(
    title = 'Block',
    user = {},
    { cancelText = 'No', acceptText = 'Yes' } = {}
  ) {
    if (cancelText) {
      this.cancelText = cancelText;
    }

    if (acceptText) {
      this.acceptText = acceptText;
    }

    this.user = user;

    this.title = title;
    this.modalRef = this.modalService.open(this.contentElem, {
      windowClass: 'ss-modal-confirmation',
      backdrop: 'static',
    });

    return this.modalRef.result.then(
      result => {
        this.title = '';
        return result;
      },
      reason => {
        this.title = '';
        return reason;
      }
    );
  }

  block(content) {
    this.blocking = true;
    this.profiles.block(this.user.id).subscribe(
      response => {
        this.blocking = false;
        if (response.success) {
          this.success.emit({ success: true });
          this.reportThankyouModalRef = this.modalService.open(content, {
            windowClass: 'ss-warning-modal',
          });
        } else {
          this.toast.warning(response.message);
        }
        console.log(this.modalRef);
        this.modalRef.close('cancel');
      },
      error => {
        this.blocking = false;
        this.modalRef.close('cancel');
        this.toast.error(error.message);
      }
    );
  }
}
