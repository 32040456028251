import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ss-playlist',
  templateUrl: './ss-playlist.component.html',
  styleUrls: ['./ss-playlist.component.scss']
})
export class SsPlaylistComponent implements OnInit {
  @Input() playlist: any = {};
  @Input() noTime = false;
  @Input() showDescription = false;

  constructor() { }

  ngOnInit() {
    console.log(this.playlist);
  }

  getMissingCovers() {
    const result = [];
    if (this.playlist.cover.length === 0) {
      return result;
    }
    for (let i = 0; i < 4 - this.playlist.cover.length; i ++) {
      result.push(1);
    }
    return result;
  }

}
