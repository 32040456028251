<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 19 19" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="Credit-Icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="icon-privacy-ic" transform="translate(-199.000000, -65.000000)" [attr.fill]="color" fill-rule="nonzero">
        <path
          d="M208.9845,72.5905 C208.9845,71.023 207.702,69.7405 206.1345,69.7405 C204.567,69.7405 203.2845,71.023 203.2845,72.5905 C203.2845,74.158 204.567,75.4405 206.1345,75.4405 C207.702,75.4405 208.9845,74.158 208.9845,72.5905 Z M206.1345,73.5405 C205.612,73.5405 205.1845,73.113 205.1845,72.5905 C205.1845,72.068 205.612,71.6405 206.1345,71.6405 C206.657,71.6405 207.0845,72.068 207.0845,72.5905 C207.0845,73.113 206.657,73.5405 206.1345,73.5405 Z M212.3095,75.4405 C213.364,75.4405 214.2095,74.595 214.2095,73.5405 C214.2095,72.486 213.364,71.6405 212.3095,71.6405 C211.255,71.6405 210.4,72.486 210.4095,73.5405 C210.4095,74.595 211.255,75.4405 212.3095,75.4405 Z M208.5,65 C203.256,65 199,69.256 199,74.5 C199,79.744 203.256,84 208.5,84 C213.744,84 218,79.744 218,74.5 C218,69.256 213.744,65 208.5,65 Z M202.6575,79.3545 C203.3035,78.8415 204.814,78.3 206.1345,78.3 C206.201,78.3 206.277,78.3095 206.353,78.3095 C206.581,77.7015 206.9895,77.084 207.588,76.5425 C207.056,76.4475 206.5525,76.3905 206.1345,76.3905 C204.8995,76.3905 202.914,76.818 201.641,77.749 C201.166,76.761 200.9,75.659 200.9,74.4905 C200.9,70.301 204.3105,66.8905 208.5,66.8905 C212.6895,66.8905 216.1,70.301 216.1,74.4905 C216.1,75.6305 215.8435,76.7135 215.3875,77.692 C214.4375,77.1315 213.1455,76.8655 212.3095,76.8655 C210.8655,76.8655 208.0345,77.635 208.0345,79.4305 L208.0345,82.0715 C205.878,81.948 203.959,80.922 202.6575,79.3545 Z"
          id="ic"></path>
      </g>
    </g>
  </svg>
</div>