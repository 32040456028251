import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-social-messenger',
  templateUrl: './icon-social-messenger.component.html',
  styleUrls: ['./icon-social-messenger.component.scss']
})
export class IconSocialMessengerComponent implements OnInit {
  @Input() size = 58;
  
  constructor() { }

  ngOnInit() {
  }

}
