import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-blog',
  templateUrl: './icon-blog.component.html',
  styleUrls: ['./icon-blog.component.scss']
})
export class IconBlogComponent implements OnInit {
  @Input() color = '#D6D6D6';
  @Input() size = 18;
  
  constructor() { }

  ngOnInit(): void {
  }

}
