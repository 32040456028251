import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sync-bar',
  templateUrl: './sync-bar.component.html',
  styleUrls: ['./sync-bar.component.scss']
})
export class SyncBarComponent implements OnInit {
  @Input() duration: any = 10;
  @Input() initial: any = 0;
  @Input() current: any = 0;
  @Output() seek = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  getProgress() {
    return this.current / this.duration * 100;
  }

  onSeek(e) {
    this.seek.emit(e.target.value);
    this.current = e.target.value;
  }
}
