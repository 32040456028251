<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 459 459" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch -->
    <title>volume-up-indicator</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path d="M0,152.965909 L0,306.034091 L102,306.034091 L229.5,433.590909 L229.5,25.4090909 L102,152.965909 L0,152.965909 Z M344.25,229.5 C344.25,183.579545 318.75,145.3125 280.5,127.454545 L280.5,331.545455 C318.75,313.6875 344.25,275.420455 344.25,229.5 Z M280.5,5 L280.5,58.5738636 C354.45,81.5340909 408,147.862636 408,229.5 C408,311.137364 354.45,377.465909 280.5,400.426136 L280.5,454 C382.5,431.038772 459,339.198864 459,229.5 C459,119.801136 382.5,27.9592268 280.5,5 Z"
        id="path-icon-volumn"></path>
    </defs>
    <g id="volume-up-indicator" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlink:href="#path-icon-volumn"></use>
      </mask>
      <use id="Mask" [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-icon-volumn"></use>
    </g>
  </svg>
</div>