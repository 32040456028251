<div class="col-12 featured-list--container px-0 featured-list--{{type}}">
    <ng-content select=".featured-list--header">
    </ng-content>
    <h2 *ngIf="showHeader">{{title}} <i [ngClass]="{'d-none': !loading}" class="fa fa-spinner fa-spin-an"></i>
        <span ngbDropdown class="d-inline-block pull-right dropdown-orderby" *ngIf="showSort">
            <span class="d-flex align-items-center" ngbDropdownToggle>
                <app-icon-time *ngIf="orderBy.sorttype === 'created_at' && orderBy.direction === 'DESC'" color="white"></app-icon-time>
                <app-icon-flame *ngIf="orderBy.sorttype === 'popularity' && orderBy.direction === 'DESC'" color="white"></app-icon-flame>
                <app-icon-sort-alpha-up *ngIf="orderBy.sorttype === 'song.title' && orderBy.direction === 'ASC'" color="white"></app-icon-sort-alpha-up>
                <app-icon-sort-alpha-down *ngIf="orderBy.sorttype === 'song.title' && orderBy.direction === 'DESC'" color="white"></app-icon-sort-alpha-down>
            </span>
            <div ngbDropdownMenu>
                <div class="dropdown-item" [ngClass]="{'selected': orderBy.sorttype === 'created_at'}" (click)="handleOrderBy('created_at', 'DESC')">Most Recent</div>
                <div class="dropdown-item" [ngClass]="{'selected': orderBy.sorttype === 'popularity'}" (click)="handleOrderBy('popularity', 'DESC')">Most Popular</div>
                <div class="dropdown-item" [ngClass]="{'selected': orderBy.sorttype === 'song.title' && orderBy.direction === 'ASC'}" (click)="handleOrderBy('song.title', 'ASC')">A-Z (Song Name)</div>
                <div class="dropdown-item" [ngClass]="{'selected': orderBy.sorttype === 'song.title' && orderBy.direction === 'DESC'}" (click)="handleOrderBy('song.title', 'DESC')">Z-A (Song Name)</div>
            </div>
        </span>
    </h2>
    <div *ngIf="!loggedInIsGold">
        <ng-adsense
        [adClient]="'ca-pub-4776037543768273'"
        [adSlot]="8177906096"
        [display]="'block'"
        [adFormat]="'fluid'"
        [layoutKey]="'-fb+5w+4e-db+86'"
        [height]="80"
        ></ng-adsense>
    </div>
    <ul class="featured-list" *ngIf="!loading; else skeleton"
        [class.ps]="scrollable"
        
        [class.featured-list--empty]="featuredList?.length == 0"
        [class.featured-list--flexible]="flexible"
        [disabled]="!scrollable"
        [perfectScrollbar]="config"
        #ps
        (psYReachEnd)="scrollEndHandle($event)">
        <li *ngFor="let item of featuredList" class="featured-list--item {{customClass}}">          
            <div [ngClass]="{'row': !noRow}"> 
                <ng-container *ngTemplateOutlet="itemTmpl, context: { $implicit: item }"></ng-container>
            </div>
        </li>
        <li *ngIf="isInfiniteScroll">
            <app-infinite-scroll (onView)="onViewChange.emit()"></app-infinite-scroll>
        </li>
        <li *ngIf="featuredList && featuredList?.length === 0" class="featured-list--no-results">
            <span>No results found.</span>
        </li>
    </ul>

    <ng-template  #skeleton>
        <div *ngIf=" title === 'Recordings' || title.includes('Open Jams') || title === 'Featured On Recordings' ">
            <div *ngFor="let e of [].constructor(18)">
                <div class="skeleton-featured-list">
                        <div class="skeleton-featured-list-image skeleton-color"></div>
                        <div class="col-auto px-0 align-items-center justify-content-between" style="flex: 0 1 42%; margin-left: 10px;">
                            <div class="skeleton-featured-list-title skeleton-color"></div>
                            <div class="skeleton-featured-list-artist skeleton-color"></div>
                        </div>
                        <div class="skeleton-featured-list-date skeleton-color" style="flex: 0 1 12%;" ></div>
                        <div class="skeleton-featured-list-stats">
                            <div class="skeleton-featured-list-stat skeleton-color"></div>
                            <div class="skeleton-featured-list-stat skeleton-color"></div>
                            <div class="skeleton-featured-list-stat skeleton-color"></div>
                        </div>
                        <div  style="display: flex;  align-items: center; margin-left: -68px; " >
                            <div class="skeleton-featured-list-button skeleton-color" style="margin-right: 6px;"></div>
                            <div class="skeleton-featured-list-3dot skeleton-color"></div>
                        </div>
                </div>
            </div> 
        </div>
           
        <div *ngIf=" title === 'Fans'  ">
            <div *ngFor="let e of [].constructor(18)">
                <div class="skeleton-featured-fans">
                    <div class="skeleton-featured-fans-image skeleton-color"></div>
                    <div class="skeleton-featured-fans-members skeleton-color"></div>
                </div>
            </div>
        </div>

        <div *ngIf="skeletonType === 'myRecordings' ">
            <div *ngFor="let e of [].constructor(6)">
                <div class="skeleton-featured-list">
                    <div class="skeleton-featured-list-check skeleton-color"></div>
                    <div class="skeleton-featured-list-image skeleton-color"></div>
                    <div class="col-auto px-0 align-items-center justify-content-between" style="flex: 0 1 42%; margin-left: 10px;">
                        <div class="skeleton-featured-list-title skeleton-color"></div>
                        <div class="skeleton-featured-list-artist skeleton-color"></div>
                        <div class="skeleton-featured-list-artist skeleton-color"></div>
                    </div>
                    <div class="skeleton-featured-list-date skeleton-color" style="flex: 0 1 12%;" ></div>
                    <div class="skeleton-featured-list-stats">
                        <div class="skeleton-featured-list-stat skeleton-color"></div>
                        <div class="skeleton-featured-list-stat skeleton-color"></div>
                        <div class="skeleton-featured-list-stat skeleton-color"></div>
                        <div class="skeleton-featured-list-privacy skeleton-color"></div>
                    </div>   
                    <div  style="display: flex;  align-items: center; margin-left: -68px; " >
                        <div class="skeleton-featured-list-button skeleton-color" style="margin-right: 6px;"></div>
                        <div class="skeleton-featured-list-3dot skeleton-color"  style="margin-right: 10px;"></div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="skeletonType === 'myRecordingsRecycleBin' ">


            <div *ngFor="let e of [].constructor(6)">
                <div class="skeleton-featured-list">
                    <div class="skeleton-featured-list-image skeleton-color"></div>
                    <div class="col-auto px-0 align-items-center justify-content-between" style="flex: 0 1 63%; margin-left: 10px;">
                        <div class="skeleton-featured-list-recyclebin-title skeleton-color"></div>
                        <div class="skeleton-featured-list-recyclebin-artist skeleton-color"></div>
                    </div>
                    <div>
                        <div class="skeleton-featured-list-recyclebin-date skeleton-color" style="flex: 0 1 12%;" ></div>
                        <div class="skeleton-featured-list-recyclebin-date skeleton-color" style="flex: 0 1 12%;" ></div>
                    </div>
                    <div  style="display: flex;  align-items: center; " >
                        <div class="skeleton-featured-list-3dot skeleton-color"  style="margin-right: 10px;"></div>
                    </div>
                </div>
            </div>

        </div>

    </ng-template>

</div>
<!-- <ng-content select=".featured-list--more"></ng-content> -->
<div *ngIf="featuredList && featuredList?.length > 4">
    <a href={{getSeeMoreURL()}} style='color: #58c7c9;' class="featured-list--more" *ngIf="hasSeeMore">See More</a>
</div>
