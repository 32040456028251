import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconInboxComponent } from './icon-inbox/icon-inbox.component';
import { IconMoreComponent } from './icon-more/icon-more.component';
import { IconFavoriteComponent } from './icon-favorite/icon-favorite.component';
import { IconFavoriteOutlineComponent } from './icon-favorite-outline/icon-favorite-outline.component';
import { IconSentComponent } from './icon-sent/icon-sent.component';
import { IconArchivedComponent } from './icon-archived/icon-archived.component';
import { IconMoonComponent } from './icon-moon/icon-moon.component';
import { IconSearchComponent } from './icon-search/icon-search.component';
import { IconNotificationComponent } from './icon-notification/icon-notification.component';
import { IconAlertComponent } from './icon-alert/icon-alert.component';
import { IconArrowDownComponent } from './icon-arrow-down/icon-arrow-down.component';
import { IconMoreDotComponent } from './icon-more-dot/icon-more-dot.component';
import { IconTimeComponent } from './icon-time/icon-time.component';
import { IconStarComponent } from './icon-star/icon-star.component';
import { IconTrashComponent } from './icon-trash/icon-trash.component';
import { IconLoadingComponent } from './icon-loading/icon-loading.component';
import { IconAngleDownComponent } from './icon-angle-down/icon-angle-down.component';
import { IconDeleteComponent } from './icon-delete/icon-delete.component';
import { IconArrowLeftComponent } from './icon-arrow-left/icon-arrow-left.component';
import { IconSearchBigComponent } from './icon-search-big/icon-search-big.component';
import { IconMobileMenuComponent } from './icon-mobile-menu/icon-mobile-menu.component';
import { IconWarningComponent } from './icon-warning/icon-warning.component';
import { IconSettingComponent } from './icon-setting/icon-setting.component';
import { IconInboxUpComponent } from './icon-inbox-up/icon-inbox-up.component';
import { IconCheckComponent } from './icon-check/icon-check.component';
import { IconPlusComponent } from './icon-plus/icon-plus.component';
import { IconArrowRightComponent } from './icon-arrow-right/icon-arrow-right.component';
import { IconPlayComponent } from './icon-play/icon-play.component';
import { IconPauseComponent } from './icon-pause/icon-pause.component';
import { IconPencilComponent } from './icon-pencil/icon-pencil.component';
import { IconCancelComponent } from './icon-cancel/icon-cancel.component';
import { IconMusicComponent } from './icon-music/icon-music.component';
import { IconMicComponent } from './icon-mic/icon-mic.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { IconCameraComponent } from './icon-camera/icon-camera.component';
import { IconPitchComponent } from './icon-pitch/icon-pitch.component';
import { IconRestartComponent } from './icon-restart/icon-restart.component';
import { IconVolumnComponent } from './icon-volumn/icon-volumn.component';
import { IconCloseComponent } from './icon-close/icon-close.component';
import { IconRefreshComponent } from './icon-refresh/icon-refresh.component';
import { IconHomeComponent } from './icon-home/icon-home.component';
import { IconSyncComponent } from './icon-sync/icon-sync.component';
import { IconFlameComponent } from './icon-flame/icon-flame.component';
import { IconHeartComponent } from './icon-heart/icon-heart.component';
import { IconCommentComponent } from './icon-comment/icon-comment.component';
import { IconEyeComponent } from './icon-eye/icon-eye.component';
import { IconShareComponent } from './icon-share/icon-share.component';
import { IconDuetComponent } from './icon-duet/icon-duet.component';
import { IconAtmComponent } from './icon-atm/icon-atm.component';
import { IconPlayPrevComponent } from './icon-play-prev/icon-play-prev.component';
import { IconPlayNextComponent } from './icon-play-next/icon-play-next.component';
import { IconFullScreenComponent } from './icon-full-screen/icon-full-screen.component';
import { IconShuffleComponent } from './icon-shuffle/icon-shuffle.component';
import { IconPlayLineComponent } from './icon-play-line/icon-play-line.component';
import { IconFullscreenComponent } from './icon-fullscreen/icon-fullscreen.component';
import { IconHeartFullComponent } from './icon-heart-full/icon-heart-full.component';
import { IconMicOffComponent } from './icon-mic-off/icon-mic-off.component';
import { IconAddReactionComponent } from './icon-add-reaction/icon-add-reaction.component';
import { IconIcComponent } from './icon-ic/icon-ic.component';
import { IconGlobeComponent } from './icon-globe/icon-globe.component';
import { IconSingsnapComponent } from './icon-singsnap/icon-singsnap.component';
import { IconLockComponent } from './icon-lock/icon-lock.component';
import { IconTrophyComponent } from './icon-trophy/icon-trophy.component';
import { IconLongArrowDownComponent } from './icon-long-arrow-down/icon-long-arrow-down.component';
import { IconPrivacyMemberonlyComponent } from './icon-privacy-memberonly/icon-privacy-memberonly.component';
import { IconPrivacyIcComponent } from './icon-privacy-ic/icon-privacy-ic.component';
import { IconPrivacyContestComponent } from './icon-privacy-contest/icon-privacy-contest.component';
import { IconPrivacyPrivateComponent } from './icon-privacy-private/icon-privacy-private.component';
import { IconPrivacyPublicComponent } from './icon-privacy-public/icon-privacy-public.component';
import { IconBubbleComponent } from '@app/shared/icons/icon-bubble/icon-bubble.component';
import { IconMegaphoneComponent } from '@app/shared/icons/icon-megaphone/icon-megaphone.component';
import { IconNewComponent } from '@app/shared/icons/icon-new/icon-new.component';
import { IconSortAlphaDownComponent } from './icon-sort-alpha-down/icon-sort-alpha-down.component';
import { IconSortAlphaUpComponent } from './icon-sort-alpha-up/icon-sort-alpha-up.component';
import { IconHeadphoneComponent } from './icon-headphone/icon-headphone.component';
import { IconHeadphoneOnComponent } from './icon-headphone-on/icon-headphone-on.component';
import { IconHeadphoneOffComponent } from './icon-headphone-off/icon-headphone-off.component';
import { BtnCameraComponent } from './btn-camera/btn-camera.component';
import { IconSocialFacebookComponent } from './icon-social-facebook/icon-social-facebook.component';
import { IconSocialTwitterComponent } from './icon-social-twitter/icon-social-twitter.component';
import { IconSocialFbMessengerComponent } from './icon-social-fb-messenger/icon-social-fb-messenger.component';
import { IconSocialMessengerComponent } from './icon-social-messenger/icon-social-messenger.component';
import { IconSocialEmailComponent } from './icon-social-email/icon-social-email.component';
import { IconButtonSmallComponent } from './icon-button-small/icon-button-small.component';
import { IconBlogComponent } from './icon-blog/icon-blog.component';

@NgModule({
  imports: [CommonModule],
  exports: [
    IconInboxComponent,
    IconMoreComponent,
    IconFavoriteComponent,
    IconSentComponent,
    IconArchivedComponent,
    IconMoonComponent,
    IconSearchComponent,
    IconNotificationComponent,
    IconAlertComponent,
    IconArrowDownComponent,
    IconMoreDotComponent,
    IconTimeComponent,
    IconStarComponent,
    IconTrashComponent,
    IconLoadingComponent,
    IconAngleDownComponent,
    IconDeleteComponent,
    IconSearchBigComponent,
    IconMobileMenuComponent,
    IconArrowLeftComponent,
    IconWarningComponent,
    IconSettingComponent,
    IconInboxUpComponent,
    IconCheckComponent,
    IconPlusComponent,
    IconArrowRightComponent,
    IconPlayComponent,
    IconPauseComponent,
    IconPencilComponent,
    IconCancelComponent,
    IconMusicComponent,
    IconMicComponent,
    IconButtonComponent,
    IconCameraComponent,
    IconPitchComponent,
    IconRestartComponent,
    IconVolumnComponent,
    IconCloseComponent,
    IconHomeComponent,
    IconSyncComponent,
    IconFlameComponent,
    IconHeartComponent,
    IconCommentComponent,
    IconEyeComponent,
    IconShareComponent,
    IconDuetComponent,
    IconAtmComponent,
    IconPlayPrevComponent,
    IconPlayNextComponent,
    IconFullScreenComponent,
    IconShuffleComponent,
    IconPlayLineComponent,
    IconFullscreenComponent,
    IconHeartFullComponent,
    IconMicOffComponent,
    IconAddReactionComponent,
    IconIcComponent,
    IconGlobeComponent,
    IconSingsnapComponent,
    IconLockComponent,
    IconTrophyComponent,
    IconLongArrowDownComponent,
    IconPrivacyMemberonlyComponent,
    IconPrivacyIcComponent,
    IconPrivacyContestComponent,
    IconPrivacyPrivateComponent,
    IconPrivacyPublicComponent,
    IconBubbleComponent,
    IconMegaphoneComponent,
    IconNewComponent,
    IconSortAlphaDownComponent,
    IconSortAlphaUpComponent,
    IconHeadphoneComponent,
    IconHeadphoneOnComponent,
    IconHeadphoneOffComponent,
    BtnCameraComponent,
    IconSocialFacebookComponent,
    IconSocialTwitterComponent,
    IconSocialFbMessengerComponent,
    IconSocialMessengerComponent,
    IconSocialEmailComponent,
    IconFavoriteOutlineComponent,
    IconButtonSmallComponent,
    IconBlogComponent,
  ],
  declarations: [
    IconInboxComponent,
    IconMoreComponent,
    IconFavoriteComponent,
    IconSentComponent,
    IconArchivedComponent,
    IconMoonComponent,
    IconSearchComponent,
    IconNotificationComponent,
    IconAlertComponent,
    IconArrowDownComponent,
    IconMoreDotComponent,
    IconTimeComponent,
    IconStarComponent,
    IconTrashComponent,
    IconLoadingComponent,
    IconAngleDownComponent,
    IconDeleteComponent,
    IconArrowLeftComponent,
    IconSearchBigComponent,
    IconMobileMenuComponent,
    IconWarningComponent,
    IconSettingComponent,
    IconInboxUpComponent,
    IconCheckComponent,
    IconPlusComponent,
    IconArrowRightComponent,
    IconPlayComponent,
    IconPauseComponent,
    IconPencilComponent,
    IconCancelComponent,
    IconMusicComponent,
    IconMicComponent,
    IconButtonComponent,
    IconCameraComponent,
    IconPitchComponent,
    IconRestartComponent,
    IconVolumnComponent,
    IconCloseComponent,
    IconRefreshComponent,
    IconHomeComponent,
    IconSyncComponent,
    IconFlameComponent,
    IconHeartComponent,
    IconCommentComponent,
    IconEyeComponent,
    IconShareComponent,
    IconDuetComponent,
    IconAtmComponent,
    IconPlayPrevComponent,
    IconPlayNextComponent,
    IconFullScreenComponent,
    IconShuffleComponent,
    IconPlayLineComponent,
    IconFullscreenComponent,
    IconHeartFullComponent,
    IconMicOffComponent,
    IconAddReactionComponent,
    IconIcComponent,
    IconGlobeComponent,
    IconSingsnapComponent,
    IconLockComponent,
    IconTrophyComponent,
    IconLongArrowDownComponent,
    IconPrivacyMemberonlyComponent,
    IconPrivacyIcComponent,
    IconPrivacyContestComponent,
    IconPrivacyPrivateComponent,
    IconPrivacyPublicComponent,
    IconBubbleComponent,
    IconMegaphoneComponent,
    IconNewComponent,
    IconSortAlphaDownComponent,
    IconSortAlphaUpComponent,
    IconHeadphoneComponent,
    IconHeadphoneOnComponent,
    IconHeadphoneOffComponent,
    BtnCameraComponent,
    IconSocialFacebookComponent,
    IconSocialTwitterComponent,
    IconSocialFbMessengerComponent,
    IconSocialMessengerComponent,
    IconSocialEmailComponent,
    IconFavoriteOutlineComponent,
    IconButtonSmallComponent,
    IconBlogComponent,
  ],
})
export class SharedIconsModule {}
