<div class="pm-menu-holder d-flex align-items-center justify-content-between">
  <app-icon-arrow-left color="white" *ngIf="showBackButton || showSearch" (click)="onBackClick()"></app-icon-arrow-left>
  <app-icon-search-big color="white" *ngIf="!showBackButton && !showSearch" (click)="onSearchClick()"></app-icon-search-big>
  <h4 class="mobile-page-title" *ngIf="!showBackButton && !showSearch">Private Message</h4>
  <div class="search-box" *ngIf="showSearch">
    <app-icon-search-big size="12"></app-icon-search-big>
    <input #searchInput type="text" class="form-control" placeholder="Search..." name="query" (input)="searchTextChanging.next($event)" />
  </div>
  <span></span>
</div>
