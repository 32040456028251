<div class="container-fluid main-container">

  <div class="container-fluid category-select" *ngIf="selection == true">

    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 product-choice">Select a category <i class="fa fa-angle-down" aria-hidden="true" (click)="selectCategory()"></i>
        <div class="row">
          <div class="col-lg-12 category-list">
            <div class="row">
              <div class="col-lg-6 category">
                <div class="row">
                  <div class="col-lg-6"><p (click)="selectCategory(0)" class="select-category">See All</p></div>
                  <div class="col-lg-6" *ngFor="let category of categories">
                    <p (click)="selectCategory(category.id)" class="select-category">{{category.name}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 overlay-product-sorting">
        <div class="row">
          <div class="col-lg-12 text-right"><span (click)="setTag('34')">MALE</span><span (click)="setTag('35')">FEMALE</span><span (click)="setTag('both')">BOTH</span></div>

          <div class="col-lg-12 overlay-sort-headline">SORT BY</div>
          <div class="col-lg-12 overlay-sort-choices">
            <p (click)="selectFilter('asc')" class="select-category">NEWEST</p>
            <p (click)="selectFilter('desc')" class="select-category">OLDEST</p>
          </div>

        </div>
      </div>
    </div>



  </div>

  <div class="row" *ngIf="category">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 product-choice"><p (click)="getCategories()" class="select-category">{{category.name}} <i class="fa fa-angle-down" aria-hidden="true"></i></p></div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 product-sorting" (click)="getCategories()">
      <span *ngIf="filter.order == 'asc'">NEWEST</span>
      <span *ngIf="filter.order == 'desc'">OLDEST</span>
      <i class="fa fa-angle-down popular-down" aria-hidden="true"></i>
    </div>
  </div>

  <div class="row category-message" *ngIf="emptyCategory">
    <h4>There is no products in selected category</h4>
  </div>

  <div class="row" *ngIf="products">

    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 main-product" *ngFor="let product of products" [routerLink]="['../../product', product.id]">
      <div class="product-image">
        <img src="{{product.images[0].src}}" alt="" width="55%">
      </div>
      <div class="product-headline" [innerHTML]="product.short_description"></div>
      <div class="product-price">${{product.price}}</div>
    </div>

  </div>
  <div class="row" *ngIf="products.length == filter.perPage">
    <div class="col-lg-12 product-more">
      <div class="product-button">
        <button type="submit" class="btn cl_sing_now" (click)="loadMoreProducts()">LOAD MORE</button>
      </div>
    </div>
  </div>

</div>

