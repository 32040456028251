<div class="app-icon">
    <svg [attr.width]="size" [style.width]="size" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <path d="M8,19.5 C9.1,19.5 10,18.6 10,17.5 L6,17.5 C6,18.6 6.89,19.5 8,19.5 L8,19.5 Z M14,13.5 L14,8.5 C14,5.43 12.36,2.86 9.5,2.18 L9.5,1.5 C9.5,0.67 8.83,0 8,0 C7.17,0 6.5,0.67 6.5,1.5 L6.5,2.18 C3.63,2.86 2,5.42 2,8.5 L2,13.5 L0,15.5 L0,16.5 L16,16.5 L16,15.5 L14,13.5 L14,13.5 Z"
                id="path-icon-alert"></path>
        </defs>
        <g id="ic_notifications" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g>
                <g id="Icon-24px" transform="translate(4.000000, 3.000000)">
                    <mask id="mask-2" fill="white">
                        <use xlink:href="#path-icon-alert"></use>
                    </mask>
                    <use id="Mask" [attr.fill]="color" xlink:href="#path-icon-alert"></use>
                </g>
                <polygon id="Bounds" points="0 0 24 0 24 24 0 24"></polygon>
            </g>
        </g>
    </svg>
</div>