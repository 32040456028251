import {Component, OnInit, Input, ChangeDetectionStrategy, HostBinding} from '@angular/core';

@Component({
  selector: 'app-more-dot-menu',
  templateUrl: './more-dot-menu.component.html',
  styleUrls: ['./more-dot-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoreDotMenuComponent implements OnInit {
  @Input() color = '#262628';
  @Input() hasMargin = true;
  @HostBinding('class.mx-0') marginStyle = false;

  constructor() {
  }

  ngOnInit() {
    if (!this.hasMargin) {
      this.marginStyle = true;
    }
  }

}
