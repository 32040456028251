<div class="container container-sm profile-settings">
  <div class="ssc-list-header">
    <h1>Update Email</h1>
  </div>
  <div class="password-form" id='emailForm'>

  <form [formGroup]="emailForm" (ngSubmit)="onEmailSubmit()">
    <div class="form-group">
      <label class="input-label" for="current_email">Current email</label>
      <input type="email" [formControl]="emailForm.controls['current_email']" class="form-control input-ghost" id="current_email">
      <span *ngIf="emailForm.controls['current_email'].hasError('required') && emailForm.controls['current_email'].touched" class="form-error">
        Current email is required.</span>
        <span *ngIf="emailForm.controls['current_email'].hasError('email') && emailForm.controls['current_email'].touched"
        class="form-error">
        Please enter a valid email.</span>
    </div>
    <div class="form-group">
      <label class="input-label" for="email">New email</label>
      <input type="email" [formControl]="emailForm.controls['email']" class="form-control input-ghost" id="email">
      <span *ngIf="emailForm.controls['email'].hasError('required') && emailForm.controls['email'].touched" class="form-error">
        New email is required.</span>
      <span *ngIf="emailForm.controls['email'].hasError('email') && emailForm.controls['email'].touched"
        class="form-error">
        Please enter a valid email.</span>
    </div>
    <div class="ctas">
      <button class="btn btn-primary">SAVE</button>
      <button class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray" type="button" (click)="goBack()">Cancel</button>
    </div>
  </form>
</div>
  <div class="ssc-list-header">
    <h1>Update Password</h1>
  </div>
  <div class="wrapper">
    <div class="password-form">
      <form [formGroup]="pwdForm" (ngSubmit)="onPasswordSubmit()">
        <div class="form-group">
          <label class="input-label" for="current_password">Current password</label>
          <input type="password" [formControl]="pwdForm.controls['current_password']" class="form-control input-ghost" id="current_password">
          <span *ngIf="pwdForm.controls['current_password'].hasError('required') && pwdForm.controls['current_password'].touched" class="form-error">
            Current password is required.</span>
          <span *ngIf="pwdForm.controls['current_password'].hasError('minlength') && pwdForm.controls['current_password'].touched"
            class="form-error">
            Current password should be at least 4 characters.</span>
        </div>
        <div class="form-group">
          <label class="input-label" for="password">New password</label>
          <input type="password" [formControl]="pwdForm.controls['password']" class="form-control input-ghost" id="password">
          <span *ngIf="pwdForm.controls['password'].hasError('required') && pwdForm.controls['password'].touched" class="form-error">
            New password is required.</span>
          <span *ngIf="pwdForm.controls['password'].hasError('minlength') && pwdForm.controls['password'].touched"
            class="form-error">
            New password should be at least 6 characters.</span>
        </div>
        <div class="form-group">
          <label class="input-label" for="password_confirmation">Confirm password</label>
          <input type="password" [formControl]="pwdForm.controls['password_confirmation']" class="form-control input-ghost" id="password_confirmation">
          <span *ngIf="pwdForm.controls['password_confirmation'].hasError('required') && pwdForm.controls['password_confirmation'].touched" class="form-error">
            Confirm password is required.</span>
          <span *ngIf="pwdForm.controls['password_confirmation'].hasError('minlength') && pwdForm.controls['password_confirmation'].touched"
            class="form-error">
            Confirm password should be at least 6 characters.</span>
        </div>
        <div class="ctas">
          <button class="btn btn-primary">SAVE</button>
          <button class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray" type="button" (click)="goBack()">Cancel</button>
        </div>
      </form>
    </div>
    <div class="sidebar">
      <div class="sidebar-desc">
        <p>Password must have at least 6 characters—the more characters, the better. We suggest:
           <li>A mixture of both uppercase and lowercase letters.</li> 
           <li>A mixture of letters and numbers.</li> 
           <li>Inclusion of at least one special character, e.g., ! @ # $</li> 
        </p>
      </div>
    </div>
  </div>
</div>