<div class="form-submit-success">
  <div class="icon-success">
    <div class="icon-inner circle-ripple circle-green">
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <div class="icon-holder">
        <app-icon-check color="white" size="48"></app-icon-check>
      </div>
    </div>
  </div>
  <h4>Recording {{isRemix ? 'Is Being Remixed' : 'Saved'}}!</h4>
  <p style="margin-bottom: 30px;color: #000;">Mixing takes about 30 - 60 seconds. Your recording will appear in your My Recordings section when it's finished.</p>
  <div class="upload-detail">
    <div class="ssc-wrapper">
      <div class="song-info">
        <div 
          class="song-poster" 
          *ngIf="image"
          [ngStyle]="{'background-image': 'url(' + image + ')'}"
        ></div>
        <div class="song-detail">
          <h4 class="song-title">{{title}}</h4>
          <h4 class="song-author">{{artist}}</h4>
        </div>
      </div>
    </div>
    <!-- <div class="upload-menu" (click)="notifyReady()">
      <app-icon-notification color="#D0D0D0" size="18"></app-icon-notification>
      <h5>NOTIFY ME WHEN IT'S READY</h5>
    </div> -->
    <div *ngIf="!isRemix" class="upload-menu" (click)="singAgain()">
      <app-icon-restart color="#D0D0D0"></app-icon-restart>
      <h5>SING AGAIN</h5>
    </div>
    <div class="upload-menu" (click)="gotoHome()">
      <app-icon-home color="#D0D0D0"></app-icon-home>
      <h5>RETURN HOME</h5>
    </div>
    <div class="upload-menu" (click)="navigate(['/d/my-recordings'])">
      <app-icon-home color="#D0D0D0"></app-icon-home>
      <h5>MY RECORDINGS</h5>
    </div>
    <div *ngIf="!isRemix" class="upload-menu" (click)="onReport()">
      <app-icon-warning color="#D0D0D0" size="24"></app-icon-warning>
      <h5>REPORT ISSUE</h5>
    </div>
  </div>
  <app-ss-report-modal #report (report)="reportRecording($event)" [reporting]="reporting">
    <div class="report-content">
      <app-ss-recording *ngIf="recording" [recording]="recording"></app-ss-recording>
    </div>
  </app-ss-report-modal>
</div>
