<div class="app-icon">
  <svg class='icon-heart' [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 49.3 (51167) - http://www.bohemiancoding.com/sketch -->
    <title>Icons/Heart</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M16.4396068,2.56039322 C18.520166,4.64095239 18.520166,8.01527877 16.4396593,10.0945543 L9.00055252,17.5347661 L8.29339322,16.8276068 L1.56060251,10.094816 C-0.520165956,8.01527877 -0.520165956,4.64095239 1.56060239,2.56018411 C3.61105535,0.510943714 6.91482814,0.480333078 9.00001867,2.46800056 C11.0860721,0.480306012 14.3903838,0.511170226 16.4396068,2.56039322 Z M15.0256025,8.68018398 C16.324834,7.38172123 16.324834,5.27404761 15.0253932,3.97460678 C13.7259524,2.67516596 11.6182788,2.67516596 10.319816,3.97439749 L9.00107699,5.29313657 L8.29397068,4.58718378 L7.68018398,3.97439749 C6.3816864,2.6751311 4.27485144,2.6751311 2.97460678,3.97460678 C1.67516596,5.27404761 1.67516596,7.38172123 2.97460678,8.68039322 L9.00044749,14.7062339 L15.0256025,8.68018398 Z"
        id="path-heart"></path>
    </defs>
    <g id="Icons/Heart" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-heart" fill="white">
        <use xlink:href="#path-heart"></use>
      </mask>
      <use id="Fill-heart" [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-heart"></use>
    </g>
  </svg>
</div>