<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
    <title>refresh-button</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M15.3,2.7 C13.725,1.0125 11.475,0 9,0 C4.05,0 0,4.05 0,9 C0,13.95 4.05,18 9,18 C13.1625,18 16.65,15.075 17.6625,11.25 L15.3,11.25 C14.4,13.8375 11.925,15.75 9,15.75 C5.2875,15.75 2.25,12.7125 2.25,9 C2.25,5.2875 5.2875,2.25 9,2.25 C10.9125,2.25 12.4875,3.0375 13.725,4.275 L10.125,7.875 L18,7.875 L18,0 L15.3,2.7 Z"
        id="path-restart"></path>
    </defs>
    <g id="refresh-button" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(9.000000, 9.000000) scale(-1, 1) translate(-9.000000, -9.000000) " id="↳Tint/Backgroud">
        <g>
          <mask id="mask-2" fill="white">
            <use xlink:href="#path-restart"></use>
          </mask>
          <use id="Mask" [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-restart"></use>
        </g>
      </g>
    </g>
  </svg>
</div>