import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-privacy-private',
  templateUrl: './icon-privacy-private.component.html',
  styleUrls: ['./icon-privacy-private.component.scss']
})
export class IconPrivacyPrivateComponent implements OnInit {
  @Input() color = '#D0D0D0';
  @Input() size = 18;

  constructor() { }

  ngOnInit() {
  }

}
