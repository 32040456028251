<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="equalizer-copy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="equalizer" transform="translate(0.000000, 13.000000)" [attr.fill]="color" fill-rule="nonzero">
        <path d="M256,98.825 C212.759,98.825 177.58,134.004 177.58,177.245 C177.58,220.486 212.759,255.665 256,255.665 C299.241,255.665 334.42,220.486 334.42,177.245 C334.42,134.004 299.241,98.825 256,98.825 Z M256,216.78 C234.198,216.78 216.466,199.041 216.466,177.246 C216.466,155.444 234.198,137.712 256,137.712 C277.796,137.712 295.534,155.444 295.534,177.246 C295.534,199.048 277.796,216.78 256,216.78 Z"
          id="Shape"></path>
        <path d="M256,217.428 C245.261,217.428 236.557,226.132 236.557,236.871 L236.557,467.595 C236.557,478.334 245.261,487.038 256,487.038 C266.739,487.038 275.443,478.334 275.443,467.595 L275.443,236.871 C275.443,226.132 266.739,217.428 256,217.428 Z"
          id="Shape"></path>
        <path d="M256,0.962 C245.261,0.962 236.557,9.666 236.557,20.405 L236.557,118.268 C236.557,129.007 245.261,137.711 256,137.711 C266.739,137.711 275.443,129.007 275.443,118.268 L275.443,20.405 C275.443,9.666 266.739,0.962 256,0.962 Z"
          id="Shape"></path>
        <path d="M433.58,232.334 C390.345,232.334 355.16,267.513 355.16,310.754 C355.16,353.995 390.345,389.174 433.58,389.174 C476.821,389.174 512,353.995 512,310.754 C512,267.513 476.815,232.334 433.58,232.334 Z M433.58,350.295 C411.771,350.295 394.039,332.556 394.039,310.754 C394.039,288.952 411.778,271.22 433.58,271.22 C455.382,271.22 473.114,288.959 473.114,310.754 C473.114,332.563 455.375,350.295 433.58,350.295 Z"
          id="Shape"></path>
        <path d="M433.58,350.289 C422.841,350.289 414.137,358.993 414.137,369.732 L414.137,467.595 C414.137,478.334 422.841,487.038 433.58,487.038 C444.325,487.038 453.023,478.334 453.023,467.595 L453.023,369.732 C453.023,358.993 444.319,350.289 433.58,350.289 Z"
          id="Shape"></path>
        <path d="M433.58,0.962 C422.841,0.962 414.137,9.666 414.137,20.405 L414.137,251.129 C414.137,261.868 422.841,270.572 433.58,270.572 C444.325,270.572 453.023,261.868 453.023,251.129 L453.023,20.405 C453.023,9.666 444.319,0.962 433.58,0.962 Z"
          id="Shape"></path>
        <path d="M78.42,232.334 C35.179,232.334 0,267.513 0,310.754 C0,353.995 35.179,389.174 78.42,389.174 C121.661,389.174 156.84,353.995 156.84,310.754 C156.841,267.513 121.662,232.334 78.42,232.334 Z M78.42,350.295 C56.618,350.295 38.886,332.556 38.886,310.754 C38.886,288.952 56.618,271.22 78.42,271.22 C100.222,271.22 117.954,288.959 117.954,310.754 C117.954,332.563 100.222,350.295 78.42,350.295 Z"
          id="Shape"></path>
        <path d="M78.42,350.289 C67.681,350.289 58.977,358.993 58.977,369.732 L58.977,467.595 C58.977,478.334 67.681,487.038 78.42,487.038 C89.159,487.038 97.863,478.334 97.863,467.595 L97.863,369.732 C97.863,358.993 89.159,350.289 78.42,350.289 Z"
          id="Shape"></path>
        <path d="M78.42,0.962 C67.681,0.962 58.977,9.666 58.977,20.405 L58.977,251.129 C58.977,261.868 67.681,270.572 78.42,270.572 C89.159,270.572 97.863,261.868 97.863,251.129 L97.863,20.405 C97.863,9.666 89.159,0.962 78.42,0.962 Z"
          id="Shape"></path>
      </g>
    </g>
  </svg>
</div>
