<div class="ss-store-option ss-glow-box" [class.selected]="selected" (click)="selectOptionClick($event)">
  <div class="ss-store-option-top">
    <div class="payment-radio">
      <span (click)="selectOptionClick($event)">
        <span></span>
      </span>
    </div>
    <div class="ss-store-option-body">
      <ng-content select=".ss-store-option-title">
      </ng-content>
      <ng-content select=".ss-store-option-body">
      </ng-content>
    </div>
  </div>
</div>
