<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path
        d="M14.31,13.851 C14.076,13.131 13.401,12.6 12.6,12.6 L11.7,12.6 L11.7,9.9 C11.7,9.40294373 11.2970563,9 10.8,9 L5.4,9 L5.4,7.2 L7.2,7.2 C7.69705627,7.2 8.1,6.79705627 8.1,6.3 L8.1,4.5 L9.9,4.5 C10.8941125,4.5 11.7,3.69411255 11.7,2.7 L11.7,2.331 C14.337,3.393 16.2,5.976 16.2,9 C16.2,10.872 15.48,12.573 14.31,13.851 Z M8.1,16.137 C4.545,15.696 1.8,12.672 1.8,9 C1.8,8.442 1.872,7.902 1.989,7.389 L6.3,11.7 L6.3,12.6 C6.3,13.5941125 7.10588745,14.4 8.1,14.4 L8.1,16.137 Z M9,-4.4408921e-17 C4.02943725,-2.04281037e-15 -4.4408921e-17,4.02943725 -4.4408921e-16,9 C-1.64313008e-15,11.3869484 0.948211563,13.6761336 2.63603897,15.363961 C4.32386638,17.0517884 6.61305159,18 9,18 C11.3869484,18 13.6761336,17.0517884 15.363961,15.363961 C17.0517884,13.6761336 18,11.3869484 18,9 C18,6.61305159 17.0517884,4.32386638 15.363961,2.63603897 C13.6761336,0.948211563 11.3869484,-2.44249065e-16 9,-4.4408921e-17 Z"
        id="path-privacy-public"></path>
    </defs>
    <g id="Credit-Icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="icon-privacy-public" transform="translate(-58.000000, -65.000000)">
        <g id="public" transform="translate(58.000000, 65.000000)">
          <mask id="mask-2" fill="white">
            <use xlink:href="#path-privacy-public"></use>
          </mask>
          <use id="Shape" [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-privacy-public"></use>
        </g>
      </g>
    </g>
  </svg>
</div>
