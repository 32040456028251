<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 16 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
  <title>Fill 1</title>
  <desc>Created with Sketch.</desc>
  <defs>
    <path
      d="M3,2.7321 L3,15.2681 L13.856,9.0001 L3,2.7321 Z M2,18.0001 C1.827,18.0001 1.654,17.9561 1.5,17.8661 C1.19,17.6881 1,17.3571 1,17.0001 L1,1.0001 C1,0.6431 1.19,0.3131 1.5,0.1341 C1.809,-0.0449 2.191,-0.0449 2.5,0.1341 L16.356,8.1341 C16.666,8.3131 16.856,8.6431 16.856,9.0001 C16.856,9.3571 16.666,9.6881 16.356,9.8661 L2.5,17.8661 C2.346,17.9561 2.173,18.0001 2,18.0001 L2,18.0001 Z"
      id="icon-play-line"></path>
  </defs>
  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Icons/Play" transform="translate(-1.000000, 0.000000)">
      <mask id="mask-2" fill="white">
        <use xlink:href="#icon-play-line"></use>
      </mask>
      <use id="Fill-1" [attr.fill]="color" xlink:href="#icon-play-line"></use>
    </g>
  </g>
</svg>
</div>