<div *ngIf='blueLimitReached' class='blueLimitContainer'>
  <div class='blueFlexContainer'>
    <p>You've reached your maximum number of free recordings as a blue member! Upgrade to GOLD now for unlimited recording!</p>
    <button (click)='purchaseGold()'>Purchase GOLD</button>
  </div>
</div>
<div *ngIf='legacyDuet' class='blueLimitContainer'>
  <div class='blueFlexContainer'>
    <p>Legacy recordings are not available for duet at this time. - Coming Soon!</p>
  </div>
</div>
<div class="ss-r-wrapper" id="spaceContainer">
  <div class="ss-r-wrapper-inner">
    <div class="ss-r-header" [ngClass]="{'song-loaded': song}">
      <div class="ss-r-header-left">

        <app-icon-button color="rgba(255, 255, 255, 0.3)" (click)="backClicked()" *ngIf="!finished">
          <app-icon-arrow-left color="white"></app-icon-arrow-left>
        </app-icon-button>
        <div class="main-controls-left">
          <h6>{{recMode}}</h6>
        </div>
        <div class="mmsb-switch ss-switch-holder" *ngIf="reviewer">
          <span class="ss-label">MMSB</span>
          <label class="ss-custom-switch">
            <input type="checkbox" class="no-disable-color" [(ngModel)]="mmsb" (change)="onChangeMMSB($event)" [disabled]="disableMMSB" />
            <span class="ss-slider"></span>
          </label>
        </div>

      </div>
      <div class="ss-r-title-content">
        <h2 class="ss-r-title" *ngIf="song && showControls && loaded">{{song.title}}</h2>
        <h6 class="ss-r-subtitle" *ngIf="!showControls">{{subtitle}}</h6>
      </div>
      <div class="ss-r-header-right">

        <app-btn-camera *ngIf="!finished && !recordingWIP() && !noCam" class="toggleVideo" [status]="recorder && recorder.camOpen" (click)="toggleCamera()"></app-btn-camera>
        <button *ngIf="finished" class="btn btn-lg btn-primary topButtons"  style='background-color: #47cca7;' (click)="tryAgain()">TRY AGAIN</button>
        <button *ngIf="finished" class="btn btn-lg btn-primary topButtons" style='background-color: white;' (click)="upload()">SAVE</button>
        <div ngbDropdown class="cam-dropdown" *ngIf="!finished && !recordingWIP() && !noCam">
          <span ngbDropdownToggle>
            <app-icon-angle-down color="#a5a5a5" size="18"></app-icon-angle-down>
          </span>
          <div ngbDropdownMenu>
            <div class="dropdown-item" [ngClass]="{'active': recorder && recorder.videoDeviceId == cam.deviceId}" *ngFor="let cam of videoSources" (click)="changeCamSource(cam.deviceId)">
              {{cam.label}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div    *ngIf='lyricsToggle' class="ss-r-lyric-wrapper" [ngClass]="{'song-loaded': song}">
      <canvas #lyric class="ss-r-lyric-canvas" id='lyricsCanvas' width="1000" height="170"></canvas>

    </div>
    <div class="ss-r-content">
      <div class="ss-r-right-panel">
        <div class="ss-r-thumb-holder" *ngIf="refreshing"></div>
        <div class="ss-r-thumb-holder" *ngIf="!refreshing">
          <span class="ss-r-version" (click)="enableTestSound()">V {{recorderVersion}}</span>
          <div class="ss-r-thumb"></div>
          <audio #audioMusic class="audio-music"></audio>
          <audio #audioMusic2 class="audio-music"></audio>
          <audio #audioVoice class="audio-voice"></audio>
          <audio #audioTest class="audio-test"></audio>
          <div class="ss-r-holder" #videoHolder>
            <div class="ss-r-item">
              <video
                class="video ss-r-video ss-r-video-record"
                autoplay="true"
                #videoRecorder
              ></video>
            </div>
            <div class="ss-r-item" id="reviewHolder">
              <video class="video ss-r-video ss-r-video-reviewer" #videoReviewer></video>
            </div>
            <div class="ss-r-item">
              <video class="video ss-r-video ss-r-video-duet" #videoDuet></video>
            </div>
          </div>
          <div class="ss-r-play-info">
            <!-- RECORD SCREEN CONTROL -->
            <div class="ss-r-btn--record">
              <app-icon-button *ngIf="buttons.pause && playerLoaded" color="white" size="40px" (click)="pause()">
                <app-icon-pause></app-icon-pause>
              </app-icon-button>
              <app-icon-button *ngIf="buttons.resume && playerLoaded" color="white" size="40px" (click)="resume()">
                <app-icon-play-line style="margin-left: 3px;"></app-icon-play-line>
              </app-icon-button>
              <app-icon-button *ngIf="buttons.resumeRecording && playerLoaded" color="white" size="40px" (click)="resumeRecording()">
                <span class="icon-record-dot dot-pink"></span>
              </app-icon-button>
            </div>
            <!-- RECORDER TIMELINE -->
            <div class="ss-r-play-time">
              <span class="ss-r-time-active">{{getCurrentTime()}}</span>
              <span class="ss-r-time-total"> / {{getDuration()}}</span>
            </div>
          </div>
          <div class="parts__selector" style='z-index: 50;'><h3>
            SELECT A PART TO SING:
          </h3></div>
          <div class="recording-countdown" *ngIf="countdownTimer"><span>{{countdown}}</span></div>
          <h2 class="recording-loading" *ngIf="!loaded && !notLoaded && !blueLimitReached">Loading... <i class="fa fa-spinner fa-pulse fa-fw" style="font-size: 47px; text-shadow: none;" *ngIf="!loaded && !notLoaded"></i></h2>
          <h2 class="recording-loading" *ngIf="reviewerLoading">Loading Reviewer... <i class="fa fa-spinner fa-pulse fa-fw" style="font-size: 47px; text-shadow: none;" *ngIf="reviewerLoading"></i></h2>
          <h2 class="recording-loading" *ngIf="reviewerLoaded">Reviewer Loaded!</h2>


          <h3 class="recording-loading" style="font-size: 39px;"*ngIf="notLoaded && !loaded">Still loading? Try refreshing the page <i class="fa fa-spinner fa-pulse fa-fw" style="font-size: 47px; text-shadow: none;" *ngIf="notLoaded && !loaded"></i></h3>
          <h2 class="recording-loading" *ngIf="restarting">Restarting... <i class="fa fa-spinner fa-pulse fa-fw" style="font-size: 47px; text-shadow: none;" *ngIf="restarting"></i></h2>
          <!-- <h2 class="recording-loading" *ngIf="soxProcessing">Processing...</h2> -->
          <h2 class="recording-loading" style="font-size: 22px; text-shadow: none; margin-top: 124px; color: red;" *ngIf="noPermissions">{{ errorMessage }}</h2><button>x</button>

        </div>
        <app-progress-bar [duration]="duration" [noSeek]="recordingWIP()" [current]="currentTime" (seek)="onSeek($event)"></app-progress-bar>
      </div>
      <div class="ss-r-mic-value" [ngClass]="{'mic-hidden': finished}">
        <div class="ss-r-mic-bar">
          <div class="ss-r-mic-bar-value">
            <canvas #micMeter width="4" height="390"></canvas>
          </div>
        </div>
        <div class="mic-selector" ngbDropdown>
          <div class="mic-dropdown" ngbDropdownToggle>
            <!-- <app-icon-mic *ngIf="recorder && recorder.micOn" color="#FF5656" size="18"></app-icon-mic> -->
            <app-icon-mic *ngIf="recorder && !recorder.micOn" color="#a5a5a5" size="18"></app-icon-mic>
            <span>
              <app-icon-angle-down color="#a5a5a5" size="18"></app-icon-angle-down>
            </span>
          </div>
          <div ngbDropdownMenu>
            <div class="dropdown-item" [ngClass]="{'active': recorder && recorder.audioDeviceId == mic.deviceId}" *ngFor="let mic of audioSources" (click)="changeMicSource(mic.deviceId)">
              {{mic.label}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ss-r-adjust-bar" [hidden]="!showVolumeBar">
      <div class="ss-bar">
        <div class="ss-bar-item" *ngIf="!finished">
          <app-icon-mic size="18" color="#a5a5a5" [style.margin]="'0 3px'"></app-icon-mic>
          <app-adjust-bar
            [value]="micVolume"
            [min]="0" [max]="1" [step]="0.01" [multiply]="10" [noFraction]="true"
            (changed)="onMicVolumeChange($event)">
          </app-adjust-bar>
        </div>
        <div class="ss-bar-item" *ngIf="finished">
          <app-icon-mic size="18" color="#a5a5a5" [style.margin]="'0 3px'"></app-icon-mic>
          <app-adjust-bar
            [value]="micVolume"
            [min]="0" [max]="1" [step]="0.01" [multiply]="10" [noFraction]="true"
            (changed)="onMicVolumeChange($event)">
          </app-adjust-bar>
        </div>
        <div *ngIf='!duet' class="ss-bar-item">
          <app-icon-music size="24" color="white"></app-icon-music>
          <app-adjust-bar
            [value]="volume"
            [min]="0" [max]="1" [step]="0.01" [multiply]="10" [noFraction]="true"
            (changed)="onVolumeChange($event)">
          </app-adjust-bar>
        </div>
      </div>
      <app-icon-button color="#F94678" size="60px" [style.marginLeft]="'30px'" (click)="onVolumeCancel()">
        <app-icon-close size="27" color="white"></app-icon-close>
      </app-icon-button>
      <app-icon-button color="#4FEAAE" size="60px" [style.marginLeft]="'30px'" (click)="onVolumeAccept()">
        <app-icon-check size="27" color="white"></app-icon-check>
      </app-icon-button>
    </div>
    <!-- SYNC BAR -->
    <div [hidden]="!showSyncBar" id="syncContainer">
    <p class="syncText">(Sync value is shown in milliseconds)</p>
    <div class="ss-r-adjust-bar">
      <div class="ss-bar">
        <div class="ss-bar-item">
          <app-recorder-sync-bar
            #syncBar
            style="width: 100%;"
            [value]="sync"
            (changed)="onSyncChange($event)">
          </app-recorder-sync-bar >
        </div>
      </div>
      <app-icon-button color="#F94678" size="60px" [style.marginLeft]="'30px'" (click)="onSyncCancel()">
        <app-icon-close size="27" color="white"></app-icon-close>
      </app-icon-button>
      <app-icon-button color="#4FEAAE" size="60px" [style.marginLeft]="'30px'" (click)="onSyncAccept()">
        <app-icon-check size="27" color="white"></app-icon-check>
      </app-icon-button>
    </div>
  </div>
    <div class="ss-r-adjust-bar" [hidden]="!showPitchBar">
      <div class="ss-bar">
        <div class="ss-bar-item">
          <app-icon-pitch size="18" color="white" [style.margin]="'0 3px'"></app-icon-pitch>
          <app-adjust-bar
            [value]="pitch"
            [stepCount]="12"
            [min]="-6" [max]="6" [step]="1"
            (changed)="onPitchChange($event)">
          </app-adjust-bar>

        </div>
        <!-- <div class="ss-bar-item">
          <p style='color: white;'>grainsize: {{grainSize}}</p>
          <app-adjust-bar
            [value]="grainSize"
            [stepCount]="50"

            [noTicks]='true'
            [min]="0.01" [max]="0.50" [step]="0.01"
            (changed)="changeGrainSize($event)">
          </app-adjust-bar>
        </div>
        <div class="ss-bar-item">
          <p style='color: white;'>overlap: {{overlap}}</p>
          <app-adjust-bar
            [value]="overlap"
            [noTicks]='true'

            [stepCount]="20"
            [min]="0.01" [max]="0.20" [step]="0.01"
            (changed)="changeOverLap($event)">
          </app-adjust-bar>
        </div> -->
      </div>
      <!-- <app-icon-button color="#F94678" size="60px" [style.marginLeft]="'30px'" (click)="onPitchCancel()">
        <app-icon-close size="27" color="white"></app-icon-close>
      </app-icon-button> -->
      <app-icon-button color="#4FEAAE" size="60px" [style.marginLeft]="'30px'" (click)="onPitchAccept()">
        <app-icon-check size="27" color="white"></app-icon-check>
      </app-icon-button>
    </div>


    <div class="ss-r-adjust-bar" [hidden]="!showLyricSpeedBar">
      <div class="ss-bar">
        <div class="ss-bar-item">
          <app-adjust-bar
            [value]="lyricSpeed"
            [stepCount]="20"
            [min]="-10" [max]="10" [step]="1"
            (changed)="onLyricSpeedChange($event)">
          </app-adjust-bar>
        </div>
      </div>
      <app-icon-button color="#4FEAAE" size="60px" [style.marginLeft]="'30px'" (click)="onLyricSpeedAccept()">
        <app-icon-check size="27" color="white"></app-icon-check>
      </app-icon-button>
    </div>


    <!-- MAIN CONTROL -->
    <div class="ss-r-control" *ngIf="showControls && loaded && !noPermissions">
      <div class="ss-r-control--main">

        <div class="main-controls" >

          <div class="ss-r-btn">
            <app-icon-button color="#e2fbf4" size="60px" (click)="onVolumeClick()">
              <app-icon-volumn size="27"></app-icon-volumn>
            </app-icon-button>
            <h6 class="ss-r-btn-text">VOLUME</h6>
          </div>
          <div class="ss-r-btn" *ngIf='!recorder.reviewer && !recordingWIP() && !duet && !pitchDisabled'>
            <app-icon-button color="#e2fbf4" size="60px" (click)="onPitchClick()">
              <app-icon-pitch size="27"></app-icon-pitch>
            </app-icon-button>
            <h6 class="ss-r-btn-text">PITCH</h6>
          </div>
          <div class="ss-r-btn-disabled ss-r-btn" *ngIf='recordingWIP() && !duet && !pitchDisabled'>
            <app-icon-button color="#e2fbf4" size="60px">
              <app-icon-pitch size="27"></app-icon-pitch>
            </app-icon-button>
            <h6 class="ss-r-btn-text">PITCH</h6>
          </div>
          <div class="ss-r-btn" *ngIf="buttons.sync">
            <app-icon-button color="#e2fbf4" size="60px" (click)="onSyncClick()">
              <app-icon-sync size="27"></app-icon-sync>
            </app-icon-button>
            <h6 class="ss-r-btn-text">SYNC</h6>
          </div>
          <div class="ss-r-btn">
            <app-icon-button color="#e2fbf4" size="60px" (click)="onLyricSpeedClick()">
              <p style="
              font-size: 33px;
              margin-top: 15px;
              ">A</p>
            </app-icon-button>
            <h6 class="ss-r-btn-text">LYRIC SPEED</h6>
          </div>
          <div id='rightButtons'>
            <div class="ss-r-btn ss-r-btn--record" *ngIf="buttons.restart" (click)="tryAgain()">
              <app-icon-button color="#e2fbf4" size="60px">
                <app-icon-restart size="27"></app-icon-restart>
              </app-icon-button>
              <h6 class="ss-r-btn-text">RESTART</h6>
            </div>
            <div class="ss-r-btn ss-r-btn--record" *ngIf="buttons.record && loaded && playerLoaded">
              <app-icon-button color="#F94678" size="60px" (click)="startRecording()">
                <span class="icon-record-dot"></span>
              </app-icon-button>
              <h6 class="ss-r-btn-text">RECORD</h6>
            </div>
            <div class="ss-r-btn ss-r-btn--play" *ngIf="buttons.review">
              <app-icon-button color="#56a26d" size="60px" (click)="stopRecording()">
                <app-icon-arrow-right color="#04071c" size="27"></app-icon-arrow-right>
              </app-icon-button>
              <h6 class="ss-r-btn-text">REVIEW</h6>
            </div>
            <div class="ss-r-btn ss-r-btn--play" *ngIf="buttons.practice && loaded && playerLoaded">
              <app-icon-button color="#3aa7a7" size="60px" (click)="startPractice()">
                <app-icon-play-line size="24" style="margin-left: 5px;"></app-icon-play-line>
              </app-icon-button>
              <h6 class="ss-r-btn-text">PRACTICE</h6>
            </div>
        </div>


          <!-- <div class="ss-r-btn ss-r-btn--record" *ngIf="recorder && buttons.monitoring">
            <app-icon-button color="white" size="60px" (click)="toggleMic(false)" *ngIf="recorder.micOn">
              <app-icon-headphone-on size="27"></app-icon-headphone-on>
            </app-icon-button>
            <app-icon-button color="white" size="60px" (click)="toggleMic(true)" *ngIf="!recorder.micOn">
              <app-icon-headphone-off color="#FF5656" size="27"></app-icon-headphone-off>
            </app-icon-button>
            <h6 class="ss-r-btn-text">monitoring</h6>
          </div> -->
        </div>
        <div class="main-controls-right">
          <div *ngIf="buttonReset">
            <div class="right-control-item" (click)="onOtherSingers()">
              <span>Hear how others sing this</span>
              <app-icon-play color="white"></app-icon-play>
            </div>
            <div class="right-control-item">
              <span>Report</span>
              <app-icon-warning color="white"></app-icon-warning>
            </div>
          </div>
        </div>
      </div>
    </div>
    <input type="hidden" placeholder="uuid" name="uuid" />
    <app-other-singers #otherSingers></app-other-singers>
    <app-purchase-gold-modal #goldcontent type="2"></app-purchase-gold-modal>
  </div>
</div>
