import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ss-confirm-modal',
  templateUrl: './ss-confirm-modal.component.html',
  styleUrls: ['./ss-confirm-modal.component.scss']
})
export class SsConfirmModalComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  @Output() confirmed = new EventEmitter();
  @Output() closed = new EventEmitter();
  title = '';
  description = '';
  cancelText = 'Cancel';
  confirmText = 'Ok';
  iconClass = 'fa fa-exclamation-triangle';
  modalRef;

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
  }

  open(title, description, cancelText, confirmText, iconClass = null) {
    this.title = title;
    this.description = description;
    if (cancelText) {
      this.cancelText = cancelText;
    }
    if (confirmText) {
      this.confirmText = confirmText;
    }
    if (iconClass) {
      this.iconClass = iconClass;
    }
    this.openModal();
  }

  openModal() {
    this.modalRef = this.modalService.open(this.contentElem, {
      windowClass: 'ss-warning-modal',
      backdrop: 'static',
    });

    this.modalRef.result.then(
      result => {
        if (this.closed) {
          this.closed.emit();
        }
      },
      async reason => {
        if (this.confirmed) {
          this.confirmed.emit();
        }
      }
    );
  }

}
