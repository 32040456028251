import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import {Location} from '@angular/common';
import {UtilService} from '@app/core';

@Component({
  selector: 'app-ss-featured-grid',
  templateUrl: './ss-featured-grid.component.html',
  styleUrls: ['./ss-featured-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SsFeaturedGridComponent implements OnInit {
  @Input() type: string;
  @Input() title: string = "";
  @Input() seeMorePage: string = null;
  @Input() hasSeeMore = true; // dummy
  @Input() showAction = true; // dummy
  @Input() cols: any = 1;
  @Input() colsXl: any = null;
  @Input() colsLg: any = null;
  @Input() colsLgm: any = null;
  @Input() colsMd: any = null;
  @Input() colsSm: any = null;
  @Input() limit: any;
  @Input() list: Array<any>;
  @Input() showHeader = true;
  @Input() loading = false;
  @Input() scrollable = true;
  @Input() customClass = '';
  @Input() showOrderOptions = true; // dummy
  @Input() sortOptions = [];
  @Input() smallTitle = false;
  @Input() halfSize = false;
  @Input() extendedSize = false;
  @Input() checkOwnRecording = false;
  @Input() freeSongFilter = false;
  @Input() noHeightLimit = false;
  @Input() customHeader = false;
  @Input() userId;
  @Input() userIdFromProfile;
  @Input() seeMoreHeader = false;
  @Input() sortSelectionIcon: number = 0;
  @Input() skeletonType = "";
  //@Input() letterFilter = '';

  sort = {};
  @ContentChild('itemContent', /* TODO: add static flag */ {}) itemTmpl: TemplateRef<any>;
  @ContentChild('headerContent', /* TODO: add static flag */ {}) headerTmpl: TemplateRef<any>;
  @Output() onScrollEnd = new EventEmitter<any>();
  @Output() onOrderBy = new EventEmitter<any>();
  @Output() freeSongChanged = new EventEmitter();
  @ViewChild('ps', { static: true }) ps;
  freeSongs = false;
  config = { wheelPropagation: false };
  windowWidth = null;
  skeletonColumns = 0;
  showInFeedAds = true;
  loggedInIsGold = true;
  breakpoints = {
    xl: 1800,
    lg: 1250,
    lgm: 1050,
    md: 992,
    sm: 768,
  };

  constructor(private location: Location, private util: UtilService) {
  }

  ngOnInit() {
    if (this.limit) {
      this.list = this.list.splice(0, this.limit);
    }
    if (this.sortOptions.length) {
      this.sort = this.sortOptions[this.sortSelectionIcon];
    }
    console.log('*** recording', this.userId, this.checkOwnRecording);
    this.windowWidth = window.innerWidth;

    let loggedIn = this.util.getloggedUserData();
    this.loggedInIsGold = loggedIn.gold;

   if( this.loggedInIsGold || this.title === 'Genres' || this.title === 'Genres - All' || this.title === ' Sing - Genres' ){
    this.showInFeedAds = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth;
  }

  checkMine(item) {
    if (!this.checkOwnRecording || !this.userId || !Array.isArray(item.users)) {
      return false;
    }
    let valid = false;
    item.users.forEach(user => {
      if (user.id === this.userId) {
        valid = true;
      }
    });
    return valid;
  }

  get gridStyles() {
    let cols = parseInt(this.cols, 10);
    if (this.windowWidth < this.breakpoints.sm && this.colsSm) {
      cols = parseInt(this.colsSm, 10);
    } else if (this.windowWidth < this.breakpoints.md && this.colsMd) {
      cols = parseInt(this.colsMd, 10);
    } else if (this.windowWidth < this.breakpoints.lgm && this.colsLgm) {
      cols = parseInt(this.colsLgm, 10);
    } else if (this.windowWidth < this.breakpoints.lg && this.colsLg) {
      cols = parseInt(this.colsLg, 10);
    } else if (this.windowWidth < this.breakpoints.xl && this.colsXl) {
      cols = parseInt(this.colsXl, 10);
    }

    this.skeletonColumns = cols;
    
    const styles: any = {
      'grid-template-columns': `repeat(${cols}, 1fr)`,
      'grid-column-gap': '10px',
    };
    // if (this.list && this.list.length) {
    //   styles.padding = '20px 5px 5px';
    // }
    return styles;
  }

  scrollEndHandle(event) {
    this.onScrollEnd.emit(this.sort); 
  }

  handleOrderBy(sort) {
    this.sort = sort;
    this.onOrderBy.emit(sort);
    this.ps.nativeElement.scrollTop = 0;
  }

  handleChangeFreeSongs(value) {
    this.freeSongs = value;
    this.freeSongChanged.emit(value);
  }

  closeOrBack() {
    this.location.back();
  }

  getSeeMoreURL(){
    let component = this.seeMorePage;
    let fields = component.split(/:/);
    let name = fields[0];
    let p:string = "";
    let u:string = "";
    if(component.includes(":")){
      p = '?p=' + fields[1];
      if(this.userIdFromProfile){
        u = '&u=' + this.userIdFromProfile;
      };
    };
 
    return '#/d/' + name + p + u;
  }
}
