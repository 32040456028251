import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { SharedModule } from '../shared';
import { SharedIconsModule } from '../shared/icons/icons.module';
import { InlineSVGModule } from 'ng-inline-svg';

import { DashboardReComponent } from './dashboard-re.component'
import { HomeReComponent } from './home-re/home-re.component'
import { MenuMainComponent } from './menu-main/menu-main.component'
import { MultiSearchComponent } from './multi-search/multi-search.component'
import { HeaderComponent } from './header/header.component';
import { HomeNewReleasesComponent } from './home-re/home-new-releases/home-new-releases.component';
import { HomeAnnouncementsComponent } from './home-re/home-announcements/home-announcements.component';
import { MainFooterComponent } from '../main-footer/main-footer.component';
import { fromPairs } from 'lodash';
import { UploadFailedListComponent } from './upload-failed-list/upload-failed-list.component';
import { AdsenseModule } from 'ng2-adsense';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
        
    // suppressScrollX: true
};

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        SharedIconsModule,
        InlineSVGModule.forRoot(),
        SharedModule,
        FormsModule,
        PerfectScrollbarModule,
        AdsenseModule.forRoot({
            // adClient: 'ca-pub-4776037543768273', 
            // adSlot: 9989671476,
           }),
    ],
    declarations: [
        MainFooterComponent,
        DashboardReComponent,
        HomeReComponent,
        MenuMainComponent,
        MultiSearchComponent,
        HeaderComponent,
        HomeNewReleasesComponent,
        HomeAnnouncementsComponent,
        UploadFailedListComponent,
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardReModule {
}
