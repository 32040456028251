<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icons/Mail" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="baseline-mic_off-24px" transform="translate(1.000000, 0.000000)" [attr.fill]="color" fill-rule="nonzero">
        <path
          d="M13.8333333,8.16666667 L12.4166667,8.16666667 C12.4166667,8.78333333 12.2833333,9.35833333 12.0583333,9.875 L13.0833333,10.9 C13.55,10.0833333 13.8333333,9.15833333 13.8333333,8.16666667 L13.8333333,8.16666667 Z M10.4833333,8.30833333 C10.4833333,8.25833333 10.5,8.21666667 10.5,8.16666667 L10.5,3.16666667 C10.5,1.78333333 9.38333333,0.666666667 8,0.666666667 C6.61666667,0.666666667 5.5,1.78333333 5.5,3.16666667 L5.5,3.31666667 L10.4833333,8.30833333 Z M1.55833333,1.5 L0.5,2.55833333 L5.50833333,7.56666667 L5.50833333,8.16666667 C5.50833333,9.55 6.61666667,10.6666667 8,10.6666667 C8.18333333,10.6666667 8.36666667,10.6416667 8.54166667,10.6 L9.925,11.9833333 C9.33333333,12.2583333 8.675,12.4166667 8,12.4166667 C5.7,12.4166667 3.58333333,10.6666667 3.58333333,8.16666667 L2.16666667,8.16666667 C2.16666667,11.0083333 4.43333333,13.3583333 7.16666667,13.7666667 L7.16666667,16.5 L8.83333333,16.5 L8.83333333,13.7666667 C9.59166667,13.6583333 10.3083333,13.3916667 10.95,13.0166667 L14.4416667,16.5 L15.5,15.4416667 L1.55833333,1.5 Z"
          id="Shape"></path>
      </g>
    </g>
  </svg>
</div>