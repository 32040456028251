import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ListenReRoutingModule }  from './listen-re-routing.module';

import { SharedModule } from '../shared';
import { SharedIconsModule } from '../shared/icons/icons.module';
import { ListenReComponent } from './listen-re.component';
import { ListenSingleReComponent } from './listen-single-re/listen-single-re.component';
import { ListenMoreComponent } from './listen-more/listen-more.component';
//import { ListenFeaturedSongsComponent } from './listen-featured-songs/listen-featured-songs.component';
import { ListenFeaturedRecordingsComponent } from './listen-featured-recordings/listen-featured-recordings.component';
import { ListenFavoriteRecordingsComponent } from './listen-favorite-recordings/listen-favorite-recordings.component';
import { ListenFavoriteMembersComponent } from './listen-favorite-members/listen-favorite-members.component';
import { ListenInnerCirclesComponent } from './listen-inner-circles/listen-inner-circles.component';
import { ListenRecentlyRecordedComponent } from './listen-recently-recorded/listen-recently-recorded.component';
import { ListenNewMemberRecordingsComponent } from './listen-new-member-recordings/listen-new-member-recordings.component';
import { ListenJamsComponent } from './listen-jams/listen-jams.component';
import { ListenDuetsComponent } from './listen-duets/listen-duets.component';
import { ListenOthersSingComponent } from './listen-others-sing/listen-others-sing.component';
import { AdsenseModule } from 'ng2-adsense';

@NgModule({
    imports: [
        NgbModule,
        InfiniteScrollModule,
        CommonModule,
        SharedModule,
        SharedIconsModule,
        RouterModule,
        ListenReRoutingModule,
        AdsenseModule.forRoot({
            // adClient: 'ca-pub-4776037543768273', 
            // adSlot: 9989671476,
           }),
    ],
    declarations: [
        ListenReComponent,
        ListenSingleReComponent,
        ListenMoreComponent,
        //ListenFeaturedSongsComponent,
        ListenFeaturedRecordingsComponent,
        ListenFavoriteRecordingsComponent,
        ListenFavoriteMembersComponent,
        ListenInnerCirclesComponent,
        ListenRecentlyRecordedComponent,
        ListenNewMemberRecordingsComponent,
        ListenJamsComponent,
        ListenDuetsComponent,
        ListenOthersSingComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ListenReModule {
}
