import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genreFormat'
})
export class GenreFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
        case 1:
            return 'Pop';
        case 2:
            return 'Alternative/Folk/Punk';
        case 3:
            return 'Acoustative';
        case 4:
            return 'Film/Stage/TV';
        case 5:
            return 'Rock';
        case 6:
            return 'R&B/Soul';
        case 7:
            return 'Dance/Electronic/Club';
        case 8:
            return 'Rap/Hip-Hop';
        case 9:
            return 'Country';
        case 10:
            return 'Holiday';
        case 11:
            return 'Latin';
        case 12:
            return 'Traditional/Classical';
        default:
            return 'No genre';
    }
  }

}
