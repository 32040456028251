<div class="search-bar-input">
    <form [formGroup]="group">
        <input #input
            formControlName="searchBarInput"
            type="text" placeholder="Search..."
            [(ngModel)]="query"
            (keyup)="debouncer.next($event)">
            <i class="fa fa-search" *ngIf="showSearchIcon"></i>
            <app-icon-close class="cancel" *ngIf="!showSearchIcon" (click)="clear()">
        </app-icon-close>
    </form>
</div>
