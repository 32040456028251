import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-genre-item',
  templateUrl: './genre-item.component.html',
  styleUrls: ['./genre-item.component.scss']
})
export class GenreItemComponent implements OnInit {
  @Input() genre: any;
  @Input() showSong = false;
  @Input() height = false;
  @Input() addHeight = false;
  addToUrl = '';

  constructor() { }

  ngOnInit() {
    if(this.addHeight){
      this.addToUrl = '?h='+this.height;
    }
  }

}
