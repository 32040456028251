import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-yes-no-inline',
  templateUrl: './yes-no-inline.component.html',
  styleUrls: ['./yes-no-inline.component.scss']
})
export class YesNoInlineComponent implements OnInit {
  @Input() yesno: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
