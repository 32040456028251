import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss']
})
export class SelectBoxComponent implements OnInit, OnChanges {

  @Input('options') options;
  @Input('defaultOption') defaultOption;
  @Input('label') label;
  @Input('valueProp') valueProp = 'name';
  @Output('onOptionChange') onOptionChange = new EventEmitter<any>();
  selectedOption;

  constructor() {
  }

  ngOnInit() {
  }

  onOptionChangeHandle($event) {
    this.onOptionChange.emit($event);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultOption'] && changes['defaultOption'].currentValue ) {
      this.selectedOption = this.defaultOption;
      this.onOptionChangeHandle(this.selectedOption);
    }
  }
}
