<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M0,2 C0,1.44771525 0.444946289,1 0.993397683,1 L17.0066023,1 C17.5552407,1 18,1.44386482 18,2 C18,2.55228475 17.5550537,3 17.0066023,3 L0.993397683,3 C0.444759292,3 0,2.55613518 0,2 Z M0,9 C0,8.44771525 0.444946289,8 0.993397683,8 L17.0066023,8 C17.5552407,8 18,8.44386482 18,9 C18,9.55228475 17.5550537,10 17.0066023,10 L0.993397683,10 C0.444759292,10 0,9.55613518 0,9 Z M0,16 C0,15.4477153 0.446311399,15 0.997544646,15 L8.00245535,15 C8.55338405,15 9,15.4438648 9,16 C9,16.5522847 8.5536886,17 8.00245535,17 L0.997544646,17 C0.446615951,17 0,16.5561352 0,16 Z"
        id="path-mobile-menu"></path>
    </defs>
    <g id="Icons/Menu" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask fill="white">
        <use xlink:href="#path-mobile-menu"></use>
      </mask>
      <use id="Combined-Shape" [attr.fill]="color" xlink:href="#path-mobile-menu"></use>
    </g>
  </svg>
</div>