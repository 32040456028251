<div *ngIf='uploading' id="uploadingContainer">
  <div id='uploadingBox'>
    <div *ngIf='uploadError == ""' class='uploadingSection'>
      <i id='uploadingSpinner' class="fa fa-spinner fa-pulse fa-fw"></i>
    </div>
    <div class='uploadingSection'>
      <h4 *ngIf='uploadError == ""'>Your recording is being uploaded.</h4>
      <h5 style='color: red; text-align: center; padding: 2rem;'*ngIf='uploadError != ""'>{{uploadError}}</h5>
      <div id="homeLink" *ngIf='uploadError != ""'>
        <a id="homeAnchorLink" [routerLink]='["/d/home"]'>
          <app-icon-home color="#D0D0D0"></app-icon-home>
          <h5>RETURN HOME</h5>
        </a>
      </div>

    </div>
  </div>
</div>
<div class="container">
  <h4 class="song-title">{{title}}</h4>
  <div class="ssc-wrapper">
    <div class="ssc-list-content">
      <div class="file-board">
        <div class="thumb-select">
          <div class="select-header">
            <h6>UPLOAD OR SELECT A THUMBNAIL</h6>
            <div class="thumb-page">
              <app-icon-angle-down size="18" (click)="onScrollTo(-400)" class="page-left"></app-icon-angle-down>
              <!-- <h6>1</h6><h6 class="total-page">/6</h6> -->
              <app-icon-angle-down size="18" (click)="onScrollTo(400)" class="page-right"></app-icon-angle-down>
            </div>
          </div>
          <div class="ss-thumbs" [perfectScrollbar]="config">
            <div [ngStyle]="{'width': (thumbs.length * 140) + 'px'}">
              <div class="file-upload" (click)="selectCustomThumb()">
                <div
                  class="file-preview"
                  [style.backgroundImage]="'url(' + imageSrc + ')'" *ngIf="imageSrc"
                  [ngClass]="{'active-thumb': imageSrc && customThumb}"
                >
                  <div class="thumb-selected">
                    <div class="thumb-selected-icon">
                      <app-icon-check color="white"></app-icon-check>
                    </div>
                  </div>
                </div>
                <div class="cancel-image" (click)="onCancelImage()" *ngIf="imageSrc">
                  <app-icon-cancel color="#F94678" size="24"></app-icon-cancel>
                </div>
                <div class="file-upload-inner" [ngClass]="{'has-error': error.noPoster}" *ngIf="!imageSrc">
                  <div class="upload-buttons">
                    <span class="plus-icon" (click)="onAddPosterFile()">
                      <app-icon-plus color="#58c7c9" size="18"></app-icon-plus>
                    </span>
                    <span class="plus-icon" (click)="takePhoto()">
                      <app-icon-camera color="#58c7c9" size="18"></app-icon-camera>
                    </span>
                  </div>
                  <h6>UPLOAD</h6>
                </div>
              </div>
              <div class="thumbnail" [ngClass]="{'active-thumb': selectedThumb && selectedThumb.id == thumb.id}" *ngFor="let thumb of thumbs" (click)="selectThumb(thumb)">
                <img [src]="thumb.uri + '?h=120'" />
                <div class="thumb-selected">
                  <div class="thumb-selected-icon">
                    <app-icon-check color="white"></app-icon-check>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="description-box">
          <div class="quill-box">
            <app-ss-editor
              [editorContent]="description"
              [state]="'close'"
              [showPostButton]="false"
              [hideToolbar]="true"
              [showSetting]="false"
              (editorContentChange)="editorContentChange($event)"
            >
            </app-ss-editor>
          </div>
          <!-- <label>Description</label>
          <textarea class="form-control" [(ngModel)]="description" rows="3"></textarea> -->
        </div>
        <div class="ss-custom-dropdown">
          <div class="type-header">
            <div class="ss-custom-input">
              <label>Tags</label>
              <div class="tags-holder">
                <div class="tags-list">
                  <app-tag *ngFor="let tag of tagsList; let e = index" color="gray" [tag]="tag" (remove)="removeTag(e)"></app-tag>
                </div>
                <div class="tags-input">
                  <input id="typeahead-tags" ng-trim="false" type="text" maxlength="22" class="form-control" [(ngModel)]="tags" [ngbTypeahead]="searchTags" (keydown)="onTagInput($event)" />
                  <button class="btn btn-primary btn-tag" (click)="addTag()">Add</button>
                </div>
                <div class="invalid-feedback" *ngIf="tagSearchFailed">Sorry, suggestions could not be loaded.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="ss-custom-dropdown privacy-items" (clickOutside)="privacyDropdown = false">
          <label>Privacy level</label>
          <div *ngFor="let p of privacyList; let e = index" class="privacy-choice" (click)="onChangePrivacy(e)" [ngClass]="{'active': e === privacy}">
            <div class='choice-label'>
              <app-icon-check color="white" size="10" *ngIf="e === privacy"></app-icon-check>
            </div>
            <span>{{p}}</span>
          </div>
        </div>
        <div class="ss-custom-dropdown" (clickOutside)="contestDropdown = false" *ngIf="id">
          <div class="type-header" (click)="contestDropdown = true">
            <div>
              <label>Contests</label>
              <span>Select Contest</span>
            </div>
            <app-icon-arrow-down size="24"></app-icon-arrow-down>
          </div>
          <div class="dropdown-menu" *ngIf="contestDropdown">
            <div class="dropdown-item" *ngFor="let c of validContests();" (click)="onSelectContest(c)">
              <p>{{c.name}}</p>
            </div>
          </div>
          <div class="ss-selected-items">
            <app-tag *ngFor="let c of selectedContests" color="purple" [tag]="c.name" (remove)="removeContest(c.id)"></app-tag>
          </div>
          <div class="ss-custom-dropdown-disabled" *ngIf="privacy === 2"></div>
        </div>
        <div class="ss-custom-dropdown" (clickOutside)="icDropdown = false">
          <div class="type-header" (click)="icDropdown = true">
            <div>
              <label>Inner Circles</label>
              <span>Select Inner Circle</span>
            </div>
            <app-icon-arrow-down size="24"></app-icon-arrow-down>
          </div>
          <div class="dropdown-menu" *ngIf="icDropdown">
            <div class="dropdown-item" *ngFor="let ic of validICs();" (click)="onSelectIC(ic.id)">
              <p>{{ic.title}}</p>
            </div>
          </div>
          <div class="ss-selected-items">
            <app-tag *ngFor="let ic of selectedICs" [tag]="ic.title" (remove)="removeIC(ic.id)"></app-tag>
          </div>
        </div>
      </div>
      <div class="ssc-wrapper-footer">
        <span class="privacy-explanation">{{privacyExplanation}}</span>
        <div class="ctas">
          <button class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray"
            (click)="onCancelUpload()">CANCEL</button>

          <button class="btn btn-primary" (click)="queue()" [disabled]="uploading" *ngIf="!id && !failedRecording">
            QUEUE FOR LATER
          </button>
          <button class="btn btn-primary" (click)="upload()" [disabled]="uploading" *ngIf="!id">
            <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="uploading"></i>
            SAVE RECORDING
          </button>
          <button class="btn btn-primary" (click)="update()" [disabled]="uploading" *ngIf="id">
            <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="updating"></i>
            UPDATE RECORDING
          </button>
        </div>
      </div>
    </div>
    <div class="ssc-sidebar">
      <div class="sing-options">
        <div class="ss-option">
          <span class="ss-option-label" [ngClass]="{'ss-disabled': !allowLoves}">Allow Loves</span>
          <label class="ss-custom-switch">
            <input type="checkbox" [(ngModel)]="allowLoves">
            <span class="ss-slider"></span>
          </label>
        </div>
        <div class="ss-option">
          <span class="ss-option-label" [ngClass]="{'ss-disabled': !allowComments}">Allow Comments</span>
          <label class="ss-custom-switch">
            <input type="checkbox" [(ngModel)]="allowComments">
            <span class="ss-slider"></span>
          </label>
        </div>
        <div class="ss-option">
          <span class="ss-option-label" [ngClass]="{'ss-disabled': !trackViews}">Track Views</span>
          <label class="ss-custom-switch">
            <input type="checkbox" [(ngModel)]="trackViews">
            <span class="ss-slider"></span>
          </label>
        </div>
        <div class="ss-option">
          <span class="ss-option-label" [ngClass]="{'ss-disabled': !socialNetwork}">Social Network</span>
          <label class="ss-custom-switch">
            <input type="checkbox" [(ngModel)]="socialNetwork">
            <span class="ss-slider"></span>
          </label>
        </div>
        <div class="ss-option">
          <span class="ss-option-label" [ngClass]="{'ss-disabled': !allowDuets}">Allow Open Duets & Jams</span>
          <label class="ss-custom-switch">
            <input type="checkbox" [(ngModel)]="allowDuets" (change)="onChangeDuet()">
            <span class="ss-slider"></span>
          </label>
        </div>
        <div class="ss-option">
          <span class="ss-option-label" [ngClass]="{'ss-disabled': !showMyDuet || !allowDuets}">Show On My Open Duets & Jams</span>
          <label class="ss-custom-switch">
            <input type="checkbox" [(ngModel)]="showMyDuet" [disabled]="privacy === 2 || !allowDuets">
            <span class="ss-slider"></span>
          </label>
        </div>
        <div class="ss-option">
          <span class="ss-option-label" [ngClass]="{'ss-disabled': !offensiveContent}">Contains Offensive Content</span>
          <label class="ss-custom-switch">
            <input type="checkbox" [(ngModel)]="offensiveContent">
            <span class="ss-slider"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <input type="file" accept="image/*" (change)="onPosterFileChange($event)" #posterRef />
  <app-ss-webcam-photo #photo (closed)="onPhotoTaken($event)"></app-ss-webcam-photo>
  <app-notification-modal #notification (closed)="onConfirmClosed()"></app-notification-modal>
  <div *ngIf="uploading" class="upload-overlay"></div>
</div>