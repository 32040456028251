<div 
  class="ss-user-photo"
  [style.width]="size + 'px'"
  [style.height]="size + 'px'"
>
  <div class="ss-user-photo-actual" [style.backgroundImage]="'url(' + user.photo + ')'"></div>
  <div class="snapicon-carousel">
    <app-ss-snapicon-carousel [size]="iconsize" *ngIf="snapicons.length">
      <ng-container *ngFor="let icon of snapicons">
        <ng-container *carouselItem>
          <img [src]="icon" />
        </ng-container>
      </ng-container>
    </app-ss-snapicon-carousel>
  </div>
  <app-user-online-icon [online]="user.online" size="xs-3" ></app-user-online-icon> 
</div>
