<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M4,0.993397683 C4,0.444759292 4.44386482,0 5,0 C5.55228475,0 6,0.444946289 6,0.993397683 L6,17.0066023 C6,17.5552407 5.55613518,18 5,18 C4.44771525,18 4,17.5550537 4,17.0066023 L4,0.993397683 Z M12,0.993397683 C12,0.444759292 12.4438648,0 13,0 C13.5522847,0 14,0.444946289 14,0.993397683 L14,17.0066023 C14,17.5552407 13.5561352,18 13,18 C12.4477153,18 12,17.5550537 12,17.0066023 L12,0.993397683 Z"
        id="path-icon-pause"></path>
    </defs>
    <g id="Icons/Pause" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlink:href="#path-icon-pause"></use>
      </mask>
      <use id="Combined-Shape" [attr.fill]="color" xlink:href="#path-icon-pause"></use>
    </g>
  </svg>
</div>