<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M10.0067163,14.5790701 C9.61248272,14.9733037 9.60675304,15.606753 10,16 L10,16 C10.3905243,16.3905243 11.0187801,16.3954335 11.4183583,15.9958552 L17.702962,9.71125155 C18.0957754,9.31843817 18.1025403,8.68832672 17.702962,8.28874845 L11.4183583,2.00414477 C11.0255449,1.61133139 10.393247,1.60675304 10,2 L10,2 C9.60947571,2.39052429 9.608137,3.02235056 10.0067163,3.42092991 L14.5857864,8 L1.00254017,8 C0.448852524,8 0,8.44386482 0,9 L0,9 C0,9.55228475 0.452517829,10 1.00254017,10 L14.5857864,10 L10.0067163,14.5790701 Z"
        id="path-arrow-right"></path>
    </defs>
    <g id="ic_arrow_right" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlink:href="#path-arrow-right"></use>
      </mask>
      <use id="Combined-Shape" [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-arrow-right"></use>
    </g>
  </svg>
</div>