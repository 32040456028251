import { Component, Input, AfterViewInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, } from '@angular/core';
//import { NguCarouselConfig, NguCarousel, NguCarouselStore } from '@ngu/carousel';
import { NgxGlideComponent } from 'ngx-glide';

@Component({
    selector: 'app-recommended-carousel',
    templateUrl: './recommended-carousel.component.html',
    styleUrls: ['./recommended-carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecommendedCarouselComponent implements AfterViewInit {
    @Input() title = 'Recommended for you';
    @Input() items = [];
    @Input() amount = 2;

    @Input() number: number = 8;
    @ViewChild(NgxGlideComponent, { static: false }) ngxGlide: NgxGlideComponent;

    //@ViewChild('carousel', {static: false}) carousel: NguCarousel<any>;
    @Input() showStats = false;
    totalItemPage = 0;
    carouselToken;
    // public carouselTileItems: Array<any> = [];
    // public carouselTile: NguCarouselConfig = {
    //     grid: { xs: this.number - 3, sm: this.number - 2 , md: this.number - 1, lg: this.number, xl: this.number, all: 0 },
    //     gridBreakpoints: {sm: 1240, md: 1685, lg: 1800, xl: 1800},
    //     speed: 600,
    //     point: {
    //         visible: true,
        
    //     },
    //     load: 2,
    //     touch: false
    // };
  
    constructor(private cdr: ChangeDetectorRef) {
    }
  
    
    ngAfterViewInit() {
      

    // if(this.number != 8){
    //     this.carouselTile.grid.xs = this.number
    //     this.carouselTile.grid.sm = 2
    //     this.carouselTile.grid.md = 2
    //     this.carouselTile.grid.lg = this.number
    //     this.carouselTile.grid.xl = this.number
    // }
      this.totalItemPage = this.items.length;
      this.detectChanges()
    //   this.carouselTileLoad();
  //   this.totalItemPage = Math.round(this.items.length / 2)
   setTimeout(()=>{
    console.log(this.ngxGlide)
  }, 200) 
  //    this.cdr.markForCheck()
  //    this.cdr.detectChanges()
    }
    getIndex() {
      if(typeof this.ngxGlide != 'undefined') {
        return this.ngxGlide.getIndex()
      } else {
        return 1
      }
    }
    public carouselTileLoad() {
      // // console.log(this.carouselTiles[j]);
      // const len = this.items.length
      // for (let i = 0; i < len; i++) {
      //   this.carouselTileItems.push(
      //     this.items[i]
      //   );
      // }
      // this.cdr.detectChanges();
      
    }
    // initDataFn(key: NguCarouselStore) {
    //   this.carouselToken = key.token;
    // }
    // afterCarouselViewedFn(data) {
    // }
    // resetFn() {
    //   this.carousel.reset(this.carouselToken);
    // }
  
    toPrevSlide() {
      this.ngxGlide.go('<')
    }
  
    toNextSlide() {
      this.ngxGlide.go('>')
    }
    detectChanges(){
      this.cdr.markForCheck()
      this.cdr.detectChanges()
    }
  }
  