import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'textSlice'
})
export class TextSlicePipe implements PipeTransform {

    transform(value: String, length: number): String {
        return value.slice(0, length) + ' [...]';
    }

}
