<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
        id="path-blog"></path>
    </defs>
    <g id="ic_blog" stroke="none" stroke-linejoin="round" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlink:href="#path-blog"></use>
      </mask>
      <use id="Combined-Shape" [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-blog"></use>
    </g>
  </svg>
</div>
