<div id='seeMoreContainer'>
    <div class='headerContainer'>
      <div class='title'>
        <app-icon-button color="white" (click)="back()" >
          <app-icon-arrow-left color="gray"></app-icon-arrow-left>
        </app-icon-button>
        <h4>{{pageTitle}}</h4>
      </div>
    </div>
  
    <ng-container *ngIf="showSeeMorePageType === 'fav-mem'">
      <app-ss-featured-grid 
        [scrollable]="false"
        title="{{pageTitle}}"
        [cols]="3"
        [colsLg]="3"
        [colsMd]="2"
        [colsSm]="1"
        [extendedSize] = "true"
        [hasSeeMore] = "false"
        [sortOptions]="memberSortOptions"
        [list]="members.items"
        [loading]="members.loading"
        (onOrderBy)="resetDataObj(members);onScrollEndHandle(members, $event)"
      >
        <ng-template #itemContent let-item>
          <div class="ssc-list-item" class="ss-list--item {{ customClass }}" >
            <!-- <a (click)="navProfile(item.id)"> -->
              <app-ss-user (click)="navProfile(item.id)" [user]="item"></app-ss-user>
            <!-- </a> -->
            <div class="song-actions">
              <!-- <a (click)="navProfile(item.id)"> -->
                <app-icon-button (click)="navProfile(item.id)" size="40px" color="white">
                  <app-icon-arrow-right></app-icon-arrow-right>
                </app-icon-button>
              <!-- </a> -->
              <app-more-dot-menu>
                <div class="dropdown-menu-content">
                  <div (click)="unfavoriteMember(item)">Remove from Favorites</div>
                  <div  (click)="navMessage( item?.id)" >Message User</div>
                </div>
              </app-more-dot-menu>
            </div>
          </div>
        </ng-template>
      </app-ss-featured-grid>
      <div  id="pageNavContainer"  style="margin-bottom: 13px;">
        <button *ngIf='members.currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
        <p >{{currentPage}}</p>
        <button *ngIf='members.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
      </div>
    </ng-container>
  
    <ng-container *ngIf="showSeeMorePageType === 'profile-fav-mem' ">
      <app-ss-featured-grid 
        [scrollable]="false"
        title="{{pageHeader}}"
        [cols]="3"
        [colsLg]="3"
        [colsMd]="2"
        [colsSm]="1"
        [extendedSize] = "true"
        [hasSeeMore] = "false"
        [sortOptions]="memberSortOptions"
        [list]="profileFavMember.items"
        [loading]="profileFavMember.loading"
        (onOrderBy)="resetDataObj(profileFavMember);onScrollEndHandleProfileFavsMember(profileFavMember, $event)"
      >
        <ng-template #itemContent let-item>
          <div class="ssc-list-item" class="ss-list--item {{ customClass }}" >
              <app-ss-user (click)="navProfile(item.id)" [user]="item"></app-ss-user>
            <div class="song-actions">
                <app-icon-button (click)="navProfile(item.id)" size="40px" color="white" border="#909090">
                  <app-icon-arrow-right></app-icon-arrow-right>
                </app-icon-button>
              <app-more-dot-menu>
                <div class="dropdown-menu-content">
                  <div (click)="unfavoriteMember(item)">Remove from Favorites</div>
                  <div  (click)="navMessage( item?.id)" >Message User</div>
                </div>
              </app-more-dot-menu>
            </div>
          </div>
        </ng-template>
      </app-ss-featured-grid>
      <div  id="pageNavContainer"  style="margin-bottom: 13px;">
        <button *ngIf='profileFavMember.currentPage != 1' (click)="getPrevSongsProfileFavsMember()">&lt; PREV</button>
        <p >{{currentPage}}</p>
        <button *ngIf='profileFavMember.lastPage' (click)="getNextSongsProfileFavsMember()">NEXT &gt;</button>
      </div>
    </ng-container>




    <ng-container  *ngIf="showSeeMorePageType === 'fav-rec'">
      <app-ss-featured-grid 
        [scrollable]="false"
        title="{{pageTitle}}"
        [hasSeeMore] = "false"
        [cols]="3"
        [colsLg]="3"
        [colsMd]="2"
        [colsSm]="1"
        [extendedSize] = "true"
        [checkOwnRecording]="true"
        [userId]="profileService.userId"
        [sortOptions]="playlistSortOptions"
        [list]="favoriteData.items" 
        [loading]="favoriteData.loading"
        (onOrderBy)="resetDataObj(favoriteData);onScrollEndHandle(favoriteData, $event)">
        <ng-template #itemContent let-item>
            <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
            <app-ss-recording  [linkActive]="false"(click)="navListen(item?.id)" [recording]="item"></app-ss-recording>
            <div class="song-actions">
                <app-more-dot-menu title="Options">
                <div class="dropdown-menu-content">
                    <div *ngIf="!item.favorited" (click)="favRecording(item)">Add Recording to Favorites</div>
                    <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Recording from Favorites</div>
                    <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                    <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                    <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                    <div (click)="navProfile(item.user_id)">Go to Profile Page</div>
                    <!-- <div (click)="handleDuet(item)">Duet / Jam</div> -->
                    <div *ngIf="profileService.userId == item.user_id" (click)="navEditRec(item?.id)">Edit Recording Info</div>
                    <!-- <div (click)="shareRecording(item)">Share this recording</div> -->
                    <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                </div>
                </app-more-dot-menu>
            </div>
            </div>
            <app-recording-stat (click)="navListen(item?.id)" [recording]="item"></app-recording-stat>
        </ng-template>
      </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
        <app-add-to-playlist #playlist></app-add-to-playlist>
        <div  id="pageNavContainer"  style="margin-bottom: 13px;">
          <button *ngIf='favoriteData.currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
          <p >{{currentPage}}</p>
          <button *ngIf='favoriteData.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
        </div>
    </ng-container >


    <ng-container  *ngIf="showSeeMorePageType === 'profile-fav-rec'">
      <app-ss-featured-grid 
        [scrollable]="false"
        title="{{pageHeader}}"
        [hasSeeMore] = "false"
        [cols]="3"
        [colsLg]="3"
        [colsMd]="2"
        [colsSm]="1"
        [extendedSize] = "true"
        [checkOwnRecording]="true"
        [userId]="profileService.userId"
        [sortOptions]="playlistSortOptions"
        [list]="profileFavRec.items" 
        [loading]="profileFavRec.loading"
        (onOrderBy)="resetDataObj(profileFavRec);onScrollEndHandleProfileFavs(profileFavRec, $event)">
        <ng-template #itemContent let-item>
            <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
            <app-ss-recording  [linkActive]="false"(click)="navListen(item?.id)" [recording]="item"></app-ss-recording>
            <div class="song-actions">
                <app-more-dot-menu title="Options">
                <div class="dropdown-menu-content">
                    <div *ngIf="!item.favorited" (click)="favRecording(item)">Add Recording to Favorites</div>
                    <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Recording from Favorites</div>
                    <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                    <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                    <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                    <div (click)="navProfile(item.user_id)">Go to Profile Page</div>
                    <!-- <div (click)="handleDuet(item)">Duet / Jam</div> -->
                    <div *ngIf="profileService.userId == item.user_id" (click)="navEditRec(item?.id)">Edit Recording Info</div>
                    <!-- <div (click)="shareRecording(item)">Share this recording</div> -->
                    <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                </div>
                </app-more-dot-menu>
            </div>
            </div>
            <app-recording-stat (click)="navListen(item?.id)" [recording]="item"></app-recording-stat>
        </ng-template>
      </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
        <app-add-to-playlist #playlist></app-add-to-playlist>
        <div  id="pageNavContainer"  style="margin-bottom: 13px;">
          <button *ngIf='profileFavRec.currentPage != 1' (click)="getPrevSongsProfileFavs()">&lt; PREV</button>
          <p >{{currentPage}}</p>
          <button *ngIf='profileFavRec.lastPage' (click)="getNextSongsProfileFavs()">NEXT &gt;</button>
        </div>
    </ng-container >





    <ng-container  *ngIf="showSeeMorePageType === 'fav-pl' || showSeeMorePageType === 'pl-fav' ">
        <app-ss-featured-grid 
            title="{{pageTitle}}"
            [scrollable]="false"
            [cols]="3"
            [colsLg]="3"
            [colsMd]="2"
            [colsSm]="1"
            [extendedSize] = "true"
            [hasSeeMore]="false"
            [sortOptions]="playlistSortOptions"
            [list]="playlist.items"
            [loading]="playlist.loading"
            (onOrderBy)="resetDataObj(playlist);onScrollEndHandle(playlist, $event)">
            <ng-template #itemContent let-item>
            <div class="ssc-list-item">
                <app-ss-playlist (click)="navPlay( item.id)" [playlist]="item"></app-ss-playlist>
                <div class="ssc-list-actions">
                  <app-icon-button (click)="navPlay( item.id)" size="40px" color="#58c7c9">
                    <app-icon-play color="white"></app-icon-play>
                  </app-icon-button>
                <app-more-dot-menu>
                    <div class="dropdown-menu-content">
                    <div (click)="unfavoritePlaylist(item)">Remove from Favorites</div>
                    </div>
                </app-more-dot-menu>
                </div>
            </div>
            </ng-template>
        </app-ss-featured-grid>
        <div  id="pageNavContainer"  style="margin-bottom: 13px;">
          <button *ngIf='playlist.currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
          <p >{{currentPage}}</p>
          <button *ngIf='playlist.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
        </div>
    </ng-container >


    <ng-container  *ngIf="showSeeMorePageType === 'profile-pl'">
      <app-ss-featured-grid 
          title="{{pageTitle}}"
          [scrollable]="false"
          [cols]="3"
          [colsLg]="3"
          [colsMd]="2"
          [colsSm]="1"
          [extendedSize] = "true"
          [hasSeeMore]="false"
          [sortOptions]="playlistSortOptions"
          [list]="profilePlaylist.items"
          [loading]="profilePlaylist.loading"
          (onOrderBy)="resetDataObj(playlist);onScrollEndHandleProfilePlaylists(profilePlaylist, $event)">
          <ng-template #itemContent let-item>
          <div class="ssc-list-item">
              <app-ss-playlist (click)="onPlayPlaylist(item)" [playlist]="item"></app-ss-playlist>
              <div class="ssc-list-actions">
                <app-icon-button (click)="onPlayPlaylist(item)" size="40px" color="#58c7c9">
                  <app-icon-play color="white"></app-icon-play>
                </app-icon-button>
              <app-more-dot-menu>
                  <div class="dropdown-menu-content">
                  <div (click)="unfavoritePlaylist(item)">Remove from Favorites</div>
                  </div>
              </app-more-dot-menu>
              </div>
          </div>
          </ng-template>
      </app-ss-featured-grid>
      <div  id="pageNavContainer"  style="margin-bottom: 13px;">
        <button *ngIf='profilePlaylist.currentPage != 1' (click)="getPrevSongsProfilePlaylists()">&lt; PREV</button>
        <p >{{currentPage}}</p>
        <button *ngIf='profilePlaylist.lastPage' (click)="getNextSongsProfilePlaylists()">NEXT &gt;</button>
      </div>
  </ng-container >


    <ng-container  *ngIf="showSeeMorePageType === 'profile-fav-pl'">
      <app-ss-featured-grid 
          title="{{pageHeader}}"
          [scrollable]="false"
          [cols]="3"
          [colsLg]="3"
          [colsMd]="2"
          [colsSm]="1"
          [extendedSize] = "true"
          [hasSeeMore]="false"
          [sortOptions]="playlistSortOptions"
          [list]="profileFavPlaylist.items"
          [loading]="profileFavPlaylist.loading"
          (onOrderBy)="resetDataObj(profileFavPlaylist);onScrollEndHandleProfileFavs(profileFavPlaylist, $event)">
          <ng-template #itemContent let-item>
          <div class="ssc-list-item">
              <app-ss-playlist (click)="onPlayPlaylist(item)" [playlist]="item"></app-ss-playlist>
              <div class="ssc-list-actions">
                <app-icon-button (click)="onPlayPlaylist(item)"size="40px" color="#58c7c9">
                  <app-icon-play color="white"></app-icon-play>
                </app-icon-button>
              <app-more-dot-menu>
                  <div class="dropdown-menu-content">
                  <div (click)="unfavoritePlaylist(item)">Remove from Favorites</div>
                  </div>
              </app-more-dot-menu>
              </div>
          </div>
          </ng-template>
      </app-ss-featured-grid>
      <div  id="pageNavContainer"  style="margin-bottom: 13px;">
        <button *ngIf='profileFavPlaylist.currentPage != 1' (click)="getPrevSongsProfileFavs()">&lt; PREV</button>
        <p >{{currentPage}}</p>
        <button *ngIf='profileFavPlaylist.lastPage' (click)="getNextSongsProfileFavs()">NEXT &gt;</button>
      </div>
  </ng-container >



    <ng-container  *ngIf="showSeeMorePageType === 'fav-artist'">
        <app-ss-featured-grid 
            title="{{pageTitle}}"
            [scrollable]="false"
            [cols]="3"
            [colsLg]="3"
            [colsMd]="2"
            [colsSm]="1"
            [extendedSize] = "true"
            [hasSeeMore]="false"
            [sortOptions]="artistSortOptions"
            [list]="artists.items"
            [loading]="artists.loading"
            (onOrderBy)="resetDataObj(artists);onScrollEndHandle(artists, $event)"
            >
            <ng-template #itemContent let-item>
            <div class="ssc-list-item">
              <app-ss-artist (click)="navArtists( item.id)" [artist]="item"></app-ss-artist>
                <div class="song-actions">
                  <app-icon-button (click)="navArtists( item.id)" size="40px" color="white" border="#909090">
                    <app-icon-arrow-right></app-icon-arrow-right>
                  </app-icon-button>
                <app-more-dot-menu>
                    <div class="dropdown-menu-content">
                    <div (click)="unfavoriteArtist(item)">Remove from Favorites</div>
                    </div>
                </app-more-dot-menu>
                </div>
            </div>
            </ng-template>
        </app-ss-featured-grid>
        <div  id="pageNavContainer"  style="margin-bottom: 13px;">
          <button *ngIf='artists.currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
          <p >{{currentPage}}</p>
          <button *ngIf='artists.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
        </div>
    </ng-container >


    <ng-container  *ngIf="showSeeMorePageType === 'profile-fav-artist'">
      <app-ss-featured-grid 
          title="{{pageHeader}}"
          [scrollable]="false"
          [cols]="3"
          [colsLg]="3"
          [colsMd]="2"
          [colsSm]="1"
          [extendedSize] = "true"
          [hasSeeMore]="false"
          [sortOptions]="artistSortOptions"
          [list]="profileFavArtist.items"
          [loading]="profileFavArtist.loading"
          (onOrderBy)="resetDataObj(profileFavArtist);onScrollEndHandleProfileFavs(profileFavArtist, $event)"
          >
          <ng-template #itemContent let-item>
          <div class="ssc-list-item">
              <a (click)="navArtists( item.id)">
              <app-ss-artist [artist]="item"></app-ss-artist>
              </a>
              <div class="song-actions">
                <app-icon-button (click)="navArtists( item.id)" size="40px" color="white" border="#909090">
                  <app-icon-arrow-right></app-icon-arrow-right>
                </app-icon-button>
              <app-more-dot-menu>
                  <div class="dropdown-menu-content">
                  <div (click)="unfavoriteArtist(item)">Remove from Favorites</div>
                  </div>
              </app-more-dot-menu>
              </div>
          </div>
          </ng-template>
      </app-ss-featured-grid>
      <div  id="pageNavContainer"  style="margin-bottom: 13px;">
        <button *ngIf='profileFavArtist.currentPage != 1' (click)="getPrevSongsProfileFavs()">&lt; PREV</button>
        <p >{{currentPage}}</p>
        <button *ngIf='profileFavArtist.lastPage' (click)="getNextSongsProfileFavs()">NEXT &gt;</button>
      </div>
    </ng-container >


    <ng-container *ngIf="showSeeMorePageType === 'fans' ">
      <div class="ssc-search-holder">
        <app-search-bar-input (onSearch)="onSearch($event)"></app-search-bar-input>
      </div>
      <app-ss-featured-grid 
        [scrollable]="false"
        title="{{pageHeader}}"
        skeletonType = "fansSeeMore"
        [cols]="3"
        [colsLg]="3"
        [colsMd]="2"
        [colsSm]="1"
        [extendedSize] = "true"
        [hasSeeMore] = "false"
        [sortOptions]="memberSortOptions"
        [list]="fans.items"
        [loading]="fans.loading"
        (onOrderBy)="resetDataObj(fans);onScrollEndHandleProfileFans(fans, $event)"
      >
        <ng-template #itemContent let-item>
          <div class="ssc-list-item" class="ss-list--item {{ customClass }}" >
              <app-ss-user (click)="navProfile(item.id)" [user]="item"></app-ss-user>
            <div class="song-actions">
                <!-- <app-icon-button (click)="navProfile(item.id)" size="40px" color="white" border="#909090">
                  <app-icon-arrow-right></app-icon-arrow-right>
                </app-icon-button> -->
              <app-more-dot-menu>
                <div class="dropdown-menu-content">
                  <div (click)="favoriteMember(item)"  *ngIf="!item.favorited">Add to 'My Favorite Members'</div>
                  <div (click)="unfavoriteMember(item)" *ngIf="item.favorited">Remove from 'My Favorite Members'</div>
                  <div  (click)="navMessage( item?.id)" >Message User</div>
                  <div (click)="navProfile(item?.id)">Go to Profile Page</div>
                </div>
              </app-more-dot-menu>
            </div>
          </div>
        </ng-template>
      </app-ss-featured-grid>
      <div  id="pageNavContainer"  style="margin-bottom: 13px;">
        <button *ngIf='fans.currentPage != 1' (click)="getPrevSongsProfileFans()">&lt; PREV</button>
        <p >{{currentPage}}</p>
        <button *ngIf='fans.lastPage' (click)="getNextSongsProfileFans()">NEXT &gt;</button>
      </div>
    </ng-container>


    <ng-container  *ngIf="showSeeMorePageType === 'pl-public' || showSeeMorePageType === 'pl-private' ">
      <app-ss-featured-grid 
          title="{{pageTitle}}"
          skeletonType = "playlistSeeMore"
          [scrollable]="false"
          [cols]="3"
          [colsLg]="3"
          [colsMd]="2"
          [colsSm]="1"
          [extendedSize] = "true"
          [hasSeeMore]="false"
          [sortOptions]="playlistSortOptions"
          [list]="playlistPublic.items"
          [loading]="playlistPublic.loading"
          (onOrderBy)="resetDataObj(playlistPublic);onScrollEndHandlePlaylistPublic(playlistPublic, $event)">
          <ng-template #itemContent let-item>
          <div class="ssc-list-item">
              <app-ss-playlist (click)="onPlayPlaylist(item)" [playlist]="item"></app-ss-playlist>
              <div class="ssc-list-actions">
                <app-icon-button (click)="onPlayPlaylist(item)" size="40px" color="#58c7c9">
                  <app-icon-play color="white"></app-icon-play>
                </app-icon-button>
              <app-more-dot-menu>
                  <div class="dropdown-menu-content">
                  <div (click)="unfavoritePlaylist(item)">Remove from Favorites</div>
                  </div>
              </app-more-dot-menu>
              </div>
          </div>
          </ng-template>
      </app-ss-featured-grid>
      <div  id="pageNavContainer"  style="margin-bottom: 13px;">
        <button *ngIf='playlistPublic.currentPage != 1' (click)="getPrevSongsPlaylistPublic()">&lt; PREV</button>
        <p >{{currentPage}}</p>
        <button *ngIf='playlistPublic.lastPage' (click)="getNextSongsPlaylistPublic()">NEXT &gt;</button>
      </div>
  </ng-container >


  </div>