import {Component, OnInit} from '@angular/core';
import {PlaylistService, ProfileService, RecordingService, Data} from '@app/core';
import {ActivatedRoute} from '@angular/router';
import { forkJoin } from "rxjs";

@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
    user;
    infoItems = [];
    fans = [];
    userRecordings$;
    userId;
    recordings;
    about = '';
    loadingFans = false;

    constructor(private recordingService: RecordingService,
                private profileService: ProfileService,
                private playlistService: PlaylistService,
                private activatedRoute: ActivatedRoute,
                private dataService: Data) {
        this.activatedRoute.parent.params.subscribe(params => {
            this.user = null;
            this.infoItems = [];
            this.fans = [];
            this.userRecordings$ = null;
            this.userId = null;
            this.recordings = null;
            this.about = '';
            this.userId = params.id;
            this.onLoad();
        });

    }
    onLoad(){this.loadingFans = true;
        this.userRecordings$ = this.recordingService.getUsersRecordings(this.userId, 1, 10);
        this.profileService.getUser(this.userId).subscribe( user => {
            this.user = user;
            this.dataService.profileUserName = user.screen_name; //get screen name to display for SeeMore fans list
            if (parseInt(user.stats.recordings) <= 0) {
                user.stats.recordings = '0'
            }
            this.infoItems = [
                {
                    text: 'MEMBER SINCE',
                    secondaryText: this.formatJSDate(user.created_at)
                },              
                {
                    text: 'FANS',
                    secondaryText: user.stats.fans
                },
                {
                    text: 'RECORDINGS',
                    secondaryText: user.stats.recordings
                },
                {
                    text: 'PLAYLIST',
                    secondaryText: user.stats.playlists
                },
                {
                    text: 'OPEN DUETS',
                    secondaryText: user.stats.open_duets
                },
                {
                    text: 'BADGES',
                    secondaryText: user.stats.badges
                }
            ];

            //console.log(this.infoItems);
            
            this.profileService.getUserFans(this.userId).subscribe( response => {
                this.loadingFans = false;
                this.fans = response.data;
            });
            
        });

    }
    ngOnInit() {

    }


    formatJSDate(d) {
        d = new Date(d)
        let year = d.getFullYear()
        let month = this.getMonthName(d.getMonth())
        let day = d.getDate();
        return `${month} ${day}, ${year}`
      }

      getMonthName(i) {
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return months[i];
      }


}
