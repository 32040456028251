<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header align-items-center">
    <h4 class="modal-title" id="modal-basic-title">NEW PLAYLIST</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
      <span aria-hidden="true" [inlineSVG]="'/assets/svgs/close.svg'"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body-content">
      <div class="input-group">
        <input class="form-control" placeholder="Title" [(ngModel)]="title" maxlength="50" />
      </div>
      <div class="input-group">
        <textarea class="form-control" placeholder="Description" [(ngModel)]="description" maxlength="255"></textarea>
      </div>
      <div class="input-group">
        <div class="ss-switch-holder">
          <span class="ss-label" [ngClass]="{'ss-disabled': !isPrivate}">Private</span>
          <label class="ss-custom-switch">
            <input type="checkbox" [(ngModel)]="isPrivate" [checked]="isPrivate" />
            <span class="ss-slider"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="d('Save click')">SAVE</button>
  </div>
</ng-template>
