import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SplashModalComponent} from '@app/shared/splash-modal/splash-modal.component';
import {ContestService, RecordingService, UsersService} from '@app/core';
import {ToastrService} from 'ngx-toastr';
import {Contest} from '@app/models/contest.model';
import Paginator from '@app/contests/paginator';
import {Recording} from '@app/models/recording.model';
import {SearchBarInputComponent} from '@app/shared/search-bar-input/search-bar-input.component';

@Component({
  selector: 'app-manage-cohost',
  templateUrl: './manage-cohost.component.html',
  styleUrls: ['./manage-cohost.component.scss']
})
export class ManageCohostComponent implements OnInit {
  modalRef: NgbModalRef;
  id;
  contest: Contest;
  paginator: Paginator;
  auditionRecording: Recording = null;
  term = '';
  @ViewChild('content', { static: true }) content;
  @ViewChild('splashModal', { static: true }) splashModal: SplashModalComponent;
  @ViewChild('searchInput') searchInput: SearchBarInputComponent;

  constructor(private contests: ContestService,
              private users: UsersService,
              private modalService: NgbModal,
              private recordings: RecordingService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
  }

  open(contest: Contest) {
    this.contest = contest;
    this.id = contest.contest_id;
    this.modalRef = this.modalService.open(this.content, {windowClass: 'add-member-modal'});
    this.modalRef.result.then(() => {
      this.paginator = null;
      this.searchInput.clear();
    }, () => {
      this.paginator = null;
      this.searchInput.clear();
    });
  }

  getRecordings() {
    this.paginator = new Paginator({
      page: 0,
      perPage: 15,
      isInfiniteScroll: true,
      nextPage: true,
      get: (page) => {
        return this.users.getUsers(this.term, page, 15);
      },
      handleSuccess: (response) => {
        this.paginator.items = this.paginator.items.concat(response.data);
        this.paginator.total = response.total || 30;
        this.paginator.page = response.current_page;
        this.paginator.nextPage = response.next_page_url;
      },
      handleError: (error) => {
        console.log('error', error);
      },
      items: []
    });

    this.paginator.request(0);
  }

  close() {
    this.modalRef.close('cancel');
  }

  onSearchHandle(term) {
    console.log(term);
    this.term = term;
    this.getRecordings();
  }

  selectRecording(recording) {
    console.log(recording);
    recording.selected = !recording.selected;
    if (recording.selected) {
      this.clearRecordings();
      recording.selected = true;
      this.auditionRecording = recording;
    }
  }

  clearRecordings() {
    this.paginator.items = this.paginator.items.map(item => {
      item.selected = false;
      return item;
    });
  }

  updateCoHost() {
    this.contests.addCohost(this.contest.contest_id, this.auditionRecording.id).subscribe(response => {
      if (response.success) {
        this.toastr.success('Co-Host', 'Co-Host updated.');
      } else {
        this.toastr.error('Co-Host', 'There was an error trying to update Co-Host.');
      }
      this.close();
    });
  }
}
