import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

@Injectable()
export class PopUpService {
  constructor() {}

  raisePopUp(title, message, type) {
    swal(title, message, type);
  }

  raisePopUpWthCallback(title, message, type, callback) {
    swal(title, message, type).then(function() {
      callback();
    });
  }
}
