import {
  Component,
  OnInit,
  Input,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, ProfileService } from '@app/core';
import { RosesService } from '@app/core/services/roses.service';


@Component({
  selector: 'app-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
})
export class ProfileDropdownComponent implements OnInit {
  user: any;
  @ViewChild('profileDropdown', { static: true }) profileDropdown;

  constructor(
    private authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private router: Router,
    private rosesService: RosesService
  ) {}

  ngOnInit() {
    this.user = this.profileService.user;
    console.log(this.user);
  }
  gotoProfile(){
    let url = `/d/profile/${this.profileService.userId }/info` 
    this.router.navigate([url]);
  }

  logout() {
    this.authenticationService.onLogOut();
  }

  close() {
    this.profileDropdown.close();
  }

  dropdownOpened() {
    this.user = this.profileService.user;
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['user'].currentValue !== changes['user'].previousValue) {
  //     this.user = changes['user'].currentValue;
  //   }
  // }

  purchaseCredit() {
    this.router.navigate(['/d/payments/credits']);
  }
}
