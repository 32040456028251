import { EventEmitter, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { UtilService } from './util.service';

@Injectable()
export class PlaylistService extends HttpService {
  newPlaylistCreated = new EventEmitter();
  playlistDeleted = new EventEmitter();

  constructor(private http: HttpClient, private utilService: UtilService) {
    super();
  }

  deletePlaylist(id) {
    const options = this.getHeaders();
    const url = this.makeUrl('/user/playlists/' + id);
    return this.http
      .delete(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getUserPlaylists(
    id: number,
    page = 1,
    perPage = 6,
    sorter = null,
    asc = true,
    query = '',
    isPrivate = null
  ) {
    let url = this.makeUrl(`/users/${id}/playlists`);
    url += this.utilService.buildUrlParams(page, perPage, sorter, asc);
    const q: any = {};
    if (query) {
      q.title = query;
    }

    if (isPrivate !== null) {
      q.private = isPrivate ? 'true' : 'false';
    }
    url += '&q=' + JSON.stringify(q);

    const options = this.getHeaders();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getPlaylists(
    page = 1,
    perPage = 6,
    sorter = null,
    asc = true,
    query = '',
    isPrivate = null
  ) {
    let url =
      '/user/playlists' +
      this.utilService.buildUrlParams(page, perPage, sorter, asc);
    const q: any = {};
    if (query) {
      q.title = query;
    }

    if (isPrivate !== null) {
      q.private = isPrivate ? 'true' : 'false';
    }
    url += '&q=' + JSON.stringify(q);

    const options = this.getHeaders();
    return this.http
      .get(this.makeUrl(url), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getPlaylistDetail(id: number) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/playlists/${id}`);
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  favorite(id: string | number) {
    console.log(id)
    const options = this.getHeaders();
    const url = this.makeUrl(`/playlists/${id}/favorite`);
    return this.http.post(url, {}, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  unfavorite(id: string | number) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/playlists/${id}/favorite`);
    return this.http.delete(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  updatePlaylistDetail(id: number, data) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/playlists/${id}`);
    return this.http
      .put(url, data, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getPlaylistRecordings(playlistId: number, page = 1, perPage = 10) {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/user/playlists/${playlistId}/recordings?page=${page}&per_page=${perPage}`
    );
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  playlistHasRecording(playlistId: number, recordingId: number) {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/user/playlists/${playlistId}/has/${recordingId}`
    );
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  addRecordingToParticularPlaylist(playlistId, recordId) {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/user/playlists/${playlistId}/recordings/${recordId}`
    );
    return this.http.post(url, {}, options);
  }

  deleteRecordingFromPlaylist(playlistId, recordId) {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/user/playlists/${playlistId}/recordings/${recordId}`
    );
    return this.http.delete(url, options);
  }

  createNewPlaylist(title, description, isPrivate) {
    const headers = this.getHeaders();
    const data = new FormData();
    console.log(title, description, isPrivate)
    data.append('title', title);
    data.append('description', description);
    data.append('private', isPrivate ? 'true' : 'false');

    return this.http
      .post(this.makeUrl('/user/playlists'), data, headers)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  playPlaylist(id) {
    const headers = this.getHeaders();
    return this.http
      .post(this.makeUrl(`/playlists/${id}/play`), {}, headers)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  sortRecordings(playlistId, ids) {
    const data = new FormData();
    ids.forEach(id => {
      data.append('order[]', id);
    });
    const headers = this.getHeaders();
    return this.http
      .post(this.makeUrl(`/user/playlists/${playlistId}/sort`), data, headers)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
}
