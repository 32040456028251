<div class="session-page">
    <div class="session-form">
        <h3>Create an account</h3>
        <!-- <app-social-signup-form (processed)="hideForm = true;"></app-social-signup-form> -->
        <app-sign-up-form *ngIf="!hideForm"></app-sign-up-form>
    </div>
    <div class="site-info-slider">
        <app-session-carousel></app-session-carousel>
    </div>
</div>
