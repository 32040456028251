import {Component, EventEmitter, Input, OnInit, Output, OnChanges} from '@angular/core';

@Component({
  selector: 'app-list-pagination',
  templateUrl: './list-pagination.component.html',
  styleUrls: ['./list-pagination.component.scss']
})
export class ListPaginationComponent implements OnInit {

  @Input('currentPage') currentPage = 1;
  @Input('pageSize') pageSize = 15;
  @Input('total') total = 0;
  @Input('boundaryLinks') boundaryLinks = false;
  @Output('onPageChange') onPageChange: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  onPageChangeHandle(event) {
    console.log('current page', event);

    this.currentPage = event;
    this.onPageChange.emit(event);
  }
}
