import {Component, OnInit} from '@angular/core';
import {forkJoin} from 'rxjs';
import {PlaylistService, ProfileService, RecordingService} from '@app/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-user-me-info',
    templateUrl: './user-me-info.component.html',
    styleUrls: ['./user-me-info.component.scss']
})
export class UserMeInfoComponent implements OnInit {
    infoItems = [];
    userRecordings$;
    user;
    userId;
    recordings;
    fans;
    totalRecordings = 0;
    totalFans = 0;
    totalBadges = 0;
    totalOpenDuets = 0;
    totalPlaylists = 0;

    constructor(private recordingService: RecordingService,
                private profileService: ProfileService,
                private playlistService: PlaylistService,
                private activatedRoute: ActivatedRoute) {
                this.activatedRoute.parent.params.subscribe(params => {
                    this.infoItems = [];
                    this.userRecordings$ = null;
                    this.user = null;
                    this.userId = null;
                    this.recordings = null;
                    this.fans = null;
                    this.totalRecordings = 0;
                    this.totalFans = 0;
                    this.totalBadges = 0;
                    this.totalOpenDuets = 0;
                    this.totalPlaylists = 0;
                    this.onLoad();
                    });
    }
    onLoad(){
        this.userRecordings$ = this.recordingService.getUserRecordings(1, 10);
        this.profileService.getUserProfile().subscribe( response => {
            this.user = response;
            this.infoItems = [
                {
                    text: 'FANS',
                    secondaryText: this.user.stats.fans
                },
                {
                    text: 'RECORDINGS',
                    secondaryText: this.user.stats.recordings
                },
                {
                    text: 'PLAYLIST',
                    secondaryText: this.user.stats.playlists
                },
                {
                    text: 'OPEN DUETS',
                    secondaryText: this.user.stats.open_duets
                },
                {
                    text: 'BADGES',
                    secondaryText: this.user.stats.badges
                }
            ];
        });

        this.profileService.getFans().subscribe( response => {
            this.fans = response.data;
        })
    }
    ngOnInit() {
        
    }

}
