import {Component, OnInit} from '@angular/core';
//import * as $ from 'jquery';
import {AuthenticationService} from '@app/core';
import { Cookie } from 'ng2-cookies';


@Component({
    selector: 'app-website-home',
    templateUrl: './website-home.component.html',
    styleUrls: ['./website-home.component.scss']
    // animations: [
    //     trigger('routeAnimation', [
    //         state('*', style({transform: 'translateY(0)', opacity: 1})),
    //         transition('void => *', [
    //             style({transform: 'translateY(100px)', opacity: 0}),
    //             animate('0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000)')
    //         ]),
    //         transition('* => void',
    //             animate('0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000)', style({
    //                 transform: 'translateX(100px)',
    //                 opacity: 0
    //             }))
    //         )
    //     ]),
    //     trigger("fadeInUp", [
    //         state("inactive", style({
    //             opacity: 0,
    //             transform: 'translateY(200px)',
    //         })),
    //         state("active", style({
    //             opacity: 1,
    //             transform: 'translateY(0)',
    //         })),

    //         transition("* => active", animate("600ms ease-in")),
    //         transition("* => inactive", animate("400ms ease-out"))
    //     ]),
    //     trigger("slideFromLeft", [
    //         state("inactive", style({
    //             opacity: 0,
    //             transform: 'translateX(200px)',
    //         })),
    //         state("active", style({
    //             opacity: 1,
    //             transform: 'translateX(0)',
    //         })),

    //         transition("* => active", animate("800ms ease-in")),
    //         transition("* => inactive", animate("400ms ease-out"))
    //     ]),
    //     trigger("slideFromRight", [
    //         state("inactive", style({
    //             opacity: 0,
    //             transform: 'translateX(-200px)',
    //         })),
    //         state("active", style({
    //             opacity: 1,
    //             transform: 'translateX(0)',
    //         })),

    //         transition("* => active", animate("800ms ease-in")),
    //         transition("* => inactive", animate("400ms ease-out"))
    //     ]),
    //     trigger("pulse", [
    //         transition('* => *', [
    //             query('a span', style({opacity: 0}), {optional: true}),
    //             query('a span', stagger('100ms', [
    //                 animate('400ms', keyframes([
    //                     style({opacity: 0, transform: 'scale(1)', offset: 0}),
    //                     style({opacity: 1, transform: 'scale(1.5)', offset: 0.5}),
    //                     style({opacity: 1, transform: 'scale(1)', offset: 1.0})
    //                 ]))]), {optional: true})
    //         ]),
    //     ]),
    // ]
})
export class WebsiteHomeComponent implements OnInit {
    isHamburgerVisible = false;
    userIsLoged = false;

    //animations
    // @HostBinding('@routeAnimation') routeAnimation = true;
    // homeAnimations: any = {};

    // constructor(private authService: AuthenticationService, private scrollimateService: ScrollimateService) {
        constructor(private authService: AuthenticationService) {

        let is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
        const is_explorer = navigator.userAgent.indexOf('MSIE') > -1;
        const is_firefox = navigator.userAgent.indexOf('Firefox') > -1;
        let is_safari = navigator.userAgent.indexOf("Safari") > -1;
        const is_opera = navigator.userAgent.toLowerCase().indexOf("op") > -1;
        if ((is_chrome) && (is_safari)) {
            is_safari = false;
        }
        if ((is_chrome) && (is_opera)) {
            is_chrome = false;
        }
        //if (!is_safari) {
            // this.homeAnimations = {
            //     defaultStates: [
            //         {
            //             method: "pxElement",
            //             value: 200,
            //             state: "active",
            //         },
            //         {
            //             method: "default",
            //             state: "inactive",
            //         }
            //     ],
            //     firstBlock: {
            //         currentState: "inactive",
            //         states: [
            //             {
            //                 method: "percentElement",
            //                 value: 75,
            //                 state: "active",
            //             },
            //             {
            //                 method: "default",
            //                 state: "inactive"
            //             }
            //         ]
            //     },
            //     secondBlock: {
            //         currentState: "inactive",
            //         states: [
            //             {
            //                 method: "percentElement",
            //                 value: 75,
            //                 state: "active",
            //             },
            //             {
            //                 method: "default",
            //                 state: "inactive"
            //             }
            //         ]
            //     },
            //     thirdBlock: {
            //         currentState: "inactive",
            //         states: [
            //             {
            //                 method: "percentElement",
            //                 value: 75,
            //                 state: "active",
            //             },
            //             {
            //                 method: "default",
            //                 state: "inactive"
            //             }
            //         ]
            //     },
            //     fourthBlock: {
            //         currentState: "inactive",
            //         states: [
            //             {
            //                 method: "percentElement",
            //                 value: 75,
            //                 state: "active",
            //             },
            //             {
            //                 method: "default",
            //                 state: "inactive"
            //             }
            //         ]
            //     },
            //     fifthBlock: {
            //         currentState: "inactive",
            //         states: [
            //             {
            //                 method: "percentElement",
            //                 value: 75,
            //                 state: "active",
            //             },
            //             {
            //                 method: "default",
            //                 state: "inactive"
            //             }
            //         ]
            //     }
            // };
        //}
        // scrollimateService.setDefaultStates(this.homeAnimations.defaultStates);
    }

    ngOnInit() {
        const token = Cookie.get('AuthToken');
        this.userIsLoged = !!token;


        // // Select all links with hashes
        // $('a[href*="#"]')
        // // Remove links that don't actually link to anything
        //     .not('[href="#"]')
        //     .not('[href="#0"]')
        //     .click(function (event) {
        //         // On-page links
        //         if (
        //             location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        //             &&
        //             location.hostname === this.hostname
        //         ) {
        //             // Figure out element to scroll to
        //             let target = $(this.hash);
        //             target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        //             // Does a scroll target exist?
        //             if (target.length) {
        //                 // Only prevent default if animation is actually gonna happen
        //                 event.preventDefault();
        //                 $('html, body').animate({
        //                     scrollTop: target.offset().top
        //                 }, 1000, function () {
        //                     // Callback after animation
        //                     // Must change focus!
        //                     const $target = $(target);
        //                     $target.focus();
        //                     if ($target.is(":focus")) { // Checking if the target was focused
        //                         return false;
        //                     } else {
        //                         $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
        //                         $target.focus(); // Set focus again
        //                     }
        //                 });
        //             }
        //         }
        //     });

    }

    onLogOut() {
        this.authService.onLogOut();
    }

    hideHamburger(event) {
        const currentTarget = event.currentTarget;
        let toElement = event.toElement;
        if (navigator.userAgent.indexOf('Firefox') !== -1) {
            toElement = event.relatedTarget;
        }
        if (currentTarget !== toElement && !this.isDescendant(currentTarget, toElement)) {
            this.isHamburgerVisible = false;
        }

    }

    isDescendant(parent, child) {
        let node = child && child.parentNode;
        while (node != null) {
            if (node === parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

}
