import { Component, HostListener, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Data, SingService, InnerCircleService, RecordingService } from '@app/core';
import { ConfirmationModalComponent } from '@app/shared/confirmation-modal/confirmation-modal.component';
import { FailedService } from '../../core/services/failed.service';
import { ToastrService } from 'ngx-toastr';
import { EmailNotificationsService } from '@app/core/services/email-notifications.service';

declare var gtag;

@Component({
  selector: 'app-sing-uploader',
  templateUrl: './sing-uploader.component.html',
  styleUrls: ['./sing-uploader.component.scss']
})
export class SingUploaderComponent implements OnInit, OnDestroy {
  @ViewChild('posterRef', { static: true }) private posterFileRef: ElementRef;
  @ViewChild('notification', { static: true }) private notificationModal;
  @ViewChild('photo', { static: true }) private photoElem;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;
  @HostListener('window:beforeunload', ['$event'])
  warn($event) {
    return $event.returnValue='Your changes will not be saved';
  }
  id: any;                // Existing recording ID
  song: any;
  validTypes: any = ['.jpg', 'jpeg', '.png'];
  imageSrc: string = null;
  posterFile: any = null;
  description = '';
  privacy = 0;
  privacyExplanation = 'Who can see my recording: members & non-members.';

  icDropdown = false;
  privacyDropdown = false;
  // privacyList = ['Public (anyone can view)', 'Private (only you can view)', 'Inner Circles'];
  privacyList = ['Public', 'Members Only', 'Private'];
  allowLoves = true;
  allowComments = true;
  trackViews = true;
  socialNetwork = true;
  allowDuets = true;
  offensiveContent = false;
  showMyDuet = false;
  tags = '';
  title = '';
  tagsList = [];

  error: any = {};
  config: any = {};
  uploadError = '';
  thumbs: any = [];
  selectedThumb: any = null;
  icList: any = [];
  selectedICs = [];
  nextPage: any = null;
  pageCount: any = 1;
  pageCurrent: any = 1;
  customThumb = true;
  uploading = false;
  updating = false;
  failedRecording = false;
  queued = false;
  gettingTags = false;
  tagSearchFailed = false;

  contestList = [];
  selectedContests = [];
  contestDropdown = false;
  failedID;
  failedSubscription: Subscription;
  failedBool = false;



  public constructor(
    private activatedRoute: ActivatedRoute,
    private data: Data,
    private singService: SingService,
    private recordingService: RecordingService,
    private icService: InnerCircleService,
    private location: Location,
    private router: Router,
    private dbService: NgxIndexedDBService,
    private failed: FailedService,
    private toastr: ToastrService,
    private emailNotificationsService: EmailNotificationsService,

  ) {
    this.activatedRoute.params.subscribe(params => {

      this.id = params.id;
      console.log(params.failedID)
      this.failedID = params.failedID;
      console.log(params)
      console.log('recording id', this.id);
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if(typeof params.failedID != 'undefined') {
        this.failedID = parseInt(params.failedID);
        this.failedRecording = true;
      }
      console.log(params)
  });
  }

  ngOnInit() {
    this.failedSubscription = this.failed.currentMessage.subscribe(message => this.failedBool = message)

    this.loadThumbs();
    this.loadInnerCircles();

    if (this.id) {
      this.recordingService.getUserRecordingByid(this.id).subscribe(result => {
        this.song = result;
        this.initWithSong();
      });
    } else if(typeof this.failedID != 'undefined') {
        this.dbService.getByKey('uploads', this.failedID).subscribe((recording) => {
          this.song = {}
          Object.keys(recording).forEach(key => {
            let property = recording[key]
            if (recording[key] == 'false' || recording[key] == 'true') {
              property = (recording[key] === 'true') // string to bool
              console.log(property, key)
            }
            this.song[key] = property
          })
          console.log(recording)
          //this.song = recording;
          this.initWithSong();
        });
      }
      else {
      if (!this.data.storage) {
        this.router.navigate(['/d/sing']);
      }

    }
  }

  searchTags = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.gettingTags = true),
      switchMap(term =>
        this.recordingService.getTags(term).pipe(
          tap(() => this.tagSearchFailed = false),
          catchError(() => {
            this.tagSearchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.gettingTags = false)
    )

  initWithSong() {
    console.log(this.song)
    this.title = this.song.song.title;
    this.description = this.song.info;
    if(this.description == null) {
      this.description = '';
    }
    //screw you angular!
    document.getElementsByClassName("ql-editor")[0].innerHTML = this.description;
    console.log(this.description)

    if(typeof this.song.tagslist != 'undefined') {
      this.tagsList = this.song.tagslist.map(tag => '#' + tag);
    }
    // TODO: inner circles
    if (this.song.private) {
      console.log('changing to private')
      this.privacy = 2;
    }
    if (this.song.members_only) {
      console.log('changing to members only')
      this.privacy = 1;
    }
    this.onChangePrivacy(this.privacy);
    this.allowComments = this.song.allow_comments;
    this.allowLoves = this.song.allow_loves;
    this.trackViews = this.song.track_views;
    this.socialNetwork = this.song.allow_share;
    this.showMyDuet = this.song.view_on_profile;
    this.allowDuets = this.song.allow_duets;
    this.offensiveContent = this.song.offensive;
    if(typeof this.song.inner_circles != 'undefined') {
      this.selectedICs = this.song.inner_circles;
    }
    if (this.song.contest) {
      this.selectedContests = this.song.contests;
      this.contestList = this.song.contests;
    }
   
    //for sendgrid email
    this.data.recordingDuetLayers = this.song.recordingDuetLayers;

  }
  ngOnDestroy() {
    this.failedSubscription.unsubscribe();
    if(this.queued) {
      setTimeout(()=>{
        window.location.reload();
      }, 500)

    }
  }

  loadInnerCircles() {
    this.icService.getShareableInnerCircles(1, 1000).subscribe(result => {
      console.log(result);
      this.icList = result.data;
    });
  }

  loadThumbs() {
    this.singService.getDefaultArtworks().subscribe(result => {
      this.thumbs = result.data;
      this.nextPage = result.next_page_url;
      this.pageCount = result.last_page;
    });
  }

  onCancelUpload() {
    if (this.id || typeof this.failedID != 'undefined') {
      this.location.back();
      // this.router.navigate(['/d/my-recordings']);
    } else {
      this.router.navigate(['/recorder', this.data.storage.song.id]);
    }
  }

  editorContentChange(event) {
    this.description = event ? event : '';
  }

  onChangePrivacy(index) {
    this.privacy = index;
    const text = [
      'Who can see my recording: members & non-members.',
      'Who can see my recording: members, non-members & the Inner Circles you\'ve selected.',
      'Who can see my recording: members.',
      'Who can see my recording: members & the Inner Circles you\'ve selected.',
      'Who can see my recording: only you.',
      'Who can see my recording: only me & the Inner Circles you\'ve selected.'
    ];
    let p = index * 2;
    if (this.selectedICs.length) {
      p += 1;
    }
    this.privacyExplanation = text[p];
  }

  selectThumb(thumb) {
    this.selectedThumb = thumb;
    this.customThumb = false;
  }

  selectCustomThumb() {
    this.selectedThumb = null;
    this.customThumb = true;
  }

  onSelectContest(contest) {
    this.contestDropdown = false;
    this.selectedContests.push(contest);
  }

  validContests() {
    return this.contestList.filter(c =>
      this.selectedContests.findIndex(x => x.id === c.id) === -1
    );
  }

  removeContest(id) {
    this.notificationModal.open('Warning', 'You can\'t un-submit your recording from a contest.');
    // this.selectedContests = this.selectedContests.filter(x => x.id !== id);
  }

  onConfirmClosed() {

  }

  onSelectIC(id) {
    this.icDropdown = false;
    const ic = this.icList.find(x => x.id === id);
    this.selectedICs.push(ic);
    this.onChangePrivacy(this.privacy);
  }

  validICs() {
    return this.icList.filter(ic =>
      this.selectedICs.findIndex(x => x.id === ic.id) === -1
    );
  }

  removeIC(id) {
    this.selectedICs = this.selectedICs.filter(x => x.id !== id);
    this.onChangePrivacy(this.privacy);
  }

  onAddPosterFile() {
    this.posterFileRef.nativeElement.click();
  }

  onPosterFileChange(event: any) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if (this.validTypes.indexOf(file.name.substr(-4).toLowerCase()) == -1) {
        alert('Please upload valid image file.\nJPG and PNG files will work.');
        return;
      }
      this.readFile(file);
    }
  }

  readFile(file) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageSrc = e.target.result;
    };
    reader.readAsDataURL(file);
    this.posterFile = file;
    console.log(this.posterFile);
  }

  onChangeDuet() {
    this.showMyDuet = this.allowDuets && this.showMyDuet;
  }

  prepareSongData() {
    const data: FormData = new FormData();
    if (typeof this.failedID != 'undefined') {
      data.append('recording', this.song.recording);
      data.append('meta', this.song.meta);
      data.append('uuid', this.song.uuid);
      data.append('device', 'Webapp');
      data.append('part', this.song.part);
      data.append('mmsb', this.song.mmsb);
    }
    if (!this.id && typeof this.failedID == 'undefined') {
      const payload = this.data.storage;

      // if (payload.payload.meta.latency > 0) {
      //   payload.payload.meta.latency = -payload.payload.meta.latency;
      // }
      data.append('recording', payload.recording, payload.uuid);
      data.append('meta', JSON.stringify(payload.payload.meta));
      data.append('uuid', payload.uuid);
      data.append('device', 'Webapp');
      data.append('part', payload.payload.part);
      data.append('mmsb', payload.mmsb ? 'true' : 'false');
    }
    if(this.description == null) {
      this.description = '';
    }
    data.append('info', this.description);
    data.append('private', this.privacy === 2 ? 'true' : 'false');
    data.append('members_only', this.privacy === 1 ? 'true' : 'false');
    data.append('allow_loves', this.allowLoves ? 'true' : 'false');
    data.append('allow_comments', this.allowComments ? 'true' : 'false');
    data.append('track_views', this.trackViews ? 'true' : 'false');
    data.append('allow_share', this.socialNetwork ? 'true' : 'false');
    data.append('allow_duets', this.allowDuets ? 'true' : 'false');
    data.append('view_on_profile', this.allowDuets && this.showMyDuet ? 'true' : 'false');
    data.append('offensive', this.offensiveContent ? 'true' : 'false');

    this.tagsList.forEach(tag => {
      if (tag.trim()) {
        data.append('tags', tag.trim());
      }
    });
    // if (this.customThumb) {
    //   if (this.posterFile) {
    //     if (!this.id) {
    //       data.append('file', this.posterFile, this.data.storage.uuid);
    //     } else {
    //       data.append('file', this.posterFile);
    //     }
    //   }
    // } else {
    //   data.append('media_id', this.selectedThumb.id);
    // }
    if (this.customThumb) {
      if(this.posterFile) {
        console.log('appending file')
        data.append('file', this.posterFile);
      }

    } else {
      console.log('appending file not custom')

      data.append('media_id', this.selectedThumb.id);
    }
    this.selectedICs.forEach(ic => {
      data.append('inner_circles', ic.id);
    });
    this.selectedContests.forEach(contest => {
      data.append('contests', contest.id);
    });

    return data;
  }

  prepareSongDataUpdate() {
    const data: FormData = new FormData();
    if (typeof this.failedID != 'undefined') {
      data.append('recording', this.song.recording);
      data.append('meta', this.song.meta);
      data.append('uuid', this.song.uuid);
      data.append('device', 'Webapp');
      data.append('part', this.song.part);
      data.append('mmsb', this.song.mmsb);
    }
    if (!this.id && typeof this.failedID == 'undefined') {
      const payload = this.data.storage;
      data.append('recording', payload.recording, payload.uuid);
      data.append('meta', JSON.stringify(payload.payload.meta));
      data.append('uuid', payload.uuid);
      data.append('device', 'Webapp');
      data.append('part', payload.payload.part);
      data.append('mmsb', payload.mmsb ? 'true' : 'false');
    }
    if(this.description == null) {
      this.description = '';
    }
    data.append('info', this.description);
    data.append('private', this.privacy === 2 ? 'true' : 'false');
    data.append('members_only', this.privacy === 1 ? 'true' : 'false');
    data.append('allow_loves', this.allowLoves ? 'true' : 'false');
    data.append('allow_comments', this.allowComments ? 'true' : 'false');
    data.append('track_views', this.trackViews ? 'true' : 'false');
    data.append('allow_share', this.socialNetwork ? 'true' : 'false');
    data.append('allow_duets', this.allowDuets ? 'true' : 'false');
    data.append('view_on_profile', this.allowDuets && this.showMyDuet ? 'true' : 'false');
    data.append('offensive', this.offensiveContent ? 'true' : 'false');

    this.tagsList.forEach(tag => {
      if (tag.trim()) {
        data.append('tags[]', tag.trim());
      }
    });
    if (this.customThumb) {
      if(this.posterFile) {
        console.log('appending file')
        data.append('file', this.posterFile);
      }

    } else {
      console.log('appending file not custom')

      data.append('media_id', this.selectedThumb.id);
    }
    this.selectedICs.forEach(ic => {
      data.append('inner_circles[]', ic.id);
    });
    this.selectedContests.forEach(contest => {
      data.append('contests[]', contest.id);
    });
    return data;
  }

  update() {
    this.updating = true;
    const data = this.prepareSongDataUpdate();
    data.append('_method', 'PUT');
    this.recordingService.update(this.id, data).subscribe(result => {
      this.updating = false;
      this.router.navigate(['/d/my-recordings']);
    });

  }
  queue(fail = false){
    this.queued = true;
    const data = this.prepareSongData();
    let dbData = {};
    data.forEach((value,key) => {
      dbData[key] = value;
      console.log(key, value)
    });
    let date = new Date()
    let formattedDate = new Intl.DateTimeFormat('en-CA').format(date)
    console.log(dbData['members_only'])
    this.dbService.add('uploads', {
      date: formattedDate,
      song: this.data.storage.song,
      meta: dbData['meta'],
      recording: dbData['recording'],
      uuid: dbData['uuid'],
      device: dbData['device'],
      part: dbData['part'],
      mmsb: dbData['mmsb'],
      info: dbData['info'],
      private: dbData['private'],
      members_only: dbData['members_only'],
      allow_loves: dbData['allow_loves'],
      allow_comments: dbData['allow_comments'],
      track_views: dbData['track_views'],
      allow_share: dbData['allow_share'],
      allow_duets: dbData['allow_duets'],
      view_on_profile: dbData['view_on_profile'],
      offensive: dbData['offensive'],
      recordingDuetLayers: this.data.recordingDuetLayers,
    }).subscribe(()=>{
      this.failed.updateDB(!this.failedBool);
      this.toastr.success('Recording was succesfully saved to Queued Uploads');
      if(!fail) {
        this.router.navigate(['/d/sing']);
      }

    })
  }
  upload() {
    const data = this.prepareSongData();
    let dbData = {};
    this.uploading = true;
    data.forEach((value,key) => {
      dbData[key] = value;
      console.log(key, value)
    });
    console.log(this.data)
    this.singService.uploadRecording(data).subscribe(result => {
      this.uploading = false;
      const image = this.customThumb ? this.imageSrc : this.selectedThumb.uri;
     // const image = this.imageSrc;
     let song;
     console.log(this.failedID)
     
     if(typeof this.failedID != 'undefined'){
       song = this.song.song
       song.previouslyFailed = true;
       //this.dbService.delete('uploads', this.failedID);
       this.dbService.delete('uploads', this.failedID).subscribe((db) => {
        this.failed.updateDB(!this.failedBool);
      });
     } else {
       song = this.data.storage.song;
       song.previouslyFailed = false;

     }

      this.data.storage = {
        image,
        song,
        recording: result.model
      };
      gtag('event', 'sing', {
        action: 'Save Recording',
        songId: result.model.id.toString(),
        saveType: 'upload'
      });

      if(dbData['private']=== 'false'){ 
        if (Object.keys(this.data.recordingDuetLayers).length){
          for(var i=0;i<Object.keys(this.data.recordingDuetLayers).length;i++) {
            if( !this.data.recordingDuetLayers[i].isMe && this.data.recordingDuetLayers[i].newsletter){
              this.emailNotificationsService.emailrecordingDuetLayers(this.data.recordingDuetLayers[i].email, this.data.recordingDuetLayers[i].name, this.data.recordingDuetLayers[i].sender_name, this.data.recordingDuetLayers[i].sender_id, result.model.id, this.data.recordingDuetLayers[i].title);
            }
          }
        }
      }

      this.router.navigate(['/d/sing-uploader/success']);
    }, (error) =>{
      console.log(error)
      console.log(dbData)
      let date = new Date()
      let formattedDate = new Intl.DateTimeFormat('en-CA').format(date)
      this.uploadError = "We had trouble uploading your recording! You can try to upload this recording again from the queued uploads panel.";

      //save the recording for later (if we're not trying to upload it again)
      if(!this.failedRecording) {
        gtag('event', 'sing', {
          action: 'Save Recording',
          saveType: 'queue'
        });
        this.queue(true);
      }

    });
  }

  onCancelImage() {
    this.posterFile = null;
    this.imageSrc = null;
    this.posterFileRef.nativeElement.value = null;
  }

  onTagInput(e) {
    if (e.keyCode === 32) {
      return false;
    }
    if (e.keyCode === 13) {
      this.addTag();
    }
  }

  addTag() {
    if(!this.tags.length){
      return;
    }
    if (!this.tags.startsWith('#')) {
      this.tags = '#' + this.tags;
    }
    this.tagsList.push(this.tags);
    this.tags = '';
  }

  removeTag(index) {
    this.tagsList.splice(index, 1);
  }

  onScrollTo(offset: any) {
    const pos = this.directiveRef.position(true);
    this.directiveRef.scrollToLeft(pos.x + offset, 500);
  }

  onPhotoTaken(e) {
    console.log(e);
    if (e.blob) {
      this.imageSrc = e.base64;
      this.posterFile = e.blob;
    }
  }

  takePhoto() {
    this.photoElem.open();
  }
}
