import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  title = '';
  description = '';
  cancelText = 'No';
  acceptText = 'Yes';

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  open(title = 'Confirm', description = '', { cancelText = 'No', acceptText = 'Yes' } = {}) {
    if(cancelText) {
      this.cancelText = cancelText;
    }

    if(acceptText) {
      this.acceptText = acceptText;
    }

    this.title = title;
    this.description = description;
    return this.modalService.open(this.contentElem, {
      windowClass: 'ss-modal-confirmation',
      backdrop: 'static',
    }).result.then((result) => {
      this.title = '';
      this.description = '';
      return result;
    }, (reason) => {
      this.title = '';
      this.description = '';
      return reason;
    });
  }
}
