<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M16,10 L14,10 L14,14 L10,14 L10,16 L14,16 L14,20 L16,20 L16,16 L20,16 L20,14 L16,14 L16,10 L16,10 Z M15,5 C9.49,5 5,9.49 5,15 C5,20.51 9.49,25 15,25 C20.51,25 25,20.51 25,15 C25,9.49 20.51,5 15,5 L15,5 Z M15,23 C10.59,23 7,19.41 7,15 C7,10.59 10.59,7 15,7 C19.41,7 23,10.59 23,15 C23,19.41 19.41,23 15,23 L15,23 Z"
        id="path-cancel"></path>
    </defs>
    <g id="ic_control_point" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-3.000000, -3.000000)" id="Icon-24px">
        <g>
          <polygon id="Shape" points="3 3 27 3 27 27 3 27"></polygon>
          <mask id="mask-2" fill="white">
            <use xlink:href="#path-cancel"></use>
          </mask>
          <use id="Mask" [attr.fill]="color" transform="translate(15.000000, 15.000000) rotate(43.000000) translate(-15.000000, -15.000000) "
            xlink:href="#path-cancel"></use>
        </g>
      </g>
    </g>
  </svg>
</div>