
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { settings } from '../../components/shop/settings';
import { Cookie } from 'ng2-cookies/ng2-cookies';

const Authorization = "Bearer " + Cookie.get('AuthToken');

@Injectable()
export class CategoriesService{
  constructor(private http: HttpClient, private httpService: HttpService) {}

  // getHeaders() { <-----who thought this was a good idea to put here???
  //   const headers = new Headers();
  //   headers.append('Authorization', "Bearer " + Cookie.get('AuthToken'));
  //   return new RequestOptions({ headers: headers });
  // }

  getCategories(perPage: any) {
    const options = this.httpService.getHeaders();
    return this.http
      .get(settings.host + 'categories?perPage=' + perPage, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      catchError(this.handleError));
  }

  getPopularProducts(perPage: any) {
    const options = this.httpService.getHeaders();
    return this.http
      .get(settings.host + 'popular?perPage=' + perPage, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      catchError(this.handleError));
  }

  getLatestProducts(perPage: any) {
    const options = this.httpService.getHeaders();
    return this.http
      .get(settings.host + 'latest?perPage=' + perPage, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      catchError(this.handleError));
  }

  getProductsFromCategory(
    id: any,
    filter: { orderby: string; order: string; perPage: any; tag: string }
  ) {
    const options = this.httpService.getHeaders();
    return this.http
      .get(
        settings.host +
          'products?id=' +
          id +
          '&orderby=' +
          filter.orderby +
          '&order=' +
          filter.order +
          '&perPage=' +
          filter.perPage +
          '&tag=' +
          filter.tag,
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      catchError(this.handleError));
  }

  selectedCategory(id: any) {
    const options = this.httpService.getHeaders();
    return this.http
      .get(settings.host + 'category?id=' + id, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      catchError(this.handleError));
  }

  getProductDetails(id: any) {
    const options = this.httpService.getHeaders();
    return this.http
      .get(settings.host + 'product?id=' + id, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      catchError(this.handleError));
  }

  private handleError(error: any) {
    return observableThrowError(error.json());
  }
}
