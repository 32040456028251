<div class="container container-ss ngucarousel-custom">
    <div class="top-controls">
        <ng-content select="h1"></ng-content>
        <div class="carousel-controls">
            <app-icon-angle-down size="18" NguCarouselPrev (click)="toPrevSlide()" class="page-left"></app-icon-angle-down>
            <div class="carousel-page">
              <span class="active">{{getIndex() + 1}}</span>/<span>{{totalItemPage}}</span>
            </div>
            <app-icon-angle-down size="18" NguCarouselNext  (click)="toNextSlide()" class="page-right"></app-icon-angle-down>
          </div>
        </div> 
       <ngx-glide [perView]='amount' [showArrows]='false' [showBullets]='false' [listenToEvents]='true' (moved)='detectChanges()'>

          <div *ngFor="let item of items; let j = index">
            <div class="recommended-item">
                <div class="recommended-item">
                    <app-featured-image [item]="items[j]" [showStats]="showStats">
                    </app-featured-image>
                </div>
            </div>
          </div>
      

        </ngx-glide>




