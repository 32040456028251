import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output, SimpleChanges,
    ViewChild
} from '@angular/core';
import {QuillEditorComponent} from 'ngx-quill';
import {UsersService, MessageBoardService} from '@app/core';
import {ActivatedRoute} from '@angular/router';
import {Cookie} from 'ng2-cookies/ng2-cookies';
import Quill from 'quill';
//import "quill-mention";
//import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
//Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
import MagicUrl from 'quill-magic-url';
Quill.register('modules/magicUrl', MagicUrl);

import {bindings} from './modules/keyboard';
import {embedVideos} from './modules/clipboard';
import {link} from './modules/link';
import CustomVideoBlot from '@app/shared/comments/ss-editor/modules/video-blot';
import 'quill-emoji/dist/quill-emoji.js'
import Mention from './modules/quill-mention/quill.mention';
import QuoteBlot from './modules/quote-blot';

@Component({
    selector: 'app-ss-editor',
    templateUrl: './ss-editor.component.html',
    styleUrls: ['./ss-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsEditorComponent implements OnInit, OnChanges {

    @Input() editorContent: any = '';
    @Input() placeholder: any = 'Add a comment...';
    @Input() autofocus = false;
    @Input() state = 'close';
    @Input() showPostButton = true;
    @Input() showSendSetting = false;
    @Input() showSetting = true;
    @Input() hideToolbar = false;
    @Input() fontSize = true;
    @Output() editorContentChange = new EventEmitter<string>();
    @Output() editorFocus = new EventEmitter<string>();
    @Output() editorBlur = new EventEmitter<string>();
    @Output() onCommentPost: EventEmitter<any> = new EventEmitter();
    //Cancel Comment
    @Output() onCancelCommentPost: EventEmitter<any> = new EventEmitter();
    //@Output() ('onCancelCommentEdit') onCancelCommentEdit: EventEmitter<any> = new EventEmitter();
    @ViewChild('editor', { static: true }) editor: QuillEditorComponent;
    enterSend = false;
    modules = {};
    uploadingImage = false;
    imageWidth = 700

    constructor(private el: ElementRef,
                private cdr: ChangeDetectorRef,
                private userService: UsersService,
                private route: ActivatedRoute,
                private messageBoardService: MessageBoardService) {


        Quill.register({
            'formats/quote': QuoteBlot,
            'modules/mention': Mention,
            CustomVideoBlot,

        }, true);
    }

    ngOnInit() {
        window.addEventListener('dragover', function(e) {
            e.preventDefault()
          }, false)
          window.addEventListener('drop', function(e) {
            e.preventDefault()
          }, false)

        this.enterSend = Cookie.get('enterSend') === 'true';

        this.modules = {
            //removed for Angular 12 update
            // imageDropAndPaste: {
            //     handler:  (event) =>  this.pasteImage(event)                         //   () => console.log('testing 1 2 3 4 5 6')  
            // },
            'emoji-toolbar': true,
            'emoji-shortname': true,
           
            mention: {
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                mentionDenotationChars: ['@'],
                source: (searchTerm, renderList, mentionChar) => {
                    this.userService.getUsers(searchTerm).subscribe(({data}) => {
                        renderList(data.map(user => {
                            return {
                                id: user.id,
                                value: user.screen_name,
                                email: user.email,
                                newsletter: user.newsletter
                            };
                        }));
                    });
                },
            },
            toolbar: {
                handlers: {
                    link: (value) => {
                        let tooltip = this.editor.quillEditor.theme.tooltip;
                        if (value) {
                            let range = this.editor.quillEditor.getSelection();
                            if (range == null || range.length === 0) return;
                            let preview = this.editor.quillEditor.getText(range);
                            if (/^\S+@\S+\.\S+$/.test(preview) && preview.indexOf('mailto:') !== 0) {
                                preview = 'mailto:' + preview;
                            }
                            tooltip.edit('link', preview);
                        } else {
                            tooltip.edit();
                        }
                    }
                }
            },
            keyboard: { bindings },
            magicUrl: true,
        };

    }
    
    pasteImage(event){
        this.uploadingImage = true;    
        console.log(event)    
        var imageData = event
        let type = event.substr(5,9)
        console.log(type)
        var byteCharacters = atob(imageData.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([ byteArray ], {
            type : type
        });
        let file = new File([blob], Date.now().toString(), {type: type});
        this.readImageWidth(file)
        this.saveToServer(file)
    }



    readImageWidth(file){
        let img = document.createElement('img');
        let blob = URL.createObjectURL(file);
        img.src = blob;
        img.id = 'hiddenImage';
        img.onload = () => {
          this.imageWidth = img.width;
          console.log(this.imageWidth)
          URL.revokeObjectURL(blob)
        }
    }

    


    ngOnChanges(changes: SimpleChanges) {
        if (changes.editorContent && typeof changes.editorContent.currentValue === 'string' && changes.editorContent.currentValue.length === 0) {
            setTimeout(() => {
                this.editor.quillEditor.setContents([
                    {
                        insert: ''
                    },
                    {
                        insert: '\n'
                    }
                ] as any);
                this.editorContent = '';
                this.cdr.detectChanges();
            }, 0);
        }
    }

    onEditorCreated() {
        console.log('EDITOR CREATED')
        //setTimeout(()=>{
            // document.getElementsByTagName('input') : to gell all Docuement imputs
            const elems = [].slice.call((<HTMLElement>this.el.nativeElement).querySelectorAll('.ql-editor'));
            elems.forEach((elem: HTMLElement) => {
                elem.addEventListener('focus', this.onEditorFocus.bind(this));
                elem.addEventListener('blur', this.onEditorBlur.bind(this));
            });

            // quill editor add image handler
            console.log(this.editor.quillEditor)
            console.log(this.editor)

            this.editor['quillEditor'].getModule('toolbar').addHandler('image', () => {
                this.selectLocalImage();
            });

            this.editor['quillEditor'].clipboard.addMatcher(embedVideos.nodeType, embedVideos.matcher);

            // quill editor add link handler
            // TODO: find a way to remove the link
            console.log(this.editor['quillEditor'])
            this.editor['quillEditor'].getModule('toolbar').addHandler('link', link);

            this.cdr.detectChanges();

        //}, 1000)
        // if (this.autofocus) {
        //     // prevent direct jump to scroll
        //     const x = window.scrollX;
        //     const y = window.scrollY;
        //     this.focus();
        //     window.scrollTo(x, y);
        // }

       
    }

    /**
     * Step1. select local image
     *
     */
    selectLocalImage() {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        // Listen upload local image and save to server
        input.onchange = () => {
            const file = input.files[0];

            // file type is only image.
            if (/^image\//.test(file.type)) {
                this.readImageWidth(file)
                this.saveToServer(file);
            } else {
                console.warn('You could only upload images.');
            }
        };
    }

    /**
     * Step2. save to server
     *
     * @param {File} file
     */
    saveToServer(file: File) {
        const id = this.route.snapshot.params && this.route.snapshot.params.id;
        this.messageBoardService.uploadMedia(null, file).subscribe(response => {
            this.insertToEditor(response.data);
        });
    }

    /**
     * Step3. insert image url to rich editor.
     *
     * @param {string} url
     */
    insertToEditor(url: string) {
        //check if they image width is too big
        if(this.imageWidth >= 700) {
            url = url + '?w=700'
        }

        // push image url to rich editor.
        const range = this.editor.quillEditor.getSelection();
        this.editor.quillEditor.insertEmbed(range.index, 'image', url);
        this.uploadingImage = false;
    }

    postComment() {

        this.onCommentPost.emit(this.editorContent);
    }

    cancelPostComment() {
        //this.onCancelCommentEdit.emit(this.editorContent);
        this.onCancelCommentPost.emit(this.editorContent);
    }

    onContentChangedHandle(obj: any): void {
        if(obj.text.length <= 4000) {
            this.editorContent = obj.html;
            this.editorContentChange.emit(obj.html);
        } else{
            this.editor.quillEditor.deleteText(4000, obj.text.length);
            console.log('content is too long!')
        }
        console.log(obj)
    }

    onEditorBlur(e: any): void {
        if (this.editorBlur) {
            this.editorBlur.emit(e);
        }
    }

    onEditorFocus(e: any): void {
        this.state = 'open';
        if (this.editorFocus) {
            this.editorFocus.emit(e);
        }
        this.cdr.detectChanges();
    }

    setEditContent(body) {
        setTimeout(()=>{
            this.editor.quillEditor.setContents(this.editor.quillEditor.clipboard.convert(body) as any);
            this.editor.quillEditor.setSelection(this.editor.quillEditor.getLength() + 1 as any);
            //Unfocuses and then focuses editor so the toolbar appears
            this.onEditorBlur('e')
            this.onEditorFocus('e')
        }, 500)

    }

    quote(quote) {
        const authorId = quote.author.id;
        const authorName = quote.author.screen_name;
        const author = `<span class="ql-quote-author" data-id="${authorId}" data-value="${authorName}">&#8212; ${authorName}</span>`;
        const body = `${quote.body} <br>${author}`;
        this.editor.quillEditor.setSelection(this.editor.quillEditor.getLength());

        this.editor.quillEditor.updateContents([
            {
                retain: this.editor.quillEditor.getLength()
            },
            {
                insert: '\n'
            },
            {
                attributes: {
                    quoteContent: body
                },
                insert: {
                    quote: body
                }
            },
            {
                insert: '\n'
            }
        ] as any);
        this.editor.quillEditor.focus();
        this.editor.quillEditor.setSelection(this.editor.quillEditor.getLength() + 1 as any);
    }

    focus() {
        this.editor.quillEditor.focus();
        this.cdr.detectChanges();
    }

    onQuillKeyDown(e) {
        if (e.keyCode === 13 && this.enterSend && this.editorContent) {
            this.postComment();
        }
    }

    onEnterSend(value) {
        Cookie.set('enterSend', value + '');
    }
}
