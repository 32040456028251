import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Playlist } from '@app/models/playlist.model';

@Component({
  selector: 'app-playlist-modal',
  templateUrl: './playlist-modal.component.html',
  styleUrls: ['./playlist-modal.component.scss']
})
export class PlaylistModalComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  playlist: Playlist;
  modalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
  ) { }
  open(playlist) {
    this.playlist = playlist;
    this.openModal();
  }

  clearData() {
    this.playlist = null;
  }

  openModal() {
    this.modalRef = this.modalService.open(this.contentElem, {
      windowClass: 'ssc-modal',
      backdrop: 'static',
    });

    this.modalRef.result.then((result) => {
      this.clearData();
    }, (reason) => {
      this.clearData();
    });
  }


  close() {
    this.modalRef.close();
  }

  ngOnInit() {
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }
}
