<quill-editor #editor
              (onContentChanged)="onContentChangedHandle($event)"
              name="editorContent"
              id='quillEditor'
              (onEditorCreated)="onEditorCreated()"
              [placeholder]="placeholder"
              [modules]="modules"
              (focusIn)="onEditorFocus($event)"
              (keydown)="onQuillKeyDown($event)">
    <span quill-editor-toolbar *ngIf="hideToolbar"></span>
    <div *ngIf="!hideToolbar" quill-editor-toolbar>
        <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
        </span>
        <span class="ql-formats">
            <!-- <select class="ql-font"></select> -->
            <select class="ql-size" *ngIf="fontSize"></select>
        </span>
        <span class="ql-formats">
            <button class="ql-link"></button>
            <button class="ql-image"></button>
            <!-- <button class="ql-video"></button> -->
            <!--<button class="ql-ssvideo"></button>-->
        </span>
        <span class="ql-formats">
            <select class="ql-align"></select>
            <select class="ql-color"></select>
        </span>
        <span class="ql-formats">
            <button class="ql-emoji"></button>
        </span>
        
        <span class="ql-formats qlc-right" (click)="postComment()" *ngIf="showPostButton">
            <button class="qlc-post"></button>
        </span>
        <!--//COMMNET CANCEL-->
        <span class="ql-formats qlc-rightleft" (click)="cancelPostComment()">
            <button class="qlc-cancel"></button>
        </span>
    </div>
</quill-editor>
<div class="send-setting" [class.d-none]="state === 'close'" (clickOutside)="showSendSetting = false" *ngIf="showSetting">
    <app-icon-setting size="18" (click)="showSendSetting = true" hoverColor="#262628" color="#D0D0D0"></app-icon-setting>
   
    <div class="dropdown-menu" *ngIf="showSendSetting">
        <!-- Add close button to dialog box -->
        <div style="text-align: right"  (click)="showSendSetting = false" ><app-icon-close [title]="Close"></app-icon-close></div>
        <h2>Pressing Enter Key will:</h2>
        <label class="form-check-inline" (click)="onEnterSend(true)">
            <input class="form-check-input" type="radio" name="mode" [value]="true" [(ngModel)]="enterSend">
            <span>Send message</span>
        </label>
        <label class="form-check-inline" (click)="onEnterSend(false)">
            <input class="form-check-input" type="radio" name="mode" [value]="false" [(ngModel)]="enterSend">
            <span>Add a line break</span>
        </label>
    </div>
</div>
<div id="ghost"></div>