import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-search-big',
  templateUrl: './icon-search-big.component.html',
  styleUrls: ['./icon-search-big.component.scss']
})
export class IconSearchBigComponent implements OnInit {
  @Input() color: string = '#262628';
  @Input() size: number = 18;

  constructor() { }

  ngOnInit() {
  }
  
}
