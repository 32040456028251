import { Component, OnInit, Input } from '@angular/core';
import {UtilService} from '@app/core';

@Component({
  selector: 'app-ss-snapicon-preview',
  templateUrl: './ss-snapicon-preview.component.html',
  styleUrls: ['./ss-snapicon-preview.component.scss']
})
export class SsSnapiconPreviewComponent implements OnInit {
  @Input() user: any = {};
  @Input() showLevel = false;
  @Input() showStatus = true;
  @Input() isSmall = false;
  @Input() timestamp: string;
  @Input() showName = false;
  constructor(private util: UtilService) { }

  ngOnInit() {
    this.user.photo = this.util.addWidthIfImgIx(this.user.photo, 100);
  }

  getSnapicons() {
    if (!this.user) {
      return [];
    }
    const { snapicons = {} } = this.user;
    return Object.keys(snapicons);
  }

}
