<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 32 32" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <polygon id="path-reaction"
        points="14.25 9.75 9.75 9.75 9.75 14.25 8.25 14.25 8.25 9.75 3.75 9.75 3.75 8.25 8.25 8.25 8.25 3.75 9.75 3.75 9.75 8.25 14.25 8.25">
      </polygon>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="03---My-Playlists---Playing" transform="translate(-921.000000, -333.000000)">
        <g id="Info-Card" transform="translate(902.000000, 125.000000)">
          <g id="Reaction" transform="translate(20.000000, 205.000000)">
            <g id="Add" transform="translate(0.000000, 4.000000)">
              <path
                d="M15,30 C23.2842712,30 30,23.2842712 30,15 C30,6.71572875 23.2842712,0 15,0 C6.71572875,0 0,6.71572875 0,15 C0,23.2842712 6.71572875,30 15,30 Z"
                id="Oval" stroke="#58C7C9" stroke-width="2" stroke-linejoin="round" stroke-dasharray="1"></path>
              <g id="ic_add" transform="translate(6.000000, 6.000000)">
                <g>
                  <polygon id="Shape" points="0 0 18 0 18 18 0 18"></polygon>
                  <mask id="mask-reaction" fill="white">
                    <use xlink:href="#path-reaction"></use>
                  </mask>
                  <use id="Mask" fill="#58C7C9" xlink:href="#path-reaction"></use>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>