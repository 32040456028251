<div class="app-icon">
    <svg [attr.width]="size" [style.width]="size" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <polygon id="path-plus" points="19 13 13 13 13 19 11 19 11 13 5 13 5 11 11 11 11 5 13 5 13 11 19 11"></polygon>
        </defs>
        <g id="ic_add" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g>
                <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-plus"></use>
                </mask>
                <use id="Mask" [attr.fill]="color" xlink:href="#path-plus"></use>
            </g>
        </g>
    </svg>
</div>
