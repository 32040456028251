import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-user-online-icon',
  templateUrl: './user-online-icon.component.html',
  styleUrls: ['./user-online-icon.component.scss']
})
export class UserOnlineIconComponent implements OnInit {
  @Input() size = 'big';
  @Input() online = false;
  
  constructor() {}

  ngOnInit(): void {}
}
