<div id='seeMoreContainer'>
  <div class='headerContainer'>
    <div class='title'>
      <app-icon-button color="white" (click)="back()" >
      <app-icon-arrow-left color="gray"></app-icon-arrow-left>
      </app-icon-button>
      <h4>Sing - Artists</h4>
    </div> 
 
  </div>
   <ul class='letterList' >
    <li *ngFor='let letter of letters'>
      <button *ngIf='selectedLetter == letter' class='letterButton selectedLetter' (click)='handleFilter(letter)'>{{letter}}</button>
      <button *ngIf='selectedLetter != letter' class='letterButton' (click)='handleFilter( letter)'>{{letter}}</button>
    </li>
  </ul> 


  <app-ss-featured-grid
  [scrollable]="false"
  title="Sing - Artists"
  seeMorePage="SingArtists"
  [hasSeeMore] = "false"
  [cols]="3"
  [colsLg]="3"
  [colsMd]="2"
  [colsSm]="1"
  [extendedSize] = "true"
  [list]="artistsData.items"
  [sortOptions]="artistSortOptions"
  [loading]="artistsData.loading"
  (onScrollEnd)="onScrollEndHandle()"
  (onOrderBy)="resetDataObj(artistsData);setSortFields($event);onScrollEndHandle()"
  >
  <ng-template #itemContent let-item>
    <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
      <app-ss-artist [artist]="item" [spread]="false"></app-ss-artist>
      <div class="song-actions col-auto">
        <div class="featured-list--item-action align-items-center" (click)="navigateArtist(item?.id)">
          <!-- <app-icon-button [shadow]="true" [routerLink]="['/d/artists/', item?.id]" role="button"> -->
          <app-icon-button style="margin-right: -0.2rem" [shadow]="true" (click)="navigateArtist(item?.id)" role="button" title="Go to Artist Profile">
            <app-icon-arrow-right></app-icon-arrow-right>
          </app-icon-button>
        </div>
        <app-more-dot-menu title="Options">
          <div class="dropdown-menu-content">
            <div (click)="onFavArtist(item)" *ngIf="!item.favorited">Add to Favorites</div>
            <div (click)="onUnfavArtist(item)" *ngIf="item.favorited">Remove from Favorites</div>
          </div>
        </app-more-dot-menu>
      </div>
    </div>
  </ng-template>
  </app-ss-featured-grid>
  <app-vert-space height="40"></app-vert-space>


   <div  id="pageNavContainer"  style="margin-bottom: 13px;">
    <button *ngIf='artistsData.currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
    <p >{{currentPage}}</p>
    <button *ngIf='artistsData.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
  </div>  
</div>


 



