<div class="container play-wrapper">
  <!-- top ad -->
<div *ngIf="!loggedInIsGold" style="text-align:center; margin-bottom: 6px;">
  <ng-adsense 
  [adClient]="'ca-pub-4776037543768273'" 
  [adSlot]="9989671476" 
  [adFormat]="'horizontal'"
  ></ng-adsense></div>
  <div class="ssc-wrapper">
    <div class="ssc-list-content" infinite-scroll [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle" (scrolled)="getComments()">
      <div class="ms-header container-fluid" *ngIf="backlink && backlink.length > 0">
        <div class="row">
          <div class="col-12 d-flex align-self-center">
            <h5 class="back-title">
              <app-icon-button color="white" [shadow]="true" [routerLink]="[backlink]">
                <app-icon-arrow-left color="black"></app-icon-arrow-left>
              </app-icon-button>
              <app-breadcrumbs>
                <app-breadcrumb top [link]="backlink" [title]="'Back to contest'">
                </app-breadcrumb>
              </app-breadcrumbs>
            </h5>
          </div>
        </div>
      </div>

      <div class="player-holder" *ngIf="recording; else skeleton">
        <app-recording-player
          *ngIf="recording"
          [id]="recordingId"
          [rec]='recording'
          [showHeader]="false"
          [shuffle]="shuffle"
          [continuousPlay]="continuousPlay"
          [showControl]="true"
          (onLove)="onLoveHandle($event)"
          (onPrev)="onPrev()"
          (onNext)="onNext($event)"
          (onReplay)="onReplay()"
          [showReplay]="showReplay"
          [isFirst]="current===0"
          [isLast]="current===ids.length - 1"
          (onShuffle)="onShuffle($event)"
        >
        </app-recording-player>
      </div>
      <div class="ql-editor-styles description" *ngIf="recording?.info" [innerHTML]="recording?.info | safeHtml"></div>

      <!--Test information Display   -->
      <!-- <span style="color:crimson;"> Loaded: {{ids.length}}/{{totalRecordings}} Current Song: {{current + 1}}</span> -->

      <!-- top ad -->
      <div *ngIf="!loggedInIsGold" style="text-align:center; margin-bottom: 6px;">
        <ng-adsense 
        [adClient]="'ca-pub-4776037543768273'" 
        [adSlot]="9989671476" 
        [adFormat]="'horizontal'"
        ></ng-adsense></div>

      <div class="col-12 recording-body-details" *ngIf="recording?.allow_comments">
        <!-- <app-comments *ngIf="recordingId" [comments]="comments" [commentsTotal]="commentsTotal" [pagination]="'paginated'"
          [recordingId]="recordingId" [loading]="loadingComments" (editorFocus)="onEditorFocused($event)"
          (editorBlur)="onEditorBlurred($event)">
        </app-comments> -->
        <app-comments [comments]="comments" *ngIf="recordingId" [pagination]="'paginated'" [commentsTotal]="commentsTotal" [recordingId]="recordingId">
        </app-comments>
      </div>
    </div>
    <div class="ssc-sidebar">
      <div class="recording-details">
        <div *ngIf="recording">
          <div class="recording-side-header">
            <div class="recording-users">
              <div class="recording-user" *ngFor="let user of recording.users" (click)="onProfile(user)">
                <img [src]="user.photo + '?h=100'" />
                <app-user-online-icon [online]="user.online" size="xs"></app-user-online-icon> 
                <app-ss-user-name [user]="user"></app-ss-user-name>
              </div>
            </div>
            <div class="recording-icons">
              <app-icon-privacy-ic size="20" *ngIf="recording?.icons.ic"></app-icon-privacy-ic>
              <app-icon-privacy-memberonly size="20" *ngIf="recording?.members_only"></app-icon-privacy-memberonly>
              <app-icon-privacy-contest size="20" *ngIf="recording?.icons.contest"></app-icon-privacy-contest>
              <app-icon-privacy-private size="20" *ngIf="recording?.icons.private"></app-icon-privacy-private>
              <app-icon-privacy-public size="20" *ngIf="recording?.icons.public  && !recording?.members_only"></app-icon-privacy-public>
            </div>
          </div>
          <div class="recording-artist">
            <div class="recording-artist-detail">
              <div class="recording-artist__title makeClickable" (click)="howOthersSingThis(recording?.song.id)" title="How Others Sing This">{{recording?.song.title}}</div>
              <div class="recording-artist__name makeClickable" (click)="navArtistProfile(recording?.song.artist.id)" title="Go to Artist Profile Page">{{recording?.song.artist.name}}</div>
            </div>
            <div class="ss-snap-credit-icon" (click)="openSnapModal()">
              <img src="/assets/pictures/ic_gold_snap.png" />
            </div>
          </div>

          <div class="box form-dropdown" *ngIf="this.recordingsLayers.items.length">
            <div ngbDropdown  #listDrop="ngbDropdown">
               <div
               class="btn form-dropdown-block lab" 
               id="dropdownLayers"
               ngbDropdownAnchor
               (click)="listDrop.toggle()"
               >View Layers
            </div> 
                 <div ngbDropdownMenu aria-labelledby="dropdownLayers" >
                  <span> <button  class="wrapper" 
                    ngbDropdownItem
                    *ngFor="let item of this.recordingsLayers.items.slice().reverse(); let e = index" (click)="selectRecording(item)">
                        <span class="item-1"  >Layer {{this.recordingsLayers.items.length - e}}: </span>
                        <div class="item-2">
                        <app-ss-user-name  [user]="item.user" [showTrinkets]="false" [showStatus]= "false" [noLink] = "false" [disabled] = "true"></app-ss-user-name>
                        </div>
                        <div class="item-3">
                         <app-icon-time size="14" color="rgba(0, 0, 0, 0.4)" title="{{item?.created_at | dateFormat | date:'MMM d, yyyy h:mma' | dateAmpm}}"   > </app-icon-time>
                        </div>
                        <div class="item-4">
                         <div style="color: rgba(0, 0, 0, 0.4);">{{item?.created_at | dateFormat | date:'MMM d, yyyy h:mma' | dateAmpm}}</div>   
                        </div>
                        <div class="item-5">
                       <app-icon-button [shadow]="true" role="button" size="22px" color="#58c7c9"><app-icon-play color="white" size="20"></app-icon-play></app-icon-button> 
                      </div>
                  </button> </span>
                 </div>
 
             </div>
         </div>

          <div class="recording-extra">
            <div class="recording-extra__time">
              <app-time-stamp [time]="recording?.created_at" [format]="'MMM d, yyyy hh:mma'">
              </app-time-stamp>
            </div>
            <div class="recording-extra__stat">
              <app-icon-comment color="#D0D0D0"></app-icon-comment>
              <span>{{formatComments(commentsTotal)}}</span>
            </div>
            <div class="recording-extra__stat">
              <app-icon-eye size="24" color="#D0D0D0"></app-icon-eye>
              <span>{{formatViews(recording?.stats?.views)}}</span>
            </div>
          </div>
          <div class="recording-reactions">
            <div class="recording-reactions__emoji">
              <app-reaction #listenReaction [reactionList]="reactionList" [reactions]="reactions"></app-reaction>
            </div>
            <div class="recording-reactions__love">
              <app-icon-heart class="btn-heart"  (click)="toggleLove()" color="#D0D0D0" ></app-icon-heart>
              <span>{{formatLove(recording?.stats?.love)}}</span>
            </div>
          </div>
          <div class="recording-share">
            <div class="recording-share-item" ngbDropdown>
              <app-icon-plus color="#D0D0D0"></app-icon-plus>
              <span ngbDropdownToggle>ADD TO ...</span>
              <div class="ssc-title-options" ngbDropdownMenu>
                <div class="dropdown-orderby">
                  <div class="dropdown-menu-content">
                    <div *ngIf="!recording?.favorited" (click)="favRecording(recording)">Add to Favorites</div>
                    <div *ngIf="recording?.favorited" (click)="unFavRecording(recording)">Remove from Favorites</div>
                    <div *ngIf="!recording.users[0].notified && profileService.userId != recording?.user_id" (click)="subscribeUser(recording)">Notify Me Next Time User Sings</div>
                    <div *ngIf="recording.users[0].notified" (click)="unsubscribeUser(recording)">Unsubscribe from User</div>
                    <div (click)="onAddtoPlaylist()">Add to Playlist</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="recording-share-item" (click)="handleSing(recording.song)">
              <app-icon-mic color="#D0D0D0" size="14"></app-icon-mic>
              <span>SING</span>
            </div>
            <div class="recording-share-item">
              <app-icon-share color="#D0D0D0"></app-icon-share>
              <span>SHARE</span>
            </div>
            <!-- <div class="recording-share-item" (click)="handleDuet(recording)">
              <app-icon-duet color="#D0D0D0"></app-icon-duet>
              <span>DUET / JAM</span>
            </div> -->
            <div class="recording-share-item">
              <app-icon-warning color="#D0D0D0"></app-icon-warning>
              <span>REPORT</span>
            </div>
            <div class="recording-share-item" (click)="sendGift()">
              <app-icon-atm color="#D0D0D0"></app-icon-atm>
              <span>SEND A GIFT</span>
            </div>
            <hr style="width:100%; color:rgba(0, 0, 0, 0.1); margin-top:0rem; margin-bottom:0.5rem">
            <button *ngIf="profileService.userId != recording?.user.id" class="SnapATip" (click)="sendSnap()">  <img class="snapIcon" width="18px"src="/assets/pictures/ic_gold_snap.png" />SNAP A TIP!</button> 
          </div>
        </div>
      </div>
      <app-ss-featured-list
        [type]="'recording'"
        [data]="recordings"
        [title]="'Playlist'"
        (loadMore)="loadRecordings()"
        [checkOwnRecording]="true"
        [userId]="profileService.userId"
      >
        <ng-template #itemContent let-item let-indx="indx">
          <div class="ssc-list-item" title="Play: {{indx+1}}" style="cursor: pointer" (click)="onPlayRecording(item, indx)" [ngClass]="{'active': indx === current}">
            <app-ss-recording [linkTitle]="false" [recording]="item"></app-ss-recording>
            <div class="song-actions">
              <!-- <app-icon-button (click)="onPlayRecording(item)" size="40px" color="#58c7c9">
                <app-icon-play color="white"></app-icon-play>
              </app-icon-button> -->
              <app-more-dot-menu title="Options">
                <div class="dropdown-menu-content">
                  <div *ngIf="!item?.favorited" (click)="favRecording(item)">Add to Favorites</div>
                  <div *ngIf="item?.favorited" (click)="unFavRecording(item)">Remove from Favorites</div>
                  <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                  <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                  <!-- <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div> -->
                  <div (click)="onRemoveFromPlaylist(item.pivot_id)">Remove from Playlist</div>
                  <div *ngIf="profileService.userId == item.user_id" [routerLink]="['/d/my-recordings', item.id]">Edit Recording Info</div>
                  <div (click)="shareRecording(item)">Share this recording</div>
                  <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                </div>
              </app-more-dot-menu>
            </div>
          </div>
          <app-recording-stat [recording]="item"></app-recording-stat>
        </ng-template>
      </app-ss-featured-list>
      <div *ngIf="!loggedInIsGold" style="text-align:center;">
        <ng-adsense 
        [adClient]="'ca-pub-4776037543768273'" 
        [adSlot]="4474696313" 
        [display]="'block'"
        [adFormat]="'autorelaxed'"
        ></ng-adsense></div>
    </div>
    <app-add-to-playlist #playlist></app-add-to-playlist>
    <app-purchase-gold-modal #goldcontent type="2"></app-purchase-gold-modal>
    <app-confirmation-modal #confirmation></app-confirmation-modal>
    <app-snap-credit-modal #snap></app-snap-credit-modal>
  </div>
  <div *ngIf="!loggedInIsGold" style="text-align:center; margin-top: 6px;">
    <ng-adsense 
    [adClient]="'ca-pub-4776037543768273'" 
    [adSlot]="4474696313" 
    [display]="'block'"
    [adFormat]="'autorelaxed'"
    ></ng-adsense></div>
</div>

<ng-template #skeleton>
  <div class="skeleton-player-holder skeleton-color"></div>
</ng-template>

<ng-template #skeletonSideBar>
  <div class="skeleton-ssc-sidebar">
    <div class="recording-details">
      <div class="recording-side-header">
        <div class="recording-users">
          <div class="recording-user">
            <div class="skeleton-img-round skeleton-color"></div>
            <div class="skeleton-usersname skeleton-color"></div>
            <div class="skeleton-img-round-small skeleton-color"></div>
          </div>
          <div class="recording-artist">
            <div class="recording-artist-detail">
              <div class="skeleton-title skeleton-color"></div>
              <div class="skeleton-artist skeleton-color"></div>
            </div>
          </div>
          <div class="recording-extra">
            <div class="skeleton-date skeleton-color"></div>
            <div class="skeleton-block skeleton-color"></div> 
          </div>
          <div class="recording-reactions">
            <div class="skeleton-img-round skeleton-color"></div>
            <div class="skeleton-smallBlock skeleton-color"></div>
          </div>
          <div class="recording-share">
            <div class="recording-share-item" ngbDropdown>
              <app-icon-plus color="#D0D0D0"></app-icon-plus>
              <span ><div class="skeleton-text skeleton-color"></div></span>
              <div class="ssc-title-options" ngbDropdownMenu>
              </div>
            </div>
            <div class="recording-share-item">
              <app-icon-mic color="#D0D0D0" size="14"></app-icon-mic>
              <span ><div class="skeleton-text skeleton-color"></div></span>
            </div>
            <div class="recording-share-item " [ngClass]="''" >
              <app-icon-share color="#D0D0D0"></app-icon-share>
              <span ><div class="skeleton-text skeleton-color"></div></span>
            </div>
            <!-- <div class="recording-share-item" >
              <app-icon-duet color="#D0D0D0"></app-icon-duet>
              <span ><div class="skeleton-text skeleton-color"></div></span>
            </div> -->
            <div class="recording-share-item">
              <app-icon-warning color="#D0D0D0"></app-icon-warning>
              <span ><div class="skeleton-text skeleton-color"></div></span>
            </div>
            <div class="recording-share-item">
              <app-icon-atm color="#D0D0D0"></app-icon-atm>
              <span ><div class="skeleton-text skeleton-color"></div></span>
            </div>
            <hr style="width:100%; color:rgba(0, 0, 0, 0.1); margin-top:0rem; margin-bottom:0.5rem">
            <div class="skeleton-snapATip skeleton-color"></div>
          </div>
        </div>
      </div>
     </div>
    </div>
</ng-template>