<div class="body_home cl_boxCont">
    <div class="main">
        <div class="ssb-navbar">
                <span class="singsnap_logo" [routerLink]="['']">
                        <img src="../../../../assets/pictures/logo.png" alt="logo" class="logo"><span class="_singsnap">SingSnap</span>
                    </span>
            <span class="ssb-actions pull-right"><img (click)="isHamburgerVisible = !isHamburgerVisible"
                                                      [src]="isHamburgerVisible ? '../../../../assets/pictures/webSite/menu_black.png' : '../../../../assets/pictures/webSite/menu.png'"
                                                      alt="burger" class="burger_menu"></span>
            <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                                                                         [routerLink]="['/sign-up']">SIGN UP</a></span>
            <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                                                                         [routerLink]="['/login']">LOGIN</a></span>
        </div>
        <div (mouseout)="hideHamburger($event)" class="hamburger" *ngIf="isHamburgerVisible">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="ssb-navbar">

                    <span class="ssb-actions pull-right"><img (click)="isHamburgerVisible = !isHamburgerVisible"
                                                              [src]="isHamburgerVisible ? '../../../../assets/pictures/webSite/menu_black.png' : '../../../../assets/pictures/webSite/menu.png'"
                                                              alt="burger" class="burger_menu"></span>
                        <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                                                                                     [routerLink]="['/sign-up']">SIGN UP</a></span>
                        <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                                                                                     [routerLink]="['/login']">LOGIN</a></span>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 menu_content">
                    <span [routerLink]="['/main/home']"><span class="fat_line"></span> home</span><br>
                    <span [routerLink]="['/main/aboutUs']"><span class="fat_line"></span> about us</span><br>
                    <span [routerLink]="['/main/pricing']"><span class="fat_line"></span> pricing</span><br>
                    <span [routerLink]="['/main/blog']"><span class="fat_line"></span> blog</span><br>
                    <span [routerLink]="['/dashboard']"><span class="fat_line"></span> app</span><br>
                    <!-- hidding store from website -->
                    <!-- <span [routerLink]="['/main/shop']"><span class="fat_line"></span> store</span><br> -->
                    <span [routerLink]="['/main/support']"><span class="fat_line"></span> support</span><br>
                    <span [routerLink]="['/main/influencer']"><span class="fat_line"></span> influencer</span><br>
                    <span (click)="onLogOut()" *ngIf="userIsLoged"><span class="fat_line"></span> log out</span><br>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 social-links">
                    <img src="../../../../assets/pictures/webSite/hamburger_facebook.png" alt="">
                    <img src="../../../../assets/pictures/webSite/hamburger_youtube.png" alt="">
                    <img src="../../../../assets/pictures/webSite/hamburger_twitter.png" alt="">
                    <img src="../../../../assets/pictures/webSite/hamburger_instagram.png" alt="">
                </div>
            </div>
        </div>
        <div class="main_content">
            <div class="col-md-offset-3">
                <!-- <span class="helpCenter" [@routeAnimation]="">Help Center</span> -->
                <span class="helpCenter">Help Center</span>
                <!-- <span class="searchOurHelpCenter" [@routeAnimation]="">Search our help center for quick answers</span> -->
                <span class="searchOurHelpCenter">Search our help center for quick answers</span>
                <!-- <div class="input-group searchField" [@routeAnimation]=""> -->
                <div class="input-group searchField">
                    <input type="text" class="form-control" placeholder="Start typing to see suggestions">
                    <span class="input-group-btn">
                        <button class="btn btn-default" type="button">SEARCH</button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row articles text-center">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6  articleHolder">
            <!-- <div class="article" [@routeAnimation]=""> -->
            <div class="article">
                <img src="../../../../assets/pictures/webSite/support/Support-memership.png" alt="" class="img-fluid">
                <div class="text">
                    <span class="heading">Membership</span>
                    <span class="seeArticles">See articles ></span>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6  articleHolder">
            <!-- <div class="article" [@routeAnimation]=""> -->
            <div class="article">
                <img src="../../../../assets/pictures/webSite/support/Support-Plans.png" alt="" class="img-fluid">
                <div class="text">
                    <span class="heading">Plans & Fees</span>
                    <span class="seeArticles">See articles ></span>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6  articleHolder">
            <!-- <div class="article" [@routeAnimation]=""> -->
            <div class="article">
                <img src="../../../../assets/pictures/webSite/support/Support-Contests.png" alt="" class="img-fluid">
                <div class="text">
                    <span class="heading">Contest</span>
                    <span class="seeArticles">See articles ></span>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6  articleHolder">
            <!-- <div class="article" [@routeAnimation]=""> -->
            <div class="article">
                <img src="../../../../assets/pictures/webSite/support/Support-Security.png" alt="" class="img-fluid">
                <div class="text">
                    <span class="heading">Security & Privacy</span>
                    <span class="seeArticles">See articles ></span>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6  articleHolder">
            <!-- <div class="article" [@routeAnimation]=""> -->
            <div class="article">
                <img src="../../../../assets/pictures/webSite/support/Support-geting_sterted.png" alt="" class="img-fluid">
                <div class="text">
                    <span class="heading">Getting Started</span>
                    <span class="seeArticles">See articles ></span>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6  articleHolder">
            <!-- <div class="article" [@routeAnimation]=""> -->
            <div class="article">
                <img src="../../../../assets/pictures/webSite/support/Support-Settings.png" alt="" class="img-fluid">
                <div class="text">
                    <span class="heading">Account Settings</span>
                    <span class="seeArticles">See articles ></span>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6  articleHolder">
            <!-- <div class="article" [@routeAnimation]=""> -->
            <div class="article">
                <img src="../../../../assets/pictures/webSite/support/Support-Bussines.png" alt="" class="img-fluid">
                <div class="text">
                    <span class="heading">Business</span>
                    <span class="seeArticles">See articles ></span>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6  articleHolder">
            <!-- <div class="article" [@routeAnimation]=""> -->
            <div class="article">
                <img src="../../../../assets/pictures/webSite/support/Support-Developers.png" alt="" class="img-fluid">
                <div class="text">
                    <span class="heading">Developers</span>
                    <span class="seeArticles">See articles ></span>
                </div>
            </div>
        </div>
    </div>
    <div class="support-video">
        <img src="../../../../assets/pictures/webSite/support/Support-Video.png" alt="" class="img-fluid video">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-holder">
            <!-- <div class="play"><i class="fa fa-play" aria-hidden="true"  [@routeAnimation]=""></i></div> -->
            <div class="play"><i class="fa fa-play" aria-hidden="true"></i></div>
            <!-- <div [@routeAnimation]=""> -->
            <div>
                <span class="title">Getting started?</span><br>
                <span class="body">Your stage is waiting</span>
            </div>
        </div>
    </div>
    <div class="frequentlyAskedQuestions">
        <!-- <h3 [@routeAnimation]="">Frequently Asked Questions</h3> -->
        <h3>Frequently Asked Questions</h3>
        <div *ngFor="let f of faq; let e = index" class="questions col-md-offset-2 col-md-8 col-sm-9" [ngClass]="{ 'questions-active' : active == e }" (click)="selectQuestion(i)">
            <!-- <div [@routeAnimation]=""> -->
            <div>
            <span class="pull-left icon-background" [ngClass]="{ 'icon-background-active' : active == e }">
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
            </span>
                <span class="question-text">
                {{ f }}
            </span>
            </div>
        </div>
    </div>
    <div class="row mail">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center">
            <!-- <img src="../../../../assets/pictures/webSite/support/Support-Letter.png" alt="" [@routeAnimation]=""> -->
            <img src="../../../../assets/pictures/webSite/support/Support-Letter.png" alt="">
            <!-- <div class="text-holder" [@routeAnimation]=""> -->
            <div class="text-holder">
                <span class="cant-find-answer">Can't find your answer?</span><br>
                <span class="text">We want to answer all your queries. Get in touch and we'll get you back as soon as we can. <a href="">Contact us ></a></span>
            </div>
        </div>
    </div>
</div>
