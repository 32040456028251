import { EventEmitter, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import { HttpService } from './http.service';

@Injectable()
export class ListenService extends HttpService {
  sortListenGenresSongs = new EventEmitter();
  loadArtistData = new EventEmitter();
  private reloadListenSongs = new Subject<any>();
  reloadListenSongsData$ = this.reloadListenSongs.asObservable();
  private reloadListenArtists = new Subject<any>();
  reloadListenArtistsData$ = this.reloadListenArtists.asObservable();
  private emitChangeSource = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();
  private reloadListenDuets = new Subject<any>();
  reloadListenDuetsData$ = this.reloadListenDuets.asObservable();
  private reloadListenGenres = new Subject<any>();
  reloadListenDuetsGenres$ = this.reloadListenGenres.asObservable();
  private reloadListenMembers = new Subject<any>();
  reloadListenDuetsMembers$ = this.reloadListenMembers.asObservable();

  constructor(private http: HttpClient) {
    super();
  }

  // Service message
  emitChange(id: any) {
    this.emitChangeSource.next(id);
  }

  getUrl(url) {
    const options = this.getHeaders();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getSongs(nextPage) {
    const options = this.getHeaders();
    return this.http
      .get(nextPage, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getListenArtists(nextPage) {
    const options = this.getHeaders();
    return this.http
      .get(nextPage, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getSelectedArtistSongs(artistId, query) {
    const options = this.getHeaders();
    return this.http
      .get(this.makeUrl(`/artists/${artistId}/songs`, { query }), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getRecordings(query) {
    const options = this.getHeaders();
    return this.http
      .get(this.makeUrl(`/recordings/`, { query }), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getSelectedArtistOpenDuets(url) {
    const options = this.getHeaders();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getChoosenSongsByUserId(url) {
    const options = this.getHeaders();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getMembers(url) {
    const options = this.getHeaders();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getSelectedArtist(url) {
    const options = this.getHeaders();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getLoggedUserMembers(url) {
    const options = this.getHeadersForAuth();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getLoggedUserFans(url) {
    const options = this.getHeadersForAuth();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getListenGenres(url) {
    const options = this.getHeaders();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getGenres() {
    const options = this.getHeaders();
    return this.http
      .get(this.makeUrl('/genres'), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getGenreSongs(genreId, perPage, sorter) {
    const options = this.getHeaders();
    return this.http
      .get(
        this.makeUrl(
          '/genres/' + genreId + '/songs/?page=1' + perPage + sorter
        ),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getDataForDesiredGenre(url) {
    const options = this.getHeaders();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  reloadAllListenArtists(data: any) {
    this.reloadListenArtists.next(data);
  }

  reloadAllListenSongs(data) {
    this.reloadListenSongs.next(data);
  }

  reloadAllListenDuets(data) {
    this.reloadListenDuets.next(data);
  }

  reloadAllListenGenres(data) {
    this.reloadListenGenres.next(data);
  }

  reloadAllListenMembers(data) {
    this.reloadListenMembers.next(data);
  }

  sendComment(body, recordingId) {
    const options = this.getHeaders();
    return this.http
      .post(this.makeUrl(`/recordings/${recordingId}/comments`), body, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getComments(recordingId) {
    const options = this.getHeaders();
    return this.http
      .get(this.makeUrl(`/recordings/${recordingId}/comments`), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
}
