<div class="container my-recordings">
    <div [ngClass]="{'cl_display-list':confirmModal, 'cl_hide-list':!confirmModal}">
        <div  class="confirmationModal"></div>
        <div role="document" class="modalWindow">
            <div class="modalContent">
                <span class="confirm-close" (click)="closeConfirmationModal()">
                    <app-icon-close size="24"></app-icon-close>
                </span>            
                <span class="modalTitle">
                    <h3 class="modalTitle--size">You're changing {{selectedRecordings.length}} {{pluralRecordings}}</h3>
                    <h3 class="modalTitle--size"> to {{selectedActionNames}}. Press "Continue"</h3>
                    <h3 class="modalTitle--size">to confirm.</h3>
                </span>
                <div class="buttonConfirmationModal">
                    <button class="confirmBtn" id="confirmationBtn" (click)="confirmActions()">Confirm</button>
                    <button class="confirmBtn" id="confirmationBtn" (click)="closeConfirmationModal()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <!-- ads side -->
    <!-- <div  *ngIf="!loggedInIsGold" class="placeAdLeft">
        <ng-adsense
        [adClient]="'ca-pub-4776037543768273'"
        [adSlot]="9024407308"
        [adFormat]="'vertical'"
        ></ng-adsense>
      </div> -->
    <!-- <div  *ngIf="!loggedInIsGold" class="placeAdRight">
        <ng-adsense
        [adClient]="'ca-pub-4776037543768273'"
        [adSlot]="9024407308"
        [adFormat]="'vertical'"
        ></ng-adsense>
      </div> -->
    <div class="row">
       <div class="col-12 col-lg-9">
           <div class="row">
               <div class="col-3">
                   <h1 class="big-title">My Recordings</h1>
                   <!-- <p>You have a total of {{user?.stats?.private_recordings}} recordings</p> -->
                   <p> </p>
                   <p style="font-weight:600; color: #000;">{{filterMessage}}</p>
                   <p style="width: 634px;font-size: 12px;">Recordings still processing will appear in gray. Refresh your screen to see when they're ready.</p>
               </div>
               <div class="col-3">
                   <div class="ss-tabs">
                       <div class="ss-tabs-item" [ngClass]="{'active': true}" [routerLink]="['/d/my-recordings']"
                       >
                       Uploaded
                       </div>
                       <div class="ss-tabs-item" [ngClass]="{'active': !true}" [routerLink]="['/d/my-recordings-recycle-bin']"
                       >
                       Recycle Bin
                       </div>
                   </div>
               </div>
               <div class="col-6">
                   <app-search-bar-input (onSearch)="onSearchHandle($event)" [query]="searchTerm">
                   </app-search-bar-input>
               </div>
           </div>
       </div>

       <div  class="col-12 col-lg-9" id="pageNavContainerTop"  style="font-weight: 400; color: black;">
        <button (click)="getPrevRecordings()">&lt; PREV</button>
         <p *ngIf='totalPages != 1'>Page: </p><select style="border-color:#D6D6D6;" name="page-dropdown" #pageDropdown (change)="onPageChangeHandle($event.target.value)">
          <option *ngFor="let page of pageArray;" [value]="page">{{page}}</option>
          </select><p *ngIf='totalPages != 1'> / {{totalPages}}</p>
        <button (click)="getNextRecordings()">NEXT &gt;</button>
       </div>

       <div class="col-12 col-1g-9 top-section">
        <span [ngClass]="{'active': active === 'upload'}" (click)="displayUploaded()" style="text-transform: none;">{{totalRecordingsShowingForFilter}} {{totalRecordingsForFilter}} Recordings: </span>
        <span  class="yearDisplay" style="padding-left:5px;" >
            Year:
                <select  [(ngModel)]="year" name="years-dropdown" id="years-dropdown" (change)="yearsClick($event.target.value)">
                    <option *ngFor="let item of yearsArray;let e = index" [value]="item">{{item}}</option>
                </select>
        </span>
         </div>

       <div class="col-12 col-lg-9">
            <!-- <div class="backdrop-loading" [ngClass]="{'d-none': !data.rqing}">
               <i class="fa fa-spinner" aria-hidden="true">
               </i>
           </div> -->
           <!-- ads in-feed -->
           <!-- <div *ngIf="!loggedInIsGold">
            <ng-adsense
            [adClient]="'ca-pub-4776037543768273'"
            [adSlot]="8177906096"
            [display]="'block'"
            [adFormat]="'fluid'"
            [layoutKey]="'-fb+5w+4e-db+86'"
            [height]="80"
            [fullWidthResponsive]="true"
            ></ng-adsense>
           </div> -->

           <app-featured-list #myRecordingsList
                              [showHeader]="false"
                              [featuredList]="data.items"
                              [loading]="data.rqing"
                              skeletonType="myRecordings"
                              [noRow]="true"
                              [scrollable]="false"
                              [isInfiniteScroll]="false"
                              (onViewChange)="onPageChangeHandle()">
               <h2 class="featured-list--header">
                   <!-- <span [ngClass]="{'active': active === 'upload'}" (click)="displayUploaded()">UPLOADED:  {{totalTest}} Recordings</span> -->
                   <!--<span [ngClass]="{'active': active === 'draft'}" (click)="displayDraft()">DRAFTS</span>-->
                   <form name="myForm" ng-controller="ExampleController">
                        <span id="checkSelector">
                            <input type="checkbox" [checked]="false" #selectedAllCheckBox class="selectedAllCheckBox"
                                                    id="selectedAll" (change)="selectedAll(selectedAllCheckBox.checked)">
                            <div class="selectingAll"></div>
                            <span class="selectedCount" (change)="selectedAll(selectedAllCheckBox.checked)">({{selectedRecordings.length}} Selected)</span>
                        </span>
                   </form>
                   <span  class="actionsDisplay" style="padding-left:5px;" >
                           <select   name="actions-dropdown" id="actions-dropdown" (change)="actionsClick($event.target.value)">
                               <option *ngFor="let item of actionsArray;let e = index" [selected]="e==0" [value]="item">{{item}}</option>
                           </select>
                           <button class="confirmBtn" (click)="openConfirmationModal()">Confirm</button>
                   </span>        
                   <div class="my-recordings-filters">
                       <span (click)="setFilter('public')" title="Public">
                           <app-icon-privacy-public [color]="filterType == 'public' ? 'rgb(88, 199, 201)' : 'rgb(208, 208, 208)'"></app-icon-privacy-public>
                       </span>
                       <span (click)="setFilter('member')" title="Member's Only">
                           <app-icon-privacy-memberonly [color]="filterType == 'member' ? 'rgb(88, 199, 201)' : 'rgb(208, 208, 208)'">
                           </app-icon-privacy-memberonly>
                       </span>
                       <span (click)="setFilter('private')" title="Private ">
                           <app-icon-privacy-private [color]="filterType == 'private' ? 'rgb(88, 199, 201)' : 'rgb(208, 208, 208)'">
                           </app-icon-privacy-private>
                       </span>
                       <span>
                           |
                       </span>
                       <span (click)="setGroup('ic')" title="Inner Circles">
                           <app-icon-privacy-ic [color]="groupIc ? 'rgb(88, 199, 201)' : 'rgb(208, 208, 208)'"></app-icon-privacy-ic>
                       </span>
                       <span (click)="setGroup('contest')" title="Contests">
                           <app-icon-privacy-contest [color]="groupContest ? 'rgb(88, 199, 201)' : 'rgb(208, 208, 208)'"></app-icon-privacy-contest>
                       </span>
                   </div>
                   <div ngbDropdown class="d-inline-block" id="recordings-dropdown">
                       <span class="align-items-center d-flex" id="recordings-sort" ngbDropdownToggle>
                           <app-icon-time *ngIf="orderBy.type === 'created_at'" color="white"></app-icon-time>
                           <app-icon-sort-alpha-up *ngIf="orderBy.type === 'song.title' && orderBy.direction === 'ASC'" color="white"></app-icon-sort-alpha-up>
                           <app-icon-sort-alpha-down *ngIf="orderBy.type === 'song.title' && orderBy.direction === 'DESC'" color="white"></app-icon-sort-alpha-down>
                           <app-icon-flame *ngIf="orderBy.type === 'popularity' && orderBy.direction === 'DESC'" color="white"></app-icon-flame>
                           <app-icon-arrow-down color="#FFFFFF" size="24"></app-icon-arrow-down>
                       </span>
                       <div ngbDropdownMenu class="dropdown-orderby" aria-labelledby="recordings-sort">
                            <div
                                class="dropdown-item"
                                [ngClass]="{'selected': orderBy.type === 'popularity' && orderBy.direction === 'DESC'}"
                                (click)="handleOrderBy('popularity', 'DESC')">
                                <span>Popularity</span>
                                <!--<i class="fa fa-arrow-down" *ngIf="orderDirection === 'ASC'"></i>
                                <i class="fa fa-arrow-up" *ngIf="orderDirection === 'DESC'"></i>-->
                            </div>
                           <div
                               class="dropdown-item"
                               [ngClass]="{'selected': orderBy.type === 'created_at' && orderBy.direction === 'DESC'}"
                               (click)="handleOrderBy('created_at', 'DESC')">
                               <span>Newest to Oldest</span>
                               <!--<i class="fa fa-arrow-down" *ngIf="orderDirection === 'ASC'"></i>
                               <i class="fa fa-arrow-up" *ngIf="orderDirection === 'DESC'"></i>-->
                           </div>
                           <div
                           class="dropdown-item"
                           [ngClass]="{'selected': orderBy.type === 'created_at' && orderBy.direction === 'ASC'}"
                           (click)="handleOrderBy('created_at', 'ASC')">
                           <span>Oldest to Newest</span>
                           <!--<i class="fa fa-arrow-down" *ngIf="orderDirection === 'ASC'"></i>
                           <i class="fa fa-arrow-up" *ngIf="orderDirection === 'DESC'"></i>-->
                       </div>
                           <div
                               class="dropdown-item"
                               [ngClass]="{'selected': orderBy.type === 'song.title' && orderBy.direction === 'ASC'}"
                               (click)="handleOrderBy('song.title', 'ASC')">
                               <span>A-Z (Song Name)</span>
                               <!--<i class="fa fa-arrow-down" *ngIf="orderDirection === 'ASC'"></i>
                               <i class="fa fa-arrow-up" *ngIf="orderDirection === 'DESC'"></i>-->
                           </div>
                            <div
                            class="dropdown-item"
                            [ngClass]="{'selected': orderBy.type === 'song.title' && orderBy.direction === 'DESC'}"
                            (click)="handleOrderBy('song.title', 'DESC')">
                                <span>Z-A (Song Name)</span>
                                <!--<i class="fa fa-arrow-down" *ngIf="orderDirection === 'ASC'"></i>
                                <i class="fa fa-arrow-up" *ngIf="orderDirection === 'DESC'"></i>-->
                            </div>
                        </div>
                   </div>
               </h2>
               <ng-template #itemContent let-item>
               
                   <div class="my-recording-item" class="ss-list--item {{ customClass }}"  [ngClass]="{ 'is-mine': checkMine(item), 'is-processing': checkProcessing(item) }">
                        <span id="selector">
                            <input type="checkbox"  [checked]="selectAll" #selectedCheckBox class="selectedCheckBox"  id="selected" (change)="selectRecording(item.id)">
                        </span>
                       <app-ss-recording  [isProcessing]="checkProcessing(item)" [max]="3" [recording]="item"></app-ss-recording>
                       <!-- <div class="col-auto px-0 align-items-center">
                           <div class="featured-list--item--poster rounded"
                                [ngStyle]="{'background-image': 'url('+item?.cover+')'}">
                               <img [src]="item?.photo" alt="" class="img-fluid">
                           </div>
                       </div>
                       <div class="col featured-item--body align-items-center">
                           <h1>{{item?.song.title | truncateText:24:'...'}} <br>
                               <app-ss-user-name [user]="item.users[0]" [showLevel]="false"></app-ss-user-name>
                           </h1>
                       </div> -->
                       <div class="recording-stats">
                           <span class="timestamped">
                               {{item?.created_at | dateFormat | date:'MMM d, yyyy hh:mma' | dateAmpm}}
                           </span>
                           <div class="recording-counter">
                               <app-icon-heart size="10px" [color]="item?.allow_loves ? activeColor : iconColor"></app-icon-heart>
                               <div *ngIf="item?.allow_loves; else track_loves_na">{{formatLove(item?.stats?.love)}}</div>
                               <ng-template #track_loves_na> </ng-template>

                           </div>
                           <div class="recording-counter">
                               <app-icon-comment size="12px" [color]="item?.allow_comments ? activeColor : iconColor"></app-icon-comment>
                               <div *ngIf="item?.allow_comments; else track_comments_na">{{formatComments(item?.stats?.comments)}}</div>
                               <ng-template #track_comments_na> </ng-template>
                           </div>
                           <div class="recording-counter">
                               <app-icon-eye  size="14px" [color]="item?.track_views ? activeColor : iconColor"></app-icon-eye>
                               <div *ngIf="item?.track_views; else track_views_na">{{formatViews(item?.stats?.views)}}</div>
                               <ng-template #track_views_na> </ng-template>
                           </div>
                       </div>
                       <div class="recording-privacy">
                           <span class="recording-privacy-badge" title="Members only"
                                   *ngIf="item?.members_only">
                               <app-icon-privacy-memberonly size="20"></app-icon-privacy-memberonly>
                           </span>
                           <span class="recording-privacy-badge" title="Private"
                                   *ngIf="item?.icons.private">
                               <app-icon-privacy-private size="20"></app-icon-privacy-private>
                           </span>
                           <span class="recording-privacy-badge" title="Public"
                                   *ngIf="item?.icons.public && !item?.members_only">
                               <app-icon-privacy-public size="20"></app-icon-privacy-public>
                           </span>
                           <span class="recording-privacy-badge" title="Inner Circle"
                                   *ngIf="item?.icons.ic">
                               <app-icon-privacy-ic size="20"></app-icon-privacy-ic>
                           </span>
                           <span class="recording-privacy-badge" title="Contest"
                                   *ngIf="item?.icons.contest">
                               <app-icon-privacy-contest size="20"></app-icon-privacy-contest>
                           </span>
                       </div>
                       <div class="d-flex">
                            <div *ngIf="item?.processing == true; else processingFalse" >
                                <app-icon-button  role="button" [shadow]="true" (click)="showProcessingMessage()"
                                                size="40px"  color="#ACACAC" title="Still Processing">
                                    <app-icon-play color="#7F7F7F"></app-icon-play> 
                                </app-icon-button>
                            </div>
                            <ng-template #processingFalse>
                                <app-icon-button [routerLink]="['/d/listen/', item?.id]" role="button" [shadow]="true"
                                    size="40px" color="#58c8ca" title="Play">
                                <app-icon-play color="white"></app-icon-play>
                                </app-icon-button>
                            </ng-template>
                            <app-more-dot-menu>
                                <div class="dropdown-menu-content">
                                    <div (click)="onEdit(item)">Edit Recording Info</div>
                                    <div *ngIf="!item?.favorited" (click)="favRecording(item)">Add to Favorites</div>
                                    <div *ngIf="item?.favorited" (click)="unFavRecording(item)">Remove from Favorites
                                    </div>
                                    <div (click)="deleteRecording(item)">Delete Recording</div>
                                    <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                                    <!-- <div *ngIf="item?.allow_duets" [routerLink]="['/duet-recorder/', item?.id]">
                                        Duet/Jam with this Recording
                                    </div> -->
                                    <div *ngIf="true && checkYear(item)" [routerLink]="['/remixer', item?.id]">
                                        Remix Recording
                                    </div>
                                    <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                                </div>
                           </app-more-dot-menu>
                       </div>
                   </div>
               </ng-template>
           </app-featured-list>
           <div  id="pageNavContainer"  style="font-weight: 400; color: black; margin-bottom: 13px;">
             <button (click)="getPrevRecordings()">&lt; PREV</button>
                <!-- <p *ngIf='totalPages != 1'>Page: </p><select  name="page-dropdown" #pageDropdown (change)="onPageChangeHandle($event.target.value)">
                <option *ngFor="let page of pageArray;"  [value]="page">{{page}}</option>
                </select><p *ngIf='totalPages != 1'> / {{totalPages}}</p> -->
                <p>Page: {{currentPage}}  / {{totalPages}}</p>
             <button (click)="getNextRecordings()">NEXT &gt;</button>
           </div>
       </div>
       <div  *ngIf="!loggedInIsGold" class="placeAdRight">
        <ng-adsense
        [adClient]="'ca-pub-4776037543768273'"
        [adSlot]="9024407308"
        [adFormat]="'vertical'"
        ></ng-adsense>
      </div>
   </div>
    <!-- ads bottom -->
    <div *ngIf="!loggedInIsGold" style="text-align:center;">
        <ng-adsense 
        [adClient]="'ca-pub-4776037543768273'" 
        [adSlot]="4474696313" 
        [display]="'block'"
        [adFormat]="'autorelaxed'"
        ></ng-adsense></div>
</div>

<app-add-to-playlist #playlist></app-add-to-playlist>
<app-confirmation-modal #confirmation></app-confirmation-modal>
