<div class="app-icon">
  <svg id="Group_10" data-name="Group 10" xmlns="http://www.w3.org/2000/svg" [style.width]="size" [attr.height.px]="size" viewBox="0 0 58 58">
    <circle id="Oval" cx="29" cy="29" r="29" fill="#3a5997" />
    <g id="facebook-logo" transform="translate(23 16)">
      <path id="Path"
        d="M7.319,7.509V5.534a4.227,4.227,0,0,1,.043-.688,1.314,1.314,0,0,1,.194-.48.79.79,0,0,1,.487-.327,3.566,3.566,0,0,1,.9-.09h2.034V0H7.72A5.389,5.389,0,0,0,3.667,1.3,5.373,5.373,0,0,0,2.435,5.131V7.509H0v3.949H2.435V22.917H7.319V11.458H10.57L11,7.509Z"
        fill="#fff" />
    </g>
  </svg>
</div>
