<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path
        d="M11,2 L12.725,3.725 L10.5575,5.8775 L11.6225,6.9425 L13.775,4.775 L15.5,6.5 L15.5,2 L11,2 Z M2,6.5 L3.725,4.775 L5.8775,6.9425 L6.9425,5.8775 L4.775,3.725 L6.5,2 L2,2 L2,6.5 Z M6.5,15.5 L4.775,13.775 L6.9425,11.6225 L5.8775,10.5575 L3.725,12.725 L2,11 L2,15.5 L6.5,15.5 Z M15.5,11 L13.775,12.725 L11.6225,10.5575 L10.5575,11.6225 L12.725,13.775 L11,15.5 L15.5,15.5 L15.5,11 Z"
        id="icon-fullscreen"></path>
    </defs>
    <g id="Icons/Play" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlink:href="#icon-fullscreen"></use>
      </mask>
      <use id="Shape" [attr.fill]="color" xlink:href="#icon-fullscreen"></use>
      <!-- <g id="Black" mask="url(#mask-2)" [attr.fill]="color">
        <rect id="Color" x="0" y="0" width="18" height="18"></rect>
      </g> -->
    </g>
  </svg>
</div>