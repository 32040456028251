<div *ngIf='popup' id='popup-container'>
    <div id='roses-popup'>
        <div id='close-popup'><div (click)='togglePopup()'><svg _ngcontent-hrs-c416="" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" style="width: 18px;"><title _ngcontent-hrs-c416="">Close</title><desc _ngcontent-hrs-c416="">Created with Sketch.</desc><defs _ngcontent-hrs-c416=""><path _ngcontent-hrs-c416="" d="M10,8 L17.0066023,8 C17.5552407,8 18,8.44386482 18,9 C18,9.55228475 17.5550537,10 17.0066023,10 L10,10 L10,17.0066023 C10,17.5552407 9.55613518,18 9,18 C8.44771525,18 8,17.5550537 8,17.0066023 L8,10 L0.993397683,10 C0.444759292,10 0,9.55613518 0,9 C0,8.44771525 0.444946289,8 0.993397683,8 L8,8 L8,0.993397683 C8,0.444759292 8.44386482,0 9,0 C9.55228475,0 10,0.444946289 10,0.993397683 L10,8 Z" id="path-icon-close"></path></defs><g _ngcontent-hrs-c416="" id="Icons/Close" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><mask _ngcontent-hrs-c416="" id="mask-2" fill="white"><use _ngcontent-hrs-c416="" xlink:href="#path-icon-close"></use></mask><use _ngcontent-hrs-c416="" id="Combined-Shape" transform="translate(9.000000, 9.000000) rotate(45.000000) translate(-9.000000, -9.000000) " xlink:href="#path-icon-close" fill="#262628"></use></g></svg></div></div>
        <div id='rose-popup-container'>
            <div>Throw a Rose for 25 credits?</div>
            <div *ngIf='!needMoreCredits' id='button-container'>
                <div>
                    <button class='rose-button' id='cancel' (click)='togglePopup()'>CANCEL</button>
                    <button class='rose-button' (click)='purchaseRose(false)'>THROW</button>
                </div>
                <div>
                    <button class='rose-button' id='anon' (click)='purchaseRose(true)'>THROW ANONYMOUSLY</button>
                </div>
            </div>
            <div *ngIf='needMoreCredits' id='error-container'>
                <p>You don't have enough credits! <a [routerLink]="['/d/payments/credits']">Click here to buy more.</a></p>
            </div>
        </div>
    </div>
</div>
<div #roseContainer id='rose-container'></div>