<ng-template #content>
  <div class="modal-header align-items-center">
    <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true" [inlineSVG]="'/assets/svgs/close.svg'"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body-content">
      {{description}}
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="closeModal()">OK</button>
  </div>
</ng-template>
