<div class="snappenin-item" *ngIf="item">
  <a class="snappenin-item-image" (click)='navigate(item.id)' [routerLink]="['/d/listen/', item.id]"
    [ngStyle]="{'background-image': 'url('+item?.cover+'?h=200)'}">
    <!-- <app-icon-button size="40px" color="#58c7c9">  TONY
      <app-icon-play color="white"></app-icon-play>
    </app-icon-button> -->
  </a>
  <h1 class="snappenin-carousel-caption">
    <span class="ss-truncate">{{item.song?.title | truncateText:getTruncateValue(19):'...'}}
       <div  class="rec-tags" [class.tags-pill]="pill" *ngIf="item.badges != 0" [ngStyle]="getStyle()" > {{ getRecordingTag(19)}}  </div>
    </span>
    <span class="info-artist ss-truncate">{{item.song?.artist_name | truncateText:getTruncateValue(19):'...'}} </span>
    <app-ss-user-name *ngFor="let user of item.users | slice:0:2" [showTrinkets]="true" [showStatus]="true" [user]="user"></app-ss-user-name> 
  </h1>
</div>
