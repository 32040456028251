import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm-reset',
  templateUrl: './confirm-reset.component.html',
  styleUrls: ['./confirm-reset.component.scss']
})
export class ConfirmResetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
