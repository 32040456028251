import {Topic} from '@app/models/topic.model';
import {Playlist} from '@app/models/playlist.model';

export class Round {
  active: boolean;
  contest_id: string;
  contest_round_id: string;
  cover: string | any;
  created_at: string;
  description: string;
  ended_at: string;
  name: string;
  next_round_id?: string | number;
  participants_count: number;
  playlist: Playlist;
  playlist_id: number;
  prev_round_id: string;
  round_number: number;
  started_at?: string;
  submissions_count?: number;
  thread: Topic;
  thread_id: number;
  updated_at: string;
}
