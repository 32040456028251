<!-- <footer class='mainfooter' *ngIf="loaded">
  <ul>
    <li id='first'><p><a>Terms of Use</a></p></li>
    <li><p><a>Code of Conduct</a></p></li>
    <li><p><a>{{ formattedDate }}</a></p></li>
  </ul>
</footer> -->


<div *ngIf="loaded" class="ssb-footer">
  <div class="footer-lower">
      <div class="footer-lower-left">
        <span [routerLink]="['privacy']">Privacy</span>
        <span [routerLink]="['termsandconditions']">Terms & Conditions</span>
        <span [routerLink]="['codeofconduct']">Code of Conduct</span>
        <span [routerLink]="['partners']">Partners</span>
        <span [routerLink]="['copyright']">Copyright</span>
        <div id="dateContainer"><p id="date"> {{ formattedDate }}</p><span [routerLink]="['copyright']" id="copyright"><i class="fa fa-copyright" aria-hidden="true"></i>{{currentYear}} SingSnap</span>
        </div>
      </div>
      <div class="footer-lower-right footer-social">
        <a href="https://www.facebook.com/singsnap/"><i class="fa fa-facebook"></i></a>
        <a href="https://www.youtube.com/c/SingSnap/"><i class="fa fa-youtube"></i></a>
        <a href="https://twitter.com/singsnap"><i class="fa fa-twitter"></i></a>
        <a href="https://www.instagram.com/singsnapapp/"><i class="fa fa-instagram"></i></a>
      </div>
  </div>
</div>
