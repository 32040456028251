
<div class="player-container">

    <div class="top-app-buttons-group" *ngIf="showHeader">
        <div class="text-center">
            <h6>{{getTitle()}}</h6>
        </div>
    </div>
    <div class="parts__selector" style='z-index: 50;'></div>

    <div class="player-video-container">
        <div id='vg-container' style="position: relative">
            <vg-player
                id='vgPlayer'
                (onPlayerReady)="onPlayerReady($event)"
                style="height: 420px;"
            >
            <!-- <div #lyricWrapper class="ss-r-lyric-wrapper">
                <canvas class="ss-r-lyric-canvas" #lyric [ngClass]="{'preview-on': previewOn}" width="1000" height="170"></canvas>
              </div> -->
              <div id='lyrics-container' [hidden]="!lyrics">
                <div id='grave' class='hidden'></div>
                <main id='lines-container'>
                    <div id='the-grave' class='lyrics'></div>
                    <div id='swipe' class='lyrics'></div>
                    <div id='next-up' class='lyrics'></div>
                    <div id='initial' class='lyrics'></div>
                    <div id='the-womb' class='lyrics'></div>
                </main>
                <div id='womb' class='hidden'></div>
            </div>
                <vg-scrub-bar >
                    <vg-scrub-bar-current-time>
                    </vg-scrub-bar-current-time>
                    <vg-scrub-bar-buffering-time>
                    </vg-scrub-bar-buffering-time>
                </vg-scrub-bar>
                <vg-controls>
                    <vg-scrub-bar style="pointer-events: none;">
                    </vg-scrub-bar>

                    <div class="time-progress">
                        <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
                        /
                        <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
                    </div>
                </vg-controls>
                <!--<vg-buffering [vgFor]="listenMedia"></vg-buffering>-->
                <source src="https://googleads.g.doubleclick.net/pagead/ads?ad_type=standardvideo_text_image&client=ca-video-pub-4776037543768273&description_url=http%3A%2F%2Fdev-www.singsnap.com%2F%23%2Fd%2Flisten%2F&videoad_start_delay=0">
                <video id="listenMedia"
                        preload="auto"
                        crossorigin="anonymous"
                        #listenMedia
                        [vgMedia]="listenMedia"
                        [preload]="preload"
                        [src]="recordingUri"
                        (seeking)="seek()"
                        style="max-width: 835px; opacity: 0;"
                        (ended)="stop()">
                        
                </video>
                <div id="volumeContainer">
                    <p>Volume</p>
                    <!-- <input type='range' min='0' max='1' step='0.1' oninput="changeVolume(e)"> -->
                    <app-adjust-bar
                    [value]="volume"
                    [min]="0" [max]="1" [step]="0.1" [multiply]="1" [noFraction]="true" [noTicks]="true"
                    (changed)="volumeChange($event)">
                  </app-adjust-bar>
                </div>
                <div id="processing-poster" *ngIf="processing && !showReplay && !converting" [style.background-image]="'url('+ poster +')'"
                        style="position: absolute;top: 0;bottom: 0;left: 0;right: 0;background-repeat: no-repeat;background-size: contain;background-position: 50%;">
                    <p style="color: #fff; position: absolute; top: 36%; text-align: center; left: 0;right: 0;font-size: 1.8em;font-family: Roboto, sans-serif;">
                        Loading...
                    </p>
                </div>
                <div id="processing-poster" *ngIf="converting" [style.background-image]="'url('+ poster +')'"
                        style="position: absolute;top: 0;bottom: 0;left: 0;right: 0;background-repeat: no-repeat;background-size: contain;background-position: 50%;">

                </div>
                <div id="poster" *ngIf="poster && showAvatarOverlay && !processing" [style.background-image]="'url('+ poster +')'"
                        style="position: absolute;top: 0;bottom: 0;left: 0;right: 0;background-repeat: no-repeat;background-size: contain;background-position: 50%;"></div>
                <div class="play-extra">
                    <div class="btn-shuffle" *ngIf="showControl" (click)="toggleShuffle()" placement="top" ngbTooltip="Shuffle">
                        <app-icon-shuffle [color]="shuffle ? '#58c7c9' : 'white'"></app-icon-shuffle>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" id="rose-icon" size="25px" (click)='sendRose()' *ngIf="!processing && !firstPlay" data-name="rose icon4" width="28" height="28" viewBox="0 0 26.156 27.181">
                      <path id="Path_88" data-name="Path 88" d="M356.005,205.533c-.474-.512-.592-.72-.572-.94a.35.35,0,0,1,.076-.228c.351-.356,1.947.633,2.953,1.42.314.246.111.111.987.863a14.635,14.635,0,0,1,2.252,2.232c.027.037.054.079.054.079a6.97,6.97,0,0,1,.642,1.175c.035.082.1.249.168.471.39,1.246.476,2.862.839,3.87a5.609,5.609,0,0,0,.589,1.148.48.48,0,0,1,.062.087l.013.025c.241.492-1.085,2-1.085,2-1.6,1.819-1.1,1.741-2.253,2.867a13.1,13.1,0,0,1-2.531,1.954c-3.159,1.794-7.152,1.45-7.263.862s3.659-.652,6.52-3.846a9.292,9.292,0,0,0,2.462-5.258c.39-4.134-2.543-7.311-3.518-8.365C356.316,205.852,356.207,205.751,356.005,205.533Z" transform="translate(-337.942 -196.708)" fill="#be1e2d"/>
                      <path id="Path_89" data-name="Path 89" d="M25.022,9.255a10.278,10.278,0,0,0-.911-2.41,10.619,10.619,0,0,0-4.45-4.167c-.761-.408-1.9-.9-2.008-.948-.639-.276-1.187-.512-1.818-.719C13.5.242,13.095.879,11.345.254A4.314,4.314,0,0,0,9.413.046a2.726,2.726,0,0,0-.472.148c-.065.028-.123.055-.18.086a2.835,2.835,0,0,0-.815.594c-.5.494-3.051,2.19-3.7,2.672C2.007,5.216,1.026,8.379.676,9.508a15.066,15.066,0,0,0,.175,9.21c.141.45.677,2.161,1.063,2.106.646-.093-.744-4.908,1.605-9.39A17.212,17.212,0,0,1,7.254,6.946C8.9,5.378,9.748,4.589,11.059,4.364c1.007-.173,2.222.2,4.648.933A18.752,18.752,0,0,1,18.82,6.413a15.972,15.972,0,0,1,3.9,2.813C24.341,10.793,24.97,12,25.3,11.842,25.673,11.66,25.207,9.944,25.022,9.255Z" transform="translate(-0.03 -0.001)" fill="#be1e2d"/>
                      <path id="Path_90" data-name="Path 90" d="M25.022,9.255a10.278,10.278,0,0,0-.911-2.41,10.619,10.619,0,0,0-4.45-4.167c-.761-.408-1.9-.9-2.008-.948-.639-.276-1.187-.512-1.818-.719C13.5.242,13.095.879,11.345.254A4.314,4.314,0,0,0,9.413.046a2.726,2.726,0,0,0-.472.148c-.065.028-.123.055-.18.086a2.835,2.835,0,0,0-.815.594c-.5.494-3.051,2.19-3.7,2.672C2.007,5.216,1.026,8.379.676,9.508a15.066,15.066,0,0,0,.175,9.21c.141.45.677,2.161,1.063,2.106.646-.093-.744-4.908,1.605-9.39A17.212,17.212,0,0,1,7.254,6.946C8.9,5.378,9.748,4.589,11.059,4.364c1.007-.173,2.222.2,4.648.933A18.752,18.752,0,0,1,18.82,6.413a15.972,15.972,0,0,1,3.9,2.813C24.341,10.793,24.97,12,25.3,11.842,25.673,11.66,25.207,9.944,25.022,9.255Z" transform="translate(-0.03 -0.001)" fill="#be1e2d"/>
                      <path id="Path_91" data-name="Path 91" d="M85.5,248.261c.555.283-1.971,3.826-.713,7.705a7.585,7.585,0,0,0,2.021,3.153,10.08,10.08,0,0,0,8.316,2.125c.594-.115,1.156-.29,1.236-.13.169.339-1.865,1.971-4.206,2.865a9.868,9.868,0,0,1-4.388.785c-2.148-.155-2.752-.991-4.45-.854a1.4,1.4,0,0,1-1.105-.252c-.237-.236-.246-.552-.293-1.264-.027-.4-.071-1.011-.142-1.781,0,0-.089-.69-.128-1.278-.006-.082-.011-.176-.015-.287,0,0-.012-.334,0-.7a29.76,29.76,0,0,1,.25-3.021A14.656,14.656,0,0,1,82.66,252C83.489,249.893,85.087,248.052,85.5,248.261Z" transform="translate(-78.602 -239.031)" fill="#be1e2d"/>
                      <path id="Path_92" data-name="Path 92" d="M220.907,283.62c.387-.17,1.434,1.177,2.056,2.42a8.95,8.95,0,0,1,.868,2.887,8.77,8.77,0,0,1-.3,2.9,8.184,8.184,0,0,1-.545,1.192.942.942,0,0,1-.424.5,1.034,1.034,0,0,1-.6-.007,5.926,5.926,0,0,0-1.719.2,12.109,12.109,0,0,1-4.869-.148c-2.542-.714-4.551-2.884-4.276-3.427.077-.152.324-.16.742-.169,1.356-.029,1.856.5,3.177.793a5.989,5.989,0,0,0,2.851-.019,3.405,3.405,0,0,0,1.44-.691,3.977,3.977,0,0,0,1.1-1.988,8.507,8.507,0,0,0,.374-2.264C220.888,284.288,220.673,283.721,220.907,283.62Z" transform="translate(-203.261 -273.08)" fill="#be1e2d"/>
                      <path id="Path_93" data-name="Path 93" d="M209.57,164.559c.392-.124.327-1.76-.127-3.07a3.853,3.853,0,0,0-.886-1.6,4.65,4.65,0,0,0-2.135-1.083c-.991-.3-1.029-.084-1.681-.363a1.012,1.012,0,0,0-.725-.1.823.823,0,0,0-.392.321,4.531,4.531,0,0,1-.365.448c-.2.209-.324.33-.45.445a4.507,4.507,0,0,0-.8,1.072,7.556,7.556,0,0,0-.616,1.318,11.835,11.835,0,0,0-.7,2.423c-.244,1.564-.034,3.207.217,3.225.3.022.165-2.315,1.82-4.222A3.77,3.77,0,0,1,204.7,162c1.452-.342,3.068.77,3.92,1.638C209.173,164.2,209.357,164.626,209.57,164.559Z" transform="translate(-193.159 -152.438)" fill="#be1e2d"/>
                      <path id="Path_94" data-name="Path 94" d="M250.674,294.374c-.325-.142-1.139.987-1.524,1.666-.344.607-.189.526-.7,1.64-.265.575-.4.792-.3,1.027.114.28.451.33.926.48,1.3.411,1.276.836,2.13.991s1.93-.093,2-.469c.092-.52-1.727-.673-2.416-2.1S251.057,294.541,250.674,294.374Z" transform="translate(-238.926 -283.437)" fill="#be1e2d"/>
                      <path id="Path_95" data-name="Path 95" d="M349.713,300.718c-.114.087-.139.428-.189,1.109a3.393,3.393,0,0,0,.029,1.3,2.124,2.124,0,0,0,.784.909c.744.624,1.116.936,1.457.792.467-.2.651-1.116.5-1.776a1.918,1.918,0,0,0-.315-.675C351.343,301.44,350.15,300.383,349.713,300.718Z" transform="translate(-336.543 -289.496)" fill="#be1e2d"/>
                    </svg>
                    <div class="btn-heart" style='margin-right: 0.5rem;' (click)="toggleLove()" [ngClass]="{'active': rec?.loved}">
                        <app-icon-heart class="btn-heart" color="#707070" size='25px'></app-icon-heart>
                        <app-icon-heart-full color="rgb(249, 70, 120)" size='25px' class="btn-heart-full" style="display: none"></app-icon-heart-full>
                    </div>
                    <svg color="white" id='lyrics-toggle' size="25px" (click)='toggleLyrics()' *ngIf="!processing && !noLyrics" xmlns="http://www.w3.org/2000/svg" width="35.47" height="35.47" viewBox="0 0 35.47 35.47">
                        <path id="Path_29" data-name="Path 29" d="M0,0H35.47V35.47H0Z" fill="none"/>
                        <path id="Path_30" data-name="Path 30" d="M28.124,13.39H4.478a1.478,1.478,0,0,0,0,2.956H28.124a1.478,1.478,0,0,0,0-2.956ZM4.478,23.735H19.257a1.478,1.478,0,0,0,0-2.956H4.478a1.478,1.478,0,0,0,0,2.956ZM28.124,6H4.478A1.482,1.482,0,0,0,3,7.478v.015A1.482,1.482,0,0,0,4.478,8.971H28.124A1.482,1.482,0,0,0,29.6,7.493V7.478A1.482,1.482,0,0,0,28.124,6Z" transform="translate(1.434 2.867)" fill="#727272"/>
                      </svg>
                    <!-- <app-icon-button color="white" style="margin-left: 14px; margin-bottom: 2px;" size="25px" (click)='toggleLyrics()' *ngIf="!processing && !noLyrics">
                    A
                </app-icon-button> -->

                </div>
                <div class="recording-love-count"
                        [ngClass]="{'recording-love-count-active': rec?.loved}">
                        <!-- {{rec?.love_count}} -->
                </div>
                <div class="video-play-controls">
                    <div class="video-play-button video-play-button__small" *ngIf="showControl && !isFirst" (click)="onPrevClick()">
                        <app-icon-play-prev color="white"></app-icon-play-prev>
                    </div>
                    <div class="btn-placeholder" *ngIf="isFirst"></div>
                    <div class="video-play-button" (click)="replay()" *ngIf="showReplay || finishedLast">
                        <app-icon-restart color="white"></app-icon-restart>
                    </div>
                    <div class="video-play-button" (click)="play()" *ngIf="!finishedLast && !processing && !isState('playing')">
                        <app-icon-play-line color="white"></app-icon-play-line>
                    </div>
                    <div class="video-play-button" (click)="pause()" *ngIf="!processing && isState('playing')">
                      <app-icon-pause color="white"></app-icon-pause>
                  </div>
                    <div class="video-play-button video-play-button__small" *ngIf="showControl && !isLast" (click)="onNextClick()">
                        <app-icon-play-next color="white"></app-icon-play-next>
                    </div>
                    <div class="btn-placeholder" *ngIf="isLast"></div>
                </div>
                <div class="video-play-full">
                    <app-icon-fullscreen color="white" style="z-index: 1060" (click)="toggleFullscreen()"></app-icon-fullscreen>
                </div>
                <!-- <app-icon-button color="white" size="40px" (click)="pause()" *ngIf="!processing && isState('playing')"
                    class="btn-pause">
                    <app-icon-pause></app-icon-pause>
                </app-icon-button> -->
                <app-roses #roses [id]='rec.id'></app-roses>
            </vg-player>
        </div>

    </div>
    <!-- Show Play Time for Testing-->
    
</div>
