import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  ws: WebSocket;
  socketIsOpen = 1;

  createObservableSocket(url:string): Observable<any>{
    this.ws = new WebSocket(url);
    this.testConnection();
   
    return new Observable(
      observer => {
        this.ws.onmessage = (event) => observer.next(event.data);
        this.ws.onerror = (event) => observer.error(event);
        this.ws.onclose = (event) => observer.complete();
        return () => this.ws.close(1000, "The user is disconeccted");
      }
    );
  }

  createObservableSocketNotifications(user:string): Observable<any>{
    let url = environment.wsURL + user;
    this.ws = new WebSocket(url);
    this.testConnection();
   
    return new Observable(
      observer => {
        this.ws.onmessage = (event) => observer.next(event.data);
        this.ws.onerror = (event) => observer.error(event);
        this.ws.onclose = (event) => observer.complete();
        return () => this.ws.close(1000, "The user is disconeccted");
      }
    );
  }


  createObservableSocketRecordingViewCount(recording:string): Observable<any>{
    let url = environment.wsURL + recording;
    this.ws = new WebSocket(url);
    this.testConnection();
   
    return new Observable(
      observer => {
        this.ws.onmessage = (event) => observer.next(event.data);
        this.ws.onerror = (event) => observer.error(event);
        this.ws.onclose = (event) => observer.complete();
        return () => this.ws.close(1000, "The user is disconeccted");
      }
    );
  }



  sendMessage(message: string): string{
    if(this.ws.readyState === this.socketIsOpen){
     this.ws.send(message);
     return `Sent to server $(message)`;
    } else {
      return `Message not sent - the socket is closed`;
    }
  }

  testConnection(){
    if(this.ws.readyState === 1){
      this.ws.send("heartbeat");
    }
    setTimeout(() => {
        this.testConnection();
    }, 550000);
  }


}  

