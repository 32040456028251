import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAmpm'
})
export class DateAmpmPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.substr(0, value.length - 2) + value.substr(value.length - 2).toLowerCase();
  }

}
