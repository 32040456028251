<div class="profile-dropdown">
  <div ngbDropdown #profileDropdown="ngbDropdown">
    <div ngbDropdownToggle (click)="dropdownOpened()">
      <!-- Remove user Level from top menu bar
      <span>Level {{user?.level}}</span>-->
      <span> </span>
      <app-avatar-thumb  *ngIf="user" [showOnline]="false" [user]="user" (click)="$event.stopPropagation()" (click)='gotoProfile()'>
      </app-avatar-thumb>
      <i class="fa fa-bars" title="Menu"></i>
    </div>
    <div class="dd-content" ngbDropdownMenu aria-labelledby="dd-settings">
      <div class="dropdown-header" (click)="close()"  *ngIf="user">
        <app-ss-user
          [user]="user"
          [showLevel]="false"
          [showStatus]="false"
          [showName]="true"
          (click)='gotoProfile()'
        >
        </app-ss-user>
        <div class="user-credit" *ngIf="user">
          <div *ngIf="user.credits" class="ss-user--credit">
            <img src="/assets/pictures/icons/icon-credit-gold.svg" />
            <span>{{user.credits - rosesService.creditsDeducted}} - <span class="purchase-credit" (click)="purchaseCredit()">Purchase More</span></span>
          </div>
          <div *ngIf="!user.credits" class="ss-user--credit-empty">
            <span class="purchase-credit" (click)="purchaseCredit()">Purchase Credits</span>
          </div>
        </div>
      </div>
      <hr>
      <div class="dd-content-mobile">
        <button class="dropdown-item" routerLinkActive="active" [routerLink]="['/d/home']">Home</button>
        <button class="dropdown-item" routerLinkActive="active" [routerLink]="['/d/listen']">Listen</button>
        <button class="dropdown-item" routerLinkActive="active" [routerLink]="['/d/sing']">Sing</button>
        <button class="dropdown-item" routerLinkActive="active" [routerLink]="['/d/songbook']">Upload</button>
        <button class="dropdown-item" routerLinkActive="active" [routerLink]="['/d/message-board/categories']">Message Board</button>
        <button class="dropdown-item" routerLinkActive="active" [routerLink]="['/d/payments/membership']">SingSnap Store</button>
        <!-- <button class="dropdown-item">Help</button> -->
        <hr />
      </div>

      <button class="dropdown-item" routerLinkActive="active" [routerLink]="['/d/my-recordings']">My Recordings</button>
      <button class="dropdown-item" routerLinkActive="active" [routerLink]="['/d/my-comments/received']">My Recording Comments</button>
      <button class="dropdown-item" routerLinkActive="active" [routerLink]="['/d/ic/list']">My Inner Circles</button>
      <button class="dropdown-item" routerLinkActive="active" [routerLink]="['/d/my-playlists']">My Playlists</button>
      <button class="dropdown-item" routerLinkActive="active" [routerLink]="['/d/my-favorites']">My Favorites</button>
      <!-- <button class="dropdown-item" routerLinkActive="active" [routerLink]="['/d/contests']">Contests</button> -->
      <button class="dropdown-item" routerLinkActive="active" [routerLink]="['/d/chat']">Chat</button>
      <button class="dropdown-item" routerLinkActive="active" (click)='gotoProfile()'>Profile</button>
      <button class="dropdown-item" routerLinkActive="active" [routerLink]="['/d/settings']">Settings & Purchases</button>
      <button class="dropdown-item" (click)="logout()">Logout</button>
    </div>
  </div>
</div>
