import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { HttpModule } from '@angular/http';

import { ArtistService } from './services/artist.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthenticationService } from './services/authentication.service';
import { ContestService } from './services/contest.service';
import { GenresService } from './services/genres.service';
import { GlobaErrorHandlerService } from './services/globa-error-handler.service';
import { HttpService } from './services/http.service';
import { ListenService } from './services/listen.service';
import { LoginAuthGuardService } from './services/login-auth-guard.service';
import { PlaylistService } from './services/playlist.service';
import { PopUpService } from './services/pop-up.service';
import { ProfileService } from './services/profile.service';
import { RecorderService } from './services/recorder.service';
import { RecordingService } from './services/recording.service';
import { CustomRouteReuseStrategy } from './services/route-reuse-strategy.service';
import { SingService } from './services/sing.service';
import { SongService } from './services/song.service';
import { SortingService } from './services/sorting.service';
import { UploadService } from './services/upload.service';
import { UsersService } from './services/users.service';
import { PusherService } from './services/pusher.service';
import { UtilService } from './services/util.service';
import { EnvService } from './services/env.service';
import { MessageBoardService } from './services/message-board.service';
import { RouterExtService } from './services/router-ext.service';
import { InnerCircleService } from './services/inner-circle.service';
import { MessageService } from './services/message.service';
import { EventService } from './services/event.service';
import { ShopService } from './services/shop.service';
import { Data } from './services/data.service';
import { GoldGuardService } from '@app/core/services/gold-guard.service';
import { HelperService } from './services';
import { FailedService } from './services/failed.service';
import { EmailNotificationsService } from './services/email-notifications.service';


@NgModule({
  imports: [
    // angular
    CommonModule,
   // HttpModule,
  ],
  declarations: [],
  providers: [
    FailedService,
    ArtistService,
    AuthGuardService,
    GoldGuardService,
    AuthenticationService,
    ContestService,
    GenresService,
    GlobaErrorHandlerService,
    HttpService,
    ListenService,
    LoginAuthGuardService,
    PlaylistService,
    PopUpService,
    ProfileService,
    RecorderService,
    RecordingService,
    CustomRouteReuseStrategy,
    SingService,
    SongService,
    SortingService,
    UploadService,
    UsersService,
    PusherService,
    UtilService,
    EnvService,
    MessageBoardService,
    InnerCircleService,
    RouterExtService,
    MessageService,
    EventService,
    ShopService,
    HelperService,
    Data,
    EmailNotificationsService
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
