import { ErrorHandler, Injectable } from '@angular/core';
import { PathLocationStrategy } from '@angular/common';

@Injectable()
export class GlobaErrorHandlerService extends ErrorHandler {
  constructor() {
    super();
  }
  handleError(error) {
    const message = error.message ? error.message : error.toString();
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    console.log(message);
    console.log(url);
    throw error;
  }
}
