import { Observable } from 'rxjs';

export default class Paginator {
  loading;
  get: (page?: number) => Observable<any>;
  handleSuccess: (response: any) => void;
  handleError: (error: Error) => void;
  page = 1;
  nextPage = 1;
  perPage = 10;
  total = 1;
  items: Array<any>;
  isInfiniteScroll: boolean = false;

  constructor(options) {
    if (options.get === undefined) {
      throw new Error('options.get must be a function');
    } else {
      this.get = options.get;
    }
    if (options.handleSuccess === undefined) {
      throw new Error('options.handleSuccess must be a function');
    } else {
      this.handleSuccess = options.handleSuccess;
    }
    if (options.handleError === undefined) {
      throw new Error('options.handleError must be a function');
    } else {
      this.handleError = options.handleError;
    }

    if (options.isInfiniteScroll === undefined) {
      this.isInfiniteScroll = false;
    } else if (typeof options.isInfiniteScroll !== 'boolean') {
      throw new Error('options.isInfiniteScroll must be a boolean');
    } else {
      this.isInfiniteScroll = options.isInfiniteScroll;
    }

    if (typeof options.page === 'number') {
      this.page = options.page;
    } else {
      this.page = 1;
    }

    this.loading = false;
    this.perPage = options.perPage || this.perPage;
    this.total = options.total || this.total;
    this.nextPage = options.nextPage || this.nextPage;
    this.items = options.items || [];
  }

  request(page?) {
    if (page) {
    }

    console.log(this.page, page);

    if (!this.isInfiniteScroll || (this.isInfiniteScroll && this.nextPage)) {
      this.loading = true;
      const _page = page ? page : this.page + 1;
      console.log('paginator requesting... page:', _page);
      return this.get(_page).subscribe((response) => {
        this.loading = false;
        this.nextPage = response.next_page_url;
        this.page = response.current_page;
        this.handleSuccess(response);
      }, (error) => {
        this.loading = false;
        this.handleError(error);
      });
    }
  }

  refresh() {
    this.nextPage = 1;
    this.page = 1;
    this.loading = true;
    this.items = [];
    return this.get(1).subscribe((response) => {
      this.loading = false;
      this.nextPage = response.next_page_url;
      this.page = response.current_page;
      this.handleSuccess(response);
    }, (error) => {
      this.loading = false;
      this.handleError(error);
    });
  }
}
