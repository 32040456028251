import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-user-snapicon-carousel',
  templateUrl: './user-snapicon-carousel.component.html',
  styleUrls: ['./user-snapicon-carousel.component.scss']
})
export class UserSnapiconCarouselComponent implements OnInit {
  @Input() icons = [];
  @Input() size = 'big';
  dimension = 35;
  posRight = 20;
  posBottom = 5;

  constructor() { }

  ngOnInit() {
    if (this.size === 'small') {
      this.dimension = 20;
      this.posRight = 0;
      this.posBottom = 0;
    } else if (this.size === 'xs') {
      this.dimension = 14;
      this.posRight = 0;
      this.posBottom = 0;
    }
  }

}
