import {Component, OnInit, Input, ChangeDetectionStrategy, NgZone} from '@angular/core';
import { uniqBy } from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ss-recording',
  templateUrl: './ss-recording.component.html',
  styleUrls: ['./ss-recording.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsRecordingComponent implements OnInit {
  @Input() recording: any = {};
  @Input() showLevel = false;
  @Input() hideUser = false;
  @Input() max = 1;
  @Input() isProcessing = false;
  @Input() linkActive = true;
  //@Input() truncateLength = 29;
  @Input() truncateLength = 33;

  @Input()
  set recordingLink(link: string[]) {
    this.link = link;
  }
  @Input() loading = false;

  get recordingLink() {
    if (this.link && this.link.length > 0) {
      return this.link;
    } else {
      return ['/d/listen/' + this.recording.id];
    }
  }

  get users() {
    if (this.recording.users.length == 0 || typeof this.recording.users == 'undefined' || !this.recording.users) {
      if(typeof this.recording.user == 'undefined') {

      }

      return this.recording.user
    } else {

    return uniqBy(this.recording.users, 'id');
  }
  }

  link: string[];

  constructor(private ngZone: NgZone, private router: Router) {}

  public navigate(commands): void {

   if(this.linkActive){
    commands = [`${commands}`]
    this.ngZone.run(() => this.router.navigate(commands)).then();

   }
  }

  ngOnInit() {

  }
  loadPage(){
    // console.log(this.recordingLink)
    // this.navigate(this.recordingLink)
  }


  getRecordingTag(currentTruncateTextLength) {
   
    let tagType: string [];
    const  { badges={} } = this.recording;
    tagType = Object.keys(badges);
    if (!tagType.length){return;}
  

    // if( this.recording.badges < 1){return ;}
    let titleLength = this.recording.song.title.length;
    let tagTypeName = this.recording.badges.name;
    let tagTypeAmount : number ;
    let tagNameLong = "";
    let tagName = ""; 
    let truncateLength: number;

    tagTypeAmount = this.recording.badges.qty;
    tagNameLong = tagTypeName + (tagTypeAmount>2?  ":" + tagTypeAmount : '');
    
    if ( (titleLength + tagNameLong.length)  <= currentTruncateTextLength  ){
        tagName = tagNameLong;
    } else{
        tagName = tagTypeName[0] + (tagTypeAmount>2?  ":" + tagTypeAmount : '');
    }
    return tagName ;
  }
 
  setColor(firstLetter){
    let color: any;
    switch(firstLetter){
      case 'h':
      case 'd':
        color='#58C7C9';
        break;
      case 'j':
        color='#cd00cd';
        break;
    }
    return color;
  }

  getStyle(){

    let outColor: string;
    if(this.recording.badges != 0){
      outColor = this.setColor(this.recording.badges.name[0]);
      return {'color': outColor, 'border-color': outColor  }; 
    }
    return ;
  }
 
  getTruncateValue(currentTruncateTextLength){
    if( !this.recording.badges.name){return currentTruncateTextLength;}
    let titleLength = this.recording.song.title.length;
    let tagTypeName = this.recording.badges.name;
    let tagTypeAmount : number ;
    let tagNameLong = "";
    let tagName = ""; 
    let truncateLength: number;

    tagTypeAmount = this.recording.badges.qty;
    tagNameLong = tagTypeName + (tagTypeAmount>2?  ":" + tagTypeAmount : '');
    
    if ( (titleLength + tagNameLong.length)  <= currentTruncateTextLength  ){
        tagName = tagNameLong;
    } else{
        tagName = tagTypeName[0] + (tagTypeAmount>2?  ":" + tagTypeAmount : '');
    }
    return truncateLength = (currentTruncateTextLength - tagName.length);
  };



}
