import SSKaraoke from "./karaoke26829.js";
import Song from "./song.js";
export function initiateRecorder(workletPath, wasmPath, video, duetVideo, baseSong, jam, filterCanvas, framesPaths) {
  if (typeof baseSong.lyrics != "undefined") {
    if (baseSong.lyrics == null) {
      return "nullLyrics";
    }
  }
  let song = new Song(baseSong);
  const Rec = new SSKaraoke(workletPath, wasmPath, video, duetVideo, song, filterCanvas, framesPaths);
  return Rec;
}
