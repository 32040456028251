<div class="row">
    <div class="col-12 col-sm-8">
        <app-featured-list
            title="Inner circles"
            [scrollable]="false"
            [featuredList]="ics"
            [showSort]="false"
            [loading]="data.rqing"
            (onScrollEnd)="onScrollEndHandle(data, $event)"
            (onOrderBy)="resetDataObj(data);onScrollEndHandle(data, $event)">
            <ng-template #itemContent let-item>
                <div class="col-12 col-sm-7">
                    <div class="row">
                        <div [routerLink]="['/d/ic/', item.id]" class="col-12 d-flex text-left align-self-center flex-column px-0">
                            <h1>{{item.title}} <br>
                            <span>{{item.description}}</span></h1>
                        </div>
                    </div>
                </div>
                <div class="col-sm-5 d-none d-sm-flex justify-content-left">
                    <app-time-stamp [time]="item.created_at" [needWatch]="false" [format]="'MMM d, yyyy hh:mma'"></app-time-stamp>
                </div>
            </ng-template>
        </app-featured-list>
    </div>
</div>
