<ng-template #content>
  <div class="modal-header align-items-center">
    <h4 class="modal-title" id="modal-basic-title">Take Photo</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true" [inlineSVG]="'/assets/svgs/close.svg'"></span>
    </button>
  </div>
  <div class="modal-body">
    <webcam 
      [width]="525" 
      [height]="500"
      [trigger]="triggerObservable" 
      (imageCapture)="handleImage($event)"
      [allowCameraSwitch]="true" 
      [switchCamera]="nextWebcamObservable"
      [videoOptions]="videoOptions" 
      [imageQuality]="1"
      (initError)="handleInitError($event)">
    </webcam>
    <button class="btn btn-primary" (click)="triggerSnapshot()">Take a Photo</button>
    <div class="photo-preview" *ngIf="webcamImage">
      <h6>Preview</h6>
      <img [src]="webcamImage.imageAsDataUrl" />
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="closeModal()">OK</button>
  </div>
</ng-template>