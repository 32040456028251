import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class RosesService extends HttpService {

  constructor(private http: HttpClient) { super();}
  creditsDeducted = 0;
  sendRose(id, timestamp, anon) {
    const headers = this.getHeaders();
    const payload: any = { "type": "rose", "timestamp": parseInt(timestamp), "anonymous": anon };
    const url = this.makeUrl(`/recordings/${id}/gift`);
    return this.http.post(url, payload, headers)
    .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
  getRoses(id){
    const url = this.makeUrl(`/recordings/${id}/gift/roses`);
    const options = this.getHeaders();
    return this.http
      .get(url, options)
  }
  subtractCredits(amount){
    this.creditsDeducted += amount
  }
}
