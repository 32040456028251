<div class="app-icon" (mouseover)="onHover()" (mouseleave)="onLeave()">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <path d="M11,15 L13,15 L13,17 L11,17 L11,15 Z M11,7 L13,7 L13,13 L11,13 L11,7 Z M11.99,2 C6.47,2 2,6.48 2,12 C2,17.52 6.47,22 11.99,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 11.99,2 L11.99,2 Z M12,20 C7.58,20 4,16.42 4,12 C4,7.58 7.58,4 12,4 C16.42,4 20,7.58 20,12 C20,16.42 16.42,20 12,20 L12,20 Z"
      id="path-icon-warning"></path>
  </defs>
  <g id="ic_error_outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g>
      <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
      <mask id="mask-2" fill="white">
        <use xlink:href="#path-icon-warning"></use>
      </mask>
      <use id="Mask" [attr.fill]="mainColor" xlink:href="#path-icon-warning"></use>
    </g>
  </g>
  </svg>
</div>
