import {Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {ProfileService} from '@app/core';

@Injectable()
export class UserProfileResolver implements Resolve<any> {
    constructor(private service: ProfileService,
                private router: Router) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const id = route.params['id'];
        console.log(id, this.service.user.id);
/*
        if(this.service.user.id == id) {
            this.router.navigate(['/d/profile/me/info']);
        }
*/
    }
}
