import {Observable} from 'rxjs';

import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {ProfileService} from '@app/core';

@Injectable()
export class UserProfileFavoritesResolver implements Resolve<any> {

    constructor(public profileService: ProfileService,
                private router: Router) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const id = route.parent.paramMap.get('id');
        this.profileService.getUser(id)
            .subscribe(profileUser => {
                if (!profileUser.public_favorites) {
                    this.router.navigate(['/d/profile/' + id + '/info']);
                }
            });
    }


}
