<div class="app-icon">
  <svg id="messangers" xmlns="http://www.w3.org/2000/svg" [style.width]="size" [attr.height.px]="size" viewBox="0 0 58 58">
    <circle id="Oval" cx="29" cy="29" r="29" fill="#49c1cf" />
    <g id="chat-bubbles-with-ellipsis" transform="translate(15 20)">
      <path id="Path"
        d="M17.282,0h-.733V8.061a2.182,2.182,0,0,1-2.2,2.39H0v.383a2.585,2.585,0,0,0,2.558,2.23H13.531l4.2,2.468-.609-2.468h.161a1.981,1.981,0,0,0,1.88-2.23V1.914A1.727,1.727,0,0,0,17.282,0Z"
        transform="translate(7.615 5.809)" fill="#fff" />
      <path id="Shape"
        d="M1.909,18.172v0L2.8,14.783c-1.377-.129-2.8-.958-2.8-2.12V2.559C0,1.339,1.651,0,3.155,0H19.825a2.883,2.883,0,0,1,2.82,2.559v10.1c0,1.183-1.265,2.145-2.82,2.145H7.629L1.911,18.171ZM16.948,5.517a1.506,1.506,0,1,0,1.507,1.506A1.508,1.508,0,0,0,16.948,5.517Zm-5.625,0a1.506,1.506,0,1,0,1.506,1.506A1.508,1.508,0,0,0,11.323,5.517Zm-5.625,0A1.506,1.506,0,1,0,7.2,7.023,1.507,1.507,0,0,0,5.7,5.517Z"
        transform="translate(0 0.209)" fill="#fff" />
    </g>
  </svg>

</div>
