<div id='public'>

<app-auth-menu></app-auth-menu>
<app-top-message></app-top-message>

<div class="main_width">
    <router-outlet></router-outlet>


</div>

</div>
<app-footer></app-footer>
