import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Input } from '@angular/core';
import {ProfileService, Data} from '@app/core';
import { RosesService } from '@app/core/services/roses.service';
import { EmailNotificationsService } from '@app/core/services/email-notifications.service';

@Component({
  selector: 'app-roses',
  templateUrl: './roses.component.html',
  styleUrls: ['./roses.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RosesComponent implements OnInit {
  constructor(private profileService: ProfileService, private rosesService: RosesService, private data: Data, private emailNotificationsService: EmailNotificationsService) { }
  popup = false
  roses;
  currentRose = 0
  user;
  currentTime = 0;
  seeking = false
  needMoreCredits = false;
  @Input() id;
  @ViewChild('roseContainer', { static: true }) private roseContainer: ElementRef;
  ngOnInit(): void {
    this.user = this.profileService.user;
    this.rosesService.getRoses(this.id).subscribe(result => {
      this.roses = Object.entries(result['data'])
      console.log(this.roses)
      console.log(result)
      
    })
    // setTimeout(()=>{
    //   this.generateRose()
    //   console.log('creating rose')
    // }, 2000)
  }
  public async generateRose(username, pictureURI) {
    let margin = (Math.floor(Math.random() * 80) / 10) - 4
    let roseDiv = document.createElement('div')
    let rose = document.createElement('img')
    let userDiv = document.createElement('div')
    let user = document.createElement('div')
    let sentARose = document.createElement('div')
    let profilePicture = document.createElement('img')
    if(pictureURI != 'anon' && pictureURI != null) {
      profilePicture.src = pictureURI
      profilePicture.style.borderRadius = '45px'
    } else {
      profilePicture.src = '../../../assets/icons/icon-96x96.png'
      username = 'Anonymous'
    }
    profilePicture.classList.add('rose-profile-picture')
    
    rose.src = '../../../assets/images/rose.png'
    rose.classList.add('rose-picture')

    user.innerText = username
    user.classList.add('rose-user')
    sentARose.innerText = 'Threw a Rose!'
    sentARose.classList.add('sent-a-rose')
    userDiv.classList.add('rose-user-div')
    userDiv.appendChild(user)
    userDiv.appendChild(sentARose)
    userDiv.style.margin = '0'
    
    roseDiv.id = 'hidden'
    roseDiv.classList.add('rose')
    roseDiv.style.marginTop = margin + 'rem'

    roseDiv.appendChild(profilePicture)
    roseDiv.appendChild(userDiv)
    roseDiv.appendChild(rose)

    this.roseContainer.nativeElement.appendChild(roseDiv)
    setTimeout(()=>{
      roseDiv.id = 'shown'
      setTimeout(()=>{
        roseDiv.id = 'destroy'
        setTimeout(()=>{
          roseDiv.remove()
        }, 500)
      }, 1500)
    }, 100)

    
    
  }
  changeTime(time) {
    if(typeof this.roses != 'undefined') {
      this.currentTime = time * 1000
      if(typeof this.roses[this.currentRose] != 'undefined') {
        if(this.currentTime >= this.roses[this.currentRose][0] && !this.seeking) {
          console.log(this.roses[this.currentRose], this.currentRose)
          this.generateRose(this.roses[this.currentRose][1]['screen_name'], this.roses[this.currentRose][1]['photo'])
          this.currentRose += 1
        }
      }
      
    }
  }
  seek(time) {
    if(typeof this.roses != 'undefined') {
      let ms = time * 1000
      this.seeking = true
      console.log(ms, this.roses)
      this.currentRose = this.roses.findIndex((rose) => ms <= rose[0])
      if(this.currentRose == -1) {
        this.currentRose = this.roses.length
      }
      console.log(this.currentRose)
      this.seeking = false
    }

  }
  togglePopup () {
    this.popup = !this.popup
    if(this.popup) {
      this.roseContainer.nativeElement.style.opacity = 0
    } else {
      this.roseContainer.nativeElement.style.opacity = 1

    }
  }
  purchaseRose(anon) {
    this.rosesService.sendRose(this.id, this.currentTime, anon).subscribe(result => {
        this.togglePopup()
        console.log(result)
        this.rosesService.subtractCredits(25)
        if(anon) {
          this.generateRose('Anonymous', 'anon')
                  if(!this.data.roseSent){
                     // send email
                     if(Object.keys(this.data.recLayers).length > 1){
                      let recLayerList = '';
                      for(var i=0;i<Object.keys(this.data.recLayers).length;i++) {
                        if( i!= Object.keys(this.data.recLayers).length){
                          recLayerList += this.data.recLayers[i].name + ', ';
                        }
                      }
                      let newRecordingName = this.data.sendCommentData.recording_song_name + " featuring " + recLayerList.slice(0, -2);
                      newRecordingName = newRecordingName.substring(0, newRecordingName.lastIndexOf(', ')) + ' &' + newRecordingName.substring(newRecordingName.lastIndexOf(', ') + 1);
                      for(var i=0;i<Object.keys(this.data.recLayers).length;i++) {
                        if(!this.data.recLayers[i].isme && this.data.recLayers[i].newsletter){
                          this.emailNotificationsService.emailRoseFeaturedOnNotificationAnonymous (this.data.recLayers[i].email, this.data.recLayers[i].name, this.data.sendCommentData.sender_name, newRecordingName, this.data.sendCommentData.share_url);
                        }
                      }
                    } else {
                        if(this.data.sendCommentData.recipient_wants_newsletter){
                          this.emailNotificationsService.emailRoseNotificationAnonymous (this.data.sendCommentData.email, this.data.sendCommentData.recipient_name, this.data.sendCommentData.sender_name, this.data.sendCommentData.recording_song_name, this.data.sendCommentData.share_url);
                        }
                    }
                    this.data.roseSent = true;
                  }  
          
        } else {

          this.generateRose(this.user.screen_name, this.user.photo);

               this.data.roseSentCount = this.data.roseSentCount + 1;
              //  // send email
              //  if(Object.keys(this.data.recLayers).length > 1){
              //   let recLayerList = '';
              //   for(var i=0;i<Object.keys(this.data.recLayers).length;i++) {
              //     if( i!= Object.keys(this.data.recLayers).length){
              //       recLayerList += this.data.recLayers[i].name + ', ';
              //     }
              //   }
              //   let newRecordingName = this.data.sendCommentData.recording_song_name + " featuring " + recLayerList.slice(0, -2);
              //   newRecordingName = newRecordingName.substring(0, newRecordingName.lastIndexOf(', ')) + ' &' + newRecordingName.substring(newRecordingName.lastIndexOf(', ') + 1);
              //   for(var i=0;i<Object.keys(this.data.recLayers).length;i++) {
              //     if(!this.data.recLayers[i].isme && this.data.recLayers[i].newsletter){
              //       this.emailNotificationsService.emailRoseFeaturedOnNotification (this.data.recLayers[i].email, this.data.recLayers[i].name, this.data.sendCommentData.sender_name, newRecordingName, this.data.sendCommentData.share_url);
              //     }
              //   }
              // } else {
              //     if(this.data.sendCommentData.recipient_wants_newsletter){
              //       this.emailNotificationsService.emailRoseNotification (this.data.sendCommentData.email, this.data.sendCommentData.recipient_name, this.data.sendCommentData.sender_name, this.data.sendCommentData.recording_song_name, this.data.sendCommentData.share_url);
              //     }
              // }





        }
       }, error => {
         if(error.status = '503') {
           this.needMoreCredits = true;
         }
       });
  }
}
