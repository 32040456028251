<ng-template #content>
  <div class="modal-header align-items-center">
    <h4 class="modal-title" id="modal-basic-title">HEAR HOW OTHERS SING THIS</h4>
    <h4 class="modal-close" (click)="closeModal()">CLOSE</h4>
  </div>
  <div class="modal-body" *ngIf="!finished">
    <div *ngIf="!recordings.length" class="no-recordings">
      <span *ngIf="loading">Loading ...</span>
      <span *ngIf="!loading">Sorry, there are no recordings of this song yet.</span>
    </div>
    <div class="recordings-list" *ngIf="recordings.length">
      <div class="ssc-list-item" *ngFor="let item of recordings | slice:0:5">
        <app-ss-recording [recording]="item"></app-ss-recording>
        <div class="song-actions">
          <app-icon-button size="40px" color="#58c7c9" (click)="showRecording(item)">
            <app-icon-play color="white" size="18"></app-icon-play>
          </app-icon-button>
          <app-more-dot-menu>
            <div class="dropdown-menu-content">
              <div *ngIf="!item.favorited" (click)="favRecording(item)">Add to Favorites</div>
              <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove from Favorites</div>
              <div [routerLink]="['/recorder/', item?.song.id]" (click)="closeModal()">Sing This Song</div>
              <div *ngIf="addToPlaylist" (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
              <!-- <div [routerLink]="['/duet-recorder/', item.id]" (click)="closeModal()">Duet / Jam</div> -->
              <div (click)="shareRecording(item)">Share this recording</div>
            </div>
          </app-more-dot-menu>
        </div>
      </div>
    </div>
    <div *ngIf="recordings && recordings.length > 5">
      <div  class="featured-list--more SeeMoreLink" (click)="OthersSingSeeMore()">See More</div>
    </div>
  </div>
</ng-template>
