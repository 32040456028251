<label for="cover">
    {{title}}
    <span class="remove" style='cursor: pointer' (click)="clearFile()">Remove</span>
</label>
<!-- <ngx-file-drop dropZoneLabel="" id='cover' [ngStyle]="style" (onFileDrop)="dropped($event)" 
    (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
        <ng-template ngx-file-drop-content-tmp  let-openFileSelector="openFileSelector">
          <span class="pick-button" (click)="openFileSelector()"><i class="fa fa-plus"></i></span>Upload
        </ng-template>
    </ngx-file-drop> -->
<div id=dropzoneContainer #container >
<ngx-file-drop id="cover"
           dropZoneClassName="cover-dropzone"
           dropZoneLabel=""
           
           (onFileDrop)="dropped($event)">
            <ng-template ngx-file-drop-content-tmp  let-openFileSelector="openFilePicker">
                <span class="pick-button" style='cursor: pointer'(click)="openFilePicker()">
                    <i class="fa fa-plus"></i>UPLOAD
                </span> 
            </ng-template>
</ngx-file-drop>
</div>