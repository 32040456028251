
import {pairwise, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';



@Injectable()
export class RouterExtService {
  previousUrl = null;

  constructor(private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof RoutesRecognized),
      pairwise(),)
      .subscribe((e: any) => {
        console.log(e);
        this.previousUrl = e[0].urlAfterRedirects;
      });
  }

  public getPreviousUrl(): string {
    return this.previousUrl;
  }

  public getCategoryIdFromRoute() {
    let categoryId;
    const prevUrl = this.getPreviousUrl();
    if (
      prevUrl &&
      (prevUrl.indexOf('categories/') || prevUrl.indexOf('subcategories/'))
    ) {
      const el = document.createElement('a');
      el.href = prevUrl;
      const pathname = el.pathname;
      categoryId = pathname.split(new RegExp(/[\/|\?]/)).pop();
    }
    return categoryId;
  }
}
