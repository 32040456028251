import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-featured-grid',
  templateUrl: './featured-grid.component.html',
  styleUrls: ['./featured-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeaturedGridComponent implements OnInit {
  @Input() noRow = false;
  @Input() type: string;
  @Input() title: string;
  @Input() hasSeeMore = true;
  @Input() showAction = true;
  @Input() cols: any;
  @Input() limit: any;
  @Input() list: Array<any>;
  @Input() showHeader = true;
  @Input() loading = false;
  @Input() scrollable = true;
  @Input() customClass = '';
  @Input() showOrderOptions = true;
  orderBy = 'recent';
  @ContentChild('itemContent', /* TODO: add static flag */ {}) itemTmpl: TemplateRef<any>;
  @Output() onScrollEnd = new EventEmitter<any>();
  @Output() onOrderBy = new EventEmitter<any>();
  @ViewChild('ps', { static: true }) ps;
  config = { wheelPropagation: false };

  constructor() {}

  ngOnInit() {
    if (this.limit) {
      this.list = this.list.splice(0, this.limit);
    }
  }

  getColClass() {
    const cols = parseInt(this.cols, 10);
    return {
      'col-4': cols === 3,
      'col-6': cols === 2,
      'col-12': cols === 1,
    };
  }

  scrollEndHandle(event) {
    this.onScrollEnd.emit(event);
  }

  handleOrderBy(order) {
    this.orderBy = order;
    this.onOrderBy.emit(order);
    this.ps.nativeElement.scrollTop = 0;
  }
}
