import {Component, OnInit, Input} from '@angular/core';
import {UtilService} from '@app/core';

@Component({
  selector: 'app-subscription-amount',
  templateUrl: './subscription-amount.component.html',
  styleUrls: ['./subscription-amount.component.scss']
})
export class SubscriptionAmountComponent implements OnInit {
  @Input() order;
  @Input() type;

  constructor(
    public util: UtilService
  ) {
  }

  ngOnInit() {
  }

  get amount() {
    return this.util.formatCurrency(this.order.total / 100);
  }

  get paymentIcon() {
    if (this.type === 'gmg') {
      return '';
    }
    if (this.order.processor === 'braintree') {
      return '/assets/svgs/icon-paypal.svg';
    } else if (this.order.processor === 'credits') {
      return '/assets/pictures/sing-snap-logo.png';
    } else if (this.order.processor === 'stripe') {
      return '/assets/pictures/credit-card-icon.png';
    } else if (this.order.processor === 'google') {
      return '/assets/pictures/icon-google.png';
    } else if (this.order.processor === 'apple') {
      return '/assets/pictures/icon-apple.png';
    } else {
      return '/assets/pictures/sing-snap-logo.png';
    }
  }

}
