import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { map ,  tap } from 'rxjs/operators';

import { HttpService } from './http.service';

const charsDict =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
//import * as moment from 'moment';

@Injectable()
export class UtilService {
  constructor(private http: HttpClient, private httpService: HttpService) {}

  get songSortOptions() {
    return [
      {
        label: 'Recent',
        icon: 'time',
        field: 'created_at',
        order: 'DESC',
        asc: false,
      },
      {
        label: 'Popular',
        icon: 'flame',
        field: 'popularity',
        order: 'DESC',
        asc: false,
      },
      {
        label: 'A to Z',
        icon: 'atoz',
        field: 'title',
        asc: true,
        order: 'ASC',
      },
      {
        label: 'Z to A',
        icon: 'ztoa',
        field: 'title',
        asc: false,
        order: 'DESC',
      },
    ];
  }

  get songSortOptionsFavoritesFirst() {
    return [
      {
        label: 'Favorites First',
        icon: 'star',
        field: 'mine_first',
        order: 'DESC',
        asc: false,
      },
      {
        label: 'Recent',
        icon: 'time',
        field: 'created_at',
        order: 'DESC',
        asc: false,
      },
      {
        label: 'Popular',
        icon: 'flame',
        field: 'popularity',
        order: 'DESC',
        asc: false,
      },
      {
        label: 'A to Z',
        icon: 'atoz',
        field: 'title',
        order: 'ASC',
        asc: true,
      },
      {
        label: 'Z to A',
        icon: 'ztoa',
        field: 'title',
        order: 'DESC',
        asc: false,
      },
    ];
  }

  get recordingSortOptions() {
    return [
      {
        label: 'Recent',
        icon: 'time',
        field: 'created_at',
        order: 'DESC',
        asc: false,
      },
      {
        label: 'Popular',
        icon: 'flame',
        field: 'popularity',
        order: 'DESC',
        asc: false,
      },
    ];
  }

  get recordingOpenDuetsSortOptions() {
    return [
      {
        label: 'Recent',
        icon: 'time',
        field: 'created_at',
        order: 'DESC',
      },
      {
        label: 'Popular',
        icon: 'flame',
        field: 'popularity',
        order: 'DESC',
      },
      {
        label: 'A to Z',
        icon: 'atoz',
        field: 'song.title',
        order: 'ASC',
        asc: true,
      },
      {
        label: 'Z to A',
        icon: 'ztoa',
        field: 'song.title',
        order: 'DESC',
        asc: false,
      },
    ];
  }






  get artistSortOptions() {
    return [
      {
        label: 'Name',
        icon: 'atoz',
        field: 'name',
        order: 'ASC',
        asc: true,
      },
      {
        label: 'Name',
        icon: 'ztoa',
        field: 'name',
        order: 'DESC',
        asc: false,
      },
    ];
  }

  get memberSortOptions() {
    return [
      {
        label: 'User name',
        icon: 'atoz',
        field: 'screen_name',
        asc: true,
      },
      {
        label: 'User name',
        icon: 'ztoa',
        field: 'screen_name',
        asc: false,
      },
    ];
  }

  get playlistSortOptions() {
    return [
      {
        label: 'Recent',
        icon: 'time',
        field: 'created_at',
        asc: false,
      },
      {
        label: 'Title',
        icon: 'atoz',
        field: 'title',
        asc: true,
      },
      {
        label: 'Title',
        icon: 'ztoa',
        field: 'title',
        asc: false,
      },
    ];
  }

  get recordingTitleSortOptions() {
    return [
      {
        label: 'Title',
        icon: 'atoz',
        field: 'title',
        asc: true,
      },
      {
        label: 'Title',
        icon: 'ztoa',
        field: 'title',
        asc: false,
      },
    ];
  }

  get innerCircleSortOptions() {
    return [
      {
        label: 'Name',
        icon: 'atoz',
        field: 'title',
        asc: true,
      },
      {
        label: 'Name',
        icon: 'ztoa',
        field: 'title',
        asc: false,
      },
      {
        label: 'Recent',
        icon: 'time',
        field: 'updated_at',
        asc: false,
      },
    ];
  }

  formatCurrency(value) {
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  generateGridData() {
    return {
      items: [],
      loading: false,
      lastPage: 1,
      currentPage: 0,
      perPage: 10,
      freeSongsFirst: false,
    };
  }

  getloggedUserData(infoKey?: string) {
    let userInfo = false;
    if (localStorage.getItem('userInfo')) {
      userInfo = JSON.parse(localStorage.getItem('userInfo'));
    }

    if (infoKey) {
      return userInfo[infoKey];
    }

    return userInfo;
  }

  setloggedUserData(data) {
    if (!data) {
      return false;
    }
    localStorage.setItem('userInfo', JSON.stringify(data));
    return true;
  }

  stripHtml(html) {
    // Create a new div element
    const temporalDivElement = document.createElement('div');
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
  }

  renderDate(value) {
    // console.log(this.formatJSDate(value))
    // console.log(moment(value, 'YYYY-MM-DD hh:mm:ss').format('MMM DD, YYYY'))
    // return moment(value, 'YYYY-MM-DD hh:mm:ss').format('MMM DD, YYYY');
    return this.formatJSDate(value);
  }

  formatGtagDate(d){
    d = new Date(d);
    let year = d.getFullYear();
    let month = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
    let day = d.getDate();
    let hours = d.getHours();
    let minutes = d.getMinutes();
    return `${month}${day}${year}${hours}${minutes} `
  }
  
  formatJSDate(d) {
    d = new Date(d)
    let year = d.getFullYear()
    let month = this.getMonthName(d.getMonth())
    let day = d.getDate();
    let timeFormat = this.formatJSTime(d);
    return `${month} ${day}, ${year}  ${timeFormat} `
  }

  formatJSTime(d){
    let hours = d.getHours();
    let minutes = (d.getMinutes()<10?'0':'') + d.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  getMonthName(i) {
    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return months[i];
  }


  formatDate(value) {
    if (!value) {
      return 'Today';
    }
    const date = this.getDate(value);
    const today = new Date();

    if (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    ) {
      return 'Today';
    }

    today.setDate(today.getDate() - 1);
    const yesterday = today;

    if (
      date.getFullYear() === yesterday.getFullYear() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getDate() === yesterday.getDate()
    ) {
      return 'Yesterday';
    }

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    let day: any = date.getDate();
    day = day < 10 ? '0' + day : day;
    let result =
      monthNames[date.getMonth()].substr(0, 3) + ' ' + date.getDate();
    if (date.getFullYear() !== new Date().getFullYear()) {
      result += ` ${date.getFullYear()}`;
    }
    return result;
  }

  getDate(value) {
    value = value.replace(' ', 'T');
    return new Date(value);
  }

  isSameDate(date1: Date, date2: Date) {
    if (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    ) {
      return true;
    } else {
      return false;
    }
  }

  formatTime(value) {
    value = value.split(' ')[1];
    const parts = value.split(':');
    let hours = parseInt(parts[0], 10);
    const minutes = parts[1];
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  formatDuration(value: any) {
    if (!value) {
      return '00:00';
    }

    value = Math.floor(parseInt(value));
    let sec = (value % 60) + '';
    sec = ('00' + sec).slice(-2);
    let min: any = Math.floor(value / 60);
    min = ('00' + min).slice(-2);
    return `${min}:${sec}`;
  }

  isPlural(value) {
    if (value > 1) {
      return 's';
    }

    return '';
  }

  datePeriod(value) {
    const date = new Date(value).getTime();
    const curr = new Date().getTime();
    const ydiff = Math.round((curr - date) / 1000 / 60 / 60 / 24 / 365);
    if (ydiff) return ydiff + ' year' + this.isPlural(ydiff);
    const mdiff = Math.round((curr - date) / 1000 / 60 / 60 / 24 / 30);
    if (mdiff) return mdiff + ' month' + this.isPlural(mdiff);
    const ddiff = Math.round((curr - date) / 1000 / 60 / 60 / 24);
    if (ddiff) return ddiff + ' day' + this.isPlural(ddiff);
    const hdiff = Math.round((curr - date) / 1000 / 60 / 60);
    if (hdiff) return hdiff + 'h';
    const midiff = Math.round((curr - date) / 1000 / 60);
    if (midiff) return midiff + 'm';
    const sdiff = Math.round((curr - date) / 1000);
    if (sdiff) return sdiff + 's';
  }

  buildUrl(url) {
    return environment.baseAPIUrl + '/' + url;
  }

  getCountries() {
    const url = 'https://s3.amazonaws.com/singsnap-dev/assets/countries.json';
    return fetch(url).then(response => {
      const json = response.json();
      console.log(json);
      return json;
    });
  }

  buildUrlParams(page = 1, perPage = 10, sorter = null, asc = true) {
    let url = `?page=${page}&per_page=${perPage}`;

    if (sorter) {
      const ascText = asc ? 'ASC' : 'DESC';
      const sorterKey = `&sorters={"${sorter}": "${ascText}"}`;
      url += sorterKey;
    }
    return url;
  }

  buildParams(
    page = 1,
    perPage = 10,
    sorter = null,
    asc = true,
    query = null,
    queryField = null
  ) {
    const params: any = {};
    params.page = page;
    params.per_page = perPage;
    if (sorter) {
      const ascText = asc ? 'ASC' : 'DESC';
      params.sorters = `{"${sorter}": "${ascText}"}`;
    }
    if (query) {
      params.q = `{"${queryField}":"${query}"}`;
    }
    return params;
  }

  buildParamsAdvanced(
    page = 1,
    perPage = 10,
    sorter = null,
    asc = true,
    query = null,
    queryField = null,
    freeSongs = false
  ) {
    const params: any = {};
    params.page = page;
    params.per_page = perPage;
    const sorters = {} as any;

    if (freeSongs) {
      sorters.gold = 'ASC';
    }
    if (sorter) {
      const ascText = asc ? 'ASC' : 'DESC';
      sorters[sorter] = ascText;
    }
    params.sorters = JSON.stringify(sorters);
    if (query) {
      params.q = `{"${queryField}":"${query}"}`;
    }
    return params;
  }

  generateAlphaNumeric(length = 4) {
    let result = '';
    for (let i = length; i > 0; --i)
      result += charsDict[Math.round(Math.random() * (charsDict.length - 1))];
    return result;
  }

  shuffleArray(array) {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  addWidthIfImgIx(src, width) {
    if (src.indexOf('imgix.net') > -1) {
      return src + '?w=' + width;
    }

    return src;
  }

  getRecordingPrivacyIcons(recording) {
    const globe = !recording.private;
    const lock = recording.private;
    const ic = recording.ic;
    const contest = recording.contest;
    const members = recording.members_only;

    if (globe && contest && ic) {
      return {
        contest,
        ic,
      };
    }
    if (globe && ic) {
      return {
        public: true,
        ic,
      };
    }
    if (globe && contest) {
      return {
        public: true,  //ADDED to dislay public icon on recordings
        contest,
      };
    }
    if (globe) {
      return {
        public: true,
      };
    }
    /********/
    if (members && contest && ic) {
      return {
        contest,
        ic,
      };
    }
    if (members && contest) {
      return {
        contest,
      };
    }
    if (members && ic) {
      return {
        members,
        ic,
      };
    }
    if (members) {
      return {
        members,
      };
    }
    /***/

    if (lock && ic) {
      return {
        private: true,
        ic,
      };
    }
    if (lock) {
      return {
        private: true,
      };
    }
    return {};
  }

  getNotificationRoute(notification) {
    const payload = notification.data.payload;
    if (!payload) {
      return null;
    }
    let link = null;
    let extras = null;
    if (payload.recording) {
      link = ['/d/listen', payload.recording];
    }
    if (payload.thread) {
      link = ['/d/message-board/topics', payload.thread];
    }
    if (payload.user) {
      link = ['/d/profile', payload.user, 'info'];
    }
    if (payload.invite) {
      link = ['/d/ic/list'];
      extras = { queryParams: { current: 'invitations' } };
    }
    if (payload['inner-circle']) {
      link = ['/d/ic', payload['inner-circle']];
    }
    if (payload.conversation) {
      link = ['/d/private-message/inbox', payload.conversation];
    }
    if (payload.contest) {
      link = ['/d/contests', payload.contest];
    }
    if (payload.my_recording) {
      link = ['/d/listen', payload.my_recording];
    }
    return { link, extras };
  }

  getNotificationRouteFormat(notification) {
    const payload = notification.data.payload;
    if (!payload) {
      return null;
    }
    let link = null;
    if (payload.recording) {
      link = ['/d/listen/' + payload.recording];
    }
    if (payload.thread) {
      link = ['/d/message-board/topics/' + payload.thread];
    }
    if (payload.user) {
      link = ['/d/profile/' + payload.user + '/info'];
    }
    if (payload.invite) {
      link = ['/d/ic/list?current=invitations'];
     
    }
    if (payload['inner-circle']) {
      link = ['/d/ic/' + payload['inner-circle']];
    }
    if (payload.conversation) {
      link = ['/d/private-message/inbox/' + payload.conversation];
    }
    if (payload.contest) {
      link = ['/d/contests/' + payload.contest];
    }
    if (payload.my_recording) {
      link = ['/d/listen/' + payload.my_recording];
    }
    return  link;
  }

  getNightmode() {
    const cookieNightmode = Cookie.get('nightmode');
    return cookieNightmode === 'true';
  }

  setNightmode(cookieNightmode) {
    if (cookieNightmode === false) {
      Cookie.set('nightmode', 'false');
    } else {
      Cookie.set('nightmode', 'true');
    }
  }
  getHelpSection() {
      // let url = this.httpService.makeUrl('/help');
      let url = ' https://dev-www.singsnap.com/help '
      //url = url.replace('/v1/', '/')
      const headers = this.httpService.getHeaders();
      return this.http.get(url, headers).pipe(map((res) => JSON.parse(JSON.stringify(res))));;
  }

  getDecadeArray(){
    return  ["All","2020's","2010's","2000's","1990's","1980's","1970's","1960's","1950's"];
  }

  getLettersArray(){
    return  ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  }


  crypt  (text:string )  {
    let salt = "Z6xX92T6qAcvSWLnWqSw";
    const textToChars = (text ) => text.split("").map((c ) => c.charCodeAt(0)); 
    const byteHex = (n ) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code ) => textToChars(salt).reduce((a , b ) => a ^ b, code);
    return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("");
  };

  decrypt(encoded)  {
    let salt = "Z6xX92T6qAcvSWLnWqSw";
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
  };

}
 