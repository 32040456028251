<main class="textpagesMain">
    <h1>Our Partners</h1>
    <article class="textpagesArticle">
  <p>We're proud to work with the following partners around the world.</p>
  <h4>Licensing Partners</h4>
  <p>These organizations help us make sure writers, composers, and publishers are paid accordingly for their music.</p>
  <img src="http://singsnap.imgix.net/assets/images/socan.png">
  <h4>SOCAN</h4>
  <h4>SOCAN is a not-for-profit organization that represents the Canadian performing rights of music creators and publishers.</h4>
  <p>SOCAN licenses more than 125,000 businesses and distributes royalties to its members and peer organizations around the world.</p>
  <img src="http://singsnap.imgix.net/assets/images/mcps-prs.png">
  <h4>MCPS-PRS</h4>
  <h4>PRS for Music (legally The MCPS-PRS Alliance Limited) is a UK copyright collection society and performance rights organisation.</h4>
  <p>PRS for Music undertakes collective rights management for musical works. Formed in 1997 it brought together MCPS (mechanical copyright) and PRS (performing rights).</p>
  <img src="http://singsnap.imgix.net/assets/images/ascap.png">
  <h4>ASCAP</h4>
  <h4>The American Society of Composers, Authors and Publishers (ASCAP) is an American not-for-profit performance-rights organization (PRO).</h4>
  <p>ASCAP protects its members' musical copyrights by monitoring public performances of their music, whether via a broadcast or live performance, and compensating them.</p>
  <img src="http://singsnap.imgix.net/assets/images/sesac.png">
  <h4>SESAC</h4>
  <h4>SESAC, originally the Society of European Stage Authors and Composers, is the smallest of the three performance rights organizations in the United States.</h4>
  <p>SESAC was founded in 1930, making it the second-oldest performing rights organization (PRO) in the United States. SESAC is currently the fastest-growing PRO in the United States.</p>
  <img src="http://singsnap.imgix.net/assets/images/bmi.png">
  <h4>BMI</h4>
  <h4>Broadcast Music, Inc. (BMI) is one of three United States performing rights organizations, along with ASCAP and SESAC.</h4>
  <h4>Miscellaneous</h4>
  <p>Here are some other organizations we work with.</p>
  <img src="http://singsnap.imgix.net/assets/images/bbb.png">
  <h4>BBB</h4>
  <h4>The Better Business Bureau (BBB) offer reviews on businesses that include background, licensing, consumer experience etc.</h4>
  <p style="padding-bottom: 30px;">These reviews are provided for businesses that are BBB accredited and also for businesses that are not BBB accredited. <a href="https://www.bbb.org/ca/on/gloucester/profile/karaoke/singsnap-corporation-0117-18960#sealclick">Click here to read their review of SingSnap.</a></p>
  </article>
  </main>