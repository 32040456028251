import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ss-thread',
  templateUrl: './ss-thread.component.html',
  styleUrls: ['./ss-thread.component.scss'],
})
export class SsThreadComponent implements OnInit {
  @Input() thread = null;
  @Input() type = null;

  constructor() {}

  ngOnInit() {}
}
