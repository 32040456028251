import { Injectable } from '@angular/core';


@Injectable()
export class EnvService {
  constructor() {}

  getEnv() {
    return 'dev';
  }
}
