// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    staging: true,
    baseAPIUrl: 'https://staging.singsnap.com/v1',
    converterApiUrl: 'https://blob.valleyeyelet.com/api/v1',
    uploaderAPIUrl: 'https://staging.singsnap.com/uploader',
    publicVapidKey: 'BFxvayWb-yTLWbMunCypLo8fB1u7xKdF3urmhRMa22R7SNfBnYvOindVnRliRWxwHYQcqnqiTtfv807g0NxeAUI',
    privateVapidKey: 'gNMbsgJUMXBmaljoXjjJAStq0ppJPWayEZ_-o0gLuCY',
    stripeKey: 'pk_test_9pnii3BhCvjVFuYZwW3JayPe',
    imgixURL: 'https://dev-singsnap.imgix.net/assets/images/',
    wsURL: 'wss://staging.singsnap.com:9002/ws/',
    settings: {
        lyricColor: 'SINGSNAP-SETTINGS-LYRIC-COLOR'
    },
    social: {
        facebookAppId: '688505204945732',
        googleAuthClientId: '1050931350331-t9gdgsfhddf4asca97f6fifgigcar15a.apps.googleusercontent.com'
    },
    PAYPAL_MODE: 'sandbox',
    bugSnagId: 'c19b28da05d7409b938d014b4997e07a'
};
