import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-home',
  templateUrl: './icon-home.component.html',
  styleUrls: ['./icon-home.component.scss']
})
export class IconHomeComponent implements OnInit {
  @Input() color: string = '#262628';
  @Input() size: number = 18;

  constructor() { }

  ngOnInit() {
  }

}
