import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '@app/core';
import { Cookie } from 'ng2-cookies';


@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
    isHamburgerVisible = false;
    userIsLoged = false;

    constructor(private authService: AuthenticationService) {
    }

    ngOnInit() {
        const token = Cookie.get('AuthToken');
        this.userIsLoged = !!token;
    }

    onLogOut() {
        this.authService.onLogOut();
    }

    hideHamburger(event) {
        const currentTarget = event.currentTarget;
        let toElement = event.toElement;
        if (navigator.userAgent.indexOf('Firefox') !== -1) {
            toElement = event.relatedTarget;
        }
        if (currentTarget !== toElement && !this.isDescendant(currentTarget, toElement)) {
            this.isHamburgerVisible = false;
        }

    }

    isDescendant(parent, child) {
        let node = child && child.parentNode;
        while (node != null) {
            if (node === parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

}
