import {Pipe, PipeTransform} from '@angular/core';
import * as MarkdownIt from 'markdown-it';
import emoji from 'markdown-it-emoji';

const md = MarkdownIt({breaks: true});
//md.use(emoji);


@Pipe({
    name: 'markdown'
})
export class MarkdownPipe implements PipeTransform {

    transform(value: string, args?: any): any {
        if (value) {
            return md.render(value)
        }
        return '';
    }
}
