import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'remainingTime'
})
export class RemainingTimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
      const d1 = new Date(value.replace(/-/g, "/"));
      const d2 = new Date();
      if (d1 < d2) {
          return false;
      }
      return new Date(d1.getTime() - (d2.getTime()  ) );
  }
}
