import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-ss-input',
    templateUrl: './ss-input.component.html',
    styleUrls: ['./ss-input.component.scss']
})
export class SsInputComponent implements OnInit {

    @Input() error: boolean = false;
    @Input() editor: boolean = false;
    @Input() select: boolean = false;
    @Input() area: boolean = false;

    constructor() {
    }

    ngOnInit() {
    }

}
