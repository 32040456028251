import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-headphone-off',
  templateUrl: './icon-headphone-off.component.html',
  styleUrls: ['./icon-headphone-off.component.scss']
})
export class IconHeadphoneOffComponent implements OnInit {
  @Input() color = '#262628';
  @Input() size = 18;

  constructor() { }

  ngOnInit() {
  }

}
