import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { SharedModule } from '../shared';
import { SharedIconsModule } from '../shared/icons/icons.module';
import { UserProfileComponent } from './user-profile.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { UserProfileRoutingModule } from './user-profile-routing.module';
import { UserProfileRecordingsComponent } from './user-profile-recordings/user-profile-recordings.component';
import { UserProfileOpenDuetsComponent } from './user-profile-open-duets/user-profile-open-duets.component';
import { UserProfileFeaturedOnComponent } from './user-profile-featured-on/user-profile-featured-on.component';
import { UserProfileInnerCircleComponent } from './user-profile-inner-circle/user-profile-inner-circle.component';
import { UserProfileBadgesComponent } from './user-profile-badges/user-profile-badges.component';
import { UserProfileGalleryComponent } from './user-profile-gallery/user-profile-gallery.component';
import { UserProfilePlaylistsComponent } from './user-profile-playlists/user-profile-playlists.component';
import { UserProfileFansComponent } from './user-profile-fans/user-profile-fans.component';
import { UserProfileFavoritesComponent } from './user-profile-favorites/user-profile-favorites.component';
import { UserMeInfoComponent } from './user-me-info/user-me-info.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AdsenseModule } from 'ng2-adsense';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};
@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UserProfileRoutingModule,
        SharedModule,
        SharedIconsModule,
        HttpClientModule,
        NgxFileDropModule,
        InlineSVGModule.forRoot(),
        PerfectScrollbarModule,
        AdsenseModule.forRoot({
            // adClient: 'ca-pub-4776037543768273',
            // adSlot: 9989671476,
           }),
    ],
    declarations: [
        UserProfileComponent,
        UserInfoComponent,
        UserProfileRecordingsComponent,
        UserProfileOpenDuetsComponent,
        UserProfileFeaturedOnComponent,
        UserProfileInnerCircleComponent,
        UserProfileBadgesComponent,
        UserProfileGalleryComponent,
        UserProfilePlaylistsComponent,
        UserProfileFansComponent,
        UserProfileFavoritesComponent,
        UserMeInfoComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ]
})
export class UserProfileModule {
}
