<div class="step-wrapper">
  <div class="step-inner">
    <div class="active-bar" [style.width]="(100 / (steps.length - 1)) * completed + '%'"></div>
    <div 
      class="step-item active" 
      *ngFor="let step of steps; let e = index" 
      [style.left]="(100 / (steps.length - 1)) * e + '%'"
      [ngClass]="{'active': e == active, 'completed': e <= completed}"
    >
      <span class="step-dot"></span>
      <h6 (click)="gotoStep(e, step.link)">{{step.text}}</h6>
    </div>
  </div>
</div>
