<div class="modal-body">
  <div class="comment-preview" *ngIf="comment">
    <div class="comment-preview-body">
      <div class="comment-content">
        <div class="comment-header">
          <div class="comment__user">
            <app-user-message-info [user]="comment.user">
            </app-user-message-info>
          </div>
          <div class="comment-time">
            <app-time-stamp [timeago]="comment.timeago">
            </app-time-stamp>
          </div>
        </div>
        <div class="comment-body ql-editor-styles" [innerHTML]="comment.body | safeHtml"></div>
      </div>
    </div>
  </div>
  <form [formGroup]="reportGroup">
    <ul>
      <h2>Report Content</h2>
      <li>
        <select name="select" style="height: auto;" class="custom-select" formControlName="reason">
          <option [ngValue]="null">Select a reason (*)</option>
          <option *ngFor="let c of reasons" [ngValue]="c">{{ c.name }}</option>
        </select>
      </li>
      <li *ngIf="hasSubReasons()">
        <select name="select" class="custom-select" formControlName="subreason">
          <option [ngValue]="null">Choose one (*)</option>
          <option *ngFor="let c of reportGroup.value.reason.value" [ngValue]="c">{{ c.name }}</option>
        </select>
      </li>
      <li>
        <label for="info">Additional information</label>
        <textarea id="info" formControlName="message" class="form-control"></textarea>
      </li>
      <li>
        <button class="btn ss-btn-primary-inverse" (click)="closeReportModal()">CANCEL</button>
        <button class="btn ss-btn-primary" (click)="report(thankReportContent)">Send</button>
      </li>
    </ul>
  </form>
</div>
<ng-template #thankReportContent let-modal>
  <div class="modal-body">
    <div class="big-success-icon">
            <span>
                <span class="fa fa-check"></span>
            </span>
    </div>
    <h4 class="text-center">Report Received!</h4>
    <p class="text-center message">
      Your report has been received. Thank you for helping us ensure the best quality for
      our <strong>SingSnap</strong> family!
    </p>
    <p class="text-center">
      <button class="btn ss-btn-primary"
              style="margin-right: 6px;"
              (click)="block()">Block user</button>
      <button class="btn btn-custom ss-btn-primary" (click)="closeThankyouReportModal()">OK</button>
    </p>
  </div>
</ng-template>
<app-block-modal #blockModal></app-block-modal>

