import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-mic',
  templateUrl: './icon-mic.component.html',
  styleUrls: ['./icon-mic.component.scss']
})
export class IconMicComponent implements OnInit {
  @Input() color = '#262628';
  @Input() size = 18;

  constructor() { }

  ngOnInit() {
  }

}
