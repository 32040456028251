import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-time-stamp',
    templateUrl: './time-stamp.component.html',
    styleUrls: ['./time-stamp.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeStampComponent implements OnInit {

    @Input() time: any;
    @Input() timeago: any;
    @Input() format: string;
    @Input() needWatch: boolean;
    @Input() showIcon =  true;
    timeNewFormat: any;

    constructor() {
    }

    ngOnInit() {
       this.SetToET(this.time);
    }

    getEstOffset() {
        //daylight savings calculation adapted from https://medium.com/make-it-heady/javascript-handle-date-in-any-timezone-with-daylight-saving-check-182657009310
        const stdTimezoneOffset = () => {
        var jan = new Date(0, 1);
        var jul = new Date(6, 1);
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
        }
        var today = new Date();
        const isDstObserved = (today: Date) => {
        return today.getTimezoneOffset() < stdTimezoneOffset();
        }
        if (isDstObserved(today)) {
        return -4;
        } else {
        return -5;
        }
      }  

    SetToET(date){
       const d = new Date(date);
       // convert to msec since Jan 1 1970
       const localTime = d.getTime();
       // obtain local UTC offset and convert to msec
       const localOffset = d.getTimezoneOffset() * 60 * 1000;
       // obtain UTC time in msec
       const utcTime = localTime + localOffset;
       // obtain and add destination's UTC time offset
       const estOffset = this.getEstOffset();;
       const ottawa = utcTime + (60 * 60 * 1000 * estOffset)
       // convert msec value to date string
       this.timeNewFormat = new Date(ottawa);
       return this.timeNewFormat;
    }

}
