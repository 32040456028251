import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-ss-featured-list',
  templateUrl: './ss-featured-list.component.html',
  styleUrls: ['./ss-featured-list.component.scss'],
})
export class SsFeaturedListComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() title: string;
  @Input() data: any;
  @Output() loadMore = new EventEmitter<any>();
  @Output() onOrderBy? = new EventEmitter<any>();
  @ViewChild('ps', { static: true }) ps;
  @ViewChild(PerfectScrollbarDirective, { static: true })
  directiveRef?: PerfectScrollbarDirective;
  orderBy = 'recent';
  config = { wheelPropagation: false };
  sortKeys = [];

  @ContentChild('itemContent', /* TODO: add static flag */ {}) itemTmpl: TemplateRef<any>;

  constructor() {}

  ngOnInit() {
    this.sortKeys = Object.keys(this.data.orderByList || {});
    this.orderBy = this.data.orderBy;
  }

  ngOnChanges(changes: SimpleChanges) {}

  onLoadMore(event) {
    if (!this.data.items.length) {
      return;
    }
    if (!this.data.loading && !this.data.loadedAll) {
      this.loadMore.emit(this.type);
    }
  }

  handleOrderBy(order) {
    if (this.onOrderBy) {
      console.log(order, this.orderBy);
      this.orderBy = order;
      this.onOrderBy.emit({ order, type: this.type });
      this.ps.nativeElement.scrollTop = 0;
    }
  }
}
