<div class="container">
  <div class="favorite-page ssc-wrapper">
    <div class="ssc-list-header">
      <h1 class="title">My Favorites</h1>
      <div class="ss-switch-holder">
        <span class="ss-label" [ngClass]="{'ss-disabled': !favVisible}">Show my favorites on my user profile<br>
        (will be visible to others)</span>
        <label class="ss-custom-switch">
          <input type="checkbox" [(ngModel)]="favVisible" [checked]="user?.settings?.public_favorites"
            (change)="onCheckboxChange($event)" />
          <span class="ss-slider"></span>
        </label>
      </div>
  </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <app-ss-featured-grid 
          title="Favorite Members"
          [cols]="1"
          [colsLg]="1"
          [colsMd]="1"
          [colsSm]="1"
          [hasSeeMore] = "true"
          seeMorePage="MyListenMore:fav-mem"
          [sortOptions]="memberSortOptions"
          [list]="data.member.items"
          [loading]="data.member.loading"
          (onScrollEnd)="onScrollEndHandle(data.member, $event)"
          (onOrderBy)="resetDataObj(data.member);onScrollEndHandle(data.member, $event)"
        >
          <ng-template #itemContent let-item>
            <div class="ssc-list-item">
              <a (click)="navProfile(item.id)">
                <app-ss-user [user]="item"></app-ss-user>
              </a>
              <div class="song-actions">
                <a (click)="navProfile(item.id)">
                  <app-icon-button size="40px" color="white" border="#909090">
                    <app-icon-arrow-right></app-icon-arrow-right>
                  </app-icon-button>
                </a>
                <app-more-dot-menu>
                  <div class="dropdown-menu-content">
                    <div (click)="unfavoriteMember(item)">Remove from Favorites</div>
                    <div  (click)="navMessage( item?.id)" >Message User</div>
                  </div>
                </app-more-dot-menu>
              </div>
            </div>
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
      </div>
      <div class="col-12 col-md-6">
        <app-ss-featured-grid 
          title="Favorite Recordings"
          [cols]="1"
          [colsLg]="1"
          [colsMd]="1"
          [colsSm]="1"
          [hasSeeMore]="true"
          seeMorePage="MyListenMore:fav-rec"
          [checkOwnRecording]="true"
          [userId]="profileService.userId"
          [sortOptions]="recordingSortOptions"
          [list]="data.recording.items"
          [loading]="data.recording.loading"
          (onScrollEnd)="onScrollEndHandle(data.recording, $event)"
          (onOrderBy)="resetDataObj(data.recording);onScrollEndHandle(data.recording, $event)"
        >
          <ng-template #itemContent let-item>
            <div class="ssc-list-item">
              <a  (click)="navListen( item.id)">
                <app-ss-recording [recording]="item"></app-ss-recording>
              </a>
              <div class="song-actions">
                <a (click)="navListen( item.id)">
                  <app-icon-button size="40px" color="#58c7c9">
                    <app-icon-play color="white"></app-icon-play>
                  </app-icon-button>
                </a>
                <app-more-dot-menu>
                  <div class="dropdown-menu-content">
                    <div (click)="unfavoriteRecording(item)">Remove from Favorites</div>
                    <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                    <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                    <div (click)="handleSing(item.song)">Sing This Song</div>
                    <!-- <div (click)="handleDuet(item)">Duet / Jam</div> -->
                    <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                    <div *ngIf="profileService.userId == item.user_id" [routerLink]="['/d/my-recordings', item.id]">Edit Recording Info</div>
                    <div (click)="shareRecording(item)">Share this recording</div>    
                    <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                  </div>
                </app-more-dot-menu>
              </div>
            </div>
            <app-recording-stat [recording]="item"></app-recording-stat>
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <app-ss-featured-grid 
          title="Favorite Playlists"
          [cols]="1"
          [colsLg]="1"
          [colsMd]="1"
          [colsSm]="1"
          [hasSeeMore]="true"
          seeMorePage="MyListenMore:fav-pl"
          [sortOptions]="playlistSortOptions"
          [list]="data.playlist.items"
          [loading]="data.playlist.loading"
          (onScrollEnd)="onScrollEndHandle(data.playlist, $event)"
          (onOrderBy)="resetDataObj(data.playlist);onScrollEndHandle(data.playlist, $event)"
        >
          <ng-template #itemContent let-item>
            <div class="ssc-list-item ssc-list-item-narrow">
              <a (click)="navPlay( item.id)">
                <app-ss-playlist [playlist]="item"></app-ss-playlist>
              </a>
              <div class="ssc-list-actions">
                <a (click)="navPlay( item.id)">
                  <app-icon-button size="40px" color="#58c7c9">
                    <app-icon-play color="white"></app-icon-play>
                  </app-icon-button>
                </a>
                <app-more-dot-menu>
                  <div class="dropdown-menu-content">
                    <div (click)="unfavoritePlaylist(item)">Remove from Favorites</div>
                  </div>
                </app-more-dot-menu>
              </div>
            </div>
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
      </div>
      <div class="col-12 col-md-4">
        <app-ss-featured-grid 
          title="Favorite Artists"
          [cols]="1"
          [colsLg]="1"
          [colsMd]="1"
          [colsSm]="1"
          [hasSeeMore]="true"
          seeMorePage="MyListenMore:fav-artist"
          [sortOptions]="artistSortOptions"
          [list]="data.artist.items"
          [loading]="data.artist.loading"
          (onScrollEnd)="onScrollEndHandle(data.artist, $event)"
          (onOrderBy)="resetDataObj(data.artist);onScrollEndHandle(data.artist, $event)"
        >
          <ng-template #itemContent let-item>
            <div class="ssc-list-item">
              <a (click)="navArtists( item.id)">
                <app-ss-artist [artist]="item"></app-ss-artist>
              </a>
              <div class="song-actions">
                <a (click)="navArtists( item.id)">
                  <app-icon-button size="40px" color="white" border="#909090">
                    <app-icon-arrow-right></app-icon-arrow-right>
                  </app-icon-button>
                </a>
                <app-more-dot-menu>
                  <div class="dropdown-menu-content">
                    <div (click)="unfavoriteArtist(item)">Remove from Favorites</div>
                  </div>
                </app-more-dot-menu>
              </div>
            </div>
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
      </div>
      <div class="col-12 col-md-4">
        <app-ss-featured-grid 
          title="Favorite Songs"
          [cols]="1"
          [colsLg]="1"
          [colsMd]="1"
          [colsSm]="1"
          [hasSeeMore]="true"
          seeMorePage="MySingMore:fav-songs"
          [sortOptions]="playlistSortOptions"
          [list]="data.song.items"
          [loading]="data.song.loading"
          (onScrollEnd)="onScrollEndHandle(data.song, $event)"
          (onOrderBy)="resetDataObj(data.song);onScrollEndHandle(data.song, $event)"
        >
          <ng-template #itemContent let-item>
            <div class="ssc-list-item">
              <a (click)="handleSing(item)">
                <app-ss-song [song]="item"></app-ss-song>
              </a>
              <div class="song-actions">
                <a (click)="handleSing(item)">
                  <app-icon-button size="40px" color="#58c7c9">
                    <app-icon-mic color="white" size="16"></app-icon-mic>
                  </app-icon-button>
                </a>
                <app-more-dot-menu title="Options">
                  <div class="dropdown-menu-content">
                    <div (click)="unfavoriteSong(item)">Remove from Favorites</div>
                    <div (click)="onArtist(item.artist)">View Artist Profile</div>
                    <div (click)="favoriteArtist(item.artist)">Add Artist to Favorites</div>
                    <div (click)="howOthersSingThis(item.id)">How Others Sing This</div>
                  </div>
                </app-more-dot-menu>
              </div>
            </div>
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
      </div>
    <app-add-to-playlist #playlist></app-add-to-playlist>
    <app-other-singers #otherSingers (addToPlaylist)="onAddtoPlaylist($event)"></app-other-singers>
    <app-purchase-gold-modal #goldcontent type="2"></app-purchase-gold-modal>
  </div>
</div>