


<div class="ss-featured-list--container px-0 featured-list--{{ type }}">
  <div *ngIf="showHeader && customHeader" class="list-header">
    <ng-container *ngTemplateOutlet="headerTmpl"> </ng-container>
  </div>
  <div *ngIf="seeMoreHeader && !showHeader && !customHeader" class="list-header">
    <ng-container class="seeMoreHeading">
      <div>
        <a (click)="closeOrBack()" role="button">
          <app-icon-button>
            <app-icon-arrow-left color="#888" size="20"></app-icon-arrow-left>
          </app-icon-button>
        </a>
        <h2>
          {{ title }}
          <i
            [ngClass]="{ 'd-none': !loading }"
            class="fa fa-spinner fa-pulse"
          ></i>
        </h2>
      </div>
    </ng-container>
  </div>
  <div *ngIf="showHeader && !customHeader" class="list-header">
    <ng-container>
      <h2 [ngClass]="{ 'small-title': smallTitle }">
        {{ title }}
        <i
          [ngClass]="{ 'd-none': !loading }"
          class="fa fa-spinner fa-pulse"
        ></i>
      </h2>
      <div
        ngbDropdown
        class="d-inline-block pull-right"
        *ngIf="sortOptions.length"
      >
        <span class="d-flex align-items-center" ngbDropdownToggle>
          <span class="free-song-sign" *ngIf="freeSongs">
            FREE <i class="fa fa-plus"></i>
          </span>
          <app-sort-icon-filter
            color="white"
            size='18px'
            [type]="sort.icon"
          ></app-sort-icon-filter>
        </span>
        <div ngbDropdownMenu>
          <div class="free-songs-option" *ngIf="freeSongFilter">
            <app-form-check
              [square]="true"
              [value]="freeSongs"
              label="Free songs first"
              (changed)="handleChangeFreeSongs($event)"
            >
            </app-form-check>
          </div>
          <div
            *ngFor="let item of sortOptions"
            class="dropdown-item dropdown-sort-item"
            [ngClass]="{
              selected: sort.field === item.field && sort.icon === item.icon
            }"
            (click)="handleOrderBy(item)"
          >
            <app-sort-icon-filter
              size="16"
              [type]="item.icon"
            ></app-sort-icon-filter>
            <span>{{ item.label }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    class="ps list-wrapper"
    [disabled]="!scrollable"
    [perfectScrollbar]="config"
    #ps
    (psYReachEnd)="scrollEndHandle($event)"
    [ngClass]="{
      'list-wrapper-half': halfSize,
      'list-wrapper-full': noHeightLimit,
      'list-wrapper-extended': extendedSize
    }"
  >
    <ng-content select=".list-inner-options"></ng-content>
    <div class="list-content" [ngStyle]="gridStyles"  *ngIf="!loading; else skeleton">
     


  <div *ngFor="let item of list; let i = index">
    <div *ngIf="cols === 1 && showInFeedAds">
      <div *ngIf="(i === 2)">
        <ng-adsense
        [adClient]="'ca-pub-4776037543768273'"
        [adSlot]="9989671476"
        [display]="'block'"
        [adFormat]="'fluid'"
        [layoutKey]="'-fb+5w+4e-db+86'"
        [height]="120"
        ></ng-adsense>
      </div>
    </div>
    <div *ngIf="cols === 2 && showInFeedAds">
      <div *ngIf="(i === 2 ||i === 3)">
        <ng-adsense
        [adClient]="'ca-pub-4776037543768273'"
        [adSlot]="9989671476"
        [display]="'block'"
        [adFormat]="'fluid'"
        [layoutKey]="'-fb+5w+4e-db+86'"
        [height]="120"
        ></ng-adsense>
      </div>
    </div>
    <div *ngIf="cols === 3 && showInFeedAds">
      <div *ngIf="(i === 3 ||i === 4 ||i === 5 || i === 15 || i === 16 || i === 17 || i === 27 || i === 28 || i === 29)">
        <ng-adsense
        [adClient]="'ca-pub-4776037543768273'"
        [adSlot]="9989671476"
        [display]="'block'"
        [adFormat]="'fluid'"
        [layoutKey]="'-fb+5w+4e-db+86'"
        [height]="120"
        ></ng-adsense>
      </div>
    </div>

    <div  
        class="ss-list--item {{ customClass }}"
        [ngClass]="{ 'is-mine': checkMine(item) }"
      >
      <div >
        <ng-container
          *ngTemplateOutlet="itemTmpl; context: { $implicit: item }"
        ></ng-container></div>
      </div>
    </div>
  </div>

    <ng-template  #skeleton>
    
      <div *ngIf="title === 'New Releases' || title === 'Featured Songs' || title.includes('Favorite Songs')  || title === 'Duets' || title.includes('Songs - ')
                 || title === 'Open Duets' || title === 'Trending Songs' || title === 'Sing - Featured Songs' || title === 'Sing - Genres' 
                 || title === 'Sing - Artists' ||  title === 'Sing - Open Duets' || title === 'Sing - Duets' || title === 'Favorite Playlists'   || title.includes('Favorite Artists')      ">
        <div class="list-content" [ngStyle]="gridStyles"> 
          <div *ngFor="let e of [].constructor(skeletonColumns * 5)">
            <div class="skeleton-ss-song">
              <div *ngIf="!title.includes('Favorite Artists')">
               <div class="skeleton-ss-song-image skeleton-color"></div>
              </div>
              <div *ngIf="title.includes('Favorite Artists')">
                <div class="skeleton-ss-song-image-round skeleton-color"></div>
              </div>
              <div class="skeleton-ss-song-info">
                <div class="skeleton-ss-song-info-title skeleton-color"></div>
                <div class="skeleton-ss-song-info-artist skeleton-color"></div>
                <div *ngIf="!title.includes('Favorite Artists')">
                  <div class="skeleton-ss-song-info-extra skeleton-color"></div>
                </div>
              </div>
              <div class="skeleton-ss-song-end">
                <div class="skeleton-ss-song-button skeleton-color"></div>
                <div class="skeleton-ss-song-3dot skeleton-color"></div>
              </div>
            </div>
          </div>  
        </div>
      </div>

      <div *ngIf="title === 'Recently Recorded' || title.includes('Recordings') || title.includes(' Snappenin') || title === 'Featured Recordings' || title === 'Favorite Recordings' 
                  || title === 'New Member\'s Recordings' ||  title === 'Listen - Recently Recorded' 
                  || title === 'Trending Recordings' || title === 'Listen - Featured Recordings' || title === 'Listen - Favorite Recordings' 
                  || title === 'Listen - Favorite Members' ||  title === 'Listen - New Member\'s Recordings' || title.includes('Favorite Recordings') ">
        <div class="list-content" [ngStyle]="gridStyles">
          <div *ngFor="let e of [].constructor(skeletonColumns * 5)">

            <div class="skeleton-ss-recording">
              <div class="skeleton-ss-recording-profile skeleton-color"></div>
              <div class="skeleton-ss-recording-info">
                <div class="skeleton-ss-recording-info-title skeleton-color"></div>
                <div class="skeleton-ss-recording-info-artist skeleton-color"></div>
                <div class="skeleton-ss-recording-info-extra skeleton-color"></div>
              </div>
              <div class="skeleton-ss-recording-end">
                <div *ngIf="title.includes('Favorite Recordings')">
                  <div class="skeleton-ss-recording-button skeleton-color"></div> 
                </div>
                <div class="skeleton-ss-recording-3dot skeleton-color"></div> 
              </div>
            </div> 
            <div class="skeleton-ss-recording-stats">
              <div class="skeleton-ss-recording-stat1 skeleton-color"></div>
              <div class="skeleton-ss-recording-stat2 skeleton-color"></div>
            </div>

          </div>
        </div>
      </div>

      <div *ngIf="title === 'Announcements'">
        <div class="list-content" [ngStyle]="gridStyles">
          <div *ngFor="let e of [].constructor(skeletonColumns * 5)">
            <div class="skeleton-announcement" >
              <div class="skeleton-announcement-image skeleton-color">
              </div>
              <div class="skeleton-announcement-info">
                <div class="skeleton-announcement-title skeleton-color"></div>
                <div class="skeleton-announcement-info skeleton-color"></div>
              </div>
            </div>
          </div>
        </div>   
      </div>



      <div *ngIf="title === 'Genres' || title === 'Genres - All' " >
        <div class="list-content" [ngStyle]="gridStyles">
          <div *ngFor="let e of [].constructor(12)">
            <div class="skeleton-genre" >
              <div *ngIf="title === 'Genres' ">
                <div class="skeleton-genre-image skeleton-color"></div>
              </div>
              <div *ngIf="title === 'Genres - All' ">
                <div class="skeleton-genre-image-all skeleton-color"></div>
              </div>
              <div>
                <div class="skeleton-genre-title skeleton-color"></div>
              </div>
            </div>
          </div>  
        </div>   
      </div>


      <div *ngIf="title === 'Artists' ">
        <div class="list-content" [ngStyle]="gridStyles">
          <div *ngFor="let e of [].constructor(6)">
            <div class="skeleton-artists" >
              <div class="skeleton-artists-image skeleton-color"></div>
              <div class="skeleton-artists-title skeleton-color"></div>
              <div class="skeleton-artists-songs skeleton-color"></div>
              <div class="skeleton-artists-arrow skeleton-color"></div>
              <div class="skeleton-artists-3dot skeleton-color"></div>
            </div>
          </div>  
        </div>   
      </div>


      <div *ngIf="skeletonType === 'playlistSeeMore' || title === 'Playlist' || title === 'Profile - Playlists' ">
        <div class="list-content" [ngStyle]="gridStyles">
          <div *ngFor="let e of [].constructor(skeletonColumns * 5)">
            <div class="skeleton-ss-playlist">
              <div class="skeleton-ss-playlist-image skeleton-color"></div>
              <div class="skeleton-ss-playlist-info">
                <div class="skeleton-ss-playlist-info-title skeleton-color"></div>
                <div class="skeleton-ss-playlist-info-artist skeleton-color"></div>
                <div class="skeleton-ss-playlist-info-extra skeleton-color"></div>
              </div>
              <div *ngIf="title === 'playlist' ">
                <div class="skeleton-ss-playlist-date skeleton-color"></div>
              </div>
              <div class="skeleton-ss-playlist-end">
                <div class="skeleton-ss-playlist-button skeleton-color"></div>
                <div class="skeleton-ss-playlist-3dot skeleton-color"></div> 
              </div>
            </div> 
          </div>  
        </div>   
      </div>



      <div *ngIf=" title.includes('Favorite Members') ">
        <div class="list-content" [ngStyle]="gridStyles">
          <div *ngFor="let e of [].constructor(18)">
              <div class="skeleton-favorite-member">
                  <div class="skeleton-favorite-member-image skeleton-color"></div>
                  <div style="flex: 0 1 50%;">
                    <div class="skeleton-favorite-member-members skeleton-color"></div>
                  </div>
                  <div class="skeleton-ss-favorite-member-end">
                    <div class="skeleton-ss-favorite-member-button skeleton-color"></div>
                    <div class="skeleton-ss-favorite-member-3dot skeleton-color"></div> 
                  </div>
              </div>
          </div>
        </div>
    </div>

    <div *ngIf=" title === 'Fans'  ">
      <div *ngFor="let e of [].constructor(6)">
          <div class="skeleton-featured-fans">
              <div class="skeleton-featured-fans-image skeleton-color"></div>
              <div class="skeleton-featured-fans-members skeleton-color"></div>
          </div>
      </div>
    </div>

    <div *ngIf="skeletonType === 'fansSeeMore' ">
      <div class="list-content" [ngStyle]="gridStyles">
        <div *ngFor="let e of [].constructor(18)">
            <div class="skeleton-featured-fans">
                <div class="skeleton-featured-fans-image skeleton-color"></div>
                <div class="skeleton-featured-fans-members skeleton-color"></div>
            </div>
        </div>
      </div>
    </div>

    <div *ngIf="skeletonType === 'icOwner' ">
      <div *ngFor="let e of [].constructor(4)">
        <div class="skeleton-ic-list">
          <div class="skeleton-ic-list-info">
            <div class="skeleton-ic-list-title skeleton-color"></div>
            <div class="skeleton-ic-list-description skeleton-color"></div>
          </div>
          <div class="skeleton-ic-list-date skeleton-color"></div>
          <div class="skeleton-ic-list-3dot skeleton-color"></div>
        </div>
      </div>
    </div>

    <div *ngIf="skeletonType === 'icMember' ">
      <div *ngFor="let e of [].constructor(4)">
        <div class="skeleton-ic-list">
          <div class="skeleton-ic-list-info2">
            <div class="skeleton-ic-list-title skeleton-color"></div>
            <div class="skeleton-ic-list-description skeleton-color"></div>
            <div class="skeleton-ic-list-description2 skeleton-color"></div>
          </div>
          <div class="skeleton-ic-list-date skeleton-color"></div>
          <div class="skeleton-ic-list-3dot skeleton-color"></div>
        </div>
      </div>
    </div>  

    <div *ngIf="skeletonType === 'icInvitations' ">
      <div *ngFor="let e of [].constructor(4)">
        <div class="skeleton-ic-list">
          <div class="skeleton-ic-list-info2">
            <div class="skeleton-ic-list-title skeleton-color"></div>
            <div class="skeleton-ic-list-description skeleton-color"></div>
            <div class="skeleton-ic-list-description2 skeleton-color"></div>
          </div>
          <div class="skeleton-ic-list-date skeleton-color"></div>
          <div class="skeleton-ic-round-image1 skeleton-color"></div>
          <div class="skeleton-ic-round-image2 skeleton-color"></div>
        </div>
      </div>
    </div> 


    </ng-template> 

    <div *ngIf="list && list.length === 0 && !loading" class="featured-list--no-results">
      <span>No results found.</span>
    </div>
  </div>
</div>

 <div *ngIf="list && list.length > 4">
  <a href={{getSeeMoreURL()}} style='color: #58c7c9;' class="featured-list--more" *ngIf="hasSeeMore">See More</a>
 </div>
