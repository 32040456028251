<div class="ms-pagination container-fluid">
    <div class="row">
        <div class="mx-auto">
            <ngb-pagination
                *ngIf="!!total"
                [maxSize]="3"
                [collectionSize]="total"
                [pageSize]="pageSize"
                size="sm"
                [ellipses]="false"
                [boundaryLinks]="boundaryLinks"
                [page]="currentPage"
                (pageChange)="onPageChangeHandle($event)">
            </ngb-pagination>
        </div>
    </div>
</div>
