<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size"viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M14.3198574,12.9056439 L17.7071068,16.2928932 C18.0976311,16.6834175 18.0976311,17.3165825 17.7071068,17.7071068 C17.3165825,18.0976311 16.6834175,18.0976311 16.2928932,17.7071068 L12.9056439,14.3198574 C11.5509601,15.3729184 9.84871145,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,9.84871145 15.3729184,11.5509601 14.3198574,12.9056439 Z M8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 Z"
        id="path-search-big"></path>
    </defs>
    <g id="Icons/Search" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask fill="white">
        <use xlink:href="#path-search-big"></use>
      </mask>
      <use id="Combined-Shape" [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-search-big"></use>
    </g>
  </svg>
</div>
