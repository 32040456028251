<a (click)="displaySearch()" [ngbCollapse]="!isCollapsed">
    <i class="fa fa-search"></i>
</a>
<div class="search-container" [ngbCollapse]="isCollapsed">
    <div class="input-group">
        <input type="text" class="form-control" placeholder="Search" (keyup)="searchkeyUp.next($event)">
        <a (click)="displaySearch()">
            <i class="fa fa-search"></i>
        </a>
    </div>
</div>
