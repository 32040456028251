import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent implements OnInit {

  @Input() color: string = '#fff';
  @Input() border: boolean = null;
  @Input() size: string = '40px';
  @Input() shadow: boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
