
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
//import { Http } from '@angular/http';
import { HttpService } from './http.service';

@Injectable()
export class PostService extends HttpService {
  constructor() {
    super();
  }

  // getPosts() {
  //   return this.http
  //     .get(this.makeUrl('/blog'), {}).pipe(
  //     map(response => response.json()));
  // }

  // getMorePosts(nextPageUrl) {
  //   return this.http.get(nextPageUrl, {}).pipe(map(response => response.json()));
  // }
}
