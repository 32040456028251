var __defProp = Object.defineProperty;
var __publicField = (obj, key, value) => {
  if (typeof key !== "symbol")
    key += "";
  if (key in obj)
    return __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value});
  return obj[key] = value;
};
import * as Tone from "./_snowpack/pkg/tone.js";
export default class SSRecorder {
  constructor(video, filterCanvas) {
    __publicField(this, "drawMeter", () => {
      let W = this.meterCtx.width;
      let H = this.meterCtx.height;
      let meterGraident = this.meterCtx.createLinearGradient(0, 0, W, H);
      meterGraident.addColorStop(1, "#4feaae");
      meterGraident.addColorStop(0, "#f94678");
      let level = this.meter.getValue();
      level = Tone.dbToGain(level);
      level = Math.min(level * 4, 1);
      this.meterCtx.clearRect(0, 0, W, H);
      this.meterCtx.fillStyle = meterGraident;
      this.meterCtx.strokeStyle = "rgba(0, 0, 0, 0)";
      this.roundRect(this.meterCtx, 0, H * (1 - level) - 4, W, H * level + 4, W / 2, meterGraident);
      return requestAnimationFrame(this.drawMeter);
    });
    this.filterCanvas = filterCanvas;
    this.video = video;
    this.constraints = {
      audio: {
        echoCancellation: false,
        noiseSuppression: false,
        autoGainControl: false,
        channelCount: 1
      },
      video: {
        width: {ideal: 640},
        height: {ideal: 640},
        aspectRatio: 1,
        ratio: 1
      }
    };
    this.events = {};
    this.stream = null;
    this.videoTurningOn = false;
    this.chunks = [];
    this.newStream = null;
    this.filters = false;
    this.frameRate = 30;
    this.recording = false;
    let canvasMeter = document.getElementById("micMeter");
    this.meterCtx = canvasMeter.getContext("2d");
    this.meterCtx.width = canvasMeter.width;
    this.meterCtx.height = canvasMeter.height;
    this.firstTry = true;
    this.noCam = false;
    this.deleteTokens = false;
    this.sources = {
      audio: [],
      video: []
    };
    if (localStorage.getItem("noCam")) {
      this.noCam = true;
    }
    if (localStorage.getItem("audioDevice")) {
      this.constraints.audio.deviceId = localStorage.getItem("audioDevice");
    }
    if (localStorage.getItem("videoDevice")) {
      this.constraints.video.deviceId = localStorage.getItem("videoDevice");
    }
  }
  getMedia() {
    setTimeout(() => {
      if (this.permissionGranted == false) {
        this.dispatchEvent("show_message", {type: "info", message: "We're still waiting to access your microphone (and camera if you use one)"});
      }
    }, 3e4);
    if (this.stream) {
      this.stopStream();
    }
    if (this.noCam) {
      delete this.constraints.video;
    }
    navigator.mediaDevices.getUserMedia(this.constraints).then((stream) => {
      if (this.deleteTokens) {
        localStorage.removeItem("audioDevice");
        localStorage.removeItem("videoDevice");
      }
      localStorage.setItem("audioDevice", stream.getTracks()[0].getSettings().deviceId);
      if (!this.noCam) {
        this.frameRate = stream.getTracks()[1].getSettings().frameRate;
        this.dispatchEvent("clearCanvas");
        localStorage.setItem("videoDevice", stream.getTracks()[1].getSettings().deviceId);
        localStorage.removeItem("noCam");
      } else {
        localStorage.setItem("noCam", "true");
      }
      if (typeof this.constraints.video == "undefined") {
        this.noCam = true;
      }
      this.stream = stream;
      this.permissionGranted = true;
      this.video.srcObject = this.stream;
      this.initRecorder();
      this.initInputDevices();
      this.grabInputs();
    }).catch((err) => {
      let error = {
        type: "error",
        message: err
      };
      this.errorMessage = err;
      console.error("Error with attempt to load inputs: ", err);
      this.dispatchEvent("hide_canvas");
      if (!this.noCam) {
        error.type = "info";
        error.message = "No Camera found! Switching to Audio Only mode.";
        this.dispatchEvent("show_message", error);
        this.noCam = true;
        this.getMedia();
      } else {
        if (typeof this.constraints.audio.deviceId != "undefined") {
          if (typeof this.constraints.video != "undefined") {
            if (typeof this.constraints.video.deviceId != "undefined") {
              delete this.constraints.video.deviceId;
              this.noCam = true;
            }
          }
          delete this.constraints.audio.deviceId;
          this.deleteTokens = true;
          this.getMedia();
        } else {
          this.dispatchEvent("no_permissions", this.errorMessage);
        }
      }
    });
  }
  initInputDevices() {
    this.meter = new Tone.Meter();
    this.tone = new Tone.UserMedia();
    this.tone.open(this.stream.getTracks()[0].getSettings().deviceId).then((_micInstance) => {
      requestAnimationFrame(this.drawMeter);
      this.tone.connect(this.meter);
    });
  }
  initRecorder() {
    let options = {
      audioBitsPerSecond: 128e3,
      videoBitsPerSecond: 15e5
    };
    let isSupported = MediaRecorder.isTypeSupported;
    if (isSupported("video/mp4")) {
      this.mimeType = "video/mp4";
    } else if (isSupported("video/webm;codecs=h264")) {
      this.mimeType = "video/webm; codecs=h264";
    } else if (isSupported("video/webm;codecs=vp9")) {
      this.mimeType = "video/webm; codecs=vp9";
    } else if (isSupported("video/webm;codecs=vp8")) {
      this.mimeType = "video/webm; codecs=vp8";
    }
    if (!this.newStream) {
      this.recorder = new MediaRecorder(this.stream, options);
    } else {
      this.recorder = new MediaRecorder(this.newStream, options);
    }
    this.recorder.ondataavailable = (e) => {
      this.chunks.push(e.data);
      this.goToReview();
    };
  }
  filtersOn() {
    this.recorder = null;
    this.newStream = this.filterCanvas.captureStream(this.frameRate);
    this.newStream.addTrack(this.stream.getTracks()[0]);
    let options = {
      audioBitsPerSecond: 128e3,
      videoBitsPerSecond: 15e5
    };
    let isSupported = MediaRecorder.isTypeSupported;
    if (isSupported("video/mp4")) {
      this.mimeType = "video/mp4";
    } else if (isSupported("video/webm;codecs=h264")) {
      this.mimeType = "video/webm; codecs=h264";
    } else if (isSupported("video/webm;codecs=vp9")) {
      this.mimeType = "video/webm; codecs=vp9";
    } else if (isSupported("video/webm;codecs=vp8")) {
      this.mimeType = "video/webm; codecs=vp8";
    }
    this.recorder = new MediaRecorder(this.newStream, options);
    this.recorder.ondataavailable = (e) => {
      this.chunks.push(e.data);
    };
    this.recorder.onstop = (e) => {
      this.goToReview();
    };
  }
  filtersOff() {
    this.recorder = null;
    this.initRecorder();
  }
  start() {
    this.recording = true;
    this.recorder.start();
    console.log(this.stream, this.newStream);
    console.log(this.recorder);
  }
  stop() {
    if (this.recorder.state != "inactive") {
      this.recorder.stop();
      this.recording = false;
    }
  }
  stopStream() {
    this.video.srcObject = null;
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
      this.stream = null;
    }
  }
  grabInputs() {
    this.sources = {
      audio: [],
      video: []
    };
    navigator.mediaDevices.enumerateDevices().then((result) => {
      result.forEach((input) => {
        if (input.kind === "audioinput") {
          this.sources.audio.push(input);
        } else if (input.kind === "videoinput") {
          this.sources.video.push(input);
        }
      });
      this.dispatchEvent("input_sources_ready", this.sources);
      if (!this.noCam) {
        this.dispatchEvent("filtersOn");
      }
    });
  }
  changeAudioDevice(deviceId) {
    this.constraints.audio.deviceId = {
      exact: deviceId
    };
    this.getMedia();
  }
  changeVideoDevice(deviceId) {
    if (typeof this.constraints.video == "undefined") {
      this.constraints.video = {
        width: {ideal: 640},
        height: {ideal: 640},
        aspectRatio: 1,
        ratio: 1
      };
    }
    this.constraints.video.deviceId = {
      exact: deviceId
    };
    this.noCam = false;
    this.getMedia();
  }
  videoOff() {
    this.noCam = true;
    this.recorder = null;
    this.dispatchEvent("filtersOff");
    this.getMedia();
  }
  videoOn() {
    this.noCam = false;
    if (typeof this.constraints.video == "undefined") {
      this.constraints.video = {
        width: {ideal: 640},
        height: {ideal: 640},
        aspectRatio: 1,
        ratio: 1
      };
    }
    this.videoTurningOn = true;
    this.getMedia();
  }
  goToReview() {
    if (this.stream) {
      this.stopStream();
    }
    this.blob = new Blob(this.chunks, {type: this.mimeType});
    this.dispatchEvent("blobDone", true);
  }
  addEventListener(eventName, cb) {
    if (!this.events[eventName]) {
      this.events[eventName] = new Array();
    }
    this.events[eventName].push(cb);
  }
  dispatchEvent(event, args) {
    if (!this.events[event])
      return;
    this.events[event].forEach((cb) => cb(event, args));
  }
  roundRect(ctx, x, y, width, height, radius, fill, stroke) {
    if (typeof stroke == "undefined") {
      stroke = true;
    }
    if (typeof radius === "undefined") {
      radius = 5;
    }
    if (typeof radius === "number") {
      radius = {tl: radius, tr: radius, br: radius, bl: radius};
    } else {
      let defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
      for (let side in defaultRadius) {
        radius[side] = radius[side] || defaultRadius[side];
      }
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  }
}
