<div class="app-icon">
  <svg width="29px" height="26px" viewBox="0 0 29 26" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
    <title>Combined Shape</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M36.1525395,27.436511 L38.8793265,24.7691311 L27.0001476,24.7691311 C26.4435925,24.7691311 26.0002661,24.324297 26.0002661,23.7755663 L26.0002661,22.8279686 C26.0002661,22.2801257 26.4479283,21.8344038 27.0001476,21.8344038 L38.8793265,21.8344038 L36.1525395,19.167024 C35.7572944,18.7803899 35.7614357,18.1553505 36.1580885,17.7673393 L36.8430645,17.0972865 C37.2482507,16.7009279 37.8817834,16.7082625 38.277159,17.0950243 L43.9062461,22.6014733 C44.3003984,22.9870384 44.3016217,23.6152999 43.9062461,24.0020616 L38.277159,29.5085107 C37.8830067,29.8940757 37.2397174,29.8942596 36.8430645,29.5062485 L36.1580885,28.8361957 C35.7529024,28.4398371 35.7589513,27.8215243 36.1525395,27.436511 Z M24.8458655,39.1754202 C25.2394537,39.5604335 25.2455026,40.1787463 24.8403165,40.5751048 L24.1553405,41.2451576 C23.7586876,41.6331688 23.1153983,41.6329849 22.721246,41.2474199 L17.0921589,35.7409708 C16.6967833,35.354209 16.6980066,34.7259475 17.0921589,34.3403825 L22.721246,28.8339334 C23.1166216,28.4471717 23.7501543,28.4398371 24.1553405,28.8361957 L24.8403165,29.5062485 C25.2369693,29.8942596 25.2411106,30.519299 24.8458655,30.9059331 L22.1190785,33.573313 L33.9982574,33.573313 C34.5504767,33.573313 34.9981389,34.0190349 34.9981389,34.5668778 L34.9981389,35.5144755 C34.9981389,36.0632062 34.5548125,36.5080403 33.9982574,36.5080403 L22.1190785,36.5080403 L24.8458655,39.1754202 Z"
        id="path-icon-sync"></path>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="black" fill-rule="evenodd">
      <g id="06---Sing-Player---Preview" transform="translate(-736.000000, -676.000000)">
        <g id="sync" transform="translate(720.000000, 660.000000)">
          <g id="Button">
            <mask id="mask-2" fill="black">
              <use xlink:href="#path-icon-sync"></use>
            </mask>
            <path stroke="#FFFFFF" stroke-width="0.5"
              d="M36.3273587,27.6152238 C36.031043,27.9050838 36.0309298,28.362084 36.3329078,28.6574829 L37.0178838,29.3275357 C37.317026,29.6201607 37.8050111,29.6206489 38.1023398,29.329798 L43.7314269,23.8233489 C44.0260908,23.5351047 44.0258092,23.0681548 43.7314269,22.7801861 L38.1023398,17.273737 C37.8009746,16.9789375 37.3227609,16.9777643 37.0178838,17.2759993 L36.3329078,17.946052 C36.0355343,18.2369467 36.0333149,18.7006736 36.3273587,18.9883112 L39.4924067,22.0844038 L27.0001476,22.0844038 C26.5859887,22.0844038 26.2502661,22.4182115 26.2502661,22.8279686 L26.2502661,23.7755663 C26.2502661,24.1875312 26.5829747,24.5191311 27.0001476,24.5191311 L39.4924067,24.5191311 L36.3273587,27.6152238 Z M24.6710463,39.3541329 L21.5059983,36.2580403 L33.9982574,36.2580403 C34.4154303,36.2580403 34.7481389,35.9264403 34.7481389,35.5144755 L34.7481389,34.5668778 C34.7481389,34.1571206 34.4124163,33.823313 33.9982574,33.823313 L21.5059983,33.823313 L24.6710463,30.7272204 C24.9650901,30.4395828 24.9628707,29.9758559 24.6654972,29.6849612 L23.9805212,29.0149084 C23.6756441,28.7166735 23.1974304,28.7178467 22.8960652,29.0126462 L17.2669781,34.5190952 C16.9725958,34.807064 16.9723142,35.2740139 17.2669781,35.5622581 L22.8960652,41.0687071 C23.1933939,41.359558 23.681379,41.3590698 23.9805212,41.0664449 L24.6654972,40.3963921 C24.9674752,40.1009932 24.967362,39.643993 24.6710463,39.3541329 Z">
            </path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>