<div class="comments-header" id="commentsHeader">
    <h2>
        <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <path d="M14.1055728,12.4472136 C14.0361451,12.3083582 14,12.1552451 14,12 L14,6.009763 C14,4.34200706 12.6618557,3 11.0068977,3 L4.99310226,3 C3.33978474,3 2,4.3398874 2,5.99310226 L2,12.0068977 C2,13.6600414 3.34110174,15 4.99620271,15 L15.381966,15 L14.1055728,12.4472136 Z M17.8944272,15.5527864 C18.2268777,16.2176875 17.743382,17 17,17 L4.99620271,17 C2.23691386,17 0,14.7649929 0,12.0068977 L0,5.99310226 C0,3.2353522 2.23518094,1 4.99310226,1 L11.0068977,1 C13.7677178,1 16,3.23872605 16,6.009763 L16,11.763932 L17.8944272,15.5527864 Z" id="path-bubble"></path>
            </defs>
            <g id="Icons/Bubble" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <use id="Rectangle-6" fill="#262628" fill-rule="nonzero" xlink:href="#path-bubble"></use>
            </g>
        </svg>
        {{commentsCount}} {{text}}
        <span class="comments-header__sort" *ngIf="showSort">
            <span class="comments-header__sort-asc" *ngIf="sortAsc" (click)="sortAscHandle.emit($event)"><app-icon-time color="white"></app-icon-time> <app-icon-arrow-down color="white"></app-icon-arrow-down></span>
            <span class="comments-header__sort-desc" *ngIf="!sortAsc" (click)="sortDescHandle.emit($event)"><app-icon-time color="white"></app-icon-time> <app-icon-arrow-down color="white"></app-icon-arrow-down></span>
          </span>
    </h2>
</div>
