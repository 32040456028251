import { Injectable } from '@angular/core';
//import { Headers, RequestOptions } from '@angular/http';

import { Cookie } from 'ng2-cookies/ng2-cookies';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

interface IMakeUrl {
  base?: string;
  query?: string;
}
//declare var jQuery: any;

@Injectable()
export class HttpService {
  get root() {
    return environment.baseAPIUrl
  }

  constructor() {}

  getHeaders(urlencoded: boolean = false) {
    let cookie = ''
    if(Cookie.get('AuthToken')){
      cookie = "Bearer " + Cookie.get('AuthToken');
    }
    const headers = new HttpHeaders({
      Authorization: cookie,
      'X-Requested-With': 'XMLHttpRequest',
      'X-SingSnap-App': 'RC01',
    })
    if (urlencoded) {
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
    }
     //headers.lazyInit
    // delete headers['lazyUpdate']

    //return new RequestOptions({ headers });
    return {headers: headers};
  }
  getNoCacheHeaders(urlencoded: boolean = false) {
    let cookie = ''
    if(Cookie.get('AuthToken')){
      cookie = "Bearer " + Cookie.get('AuthToken');
    }
    const headers = new HttpHeaders({
      Authorization: cookie,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'X-Requested-With': 'XMLHttpRequest',
      'X-SingSnap-App': 'RC01',
    })
    if (urlencoded) {
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
    }
     //headers.lazyInit
    // delete headers['lazyUpdate']

    //return new RequestOptions({ headers });
    return {headers: headers};
  }


  // getSimpleHeaders(urlencoded: boolean = false) {
  //   const headers = new Headers({
  //     //'X-Requested-With': 'XMLHttpRequest',
  //     //'X-SingSnap-App': 'RC01'
  //   });

  //   if (urlencoded) {
  //     headers.append('Content-Type', 'application/x-www-form-urlencoded');
  //   }

  //   return new RequestOptions({ headers });
  // }

  getRawHeaders(urlencoded: boolean = false) {
    let cookie = ''
    if(Cookie.get('AuthToken')){
      cookie = "Bearer " + Cookie.get('AuthToken');
    }
    const headers = new HttpHeaders({
      Authorization: cookie,
      'X-Requested-With': 'XMLHttpRequest',
      'X-SingSnap-App': 'RC01',
    });

    if (urlencoded) {
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
    }

    return headers;
  }

  getHttpHeaders(urlencoded: boolean = false){
    let cookie = ''
    if(Cookie.get('AuthToken')){
      cookie = "Bearer " + Cookie.get('AuthToken');
    }
    const headers = new HttpHeaders({
      Authorization: cookie,
      'X-Requested-With': 'XMLHttpRequest',
      'X-SingSnap-App': 'RC01',
    });

    if (urlencoded) {
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
    }

    return {headers: headers};
  }

  getRawJsonHeaders() {
    const headers = {
      Authorization: "Bearer " + Cookie.get('AuthToken'),
      'X-SingSnap-App': 'RC01',
    };

    return {headers: headers};
  }

  getHeadersForAuth() {
    let cookie = ''
    if(Cookie.get('AuthToken')){
      cookie = "Bearer " + Cookie.get('AuthToken');
    }
    const headers = new HttpHeaders({
      Authorization: cookie,
      'X-Requested-With': 'XMLHttpRequest',
      'X-SingSnap-App': 'RC01',
    });
    return {headers: headers};
  }

  makeUrl(path: string, options?: IMakeUrl): string {
    options = options || {};
    const base = options.base ? options.base : this.root;
    const query = options.query ? options.query : null;
    const url = base + path + (!!query ? `?${query}` : '');
    return url;
  }

  getNextPage(data) {
    return data.current_page + 1 <= data.last_page ? data.current_page + 1 : -1;
  }

  getSorters(sortBy) {
    let sorters;
    if (sortBy) {
      let dir = 'ASC';
      if (sortBy.indexOf('-') === 0) {
        dir = 'DESC';
      }
      sortBy = sortBy.replace('-', '');
      sorters = {};
      sorters[sortBy] = dir;
    }
    return sorters;
  }

  getMentionsIds(text: string) {
    if (typeof text !== 'string') {
      throw TypeError('getMentionsIds text must be string');
    }
    let domparser = new DOMParser()​​
    const $text = domparser.parseFromString(text, 'text/html')
    //const mentions = jQuery($text).find('> .mention');
    const mentions = $text.querySelectorAll('.mention')
    const mentionsIds = [];
    mentions.forEach((idx, el) => {
      //mentionsIds.push(jQuery(el).data('id'));
      mentionsIds.push(idx.id);
    });

    return mentionsIds;
  }
}
