import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-switch',
  templateUrl: './form-switch.component.html',
  styleUrls: ['./form-switch.component.scss']
})
export class FormSwitchComponent implements OnInit {
  @Input() label = '';
  @Input() value = '';
  @Input() disabled = false;
  @Input() showGold = false;
  @Output() changed = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  handleChange() {
    this.changed.emit(this.value);
  }
}
