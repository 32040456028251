import { Component, OnInit } from '@angular/core';
import { RecordingService } from '@app/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-listen-more',
  templateUrl: './listen-more.component.html',
  styleUrls: ['./listen-more.component.scss']
})
export class ListenMoreComponent implements OnInit {
  songId;
  constructor(private recordingService: RecordingService,
              private activatedRoute: ActivatedRoute) { 
    this.activatedRoute.params.subscribe(params => {
      this.songId = params.id;
  });
  }

  ngOnInit() {
    this.recordingService.getRecordingsBySong(this.songId).subscribe( recordings => {
      console.log(recordings);
    });
  }

}
