<app-top-message></app-top-message>
<!-- top ad -->
<div *ngIf="!loggedInIsGold" style="text-align:center;">
  <ng-adsense 
  [adClient]="'ca-pub-4776037543768273'" 
  [adSlot]="9989671476" 
  [adFormat]="'horizontal'"
  ></ng-adsense></div>
<!-- [pageLevelAds]="true" -->

<div class="top-section" id="showcase-container" style="min-height: 340px">

  <div class="container container-ss">
    <div class="row-top"> 
        <app-top-horizontal-list  class="test"  title="Showcase" pageType="SeeMore/Showcase" [amount]='6' [mode]='1' [showcase]='true' [items]="showcases" [loading]="snappinLoading" ></app-top-horizontal-list> 
    </div>
  </div>
</div>

<div class="top-section" style="min-height: 340px">

  <div class="container container-ss">
    <div class="row-top"> 
        <app-top-horizontal-list  class="test"  title="What's Snappenin'!" pageType="SeeMore/Snappenin" [amount]='6' [mode]='1' [items]="snappenins" [loading]="snappinLoading" ></app-top-horizontal-list> 
    </div>
  </div>
</div>


<!-- ads side -->
<div *ngIf="!loggedInIsGold" class="placeAdLeft">
  <ng-adsense
  [adClient]="'ca-pub-4776037543768273'"
  [adSlot]="9024407308"
  ></ng-adsense>
</div>
<div *ngIf="!loggedInIsGold" class="placeAdRight">
  <ng-adsense
  [adClient]="'ca-pub-4776037543768273'"
  [adSlot]="9024407308"
  [adFormat]="'vertical'"
  ></ng-adsense>
</div>

<div class="container container-ss bottom-section">
  <div class="row">
    <div class="col-12 col-lg-4">
      <app-ss-featured-grid
        title="New Releases"
        seeMorePage="HomeNewReleases"
        [hasSeeMore] = "true"
        [scrollable] = "false"
        [cols]="1"
        [colsLg]="1"
        [colsMd]="1"
        [colsSm]="1"
        [sortOptions]="songSortOptions"
        [list]="releasedData.items"
        [loading]="releasedData.loading"
        [freeSongFilter]="false"
        (freeSongChanged)="handleFreeSongsChange(releasedData, $event)"
        (onScrollEnd)="onScrollEndFreeSongsHandle(releasedData, $event)"
        (onOrderBy)="resetDataObj(releasedData);onScrollEndFreeSongsHandle(releasedData, $event)"
      >
        <ng-template #itemContent let-item>
          <div class="ssc-list-item"  class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
            <app-ss-song [song]="item" [uploadDate]="true" (click)="navigateSong(item?.id)"></app-ss-song>
            <div class="song-actions">
              <a (click)="navigateSong(item?.id)" role="button">
                <app-icon-button [shadow]="true" size="40px" color="#58c7c9" title="Record">
                  <app-icon-mic color="white" size="16"></app-icon-mic>
                </app-icon-button>
              </a>
              <app-more-dot-menu title="Options">
                <div class="dropdown-menu-content">
                  <div *ngIf="!item.favorited" (click)="favSong(item)">Add Song to Favorites</div>
                  <div *ngIf="item.favorited" (click)="unFavSong(item)">Remove Song from Favorites</div>
                  <div (click)="navArtistProfile(item.artist.id)">View Artist Profile</div>
                  <div (click)="howOthersSingThis(item.id)">How Others Sing This</div>
                </div>
              </app-more-dot-menu>
            </div>
          </div>
        </ng-template>
      </app-ss-featured-grid>
      <app-vert-space height="40"></app-vert-space>
    </div>
    <div class="col-12 col-lg-4">
      <app-ss-featured-grid
        title="Recently Recorded"
        seeMorePage="ListenRecentlyRecorded"
        [hasSeeMore] = "true"
        [scrollable] = "false"
        [cols]="1"
        [colsLg]="1"
        [colsMd]="1"
        [colsSm]="1"
        [halfSize]="false"
        [checkOwnRecording]="true"
        [userId]="profileService.userId"
        [sortOptions]="recordingSortOptions"
        [list]="topRecordingData.items"
        [loading]="topRecordingData.loading"
        (onScrollEnd)="onScrollEndHandle(topRecordingData, $event)"
        (onOrderBy)="resetDataObj(topRecordingData);onScrollEndHandle(topRecordingData, $event)"
      >
        <ng-template #itemContent let-item>
          <div class="ssc-list-item"  class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
            <app-ss-recording title="Play" [loading]="topRecordingData.loading" (click)="navListen(item?.id)" [truncateLength] = "29" [recording]="item"></app-ss-recording>
            <div class="song-actions" *ngIf="!topRecordingData.loading">
              <a title="Play" (click)="navListen(item?.id)">
              <!-- HIDE Large Button
              <app-icon-button [routerLink]="['/d/listen/', item?.id]" role="button" [shadow]="true" size="40px" color="#58c7c9" title="Play">
                <app-icon-play color="white"></app-icon-play>
              </app-icon-button>  -->
              </a>
              <app-more-dot-menu  title="Options">
                <div class="dropdown-menu-content">
                  <div *ngIf="!item.favorited" (click)="favRecording(item)">Add Recording to Favorites</div>
                  <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Recording from Favorites</div>
                  <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                  <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                  <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                  <div (click)="navProfile(item.user_id)">Go to Profile Page</div>
                  <!-- <div (click)="recordingToLegacy(item)">Appear on Legacy</div> -->
                  <div *ngIf="profileService.userId == item.user_id" [routerLink]="['/d/my-recordings', item.id]">Edit Recording Info</div>
                  <!-- <div (click)="shareRecording(item)">Share this recording</div> -->
                  <!-- <div>Artist Profile</div> -->
                  <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                </div>
              </app-more-dot-menu>
            </div>
          </div>
          <app-recording-stat [recording]="item" *ngIf="!topRecordingData.loading"></app-recording-stat>
        </ng-template>
      </app-ss-featured-grid>
      <app-vert-space height="40"></app-vert-space>
    </div>
    <div class="col-12 col-lg-4">
      <app-ss-featured-grid
        title="Announcements"
        seeMorePage="HomeAnnouncements"
        [hasSeeMore] = "true"
        [scrollable] = "false"
        [list]="announcementData.items"
        [cols]="1"
        [colsLg]="1"
        [colsMd]="1"
        [colsSm]="1"
        [loading]="announcementData.loading"
        (onScrollEnd)="onScrollEndHandle(announcementData, $event)"
        (onOrderBy)="resetDataObj(announcementData);onScrollEndHandle(announcementData, $event)">
        <ng-template #itemContent let-item>
          <app-ss-announcement [announcement]="item" [loading]="announcementData.loading"></app-ss-announcement>
        </ng-template>
      </app-ss-featured-grid>
      <app-vert-space height="40"></app-vert-space>
    </div>
    <app-add-to-playlist #playlist></app-add-to-playlist>
  </div>
      <div *ngIf="!loggedInIsGold" style="text-align:center;">
        <ng-adsense 
        [adClient]="'ca-pub-4776037543768273'" 
        [adSlot]="4474696313" 
        [display]="'block'"
        [adFormat]="'autorelaxed'"
        ></ng-adsense></div>
</div>
<app-fullscreen-modal #fsModal></app-fullscreen-modal>
