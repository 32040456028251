import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-subscription-status',
  templateUrl: './subscription-status.component.html',
  styleUrls: ['./subscription-status.component.scss']
})
export class SubscriptionStatusComponent implements OnInit {
  @Input() status = 0;
  colors = ['#b2b2b3', 'greenyellow', 'greenyellow', '#f04478'];
  labels = ['Not Started', 'Active', 'Canceled', 'Expired'];
  constructor() { }

  ngOnInit() {
  }

  get color() {
    return this.colors[this.status];
  }

  get label() {
    return this.labels[this.status];
  }

}
