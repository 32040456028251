import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-setting',
  templateUrl: './icon-setting.component.html',
  styleUrls: ['./icon-setting.component.scss']
})
export class IconSettingComponent implements OnInit {
  @Input() color: string = '#262628';
  @Input() size: number = 18;
  @Input() hoverColor: string = null;
  mainColor: string;

  constructor() { }

  ngOnInit() {
    this.mainColor = this.color;
  }

  onHover() {
    if (this.hoverColor) {
      this.mainColor = this.hoverColor;
    }
  }

  onLeave() {
    this.mainColor = this.color;
  }

}
