<div class="container container-ss subscription-history">
  <div class="row" *ngIf="subscription">
    <div class="col-12 col-md-9">
      <div class="payment-history">
        <a [routerLink]="'/d/settings/subscription/active'">
          <app-icon-button size="40px" color="white" border="#909090">
            <app-icon-arrow-left></app-icon-arrow-left>
          </app-icon-button>
          <h5>{{subscription.product.name}}</h5>
        </a>
        <div class="subscription-block" *ngFor="let item of data">
          <div class="subscription-block-sub">
            <div class="subscription-item-faint">#{{item.order.charge_id || item.id}}</div>
            <div class="subscription-icon">
              <span class="state-icon">
                <app-icon-check size="10" color="white"></app-icon-check>
              </span>
              <span class="state-text">Successful payment</span>
            </div>
          </div>
          <div class="subscription-block-sub">
            <div class="subscription-item-faint">{{util.renderDate(item.date_paid)}}</div>
            <div class="subscription-item-state">
              <span class="state-text">${{util.formatCurrency(item.order.total / 100)}} USD</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="payment-next">
        <div class="payment-next-detail">
          <h6>Next Payment</h6>
          <span>{{util.renderDate(subscription.subscription.ends_at)}}</span>
        </div>
        <button *ngIf="subscription.status !== 3" class="btn btn-secondary btn-subscription" [disabled]="loading" (click)="handleCancelCheck()">Cancel Subscription</button>
      </div>
    </div>
  </div>
</div>

<app-confirmation-modal #confirmation></app-confirmation-modal>