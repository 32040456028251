import SuperPlayer from "./superpoweredPlayer8773.js";
import LyricsPlayer from "./LyricsPlayer8773.js";
export default class SSRemixer {
  constructor(workletPath, wasmPath, video, duetVideo, recording) {
    this.remix = recording;
    this.song = this.remix.remix.song;
    this.song.lyrics = this.remix.lyrics;
    let duetData = {duet: this.song.duet, parts: this.song.parts};
    this.lyricsPlayer = new LyricsPlayer(this.song.lyrics, this.song.wordTimings, duetData);
    this.meta = this.remix.remix.meta;
    this.workletPath = workletPath;
    this.wasmPath = wasmPath;
    this.voiceBlob;
    this.video = video;
    this.video.muted = true;
    this.duetVideo = duetVideo;
    this.duetVideo.muted = true;
    this.jam = this.remix.remix.jam;
    this.pitch = 0;
    this.musicVolume = 1;
    this.vocalVolume = 1;
    this.playing = false;
    this.loaded = false;
    this.sources = {};
    this.part;
    this.playing = false;
    this.superPlayer;
    this.events = {};
    this.duration;
    this.sampleRate;
    this.song;
  }
  init() {
    let ctx = new AudioContext();
    this.sampleRate = ctx.sampleRate;
    ctx = null;
    this.superPlayer = new SuperPlayer(this.workletPath, this.wasmPath, this.sampleRate);
    this.hookUpEvents();
    this.superPlayer.init();
  }
  hookUpEvents() {
    this.superPlayer.addEventListener("superPlayerReady", () => {
      if (this.jam) {
        this.duetVideo.src = this.remix.music;
        this.superPlayer.loadWebAudio(this.duetVideo.src);
        this.duetVideo.addEventListener("loadeddata", (event) => {
          this.duetVideo.pause();
        });
        this.duetVideo.load();
      } else {
        this.superPlayer.loadWebAudio(this.song.uri);
      }
    });
    this.superPlayer.addEventListener("durationGet", () => {
      this.loadVoice();
    });
    this.superPlayer.addEventListener("playheadUpdate", (event, message) => {
      this.dispatchEvent("playheadUpdate");
      this.lyricsPlayer.updateLyrics(parseInt(message));
    });
    this.superPlayer.addEventListener("voiceReady", () => {
      this.loadValues();
    });
  }
  async loadVoice() {
    fetch(this.remix.voice).then((res) => res.blob()).then((blob) => {
      this.voiceBlob = blob;
      this.superPlayer.loadVoice(blob);
    });
  }
  loadValues() {
    this.video.src = this.remix.voice;
    this.video.load();
    this.video.onloadeddata = (event) => {
      this.video.pause();
      this.video.currentTime = 0;
      if (typeof this.meta.pitch != "undefined" && !this.jam) {
        if (this.meta.pitch != null) {
          this.pitch = parseFloat(this.meta.pitch.split("_")[1]);
          if (this.meta.pitch.split("_")[0] === "LOWER") {
            this.pitch = -this.pitch;
          }
          this.changePitch(this.pitch);
        }
      }
      this.sync(this.meta.latency);
      this.changeMusicVolume(this.meta.base_vol);
      this.changeVocalVolume(this.meta.mic_vol);
      this.dispatchEvent("initDom");
    };
  }
  changePitch(value) {
    this.superPlayer.changePitch(value);
    this.pitch = value;
  }
  seek(e) {
    let noScrub;
    let value;
    if (typeof e == "object") {
      noScrub = false;
      value = e.target.valueAsNumber;
    } else {
      noScrub = true;
      value = e;
    }
    this.video.pause();
    this.video.currentTime = (value + this.superPlayer.voiceOffset) / 1e3;
    this.lyricsPlayer.seek(value);
    this.superPlayer.seek(value);
    if (this.jam) {
      this.duetVideo.pause();
      this.duetVideo.currentTime = (value + this.superPlayer.musicOffset) / 1e3;
    }
    if (noScrub) {
      this.dispatchEvent("playheadUpdate");
    }
    if (this.superPlayer.playing) {
      if (this.jam) {
        this.duetVideo.play();
      }
      this.video.play();
      this.dispatchEvent("startScrubber");
    }
  }
  play() {
    this.superPlayer.play(this.video.currentTime * 1e3 - this.superPlayer.voiceOffset);
    this.video.play();
    if (this.duetVideo) {
      this.duetVideo.play();
    }
    this.playing = true;
  }
  pause() {
    this.video.pause();
    if (this.jam) {
      this.duetVideo.pause();
    }
    this.superPlayer.pause();
    this.playing = false;
  }
  changeMusicVolume(e) {
    let value = e;
    this.superPlayer.changeMusicVolume(value);
  }
  changeVocalVolume(e) {
    let value = e;
    this.superPlayer.changeVocalVolume(value);
  }
  sync(value) {
    this.superPlayer.sync(value);
    this.video.currentTime = this.video.currentTime + this.superPlayer.voiceOffset / 1e3;
  }
  addEventListener(eventName, cb) {
    if (!this.events[eventName]) {
      this.events[eventName] = new Array();
    }
    this.events[eventName].push(cb);
  }
  dispatchEvent(event, args) {
    if (!this.events[event])
      return;
    this.events[event].forEach((cb) => cb(event, args));
  }
}
