import {Component, OnInit, ChangeDetectorRef, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import { Location } from '@angular/common';
import { UtilService, ProfileService, ArtistService, SongService } from '@app/core';

@Component({
  selector: 'app-artist-seemore',
  templateUrl: './artist-seemore.component.html',
  styleUrls: ['./artist-seemore.component.scss']
})
export class ArtistSeemoreComponent implements OnInit {

  artist;
  artistId;
  genres = [];
  songs = [];
  relatedArtists = [];
  loading = false;
  term = '';
  itemsPerPage = 30;
  currentPage = 1;
  pageTitle = "Artist Songs";
  filterByDuets = false;
  headerTitle = "";

  playlistSortOptions = this.util.playlistSortOptions;

  songData = {
    ...this.util.generateGridData(),
    freeSongsFirst: false,
    sort: this.playlistSortOptions[0],
    fetch: this.artistService.getArtistSongs.bind(this.artistService),
  };

  duetData = {
    ...this.util.generateGridData(),
    freeSongsFirst: false,
    sort: this.playlistSortOptions[0],
    fetch: this.artistService.getArtistDuets.bind(this.artistService),
  };

  constructor(
    private artistService: ArtistService,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toast: ToastrService,
    private cdr: ChangeDetectorRef,
    private songService: SongService,
    private profileService: ProfileService,
    private util: UtilService,
    private location: Location,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (this.artistId && this.artistId !== params.id) {
        this.artistId = params.id;
        this.init();
      } else {
        this.artistId = params.id;
      }
    });
   }

   ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if(typeof params.page != 'undefined') {
        this.currentPage = params.page
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if(typeof params.term != 'undefined') {
        this.term = params.term
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if(typeof params.duets != 'undefined') {
       this.filterByDuets = params.duets;
      }
    });   

    this.init();
  }
  
  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  setPageParam() {
    this.location.replaceState(this.location.path().split('?')[0], '');
    this.location.replaceState(this.location.path() + `?page=${this.currentPage}`);
    if(this.term.length >0){
      this.location.replaceState(this.location.path() + `&term=${this.term}`);
    }
    if(this.filterByDuets === true){
      this.location.replaceState(this.location.path() + `&duets=true`);
    }
  }

  checkMine(item){
    return item.favorited;
  }

  handleSing(song) {
    this.navigate([`/recorder/${song.id}`])
  }

  onScrollEndHandle(data, sort) {
    if (sort) {
      data.sort = sort;
    }  console.log("data: ", data)
    if (!data.loading) {
      data.loading = true;
      this.detectChanges();
      data.fetch(this.artistId, this.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, this.term, data.freeSongsFirst)
        .subscribe(response => {
          data.currentPage = this.currentPage;
          this.setPageParam();
          data.items = response.data;
          data.lastPage = response.next_page_url;
          data.loading = false;
          this.detectChanges();
        }, error => {
          this.toast.error(error.message);
        });
    }
  }

  getNextSongs() {
    let data = this.songData;
    if (!data.loading) {
      data.loading = true;
      this.currentPage++;
      this.detectChanges();
      data.fetch(this.artistId, this.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, this.term, data.freeSongsFirst)
        .subscribe(response => {
          data.currentPage = this.currentPage;
          this.setPageParam();
          data.items = response.data;
          data.lastPage = response.next_page_url;
          data.loading = false;
          this.detectChanges();
        }, error => {
          this.toast.error(error.message);
        });
    }
  }

  getPrevSongs() {
    let data = this.songData;
    if (!data.loading && data.currentPage != 1) {
      data.loading = true;
      this.currentPage--;
      this.detectChanges();
      data.fetch(this.artistId, this.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, this.term, data.freeSongsFirst)
        .subscribe(response => {
          data.currentPage = this.currentPage;
          this.setPageParam();
          data.items = response.data;
          data.lastPage = response.next_page_url;
          data.loading = false;
          this.detectChanges();
        }, error => {
          this.toast.error(error.message);
        });
    }
  }

  refreshData(data) {
    Object.assign(data, this.util.generateGridData());
    this.onScrollEndHandle(data, null);
  }

  init() {
    if (!this.profileService.user.gold) {
      this.songData.freeSongsFirst = true;
      this.duetData.freeSongsFirst = true;
    }
    this.duetsClick(this.filterByDuets);
    this.artistService.get(this.artistId).subscribe(artist => {
      this.artist = artist;
      this.headerTitle =  this.pageTitle + " - " + artist.name;
    });
  }

  duetsClick(clicked){
    if (clicked){
      this.filterByDuets =  true; 
      this.resetDataObj(this.duetData);
      this.refreshData(this.duetData);
    } else {
      this.filterByDuets =  false; 
      this.resetDataObj(this.songData);
      this.refreshData(this.songData);
    }
  }


  onSearchHandle(term) {
    this.term = term.trim();
    if(this.filterByDuets){
      this.resetDataObj(this.duetData);
      this.onScrollEndHandle(this.duetData, null);
    } else {
      this.resetDataObj(this.songData);
      this.onScrollEndHandle(this.songData, null);
    }
  }

  goToArtist(id) {
     this.navigate([`d/artists/${id}`])
   }

  onFavArtist(artist) {
    this.artistService.favorite(artist.id).subscribe(result => {
      artist.favorited = true;
      this.toast.success('Added artist to favorites');
    });
  }

  onUnfavArtist(artist) {
    this.artistService.unfavorite(artist.id).subscribe(result => {
      artist.favorited = false;
      this.toast.success('Removed artist from favorites');
    });
  }

  toggleFavorite() {
    if (!this.artist.favorited) {
      this.artistService.favorite(this.artistId).subscribe(response => {
        if (response.success) {
          this.artist.favorited = true;
          this.toast.success(this.artist.name + ' added to favorites.');
        } else {
          this.toast.error(response.message);
        }
      });
    } else {
      this.artistService.unfavorite(this.artistId).subscribe(response => {
        if (response.success) {
          this.artist.favorited = false;
          this.toast.success(this.artist.name + ' removed from favorites.');
        } else {
          this.toast.error(response.message);
        }
      });
    }

  }

  favSong(song) {
    this.songService.fav(song.id).subscribe(response => {
      if (response.success) {
        song.favorited = true;
        this.toast.success('Song was added to favorites.');
      } else {
        this.toast.error('There was an error, try again later.');
      }
    });
  }

  unFavSong(song) {
    this.songService.unfav(song.id).subscribe(response => {
      if (response.success) {
        song.favorited = false;
        this.toast.success('Song was removed from favorites.');
      } else {
        this.toast.error('There was an error, try again later.');
      }
    });
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  resetDataObj(data) {
    this.currentPage = 1;
    data.currentPage = 1;
    data.loading = false;
    data.items = [];
    data.lastPage = 1;
  }

  back(){
    window.history.back();
  }

  handleFreeSongsChange(data, freeSongsFirst) {
    data.freeSongsFirst = freeSongsFirst;
    this.resetDataObj(data);
    this.onScrollEndHandle(data, null);
  }

  howOthersSingThis(id){
    this.navigate([`/d/ListenOthersSing/${id}`]);
  }
}
