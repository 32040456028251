import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { NotificationService, UtilService } from '@app/core/services';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  data = {
    fetch: this.notificationService.getNotifications.bind(this.notificationService),
    ...this.util.generateGridData(),
    perPage: 10,
    currentPage: 1,
    sort: {}
  };
  query = '';

  constructor(
    private util: UtilService,
    private notificationService: NotificationService,
    private toastr: ToastrService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.onScrollEndHandle(this.data);
  }

  async onScrollEndHandle(data) {
    if (this.data.loading || !this.data.lastPage) {
      return;
    }

    data.loading = true;
    try {
      const response = await data.fetch(data.currentPage, data.perPage, this.query).toPromise();
      data.currentPage++;
      data.items = data.items.concat(response.data);
      data.lastPage = response.next_page_url, scrollTo(0, 0);
    } catch (err) {
      this.toastr.error(err.message || 'Failed to load notifications.');
    } finally {
      this.data.loading = false;
      this.detectChanges();
      if (!this.data.items){
      }
    }
  }

  formatTime(time) {
    return this.util.datePeriod(time);
  }

  resetData() {
    this.data.items = [];
    this.data.loading = false;
    this.data.lastPage = 1;
    this.data.currentPage = 1;
  }

  onSearch(query) {
    this.query = query;
    this.resetData();
    this.onScrollEndHandle(this.data);
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  async readAll() { 
    if (this.data.items && this.data.items.length === 0){
        return;
    }
    try {
      await this.notificationService.markAllAsRead().toPromise();
      this.data.items.forEach(notification => notification.read_at = true);
      this.detectChanges();
    } catch (err) {
      this.toastr.error(err.message || 'Failed to mark all as read.');
    }
  }

  async onClick(notification) {
    if (!notification.read_at) {
      this.markAsRead(notification);
    }
    const {link, extras} = this.util.getNotificationRoute(notification);
    if (link) {
      if (extras) {
        this.router.navigate(link, extras);
      } else {
        this.router.navigate(link);
      }
    }
  }

  getRoute(notification){
    const link = this.util.getNotificationRouteFormat(notification);
     if (link) {
         return link;
      }
  }

  async markAsRead(notification) {
    try {
      await this.notificationService.markNotificationAsRead(notification.id).toPromise();
      notification.read_at = true;
      this.detectChanges();
    } catch (err) {
      console.log(err);
    }
  }


  async clearAll(){
    if (this.data.items && this.data.items.length != 0){
      confirm("Do you really want to clear all notifications?")
    }
    try {
     await this.notificationService.clearAllNotifications().toPromise();
      this.detectChanges();
    } catch (err) {
      console.log(err);
      this.toastr.error(err.message || 'Failed to clear all notifications.');
    }
    this.resetData();
    this.detectChanges();
  }

}
