<app-ss-featured-grid
seeMorePage="HomeAnnouncements"
title= "Announcements"
[scrollable]="false"
[hasSeeMore] = "false"
[showHeader] = "false"
[seeMoreHeader] = "true"
[list]="announcementData.items"
[cols]="3"
[colsLg]="3"
[colsMd]="3"
[colsSm]="1" 
[extendedSize] = "true"
[loading]="announcementData.loading"
(onScrollEnd)="onScrollEndHandle(announcementData, $event)"
(onOrderBy)="resetDataObj(announcementData);onScrollEndHandle(announcementData, $event)">
<ng-template #itemContent let-item>
  <app-ss-announcement [loading]="announcementData.loading" [announcement]="item"></app-ss-announcement>
</ng-template>
</app-ss-featured-grid>
<app-vert-space height="40"></app-vert-space>


<div  id="pageNavContainer"  style="margin-bottom: 13px;">
  <button *ngIf='announcementData.currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
  <p >{{currentPage}}</p>
  <button *ngIf='announcementData.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
</div>