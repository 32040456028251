import { Component, OnInit, Input, ChangeDetectionStrategy, NgZone, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from './../../core/services';

@Component({
  selector: 'app-ss-user-name',
  templateUrl: './ss-user-name.component.html',
  styleUrls: ['./ss-user-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsUserNameComponent implements OnInit {
  @Input() user: any = {};
  @Input() showLevel = false;
  @Input() showStatus = true;
  @Input() profile = true;
  @Input() noLink = false;
  @Input() disabled = false;
  @Input() showTrinkets = true;


  constructor(private ngZone: NgZone, private router: Router,  private userService: UserService,) { }

  ngOnInit() {
  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  onScreenName(ref) { 
    this.navigate(['/d/profile/', ref,'info']);
  }

  get letters() {
    if (!this.user.colors) {
      return [];
    }
    const colors = this.user.colors.colors;
    const result = this.user.screen_name.split('').map((letter, index) => ({
      value: letter,
      color: colors[index % colors.length]
    }));
    return result;
  }

  getStyle() {
    if (this.user && this.user.username_tag) {
      return { color: this.user.username_tag.color };
    } else {
      return {};
    }
  }

  getTrinkets() {
    const { trinkets = {} } = this.user;
    return Object.keys(trinkets).map(k => ({
      img: k,
      value: trinkets[k]
    })).slice(0, 4);
  }

  getImageNameFromURL(url){
    let pos = url.lastIndexOf("/");
    let result =  url.substr(pos + 1);
    return result;
  }
}
