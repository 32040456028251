import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-purchase-gold-modal',
  templateUrl: './purchase-gold-modal.component.html',
  styleUrls: ['./purchase-gold-modal.component.scss']
})
export class PurchaseGoldModalComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  @Input() type = 1;
  title = '';
  description = '';
  modalReference: any;

  constructor(
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit() {
    if (this.type === 1) {
      this.description = 'You have discovered a fabulous feature that can be unlocked if you subscribe to Gold!';
    } else if (this.type == 3 ) {
      this.description = "You've reached your maximum number of 5 free recordings as a blue member! Upgrade to GOLD now to restore more recordings!";
    } else {
      this.description = 'You need a Gold Membership to record this!';
    }
  }

  open(message) {
    if (message) {
      this.description = message;
    }
    this.modalReference = this.modalService.open(this.contentElem, {
      windowClass: 'ssc-modal ssc-modal-sm',
      backdrop: 'static',
    });
  }

  closeModal() {
    this.modalReference.close();
  }

  handlePurchase() {
    this.closeModal();
    this.router.navigate(['/d/payments/membership']);
  }
}
