import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent implements OnInit {
  @HostListener('window:click', ['$event.target'])
  onClick(btn) {
      if (btn.tagName == 'NGB-MODAL-WINDOW') {
        //close share window if you click outside of it
        this.modalRef.close('ok')

      }

  }


  @ViewChild('content', { static: true }) private contentElem: ElementRef;


  modalRef;
  data: any = {};
  mobile = false;

  constructor(private modalService: NgbModal, private toastr: ToastrService,) { }

  ngOnInit() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // we're on a mobile device
      this.mobile = true;
    }

  }

  get title() {
    if (this.data.recording) {
      return 'Recording';
    }
    if (this.data.thread) {
      return 'Thread';
    }
    if (this.data.user) {
      return 'Profile';
    }

  }

  open(data) {
    this.data = data;
    console.log(data);
    this.openModal();
  }

  openModal() {

    this.modalRef = this.modalService.open(this.contentElem, {
      windowClass: 'ss-warning-modal ss-share-modal',
      backdrop: 'static',
    });


    this.modalRef.result.then(
      result => {
        console.log('** result', result);

      },
      async reason => {
        console.log('** reason', reason);
      }
    );

  }

  copyToClipboard(text:string){
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        this.toastr.success('Link copied!');
      }, (error) => {
        console.log(error)
      });
    } else {
      console.log('Browser does not support Clipboard API')
    }
  }

  replaceShareWithWWW(url){
    return url.replace("share","www")
  }

}
