import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-play-prev',
  templateUrl: './icon-play-prev.component.html',
  styleUrls: ['./icon-play-prev.component.scss']
})
export class IconPlayPrevComponent implements OnInit {
  @Input() color = '#262628';
  @Input() size = 18;

  constructor() { }

  ngOnInit() {
  }

}
