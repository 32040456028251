<div class="row">
    <div class="col-12 col-sm-9">
        <app-content-box title="About">
            {{user?.bio}}
        </app-content-box>
    </div>
    <div class="col-12 col-sm-3">
        <app-simple-list title="Info" *ngIf="infoItems.length" [list]="infoItems">
        </app-simple-list>
    </div>
</div>

<div class="row" style="margin-top: 40px;">
    <div class="col-12 col-sm-9">
        <app-featured-grid
            *ngIf="recordings"
            title="Recordings"
            cols="1"
            [showAction]="false"
            [hasSeeMore]="false"
            [list]="recordings">
            <ng-template #itemContent let-item>
                <div class="col-auto px-0 align-items-center">
                    <div class="featured-list--item--poster rounded-circle"
                         [ngStyle]="{'background-image': 'url('+item?.song?.photo+')'}">
                        <img [src]="item?.song?.photo" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="col featured-item--body align-items-center">
                    <h1>{{item?.song?.title}}</h1>
                </div>
                <div class="col featured-item--body align-items-center">
                    <app-user-badge-level [user]="item?.user">
                    </app-user-badge-level>
                </div>
                <div class="col-auto featured-list--item-action align-items-center">
                    <a class="btn btn-primary fa fa-chevron-right" [routerLink]="['/d/listen/', item?.id]" role="button">
                    </a>
                </div>
            </ng-template>
        </app-featured-grid>
    </div>
    <div class="col-12 col-sm-3">
        <app-featured-list title="Fans" [featuredList]="fans">
            <ng-template #itemContent let-item>
                <app-user-message-info [user]="item" [showBio]="false"></app-user-message-info>                
            </ng-template>
        </app-featured-list>
    </div>
</div>
