<div class="app-icon" (mouseover)="onHover()" (mouseleave)="onLeave()">
    <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <path d="M6,2 L6,0.998956561 C6,0.447248087 6.45097518,0 6.99077797,0 L11.009222,0 C11.5564136,0 12,0.442660332 12,0.998956561 L12,2 L17,2 C17.5522847,2 18,2.44771525 18,3 C18,3.55228475 17.5522847,4 17,4 L1,4 C0.44771525,4 0,3.55228475 0,3 C0,2.44771525 0.44771525,2 1,2 L6,2 L6,2 Z M14,7 C14,6.44771525 14.4477153,6 15,6 C15.5522847,6 16,6.44771525 16,7 L16,15.000385 C16,16.6573061 14.6500671,18 12.9914698,18 L5.0085302,18 C3.34583835,18 2,16.663757 2,15.000385 L2,7 C2,6.44771525 2.44771525,6 3,6 C3.55228475,6 4,6.44771525 4,7 L4,15.000385 C4,15.5559714 4.44721709,16 5.0085302,16 L12.9914698,16 C13.5478977,16 14,15.5503221 14,15.000385 L14,7 Z" id="path-icon-delete"></path>
        </defs>
        <g id="Icons/Delete" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <mask fill="white">
                <use xlink:href="#path-icon-delete"></use>
            </mask>
            <use id="Combined-Shape" [attr.fill]="mainColor" fill-rule="nonzero" xlink:href="#path-icon-delete"></use>
        </g>
    </svg>
</div>