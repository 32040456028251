import { Injectable } from '@angular/core';


import { HttpService } from './http.service';
import { Observable, of, Subject, forkJoin } from 'rxjs';
import { Contest, ContestsSearchResult } from '@app/models/contest.model';
import { Round } from '@app/models/round.model';
import { HttpClient } from '@angular/common/http';
import { ResponseSimple, ResponsePaginated } from '@app/models/responses.model';
import { map } from 'rxjs-compat/operator/map';

/*
INACTIVE = 0;      # Upcoming, users can't join
OPEN = 1;          # Users can Join
ACTIVE = 2;        # Rounds running
COMPLETE = 3;      # Finished
 */

@Injectable()
export class ContestService extends HttpService {
  searchTerm$ = new Subject<any>();
  contestCover$ = new Subject<any>();
  searchTermContest$ = new Subject<any>();
  nextRoundPosted$ = new Subject<any>();
  joinContest$ = new Subject<any>();
  rejectCandidate$ = new Subject<any>();
  acceptCandidate$ = new Subject<any>();
  closeRound$ = new Subject<any>();

  constructor(private http: HttpClient) {
    super();
  }

  getCloseRound() {
    return this.closeRound$;
  }

  updateCloseRound(data: any) {
    this.closeRound$.next(data);
  }

  getRejectCandidate() {
    return this.rejectCandidate$;
  }

  updateRejectCandidate(data: any) {
    this.rejectCandidate$.next(data);
  }

  getAcceptCandidate() {
    return this.acceptCandidate$;
  }

  updateAcceptCandidate(data: any) {
    this.acceptCandidate$.next(data);
  }

  getJoinContest() {
    return this.joinContest$;
  }

  updateJoinContest(data: any) {
    this.joinContest$.next(data);
  }

  getContestCover() {
    return this.contestCover$;
  }

  updateContestCover(data: any) {
    this.contestCover$.next(data);
  }

  getNextRoundPosted() {
    return this.nextRoundPosted$;
  }

  updateNextRoundPosted(data: any) {
    this.nextRoundPosted$.next(data);
  }

  getSearchTerm() {
    return this.searchTerm$;
  }

  updateSearchTerm(data: any) {
    this.searchTerm$.next(data);
  }

  getSearchTermContest() {
    return this.searchTermContest$;
  }

  updateSearchTermContest(data: any) {
    this.searchTermContest$.next(data);
  }

  getContest(id) {
    const url = this.makeUrl(`/contests/${id}`);
    const headers = this.getHeaders()
    return this.http.get<Contest>(url, headers);
  }

  updateContest(topic) {
    const fd = new FormData();
    fd.append('_method', 'PUT');
    fd.append('description', topic.description);
    fd.append('name', topic.name);

    /*if (topic.tags) {
        fd.append('tags', topic.tags);
    }*/

    if (topic.cover) {
      fd.append('cover', topic.cover);
    }

    const url = this.makeUrl(`/user/contests/${topic.id}`);
    return this.http.put<ResponseSimple>(url, fd);
  }

  getHostContests({ page = 1, perPage = 15, term = '' } = {}) {
    const params = {} as any;
    params.page = page;
    params.per_page = perPage;
    params.sorters = JSON.stringify({ created_at: 'DESC' });
    if (term !== '') {
      params.q = JSON.stringify({ name: term });
    }
    const headers = this.getHeaders()
    headers['params'] = params

    const url = this.makeUrl('/user/contests');

    return this.http.get<ResponsePaginated>(url, headers);
  }

  getOpenContests({ page = 1, perPage = 15, term = '' } = {}) {
    const params = {} as any;
    params.page = page;
    params.per_page = perPage;
    params.sorters = JSON.stringify({ created_at: 'DESC' });
    if (term !== '') {
      params.q = JSON.stringify({ name: term });
    }
    const headers = this.getHeaders()
    headers['params'] = params

    const url = this.makeUrl('/contests/open');

    return this.http.get<ResponsePaginated>(url, headers);
  }

  getUpcomingContests({ page = 1, perPage = 15, term = '' } = {}) {
    const params = {} as any;
    params.page = page;
    params.per_page = perPage;
    params.sorters = JSON.stringify({ created_at: 'DESC' });
    if (term !== '') {
      params.q = JSON.stringify({ name: term });
    }
    const headers = this.getHeaders()
    headers['params'] = params

    const url = this.makeUrl('/contests/upcoming');

    return this.http.get<ResponsePaginated>(url, headers);
  }

  getProgressContests({ page = 1, perPage = 15, term = '' } = {}) {
    const params = {} as any;
    params.page = page;
    params.per_page = perPage;
    params.sorters = JSON.stringify({ created_at: 'DESC' });
    if (term !== '') {
      params.q = JSON.stringify({ name: term });
    }
    const headers = this.getHeaders()
    headers['params'] = params

    const url = this.makeUrl('/contests/in-progress');

    return this.http.get<ResponsePaginated>(url, headers);
  }

  getCompleteContests({ page = 1, perPage = 15, term = '' } = {}) {
    const params = {} as any;
    params.page = page;
    params.per_page = perPage;
    params.sorters = JSON.stringify({ created_at: 'DESC' });
    if (term !== '') {
      params.q = JSON.stringify({ name: term });
    }

    const url = this.makeUrl('/contests/complete');
    const headers = this.getHeaders()
    headers['params'] = params

    return this.http.get<ResponsePaginated>(url, headers);
  }

  getEnteredContests({ page = 1, perPage = 15, term = '' } = {}) {

    const params = {} as any;
    params.page = page;
    params.per_page = perPage;
    params.sorters = JSON.stringify({ created_at: 'DESC' });
    if (term !== '') {
      params.q = JSON.stringify({ name: term });
    }
    const headers = this.getHeaders()
    headers['params'] = params

    const url = this.makeUrl('/user/contests/entered');

    return this.http.get<ResponsePaginated>(url, headers);
  }

  getContestRounds({ id = null, page = 1, perPage = 15, term = '' } = {}) {
    const params = {} as any;
    params.page = page;
    params.per_page = perPage;
    params.sorters = JSON.stringify({ created_at: 'DESC' });
    if (term !== '') {
      params.q = JSON.stringify({ name: term });
    }
    const headers = this.getHeaders()
    headers['params'] = params

    const url = this.makeUrl(`/contests/${id}/rounds`);

    return this.http.get<Round[]>(url, headers);
  }

  getContestPlaylist({ id = null, page = 1, perPage = 15, term = '' } = {}) {
    const params = {} as any;
    params.page = page;
    params.per_page = perPage;
    params.sorters = JSON.stringify({ created_at: 'DESC' });
    if (term !== '') {
      params.q = JSON.stringify({ name: term });
    }
    const headers = this.getHeaders()
    headers['params'] = params

    const url = this.makeUrl(`/contests/${id}/rounds`);

    return this.http.get<ResponsePaginated>(url, headers);
  }

  getContestParticipants({
    id = null,
    page = 1,
    perPage = 15,
    term = '',
  } = {}) {
    const params = {} as any;
    params.page = page;
    params.per_page = perPage;
    params.sorters = JSON.stringify({ created_at: 'DESC' });
    if (term !== '') {
      params.q = JSON.stringify({ name: term });
    }
    const headers = this.getHeaders()
    headers['params'] = params

    const url = this.makeUrl(`/contests/${id}/participants`);

    return this.http.get<ResponsePaginated>(url, headers);
  }

  getContestCandidates({ id = null, page = 1, perPage = 15, term = '' } = {}) {
    const params = {} as any;
    params.page = page;
    params.per_page = perPage;
    params.sorters = JSON.stringify({ created_at: 'DESC' });
    if (term !== '') {
      params.q = JSON.stringify({ name: term });
    }
    const headers = this.getHeaders()
    headers['params'] = params

    const url = this.makeUrl(`/contests/${id}/candidacies`);

    return this.http.get(url, headers);
  }

  removeParticipant(contestId, memberId) {
    const url = this.makeUrl(`/contests/${contestId}/participants/${memberId}`);
    const headers = this.getHeaders()

    return this.http.delete<ResponseSimple>(url, headers);
  }

  join(contestId, recordingId?) {
    const payload = {} as any;
    if (recordingId) {
      payload.recording_id = recordingId;
    }
    const headers = this.getHeaders()

    const url = this.makeUrl(`/contests/${contestId}/join`);

    return this.http.post<ResponseSimple>(url, payload, headers);
  }

  close(contestId) {
    const url = this.makeUrl(`/user/contests/${contestId}/close`);
    const headers = this.getHeaders()
    return this.http.post<ResponseSimple>(url, {}, headers);
  }

  closeRound(roundId) {
    const url = this.makeUrl(`/user/rounds/${roundId}/close`);
    const headers = this.getHeaders()

    return this.http.post<ResponseSimple>(url, {}, headers);
  }

  addCohost(contestId: string, userId: number) {
    const url = this.makeUrl(`/user/contests/${contestId}`);
    const payload = {} as any;
    payload.cohost_id = userId;
    payload._method = 'PUT';
    const headers = this.getHeaders()

    return this.http.post<ResponseSimple>(url, payload, headers);
  }

  addRound(contestId, round: Round) {
    const fd = new FormData();

    fd.append('name', round.name);
    fd.append('description', round.description);

    if (round.cover) {
      fd.append('cover', round.cover);
    }

    const url = this.makeUrl(`/user/contests/${contestId}/rounds`);
    const headers = this.getHeaders()

    return this.http.post<ResponseSimple>(url, fd, headers);
  }

  create(contest: Contest) {
    const fd = new FormData();

    fd.append('name', contest.name);
    fd.append('description', contest.description);
    fd.append('start_at', contest.start_at);
    fd.append(
      'new_recordings_only',
      JSON.stringify(contest.new_recordings_only)
    );
    fd.append('rounds_count', JSON.stringify(contest.rounds_count));
    fd.append('rounds_length', JSON.stringify(contest.rounds_length));
    fd.append(
      'multiple_submissions',
      JSON.stringify(contest.multiple_submissions)
    );

    const mentionsIds = [...new Set(this.getMentionsIds(contest.description))];

    if (mentionsIds.length > 0) {
      mentionsIds.forEach(val => {
        fd.append('cited[]', val);
      });
    }

    if (typeof contest.submit_recording === 'boolean') {
      fd.append('submit_recording', JSON.stringify(contest.submit_recording));
    }

    if (contest.min_users) {
      fd.append('min_users', JSON.stringify(contest.min_users));
    }

    if (contest.criteria) {
      fd.append('criteria', contest.criteria);
    }

    if (contest.rules) {
      fd.append('rules', contest.rules);
    }

    if (contest.prize) {
      fd.append('prize', contest.prize);
    }

    if (contest.cover) {
      fd.append('cover', contest.cover);
    }

    const url = this.makeUrl('/user/contests');
    const headers = this.getHeaders()

    return this.http.post<ResponseSimple>(url, fd, headers);
  }

  update(id, contest: Contest) {
    const fd = new FormData();

    fd.append('_method', 'PUT');
    fd.append('name', contest.name);
    fd.append('description', contest.description);
    fd.append('start_at', contest.start_at);
    fd.append(
      'new_recordings_only',
      JSON.stringify(contest.new_recordings_only)
    );
    fd.append('rounds_count', JSON.stringify(contest.rounds_count));
    fd.append('rounds_length', JSON.stringify(contest.rounds_length));
    fd.append(
      'multiple_submissions',
      JSON.stringify(contest.multiple_submissions)
    );
    fd.append('min_users', JSON.stringify(contest.min_users));

    const mentionsIds = [...new Set(this.getMentionsIds(contest.description))];

    if (mentionsIds.length > 0) {
      mentionsIds.forEach(val => {
        fd.append('cited[]', val);
      });
    }

    if (typeof contest.submit_recording === 'boolean') {
      fd.append('submit_recording', JSON.stringify(contest.submit_recording));
    }

    if (contest.cover) {
      fd.append('cover', contest.cover);
    }

    const url = this.makeUrl(`/user/contests/${id}`);
    const headers = this.getHeaders()

    return this.http.post<ResponseSimple>(url, fd, headers);
  }

  submitRecording(contestId, recordingId) {
    const payload = {} as any;
    if (recordingId) {
      payload.recording_id = recordingId;
    }

    const url = this.makeUrl(`/user/contests/${contestId}/submit`);
    const headers = this.getHeaders()

    return this.http.post<ResponseSimple>(url, payload, headers);
  }

  acceptCandidate(candidacyId) {
    const url = this.makeUrl(`/user/invites/${candidacyId}/accept`);
    const headers = this.getHeaders()
    return this.http.post<ResponseSimple>(url, {}, headers);
  }

  rejectCandidate(candidacyId) {
    const url = this.makeUrl(`/user/invites/${candidacyId}/decline`);
    const headers = this.getHeaders()
    return this.http.post<ResponseSimple>(url, {}, headers);
  }
}
