import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-icon-notification',
  templateUrl: './icon-notification.component.html',
  styleUrls: ['./icon-notification.component.scss']
})
export class IconNotificationComponent implements OnInit {
  @Input() color: string = '#262628';
  @Input() size: number = 18;
  @Input() notify: string = 'false';
  @ViewChild('icon', { static: true }) private icon: ElementRef;


  constructor() { }

  ngOnInit() {
    if(this.notify == 'true') { 
      this.icon.nativeElement.classList.add('icon-active')
    }
  }

}
