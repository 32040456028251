<div class="ss-user">
  <div class="ss-user--profile" [class.ss-user--profile-xs]="isSmall" *ngIf="showPhoto">
    <img [src]="user.photo" />
    <app-user-snapicon-carousel
      [size]="isSmall ? 'xs' : 'small'"
      [icons]="getSnapicons()"
      *ngIf="getSnapicons().length"
    ></app-user-snapicon-carousel>
    <app-user-online-icon [online]="user.online" [size]="isSmall ? 'xs-7' : 'small'"></app-user-online-icon> 
   
  </div>
  <div class="ss-user--info">
    <!-- <div *ngIf="showName" class="ss-user--actual-name">{{ user.name }}</div> -->
    <app-ss-user-name
      [user]="user"
      [showLevel]="showLevel"
      [noLink]="noLink"
      [disabled]="disabled"  title="{{user.can_edit}}"
    ></app-ss-user-name>
 
    <div *ngIf="showStatus && user.status" class="ss-user--info-status">
      {{ user.status }}
    </div>
    <div *ngIf="timestamp" class="ss-user--info-timestamp">
      <app-time-stamp
        [time]="timestamp"
        [needWatch]="false"
        [format]="'MMM d, yyyy hh:mma'"
      ></app-time-stamp>
    </div>   
  </div>
  
</div>
