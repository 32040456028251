import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-privacy-contest',
  templateUrl: './icon-privacy-contest.component.html',
  styleUrls: ['./icon-privacy-contest.component.scss']
})
export class IconPrivacyContestComponent implements OnInit {
  @Input() color = '#D0D0D0';
  @Input() size = 18;

  constructor() { }

  ngOnInit() {
  }

}
