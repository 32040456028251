import { ChangeDetectorRef,  NgZone, Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import {
  GenresService,
  UtilService,
  EventService,
  RecordingService,
  SongService,
  ProfileService,
  HelperService,
} from '@app/core';
import { ArtistService } from '@app/core/services/artist.service';

declare var $: any;

@Component({
  selector: 'app-sing-open-duets',
  templateUrl: './sing-open-duets.component.html',
  styleUrls: ['./sing-open-duets.component.scss']
})
export class SingOpenDuetsComponent implements OnInit {
  recording;
  titleLength = 24;
  duets = [];
  artists = [];
  trending$;
  recordingSortOptions = [];
  songSortOptions = [];
  artistSortOptions = [];
  genreSortOptions = this.util.recordingSortOptions;
  @ViewChild('goldcontent') private goldModal;
  itemsPerPage = 30;

  selectedLetter = '';
  filterByVideo = 0;
  selectedGenreIndex = 0;
  selectedDecadeIndex = 0;
  currentPage = 1;

  letters = [];
  decadesArray = ["All","1950's","1960's","1970's","1980's","1990's","2000's","2010's","2020's"];
  genresArray = [{name: 'All', id: 90000}];
  videoOptions = ["All", "Video Only", "Audio Only"];

  genresData: any = {
    ...this.util.generateGridData(),
    sort: this.genreSortOptions[0],
    fetch: this.genreService.getGenres.bind(this.genreService),
  };

  openDuetsData: any = {
    ...this.util.generateGridData(),
    fetch: this.recordingService.getOpenDuetsSeeMore.bind(this.recordingService),
  };

  duetsData: any = {
    ...this.util.generateGridData(),
    perPage: this.itemsPerPage,
    fetch: this.songService.getDuets.bind(this.songService),
  };

  constructor(
    private recordingService: RecordingService,
    private songService: SongService,
    private genreService: GenresService,
    private router: Router,
    private artistService: ArtistService,
    private toastService: ToastrService,
    public profileService: ProfileService,
    private helperService: HelperService,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    private util: UtilService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.recordingSortOptions = this.util.recordingOpenDuetsSortOptions;
    this.songSortOptions = this.util.songSortOptions;
    this.artistSortOptions = this.util.artistSortOptions;
    this.openDuetsData.sort = this.util.recordingOpenDuetsSortOptions[0];
    this.duetsData.sort = this.util.songSortOptions[0];

  }

  ngOnInit() {
    // console.log('users gold', this.profileService.user.gold);
    /* set default free first songs for blue users */
    // if (!this.profileService.user.gold) {
    //   this.duetsData.freeSongsFirst = true;
    //   this.onScrollEndHandle(this.duetsData, { field: 'gold', asc: true });
    //   } else {
    //      this.onScrollEndHandle(this.duetsData, null);
    // }

    this.genresData.fetch().subscribe((response)=>{
      console.log(response)
      response.data.forEach(item => {
        this.genresArray.push(item)
      })
    })
    this.letters = this.util.getLettersArray();


    this.route.queryParams.subscribe(params => {
      if(typeof params.page != 'undefined') {
        this.currentPage = params.page;
      }
    });

    this.route.queryParams.subscribe(params => {
      if(typeof params.webcam != 'undefined') {
        this.filterByVideo = params.webcam;
      }
    });

    this.onScrollEndHandle();
  }
  
  checkMine(item){
    return item.favorited;
  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  favRecording(recording) {
    this.recordingService.fav(recording.id).subscribe(response => {
      if (response.success) {
        // recording = response.model;
        recording.favorited = true;
        this.toastService.success('Recording was added to favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  unFavRecording(recording) {
    this.recordingService.unfav(recording.id).subscribe(response => {
      if (response.success) {
        // recording = response.model;
        recording.favorited = false;
        this.toastService.success('Recording was removed from favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  handleSing(song) {
    if (song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    // this.router.navigate(['/d/sing', song.id]);
    this.navigate([`/recorder/${song.id}`])
  }

  handleDuet(duet) {
    if (duet.song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    // this.router.navigate(['/duet-recorder', duet.id]);
    this.navigate([`/duet-recorder/${duet.id}`])
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  back(){
    window.history.back();
  }

  setPageParam() {  
    this.location.replaceState(this.location.path().split('?')[0], '');
    this.location.replaceState(this.location.path() + `?page=${this.currentPage}`);
    this.location.replaceState(this.location.path() + `&webcam=${this.openDuetsData.video}`);
  }


  // onScrollEndHandle(data, sort, reload = false) {
  //   if (sort) {
  //     data.sort = sort;
  //   }
  //   if(typeof data.filter == 'undefined') {
  //     data.filter = {};
  //   }


  //   this.openDuetsData.filter.webcam = this.filterByVideo;
    
  //   if (reload || (!data.loading && data.lastPage)) {
  //     data.loading = true;
  //     data.currentPage++;
  //     this.detectChanges();
  //     data
  //       .fetch(data.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade)
  //       .subscribe(
  //         response => {
  //           data.items = data.items.concat(response.data);
  //           data.lastPage = response.next_page_url;
  //           this.setPageParam();
  //           data.loading = false;
  //           this.detectChanges();
  //         },
  //         error => {
  //           this.toastService.error(error.message);
  //         }
  //       );
  //   }
  // }
 
  setSortFields(sort){
    if (sort) {
      this.openDuetsData.sort = sort;
    }
  }


  onScrollEndHandle() {
   this.openDuetsData.video = this.filterByVideo;
   let data = this.openDuetsData;

   let reload = false;
   if(typeof data.filter == 'undefined') {
    data.filter = {};
   }
    if (reload || (!data.loading && data.lastPage)) {
      data.loading = true;
      this.detectChanges();
      data
        .fetch(this.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade, data.video)
        .subscribe(
          response => {
            data.currentPage = this.currentPage;
            data.items = data.items.concat(response.data);
            data.lastPage = response.next_page_url;
            this.setPageParam();
            data.loading = false;
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }


  getNextSongs() {
    let data = this.openDuetsData;
    console.log(data.filter)
    console.log(data.currentPage)
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }

    if (!data.loading && data.lastPage) {
      data.loading = true;
      data.currentPage++;
      console.log(data.currentPage)
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade, data.video)
        .subscribe(
          response => {
            this.currentPage++
            data.items = response.data;
            this.setPageParam();
            data.lastPage = response.next_page_url;
            data.loading = false;
            window.scrollTo(0, 0);
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }
  getPrevSongs() {
    let data = this.openDuetsData;
    console.log(data.filter)
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }

    if (!data.loading && data.currentPage != 1) {
      data.loading = true;
      data.currentPage--;
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade, data.video)
        .subscribe(
          response => {
            this.currentPage--
            data.items = response.data;
            this.setPageParam();
            data.lastPage = response.next_page_url;
            data.loading = false;
            window.scrollTo(0, 0);
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  handleFreeSongsChange(data, gold) {
    data.freeSongsFirst = gold;
    this.resetDataObj(data);
    this.onScrollEndHandle();
  }

  resetDataObj(data) {
    data.currentPage = 1;
    data.loading = false;
    data.items = [];
    data.lastPage = 1;
    this.currentPage = 1;
  }

  openLink(url) {
    window.open(window.location.origin + '/#' + url);
  }

  onArtist(artist) {
    // this.openLink('/d/artists/' + artist.id);
    this.navigate([`/d/artists/${artist.id}`])
  }

  onFavArtist(artist) {
    this.artistService.favorite(artist.id).subscribe(result => {
      artist.favorited = true;
      this.toastService.success('Added song to favorites');
    });
  }

  onUnfavArtist(artist) {
    this.artistService.unfavorite(artist.id).subscribe(result => {
      artist.favorited = false;
      this.toastService.success('Removed artist from favorites');
    });
  }

  async subscribeUser(recording) {
    const recordingList = [this.openDuetsData.items, this.duetsData.items];
    this.helperService.subscribeUser(recording, recordingList);
  }

  async unsubscribeUser(recording) {
    const recordingList = [this.openDuetsData.items, this.duetsData.items];
    this.helperService.unsubscribeUser(recording, recordingList);
  }

  shareRecording(recording) {
    this.eventService.onSocialShare({ recording });
  }


  handleFilter(filter) { 
    if(this.openDuetsData.filter.title == filter) {
     // delete this.openDuetsData.filter.title;
      this.selectedLetter = '';
    } else {
      this.selectedLetter = filter;
      this.openDuetsData.filter.title = filter;
    }
    this.resetDataObj(this.openDuetsData);
    this.onScrollEndHandle();
  }

  videoClick(value){
    this.filterByVideo=value;
   this.openDuetsData.webcam = this.filterByVideo;
    this.resetDataObj(this.openDuetsData);
    this.onScrollEndHandle( );
  }

  howOthersSingThis(id){
    this.navigate([`/d/ListenOthersSing/${id}`]);
  }
}
