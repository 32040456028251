import {Component, HostBinding, Input, ViewChild, OnInit , ChangeDetectionStrategy, ChangeDetectorRef,} from '@angular/core';
//import { NguCarouselConfig, } from '@ngu/carousel';
import {environment} from '../../../../environments/environment';
import { NgxGlideComponent } from 'ngx-glide';

@Component({
  selector: 'app-session-carousel',
  templateUrl: './session-carousel.component.html',
  styleUrls: ['./session-carousel.component.scss'],
})
export class SessionCarouselComponent implements OnInit  {
  @Input('hideArrows') hideArrows = true;
  @Input('sideBySide') sideBySide = false;
  @HostBinding('class.side-by-side') sideBySideCls = false;
  @ViewChild(NgxGlideComponent, { static: false }) ngxGlide: NgxGlideComponent;
  imgags = [
    environment.imgixURL + '/SingSnap_recorder.png?w=700',
    environment.imgixURL + '/sing_section.png?w=700',
    environment.imgixURL + '/player_and_comments.png?w=700',
  ];
  // public carouselTileItems: Array<any> = [];
  // public carouselTile: NguCarouselConfig = {
  //   grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
  //   speed: 600,
  //   point: {
  //     visible: true
  //   },
  //   loop: true,
  //   load: 2,
  //   touch: false,
  // }


  items = [
    {
      title: 'Sing Your Heart Out!',
      description: 'Register for free access to singing and recording thousands of karaoke songs, millions of recordings to listen to and entertainment that lasts a lifetime!',
      image: environment.imgixURL + '/SingSnap_recorder.png?w=700'
    },
    {
      title: 'Join The Best Community In The World!',
      description: 'With members from every corner of the earth, this is your chance to meet and collaborate with music lovers across the planet!',
      image: environment.imgixURL + '/sing_section.png?w=700'
    },
    {
      title: 'Fun and Fabulous Features!',
      description: 'With new and innovative technology we make recording from your mobile device feel like a portable music studio!',
      image: environment.imgixURL + '/player_and_comments.png?w=700'
    },
  ];

  constructor(private cdr: ChangeDetectorRef) {
    // this.carouselOne = {
    //   grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    //   speed: 600,
    //   interval: 5000,
    //   point: {
    //     visible: true
    //   },
    //   loop: true,
    //   load: 2,
    //   touch: false,
    // };
  }

  ngOnInit () {
    if(this.sideBySide) {
      this.sideBySideCls = true;
    }


  }


}
