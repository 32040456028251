<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header align-items-center">
    <h4 class="modal-title" id="modal-basic-title">
      ADD TO PLAYLIST
      <i class="fa fa-spinner fa-pulse" *ngIf="loading"></i>
    </h4>
    <div class="ssc-btn-new-item" (click)="onNewPlaylist()">
        <div class="upload-icon">
          <app-icon-more color="white" size="12"></app-icon-more>
        </div>
        <span>CREATE NEW PLAYLIST</span>
      </div>
    <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
      <span aria-hidden="true" [inlineSVG]="'/assets/svgs/close.svg'"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body-content">
      <div class="playlist-content" [perfectScrollbar]="config" (psYReachEnd)="scrollEndHandle($event)">
        <div class="playlist-item" *ngFor="let item of playlist" (click)="onToggle(item.id)">
          <app-ss-playlist [playlist]="item" wide="true"></app-ss-playlist>
          <div class="ss-check-icon" [ngClass]="{'active': isSelected(item.id)}">
            <app-icon-check color="white" size="10" *ngIf="isSelected(item.id)"></app-icon-check>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="d('Save click')">ADD TO PLAYLIST</button>
  </div>
</ng-template>
<app-new-playlist #newPlaylist (created)="newPlaylistCreated()"></app-new-playlist>
<app-purchase-gold-modal #goldcontent></app-purchase-gold-modal>
