import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit {

  constructor() { }
  loaded = false;
  date;
  formattedDate;
  currentYear;
  public static options = {
    year: 'numeric',
    month: 'short',     //original month: 'numeric'
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  } as const
  ngOnInit() {
    this.getDate();
    setTimeout(() =>{this.loaded = true;}, 2000)
    setInterval(() =>{this.getDate();}, 30000)
  }
  getEstOffset() {
    //daylight savings calculation adapted from https://medium.com/make-it-heady/javascript-handle-date-in-any-timezone-with-daylight-saving-check-182657009310
    const stdTimezoneOffset = () => {
    var jan = new Date(0, 1)
    var jul = new Date(6, 1)
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
    }
    var today = new Date()
    const isDstObserved = (today: Date) => {
    return today.getTimezoneOffset() < stdTimezoneOffset()
    }
    if (isDstObserved(today)) {
    return -4
    } else {
    return -5
    }
  }  
  getDate(){
    const d = new Date()
    // convert to msec since Jan 1 1970
    const localTime = d.getTime()
    // obtain local UTC offset and convert to msec
    const localOffset = d.getTimezoneOffset() * 60 * 1000
    // obtain UTC time in msec
    const utcTime = localTime + localOffset
    // obtain and add destination's UTC time offset
    const estOffset = this.getEstOffset()
    const ottawa = utcTime + (60 * 60 * 1000 * estOffset)
    // convert msec value to date string
    this.date = new Date(ottawa)

    let options = {
      year: 'numeric',
      month: 'short',     //original month: 'numeric'
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    } as const

    let ourYear = {
      year: 'numeric'
    } as const


    this.formattedDate = new Intl.DateTimeFormat('en-CA', options).format(this.date) //"7/22/2018, 7:22:13 AM"
    this.currentYear = new Intl.DateTimeFormat('en-CA', ourYear).format(this.date)
  }

}
