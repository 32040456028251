<div *ngIf="genre">
  <div class="genre-image">
    <div class="image-holder">
      <img [src]="genre.photo+addToUrl">
    </div>
  </div>
  <div class="genre-name">{{genre.name}}</div>
  <!-- <div class="genre-stat" *ngIf="!showSong">{{genre.stats.recordings}} Recordings</div> -->
  <div class="genre-stat" *ngIf="showSong">{{genre.stats.songs}} Songs</div>
</div>
