<ng-template #content let-modal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="audition-title">
      <h4>Select And Submit Recording</h4>
    </div>
    <hr>
    <div class="search-input form-row">
      <app-search-bar-input #searchInput class="col" (onSearch)="onSearchHandle($event)"></app-search-bar-input>
    </div>
    <app-featured-list
      *ngIf="paginator"
      class="audition-list"
      [featuredList]="paginator.items"
      [showHeader]="false"
      [scrollable]="false"
      [staticHeight]="true"
      [loading]="paginator.loading"
      [flexible]="true"
      [isInfiniteScroll]="true"
      [noRow]="true"
      (onViewChange)="paginator.request(paginator.page)"
      >
      <ng-template #itemContent let-item>
        <div class="contest row">
          <div class="col">
            <app-ss-recording [recording]="item"></app-ss-recording>
          </div>
          <div class="col-auto">
            <app-icon-button [routerLink]="['/d/listen/', item.id]" size="40px" color="#58c7c9">
              <app-icon-play color="white"></app-icon-play>
            </app-icon-button>
          </div>
          <div class="col-auto">
            <label class="check-circle">
              <span class="round round-xs" [ngClass]="{'active': item.submit_audition}"
                  (click)="selectRecording(item)"></span>
            </label>
          </div>
        </div>
        <app-recording-stat [recording]="item"></app-recording-stat>
      </ng-template>
    </app-featured-list>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary btn-lg btn-block" [disabled]=""
            (click)="auditionToContest()">Submit</button>
  </div>
</ng-template>
<app-splash-modal #splashModal></app-splash-modal>
