<ng-template #content let-modal>
  <div class="modal-body">
      <h4 class="text-center">{{title}}</h4>
      <p class="text-center message">
          {{message}}
      </p>
      <p class="text-center">
          <button class="btn ss-btn-primary" (click)="close()">OK</button>
      </p>
  </div>
</ng-template>
