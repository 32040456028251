<div class="top-section">
  <div class="container container-ss">
    <div class="row-top">
       <app-top-horizontal-list  [showStats]="true" pageType="ListenRecentlyRecorded" title="Recently Recorded" [amount]='6' [mode]='2' [items]="recentRecordedData.items" [loading]="recentRecordedData.loading"></app-top-horizontal-list>
    </div>
  </div>
</div>


<!-- MultiAds -->
<div *ngIf="!loggedInIsGold" class="placeAdLeft">
  <ng-adsense
  [adClient]="'ca-pub-4776037543768273'"
  [adSlot]="6875544661"
  ></ng-adsense>
</div>
<div *ngIf="!loggedInIsGold" class="placeAdRight">
  <ng-adsense
  [adClient]="'ca-pub-4776037543768273'"
  [adSlot]="6875544661"
  ></ng-adsense>
</div>



<div class="container-fluid bottom-section">
  <div class="container container-ss">
    <div class="row">
      <!-- FEATURED RECORDINGS -->
      <div class="col-12 col-lg-8" style="height:  720px;">
        <app-ss-featured-grid
          title="Featured Recordings"
          seeMorePage="ListenFeaturedRecordings"
          [hasSeeMore] = "true"
          [scrollable] = "false"
          [cols]="2"
          [colsLg]="1"
          [colsMd]="2"
          [colsSm]="1"
          [checkOwnRecording]="true"
          [userId]="profileService.userId"
          [sortOptions]="recordingSortOptions"
          [list]="featuredData.items"
          [loading]="featuredData.loading"
          (onScrollEnd)="onScrollEndHandle(featuredData, $event)"
          (onOrderBy)="resetDataObj(featuredData);onScrollEndHandle(featuredData, $event)">
          <ng-template #itemContent let-item>
            <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
              <app-ss-recording (click)="navListen(item?.id)" [recording]="item"></app-ss-recording>
              <div class="song-actions">
                <a title="Play"  (click)="navListen(item?.id)" >
                  <!-- HIDE Large Button
                  <app-icon-button [shadow]="true" role="button" size="40px" color="#58c7c9">
                    <app-icon-play color="white"></app-icon-play>
                  </app-icon-button> -->
                </a>
                <app-more-dot-menu title="Options">
                  <div class="dropdown-menu-content">
                    <div *ngIf="!item.favorited" (click)="favRecording(item)">Add Recording to Favorites</div>
                    <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Recording from Favorites</div>
                    <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                    <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                    <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                    <div (click)="navProfile(item.user_id)">Go to Profile Page</div>
                    <!-- <div (click)="handleDuet(item)">Duet / Jam</div> -->
                    <div *ngIf="profileService.userId == item.user_id" (click)="navEditRec(item?.id)">Edit Recording Info</div>
                    <!-- <div (click)="shareRecording(item)">Share this recording</div> -->
                    <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                  </div>
                </app-more-dot-menu>
              </div>
            </div>
            <app-recording-stat  (click)="navListen(item?.id)" [recording]="item"></app-recording-stat>
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
      </div>

       <!-- NEW MEMBER'S RECORDINGS -->
       <div class="col-12 col-lg-4">
        <app-ss-featured-grid 
          title="New Members' Recordings"
          seeMorePage="ListenNewMemberRecordings"
          [hasSeeMore] = "true"
          [scrollable] = "false"
          [list]="newMemberData.items" 
          [halfSize]="false"
          [checkOwnRecording]="true"
          [userId]="profileService.userId"
          [sortOptions]="recordingSortOptions"
          [loading]="newMemberData.loading"
          (onScrollEnd)="onScrollEndHandle(newMemberData, $event)"
          (onOrderBy)="resetDataObj(newMemberData);onScrollEndHandle(newMemberData, $event)"
        >
          <ng-template #itemContent let-item>
            <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
              <app-ss-recording (click)="navListen(item?.id)" [recording]="item"></app-ss-recording>
              <div class="song-actions">
                <a title="Play" (click)="navListen(item?.id)">
                  <!-- HIDE Large Button
                  <app-icon-button [shadow]="true" role="button" size="40px" color="#58c7c9">
                    <app-icon-play color="white"></app-icon-play>
                  </app-icon-button> -->
                </a>
                <app-more-dot-menu title="Options">
                  <div class="dropdown-menu-content" >
                    <div *ngIf="!item.favorited" (click)="favRecording(item)">Add Recording to Favorites</div>
                    <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Recording from Favorites</div>
                    <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                    <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                    <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                    <div (click)="navProfile(item.user_id)">Go to Profile Page</div>
                    <!-- <div (click)="handleDuet(item)">Duet / Jam</div> -->
                    <div *ngIf="profileService.userId == item.user_id" (click)="navEditRec(item?.id)">Edit Recording Info</div>
                    <!-- <div (click)="shareRecording(item)">Share this recording</div> -->
                    <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                  </div>
                </app-more-dot-menu>
              </div>
            </div>
            <app-recording-stat  (click)="navListen(item?.id)" [recording]="item"></app-recording-stat>
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
      </div> 
    
    </div>
    <div class="row">
      <!-- FAVORITE MEMBERS -->
      <div class="col-12 col-lg-8">
        <app-ss-featured-grid 
          title="Favorite Members' Recordings"
          seeMorePage="ListenFavoriteMembers"
          [hasSeeMore] = "true"
          [scrollable] = "false"
          [list]="byFavData.items" 
          [loading]="byFavData.loading" 
          [cols]="2"
          [colsLg]="2"
          [colsMd]="2"
          [colsSm]="1" 
          [sortOptions]="recordingSortOptions"
          (onScrollEnd)="onScrollEndHandle(byFavData, $event)" 
          (onOrderBy)="resetDataObj(byFavData);onScrollEndHandle(byFavData, $event)">
          <ng-template #itemContent let-item>
            <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
              <app-ss-recording [hideUser]="true" [recording]="item" (click)="navListen(item?.id)"></app-ss-recording>
              <!-- removed because of double name showing
              <app-ss-user-name [user]="item.users[0]"></app-ss-user-name> -->
              <div class="song-actions">
                <a  (click)="navListen(item?.id)" title="Play">
                  <!-- HIDE Large Button
                  <app-icon-button [shadow]="true" role="button" size="40px" color="#58c7c9">
                    <app-icon-play color="white"></app-icon-play>
                  </app-icon-button> -->
                </a>
                <app-more-dot-menu  title="Options">
                  <div class="dropdown-menu-content">
                    <div *ngIf="!item.favorited" (click)="favRecording(item)">Add Recording to Favorites</div>
                    <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Recording from Favorites</div>
                    <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                    <div (click)="navProfile(item.user_id)">Go to Profile Page</div>
                    <!-- <div (click)="handleDuet(item)">Duet / Jam</div> -->
                    <!-- <div (click)="shareRecording(item)">Share this recording</div> -->
                    <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                  </div>
                </app-more-dot-menu>
              </div>
            </div>
            <app-recording-stat  (click)="navListen(item?.id)" [recording]="item"></app-recording-stat>
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
      </div>
           
  <!-- Favorite RECORDINGS -->
  <div class="col-12 col-lg-4">
    <app-ss-featured-grid 
      title="Favorite Recordings"
      seeMorePage="ListenFavoriteRecordings"
      [hasSeeMore] = "true"
      [scrollable] = "false"
      [cols]="1"
      [colsLg]="1"
      [colsMd]="1"
      [colsSm]="1" 
      [checkOwnRecording]="true"
      [userId]="profileService.userId"
      [sortOptions]="recordingSortOptions"
      [list]="favoriteData.items" 
      [loading]="favoriteData.loading"
      (onScrollEnd)="onScrollEndHandle(favoriteData, $event)" 
      (onOrderBy)="resetDataObj(favoriteData);onScrollEndHandle(favoriteData, $event)">
      <ng-template #itemContent let-item>
        <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
          <app-ss-recording (click)="navListen(item?.id)" [recording]="item"></app-ss-recording>
          <div class="song-actions">
            <a title="Play" (click)="navListen(item?.id)">
               <!-- HIDE Large Button 
              <app-icon-button [shadow]="true" role="button" size="40px" color="#58c7c9">
                  <app-icon-play color="white"></app-icon-play>
                </app-icon-button> -->
            </a>
            <app-more-dot-menu title="Options">
              <div class="dropdown-menu-content">
                <div (click)="howOthersSingThis(item)">How Others Sing This</div>
                <div *ngIf="!item.favorited" (click)="favRecording(item)">Add Recording to Favorites</div>
                <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Recording from Favorites</div>
                <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                <div (click)="navProfile(item.user_id)">Go to Profile Page</div>
                <!-- <div (click)="handleDuet(item)">Duet / Jam</div> -->
                <div *ngIf="profileService.userId == item.user_id"  (click)="navEditRec(item?.id)">Edit Recording Info</div>
                <!-- <div (click)="shareRecording(item)">Share this recording</div> -->
                <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
              </div>
            </app-more-dot-menu>
          </div>
        </div>
        <app-recording-stat  (click)="navListen(item?.id)" [recording]="item"></app-recording-stat>
      </ng-template>
    </app-ss-featured-grid>
    <app-vert-space height="40"></app-vert-space>
  </div>


  <div class="row">
    <!-- DUETS -->
    <div class="col-12 col-lg-8">
      <app-ss-featured-grid 
        title="Duets"
        seeMorePage="ListenDuets"
        [hasSeeMore] ="true"
        [scrollable] ="false"
        [limit]="8"
        [cols]="2"
        [colsLg]="1"
        [colsMd]="2"
        [colsSm]="1"
        [checkOwnRecording]="true"
        [userId]="profileService.userId"
        [sortOptions]="recordingSortOptions"
        [list]="openDuetsData.items" 
        [loading]="openDuetsData.loading"
        (onScrollEnd)="onScrollEndHandle(openDuetsData, $event)"
        (onOrderBy)="resetDataObj(openDuetsData);onScrollEndHandle(openDuetsData, $event)">
        <ng-template #itemContent let-item>
          <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
            <app-ss-recording [hideUser]="true" [recording]="item" (click)="navListen(item?.id)"></app-ss-recording>
            <!-- removed because of double name showing
            <app-ss-user-name [user]="item.users[0]"></app-ss-user-name> -->
            <div class="song-actions">
              <a  (click)="navListen(item?.id)" title="Play">
                <!-- HIDE Large Button
                <app-icon-button [shadow]="true" role="button" size="40px" color="#58c7c9">
                  <app-icon-play color="white"></app-icon-play>
                </app-icon-button> -->
              </a>
              <app-more-dot-menu  title="Options">
                <div class="dropdown-menu-content">
                  <div *ngIf="!item.favorited" (click)="favRecording(item)">Add Recording to Favorites</div>
                  <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Recording from Favorites</div>
                  <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                  <div (click)="navProfile(item.user_id)">Go to Profile Page</div>
                  <!-- <div (click)="handleDuet(item)">Duet / Jam</div> -->
                  <!-- <div (click)="shareRecording(item)">Share this recording</div> -->
                  <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                </div>
              </app-more-dot-menu>
            </div>
          </div>
          <app-recording-stat  (click)="navListen(item?.id)" [recording]="item"></app-recording-stat>
        </ng-template>
      </app-ss-featured-grid>
      <app-vert-space height="60"></app-vert-space>
    </div>
         
  <!-- Jams RECORDINGS -->
  <div class="col-13 col-lg-4">
    <app-ss-featured-grid 
      title="Jams"
      seeMorePage="ListenJams"
      [hasSeeMore] = "true"
      [scrollable] = "false"
      [checkOwnRecording]="true"
      [userId]="profileService.userId"
      [sortOptions]="recordingSortOptions"
      [list]="jamsData.items" 
      [limit]="4"
      [cols]="1"
      [colsLg]="1"
      [colsMd]="1"
      [colsSm]="1" 
      [loading]="jamsData.loading"
      (onScrollEnd)="onScrollEndHandle(jamsData, $event)" 
      (onOrderBy)="resetDataObj(favoriteData);onScrollEndHandle(jamsData, $event)">
      <ng-template #itemContent let-item>
        <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
          <app-ss-recording (click)="navListen(item?.id)" [recording]="item"></app-ss-recording>
          <div class="song-actions">
            <a title="Play" (click)="navListen(item?.id)">
               <!-- HIDE Large Button 
              <app-icon-button [shadow]="true" role="button" size="40px" color="#58c7c9">
                  <app-icon-play color="white"></app-icon-play>
                </app-icon-button> -->
            </a>
            <app-more-dot-menu title="Options">
              <div class="dropdown-menu-content">
                <div (click)="howOthersSingThis(item)">How Others Sing This</div>
                <div *ngIf="!item.favorited" (click)="favRecording(item)">Add Recording to Favorites</div>
                <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Recording from Favorites</div>
                <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                <div (click)="navProfile(item.user_id)">Go to Profile Page</div>
                <!-- <div (click)="handleDuet(item)">Duet / Jam</div> -->
                <div *ngIf="profileService.userId == item.user_id"  (click)="navEditRec(item?.id)">Edit Recording Info</div>
                <!-- <div (click)="shareRecording(item)">Share this recording</div> -->
                <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
              </div>
            </app-more-dot-menu>
          </div>
        </div>
        <app-recording-stat  (click)="navListen(item?.id)" [recording]="item"></app-recording-stat>
      </ng-template>
    </app-ss-featured-grid>
    <app-vert-space height="60"></app-vert-space>

  </div>

 
    <!-- ads bottom -->
   <!--- <div *ngIf="!loggedInIsGold" style="text-align:center;">
      <ng-adsense 
      [adClient]="'ca-pub-4776037543768273'" 
      [adSlot]="4625033266" 
      [display]="'inline-block'"
      [width]="800"
      [height]="180"
      ></ng-adsense></div>
-->

    <div class="row" style="width: 100%;">
      <!-- GENRES -->
      <div class="col-12 col-lg-12">
        <app-ss-featured-grid
          title="Genres"
          [cols]="6"
          [colsLg]="5"
          [colsLgm]="4"
          [colsMd]="3"
          [colsSm]="2"
          [list]="genresData.items"
          seeMorePage="SeeMore/Genres"
          [hasSeeMore] = "true"
          [scrollable] = "false"
          [loading]="genresData.loading"
          (onScrollEnd)="onScrollEndHandle(genresData, $event)"
          (onOrderBy)="resetDataObj(genresData);onScrollEndHandle(genresData, $event)"
        >
          <ng-template #itemContent let-item>
          <div class="point">
            <div (click)="navGenres(item?.id)">
              <app-genre-item [genre]="item" [height]="150" [addHeight]="true" ></app-genre-item>
            </div>
          </div>  
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
      </div>
      
    </div>
    <app-add-to-playlist #playlist></app-add-to-playlist>
    <app-purchase-gold-modal #goldcontent type="2"></app-purchase-gold-modal>
  </div>
</div>


