import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {InnerCircleService} from '@app/core/services/inner-circle.service';
import {ActivatedRoute, Router} from '@angular/router';
import { ProfileService } from '@app/core';
import { ToastrService } from 'ngx-toastr';
import { InnerCircle } from '@app/models/innercircle.model';

@Component({
    selector: 'app-user-profile-inner-circle',
    templateUrl: './user-profile-inner-circle.component.html',
    styleUrls: ['./user-profile-inner-circle.component.scss']
})
export class UserProfileInnerCircleComponent implements OnInit {

    ics: Array<InnerCircle>;
    userId = '';
    data = {
        items: [],
        rqing: false,
        sort: 'created_at',
        lastPage: 1,
        currentPage: 1,
        perPage: 20,
        fetch: this.icService.getSharedInnerCircles.bind(this.icService)
    };

    constructor(private icService: InnerCircleService,
                private router: Router,
                private profileService: ProfileService,
                private activatedRoute: ActivatedRoute,
                private toastService: ToastrService,
                private cdr: ChangeDetectorRef) {
        this.activatedRoute.parent.params.subscribe(params => {
            console.log('?', params);
            this.userId = params.id;
        });
    }

    ngOnInit() {
        if (!this.userId) {
            this.router.navigate([`/d/profile/${this.profileService.userId}/info`])
                .then(_ => {
                    // navigation is done
                });
        }

        this.data.fetch(this.userId, this.data.currentPage, this.data.perPage).subscribe((response: any) => {
            this.ics = response.data;
        });
    }

    onScrollEndHandle(data, sort = 'created_at') {
        if (typeof sort !== 'object') {
            if (sort) {
                sort = this.cleanSort(sort);
                data.sort = sort;
            }
        }

        if (!data.rqing && data.lastPage) {
            data.rqing = true;            
            this.detectChanges();
            data.fetch(this.userId, data.currentPage, data.perPage, data.sort)
            .subscribe(response => {
                data.currentPage = data.currentPage + 1;
                data.items = data.items.concat(response.data);
                data.lastPage = response.next_page_url;
                data.rqing = false;
                this.detectChanges();
            }, error => {
                this.toastService.error(error.message);
            });
        }
    }

    resetDataObj(data) {
        data.currentPage = 1;
        data.rqing = false;
        data.items = [];
        return data;
    }

    cleanSort(sort) {
        if (typeof sort === 'string') {
            if (sort === 'popular') {
                return 'popularity';
            } else {
                return 'created_at';
            }
        } else {
            return 'created_at';
        }
    }

    detectChanges() {
        this.cdr.markForCheck();
        this.cdr.detectChanges();
    }

}
