<div class="app-icon">
    <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <path d="M10,8 L17.0066023,8 C17.5552407,8 18,8.44386482 18,9 C18,9.55228475 17.5550537,10 17.0066023,10 L10,10 L10,17.0066023 C10,17.5552407 9.55613518,18 9,18 C8.44771525,18 8,17.5550537 8,17.0066023 L8,10 L0.993397683,10 C0.444759292,10 0,9.55613518 0,9 C0,8.44771525 0.444946289,8 0.993397683,8 L8,8 L8,0.993397683 C8,0.444759292 8.44386482,0 9,0 C9.55228475,0 10,0.444946289 10,0.993397683 L10,8 Z"
            id="path-icon-more"></path>
    </defs>
    <g id="Icons/Add" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <mask fill="white">
            <use xlink:href="#path-icon-more"></use>
        </mask>
        <use id="Combined-Shape" [attr.fill]="color" xlink:href="#path-icon-more"></use>
    </g>
    </svg>
</div>