import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-trash',
  templateUrl: './icon-trash.component.html',
  styleUrls: ['./icon-trash.component.scss']
})
export class IconTrashComponent implements OnInit {
  @Input() color: string = '#262628';
  @Input() size: number = 18;

  constructor() { }

  ngOnInit() {
  }

}
