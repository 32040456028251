import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-ss-store-option',
  templateUrl: './ss-store-option.component.html',
  styleUrls: ['./ss-store-option.component.scss']
})
export class SsStoreOptionComponent implements OnInit {


  @Input() selected;
  @Output() selectOption: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  selectOptionClick($event) {
    this.selectOption.emit($event);
  }
}
