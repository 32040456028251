<div id='seeMoreContainer'>
    <div class='headerContainer'>
      <div class='title'>
        <app-icon-button color="white" (click)="back()" >
        <app-icon-arrow-left color="gray"></app-icon-arrow-left>
        </app-icon-button>
        <h4>Listen - How Others Sing This</h4>
      </div>
    </div>
  
          <app-ss-featured-grid 
            [scrollable]="false"
            title={{titleToDisplay}}
            seeMorePage="ListenOthersSing"
            [hasSeeMore] = "false"
            [cols]="3"
            [colsLg]="3"
            [colsMd]="2"
            [colsSm]="1"
            [extendedSize] = "true"
            [checkOwnRecording]="true"
            [userId]="profileService.userId"
            [sortOptions]="recordingSortOptions"
            [list]="howOthersSingThis.items"
            [loading]="howOthersSingThis.loading"
            (onScrollEnd)="onScrollEndHandle(howOthersSingThis, $event)"
            (onOrderBy)="resetDataObj(howOthersSingThis);onScrollEndHandle(howOthersSingThis, $event)"
          >
            <ng-template #itemContent let-item>
              <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
                <app-ss-recording [linkActive]="false" (click)="navListen(item?.id)" [recording]="item"></app-ss-recording>
                <div class="song-actions">
                  <app-more-dot-menu>
                    <div class="dropdown-menu-content">
                      <div *ngIf="!item.favorited" (click)="favRecording(item)">Add Recording to Favorites</div>
                      <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Recording from Favorites</div>
                      <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                      <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                      <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                      <div (click)="navProfile(item.user_id)">Go to Profile Page</div>
                      <!-- <div (click)="handleDuet(item)">Duet / Jam</div> -->
                      <div *ngIf="profileService.userId == item.user_id" (click)="navEditRec(item?.id)">Edit Recording Info</div>
                      <!-- <div (click)="shareRecording(item)">Share this recording</div> -->
                    </div>
                  </app-more-dot-menu>
                </div>
              </div>
  
              <app-recording-stat (click)="navListen(item?.id)" [recording]="item"></app-recording-stat>
            </ng-template>
          </app-ss-featured-grid>
          <app-vert-space height="40"></app-vert-space>
          <app-add-to-playlist #playlist></app-add-to-playlist>
  
          <div  id="pageNavContainer"  style="margin-bottom: 13px;">
            <button *ngIf='howOthersSingThis.currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
            <p >{{currentPage}}</p>
            <button *ngIf='howOthersSingThis.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
          </div>
      </div>              
