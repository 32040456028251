<ng-template #content let-modal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="icon-inner circle-ripple circle-green">
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <div class="icon-holder">
        <i [class]="iconClass"></i>
      </div>
    </div>
    <h4>{{title}}</h4>
    <p class="block-message">{{description}}</p>
    <div class="modal-actions">
      <button class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray" (click)="c('ok')" [disabled]="paying">{{cancelText}}</button>
      <div [ngClass]="{'hide-button': paying}">
        <app-paypal-button-checkout
          (authorized)="paypalAuthorized()"
          (loading)="handleLoading()"
          (error)="handleError()"
          [charge]="charge"
        >
        </app-paypal-button-checkout>
      </div>
    </div>
  </div>
</ng-template>
