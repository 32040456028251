import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'sort'
})
export class SortPipe implements PipeTransform {

    transform(items: any, sortedBy: any): any {
        if (items && items.length) {
            return items.sort((a, b) => {
                return a[sortedBy] - b[sortedBy];
            });
        }
    }

}
