<div class="row">
    <div class="col-14 col-sm-10">
        <app-featured-list
            title="Featured On Recordings"
            [featuredList]="data.items"
            [showHeader]="true"
            [showSort]="false"
            [loading]="data.rqing"
            [scrollable]="false"
            [isInfiniteScroll]="false"
            (onOrderBy)="resetObj();handleOrderBy($event)">
            <ng-template #itemContent let-item>
            <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">    
                 <!-- Remove rounded edge -->
                <div class="col-auto px-0 align-items-center"> 
                    <div class="featured-list--item--poster"
                         [ngStyle]="{'background-image': 'url('+item?.cover+')'}">
                        <img [src]="item?.photo" alt="" class="img-fluid">
                    </div>
                    <app-icon-camera  id='cam' *ngIf='item?.webcam' color="white" size="12"></app-icon-camera>
                </div>
                <div class="col featured-item--body align-items-center" title="Play" [routerLink]="['/d/listen/', item?.id]">
                    <h1>{{item?.song.title | truncateText:getTruncateValue(item, 60):'...'}} 
                        <div  class="rec-tags" [class.tags-pill]="pill" *ngIf="item.badges != 0" [ngStyle]="getStyle(item)" > {{ getRecordingTag(item,60)}}  </div> 
                        <br>
                        <span>
                            <!-- {{item.users[0].name}} -->
                            <div class="recording-counter" title="Go to Profile Page">
                                <app-user-badge-level [user]="item?.users[0]">
                                </app-user-badge-level>
                                <app-user-badge-level [user]="item?.users[1]">
                                </app-user-badge-level>
                            </div>
                        </span>
                    </h1>
                </div>
                <div class="col featured-item--body align-items-center justify-content-between">
                    <span class="recording-counter">
                        <i [inlineSVG]="'/assets/pictures/icons/time.svg'"></i>
                        {{item?.created_at | dateFormat | date:'MMM d, yyyy h:mma' | dateAmpm}}
                    </span>
                    <div class="recording-counter">
                        <div *ngIf="item?.allow_loves; else track_loves_na">
                            <i [inlineSVG]="'/assets/pictures/icons/heart.svg'"></i>
                            {{formatLove(item?.stats?.love)}}
                        </div>
                        <ng-template #track_loves_na> 
                            <i [inlineSVG]="'/assets/pictures/icons/heart.svg'"></i>  
                        </ng-template>
                    </div>
                    <div class="recording-counter">
                        <div *ngIf="item?.allow_comments; else track_comments_na">
                            <i [inlineSVG]="'/assets/pictures/icons/bubble.svg'"></i>
                            {{formatComments(item?.stats?.comments)}}
                        </div>
                        <ng-template #track_comments_na> 
                            <i [inlineSVG]="'/assets/pictures/icons/bubble.svg'"></i>  
                        </ng-template>                    
                    </div>
                    <div class="recording-counter">
                        <div *ngIf="item?.track_views; else track_views_na">
                            <i [inlineSVG]="'/assets/pictures/icons/eye.svg'"></i>
                            {{formatViews(item?.stats?.views)}}
                        </div>
                        <ng-template #track_views_na> 
                            <i [inlineSVG]="'/assets/pictures/icons/eye.svg'"></i> 
                        </ng-template>                        
                    </div>
                </div>
                <div class="col-auto featured-list--item-action align-items-center">
                    <a [routerLink]="['/d/listen/', item?.id]" title="Play">
                        <app-icon-button role="button" [shadow]="true" size="40px" color="#58c7c9">
                            <app-icon-play color="white"></app-icon-play>
                        </app-icon-button>
                    </a>
                    <app-more-dot-menu title="Options">
                    <div class="dropdown-menu-content dropdown-menu-profile-recordings">
                        <div *ngIf="!item.favorited" (click)="favRecording(item)">Add Recording to Favorites</div>
                        <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Recording to Favorites</div>
                        <div [routerLink]="['/recorder/', item?.song.id]">Sing this song</div>
                        <!-- <div *ngIf="item?.allow_duets" [routerLink]="['/duet-recorder/', item?.id]">Duet/Jam with this Recording</div> -->
                        <div [routerLink]="['/d/artists/', item?.song.artist_id]">Artist Profile</div>
                        <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                    </div>
                    </app-more-dot-menu>
                </div>
            </div>    
            </ng-template>
        </app-featured-list>
        <div   id="pageNavContainer" style="margin-bottom: 13px;"><button (click)="getPrevRecordings()">&lt; PREV</button><p>Page: {{currentPage}}</p><button (click)="getNextRecordings()">NEXT &gt;</button></div>
    </div>
</div>