import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vert-space',
  templateUrl: './vert-space.component.html',
  styleUrls: ['./vert-space.component.scss']
})
export class VertSpaceComponent implements OnInit {
  @Input() height = 30;

  constructor() { }

  ngOnInit() {
  }

  get value() {
    return this.height + 'px';
  }

}
