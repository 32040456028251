import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class SortingService {
  private selector = new Subject<any>();

  sendMessage(message: string) {
    this.selector.next(message);
  }

  getMessage(): Observable<any> {
    return this.selector.asObservable();
  }
}
