
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { HttpService } from './http.service';

@Injectable()
export class RecorderService extends HttpService {
  duet: boolean;
  duetId: number;

  constructor(private http: HttpClient) {
    super();
  }
  getDuet() {
    return this.duet;
  }
  getDuetId() {
    return this.duetId;
  }
  setDuet(value: boolean, id: number) {
    this.duet = value;
    this.duetId = id;
  }
  getSongForRecorder(id: string) {
    const options = this.getHeaders();
    return this.http
      .get(this.root + '/songs/' + id + '/record', options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
    /*.catch((err) => {
                return Observable.throw(err);
            });*/
  }

  getRecordingForPlayer(id: string) {
    const options = this.getHeaders();
    return this.http
      .get(this.root + '/recordings/' + id + '/play', options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      catchError(err => {
        return observableThrowError(err);
      }));
  }

  getDuetForRecorder(id) {
    const options = this.getHeaders();
    options.headers.append('X-Requested-With', 'XMLHttpRequest');
    //options.headers.append('Site-Host', 'As)_(ko;8as^65r');
    return this.http
      .get(this.root + '/duets/' + id + '/record', options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      catchError(err => {
        return observableThrowError(err);
      }));
  }
}
