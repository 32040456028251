<main class="textpagesMain">
    <h1>SingSnap Copyright</h1>
    <article class="textpagesArticle">
  <h4>At SingSnap, we are committed to helping everyone protect their copyright and other intellectual property rights. SingSnap has licensing agreements with several copyright collective management organizations, including those listed on our Partners page.</h4>
  <p>SingSnap is governed by Canadian law, which not only has a fair dealing provision that is similar to fair use laws found in the United States, but also features specific protections for non-commercial user-generated content. That provision, found at Section 29.21 of the Canadian Copyright Act, is the first of its kind anywhere in the world. It gives users the right to use copyrighted works when they create non-commercial user generated content under appropriate circumstances. Those circumstances include creating something new such as a remix, mashup, or new work that combines something new with something old, attribution where possible, the use of authorized content, and the assurance that the new work does not create a substantial adverse effect on the original work. The provision also grants intermediaries such as SingSnap the right to disseminate non-commercial user generated content.</p>
  <p>While we believe that many of the works posted by SingSnap users qualify for the non-commercial user generated content provision, we want to ensure that all copyright owners feel adequately protected. After having regard for fair use, fair dealing, and the non-commercial user generated content rules, copyright owners who still believe that their works have been posted in violation of the law, may file a notice of infringement by email at <a href="mailto:copyright@singsnap.com">copyright@singsnap.com</a>, by fax at 613-909-7525, or by mail at SingSnap Corporation, 2797 Quinn Road, Ottawa, Ontario, K1T 3V4, Canada.</p>
  <p>A valid notice of infringement must include the following information:</p>
  <p class="listSection">1. A physical or electronic signature of a person authorized to act on behalf of the owner of the work(s) that has/have been allegedly infringed;</p>
  <p class="listSection">2. Identification of the works or material claimed to have been infringed, or, if multiple works are covered by a single notification, a representative list of such works;</p>
  <p class="listSection">3. Identification of the specific material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient for SingSnap to locate the material;</p>
  <p class="listSection">4. Information reasonably sufficient to permit SingSnap to contact the claimant, such as an address, telephone number, and, if available, an electronic mail address at which the complaining party may be contacted;</p>
  <p class="listSection">5. A statement by the claimant that they have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</p>
  <p class="listSection">6. A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</p>
  <p>A valid notice of infringement may result in the removal of the content from SingSnap. In the event of a content removal, users will be entitled to file a counter-notification. A valid counter-notification must include name, address and telephone number, physical or electronic signature, identification of the content at issue, and a statement confirming good faith belief that it was removed in error. The claimant who requested the content removal can use that information to assist them in filing a court action. This means that SingSnap is required to pass the contact information provided in the counter-notification to the claimant.</p>
  <p>Upon receipt of a valid counter-notification, SingSnap will forward it to the claimant. If the claimant does not notify SingSnap that they have filed an action seeking a court order to restrain the alleged infringing activity on SingSnap related to the material in question within 15 business days, we will promptly restore the eligible content.</p>
  <p>SingSnap's policy is to disable the accounts of people who repeatedly infringe others' intellectual property rights when appropriate.</p>
  <p style="padding-bottom: 30px;">If you have any questions about this copyright policy, contact SingSnap at <a href="mailto:support@singsnap.com">support@singsnap.com.</a></p>
  </article>
  </main>