import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    EventEmitter, Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {RecordingService, ProfileService} from '@app/core';
import {MessageBoardService, Data} from '@app/core';
import {Subject} from 'rxjs';
import {ReactionComponent} from '../../reaction/reaction.component';
import {Comment} from './comment.model';

import {ReportModalContentComponent} from '../../report-modal-content/report-modal-content.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentComponent implements OnInit, OnDestroy {
    @Input() comment: Comment;
    @Input() editableFor = 15 * 60 * 1000; // 15 minutes
    @Input() userId;
    @Input() ownerUser;
    @Input() isReply = false;
    @Input() isLast = false;
    @Input() type = 'comments'; // comments | message-board
    @Input() depth = 0;
    @Output() onError: EventEmitter<any> = new EventEmitter();
    @Output() onQuote: EventEmitter<any> = new EventEmitter();
    @Output() onReply: EventEmitter<any> = new EventEmitter();
    @Output() onCommentDelete: EventEmitter<any> = new EventEmitter();


    @ViewChild('listenCommentReaction', { static: true }) listenCommentReaction: ReactionComponent;

    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private favoriteSource = new Subject<any>();
    favorite$ = this.favoriteSource.asObservable();
    reactionList = [];
    reactions = [];
    placement = 'right';   // placement = 'right-bottom';  
    editMode = false;
    replyMode = false;
    repliesOpen = false;
    repliesPerPage = 10;
    repliesPage = 1;
    repliesPageLast = 1;
    repliesHasBeenOpen = false;
    favoriting = null;
    loadingReplies = false;
    createdAtTime;
    service: any;
    parentId;
    commentId;
    loggedUserId;
    isOwnComment = false;
    commentType = '';
    commentUser: any;

    constructor(
        
                private router: Router,
                private cdr: ChangeDetectorRef,
                private injector: Injector,
                private profileService: ProfileService,
                private toast: ToastrService,
                private modalService: NgbModal,
                private data: Data) {
            this.loggedUserId = profileService.user.id;
    }
 

    ngOnInit() { console.log("quoteinit: ", this.comment)
        this.profileService.getUser(this.comment.user.id).subscribe(response=>{
            this.commentUser = response;
        });
        if(this.comment.user.id == this.loggedUserId) {
            this.isOwnComment = true;
        } else {
            this.isOwnComment = false;
        }

        if (this.type === 'message-board') {
            this.service = this.injector.get<any>(MessageBoardService);
            this.parentId = this.comment.thread_id;
            this.commentType = 'reply';
        } else {
            this.service = this.injector.get<any>(RecordingService);
            this.parentId = this.comment.recording_id;
            this.commentType = 'comment';
        }

        this.commentId = this.comment.comment_id ? this.comment.comment_id : this.comment.reply_id;

        this.favorite$.pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.favoriting = null;
                if (data.success) {
                    this.comment.loved = data.model.loved;
                    this.comment.stats.love = data.model.stats.love;
                    this.cdr.detectChanges();
                }
            });

        this.reactionList = (this.comment.reactions || []).concat([]);
        this.reactions = this.comment.reactions.filter(reaction => {
            return reaction.count > 0;
        });
        if (this.reactions.length > 1) {
            this.reactions.sort((a, b) => {
                return b.count - a.count;
            });
        }

        this.listenCommentReaction.emoji$.pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(emojiReaction => {
                this.service.reactionComment(this.commentId, emojiReaction.id)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(({success, model}) => {
                        if (success) {
                            this.reactionList = model.concat([]);
                            this.reactions = model.filter(reaction => {
                                return reaction.count > 0;
                            });
                            if (this.reactions.length > 1) {
                                this.reactions.sort((a, b) => {
                                    return b.count - a.count;
                                });
                            }
                            this.cdr.detectChanges();

                            if(this.type === 'message-board'){
                                this.data.msgBrdReplyReaction = this.data.msgBrdReplyReaction + 1;  console.log("yoyo: ",this.commentUser.email)
                                //replyList
                                let emailFound = this.data.msgBrdReplyList.find((value) => {
                                    if (value.topic_reply_recipient_email === this.commentUser.email) return true;
                                });
                                if (emailFound){
                                    var reactionData = this.data.msgBrdReplyList.find(value => value.topic_reply_recipient_email === this.commentUser.email).topic_reply_reaction++;
                                    this.data.msgBrdReplyList.find(value => value.topic_reply_recipient_email === this.commentUser.email).topic_reply_emoji.push(emojiReaction.code);
                                    
                                } else {
                                    this.data.msgBrdReplyList.push(
                                        {
                                            'topic_reply_recipient_email': this.commentUser.email,
                                            'topic_reply_recipient_name': this.commentUser.screen_name,
                                            'newsletter': this.commentUser.newsletter,
                                            'topic_reply_quote': 0,
                                            'topic_reply_love': 0,
                                            'topic_reply_reaction': 1,
                                            'topic_reply_emoji': []
                                        }
                                    );                
                                    this.data.msgBrdReplyList.find(value => value.topic_reply_recipient_email === this.commentUser.email).topic_reply_emoji.push(emojiReaction.code);
                                }
                                //Post_reply_list
                                emailFound = this.data.msgBrdPost_Reply_List.find((value) => {
                                    if (value.topic_reply_recipient_email === this.commentUser.email) return true;
                                });
                                if (emailFound){
                                    var reactionData = this.data.msgBrdPost_Reply_List.find(value => value.topic_reply_recipient_email === this.commentUser.email).topic_reply_reaction++;
                                    this.data.msgBrdPost_Reply_List.find(value => value.topic_reply_recipient_email === this.commentUser.email).topic_reply_emoji.push(emojiReaction.code);
                                    
                                } else {
                                    this.data.msgBrdPost_Reply_List.push(
                                        {
                                            'topic_reply_recipient_email': this.commentUser.email,
                                            'topic_reply_recipient_name': this.commentUser.screen_name,
                                            'newsletter': this.commentUser.newsletter,
                                            'topic_reply_quote': 0,
                                            'topic_reply_love': 0,
                                            'topic_reply_reaction': 1,
                                            'topic_reply_emoji': []
                                        }
                                    );                
                                    this.data.msgBrdPost_Reply_List.find(value => value.topic_reply_recipient_email === this.commentUser.email).topic_reply_emoji.push(emojiReaction.code);
                                }
                            } else {
                                let emailFound = this.data.recordingCommentReplies.find((value) => {
                                    if (value.recording_reply_recipient_email === this.commentUser.email) return true;
                                });
                                if (emailFound){
                                    var reactionData = this.data.recordingCommentReplies.find(value => value.recording_reply_recipient_email === this.commentUser.email).recording_reply_reaction++;
                                    this.data.recordingCommentReplies.find(value => value.recording_reply_recipient_email === this.commentUser.email).recording_reply_emoji.push(emojiReaction.code);
                                    
                                } else {
                                    this.data.recordingCommentReplies.push(
                                        {   
                                            'recording_reply_recipient_email': this.commentUser.email,
                                            'recording_reply_recipient_name': this.commentUser.screen_name,
                                            'newsletter': this.commentUser.newsletter,
                                            'recording_reply_replies': 0,
                                            'recording_reply_loved': 0,
                                            'recording_reply_reaction': 1,
                                            'recording_reply_emoji': []
                                        }
                                    );                
                                    this.data.recordingCommentReplies.find(value => value.recording_reply_recipient_email === this.commentUser.email).recording_reply_emoji.push(emojiReaction.code);
                                }
                            }
                        }
                    });
            });
        this.createdAtTime = new Date(this.comment.created_at).getTime();



        console.log('ownerUser', this.loggedUserId);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onCommentEditHandle(event) {
        console.log(event, this.commentId);
        this.service.updateComment(event.content, event.commentId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                response => {
                    if (response.success) {
                        const comment = response.model as Comment;
                        this.comment.body = comment.body;
                    } else {
                       // this.onError.emit(error);
                       this.toast.error('Error, could not leave reply.');
                    }
                    event.onComplete();
                    this.toggleEditBox();
                    this.cdr.detectChanges();
                }, error => {
                    event.onComplete();
                   // this.onError.emit(respose));
                   this.toast.error('This reply can no longer be edited.');
                   this.toggleEditBox();
                   this.cdr.detectChanges();
                }); 
    }

    //Cancel Commnet
    onCancelCommentEditHandle(event) {
        this.toggleEditBox();
        this.cdr.detectChanges();
    }

    onCommentReplyHandle({content, recordingId, commentId, onComplete}) {
        this.service.sendComment(content, recordingId, commentId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                response => {
                    this.loadingReplies = false;
                    if (response.success) {
                        this.comment.stats.replies = response.model.parent ? response.model.parent.stats.replies : response.model.stats.replies;
                        // this.comment.replies = response.model.data as Comment[];
                        this.comment.replies = this.comment.replies || [];
                        this.comment.replies.unshift(response.model);
                        // add one page for next pagination load
                        this.repliesPage = response.model.current_page + 1;
                        this.repliesHasBeenOpen = false;
                        this.cdr.detectChanges();

                        //sendgrid email
                        let emailFound = this.data.recordingCommentReplies.find((value) => {
                            if (value.recording_reply_recipient_email === this.commentUser.email) return true;
                        });
                        if (emailFound){
                            this.data.recordingCommentReplies.find(value => value.recording_reply_recipient_email === this.commentUser.email).recording_reply_replies++;
                        } else {
                            this.data.recordingCommentReplies.push(
                                {
                                    'recording_reply_recipient_email': this.commentUser.email,
                                    'recording_reply_recipient_name': this.commentUser.screen_name,
                                    'newsletter': this.commentUser.newsletter,
                                    'recording_reply_replies': 1,
                                    'recording_reply_loved': 0,
                                    'recording_reply_reaction': 0,
                                    'recording_reply_emoji': []
                                }
                            );
                        }

                    } else {
                        //this.onError.emit(response);
                        this.toast.error('Error, could not post reply.');
                    }
                    onComplete();
                    this.repliesOpen = true;
                    this.toggleReplyBox();
                }, error => {
                    if (error.error.reason === 'UNAUTHENTICATED'){
                    this.whenLoggedOut();
                    }
                    console.log(error);
                });
    }

    canEdit() {
        return this.comment.can_edit && this.comment.user_id === this.userId;
    }

    canReply() {
        return this.comment.user_id !== this.userId;
    }

    toggleEditBox() {
        this.editMode = !this.editMode;
        this.cdr.detectChanges();
    }

    toggleReplyBox() {
        this.replyMode = !this.replyMode;
    }

    isEditing() {
   
        return this.editMode;
    }

    isReplying() {
        return this.replyMode;
    }

    getEditText() {
        return this.isEditing() ? 'CANCEL' : 'EDIT';
    }

    //TONY REPLY
    getReplyText() {
        return this.isReplying() ? 'CANCEL' : 'REPLY';
    }

    loadRepliesOnToggle() {
        this.repliesOpen = true;
        this.comment.replies = this.comment.replies || [];
        if (this.comment.replies.length < this.comment.stats.replies) {
            this.loadMoreReplies();
        }
    }

    loadMoreReplies() {
        this.repliesOpen = true;
        if (!this.loadingReplies) {
            this.loadingReplies = true;
            this.service.getReplies(this.comment.comment_id, this.repliesPage, this.repliesPerPage)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(response => {
                    this.comment.replies = this.comment.replies || [];
                    this.comment.replies = [...this.comment.replies, ...response.data];
                    console.log(this.comment.replies);
                    this.repliesPageLast = response.last_page;
                    if (this.repliesPage + 1 <= this.repliesPageLast) {
                        this.repliesPage = this.repliesPage + 1;
                    }
                    this.loadingReplies = false;
                    this.cdr.detectChanges();
                }, error => {
                    this.loadingReplies = false;
                });
        }
    }

    toggleFavorite(commentId) {
        this.favoriting = '';
        (this.comment.loved ? this.unFavorite(commentId) : this.favorite(commentId))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.favoriteSource.next(data);
            });
    }

    favorite(commentId) {
        return this.service.favoriteComment(commentId);
    }

    unFavorite(commentId) {
        return this.service.unFavoriteComment(commentId);
    }

    trackByName(index, comment) {
        return comment.id;
    }

    quote() {
        console.log('quote', this.comment.body); 
        console.log('quote_user', this.comment.user); 
        this.onQuote.emit({
            body: this.comment.body,
            author: this.comment.user
        });
    }

    report() {
        const modalRef = this.modalService.open(ReportModalContentComponent, {windowClass: 'report-actions-modal'});
        modalRef.componentInstance.type = this.type;
        modalRef.componentInstance.id = this.commentId;
        modalRef.componentInstance.coment = this.comment;
        modalRef.componentInstance.commentUserId = this.comment.user.id;
        //console.log(this.comment);
    }

    reply() {
        this.onReply.emit({});
    }

    deleteComment(comment) {
        this.onCommentDelete.emit(comment);
    }

    timeAgoInWords(date){
        let startDateTime = false;
        let  dateNow: any;
        dateNow = new Date();
        let  datePast: any;
        datePast = new Date(date);
        const localTime = dateNow.getTime();
        const pastTime = datePast;
          let seconds = Math.floor((localTime - pastTime) / 1000);
          let intervalType;
          let interval = Math.floor(seconds / 31536000);
             if (interval >= 1) {
            startDateTime = true;  
            intervalType = 'year';
            } else {
                    interval = Math.floor(seconds / 2592000);
                     if (interval >= 1) {
                        startDateTime = true;  
                        intervalType = 'month';
                     } else {
                        interval = Math.floor(seconds / 86400);
                        if (interval >= 1) {
                        startDateTime = true;  
                        intervalType = 'day';
                        } 

                           else{ interval = Math.floor(seconds / 43200);
                               if (interval >= 1) {
                                startDateTime = true;  
                                intervalType = 'half day';
                             }
                        
                            else {
                            interval = Math.floor(seconds / 3600);
                            if (interval >= 1) {
                            intervalType = "hour";
                             } else {
                                interval = Math.floor(seconds / 60);
                                if (interval >= 1) {
                                    intervalType = "minute";
                                } else {
                                    interval = seconds;
                                    intervalType = "second";
                                }
                            }
                        }
                 }  }//new half day else
            }
          if (interval > 1 || interval === 0) {
            intervalType += 's';
          }
          if( !startDateTime  ){
            return interval + ' ' + intervalType + ' ago';
            } else {
                var datePipe = new DatePipe("en-US");
                return datePipe.transform(date, 'MMM d, yyyy hh:mma');
            }
     }

      
    whenLoggedOut(){
       // this.router.navigate(['/login']);
       // window.location.href = '/#/login?url=' + this.router.url;
       this.router.navigateByUrl("/login?url=" + this.router.url );
    }

 }
