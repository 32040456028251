import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { ProfileService } from '@app/core';

@Component({
  selector: 'app-settings-profile',
  templateUrl: './settings-profile.component.html',
  styleUrls: ['./settings-profile.component.scss']
})
export class SettingsProfileComponent implements OnInit {
  pwdForm: UntypedFormGroup;
  emailError = ''
  emailForm: UntypedFormGroup;

  constructor(
    private service: ProfileService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private toast: ToastrService
  ) {
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.pwdForm = this.fb.group({
      current_password: ['', [Validators.required, Validators.minLength(4)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_confirmation: ['', [Validators.required, Validators.minLength(6)]],
    }, {
      validator: this.passwordMatchValidator
    });
    this.pwdForm.setValue({
      current_password: '',
      password: '',
      password_confirmation: ''
    });

    this.emailForm = this.fb.group({
      current_email: ['', [    Validators.required, Validators.email]],
      email: ['', [Validators.required, Validators.email]],
    });
    this.emailForm.setValue({
      current_email: this.service.user.email,
      email: '',
    });
  }

  passwordMatchValidator(g: UntypedFormGroup) {
    return g.get('password').value === g.get('password_confirmation').value
      ? null : { mismatch: true };
  }


  onPasswordSubmit() {
    if (this.pwdForm.getError('mismatch')) {
      this.toast.error('The new passwords don\'t match.');
    }
    this.pwdForm.controls.current_password.markAsDirty();
    this.pwdForm.controls.password.markAsDirty();
    this.pwdForm.controls.password_confirmation.markAsDirty();
    if (!this.pwdForm.valid) {
      return;
    }
    const data = this.pwdForm.value;
    this.service.updatePassword(data).subscribe(result => {
      this.toast.success('Your password has been updated.');     
      this.pwdForm.reset();

    }, (error) => {
      this.toast.error('Invalid password.');
    });
  }

  onEmailSubmit() {
    this.emailError = '';
    console.log(this.emailForm.value)
    this.emailForm.controls.current_email.markAsDirty();
    this.emailForm.controls.email.markAsDirty();
    if (!this.emailForm.valid) {
      return;
    }
    const data = this.emailForm.value;
    delete data.current_email
    console.log(data)
    this.service.updateEmail(data).subscribe(result => {
      this.toast.success('Your email has been updated.');
      this.emailForm.reset();
    }, (error) => {
      if(error.error.message == 'Duplicate Entry') {
        this.emailError = 'Email is already being used! Try a different one.'
      }
    });
  }
  goBack() {
    this.router.navigate(['/d/settings/general']);
  }
}
