<div class="container-fluid main-container" *ngIf="product">
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 product-slideshow">
      <img src="{{product.images[0].src}}" width="50%" alt="" *ngIf="product.images">
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 product-basic">
      <div class="product-category">
        <span class="category-color" *ngIf="product.tags">{{product.tags[0].name | uppercase}} / </span><span *ngIf="product.categories">{{product.categories[0].name | uppercase}}</span>
      </div>
      <div class="product-name">
        <h2><b>{{product.name}}</b></h2>
      </div>
      <div class="product-price">
        <p>${{product.price}}</p>
      </div>
      <div class="product-description" [innerHTML]="product.short_description"></div>
      <div class="product-choices">
        <div class="row" style="text-align: center">
          <span *ngFor="let att of product.attributes" class="center-span">
            <div class="col-lg-12 product-color" *ngIf="att.name == 'color'">
              <span>COLOR</span>
              <div class="product-color-shape">
                <i class="fa product-color-circle" [style.color]="color" aria-hidden="true" *ngFor="let color of att.options" (click)="addColor(color)"
                   [ngClass]="{'fa-check-circle': color == product.color, 'fa-circle': color != product.color}"></i>
              </div>
            </div>
          </span>
          <span *ngFor="let att of product.attributes;" class="center-span">
            <div class="col-lg-12 product-size" *ngIf="att.name == 'size'">
              <span *ngIf="att.name == 'size'">SIZE</span>
              <div class="product-size-shape">
                <span class="product-size-shape-number" *ngFor="let size of att.options; let last = last"
                      [ngClass]="{'no-border': last==true, 'choiceBackground': size == product.size}" (click)="addSize(size)">{{size}}</span>
              </div>
            </div>
          </span>
        </div>
      </div>
      <div class="product-button">
        <button type="submit" class="btn cl_sing_now" (click)="addToCart(product)">ADD TO CART<i class="fa fa-angle-right cart-arrow" aria-hidden="true"></i></button>
      </div>
      <div class="product-show-details" (click)="showHide()">
        <span>
          <i class="fa fa-plus-square-o" aria-hidden="true" *ngIf="showCategory == false"></i>
          <i class="fa fa-minus-square-o" aria-hidden="true" *ngIf="showCategory != false"></i>
          Product details</span>
      </div>

    </div>
  </div>
</div>
<div class="container-fluid details-category" *ngIf="showCategory == true">
  <div class="row">
    <div class="col-lg-6 no-padding">
      <div class="col-lg-12 no-padding" *ngFor="let image of product.images; let e = index">
        <img src="{{image.src}}" width="100%" alt="" *ngIf="e > 0">
      </div>
    </div>
    <div class="col-lg-6">
      <div class="details-name">
        <h2>Product <b>Details</b></h2>
      </div>
      <div class="details-description" [innerHTML]="product.description"></div>
    </div>
  </div>
</div>
