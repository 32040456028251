<div class="reaction">
    <div class="reaction__reaction-selector dropdown" [ngClass]="placement" ngbDropdown [placement]="placement" #reactionDrop="ngbDropdown">
        
        <div class="reaction-add"
            id="dropdownReaction"
            ngbDropdownAnchor 
            (click)="reactionDrop.toggle()" 
            (focus)="reactionDrop.open()"
            title="{{placement}}"
        >
            <app-icon-add-reaction size="35"></app-icon-add-reaction>
        </div>
        <div ngbDropdownMenu aria-labelledby="dropdownReaction">
            <button class="dropdown-item" *ngFor="let emoji of reactionList" (click)="reactionSelect(emoji)">{{emoji.code}}</button>
        </div>
    </div>
    <div class="reaction__reactions-list" *ngIf="reactions.length">
            <li *ngFor="let reaction of reactions">
                {{reaction.code}} <span class="badge badge-pill badge-primary" style="background-color:#58C7C9">{{reaction.count}}</span>
            </li>
    </div>
</div>
