import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeReComponent } from '@app/dashboard-re';
import { DashboardReComponent } from '@app/dashboard-re';
import { ListenReComponent } from '@app/listen-re';
import {
    SingReComponent,
    SingRecorderComponent,
    SingUploaderComponent,
    SingUploadSuccessComponent,
    RemixRecordingComponent
} from '@app/sing-re';

import { WebsiteHomeComponent } from './components/website/website-home/website-home.component';
import { MainComponent } from './components/website/main/main.component';
import { LoginComponent } from './components/website/login/login.component';
import { SignUpComponent } from './components/website/sign-up/sign-up.component';
import { LoginAuthGuardService } from '@app/core';
import { AuthGuardService } from '@app/core';
import { EnvSwitcherComponent } from './env-switcher/env-switcher.component';
import { LegacyViewerComponent } from './legacy-viewer/legacy-viewer.component';
import { ListenSingleReComponent } from './listen-re/listen-single-re/listen-single-re.component';
import { PlayPlaylistComponent } from './play-playlist/play-playlist.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { MyRecordingsComponent } from '@app/my-recordings/my-recordings.component';
import { ListenMoreComponent } from './listen-re/listen-more/listen-more.component';
import { MyFavoritesComponent } from './my-favorites/my-favorites.component';
import { ResetPasswordComponent } from './components/website/reset-password/reset-password.component';
import { RecoveryComponent } from './components/website/recovery/recovery.component';
import { ResetCodeComponent } from './components/website/reset-code/reset-code.component';
import { ConfirmResetComponent } from './components/website/confirm-reset/confirm-reset.component';
import { SignupCompleteComponent } from './components/website/signup-complete/signup-complete.component';
import { AuthSingComponent } from './components/website/auth-sing/auth-sing.component';
import { AuthHelpComponent } from './components/website/auth-help/auth-help.component';
import { AuthListenComponent } from './components/website/auth-listen/auth-listen.component';
import { MyRecordingsRecycleBinComponent } from '@app/my-recordings-recycle-bin/my-recordings-recycle-bin.component';
import { ChatComponent } from '@app/chat/chat.component';

import {ListenFeaturedRecordingsComponent} from './listen-re/listen-featured-recordings/listen-featured-recordings.component';
import {SingFeaturedSongsComponent} from './sing-re/sing-featured-songs/sing-featured-songs.component';
import {HomeNewReleasesComponent} from './dashboard-re/home-re/home-new-releases/home-new-releases.component';
import {HomeAnnouncementsComponent} from './dashboard-re/home-re/home-announcements/home-announcements.component';
import {ListenFavoriteRecordingsComponent} from './listen-re/listen-favorite-recordings/listen-favorite-recordings.component';
import {ListenFavoriteMembersComponent} from './listen-re/listen-favorite-members/listen-favorite-members.component';
import {ListenInnerCirclesComponent} from './listen-re/listen-inner-circles/listen-inner-circles.component';
import {ListenRecentlyRecordedComponent} from './listen-re/listen-recently-recorded/listen-recently-recorded.component';
import {ListenNewMemberRecordingsComponent} from './listen-re/listen-new-member-recordings/listen-new-member-recordings.component';
import {ListenJamsComponent} from './listen-re/listen-jams/listen-jams.component';
import {ListenDuetsComponent} from './listen-re/listen-duets/listen-duets.component';
import {SingFavoriteSongsComponent} from './sing-re/sing-favorite-songs/sing-favorite-songs.component';
import {SingArtistsComponent} from './sing-re/sing-artists/sing-artists.component';
import {SingOpenDuetsComponent} from './sing-re/sing-open-duets/sing-open-duets.component';
import {SingDuetsComponent} from './sing-re/sing-duets/sing-duets.component';
import {TermsComponent} from './terms/terms.component'
import {PrivacyComponent} from './privacy/privacy.component'
import {CodeOfConductComponent} from './code-of-conduct/code-of-conduct.component'
import {HelpComponent} from './help/help.component'
import { SuperrecorderComponent } from './superrecorder/superrecorder.component';
import {PartnersComponent} from './partners/partners.component'
import { CopyrightComponent } from './components/website/policies/copyright/copyright.component';
import { SuperremixerComponent } from './superremixer/superremixer.component';
import {ListenOthersSingComponent} from './listen-re/listen-others-sing/listen-others-sing.component';
import {MyListenMoreComponent} from './my-listen-more/my-listen-more.component';
import {MySingMoreComponent} from './my-sing-more/my-sing-more.component';
import {SeeMoreComponent} from './see-more/see-more.component';
import { ArtistSeemoreComponent } from './artists/artist-seemore/artist-seemore.component';



const appRoutes: Routes = [
    // {
    //     path: 'shell',
    //     loadChildren: './shell/shell.module#ShellModule'
    // },
    // {
    //     path: 'shell-ms',
    //     loadChildren: './shell-ms/shell-ms.module#ShellMsModule'
    // },
    {
        path: 'message-board',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./message-board/message-board.module').then(m => m.MessageBoardModule)
    },
    {
        path: 'recorder/:id',
        canActivate: [AuthGuardService],
        component: SuperrecorderComponent
    },
    {
        path: 'duet-recorder/:id',
        canActivate: [AuthGuardService],
        component: SuperrecorderComponent, 
        data: { jam: true }
    },
    {
        path: 'remixer/:id',
        canActivate: [AuthGuardService],
        component: SuperremixerComponent
    },
    {
        path: '',
        component: MainComponent,
        children: [
            { path: '', redirectTo : 'login', pathMatch : 'full'},
            { path: 'login', canActivate: [LoginAuthGuardService], component: LoginComponent },
            { path: 'sign-up', canActivate: [LoginAuthGuardService], component: SignUpComponent },
            { path: 'recovery', canActivate: [LoginAuthGuardService], component: RecoveryComponent },
            { path: 'reset-password', canActivate: [LoginAuthGuardService], component: ResetPasswordComponent },
            { path: 'reset-code', canActivate: [LoginAuthGuardService], component: ResetCodeComponent },
            { path: 'confirm-reset', canActivate: [LoginAuthGuardService], component: ConfirmResetComponent },
            { path: 'listen', canActivate: [LoginAuthGuardService], component: AuthListenComponent },
            { path: 'sing', canActivate: [LoginAuthGuardService], component: AuthSingComponent },
        ]
    },
    {
        path: 'd',
        canActivate: [AuthGuardService],
      //  canActivateChild: [AuthGuardService],
        component: DashboardReComponent,
        children: [
            { path: 'help', component: HelpComponent },
            { path: 'signup-complete', component: SignupCompleteComponent },
            { path: 'home', component: HomeReComponent },
            { path: 'play/:id', component: PlayPlaylistComponent },
            { path: 'listen', component: ListenReComponent },
            { path: 'listen/:id', component: ListenSingleReComponent },
            { path: 'contests/candidacy/:candidacyId/recording/:id', component: ListenSingleReComponent, data: {candidate: true} },
            { path: 'listen/:id/more', component: ListenMoreComponent },
            { path: 'sing', component: SingReComponent },
            { path: 'sing/:id', component: SingRecorderComponent },
            //{ path: 'sing/:id', redirectTo : 'recorder/:id', pathMatch : 'full'},
            { path: 'sing-recording/:id', component: SingRecorderComponent, data: { duet: true, jam: true } },
            { path: 'sing-uploader', component: SingUploaderComponent },
            { path: 'sing-uploader/success', component: SingUploadSuccessComponent },
            { path: 'remix/success', component: SingUploadSuccessComponent, data: { remix: true } },
            { path: 'remix/:id', component: RemixRecordingComponent },
            { path: 'notifications', component: NotificationsComponent },
            { path: 'env', component: EnvSwitcherComponent },
            { path: 'ListenFeaturedRecordings', component: ListenFeaturedRecordingsComponent },
            { path: 'SingFeaturedSongs', component: SingFeaturedSongsComponent },
            { path: 'HomeNewReleases', component: HomeNewReleasesComponent },
            { path: 'HomeAnnouncements', component: HomeAnnouncementsComponent },
            { path: 'ListenFavoriteRecordings', component: ListenFavoriteRecordingsComponent },
            { path: 'ListenFavoriteMembers', component: ListenFavoriteMembersComponent },
            { path: 'ListenInnerCircles', component: ListenInnerCirclesComponent },
            { path: 'ListenRecentlyRecorded', component: ListenRecentlyRecordedComponent },
            { path: 'ListenNewMemberRecordings', component: ListenNewMemberRecordingsComponent },
            { path: 'ListenJams', component: ListenJamsComponent },
            { path: 'ListenDuets', component: ListenDuetsComponent },
            { path: 'SingFavoriteSongs', component: SingFavoriteSongsComponent },
            { path: 'SingArtists', component: SingArtistsComponent },
            { path: 'SingOpenDuets', component: SingOpenDuetsComponent },
            { path: 'SingDuets', component: SingDuetsComponent },
            { path: 'termsandconditions', component: TermsComponent },
            { path: 'privacy', component: PrivacyComponent },
            { path: 'codeofconduct', component: CodeOfConductComponent },
            { path: 'partners', component: PartnersComponent},
            { path: 'copyright', component: CopyrightComponent},
            { path: 'ListenOthersSing/:id', component: ListenOthersSingComponent },
            { path: 'MySingMore', component: MySingMoreComponent },
            { path: 'MyListenMore', component: MyListenMoreComponent },
            { path: 'SeeMore/:type', component: SeeMoreComponent },
            { path: 'ArtistSeemore/:id', component: ArtistSeemoreComponent },
            {
                path: 'payments',
                loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule)
            },
            {
                path: 'artists',
                loadChildren: () => import('./artists/artists.module').then(m => m.ArtistsModule)
            },
            {
                path: 'genres',
                loadChildren: () => import('./genres/genres.module').then(m => m.GenresModule)
            },
            {
                path: 'message-board',
                loadChildren: () => import('./message-board/message-board.module').then(m => m.MessageBoardModule)
            },
            {
                path: 'ic',
                loadChildren: () => import('./innercircles/innercircles.module').then(m => m.InnercirclesModule)
            },
            {
                path: 'contests',
                loadChildren: () => import('./contests/contests.module').then(m => m.ContestsModule)
            },
            {
                path: 'songbook',
                loadChildren: () => import('./songbook/songbook.module').then(m => m.SongbookModule)
            },
            {
                path: 'private-message',
                loadChildren: () => import('./private-message/private-message.module').then(m => m.PrivateMessageModule)
            },
            {
                path: 'profile',
                loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule)
            },
            {
                path: 'settings',
                loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
            },
            {
                path: 'demo',
                loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule)
            },
            { path: 'my-recordings', component: MyRecordingsComponent },
            { path: 'my-recordings-recycle-bin', component: MyRecordingsRecycleBinComponent },
            { path: 'chat', component: ChatComponent },
            { path: 'my-recordings/:id', component: SingUploaderComponent },
            { path: 'my-comments', loadChildren: () => import('./my-recording-comments/my-recording-comments.module').then(m => m.MyRecordingCommentsModule) },
            {
                path: 'my-playlists',
                loadChildren: () => import('./my-playlist/my-playlist.module').then(m => m.MyPlaylistModule)
            },
            { path: 'my-favorites', component: MyFavoritesComponent },
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: '**', pathMatch: 'full', redirectTo: 'home' }
        ]
    },
    {
        path: 'lv',
        component: LegacyViewerComponent,
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/login'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { useHash: true, relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
