import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
    @Input() value: boolean;
    constructor() {
    }
  ngOnInit() {
  }

  changeState(check) {
      let cls = 'switch';
      if (check.className.search('checked') === -1) {
        cls += ' checked';
      }
      check.className = cls;
  }
}
