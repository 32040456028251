<div  #icon class="d-inline-block notification-dropdown" ngbDropdown #notificationDropdown="ngbDropdown">
    <i class="fa fa-bell" ngbDropdownAnchor (click)="open($event)"></i>
    <div ngbDropdownMenu aria-labelledby="dropdownManual">
        <div class="notification-header">
            <div class="notification-alert">
                Notifications 
                <!-- <span>+10</span> -->
            </div>
            <div>
                <a (click)="readAll()">Mark All As Read</a>
                <a (click)="close()" [routerLink]="['/d/notifications']">See All</a>
            </div>
         </div>
        <div class="notification-list">
            <div 
                class="notification-item" 
                [ngClass]="{'notification-item--unread': !item.read_at}"
                *ngFor="let item of notifications"
                (click)="onClick(item)"
            >
                <span class="notification-icon">
                    <img [src]="item.data.icon" *ngIf="item.data.icon">
                </span>
                <a class="notification-body" routerLink="{{getRoute(item)}}">
                    {{item.data.message}}
                </a>
                <span class="notification-time">{{formatTime(item.created_at)}}</span>
            </div>
        </div>
    </div>
</div>