import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';

@Injectable()
export class MessageService {
  constructor(private http: HttpClient, private httpService: HttpService, private utilService: UtilService) {}

  getConversationList(query: string, page: number = 1, per_page: number = 10) {
    let url = this.utilService.buildUrl(`conversations/inbox`);
    let params = new HttpParams();


    params = params.append('page', page + '');
    params = params.append('per_page', per_page + '');
    if (query) {
      params = params.append('q', `{"screen_name":"${query}"}`);
    }
    const headers = this.httpService.getHeaders();
    headers['params'] = params
    return this.http.get(url, headers);
  }

  getConversation(cId) {
    const url = this.utilService.buildUrl(`conversations/${cId}`);
    const headers = this.httpService.getHeaders();
    return this.http.get(url, headers);
  }

  getConversationMessages(id: string, per_page: number = 15) {
    const url = this.utilService.buildUrl(`conversations/${id}/messages`);
    let params = new HttpParams();
    params = params.append('per_page', per_page + '');
    const headers = this.httpService.getHeaders();
    headers['params'] = params
    return this.http.get(url, headers);
  }

  getConversationMessagesByUser(id: string, per_page: number = 15) {
    const url = this.utilService.buildUrl(`conversations/with-user/${id}`);

    let params = new HttpParams();
    params = params.append('per_page', per_page + '');
    const headers = this.httpService.getHeaders();
    headers['params'] = params
    return this.http.get(url, headers);
  }

  getMessagesFromUser(userId) {
    const url = this.utilService.buildUrl(`messages/from/${userId}`);
    const headers = this.httpService.getHeaders();
    return this.http.get(url, headers);
  }

  sendMessage(to_user: number, body: string) {
    const url = this.utilService.buildUrl(`messages`);
    const headers = this.httpService.getHeaders();
    return this.http.post(url, { to_user, body }, headers);
  }

  readMessage(id: number) {
    const url = this.utilService.buildUrl(`messages/${id}/read`);
    const headers = this.httpService.getHeaders();
    return this.http.get(url, headers);
  }

  unreadMessage(id: number) {
    const url = this.utilService.buildUrl(`messages/${id}/unread`);
    const headers = this.httpService.getHeaders();
    return this.http.post(url, {}, headers);
  }

  archiveMessage(id: number) {
    const url = this.utilService.buildUrl(`messages/${id}/archive`);
    const headers = this.httpService.getHeaders();
    return this.http.get(url, headers);
  }

  deleteMessage(ids) {
    const url = this.utilService.buildUrl(`messages`);

    const options = this.httpService.getHeaders()
    options['body'] = {
        messages: ids,
      }
    return this.http.delete(url, options);
  }

  deleteConversation(id: string) {
    const url = this.utilService.buildUrl(`conversations/${id}`);
    const headers = this.httpService.getHeaders();
    return this.http.delete(url, headers);
  }

  uploadConversationImage(id: string, file: any) {
    const url = this.utilService.buildUrl(`conversations/${id}/upload-media`);
    let formData: FormData = new FormData();
    formData.append('file', file);
    const headers = this.httpService.getHeaders();
    return this.http.post(url, formData, headers);
  }

  getArchivedList(query: string, page: number = 1, per_page: number = 15) {
    const url = this.utilService.buildUrl(`conversations/archived`);
    let params = new HttpParams();

    params = params.append('page', page + '');
    params = params.append('per_page', per_page + '');
     //add sort to archived messages
     params = params.append('sorters', '{"received_at":"DESC"}');
    if (query) {
      params = params.append('q', `{"screen_name":"${query}"}`);
    }
    const headers = this.httpService.getHeaders();
    headers['params'] = params
    return this.http.get(url, headers);
  }

  getSentItem(id: number) {
    const url = this.utilService.buildUrl(`conversations/${id}/messages/`);
    const headers = this.httpService.getHeaders();
    return this.http.get(url, headers);
  }

  getSentList(query: string, page: number = 1, per_page: number = 15) {
    const url = this.utilService.buildUrl(`conversations/sent`);
    let params = new HttpParams();

    params = params.append('page', page + '');
    params = params.append('per_page', per_page + '');
    if (query) {
      params = params.append('q', `{"screen_name":"${query}"}`);
    }
    const headers = this.httpService.getHeaders();
    headers['params'] = params
    return this.http.get(url, headers);
  }

  archiveConversation(id: string) {
    const url = this.utilService.buildUrl(`conversations/${id}/archive`);
    const headers = this.httpService.getHeaders();
    return this.http.post(url, {}, headers);
  }

  unarchiveConversation(id: string) {
    const url = this.utilService.buildUrl(`conversations/${id}/archive`);
    const headers = this.httpService.getHeaders();
    return this.http.delete(url, headers);
  }

  getFavoriteMessages(page, per_page: number = 15, query): Observable<any> {
    const url = this.utilService.buildUrl(`user/favorites/message`);
    let params = new HttpParams();
    params = params.append('per_page', per_page + '');
    params = params.append('page', page + '');
    //add sort to favorite messages
    params = params.append('sorters', '{"created_at":"DESC"}');
    if (query) {
      params = params.append('q', query);
    }
    const headers = this.httpService.getHeaders();
    headers['params'] = params
    return this.http.get(url, headers);
  }

  // favorite message content
  getFavoriteMessage(id: number) {
    const url = this.utilService.buildUrl(`user/favorites/message/${id}`);
    const headers = this.httpService.getHeaders();
    return this.http.get(url, headers);
  }

  // favorite message pagination content
  getFavoriteMessageItem(id: number, per_page: number = 15) {
    const url = this.utilService.buildUrl(`conversations/favorite/${id}`);
    //let params = new HttpParams();

    //params = params.append('per_page', per_page + '');
    const headers = this.httpService.getHeaders();
    //headers['params'] = params
    return this.http.get(url, headers);
  }

  favoriteMessage(ids) {
    const url = this.utilService.buildUrl(`messages/favorite`);
    const headers = this.httpService.getHeaders();
    return this.http.post(url, {
      messages: ids,
    }, headers);
  }

  reportMessage(id: number, reason = '', message = '') {
    const url = this.utilService.buildUrl(`messages/${id}/report`);
    const headers = this.httpService.getHeaders();

    return this.http.post(url, { reason, message }, headers);
  }

  unfavoriteMessage(ids) {
    const url = this.utilService.buildUrl(`messages/favorite`);

    const options = this.httpService.getHeaders()
    options['body'] = {messages: ids}


    return this.http.delete(url, options);
  }

  // Deprecated
  getMessageTags(q: string) {
    const url = this.utilService.buildUrl('messages/tag');
    let params = new HttpParams();

    params = params.append('q', q);
    const headers = this.httpService.getHeaders();
    headers['params'] = params
    return this.http.get(url, headers);
  }

  getData(url, per_page: number = 0, q: string = ''): Observable<any> {
    let params = new HttpParams();
    if (per_page) {
      params = params.append('per_page', per_page + '');
    }
    if (q) {
      params = params.append('q', `{"screen_name":"${q}"}`);
    }
    const headers = this.httpService.getHeaders();
    headers['params'] = params
    return this.http.get(url, headers);
  }
}
