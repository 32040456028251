<div class="report-modal" *ngIf="show">
  <div class="report-wrapper">
    <h1 class="report-modal-title">SingSnap Terms of Use</h1>
    <div class="modal-content">
      <p>Last updated: January 21, 2019</p>
      <p>THESE TERMS AND CONDITIONS CONTAIN LEGAL OBLIGATIONS. PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THE
      SINGSNAP SITE OR SINGSNAP SERVICE.</p>
      <h3>1. Introduction</h3>
      <p>Welcome to the SingSnap website. SingSnap provides an online singing service that permits you to listen to, sing-along
      with, record, and playback personalized renditions of your favourite recording under certain terms and conditions as
      set forth in this Agreement.
      <br><br>
      These Terms and Conditions apply to all SingSnap sites and services which reference these Terms and Conditions. By
      using a SingSnap Site, or any product or service (collectively the "Services" or the "SingSnap Services") available on
      a SingSnap site, you agree, without limitation or qualification, to be bound by these terms and conditions and such
      other additional or alternative terms, conditions, rules and policies which are displayed or to which you may be
      directed in connection with any particular SingSnap Site or Service, all as may be modified by SingSnap from time to
      time in its sole discretion (collectively the "Terms and Conditions").
      <br><br>
      If you do not agree with these Terms and Conditions, you may not use a SingSnap Site or any of the Services on a
      SingSnap Site. Please note that we reserve the right, at our discretion, to change, modify, add to or remove portions
      of these Terms and Conditions at any time. Please check periodically for changes, which will indicate the latest
      revision date. Your continued use of a SingSnap Site following the posting of any changes to these Terms and Conditions
      will mean you understand and accept those changes.
      <br><br>
      You are authorized to use the SingSnap Services only for personal, noncommercial use. SingSnap may not be used for
      commercial purposes without the express, written consent of SingSnap. Unauthorized or unlawful use of SingSnap will be
      investigated. Commercial advertisements, affiliate links, and other forms of solicitation may be removed from member
      profiles without notice and may result in termination of membership privileges. Appropriate legal action will be taken
      by SingSnap for any unauthorized or unauthorized unlawful use of the SingSnap Site or SingSnap Services.
      </p>
      <h3>2. Site Registration and Security</h3>
      <p>Certain of the services and related features that may be made available on a SingSnap Site may require registration or
      subscription. Should you choose to register or subscribe for any such services or related features, you agree to
      provide accurate and current information about yourself as required by the relevant registration or subscription
      process, and to promptly update such information as necessary to ensure that it is kept accurate and complete. You
      agree to be responsible for: (a) maintaining the confidentiality of any passwords or other account identifiers which
      you choose or are assigned as a result of any registration or subscription on a SingSnap Site and (b) all activities
      that occur under such password or account. Further, you agree to notify us of any unauthorized use of your password or
      account. SingSnap shall not be responsible or liable, directly or indirectly, in any way for any loss or damage of any
      kind incurred as a result of, or in connection with, your failure to comply with this section.
      </p>
      <h3>3. Site Content</h3>
      <h4>a. Restrictions on Use of Materials</h4>
      <p>All material, information and content available on a SingSnap Site, including without limitation graphics, text, audio
      clips, video clips, pictures, editorial content the manner in which such content is presented, and the SingSnap
      Services are: © 2016 SingSnap, its affiliates and/or licensors and all rights are reserved. The content and the
      SingSnap Services are protected by Canadian and international copyright laws and treaty provisions. You agree not to
      reproduce, make derivative works of, retransmit, distribute, sell, publish, communicate, broadcast, make available, or
      make any unauthorized or infringing use of any of the content obtained through a SingSnap Site or any of our Services,
      including without limitation, by framing or similar means, without the prior written consent of SingSnap.</p>
      <h4>b. Trade-Marks</h4>
      <p>SingSnap and all associated designs and logos are trade-marks of SingSnap. All other product, brand and company names
      and logos used or mentioned on a SingSnap Site or any SingSnap Service may be the trade-marks or registered trade-marks
      of their respective owners. Any use of any trade-marks appearing on a SingSnap Site or any SingSnap Service without the
      express written consent of the owner of the trade-mark is strictly prohibited.</p>
      <h4>c. Links</h4>
      <p>Please note that certain links on a SingSnap Site may take you to other websites. SingSnap provides these links only as
      a convenience and not as an endorsement by SingSnap. These linked sites are not necessarily under the control of
      SingSnap If you decide to visit any linked site, you do so at your own risk and it is your responsibility to take all
      protective measures to guard against viruses and other destructive elements. SingSnap is not responsible for the
      content of any such linked sites or any other web page which is not part of a SingSnap Site and under SingSnap's
      control. Unless otherwise expressly provided, SingSnap makes no representation or warranty regarding, and does not
      endorse, any linked site or the information, products or services appearing thereon. Accordingly, you agree that
      SingSnap will not be responsible or liable in any way for the accuracy, relevancy, copyright compliance, legality, or
      decency of material contained in any site linked from a SingSnap Site.</p>
      <h4>d. Copyright</h4>
      <p>All copyrights in and to the SingSnap Service, including but not limited to, singsnap.com (including the compilation of
      content, postings, links to other Internet resources, and descriptions of those resources), and software, are owned by
      SingSnap and/or its licensors. The use of the software or any part of the service, except for use of the service as
      permitted in these terms of service, is strictly prohibited and infringes on the intellectual property rights of others
      and may subject you to civil and criminal penalties, including possible monetary damages, for copyright infringement.
      In the event of a dispute between users of SingSnap as to ownership of original content, SingSnap follows procedures
      that can be found by clicking on the "Copyright" link which is displayed in the lower portion of the SingSnap site.
      Notwithstanding Canadian copyright law and the U.S. DMCA process, SingSnap reserves the right to remove content from
      the site at anytime at management's sole discretion. The terms and conditions established under the Copyright link are
      hereby incorporated into these Terms and Conditions.
      <br><br>
      SingSnap expressly disclaims any and all liability in connection with content submitted by users. SingSnap does not
      permit copyright infringing activities and infringement of intellectual property rights on the SingSnap Service and it
      will remove all content if properly notified that such content infringes on another's intellectual property rights.
      SingSnap reserves the right to remove content without prior notice.</p>
      <h3>4. Site Conduct</h3>
      <h4>a. Legal Compliance and Monitoring</h4>
      <p>In consideration of the availability, and your use, of a SingSnap Site or any SingSnap Service, you agree to comply
      with all applicable laws and these Terms and Conditions when using such site or service. You acknowledge that SingSnap
      has no obligation to monitor a SingSnap Site or any content accessible through a SingSnap Site or any SingSnap Service.
      However, you agree that SingSnap has the right to monitor a SingSnap Site electronically from time to time and to
      disclose any information as necessary to satisfy any law, regulation or other governmental request, to operate a
      SingSnap Site or any SingSnap Service properly, or to protect itself or its users and customers in accordance with the
      SingSnap Privacy Policy.</p>
      <h4>b. Prohibited Conduct</h4>
      <p>You agree not to:
        <br><br>
      a) post, transmit, link to, or otherwise distribute any materials, information or content constituting, advocating or
      encouraging conduct that would constitute a criminal offence or give rise to civil liability, or otherwise use a
      SingSnap Site or any SingSnap Service in a manner which is contrary to law or would serve to restrict or inhibit any
      other user or customer from using or enjoying a SingSnap Site, any SingSnap Service or the Internet;
      <br><br>
      b) defame, abuse, stalk, harass, threaten or otherwise violate the legal rights of others, including without
      limitation, rights relating to privacy and publicity;
      <br><br>
      c) post, transmit, link to, or otherwise distribute any inappropriate, profane, defamatory, infringing, obscene,
      indecent or unlawful material or information;
      <br><br>
      d) post, transmit, link to, or otherwise distribute any information, materials or content (including for greater
      certainty, software) which contains a virus, cancelbot, trojan horse, worm or other harmful or disruptive component;
      <br><br>
      e) attempt, encourage, or assist any other person to circumvent or modify any security technology or software that is
      part of a SingSnap Site or SingSnap Service;
      <br><br>
      f) promote an illegal or unauthorized copy of another person's copyrighted work, such as providing pirated computer
      programs or links to them, providing information to circumvent manufacture-installed copy-protected devices, or provide
      unauthorized sound recordings or links to unauthorized sound recording files;
      <br><br>
      g) record parodies or rewrites of songs on SingSnap backing tracks. For avoidance of doubt, you are free to perform
      your own compositions on the SingSnap original blank track;
      <br><br>
      h) use the SingSnap Service while driving a motor vehicle or in any other situation deemed unsafe for usage.
      </p>
      <h4>c. Forum Conduct</h4>
      <p>
      By uploading materials to any message board or forum available on a SingSnap Site (collectively "Forums") or otherwise
      submitting any materials to us through any other content generating or submitting service on a SingSnap Site, you
      automatically grant (or warrant that the owner of such materials expressly grants) SingSnap a worldwide, perpetual,
      royalty-free, irrevocable and nonexclusive right and license to use, copy, adapt, transmit, communicate, publicly
      display and perform, distribute and create compilations and derivative works from such submitted materials, for the
      purposes of publishing and promoting such materials in connection with the Forum or other Service in respect of which
      the materials were submitted or generated, and for all promotions thereof. Such right and license shall apply with
      respect to any form, media or technology now known or later developed. In addition, you warrant that all "moral rights"
      in such materials have been waived.
      <br><br>
      SingSnap is not responsible for the content or accuracy of any information, and will not be responsible for any
      reliance or decisions made based on such information. When using a Forum, you may not post, transmit, link to, or
      otherwise distribute any information, materials or content that do not generally pertain to the designated topic or
      theme of the particular Forum. Use of a Forum for commercial purposes of any kind is strictly prohibited. Please note
      that SingSnap reserves the right to refuse to post or to remove any information or materials, in whole or in part,
      that, in its sole discretion, are unacceptable, undesirable, or in violation of these Terms and Conditions.
      </p>
      <h4>d. Third Party Dealings</h4>
      <p>You acknowledge and agree that your correspondence or business dealings with any third parties, including without
      limitation any merchants or advertisers, found on, or through, a SingSnap Site or any SingSnap Service, including
      without limitation payment for and delivery of related goods and services, and all other terms, conditions,
      representations and warranties related to such dealings, are solely as between you and such third parties. SingSnap
      assumes no responsibility whatsoever for any charges you or any user of your account incurs when making purchases or
      other transactions in this manner.</p>
      <h3>5. Submissions to SingSnap</h3>
      <h4>a) Content and Recordings</h4>
      <p>You may submit content to the SingSnap Service. You shall be solely responsible for your own content and the
      consequences of submitting your content to the SingSnap Service. You affirm, represent, and warrant that you own or
      have the necessary licences, rights, consents, and permissions to publish content you submit.
      <br><br>
      You license to SingSnap all patent, trademark, trade secret, copyright or other proprietary rights in and to such
      content for publication on the SingSnap Service pursuant to the Terms and Conditions. By submitting content to
      SingSnap, you retain your ownership rights in the content but explicitly grant to SingSnap and to its assignees and
      licensees the right and permission to use, broadcast, publish, perform, create derivative works, monetize and sell the
      video and audio recordings in any medium, throughout the world, without any restriction whatsoever on a royalty-free
      basis.
      <br><br>
      You grant each user of the Service a worldwide, non-exclusive, royalty-free, sub-licenseable and transferable licence
      to access your content through the SingSnap Service, and to use, reproduce, distribute, modify, prepare derivative
      works of, display and perform such content as permitted through the functionality of the SingSnap Service and under the
      Terms and Conditions. You acknowledge and agree that other users of the SingSnap Service may modify your content at any
      time following submission thereof and assume the risks related thereto.
      <br><br>
      You are free to remove your content at any time. You understand and agree, however, that SingSnap may retain, but not
      display, distribute or perform, server copies of your content that have been removed or deleted by you. You further
      understand and agree that any content you have submitted, as thereafter modified by any other user of the Services,
      which has been previously downloaded by any other user or person will remain within the control of such user or person.
      You further agree that content you submit to the SingSnap Service will not contain third party copyrighted material, or
      material that is subject to other third party proprietary rights, unless you have permission from the rightful owner of
      the material or you are otherwise legally entitled to post the material and to grant SingSnap all of the licence rights
      granted herein.
      </p>
      <h4>b) Site and Service Enhancements</h4>
      <p>SingSnap is always improving its websites and services and developing new features. If you have ideas and/or
      suggestions regarding improvements or additions, we would like to hear them, but any submission will be subject to
      these Terms and Conditions. Under no circumstances shall any disclosure of any idea and/or suggestion or related
      material to SingSnap be subject to any obligation of confidentiality or expectation of compensation. By submitting the
      idea and/or suggestion or any related material to SingSnap, you are waiving any and all rights that you may have in the
      idea and/or suggestion or any related material and are representing and warranting to SingSnap that the idea and/or
      suggestion or any related material are wholly original with you, that no one else has any rights in the idea and/or
      suggestion or any related material and that SingSnap is free to implement the idea and/or suggestion and to use the
      related material if it so desires, as provided or as modified by SingSnap, without obtaining permission or license from
      you or any third party.</p>
      <h3>6. Subscription Services</h3>
      <p>
        Certain of the Services made available on a SingSnap Site may be offered by SingSnap on a payment basis (a "Pay
        Service"). Pay Services may be offered by SingSnap on a single download basis, prepaid usage basis or on a periodic
        subscription ("Subscription") basis. Should you choose to purchase a Pay Service, you will be required to register and
        you will be assigned an account for such Pay Service (the "Pay Service Account") based on the information you provide
        as part of the registration process.
        <br><br>
        You agree to pay all the fees applicable for such Pay Service you incur through your Pay Service Account, including
        without limitation any fees associated with the use of such Pay Service together with all applicable taxes (the
        "Fees"). Unless otherwise indicated on the relevant Pay Service description page (the "Pay Service Description"), the
        Fees for a Pay Service purchased through your Pay Service Account are chargeable immediately (and at the beginning of
        each renewal period for periodic Subscription Pay Services) to the credit card you designated for the particular Pay
        Service Account or through such other billing process as applicable. All Fees are non-refundable. SingSnap reserves the
        right to change the amount of, or basis for determining, any Fees for a Pay Service, and to institute new Fees or terms
        at any time effective upon notice to you, which notice may for certainty include the posting of any such changes on the
        relevant pages related to the use of the Pay Service.
        <br><br>
        Please note that Subscription Pay Services offered on a periodic subscription basis renew automatically at the end of
        the relevant period as set out in the applicable Pay Service Description and Fees in respect of each such renewal shall
        be based on the then current rate for the particular Subscription Pay Service. You may terminate your subscription at
        any time, which termination will only be effective at the end of your subscription period.
        <br><br>
        By registering for a Pay Service, you warrant that: (a) you are of the age of majority in the jurisdiction in which you
        reside, (b) all information you submit is true and correct (including without limitation all credit card information),
        and (c) you are the cardholder of the credit card you designated for your Pay Service Account. In consideration of the
        availability, and your use, of a Pay Service, you acknowledge and agree: (a) to these Terms and Conditions, including
        without limitation the disclaimers, limitations of liability and provisions related to prohibited conduct set out
        herein as well as any terms and conditions which may be set out in the relevant Pay Service Description, (b) that you
        are solely responsible for all acts or omissions that occur through your Pay Service Account, (c) that the Pay Service
        is for your personal, non-commercial use and you agree not to resell, distribute, transfer, share or otherwise use any
        Pay Service, and (d) that SingSnap reserves the right to suspend or terminate your Pay Service Account and your use of
        a Pay Service at any time, including without limitation, where SingSnap has determined, in its sole discretion, that
        your use of the Pay Service is, or was, in breach of these Terms and Conditions. Any such termination by SingSnap shall
        be in addition to and without prejudice to such rights and remedies as may be available to SingSnap, including without
        limitation injunction and other equitable remedies. Your ability to use the Pay Service is also subject to any limits
        established by SingSnap or by your credit card issuer. If Fees cannot be charged to your credit card or your charge is
        returned for any reason, including without limitation chargeback, SingSnap reserves the right to either suspend or
        terminate your Pay Service Account and your access to the Pay Service.
      </p>
      <h3>7. Responsibility for Minors</h3>
      <p>In cases where you have authorized a minor under the age of 18 to use a SingSnap Site or any SingSnap Service, you
      recognize that you are fully responsible for: (a) the online conduct of such minor; (b) controlling the minor's access
      to and use of such site or service; and (c) the consequences of any misuse by the minor. You acknowledge that some of
      the areas of a SingSnap Site may contain material that is inappropriate for minors. Minors under the age of 13 are not
      permitted to use the SingSnap site or any SingSnap service.</p>
      <h3>8. Disclaimer</h3>
      <p>You expressly understand and agree that: (a) a SingSnap Site and any SingSnap Service are provided on an "as is" and
      "as available" basis and that SingSnap makes no warranties, representations or conditions (as used in this section
      "warranties") of any kind, whether express or implied, including, but not limited to, implied warranties of
      merchantability, fitness for a particular purpose and non-infringement, and that any such warranties are hereby
      expressly disclaimed, and (b) SingSnap specifically makes no warranties that a SingSnap Site or any SingSnap Service,
      including without limitation any content, information, products or services obtained from or through the use of a
      SingSnap Site or any SingSnap Service, will be provided on an uninterrupted, timely, secure or error-free basis or that
      such service(s) or the results derived therefrom will meet your requirements or expectations.
      </p>
      <h3>9. Privacy</h3>
      <p>
      SingSnap respects the privacy of all of its users and customers and is committed to protecting their privacy. Our
      privacy policy specifies how user information is collected, used and disclosed by us in connection with your use of a
      SingSnap Site and any of the SingSnap Services.
      <br><br>
      You hereby provide SingSnap with consent to send you emails based on any information you have provided SingSnap, any
      information related to the information you have such provided, or any other information. You hereby agree that any such
      email sent by SingSnap is not spam, illegal, or unsolicited. You hereby further agree that if you choose to delete your
      profile and/or resign from SingSnap, your profile will not appear in any search results, however, SingSnap keeps the
      right, to continue sending you emails in other fields, subject to your withdrawal of consent.
      </p>
      <h3>10. Limitation of Liability</h3>
      <p>
      YOU EXPRESSLY UNDERSTAND AND AGREE THAT IN NO EVENT SHALL SINGSNAP, INCLUDING WITHOUT LIMITATION ITS AFFILIATES AND
      LICENSORS, BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR
      EXEMPLARY DAMAGES, AND ANY DAMAGES FOR LOSS OF PROFITS, SAVINGS, GOODWILL OR OTHER INTANGIBLE LOSSES, REGARDLESS OF
      WHETHER SINGSNAP HAD BEEN ADVISED OF OR COULD HAVE FORESEEN THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR IN
      CONNECTION WITH: (A) THE USE, INABILITY TO USE OR PERFORMANCE OF ANY OF THE SINGSNAP SERVICES OF A SINGSNAP SITE, OR
      (B) ANY UNAUTHORIZED ACCESS TO OR MODIFICATION TO ANY OF YOUR CONTENT OR TRANSMISSIONS, OR (C) ANY OTHER MATTER
      RELATING TO A SINGSNAP SITE OR ANY OF THE SERVICES.
      <br><br>
      You expressly acknowledge that SingSnap has entered into this agreement, and has or may make a SingSnap Site, content
      and SingSnap Services available to you in reliance upon the limitations and exclusions of liability and the disclaimers
      set forth herein, and that the same form an essential basis of the bargain between you and SingSnap. You expressly
      agree that the limitations and exclusions of liability and the disclaimers set forth herein will survive, and continue
      to apply in the case of, a fundamental breach or breaches, the failure of essential purpose of contract, the failure of
      any exclusive remedy or termination of this agreement.
      </p>
      <h3>11. Indemnification</h3>
      <p>You agree to defend, indemnify and hold harmless each of SingSnap, its affiliates and licensors and each of their
      respective officers, directors, employees and agents, including all third parties mentioned on a SingSnap Site, from
      and against any and all claims, actions or demands, including without limitation reasonable legal and accounting fees,
      resulting from or related to: (a) your breach of any of these Terms and Conditions; (b) your access to or use of a
      SingSnap Site, SingSnap Services or content; or (c) your use or reliance on, or publication, communication or
      distribution of anything on or from a SingSnap Site or through any SingSnap Services. You shall use your best efforts
      to cooperate with us in the defense of any claim. We reserve the right, at our own expense, to assume the exclusive
      defense and control of any matter otherwise subject to indemnification by you.</p>
      <h3>12. Termination</h3>
      <p>You acknowledge and agree that SingSnap, in its sole and absolute discretion, may, without notice to you, suspend or
      terminate your account or your use of, or access to, a SingSnap Site or any SingSnap Service, and remove and discard
      any information or content related to a SingSnap Site or any SingSnap Service (and your use thereof), for any reason,
      including where SingSnap believes that you have violated any of these Terms and Conditions. You further agree that
      SingSnap shall not be liable to you or to any other person as a result of any such suspension or termination. If you
      are dissatisfied with a SingSnap Site or any SingSnap Service or with any terms, conditions, rules, policies,
      guidelines, or practices of SingSnap in operating a SingSnap Site or any SingSnap Service, your sole and exclusive
      remedy is to discontinue using a SingSnap Site or any SingSnap Service.</p>
      <h3>13. Jurisdiction</h3>
      <p>All SingSnap Sites and SingSnap Services are controlled, operated and administered by SingSnap from its offices within
      Canada. SingSnap makes no representation or warranty that a SingSnap Site or any SingSnap Service is appropriate or
      available for use at any locations outside Canada. If you access a SingSnap Site from outside Canada, you are
      responsible for compliance with all applicable laws. You may not export any of the Content accessible through a
      SingSnap Site in violation of applicable export laws and regulations. These Terms and Conditions shall be interpreted,
      construed and governed by the laws in force in the Province of Ontario, Canada, without reference to its conflict of
      laws principles. Each party hereby agrees to submit to the jurisdiction of the courts of the Province of Ontario and to
      waive any objections based upon venue. The United Nations Convention on Contracts for the International Sale of Goods
      does not apply to these Terms and Conditions.</p>
      <h3>14. Contact</h3>
      <p>To contact SingSnap in connection with these Terms and Conditions or the SingSnap sites, send an email to
      <a href="mailto:support@singsnap.com">support@singsnap.com</a>.</p>
      <h3>15. General Provisions</h3>
      <p>This agreement is personal to you, and you may not assign your rights or obligations to anyone. These Terms and
      Conditions constitute the entire agreement between SingSnap and you pertaining to your use of a SingSnap Site, a
      SingSnap Service and content and supersedes any prior agreements between you and SingSnap with respect to the subject
      matter hereof. SingSnap's failure to insist upon or enforce strict performance of any right or provision of these Terms
      and Conditions shall not constitute or be construed as a waiver of any right or provision. If any of the provisions (or
      parts thereof) contained in these Terms and Conditions are determined to be void, invalid or otherwise unenforceable by
      a court of competent jurisdiction, such determination shall not affect the remaining provisions (or parts thereof)
      contained herein. No changes to these Terms and Conditions shall be made except by a revised posting on this page or
      except as otherwise expressly contemplated herein. The parties have required that these Terms and Conditions and all
      documents relating thereto be drawn up in English. Les parties ont demandé que le présent accord et tous les documents
      connexes soient rédigés en anglais.
      </p>
    </div>
    <div class="btn-ctas">
      <button class="btn btn-cancel" (click)="onCancel()">CANCEL</button>
      <button class="btn btn-primary" (click)="onAgree()">I AGREE</button>
    </div>
  </div>
</div>