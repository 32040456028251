<div class="form-submit-success">
  <!-- <div class="icon-success">
    <div class="icon-inner circle-ripple circle-green">
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <div class="icon-holder">
        <app-icon-check color="white" size="30"></app-icon-check>
      </div>
    </div>
  </div> -->
  <div class="success-image">
    <img src="/assets/pictures/register-complete.png" />
  </div>
  <h4>Welcome to the family!</h4>
  <a routerLink="/d/home" class="btn btn-primary">GET STARTED!</a>
</div>