import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class NotificationService extends HttpService {
  constructor(private http: HttpClient, private util: UtilService) {
    super();
  }

  getNotifications(
    page = 1,
    perPage = 10,
    query = null,
    unread = false
  ): Observable<any> {
    const params: any = this.util.buildParams(
      page,
      perPage,
      null,
      null,
      query,
      'message'
    );
    if (unread) {
      params.q = '{"read_at":null}';
    }
    const options = this.getHeaders()
    console.log(options)
    options['params'] = params
    const url = this.makeUrl(`/user/notifications`);
    return this.http.get(url, options);
  }

  markNotificationAsRead(id) {
    const options = this.getHeaders()
    return this.http.post(this.makeUrl(`/user/notifications/${id}`), {}, options);
  }

  markAllAsRead() {
    const options = this.getHeaders()
    return this.http.post(this.makeUrl(`/user/notifications/mark_as_read`), {}, options);
  }

  clearAllNotifications(){
    const options = this.getHeaders()
    return this.http.delete(this.makeUrl(`/user/notifications`), options);

  }
}
