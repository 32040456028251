import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-social-email',
  templateUrl: './icon-social-email.component.html',
  styleUrls: ['./icon-social-email.component.scss']
})
export class IconSocialEmailComponent implements OnInit {
  @Input() size = 40;

  constructor() { }

  ngOnInit() {
  }

}

