<div class="app-icon">
    <svg [attr.width]="size"
    [attr.height.px]="size" viewBox="0 0 18 18" version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <path
                d="M9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 Z M9,16 C12.8659932,16 16,12.8659932 16,9 C16,5.13400675 12.8659932,2 9,2 C5.13400675,2 2,5.13400675 2,9 C2,12.8659932 5.13400675,16 9,16 Z M10,8 L13,8 C13.5522847,8 14,8.44771525 14,9 C14,9.55228475 13.5522847,10 13,10 L9,10 C8.44771525,10 8,9.55228475 8,9 L8,5 C8,4.44771525 8.44771525,4 9,4 C9.55228475,4 10,4.44771525 10,5 L10,8 Z"
                id="path-time"></path>
        </defs>
        <g id="Icons/Time" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <use id="Combined-Shape" [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-time"></use>
        </g>
    </svg>
</div>