import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-price-box',
  templateUrl: './price-box.component.html',
  styleUrls: ['./price-box.component.scss']
})
export class PriceBoxComponent implements OnInit {

  @Input('price') price;
  @Input('period') period;
  @Input('btnText') btnText = 'Buy Now';
  @Input('title') title = '';
  @Input('description') description = '';
  @Output() onBtnClicked = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  btnClicked($event) {
    this.onBtnClicked.emit($event);
  }
}
