import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  @Input() btnText = 'Start Singing';
  @Input() btnText2 = '';
  @Input() title = 'Payment Complete!';
  @Output() btnClick: EventEmitter<any> = new EventEmitter();
  @Output() btnClick2: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  handleClick($event) {
    this.btnClick.emit($event);
  }

  handleClick2($event) {
    this.btnClick2.emit($event);
  }
}
