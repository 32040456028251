<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="Credit-Icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="icon-contest-trophy" transform="translate(-113.000000, -65.000000)" [attr.fill]="color"  fill-rule="nonzero">
        <path
          d="M126.95,65.9 C126.95,65.81 126.86,65.72 126.86,65.63 L126.68,65 L117.32,65 L117.14,65.63 C117.14,65.72 117.05,65.81 117.05,65.9 L113,65.9 L113,69.23 C113,70.94 113.72,72.56 114.89,73.82 C116.06,74.99 117.59,75.62 119.21,75.62 C119.75,76.07 120.38,76.43 121.1,76.52 L121.1,78.5 C119.39,78.77 117.86,79.94 117.14,81.65 L116.6,83 L127.4,83 L126.86,81.74 C126.14,80.03 124.61,78.86 122.9,78.59 L122.9,76.61 C123.62,76.43 124.25,76.16 124.79,75.71 C126.41,75.71 127.94,74.99 129.11,73.91 C130.28,72.65 131,71.03 131,69.23 L131,65.9 L126.95,65.9 Z M114.8,69.23 L114.8,67.7 L116.69,67.7 C116.6,68.42 116.6,69.05 116.6,69.5 C116.6,70.94 116.96,72.29 117.5,73.46 C117.05,73.28 116.6,72.92 116.24,72.56 C115.25,71.66 114.8,70.49 114.8,69.23 Z M119.66,81.2 C120.29,80.66 121.1,80.3 122,80.3 C122.9,80.3 123.71,80.66 124.34,81.2 L119.66,81.2 Z M122,74.9 C120.02,74.9 118.4,72.47 118.4,69.5 C118.4,68.69 118.49,67.61 118.67,66.8 L125.33,66.8 C125.51,67.61 125.6,68.78 125.6,69.5 C125.6,72.47 123.98,74.9 122,74.9 Z M129.2,69.23 C129.2,70.49 128.75,71.66 127.85,72.56 C127.49,72.92 127.04,73.19 126.59,73.46 C127.13,72.29 127.49,71.03 127.49,69.59 C127.49,69.14 127.49,68.42 127.4,67.79 L129.2,67.79 L129.2,69.23 Z"
          id="contests"></path>
      </g>
    </g>
  </svg>
</div>