import { Injectable } from '@angular/core';

@Injectable()
export class Data {
  public storage: any;
  
  public profileUserName: string = "Data Sevice";

  public commentSent = false;
  public loveSent = false;
  public roseSent = false;

  public commentText = '';
  public commentSentCount = 0;
  public loveSentBool = 0;
  public roseSentCount = 0;
  public reactionSentEmoji:string;
  public reactionSent = false;
  public sendCommentData = {
    'email': '', 'recipient_name': '', 'sender_name': '', 'recording_song_name': '', 'share_url': '', 'recipient_wants_newsletter': false
  }
  public recLayers = {};

  public msgBrdTopicName:string = '';
  public msgBrdTopicReply = 0;
  public msgBrdTopicLove = false;
  public msgBrdTopicFav = false;

  public msgBrdReplyQuoted = 0;
  public msgBrdReplyLoved = 0;
  public msgBrdReplyReaction = 0;
  public msgBrdReplyList: any = [];
  public isNewTopic = false;

  public msgBrdPost_Reply_List: any = [];



  public messagesSentList: any = [];

  public msgBrdQuotedUserId = {};
  public msgBrdQuoted: any;
  public msgBrdMentions: any;
  public replyToId: any;

  public msgBrdQuoteLayers = {};
  public mentionList: any = [];  
  public recordingDuetLayers = [];

  public recordingCommentReplies = [];

  getQuotedIds(text) {
    if (typeof text !== 'string') {
      throw TypeError('getQuotedIds text must be string');
    }
    //const $text = jQuery.parseHTML(text);
    console.log('getting quoted ids')
    let domparser = new DOMParser()​​
    const $text = domparser.parseFromString(text, 'text/html')
    //const quoteAuthors = jQuery($text).find('.ql-quote-author:first');
    const quoteAuthors = $text.querySelectorAll('.ql-quote-author')
    const quoteAuthorsIds = [];
    quoteAuthors.forEach((idx, el) => {

      quoteAuthorsIds.push(idx);
    });
    // if(Object.keys(quoteAuthorsIds).length ){
    // console.log("yoyo: ", quoteAuthorsIds[0].dataset.id)
    // console.log("yoyo11: ", quoteAuthorsIds[0].dataset.value)
    // console.log("yoyo22: ", quoteAuthorsIds[0].dataset.email)
    // }

    if(Object.keys(quoteAuthorsIds).length ){
      var recLayers = {};
      for(var i=0;i<Object.keys(quoteAuthorsIds).length;i++) {
              var users = this.msgBrdQuoteLayers[i]  = {}; //define first if it a object otherwise it say undefined.
              users['id'] = quoteAuthorsIds[i].dataset.id;
              users['name'] = quoteAuthorsIds[i].dataset.value;
              users['email'] = quoteAuthorsIds[i].dataset.email;
       //       users['isme'] = (this.user.id === this.rec.users[i].id) ? true : false;  //this.isMe
        //      users['newsletter'] = this.rec.users[i].newsletter; 
          }
    }
    return quoteAuthorsIds;
  }

  getMentionsIds(text: string) {
    console.log('getting mentions by id')
    if (typeof text !== 'string') {
      throw TypeError('getMentionsIds text must be string');
    }
    let domparser = new DOMParser()​​
    const $text = domparser.parseFromString(text, 'text/html')
    //const mentions = jQuery($text).find('> .mention');
    const mentions = $text.querySelectorAll('.mention')
    const mentionsIds = [];
    mentions.forEach((idx, el) => {
      console.log(idx, el);
      //mentionsIds.push(jQuery(el).data('id'));
      mentionsIds.push(idx);
    });

    // if(Object.keys(mentionsIds).length ){
    //   console.log("yoyo: ", mentionsIds[0].dataset.id)
    //   console.log("yoyo11: ", mentionsIds[0].dataset.value)
    //   console.log("yoyo22: ", mentionsIds[0].dataset.email)
    //   }
    return mentionsIds;
  }


  parseComment (comment, replyToId?){
    let response: any = {};
    const quoteAuthorsIds = [...new Set(this.getQuotedIds(comment))];
    const mentionsIds = [...new Set(this.getMentionsIds(comment))];
    if (quoteAuthorsIds.length > 0) {
      response.msgBrdQuoted = quoteAuthorsIds;
      
    }
    if (mentionsIds.length > 0) {
      response.msgBrdMentions = mentionsIds;
    }
    if (replyToId) {
      response.replyToId = replyToId;
    }
    return response;
  }

  truncateComment(comment){
    comment = comment.replaceAll('</p>', '  ');
    comment = comment.replaceAll(/<[^>]*>/g, '');
    if (comment.length >= 23){
      return comment.slice(0, 20) + '...';
    } else {
      return comment;
    }
  }

  removeHTTP(uri){
     return uri.replace('https://', '');
  }



}
