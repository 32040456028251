import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ss-artist',
  templateUrl: './ss-artist.component.html',
  styleUrls: ['./ss-artist.component.scss']
})
export class SsArtistComponent implements OnInit {
  @Input() artist: any = {};
  @Input() spread = false;

  constructor() { }

  ngOnInit() {
  }

}
