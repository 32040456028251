
<div class="session-page">
  <div class="session-form">
    <h3>Welcome to SingSnap!</h3>
    <!-- <app-social-signup-form (processed)="hideForm = true;"></app-social-signup-form> -->
    <form [formGroup]="myForm" *ngIf="!hideForm">
      <div class="form-group">
        <label for="email">USERNAME</label>
        <input name="email" style ="padding-left: 0.5em;" type="text" class="form-control" id="email" formControlName="email" required>
        <span class="input-error" *ngIf="!validEmail">Enter valid username/email.</span>
      </div>
      <div class="form-group">
        <label for="password">PASSWORD</label>
        <!-- <input name="pass" type="password" class="form-control" id="password" formControlName="password" required> -->
        <div class="input-group">
          <input name="pass" style ="padding-left: 0.5em;" [type]="fieldTextType ? 'text' : 'password'" class="form-control" formControlName="password" required/>
          <div class="input-group-append">
            <span class="input-group-text">
               <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}" (click)="toggleFieldTextType()"></i>
            </span>
          </div>
        </div>
        <span class="input-error" *ngIf="!validPass">Password should be at least 4 characters.</span>
        <span class="input-error" *ngIf="loginError">We were unable to log you in, please make sure your username and password are correct! </span>
        <span class="input-error" *ngIf="loginMessage != ''">Error: {{loginMessage}} </span>
        <span class="input-error" *ngIf="loginMessage != ''">Having trouble logging in or resetting your password? We're here to help. Just send an email to support@singsnap.com and we'll get back to you.</span>

        <a class="forgot-link" [routerLink]="'/recovery'">Forgot Password?</a>
      </div>
      <div class="submit"> 
        <button type="submit" class="btn btn-primary" (click)="onSubmit()" [disabled]="submitting">
          <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="submitting"></i>
          LOG IN
        </button>
        <div class="session-help-text">
          <span>Don't have an account yet?</span>
          <h6 style="cursor:pointer;" [routerLink]="'/sign-up'">Sign up now!</h6>
        </div>
      </div>
    </form>
  </div>
  <div class="site-info-slider">
    <app-session-carousel></app-session-carousel>
  </div>
</div>


