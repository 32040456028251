import {Component, OnInit} from '@angular/core';
import {ProfileService} from '@app/core';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-settings-general',
  templateUrl: './settings-general.component.html',
  styleUrls: ['./settings-general.component.scss']
})
export class SettingsGeneralComponent implements OnInit {
  user;
  settings = {
    profile_visibility: false,
    notifications: false
  };
  saving = false;
  settingsGroup = [
    {
      label: 'Featured on tab',
      key: 'featured_on'
    },
    // {
    //   label: 'Achievements Program',
    //   key: 'achievements'
    // },
    // {
    //   label: 'Disable Trinkets/Snapicons',
    //   key: '',
    //   disabled: true
    // },
    {
      label: 'Show favorites on my profile',
      key: 'public_favorites',
    },
    // {
    //   label: 'Recorder with FPS adjustment',
    //   key: '',
    //   disabled: true
    // },
    // {
    //   label: 'Recorder with camera adjustment',
    //   key: '',
    //   disabled: true
    // },
    {
      label: 'Enable auto-play for player',
      key: '',
      disabled: true,
      // class: 'settings-item-full',
      gold: true
    },
    {
      label: 'Family filter',
      key: 'filter_offensive',
      // class: 'settings-item-full',
    },
    {
      label: 'Appear Offline',
      key: 'appear_offline',
    }
  ];

  notificationsGroup = [
    // { label: 'Recording' },
    // { label: 'Message Board' },
    // { label: 'Social' },
    // { label: 'Mentions' },
    // { label: 'Contests', class: 'settings-item-full' }
    {label: 'Notifications', class: 'settings-item-full'}
  ];

  constructor(
    private profileService: ProfileService,
    private toastService: ToastrService,
  ) {
  }

  ngOnInit() {
    this.loadSettings();
  }

  async handleSave() {
    try {
      await this.profileService.alterSettings(this.settings).toPromise();
      this.toastService.success('Updated user settings.');
    } catch (err) {
      this.toastService.error(err.message);
    }
  }

  handleCancel() {

  }

  handleChangeSetting(key, value) {
    this.settings[key] = value;
  }

  handleChangeNotification(value) {
    this.settings.notifications = value;
  }

  handleChangeVisibility(value) {
    this.settings.profile_visibility = value;
  }

  async loadSettings() {
    try {
      const requests = [];
      requests.push(this.profileService.getUserProfile().toPromise());
      //requests.push(this.profileService.getSettings().toPromise());
      // requests.push(this.profileService.getRefunded().toPromise());
      const results = await Promise.all(requests);
      this.user = results[0];
      this.settings = this.user.settings;
      this.enableGoldFeatures(); // if possible
    } catch (err) {

    }
  }

  enableGoldFeatures() {
    this.settingsGroup = this.settingsGroup.map(setting => {
      if (this.user.gold && setting.gold) {
        setting.disabled = false;
      }
      return setting;
    });
  }

}
