import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-adjust-bar',
  templateUrl: './adjust-bar.component.html',
  styleUrls: ['./adjust-bar.component.scss']
})
export class AdjustBarComponent implements OnInit {
  @Input() min: any = 0;
  @Input() max: any = 10;
  @Input() value: any = 0;
  @Input() multiply = 1;
  @Input() noFraction = false;
  @Input() step: any = 1;
  @Input() classes: any = [];
  @Output() changed = new EventEmitter<number>();
  @Input() stepCount = 10;
  @Input() noTicks = false;


  steps = [];

  constructor() { }

  ngOnInit() {
    for (let i = 0; i <= this.stepCount; i ++) {
      this.steps.push(i);
    }
  }

  getClasses() {
    return [...this.classes, 'ss-progress-input'].join(' ');
  }

  getProgress() {
    return (this.value - this.min) / (this.max - this.min) * 100;
  }

  getStepValue(step) {
    let value = (this.max - this.min) / this.stepCount * step + parseInt(this.min, 10);
    value = value * this.multiply;
    if (this.noFraction) {
      return value.toFixed(0);
    }
    if (value !== Math.floor(value)) {
      return value.toFixed(1);
    } else {
      return value;
    }
  }

  onSeek(e) {
    // this.value = e.target.value;
    console.log(this.value);
    this.changed.emit(e.target.value);
  }
}
