import {ChangeDetectorRef, Component, Input, Output, OnChanges, EventEmitter, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-comments-header',
    templateUrl: './comments-header.component.html',
    styleUrls: ['./comments-header.component.scss']
})
export class CommentsHeaderComponent implements OnInit, OnChanges {
    @Input() commentsCount = 0;
    @Input() text = 'Comments';
    @Input() type = 'message-board';
    @Input() sortAsc = true;
    @Input() showSort = false;
    @Output() sortAscHandle = new EventEmitter<any>();
    @Output() sortDescHandle = new EventEmitter<any>();

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log(changes)
        if (changes.commentsCount){
            if (changes.commentsCount.currentValue) {
                this.updateHeaderText();
            } 
        } else {
            if (changes.currentValue) {
                this.updateHeaderText();
            }
        }
    }


    updateHeaderText() {
        if (this.type === 'message-board') {
            this.text = this.commentsCount === 1 ? 'Reply' : 'Replies';
        } else {
            this.text = this.commentsCount === 1 ? 'Comment' : 'Comments';
        }
        this.cdr.detectChanges();
    }

}
