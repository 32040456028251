var __defProp = Object.defineProperty;
var __publicField = (obj, key, value) => {
  if (typeof key !== "symbol")
    key += "";
  if (key in obj)
    return __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value});
  return obj[key] = value;
};
export default class Jam {
  constructor(remix, jam) {
    __publicField(this, "users", {});
    __publicField(this, "layer");
    __publicField(this, "lyrics");
    __publicField(this, "music");
    __publicField(this, "voice");
    __publicField(this, "title");
    __publicField(this, "parts", []);
    __publicField(this, "remix");
    __publicField(this, "getSelectableParts", () => Object.keys(this.parts).filter((p) => this.parts[p]));
    __publicField(this, "getTogetherParts", () => Object.keys(this.parts).filter((p) => !this.parts[p]));
    this.remix = remix;
    this.remix.meta.base_vol = parseFloat(this.remix.meta.base_vol);
    this.remix.meta.mic_vol = parseFloat(this.remix.meta.mic_vol);
    this.remix.meta.latency = parseFloat(this.remix.meta.latency);
    this.remix.jam = jam;
    this.lyrics = remix.lyrics.lyrics;
    this.music = remix.parent_uri || remix.song.uri;
    this.title = remix.song.title;
    this.voice = remix.uri;
    this.layer = remix.users.length + 1;
    this.isFirst = remix.users.length === 1;
    this.users = remix.users;
  }
}
