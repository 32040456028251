<ng-template #content>
  <div class="modal-body gold-content">
    <!-- <div class="close-icon circle-ripple">
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <div class="icon-holder">
        <app-icon-close color="white" size="30"></app-icon-close>
      </div>
    </div> -->
    <div class="ss-logo">
      <img src="/assets/pictures/sing-snap-logo.png" />
    </div>
    <h4>Oops!</h4>
    <p>{{description}}</p>
    <div class="ctas">
      <button class="btn ss-btn-primary-inverse btn-custom-step-gray"
        (click)="closeModal()">CANCEL</button>
      <button class="btn btn-primary btn-custom-step" (click)="handlePurchase()">PURCHASE GOLD!</button>
    </div>
  </div>
</ng-template>
