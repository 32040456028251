import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService, ProfileService } from '@app/core';
import { UtilService } from '@app/core';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from "@angular/router";
import Bugsnag from '@bugsnag/js';
declare const gapi: any;
declare var gtag;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  myForm: UntypedFormGroup;
  hideForm = false;

  validEmail = true;
  validPass = true;
  submitting = false;
  loginMessage = '';
  loginError = false;
  intention;
  fieldTextType: boolean;

  constructor(
    private service: AuthenticationService,
    private util: UtilService,
    private router: Router,
    private toast: ToastrService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.intention = params['url'];
      console.log(this.intention); // Print the parameter to the console. 
  });
    this.myForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(1),   //set minimum username to 1 char from 3 for legacy support
        Validators.maxLength(30),
      ]),
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
    });
  }

  async onSubmit() {
    this.loginMessage = '';
    console.log('submitting')
    this.validEmail = this.myForm.controls.email.valid;
    this.validPass = this.myForm.controls.password.valid;
    this.loginError = false;
    if (this.myForm.valid === true) {
      this.submitting = true;
      try {
        const result = await this.service
          .onLogin(this.myForm.value)
          .toPromise();
          console.log(result)
        //Cookie.delete('domain')
        this.util.setloggedUserData(result['user']);
        localStorage.setItem('avatar', result['user']['photo']);
        //localStorage.setItem('token', result['token']);
        //Cookie.set('Authorization', 'Bearer ' + result['token']);


        //set expiry date a week from today
        let today = new Date();
        let expire = new Date();
        expire.setTime(today.getTime() + 3600000*24*14);
        
        document.cookie = `domain=.singsnap.com`
      // document.cookie = `AuthToken=${result['token']};expires=${expire.toUTCString()}`
      //  document.cookie = `UserId=${result['user']['id']};expires=${expire.toUTCString()}`

        document.cookie = `AuthToken=${result['token']};domain=.singsnap.com;expires=${expire.toUTCString()}`
        document.cookie = `UserId=${result['user']['id']};domain=.singsnap.com;expires=${expire.toUTCString()}`

        gtag("event", "login", {
          method: "password",
          loginState: 'success' ,
          user_id: result['user']['id'].toString(),
          goldMember: result['user']['gold'].toString()
          });
        // Cookie.set('AuthToken', result['token']);
        // Cookie.set('', result['user']['id']);

        //SET LOCAL TOKEN FOR LOCAL LOGIN
        if(window.location.href.includes("localhost")){
          Cookie.set('AuthToken', result['token'], Number(expire), '/');
        }
        if(typeof this.intention != 'undefined') {
          this.router.navigate([this.intention]);
        } else {
          this.router.navigate(['d/home']);
        }
      } catch (err) {
        gtag("event", "login", {
          method: "password",
          loginState: 'failure' ,
          });
        //const error = err.json();
        console.log(err)
        if(typeof err.error.reason != 'undefined') {
          this.loginMessage = err.error.reason;
          console.log(this.loginMessage)
           Bugsnag.notify('Login error: ' + err.error.reason)
        } else {
           Bugsnag.notify('Login error: undefinded');
        }
        this.validPass = true;
        this.loginError = true;
        //this.toast.error('We were unable to log you in, please make sure your username and password are correct!');
      } finally {
        this.submitting = false;
      }
    }
  }

  onFacebookSubmit() {}

  signInCallback(res) {
    const authInstance = gapi.auth2.getAuthInstance();
    if (!authInstance.isSignedIn.get()) {
     // this.onGooglePlusSubmit();
    } else {
      const userData = authInstance.currentUser.get();
      const response = userData.getAuthResponse();
      const profile = userData.getBasicProfile();
      const parsedData = {
        name: profile.getName(),
        email: profile.getEmail(),
        token: response.id_token,
        code: res.code,
      };
      // this.service.onGoogleSignUp(parsedData).subscribe(
      //   result => {
      //     this.util.setloggedUserData(result.user);
      //     Cookie.set('Authorization', 'Bearer ' + result.token);
      //     Cookie.set('AuthToken', result.token);
      //     this.router.navigate(['']);
      //     window.location.reload();
      //   },
      //   error => {
      //     console.log(error);
      //   }
     // );
    }
  }

  onGooglePlusSubmit() {
    // gapi.auth2
    //   .getAuthInstance()
    //   .grantOfflineAccess({})
    //   .then(this.signInCallback.bind(this));
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
