<div class="app-icon">
    <svg [attr.width]="size" [attr.height.px]="size" viewBox="0 0 18 16" version="1.1"
         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <path
                d="M14.1055728,11.4472136 C14.0361451,11.3083582 14,11.1552451 14,11 L14,5.009763 C14,3.34200706 12.6618557,2 11.0068977,2 L4.99310226,2 C3.33978474,2 2,3.3398874 2,4.99310226 L2,11.0068977 C2,12.6600414 3.34110174,14 4.99620271,14 L15.381966,14 L14.1055728,11.4472136 Z M17.8944272,14.5527864 C18.2268777,15.2176875 17.743382,16 17,16 L4.99620271,16 C2.23691386,16 0,13.7649929 0,11.0068977 L0,4.99310226 C0,2.2353522 2.23518094,0 4.99310226,0 L11.0068977,0 C13.7677178,0 16,2.23872605 16,5.009763 L16,10.763932 L17.8944272,14.5527864 Z"
                id="path-bubble"></path>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-327.000000, -292.000000)">
                <g transform="translate(0.000000, 90.000000)">
                    <g transform="translate(0.000000, 55.000000)">
                        <g transform="translate(197.000000, 20.000000)">
                            <g transform="translate(10.528571, 11.000000)">
                                <g transform="translate(119.000000, 116.000000)">
                                    <g transform="translate(0.471429, 0.000000)">
                                        <mask fill="white">
                                            <use xlink:href="#path-bubble"></use>
                                        </mask>
                                        <use fill="#D0D0D0" fill-rule="nonzero" xlink:href="#path-bubble"></use>
                                    </g>
                                    <g id="Group" transform="translate(2.471429, 1.000000)" fill="#D0D0D0">
                                        <path
                                            d="M2,-6.83214169e-16 L11,4.4408921e-16 C12.1045695,-7.39871708e-16 13,0.8954305 13,2 L13,8.2285791 L13.3167289,11.4924969 C13.4234146,12.5919022 12.6186565,13.5696321 11.5192512,13.6763178 C11.4146699,13.6864663 11.3094515,13.6883576 11.2045731,13.6819741 L1.87849347,13.1143358 C0.823064229,13.0500964 -1.25374146e-15,12.1754126 -4.4408921e-16,11.1180302 L-1.33226763e-15,2 C-1.95806578e-15,0.8954305 0.8954305,-1.1293615e-15 2,-1.33226763e-15 Z M2,4 L2,5.5 L10,5.5 L10,4 L2,4 Z M2,8 L2,9.5 L10,9.5 L10,8 L2,8 Z"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</div>
