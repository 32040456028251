<form [formGroup]="formGroup">
  <div class="form-group">
    <label for="name">NAME</label>
    <input name="username" type="text" class="form-control" formControlName="name" required>
    <span class="input-error" *ngIf="!formGroup.controls['name'].pristine && !formGroup.controls['name'].valid">
      Your full name is required.
    </span>
  </div>
  <div class="form-group">
    <label for="email">EMAIL</label>
    <input name="email" type="email" (change)="checkUniqueEmail()" class="form-control" id="email" formControlName="email" required>
    <span class='input-error' *ngIf='emailError != ""'>{{emailError}}</span>
    <span class="input-error" *ngIf="emailError == '' && !formGroup.controls['email'].pristine && !formGroup.controls['email'].valid">Enter valid email</span>
  </div>
  <div class="form-group">
    <label for="name">USERNAME</label>
    <input name="name" type="text" (change)="checkUniqueScreenName()" class="form-control" id="screen_name" formControlName="screen_name" required>
    <span class="input-error"
      *ngIf="!formGroup.controls['screen_name'].pristine && !formGroup.controls['screen_name'].valid && formGroup.controls['screen_name'].errors.required">
      A username is required.
    </span>
    <span class='input-error' *ngIf='screenNameError != ""'>{{screenNameError}}</span>
    <span class="input-error" *ngIf="screenNameError == '' && !formGroup.controls['screen_name'].pristine
                        && !formGroup.controls['screen_name'].valid
                        && (formGroup.controls['screen_name'].errors.minlength
                        || formGroup.controls['screen_name'].errors.maxlength)">
      Your username should be 2 to 20 characters.
    </span>
    <span class="input-error"
      *ngIf="!formGroup.controls['screen_name'].pristine && !formGroup.controls['screen_name'].valid && formGroup.controls['screen_name'].errors.isValid">
      Please use only letters, numbers, hyphens, and underscores with no spaces.
    </span>
  </div>
  <div class="form-group">
    <label for="password">PASSWORD</label>
    <div class="input-group"> 
      <input name="password" [type]="fieldTextType ? 'text' : 'password'" class="form-control" id="password" formControlName="password" required>
      <div class="input-group-append">
        <span class="input-group-text" style="cursor:pointer">
          <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}" (click)="toggleFieldTextType()"></i>
        </span>
      </div>
    </div>
    <span class="input-error" *ngIf="!formGroup.controls['password'].pristine && !formGroup.controls['password'].valid">Password should be at least 6 characters.</span>
  </div>
  <div class="form-group">
    <label for="confirmPassword">CONFIRM PASSWORD</label>
    <div class="input-group"> 
      <input name="confirmPassword" [type]="fieldTextTypeTwo ? 'text' : 'password'"  class="form-control" (focus)="onFocus()"  (clickOutside)="onClickOutside()" id="confirmPassword"  formControlName="confirmPassword" required >
      <div class="input-group-append">
        <span class="input-group-text" style="cursor:pointer">
          <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextTypeTwo,'fa-eye': fieldTextTypeTwo}" (click)="toggleFieldTextTypeTwo()"></i>
        </span>
      </div>
    </div>
    <span class="input-error" *ngIf="focusedOnce && showPasswordError && !hasFocus">Passwords don't match, please try again.</span>
      <!-- <span class="input-error" *ngIf="!formGroup.controls['confirmPassword'].pristine && !formGroup.controls['confirmPassword'].valid && checkPassword && !hasFocus">Password does not match.</span> -->
  </div>
  <div class="form-group">
    <label for="gender">GENDER</label>
    <input name="gender" type="hidden" [(ngModel)]="gender" formControlName="gender">
    <div ngbDropdown style="cursor:pointer">
      <div class="form-group-input" ngbDropdownToggle>
        <span>{{gender}}</span>
        <div class="form-group-dropdown">
          <i class="fa fa-angle-down"></i>
        </div>
      </div>
      <div class="dropdown-menu" aria-labelledby="gender">
        <div *ngFor="let gender of genders">
          <button class="dropdown-item" on-click="changeGender(gender)">{{gender}}</button>
        </div>
      </div>
    </div>
    <span
      class="input-error"
      *ngIf="!formGroup.controls['gender'].pristine && !gender"
    >
      A gender is required.
    </span>
  </div>
  <div class="form-group">
    <label for="birthday">BIRTHDAY (yyyy-mm-dd)</label>
    <input
      class="form-control"
      [minDate]="{year: 1900, month: 1, day: 1}"
      [maxDate]="maxDate"
      [startDate]="startDate"
      formControlName="birthday"
      placeholder="yyyy-mm-dd"
      ngbDatepicker
      #d="ngbDatepicker" (dateSelect)="onDateSelect($event)">
     <div class="form-group-dropdown" style="cursor:pointer">
      <i class="fa fa-angle-down" (click)="d.toggle()"></i>
    </div> 
    <span class="input-error" *ngIf="!formGroup.controls['birthday'].pristine && !formGroup.controls['birthday'].valid && !formGroup.controls['birthday'].errors.invalidDate; else dobOk">
      A valid birthday is required.
    </span>
    <ng-template #dobOk>
      <span class="input-error" *ngIf="formGroup.controls['birthday'].valid ; else dob13">
      </span>  
    </ng-template>
    <ng-template #dob13>
      <span class="input-error" *ngIf="formGroup.controls['birthday'].errors.invalidDate">
        You must be at least 13 years old.
      </span>  
    </ng-template>
  </div>

  <div class="newsletter">
    <input type="checkbox"  #wantsNewsLetter class="selectedCheckBox"  id="selected" (change)="setWantsNewsLetter(wantsNewsLetter.checked)">
    <span class="newsletter_text">
      Yes! I want to receive emails about SingSnap Community News, Announcements & Exclusive Offers!
    </span>
  </div>

  <div class="submit">
    <div *ngIf='signUpErrors.length != 0'>
      <span *ngFor='let error of signUpErrors' class="input-error" style='color: red;'>{{error}}</span>
    </div>
    <button type="submit" class="btn btn-primary" (click)="onSubmit()" [disabled]="submitting">
      <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="submitting"></i>
      SIGN UP
    </button>
    <div class="session-help-text">
      <span>Already have an account?</span>
      <h6 style="cursor:pointer;" [routerLink]="'/login'">Log in</h6>
    </div>
  </div>
</form>