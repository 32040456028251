<div class="app-icon">
    <svg [attr.width]="size" [style.width]="size" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <path d="M9,2 C7.95,2 6.95,2.16 6,2.46 C10.06,3.73 13,7.52 13,12 C13,16.48 10.06,20.27 6,21.54 C6.95,21.84 7.95,22 9,22 C14.52,22 19,17.52 19,12 C19,6.48 14.52,2 9,2 L9,2 Z"
                id="path-icon-moon"></path>
        </defs>
        <g id="ic_brightness_3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g>
                <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-icon-moon"></use>
                </mask>
                <use id="Mask" [attr.fill]="color" xlink:href="#path-icon-moon"></use>
            </g>
        </g>
    </svg>
</div>