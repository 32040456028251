<div class="app-icon">
  <svg [attr.width]="size" [attr.height.px]="size" viewBox="0 0 15 11" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path
        d="M12.75,9.75 L12.75,12.75 C12.75,13.1642136 12.4142136,13.5 12,13.5 C11.8725,13.5 11.7525,13.5 11.295,13.125 C10.83,12.75 10.0425,12 9.2325,11.625 C8.4825,11.28 7.71,11.25 6.945,11.25 L7.8525,13.74 L7.875,13.875 C7.875,14.0821068 7.70710678,14.25 7.5,14.25 L6,14.25 C5.835,14.25 5.6925,14.145 5.6475,13.995 L4.6425,11.25 L4.5,11.25 C4.08578644,11.25 3.75,10.9142136 3.75,10.5 C2.92157288,10.5 2.25,9.82842712 2.25,9 C2.25,8.17157288 2.92157288,7.5 3.75,7.5 C3.75,7.08578644 4.08578644,6.75 4.5,6.75 L6.75,6.75 C7.5825,6.75 8.415,6.75 9.2325,6.375 C10.0425,6 10.83,5.25 11.295,4.875 C11.7525,4.5 11.8725,4.5 12,4.5 C12.4142136,4.5 12.75,4.83578644 12.75,5.25 L12.75,8.25 C13.1642136,8.25 13.5,8.58578644 13.5,9 C13.5,9.41421356 13.1642136,9.75 12.75,9.75 Z M16.5,9 C16.5,10.035 16.08,10.9725 15.405,11.655 L14.34,10.59 C14.745,10.185 15,9.6225 15,9 C15,8.3775 14.745,7.815 14.34,7.41 L15.405,6.345 C16.08,7.0275 16.5,7.965 16.5,9 Z"
        id="path-megaphone"></path>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-924.000000, -316.000000)">
        <g transform="translate(902.000000, 125.000000)">
          <g id="Announcements" transform="translate(20.000000, 187.000000)">
            <g id="ic_bullhorn">
              <g id="ic_arrow_drop_down">
                <polygon id="Shape" points="0 0 18 0 18 18 0 18"></polygon>
                <mask id="mask-megaphone" fill="white">
                  <use xlink:href="#path-megaphone"></use>
                </mask>
                <use [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-megaphone"></use>
                <g mask="url(#mask-megaphone)" fill="#D0D0D0">
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>