<div class="container">
    <div class="row">
        <div class="col-12">
            <h2>Env switcher (current: {{currentEnv}})</h2>
            <form [formGroup]="form">
                <div class="form-check form-check-inline">
                    <label>
                        <input type="radio" name="env" value="dev" formControlName="env">
                        Dev
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label>
                        <input type="radio" name="env" value="staging" formControlName="env">
                        Staging
                    </label>
                </div>
                <button class="btn btn-primary" type="button" [disabled]="form.controls['env'].invalid"
                        (click)="onSubmit()">Env Button</button>
            </form>
        </div>
    </div>
</div>
