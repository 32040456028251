import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-avatar-thumb',
    templateUrl: './avatar-thumb.component.html',
    styleUrls: ['./avatar-thumb.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarThumbComponent implements OnInit {
    @Input() user;
    @Input() big = false;
    @Input() showOnline = true;

    constructor() {
    }

    ngOnInit() {
    }

}
