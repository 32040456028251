import {Component, OnInit} from '@angular/core';
//import {Http} from '@angular/http';


@Component({
    selector: 'app-legacy-viewer',
    templateUrl: './legacy-viewer.component.html',
    styleUrls: ['./legacy-viewer.component.scss']
})
export class LegacyViewerComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
        // this.http.get('http://www.singsnap.com/karaoke/watchandlisten/comments/26307166-424470?allowColor=true&allowSize=true&page=4&perPage=20&totalRecords=62')
        //     .subscribe(data => {
        //         console.log(data);
        //     });
    }

}
