<ng-template #content>
  <div class="modal-body">
    <div>

    </div>
    <h4>{{title}}</h4>
    <div class="ctas">
      <button class="btn btn-primary btn-custom-step" (click)="ok()">{{okText}}</button>
    </div>
  </div>
</ng-template>
