<div class="container-fluid main-container">
    <div class="row">
        <div class="col-lg-6 orders-headline">
            My Data
            <table *ngIf="user">
                <tbody>
                    <tr *ngIf="user.name">
                        <td class="orders-title">NAME</td>
                        <td class="orders-data">{{user.name}}</td>
                    </tr>
                    <tr *ngIf="user.email">
                        <td class="orders-title">EMAIL</td>
                        <td class="orders-data">{{user.email}}</td>
                    </tr>
                    <tr>
                        <td class="orders-title">PHONE</td>
                        <td class="orders-data">+1 234 5678 90</td>
                    </tr>
                    <tr *ngIf="user.gender">
                        <td class="orders-title">GENRE</td>
                        <td class="orders-data">{{user.gender}}</td>
                    </tr>
                    <tr *ngIf="user.status">
                        <td class="orders-title">BIRTHDAY</td>
                        <td class="orders-data">{{user.status}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6 orders-headline" *ngIf="allOrders.length > 0">
            My Address
            <table>
                <tbody>
                    <tr>
                        <td class="orders-title">COUNTRY</td>
                        <td class="orders-data">{{allOrders[0].shipping.country}}</td>
                    </tr>
                    <tr>
                        <td class="orders-title">CITY</td>
                        <td class="orders-data">{{allOrders[0].shipping.city}}</td>
                    </tr>
                    <tr>
                        <td class="orders-title">ZIP CODE</td>
                        <td class="orders-data">{{allOrders[0].shipping.postcode}}</td>
                    </tr>
                    <tr>
                        <td class="orders-title">STATE</td>
                        <td class="orders-data">{{allOrders[0].shipping.state}}</td>
                    </tr>
                    <tr>
                        <td class="orders-title">ADDRESS</td>
                        <td class="orders-data">{{allOrders[0].shipping.address_1}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" *ngIf="allOrders.length > 0">
        <div class="col-lg-12 orders-headline">
            My Orders
        </div>
        <div class="col-lg-12">
            <div class="table-responsive">
                <table class="table orders-table" *ngIf="allOrders.length > 0">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Order Number</th>
                            <th>Products</th>
                            <th>Order Total</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let order of allOrders">
                            <td class="orders-table-date">{{order.date_created | date: 'MM/dd/yyyy'}}</td>
                            <td>#{{order.id}}</td>
                            <td>{{order.line_items.length}}</td>
                            <td>${{order.total | round}}</td>
                            <td class="orders-table-processing"
                                [ngClass]="{'orders-table-shipped': order.status == 'completed'}">{{order.status | uppercase}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="allOrders.length == 0">
        <div class="col-lg-12 orders-headline">
            You don't have any orders
        </div>
    </div>
</div>
