
import {switchMap, distinctUntilChanged, debounceTime,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilService } from './util.service';

@Injectable()
export class UsersService extends HttpService {
  constructor(
    //private http: Http,
    private http2: HttpClient,
    private utilService: UtilService
  ) {
    super();
  }

  getUsers(searchTerm = '', page = 1, perPage = 10) {
    const options = this.getHeaders();
    options['responseType'] = "json"
    const url = `/users?page=${page}&per_page=${perPage}&gold=false&sorters={"screen_name":"DESC"}&q={"screen_name":"${searchTerm}"}`;
    return this.http2
      .get(this.makeUrl(url), options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  isUnique(email, screenName): Observable<any> {
    const options = this.getHeaders();
    const params = {
      email,
      screen_name: screenName,
    };
    return this.http2
      .post(this.makeUrl(`/users/unique`), params, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getMembers(
    page = 1,
    perPage = 10,
    sort = 'screen_name',
    asc = true,
    query = null,
    queryField = 'screen_name'
  ) {
    const options = this.getHeaders();
    const params = this.utilService.buildParams(
      page,
      perPage,
      sort,
      asc,
      query,
      queryField
    );
    delete params.sorters;
    options['params'] = params;
    const url = `/users`;
    return this.http2
      .get(this.makeUrl(url), options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  searchUsers(terms: Observable<string>, page?, perPage?) {
    console.log('? searching ?');
    return terms.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(term => this.getUsers(term, page, perPage)),);
  }

  lookupUsers(id, gold = false, searchTerm, page = 1, perPage = 10) {
    const options = this.getHeaders();
    const url = `/user/inner-circles/${id}/members-lookup?q=${searchTerm}&gold=${gold}&page=${page}&per_page=${perPage}`;
    return this.http2
      .get(this.makeUrl(url), options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  // TODO: replace with real endpoint
  getTopMembers() {
    const options = this.getHeaders();
    return this.http2
      .get(this.makeUrl('/users?page=1&per_page=6'), options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getUsersSearchResult(searchString) {
    const options = this.getHeaders();
    return this.http2
      .get(
        this.makeUrl(
          '/users?page=1&per_page=3&q={"name":"' + searchString + '"}'
        ),
        options
      ).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  unfav(id) {
    const options = this.getHeaders();
    return this.http2
      .delete(this.makeUrl(`/user/${id}/favorite`), options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  favorite(id) {
    const options = this.getHeaders();
    return this.http2
      .post(this.makeUrl(`/user/${id}/favorite`), options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  subscribeUser(user) {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/users/${user.id}/notify`);
    return this.http2.post(url, {}, headers);
  }

  unsubscribeUser(user) {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/users/${user.id}/notify`);
    return this.http2.delete(url, headers);
  }

  blockUser(user) {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/users/${user.id}/block`);
    return this.http2.post(url, {}, headers);
  }
}
