import { Component, OnInit, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject ,  Observable } from 'rxjs';
import { Cookie } from 'ng2-cookies';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';

@Component({
  selector: 'app-ss-webcam-photo',
  templateUrl: './ss-webcam-photo.component.html',
  styleUrls: ['./ss-webcam-photo.component.scss']
})
export class SsWebcamPhotoComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  modalReference: any;
  imageBlob: any;
  blobUrl: any;

  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  constructor(
    private modalService: NgbModal
  ) { }

  public ngOnInit(): void {
    const deviceId = Cookie.get('recorder-video-device-id');
    if (deviceId) {
      this.showNextWebcam(deviceId);
    }
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.imageBlob = this.b64toBlob(webcamImage.imageAsBase64, 'image/jpeg');
    this.blobUrl = URL.createObjectURL(this.imageBlob);
  }

  b64toBlob (b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  open() {
    this.webcamImage = null;
    this.imageBlob = null;
    this.modalReference = this.modalService.open(this.contentElem, {
      windowClass: 'ssc-modal',
      backdrop: 'static',
    });
  }

  closeModal() {
    this.modalReference.close();
    if (this.closed) {
      this.closed.emit({
        blob: this.imageBlob,
        base64: this.webcamImage.imageAsDataUrl
      });
    }
  }
}
