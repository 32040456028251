import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-long-arrow-down',
  templateUrl: './icon-long-arrow-down.component.html',
  styleUrls: ['./icon-long-arrow-down.component.scss']
})
export class IconLongArrowDownComponent implements OnInit {
  @Input() color = '#262628';
  @Input() size = 11;

  constructor() { }

  ngOnInit() {
  }

}
