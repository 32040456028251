import { ChangeDetectorRef, Component, OnInit, NgZone } from '@angular/core';
import { ProfileService, UtilService, Data } from '@app/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { EmailNotificationsService } from '@app/core/services/email-notifications.service';


@Component({
  selector: 'app-user-profile-fans',
  templateUrl: './user-profile-fans.component.html',
  styleUrls: ['./user-profile-fans.component.scss']
})
export class UserProfileFansComponent implements OnInit {
  fans = [];
  userId;
  me;
  constructor(
    private profileService: ProfileService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private toast: ToastrService,
    private ngZone: NgZone,
    private router: Router,
    private util: UtilService,
    private dataService: Data,
    private emailNotificationsService: EmailNotificationsService,
  ) {
    this.activatedRoute.parent.params.subscribe(params => {
      console.log('ITS LOADING')
      this.fans = [];
      this.userId;
      this.userId = params.id;
      this.onLoad();
    });

    this.profileService
    .getUser(this.userId)
    .subscribe(response => {
      this.dataService.profileUserName = response.screen_name;
    }); 
  }

  currentPage = 1;
  totalPages;
  memberSortOptions = this.util.memberSortOptions;
  query = "";

  data = {
    total: 0,
    rqing: false,
    currentPage: 1,
    lastPage: 1,
    current_page: 1,
    first_page_url: '',
    from: 1,
    next_page_url: '',
    per_page: 1,
    prev_page_url: '',
    to: 1,
    last_page: 1,
    sort: this.memberSortOptions[0],
};


  
  ngOnInit() {

  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  onLoad() {
    if (this.userId) {
      this.getItems(null);
    } else {
      this.profileService.getUserProfile().subscribe(user => {
        this.userId = user['id'];
        this.me = user;
        this.getItems(null)
      });
    }
  }

  getItems(sort) {
    if (sort) {
      this.data.sort = sort;
    }
    this.data.rqing = true;
    this.currentPage = 1;
    this.profileService.getUserFans(this.userId, this.currentPage, 10, 'screen_name' , this.data.sort.asc, this.query)
    .subscribe((response) => {
      this.fans = response.data;
      this.data =  response;
      this.data.rqing = false;
      this.detectChanges();
       this.setTotalPages();
       this.totalPages = this.data.last_page;
    });
  }

  setTotalPages(){
    return  this.totalPages;
  }

  getPrevFans(){
    if (!this.data.rqing ) {
      if(this.currentPage<=1){
        this.detectChanges();
          return;
      }
      this.currentPage--, scrollTo(0, 500);
      this.data.rqing = true;
      this.detectChanges();
      this.profileService.getUserFans(this.userId, this.currentPage, 10, 'screen_name' , this.data.sort.asc, this.query)
    .subscribe((response) => {
              this.data.currentPage = this.data.currentPage - 1;
              this.fans = response.data;
              this.data =  response;       
            });
            this.totalPages = this.data.last_page;
              this.data.rqing = false;
              this.detectChanges();
      }
  }

  getNextFans(){
    if (!this.data.rqing) {
     if(this.currentPage == this.totalPages){
         return;
      }
      this.currentPage++, scrollTo(0, 500);
      this.data.rqing = true;
      this.detectChanges();
      let obs = [];
      this.profileService.getUserFans(this.userId, this.currentPage, 10, 'screen_name' , this.data.sort.asc, this.query)
    .subscribe((response) => {
              this.data.currentPage = this.data.currentPage + 1;
              this.fans = response.data;
              this.data =  response;       
            });
            this.totalPages = this.data.last_page;
              this.data.rqing = false;
              this.detectChanges();
      }
  }

 getLastPageFromURL(url){
    let pos = url.lastIndexOf("=");
    let result =  url.substr(pos + 1);
    return result;
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  unfavoriteMember(profile) {
    this.profileService.unfavoriteMember(profile.id).subscribe(response => {
      if (response.success) {
         profile.favorited = true;
         this.toast.success(
          response.model.screen_name + ' removed from favorites.'
        );
      } else {
        this.toast.error(response.message);
      }
    });
  }
  
  favoriteMember(profile) {
    this.profileService.favoriteMember(profile.id).subscribe(response => {
      if (response.success) {
        profile.favorited = true;
        this.toast.success(
         response.model.screen_name + ' added to favorites.'
       );
       if(profile.newsletter){
        //this.emailNotificationsService.emailFavoriteNotification(profile.email, profile.recipient_name, this.me.screen_name)
        this.emailNotificationsService.emailFavoriteNotificationV2(profile.email, profile.recipient_name, this.me.screen_name, this.me.id, this.me.photo.replace('https://', ''))
      }
     } else {
       this.toast.error(response.message);
     }
    });
  }

  navMessage(id){
    this.navigate([`/d/private-message/inbox/${id}`]);
  }

  navProfile(id){
    this.navigate([`/d/profile/${id}`]);
  }

  onSearch(query) {
    this.query = query;
    this.data.sort =  this.memberSortOptions[0]
    this.getItems(this.data.sort);
  }


}
