import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import {
  ProfileService,
  RecordingService,
  PlaylistService,
  HelperService,
  ArtistService,
  SongService,
  UtilService,
  EventService,
  Data
} from '@app/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EmailNotificationsService } from '@app/core/services/email-notifications.service';

@Component({
  selector: 'app-user-profile-favorites',
  templateUrl: './user-profile-favorites.component.html',
  styleUrls: ['./user-profile-favorites.component.scss'],
})
export class UserProfileFavoritesComponent implements OnInit {
  user: any;
  @ViewChild('playlist', { static: true }) private playlistElem;
  @ViewChild('goldcontent', { static: true }) private goldModal;
  @ViewChild('confirmation', { static: true }) private confirmationModal;
  userId;
  PAGE_SIZE = 10;
  memberSortOptions = this.util.memberSortOptions;
  recordingSortOptions = this.util.recordingSortOptions;
  playlistSortOptions = this.util.playlistSortOptions;
  artistSortOptions = this.util.artistSortOptions;


  favVisible: false;  //V2


  data = {
    artist: {
      ...this.util.generateGridData(),
      sort: this.artistSortOptions[0],
    },
    member: {
      ...this.util.generateGridData(),
      sort: this.memberSortOptions[0],
    },
    song: {
      ...this.util.generateGridData(),
      sort: this.playlistSortOptions[0],
    },
    recording: {
      ...this.util.generateGridData(),
      sort: this.playlistSortOptions[0],
    },
    playlist: {
      ...this.util.generateGridData(),
      sort: this.playlistSortOptions[0],
    },
  };

  constructor(
    public profileService: ProfileService,
    private recordingService: RecordingService,
    private playlistService: PlaylistService,
    private artistService: ArtistService,
    private songService: SongService,
    private helperService: HelperService,
    private router: Router,
    private toastService: ToastrService,
    private eventService: EventService,
    private activatedRoute: ActivatedRoute,
    private util: UtilService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private dataService: Data,
    private emailNotificationsService: EmailNotificationsService
  ) {
    this.activatedRoute.parent.params.subscribe(params => {
      this.userId = params.id;
    });

    this.profileService
    .getUser(this.userId)
    .subscribe(response => {
      //this.user = response;
      this.dataService.profileUserName = response.screen_name;
    });
  }

  // async ngOnInit() {
  //   this.user = await this.profileService.getUserProfile().toPromise();
  //   if (!this.userId) {
  //     this.userId = this.user.id;
  //   }
  //   this.loadData('member');
  //   this.loadData('artist');
  //   this.loadData('song');
  //   this.loadData('recording');
  //   this.loadData('playlist');
  // }

  //V2 - remove async
  ngOnInit() {
    this.profileService.getUserProfile().subscribe(user => {
      this.user = user;
      this.favVisible = user['public_favorites'];
    });

    this.loadData('member');
    this.loadData('artist');
    this.loadData('song');
    this.loadData('recording');
    this.loadData('playlist');
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  async loadData(type, sort = null) {
    const data = this.data[type];
    if (sort) {
      data.sort = sort;
    }
    if (data.loading || !data.lastPage || !this.userId) {
      return;
    }
    data.loading = true;
    data.currentPage++;
    this.detectChanges();
    const orderBy = data.sort.field;
    const asc = data.sort.asc;
    const request =
      type === 'member'
        ? this.profileService.getUserFavoriteUsers(
            this.userId,
            data.currentPage,
            this.PAGE_SIZE,
            orderBy,
            asc
          )
        : this.profileService.getUserFavorites(
            this.userId,
            type,
            data.currentPage,
            this.PAGE_SIZE,
            orderBy,
            asc
          );

    try {
      const result = await request.toPromise();
      data.items = data.items.concat(result.data);
      data.lastPage = result.next_page_url;
    } catch (err) {
    } finally {
      data.loading = false;
      this.detectChanges();
    }
  }

  generateEmpty() {
    return {
      items: [],
      total: 0,
      current: 1,
      loadedAll: false,
      loading: false,
    };
  }

  onOrderBy(event) {
    if (this.data[event.type].orderBy === event.order) {
      return;
    }
    this.data[event.type].orderBy = event.order;
    this.refreshData(event.type);
  }

  resetDataObj(type) {
    const data = this.data[type];
    data.currentPage = 0;
    data.loading = false;
    data.items = [];
    data.lastPage = 1;
  }

  refreshData(type) {
    this.data[type] = Object.assign(
      this.data[type],
      this.util.generateGridData()
    );
    this.loadData(type);
  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  privateMessage(ref){
    this.navigate(['/d/private-message/inbox', ref]);
  }
  editMyRecordingInfo(ref){
    this.navigate(['/d/my-recordings', ref]);
  }


  onProfile(ref) {
    this.navigate(['/d/profile/', ref,'info']);
  }

  onPlaylist(playlist) {
    this.navigate(['/d/play', playlist.id]);
  }

  handleSing(song) {
    if (song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    this.navigate(['/recorder', song.id]);
  }

  handleDuet(duet) {
    if (duet.song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    this.navigate(['/duet-recorder', duet.id]);
  }

  checkMine(item){
    return item.favorited;
  }


  onSong(song) {
    this.navigate(['/recorder/', song.id]);
  }

  onArtist(artist) {
    this.navigate(['/d/artists/', artist.id]);
  }

  onRecording(recording) {
    this.navigate(['/d/listen/', recording.id]);
  }

  onAddtoPlaylist(id) {
    this.playlistElem.open(id);
  }

  unfavoriteMember(profile) {
    this.profileService.unfavoriteMember(profile.id).subscribe(() => {
      this.refreshData('member');
      this.refreshData('recording');
    });
  }
  
  favoriteMember(profile) {
    //this.profileService.favoriteMember(profile.id).subscribe(() => {
    //  this.refreshData('member');
    //  this.refreshData('recording');
    // });


    this.profileService.favoriteMember(profile.id).subscribe(response => {
      if (response.success) {
        this.refreshData('member');
        this.refreshData('recording');
        if(profile.newsletter){
         // this.emailNotificationsService.emailFavoriteNotification(profile.email, profile.recipient_name, this.user.screen_name)
          this.emailNotificationsService.emailFavoriteNotificationV2(profile.email, profile.recipient_name, this.user.screen_name, this.user.id, this.user.photo.replace('https://', ''))
        }
      } else {
        this.refreshData('member');
        this.refreshData('recording');
      }
    });





  }
  unfavoriteRecording(recording) {
    this.recordingService.unfav(recording.id).subscribe(() => {
      recording.favorited = false;
    });
  }

  favoriteRecording(recording) {
    this.recordingService.fav(recording.id).subscribe(() => {
      recording.favorited = true;
    });
  }

  unfavoritePlaylist(playlist) {
    this.playlistService.unfavorite(playlist.id).subscribe(() => {
      playlist.favorited = false;
    });
  }

  favoritePlaylist(playlist) {
    this.playlistService.favorite(playlist.id).subscribe(() => {
      playlist.favorited = true;
    });
  }

  unfavoriteArtist(artist) {
    this.artistService.unfavorite(artist.id).subscribe(() => {
      this.refreshData('artist');
    });
  }
  
  favoriteArtist(artist) {
    this.artistService.favorite(artist.id).subscribe(() => {
      this.refreshData('artist');
    });
  }

  unfavoriteSong(song) {
    this.songService.unfav(song.id).subscribe(() => {
      this.refreshData('song');
    });
  }

  onEditPlaylist(playlistId) {
    this.navigate(['/d/my-playlists/edit/', `${playlistId}`]);
  }

  onDeletePlaylist(playlistId) {
    this.confirmationModal
      .open('CONFIRM', 'Are you sure you want to delete the playlist?')
      .then(result => {
        if (result === 'ok') {
          this.deleteItem(playlistId);
        }
      });
  }

  deleteItem(playlistId) {
    this.data.playlist.loading = true;
    this.playlistService.deletePlaylist(playlistId).subscribe(result => {
      this.data.playlist.items = this.data.playlist.items.filter(
        x => x.id !== playlistId
      );
      this.data.playlist.loading = false;
    });
  }

 subscribeUser(recording) {
    const recordingList = [this.data.recording.items];
    this.helperService.subscribeUser(recording, recordingList);
    this.refreshData('member');
  }

  unsubscribeUser(recording) {
    const recordingList = [this.data.recording.items];
    this.helperService.unsubscribeUser(recording, recordingList);
  }

  shareRecording(recording) {
    this.eventService.onSocialShare({ recording });
  }

  howOthersSingThis(id){
    this.navigate([`/d/ListenOthersSing/${id}`]);
  }
}
