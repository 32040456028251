import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { EventService } from '../../core/services';

@Component({
  selector: 'app-step-nav',
  templateUrl: './step-nav.component.html',
  styleUrls: ['./step-nav.component.scss']
})
export class StepNavComponent implements OnInit {
  @Input() steps: any = [];
  @Input() active: Number = 0;
  @Output() onStep: EventEmitter<any> = new EventEmitter();
  @Input() completed: number = 0;

  constructor( ) { }

  ngOnInit() {
  }

  gotoStep(i, link) {
    if (i <= this.completed) {
      this.onStep.emit(i);
    }
  }
}
