<div class="ss-progress-holder">
  <input *ngIf='!recorder' type="range" [disabled]="noSeek" class="ss-progress-input" step="0.1" min="0" [max]="duration" [(ngModel)]="current"
    (change)="onSeek($event)" 
    (input)="onSeek($event)" 
    />
    <input *ngIf='recorder' type="range" [disabled]="noSeek" class="ss-progress-input" step="0.1" min="0" [max]="duration" [(ngModel)]="current"
    (change)="onSeek($event)" 
    (input)="onChange($event)" 
    />
  <div class="ss-progress-actual" [ngClass]="{'ss-progress-step': isStep}">
    <div class="ss-progress-bar" #progressBar>
      <span class="barline" [style.width]="getProgress() + '%'"></span>
    </div>
  </div>
  <span class="ss-seeker" [ngClass]="{'ss-seeker-step': isStep}" #seeker [style.left]="getProgress() + '%'"></span>
</div>