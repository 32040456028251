<div id='seeMoreContainer'>
  <div class='headerContainer'>
    <div class='title'>
      <app-icon-button color="white" (click)="back()" >
      <app-icon-arrow-left color="gray"></app-icon-arrow-left>
      </app-icon-button>
      <h4>Listen - Jams</h4>
    </div>
  </div>  
    
    <app-ss-featured-grid 
      [scrollable]="false"
      title="Listen - Jams"
      seeMorePage="ListenJams"
      [hasSeeMore] = "false"
      [cols]="3"
      [colsLg]="3"
      [colsMd]="2"
      [colsSm]="1"
      [extendedSize] = "true"
      [checkOwnRecording]="true"
      [userId]="profileService.userId"
      [sortOptions]="recordingSortOptions"
      [list]="jamsData.items"
      [loading]="jamsData.loading"
      (onScrollEnd)="onScrollEndHandle(jamsData, $event)"
      (onOrderBy)="resetDataObj(jamsData);onScrollEndHandle(jamsData, $event)">
      <ng-template #itemContent let-item>
        <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
          <app-ss-recording [linkActive]="false" [recording]="item"></app-ss-recording>
          <div class="song-actions">
            <app-icon-button [routerLink]="['/d/listen/', item?.id]" [shadow]="true" role="button" size="40px" color="#58c7c9" title="Play">
              <app-icon-play color="white"></app-icon-play>
            </app-icon-button>
            <app-more-dot-menu>
              <div class="dropdown-menu-content">
                <div *ngIf="!item.favorited" (click)="favRecording(item)">Add to Favorites</div>
                <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove from Favorites</div>
                <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                <!-- <div (click)="handleDuet(item)">Duet / Jam</div> -->
                <div *ngIf="profileService.userId == item.user_id" [routerLink]="['/d/my-recordings', item.id]">Edit Recording Info</div>
                <div (click)="shareRecording(item)">Share this recording</div>
                <div>Artist Profile</div>
              </div>
            </app-more-dot-menu>
          </div>
        </div>
        <app-recording-stat [recording]="item"></app-recording-stat>
      </ng-template>
    </app-ss-featured-grid>
    <app-vert-space height="40"></app-vert-space>

    <div  id="pageNavContainer"  style="margin-bottom: 13px;">
      <button *ngIf='jamsData.currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
      <p >{{currentPage}}</p>
      <button *ngIf='jamsData.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
    </div>
</div>    
