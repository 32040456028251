import { Component, OnInit, ViewChild } from '@angular/core';
import { ProfileService, UsersService } from '@app/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings-unblock',
  templateUrl: './settings-unblock.component.html',
  styleUrls: ['./settings-unblock.component.scss'],
})
export class SettingsUnblockComponent implements OnInit {
  @ViewChild('confirmModal', { static: true }) confirmModal;
  blockedMembers = [];
  members = [];
  isLoading = false;
  currentMember = null;
  searchTerm = '';
  isBlock = true;

  constructor(
    private profiles: ProfileService,
    private usersService: UsersService,
    private router: Router,
    private toast: ToastrService
  ) {}

  ngOnInit() {
    this.loadBlockedMembers();
  }

  get memberList() {
    if (this.searchTerm) {
      return this.members;
    } else {
      return this.blockedMembers;
    }
  }

  async onSearch(term) {
    this.searchTerm = term;
    if (!this.searchTerm) {
      this.members = [];
      return;
    }
    const result = await this.usersService
      .getUsers(this.searchTerm)
      .toPromise();
    if (this.searchTerm) {
      this.members = result.data;
      this.members.forEach(m => (m.unblocked = true));
    }
  }

  onSearchClear() {
    this.members = [];
  }

  async loadBlockedMembers() {
    this.isLoading = true;
    try {
      const result = await this.profiles.blockedMembers().toPromise();
      this.blockedMembers = result.data;
      this.blockedMembers.forEach(m => (m.loading = false));
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }

  handleConfirmed() {
    if (this.isBlock) {
      this.blockMember();
    } else {
      this.unblockMember();
    }
  }

  unblock(member) {
    this.currentMember = member;
    this.isBlock = false;
    const description = `Are you sure you want to unblock ${member.screen_name}?`;
    this.confirmModal.open('Unblock Member', description, 'Cancel', 'Unblock');
  }

  block(member) {
    this.currentMember = member;
    this.isBlock = true;
    const description = `Are you sure you want to block ${member.screen_name}?`;
    this.confirmModal.open('Block Member', description, 'Cancel', 'block');
  }

  unblockMember() {
    this.currentMember.loading = true;
    this.profiles.unblock(this.currentMember.id).subscribe(
      response => {
        this.currentMember.loading = false;
        if (response.success) {
          this.blockedMembers = this.blockedMembers.filter(
            m => m.id !== this.currentMember.id
          );
          this.toast.success(response.message);
        } else {
          this.toast.warning(response.message);
        }
      },
      error => {
        this.currentMember.loading = false;
        this.toast.error(error.message);
      }
    );
  }

  blockMember() {
    this.currentMember.loading = true;
    this.profiles.block(this.currentMember.id).subscribe(
      response => {
        this.currentMember.loading = false;
        if (response.success) {
          this.currentMember.unblocked = false;
          this.loadBlockedMembers();
          this.members = this.members.filter(
            m => m.id !== this.currentMember.id
          );
          this.toast.success(response.message);
        } else {
          this.toast.warning(response.message);
        }
      },
      error => {
        this.currentMember.loading = false;
        this.toast.error(error.message);
      }
    );
  }

  goBack() {
    this.router.navigate(['/d/settings/general']);
  }
}
