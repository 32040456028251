import {
  Component, ContentChildren, Directive, ElementRef,
  Input, OnInit, QueryList, OnDestroy, ViewChild, ViewChildren
} from '@angular/core';

import { CarouselItemDirective } from '../directives/carousel-item.directive';
import { animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style } from '@angular/animations';

@Directive({
  selector: '.carousel-item'
})
export class CarouselItemElement {
}

@Component({
  selector: 'app-ss-snapicon-carousel',
  templateUrl: './snapicon-carousel.component.html',
  styleUrls: ['./snapicon-carousel.component.scss'],
})

export class SnapiconCarouselComponent implements OnInit, OnDestroy {
  @ContentChildren(CarouselItemDirective) items: QueryList<CarouselItemDirective>;
  @ViewChildren(CarouselItemElement, { read: ElementRef }) private itemsElements: QueryList<ElementRef>;
  @ViewChild('carousel', { static: true }) private carousel: ElementRef;
  @Input() size = 30;
  private hideTiming = '250ms ease-in';
  private showTiming = '400ms ease-in';
  carouselWrapperStyle = {};
  private currentSlide = 0;
  private interval = null;

  constructor(private builder: AnimationBuilder) {
    this.interval = setInterval(() => this.next(), 5000);
  }

  ngOnInit() {
    console.log(this.carousel)

  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  next() {
    const prevSlide = this.currentSlide;
    this.currentSlide = (this.currentSlide + 1) % this.items.length;

    // hide animation
    const hideAnim: AnimationFactory = this.buildAnimation(0, this.hideTiming);
    const hidePlayer: AnimationPlayer = hideAnim.create(this.carousel.nativeElement.children[prevSlide]);
    hidePlayer.play();

    // show animation
    const showAnim: AnimationFactory = this.buildAnimation(1, this.showTiming);
    const showPlayer: AnimationPlayer = showAnim.create(this.carousel.nativeElement.children[this.currentSlide]);
    setTimeout(() => showPlayer.play(), 250);

  }

  private buildAnimation(value, timing) {
    return this.builder.build([
      animate(timing, style({ opacity: value }))
    ]);
  }

}
