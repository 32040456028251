import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-sort-alpha-down',
  templateUrl: './icon-sort-alpha-down.component.html',
  styleUrls: ['./icon-sort-alpha-down.component.scss']
})
export class IconSortAlphaDownComponent implements OnInit {
  @Input() color = '#262628';
  @Input() size = 18;

  constructor() { }

  ngOnInit() {
  }

}
