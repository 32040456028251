import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '@app/core/services';

@Component({
  selector: 'app-update-user-status',
  templateUrl: './update-user-status.component.html',
  styleUrls: ['./update-user-status.component.scss']
})

export class UpdateUserStatusComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  @Output() updated: EventEmitter<any> = new EventEmitter();
  status = '';
  modalReference: any;
  finished = false;

  constructor(
    private modalService: NgbModal,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
  }

  onChange() {
    if (this.status && this.status.length > 100) {
      this.status = this.status.substr(0, 100);
    }
  }

  open(value) {
    this.status = value || '';
    this.finished = false;
    this.modalReference = this.modalService.open(this.contentElem, {
      windowClass: 'ssc-modal ssc-modal-sm',
      backdrop: 'static',
    });
    // this.modalReference.result.then((result) => {
    // }, (reason) => {
    // });
  }

  closeModal() {
    this.modalReference.close();
  }

  updateStatus() {
    this.profileService.updateUser({ status: this.status }).subscribe(result => {
      this.finished = true;
      this.updated.emit(this.status);
    });
  }
}
