import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PlaylistService, ProfileService, UtilService, Data } from '@app/core/services';

@Component({
  selector: 'app-user-profile-playlists',
  templateUrl: './user-profile-playlists.component.html',
  styleUrls: ['./user-profile-playlists.component.scss']
})
export class UserProfilePlaylistsComponent implements OnInit {
  @ViewChild('confirmation', { static: true }) private confirmationModal;
  PAGE_SIZE = 10;
  query = '';
  isPrivate = false;
  loading = false;
  userId: any;
  isOwn = false;
  playlistSortOptions = this.util.playlistSortOptions;
  data: any = {
    ...this.util.generateGridData(),
    sort: this.playlistSortOptions[0]
  };

  constructor(
    private playlistService: PlaylistService,
    private profileService: ProfileService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastrService,
    private util: UtilService,
    private cdr: ChangeDetectorRef,
    private dataService: Data
  ) {
    this.activatedRoute.parent.params.subscribe(params => {
      this.userId = params.id;
    });

    this.profileService
    .getUser(this.userId)
    .subscribe(response => {
      //this.user = response;
      this.dataService.profileUserName = response.screen_name;
    });
  }

  ngOnInit() {
    if (!this.userId) {
      this.isOwn = true;
      this.profileService.getUserProfile().subscribe(user => {
        this.userId = user['id'];
        this.onScrollEndHandle(this.data, null);
      });
    } else {
      this.onScrollEndHandle(this.data, null);
    }
  }

  checkMine(item){
    return item.favorited;
  }

  refreshData() {
    this.data = {
      ...this.data,
      ...this.util.generateGridData()
    };
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  async onScrollEndHandle(data, sort) {
    if (sort) {
      data.sort = sort;
    }

    if (this.data.loading || !this.data.lastPage || !this.userId) {
      return;
    }
    this.data.loading = true;
    try {
      this.data.currentPage++;
     // this.detectChanges();   //PLAYLIST
      const request = this.playlistService.getUserPlaylists(
        this.userId,
        this.data.currentPage, this.PAGE_SIZE, this.data.sort.field,
        this.data.sort.asc, this.query, this.isPrivate
      );
      const result = await request.toPromise();
      this.data.lastPage = result.next_page_url;
      this.data.items = this.data.items.concat(result.data);
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.data.loading = false;
      this.detectChanges();
    }
  }

  resetDataObj(data) {
    data.currentPage = 0;
    data.items = [];
    data.lastPage = 1;
    this.data.loading = false;
  }

  onSearch(query) {
    this.query = query;
    this.refreshData();
    this.onScrollEndHandle(this.data, null);
  }

  onPlay(playlistId) {
    this.playlistService.playPlaylist(playlistId).subscribe(() => { });
    this.router.navigate(['/d/play/', `${playlistId}`]);
  }

  onFavorite(playlist) {
    console.log(playlist)
    this.playlistService.favorite(playlist.id).subscribe(() => {
      this.toastService.success('Added!');
      playlist.favorited = true;
    });
  }

  onUnfavorite(playlist) {
    this.playlistService.unfavorite(playlist.id).subscribe(() => {
      this.toastService.success('Removed!');
      playlist.favorited = false;
    });
  }

  onEdit(playlist) {
    this.router.navigate(['/d/my-playlists/edit/', `${playlist.id}`]);
  }

  onDelete(playlist) {
    this.confirmationModal.open(
      'CONFIRM',
      'Are you sure you want to delete the playlist?'
    ).then(result => {
      if (result === 'ok') {
        this.deleteItem(playlist.id);
      }
    });
  }

  deleteItem(playlistId) {
    this.data.loading = true;
    this.playlistService.deletePlaylist(playlistId).subscribe(result => {
      this.data.items = this.data.items.filter(x => x.id !== playlistId);
      this.data.loading = false;
      this.toastService.success('Deleted!');
    });
  }

}
