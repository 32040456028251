
    <div class='headerContainer'>
      <div class='title'>
        <app-icon-button color="white" (click)="back()" >
          <app-icon-arrow-left color="gray"></app-icon-arrow-left>
        </app-icon-button>
        <h4>{{pageTitle}}</h4>
      </div>
    </div>
    <div id='seeMoreContainer'>
      <!-- ads side -->
      <div class="placeAdLeft" style="float:left;">
        <ng-adsense
        [adClient]="'ca-pub-4776037543768273'"
        [adSlot]="9024407308"
        ></ng-adsense>
      </div>
      <div class="placeAdLeft" style="float:right;">
        <ng-adsense
        [adClient]="'ca-pub-4776037543768273'"
        [adSlot]="9024407308"
        ></ng-adsense>
      </div>


      <ng-container  *ngIf="showSeeMorePageType === 'Showcase'">
        <!-- [sortOptions]="playlistSortOptions" -->
        <app-ss-featured-grid 
          [scrollable]="false"
          title="{{pageTitle}}"
          [hasSeeMore] = "false"
          [cols]="3"
          [colsLg]="3"
          [colsMd]="2"
          [colsSm]="1"
          [extendedSize] = "true"
          [checkOwnRecording]="true"
          [list]="showcaseRecording.items" 
          [loading]="showcaseRecording.loading"
          (onOrderBy)="resetDataObj(showcaseRecording);LoadSongs(showcaseRecording, $event)">
          <ng-template #itemContent let-item>
              <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
              <app-ss-recording  [linkActive]="false"(click)="navListen(item?.id)" [recording]="item"></app-ss-recording>
              <div class="song-actions">
                  <app-more-dot-menu title="Options">
                      <div class="dropdown-menu-content">
                          <div *ngIf="!item.favorited" (click)="favRecording(item)">Add Recording to Favorites</div>
                          <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Recording from Favorites</div>
                          <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                          <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                          <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                          <div (click)="navProfile(item.user_id)">Go to Profile Page</div>
                          <!-- <div (click)="handleDuet(item)">Duet / Jam</div> -->
                          <div *ngIf="profileService.userId == item.user_id" (click)="navEditRec(item?.id)">Edit Recording Info</div>
                          <!-- <div (click)="shareRecording(item)">Share this recording</div> -->
                          <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                      </div>
                  </app-more-dot-menu>
              </div>
              </div>
              <app-recording-stat (click)="navListen(item?.id)" [recording]="item"></app-recording-stat>
          </ng-template>
        </app-ss-featured-grid>
          <app-vert-space height="40"></app-vert-space>
          <app-add-to-playlist #playlist></app-add-to-playlist>
      </ng-container >

      
    <ng-container  *ngIf="showSeeMorePageType === 'Snappenin'">
      <!-- [sortOptions]="playlistSortOptions" -->
      <app-ss-featured-grid 
        [scrollable]="false"
        title="{{pageTitle}}"
        [hasSeeMore] = "false"
        [cols]="3"
        [colsLg]="3"
        [colsMd]="2"
        [colsSm]="1"
        [extendedSize] = "true"
        [checkOwnRecording]="true"
        [list]="whatsSnappenin.items" 
        [loading]="whatsSnappenin.loading"
        (onOrderBy)="resetDataObj(whatsSnappenin);LoadSongs(whatsSnappenin, $event)">
        <ng-template #itemContent let-item>
            <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
            <app-ss-recording  [linkActive]="false"(click)="navListen(item?.id)" [recording]="item"></app-ss-recording>
            <div class="song-actions">
                <app-more-dot-menu title="Options">
                    <div class="dropdown-menu-content">
                        <div *ngIf="!item.favorited" (click)="favRecording(item)">Add Recording to Favorites</div>
                        <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Recording from Favorites</div>
                        <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                        <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                        <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                        <div (click)="navProfile(item.user_id)">Go to Profile Page</div>
                        <!-- <div (click)="handleDuet(item)">Duet / Jam</div> -->
                        <div *ngIf="profileService.userId == item.user_id" (click)="navEditRec(item?.id)">Edit Recording Info</div>
                        <!-- <div (click)="shareRecording(item)">Share this recording</div> -->
                        <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                    </div>
                </app-more-dot-menu>
            </div>
            </div>
            <app-recording-stat (click)="navListen(item?.id)" [recording]="item"></app-recording-stat>
        </ng-template>
      </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
        <app-add-to-playlist #playlist></app-add-to-playlist>
        <div  id="pageNavContainer"  style="margin-bottom: 13px;">
          <button *ngIf='whatsSnappenin.currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
          <p >{{currentPage}}</p>
          <button *ngIf='whatsSnappenin.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
        </div>
    </ng-container >

    <ng-container  *ngIf="showSeeMorePageType === 'Trending-Songs'">
        <!-- [sortOptions]="songSortOptions" -->
        <app-ss-featured-grid
            [scrollable]="false"
            title="{{pageTitle}}"
            [hasSeeMore] = "false"
            [cols]="3"
            [colsLg]="3"
            [colsMd]="2"
            [colsSm]="1"
            [extendedSize] = "true"
            [list]="trendingSongs.items"
            [loading]="trendingSongs.loading"
            [freeSongFilter]="false"
            (onOrderBy)="resetDataObj(trendingSongs);LoadSongs(trendingSongs, $event)">
            >
            <ng-template #itemContent let-item>
                <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
                <app-ss-song [linkActive]="false" [song]="item"></app-ss-song>
                <div class="song-actions">
                    <app-icon-button style="margin-right: -0.2rem" (click)="handleSing(item)" [shadow]="true" role="button" size="40px" color="#58c7c9" title="Record">
                    <app-icon-mic color="white" size="16"></app-icon-mic>
                    </app-icon-button>
                    <app-more-dot-menu title="Options">
                    <div class="dropdown-menu-content">
                        <div (click)="favoriteSong(item)" *ngIf="!item.favorited">Add to Favorites</div>
                        <div (click)="unfavoriteSong(item)" *ngIf="item.favorited">Remove from Favorites</div>
                        <div (click)="onArtist(item.artist)">View Artist Profile</div>
                        <div (click)="howOthersSingThis(item.id)">How Others Sing This</div>
                    </div>
                    </app-more-dot-menu>
                </div>
                </div>
            </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
        <div  id="pageNavContainer"  style="margin-bottom: 13px;">
            <button *ngIf='trendingSongs.currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
            <p >{{currentPage}}</p>
            <button *ngIf='trendingSongs.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
        </div>
    </ng-container>   

    <ng-container  *ngIf="showSeeMorePageType === 'Trending-Recordings'">
        <!-- [sortOptions]="playlistSortOptions" -->
        <app-ss-featured-grid 
          [scrollable]="false"
          title="{{pageTitle}}"
          [hasSeeMore] = "false"
          [cols]="3"
          [colsLg]="3"
          [colsMd]="2"
          [colsSm]="1"
          [extendedSize] = "true"
          [checkOwnRecording]="true"
          [list]="trendingRecordings.items" 
          [loading]="trendingRecordings.loading"
          (onOrderBy)="resetDataObj(trendingRecordings);LoadSongs(trendingRecordings, $event)">
          <ng-template #itemContent let-item>
              <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
              <app-ss-recording  [linkActive]="false"(click)="navListen(item?.id)" [recording]="item"></app-ss-recording>
              <div class="song-actions">
                  <app-more-dot-menu title="Options">
                  <div class="dropdown-menu-content">
                      <div *ngIf="!item.favorited" (click)="favRecording(item)">Add Recording to Favorites</div>
                      <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Recording from Favorites</div>
                      <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                      <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                      <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
                      <div (click)="navProfile(item.user_id)">Go to Profile Page</div>
                      <!-- <div (click)="handleDuet(item)">Duet / Jam</div> -->
                      <div *ngIf="profileService.userId == item.user_id" (click)="navEditRec(item?.id)">Edit Recording Info</div>
                      <!-- <div (click)="shareRecording(item)">Share this recording</div> -->
                      <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                  </div>
                  </app-more-dot-menu>
              </div>
              </div>
              <app-recording-stat (click)="navListen(item?.id)" [recording]="item"></app-recording-stat>
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
        <app-add-to-playlist #playlist></app-add-to-playlist>
        <div  id="pageNavContainer"  style="margin-bottom: 13px;">
            <button *ngIf='trendingRecordings.currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
            <p >{{currentPage}}</p>
            <button *ngIf='trendingRecordings.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
        </div>
    </ng-container >
 


    <ng-container  *ngIf="showSeeMorePageType === 'Genres'">
        <!-- [sortOptions]="playlistSortOptions" -->
        <app-ss-featured-grid 
          [scrollable]="false"
          title="{{pageTitle}}"
          [hasSeeMore] = "false"
          [cols]="6"
          [colsLg]="4"
          [colsMd]="3"
          [colsSm]="2"
          [extendedSize] = "true"
          [checkOwnRecording]="false"
          [list]="genresData.items" 
          [loading]="genresData.loading"
          (onOrderBy)="resetDataObj(genresData);LoadSongs(genresData, $event)">
          <ng-template #itemContent let-item>
            <div class="point">
              <div (click)="navGenres(item?.id)">
                <app-genre-item [genre]="item" [height]="150" [addHeight]="true" ></app-genre-item>
              </div>
            </div>  
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
        <app-add-to-playlist #playlist></app-add-to-playlist>
    </ng-container >


    <ng-container  *ngIf="showSeeMorePageType === 'Genres-Songs'">
      <!-- [sortOptions]="playlistSortOptions" -->
      <app-ss-featured-grid 
        [scrollable]="false"
        title="{{pageTitle}}"
        [hasSeeMore] = "false"
        [cols]="6"
        [colsLg]="4"
        [colsMd]="3"
        [colsSm]="2"
        [extendedSize] = "true"
        [checkOwnRecording]="false"
        [list]="genresData.items" 
        [loading]="genresData.loading"
        (onOrderBy)="resetDataObj(genresData);LoadSongs(genresData, $event)">
        <ng-template #itemContent let-item>
          <div class="point">
            <div (click)="navGenresSongs(item?.id)">
              <app-genre-item [genre]="item" [height]="150" [addHeight]="true" ></app-genre-item>
            </div>
          </div>  
        </ng-template>
      </app-ss-featured-grid>
      <app-vert-space height="40"></app-vert-space>
      <app-add-to-playlist #playlist></app-add-to-playlist>
  </ng-container >

























</div>
