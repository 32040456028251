<a class="ss-message-board">
  <div class="ss-message-board--photo">
    <img [src]="message.cover + '?h=100'">
  </div>
  <div class="ss-message-board--info">
    <div class="ss-mb-main">
      <span class="info-title ss-truncate">{{message.title}}</span>
      <app-ss-user-name [user]="message.user"></app-ss-user-name>
    </div>
    <div class="ss-mb-update">
      <span>Last update</span>
      <app-ss-user-name [user]="message.latest_user ? message.latest_user : message.user"></app-ss-user-name>
      <app-time-stamp [time]="message.updated_at" [needWatch]="false" [format]="'MMM d, yyyy hh:mma'"></app-time-stamp>
    </div>
  </div>
</a>
