import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Input, ElementRef  } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { NotificationService, UtilService } from '@app/core/services';

@Component({
  selector: 'app-notifications-dropdown',
  templateUrl: './notifications-dropdown.component.html',
  styleUrls: ['./notifications-dropdown.component.scss']
})
export class NotificationsDropdownComponent implements OnInit, OnDestroy {
  notifications = [];
  @ViewChild('notificationDropdown', { static: true }) notificationDropdown;
  fetcher = null;
  @Input() notify: string = 'false';
  @ViewChild('icon', { static: true }) private icon: ElementRef;
 
  constructor(
    private util: UtilService,
    private notificationService: NotificationService,
    private toastr: ToastrService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if(this.notify == 'true') { 
      this.icon.nativeElement.classList.add('icon-active')
    }
    //Remove to reduce notification calls when opening up app
    //this.loadNotifications();
    //old code
    // this.fetcher = setInterval(() => this.loadNotifications(), 30000);
  }

  ngOnDestroy() {
    // clearInterval(this.fetcher);
  }

  async loadNotifications() {
    try {
      const result = await this.notificationService.getNotifications(1, 10, null, true).toPromise();
      this.notifications = result.data;
      this.detectChanges();
    } catch (err) {
      this.toastr.error(err.message || 'Failed to load notifications.');
    } finally {

    }
  }

  close() {
    this.notificationDropdown.close();
  }

  open(event) {
    event.stopPropagation();
    this.loadNotifications();
    this.notificationDropdown.open();
  }

  formatTime(time) {
    return this.util.datePeriod(time);
  }

  async readAll() {
    if (this.notifications && this.notifications.length === 0){
      return;
  }
    try {
      await this.notificationService.markAllAsRead().toPromise();
      this.notifications.forEach(notification => notification.read_at = true);
      this.detectChanges();
    } catch (err) {
      this.toastr.error(err.message || 'Failed to mark all as read.');
    }
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  async onClick(notification) {
    if (!notification.read_at) {
      this.markAsRead(notification);
    }
    const {link, extras} = this.util.getNotificationRoute(notification);
    if (link) {
      if(extras) {
        this.router.navigate(link, extras);
      } else {
        this.router.navigate(link);
      }
      this.close();
    }
  }

  getRoute(notification){
    const link = this.util.getNotificationRouteFormat(notification);
     if (link) {
         return link;
      }
  }

  async markAsRead(notification) {
   if(!notification){
     return
   }
    try {
      await this.notificationService.markNotificationAsRead(notification.id).toPromise();
      notification.read_at = true;
      this.detectChanges();
    } catch (err) {
      console.log(err);
    }
  }
}
