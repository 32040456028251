import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {NgbCalendar, NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {Overlay} from '@angular/cdk/overlay';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss']
})
export class DatetimePickerComponent implements OnInit, DoCheck, OnChanges {

  @Input('date') date: NgbDateStruct;
  @Input('time') time: NgbTimeStruct;
  minDate: NgbDateStruct;

  differ: KeyValueDiffer<string, any>;

  @Output('onClose') onClose = new EventEmitter<any>();
  @Output('onChange') onChange = new EventEmitter<any>();
  @Output('onOk') onOk = new EventEmitter<any>();

  constructor(private differs: KeyValueDiffers,
              private calendar: NgbCalendar) {
    this.differ = this.differs.find({}).create();
  }

  ngDoCheck() {
    const change = this.differ.diff(this);
    if (change) {
      change.forEachChangedItem(item => {
        if (item.key === 'time' || item.key === 'date') {
          this.onChange.emit({date: this.date, time: this.time});
        }
      });
    }
  }

  ngOnInit() {
    this.minDate = {year: 1920, month: 1, day: 1};
  }

  open() {

  }

  ok() {
    this.onOk.emit({date: this.date, time: this.time});
  }

  close() {
    this.onClose.emit({date: this.date, time: this.time});
  }

  ngOnChanges(changes: SimpleChanges): void {
  }
}
