<div class="container container-sm user-settings">
  <div class="ssc-list-header">
    <h1>Lyrics color</h1>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div class="color-wrapper">
        <div class="color-item" *ngFor="let color of colors">
          <div class="color-title">{{color.title}}</div>
          <div class="color-info">
            <span>{{color.color}}</span>
            <div 
              class="color-holder" 
              [(colorPicker)]="color.color" 
              [style.background]="color.color"
              cpPosition="right" 
              (colorPickerChange)="handleChange()"
            ></div>
          </div>
        </div>
      </div>
      <div class="page-actions">
        <button class="btn btn-primary" (click)="handleSave()">
          SAVE
        </button>
        <button class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray" (click)="goBack()">CANCEL</button>
      </div>
    </div>
  </div>
</div>