import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fullscreen-modal',
  templateUrl: './fullscreen-modal.component.html',
  styleUrls: ['./fullscreen-modal.component.scss']
})
export class FullscreenModalComponent implements OnInit {

  @Output('handleOk') handleOk = new EventEmitter<any>();
  @Input('title') title: string;
  @Input('okText') okText: string;

  @ViewChild('content', { static: true }) private contentElem: ElementRef;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  ok() {
    this.handleOk.emit({});
  }

  open(title = 'Confirm', okText = 'ok') {
    this.title = title;
    this.okText = okText;
    return this.modalService.open(this.contentElem, {
      windowClass: 'ss-modal-fullscreen',
      backdrop: 'static',
    }).result.then((result) => {
      this.title = '';
      return result;
    }, (reason) => {
      this.title = '';
      return reason;
    });
  }
}
