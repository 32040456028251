import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sort-icon-filter',
  templateUrl: './sort-icon-filter.component.html',
  styleUrls: ['./sort-icon-filter.component.scss']
})
export class SortIconFilterComponent implements OnInit {
  @Input() type = '';
  @Input() size = 20;
  @Input() color = '#262628';

  constructor() { }

  ngOnInit() {
    console.log(this.color);
  }

}
