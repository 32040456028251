import {Component, OnInit, HostBinding} from '@angular/core';
//import * as $ from 'jquery';
import {AuthenticationService} from '@app/core';
// import { trigger, state, style, animate, transition, keyframes, query, stagger } from '@angular/animations';
// import { ScrollimateService } from "ng2-scrollimate";

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss']
    // animations: [
    //     trigger('routeAnimation', [
    //         state('*', style({transform: 'translateY(0)', opacity: 1})),
    //         transition('void => *', [
    //           style({transform: 'translateY(100px)', opacity: 0}),
    //           animate('0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000)')
    //         ]),
    //         transition('* => void',
    //           animate('0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000)', style({
    //             transform: 'translateX(100px)',
    //             opacity: 0
    //           }))
    //         )
    //       ]),
    //     trigger("fadeInUp", [
    //         state("inactive", style({
    //             opacity: 0,
    //             transform: 'translateY(200px)',
    //         })),
    //         state("active", style({
    //             opacity: 1,
    //             transform: 'translateY(0)',
    //         })),

    //         transition("* => active", animate("600ms ease-in")),
    //         transition("* => inactive", animate("400ms ease-out"))
    //     ]),
    //     trigger("slideFromLeft", [
    //         state("inactive", style({
    //             opacity: 0,
    //             transform: 'translateX(200px)',
    //         })),
    //         state("active", style({
    //             opacity: 1,
    //             transform: 'translateX(0)',
    //         })),

    //         transition("* => active", animate("800ms ease-in")),
    //         transition("* => inactive", animate("400ms ease-out"))
    //     ]),
    //     trigger("slideFromRight", [
    //         state("inactive", style({
    //             opacity: 0,
    //             transform: 'translateX(-200px)',
    //         })),
    //         state("active", style({
    //             opacity: 1,
    //             transform: 'translateX(0)',
    //         })),

    //         transition("* => active", animate("800ms ease-in")),
    //         transition("* => inactive", animate("400ms ease-out"))
    //     ]),
    //     trigger("flipInY", [
    //         state("inactive", style({
    //             opacity: 0,
    //         })),
    //         state("active", style({
    //             opacity: 1,
    //         })),
    //         transition('inactive => active', [
    //             animate(1000, keyframes([

    //                 style({opacity: 0, transform: 'perspective(400px) rotateY(90deg)', offset: 0}),
    //                 style({opacity: 1, transform: 'perspective(400px) rotateY(-10deg)',  offset: 0.4}),
    //                 style({opacity: 1, transform: 'perspective(400px) rotateY(10deg)',   offset: 0.7}),
    //                 style({opacity: 1, transform: 'perspective(400px) rotateY(0)',   offset: 1.0})

    //             ]))
    //           ]),
    //         transition('active => inactive', [
    //             animate(1000, keyframes([
    //               style({opacity: 0, transform: 'perspective(400px) rotateY(90deg)', offset: 0}),
    //               style({opacity: 1, transform: 'perspective(400px) rotateY(-10deg)',  offset: 0.4}),
    //               style({opacity: 1, transform: 'perspective(400px) rotateY(10deg)',   offset: 0.7}),
    //               style({opacity: 1, transform: 'perspective(400px) rotateY(0)',   offset: 1.0})
    //             ]))
    //           ])
    //     ]),
    //     trigger("rollIn", [
    //         transition('* => *', [
    //             query('img', style({ opacity: 0 }), {optional: true}),
    //             query('img', stagger('100ms', [
    //                 animate('400ms', keyframes([
    //                     style({ opacity: 0, transform: 'translateX(-100%)  rotate(-120deg)', offset: 0 }),
    //                     style({ opacity: 1, transform: 'translateX(0px) rotate(0deg)', offset: 1.0 })
    //                 ]))]), { optional: true })
    //           ]),
    //     ]),
    // ],
})
export class AboutUsComponent implements OnInit {
    isHamburgerVisible = false;
    userIsLoged = false;

    //animations
    // @HostBinding('@routeAnimation') routeAnimation = true;
    // aboutUSAnimations: any = {};


    // constructor(private authService: AuthenticationService, private scrollimateService: ScrollimateService) {
    constructor(private authService: AuthenticationService) {

        let is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
        const is_explorer = navigator.userAgent.indexOf('MSIE') > -1;
        const is_firefox = navigator.userAgent.indexOf('Firefox') > -1;
        let is_safari = navigator.userAgent.indexOf("Safari") > -1;
        const is_opera = navigator.userAgent.toLowerCase().indexOf("op") > -1;
        if ((is_chrome) && (is_safari)) {
            is_safari = false;
        }
        if ((is_chrome) && (is_opera)) {
            is_chrome = false;
        }
        // if (!is_safari) {
            // this.aboutUSAnimations = {
            //     defaultStates: [
            //         { //PRIORITY 3
            //             method: "pxElement", //this will be true if more than 200px of the element are currently visible (scrolled)
            //             value: 200,
            //             state: "active",
            //         },
            //         { //PRIORITY 4
            //             method: "default", //this will always be true
            //             state: "inactive",
            //         }
            //     ],
            //     secondBlock: {
            //         currentState: "inactive",
            //         states: [
            //             {
            //                 method: "percentElement",
            //                 value: 50,
            //                 state: "active",
            //             },
            //             {
            //                 method: "default",
            //                 state: "inactive"
            //             }
            //         ]
            //     },
            //     thirdBlock: {
            //         currentState: "inactive",
            //         states: [
            //             {
            //                 method: "percentElement",
            //                 value: 75,
            //                 state: "active",
            //             },
            //             {
            //                 method: "default",
            //                 state: "inactive"
            //             }
            //         ]
            //     },
            //     fourthBlock: {
            //         currentState: "inactive",
            //         states: [
            //             {
            //                 method: "percentElement",
            //                 value: 50,
            //                 state: "active",
            //             },
            //             {
            //                 method: "default",
            //                 state: "inactive"
            //             }
            //         ]
            //     },
            //     fourthBlockSlider: {
            //         currentState: "inactive",
            //         states: [
            //             {
            //                 method: "percentElement",
            //                 value: 75,
            //                 state: "active",
            //             },
            //             {
            //                 method: "default",
            //                 state: "inactive"
            //             }
            //         ]
            //     },
            //     fifthBlock: {
            //         currentState: "inactive",
            //         states: [
            //             {
            //                 method: "percentElement",
            //                 value: 60,
            //                 state: "active",
            //             },
            //             {
            //                 method: "default",
            //                 state: "inactive"
            //             }
            //         ]
            //     },
            //     fifthBlockImages: {
            //         currentState: "inactive",
            //         states: [
            //             {
            //                 method: "percentElement",
            //                 value: 0,
            //                 state: "active",
            //             },
            //             {
            //                 method: "default",
            //                 state: "inactive"
            //             }
            //         ]
            //     }
            // };
        // }
        //scrollimateService.setDefaultStates(this.aboutUSAnimations.defaultStates); //set the default states
    }

    ngOnInit() {
        // const token = localStorage.getItem('token');
        // this.userIsLoged = !!token;
        // const slide = $('.slider-single');
        // const slideTotal = slide.length - 1;
        // let slideCurrent = -1;

        // function slideInitial() {
        //     slide.addClass('proactivede');
        //     setTimeout(function () {
        //         slideRight();
        //     }, 500);
        //     setTimeout(function () {
        //         $('.slider-content').css({
        //             '-webkit-box-shadow': '50px 100px 100px -19px rgba(0, 0, 0, 0.4)',
        //             '-moz-box-shadow': '50px 100px 100px -19px rgba(0, 0, 0, 0.4)',
        //             'box-shadow': '50px 100px 100px -19px rgba(0, 0, 0, 0.4)'
        //         });
        //     }, 600);
        // }

        // function slideRight() {
        //     if (slideCurrent < slideTotal) {
        //         slideCurrent++;
        //     } else {
        //         slideCurrent = 0;
        //     }

        //     if (slideCurrent > 0) {
        //         var preactiveSlide = slide.eq(slideCurrent - 1);
        //     } else {
        //         var preactiveSlide = slide.eq(slideTotal);
        //     }
        //     const activeSlide = slide.eq(slideCurrent);
        //     if (slideCurrent < slideTotal) {
        //         var proactiveSlide = slide.eq(slideCurrent + 1);
        //     } else {
        //         var proactiveSlide = slide.eq(0);

        //     }

        //     slide.each(function () {
        //         const thisSlide = $(this);
        //         if (thisSlide.hasClass('preactivede')) {
        //             thisSlide.removeClass('preactivede preactive active proactive').addClass('proactivede');
        //         }
        //         if (thisSlide.hasClass('preactive')) {
        //             thisSlide.removeClass('preactive active proactive proactivede').addClass('preactivede');
        //         }
        //     });
        //     preactiveSlide.removeClass('preactivede active proactive proactivede').addClass('preactive');
        //     activeSlide.removeClass('preactivede preactive proactive proactivede').addClass('active');
        //     proactiveSlide.removeClass('preactivede preactive active proactivede').addClass('proactive');
        // }

        // function slideLeft() {
        //     if (slideCurrent > 0) {
        //         slideCurrent--;
        //     } else {
        //         slideCurrent = slideTotal;
        //     }

        //     if (slideCurrent < slideTotal) {
        //         var proactiveSlide = slide.eq(slideCurrent + 1);
        //     } else {
        //         var proactiveSlide = slide.eq(0);
        //     }
        //     const activeSlide = slide.eq(slideCurrent);
        //     if (slideCurrent > 0) {
        //         var preactiveSlide = slide.eq(slideCurrent - 1);
        //     } else {
        //         var preactiveSlide = slide.eq(slideTotal);
        //     }
        //     slide.each(function () {
        //         const thisSlide = $(this);
        //         if (thisSlide.hasClass('proactivede')) {
        //             thisSlide.removeClass('preactive active proactive proactivede').addClass('preactivede');
        //         }
        //         if (thisSlide.hasClass('proactive')) {
        //             thisSlide.removeClass('preactivede preactive active proactive').addClass('proactivede');
        //         }
        //     });
        //     preactiveSlide.removeClass('preactivede active proactive proactivede').addClass('preactive');
        //     activeSlide.removeClass('preactivede preactive proactive proactivede').addClass('active');
        //     proactiveSlide.removeClass('preactivede preactive active proactivede').addClass('proactive');
        // }

        // const left = $('.slider-left');
        // const right = $('.slider-right');
        // left.on('click', function () {
        //     slideLeft();
        // });
        // right.on('click', function () {
        //     slideRight();
        // });

        // slideInitial();
    }

    onLogOut() {
        this.authService.onLogOut();
    }

    hideHamburger(event) {
        const currentTarget = event.currentTarget;
        let toElement = event.toElement;
        if (navigator.userAgent.indexOf('Firefox') !== -1) {
            toElement = event.relatedTarget;
        }
        if (currentTarget !== toElement && !this.isDescendant(currentTarget, toElement)) {
            this.isHamburgerVisible = false;
        }

    }

    isDescendant(parent, child) {
        let node = child && child.parentNode;
        while (node != null) {
            if (node === parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }
}
