<div class="comments">
    <div class="backdrop-loading" *ngIf="pagination === 'paginated'" [ngClass]="{'d-none': !loading}">
        <i class="fa fa-spinner" aria-hidden="true">
        </i>
    </div>

    <app-comments-header #commentsHeader [commentsCount]="commentsTotal" [showSort]="type === 'comments'" [sortAsc]="sortAsc" (sortAscHandle)="sortAscHandle($event)" (sortDescHandle)="sortDescHandle($event)" [text]="text" [type]="type"></app-comments-header>



    <!-- reply box for recording comments -->
    <app-reply-comment #mainEditor
                       *ngIf="type == 'comments'"
                       [recordingId]="recordingId"
                       [replyDisabled]="replyDisabled"
                       [placeholder]="placeholder"
                       (editorFocus)="onEditorFocused($event)"
                       (editorBlur)="onEditorBlurred($event)"
                       (onCommentCreate)="onCommentCreateHandle($event)">
    </app-reply-comment>
    <!-- reply box for recording comments -->
    <div *ngIf="type !== 'message-board' && pageLoaded" id="pageNavContainer" style="margin-bottom: 13px;"><button (click)="getPrevComments()">&lt; PREV</button><p>Page: {{currentCommentPage}}</p><button (click)="getNextComments()">NEXT &gt;</button></div>

    <!-- Added For Comment Delete-->
    <app-comment *ngFor="let comment of comments"
                 [comment]="comment"
                 [userId]="user?.id"
                 [ownerUser]="ownerUser"
                 [type]="type"
                 (onError)="onErrorHandle($event)"
                 (onReply)="onReplyHandle($event)"
                 (onQuote)="onQuoteHandle($event)"
                 (onCommentDelete)="onCommentDeleteHandle($event)">
    </app-comment>
    <!-- reply box for messageboard comments -->
    <app-reply-comment #mainEditor
                       elId="mainEditor"
                       *ngIf="type === 'message-board'"
                       [recordingId]="recordingId"
                       [replyDisabled]="replyDisabled"
                       [placeholder]="placeholder"
                       [state]="'open'"
                       [autofocus]="true"
                       (editorFocus)="onEditorFocused($event)"
                       (editorBlur)="onEditorBlurred($event)"
                       (onCommentCreate)="onCommentCreateHandle($event)">
    </app-reply-comment>
    <!-- reply box for messageboard comments -->

    <!-- <app-infinite-scroll *ngIf="pagination !== 'paginated'" (onView)="getComments()"></app-infinite-scroll> -->
    <div *ngIf="type !== 'message-board' && pageLoaded && comments.length > 0" id="pageNavContainer"><button (click)="getPrevComments()">&lt; PREV</button><p>Page: {{currentCommentPage}}</p><button (click)="getNextComments()">NEXT &gt;</button></div>
    <app-list-pagination
    *ngIf="pagination === 'paginated'"
        [total]="commentsTotal"
        [pageSize]="perPage"
        [currentPage]="page"
        [boundaryLinks]="true"
        (onPageChange)="page = $event; onPageChangeHandle($event)">
    </app-list-pagination>

    <div class="comment--loading" *ngIf="pagination !== 'paginated'" [ngClass]="{'d-none': !loading}">
        <i class="fa fa-spinner" aria-hidden="true"></i>
    </div>

    <ng-template #errorModalRef let-c="close" let-d="dismiss">
        <div class="p-3 bg-warning text-dark row">
            <div class="col-6 align-self-center">{{currentError}}</div>
            <div class="col-6">
                <button type="button" class="btn btn-outline-dark pull-right" (click)="c('Close click')">Close</button>
            </div>
        </div>
    </ng-template>
    <app-blocked-notify-modal #blockedModal></app-blocked-notify-modal>
</div>
