import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ss-store-box',
  templateUrl: './ss-store-box.component.html',
  styleUrls: ['./ss-store-box.component.scss']
})
export class SsStoreBoxComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
