<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M6.48528137,12.0710678 L1.70710678,7.29289322 C1.31658249,6.90236893 0.683417511,6.90236893 0.292893219,7.29289322 C-0.0976310729,7.68341751 -0.0976310729,8.31658249 0.292893219,8.70710678 L5.77817459,14.1923882 C6.16869888,14.5829124 6.80186386,14.5829124 7.19238816,14.1923882 L17.6776695,3.70710678 C18.0681938,3.31658249 18.0681938,2.68341751 17.6776695,2.29289322 C17.2871452,1.90236893 16.6539803,1.90236893 16.263456,2.29289322 L6.48528137,12.0710678 Z"
        id="path-icon-check"></path>
    </defs>
    <g id="Icons/Done" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlink:href="#path-icon-check"></use>
      </mask>
      <use id="Rectangle-3" [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-icon-check"></use>
    </g>
  </svg>
</div>