import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import { SocialAuthService } from 'angularx-social-login';
//import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { UsersService, AuthenticationService, UtilService } from '@app/core';

@Component({
  selector: 'app-social-signup-form',
  templateUrl: './social-signup-form.component.html',
  styleUrls: ['./social-signup-form.component.scss']
})
export class SocialSignupFormComponent implements OnInit {
  @Output() processed = new EventEmitter();
  formGroup: UntypedFormGroup;
  hideForm = true;
  initiated = false;
  socialUser;

  constructor(
    //private authService: SocialAuthService,
    private usersService: UsersService,
    private util: UtilService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private toast: ToastrService
  ) {
  }

   ngOnInit() {
  //   this.createForm();

  //   this.authService.authState.subscribe(async (user) => {
  //     if (!user || !this.initiated) {
  //       return;
  //     }
  //     this.initiated = false;
  //     console.log('user', user);
  //     this.socialUser = user;
  //     const screenName = `${user.firstName}${user.lastName}`;
  //     try {
  //       const email = user.email;
  //       const response = await this.usersService.isUnique(email, screenName).toPromise();
  //       console.log(response);
  //       if (response.success) {
  //         this.hideForm = false;
  //         this.processed.emit();
  //         this.formGroup.setValue({
  //           email: this.socialUser.email,
  //           name: this.socialUser.name,
  //           screen_name: `${this.socialUser.firstName}${this.socialUser.lastName}`,
  //           password: ''
  //         });
  //       }
  //     } catch (err) {
  //       console.log(err);
  //       const data = JSON.parse(err._body);
  //       if (data && !data.success) {
  //         const loginData = {
  //           email: this.socialUser.email,
  //           name: this.socialUser.name,
  //           code: this.socialUser.authToken,
  //           screen_name: `${this.socialUser.firstName}${this.socialUser.lastName}`
  //         };
  //         this.login(loginData);
  //       }
  //     }
  //   });
  }

  // onSubmit() {
  //   this.formGroup.controls.name.markAsDirty();
  //   this.formGroup.controls.email.markAsDirty();
  //   this.formGroup.controls.screen_name.markAsDirty();
  //   this.formGroup.controls.password.markAsDirty();
  //   if (this.formGroup.valid) {
  //     const loginData = this.formGroup.value;
  //     loginData.code = this.socialUser.authToken;
  //     if (!loginData.password) {
  //       delete loginData.password;
  //     }
  //     this.login(loginData, true);
  //   }
  // }

  // login(data, isNew = false) {
  //   if (this.socialUser.provider === 'GOOGLE') {
  //     this.loginGoogle(data, isNew);
  //   } else if (this.socialUser.provider === 'FACEBOOK') {
  //     this.loginFB(data, isNew);
  //   }
  // }

  // signout() {
  //   try {
  //     this.authService.signOut();
  //   } catch (err) {
  //     console.log('social signout error', err);
  //   }
  // }

  // async loginGoogle(data, isNew = false) {
  //   try {
  //     const result = await this.authenticationService.onGoogleSignUp(data).toPromise();
  //     this.util.setloggedUserData(result.user);
  //     localStorage.setItem('token', result.token);
  //     Cookie.set('Authorization', 'Bearer ' + result.token);
  //     if (isNew) {
  //       this.router.navigate(['/d/signup-complete']);
  //     } else {
  //       this.router.navigate(['/d/home']);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     const error = JSON.parse(err._body);
  //     this.toast.error(error.message);
  //   }
  // }

  // async loginFB(data, isNew = false) {
  //   try {
  //     const result = await this.authenticationService.onFacebookSignUp(data).toPromise();
  //     this.util.setloggedUserData(result.user);
  //     localStorage.setItem('token', result.token);
  //     Cookie.set('Authorization', 'Bearer ' + result.token);
  //     if (isNew) {
  //       this.router.navigate(['/d/signup-complete']);
  //     } else {
  //       this.router.navigate(['/d/home']);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     const error = JSON.parse(err._body);
  //     this.toast.error(error.message);
  //   }
  // }

  // handleFBSignup() {
  //   this.initiated = true;
  //   //this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  // }

  // handleGoogleSignup() {
  //   this.initiated = true;
  //   //this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  // }

//   createForm() {
//     this.formGroup = this.fb.group({
//       name: ['', [
//         Validators.required,
//       ]],
//       email: ['', [
//         Validators.required,
//         Validators.email,
//         Validators.minLength(5),
//         Validators.maxLength(320)
//       ]],
//       screen_name: ['', [
//         Validators.required,
//         Validators.minLength(2),
//         Validators.maxLength(20),
//         this.isValidScreenName.bind(this)
//       ]],
//       password: ['', [
//         Validators.minLength(6),
//         Validators.maxLength(20)
//       ]],
//     });
//   }

//   isValidScreenName(input: AbstractControl) {
//     const screenNameArray = input.value.split(/(?![A-Za-z0-9\-\_]+)/);
//     if (screenNameArray.length > 1) {
//       return {
//         isValid: true
//       };
//     } else {
//       return null;
//     }
//   }
 }
