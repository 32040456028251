import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-ss-price',
  templateUrl: './ss-price.component.html',
  styleUrls: ['./ss-price.component.scss']
})
export class SsPriceComponent implements OnInit, OnChanges {

  @Input() price;
  @Input() period;
  @Input() showDecimal = true;
  intPart = '';
  decPart = '';

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['price'].currentValue && changes['price'].currentValue !== changes['price'].previousValue) {
      const price = this.price / 100;
      const priceParts = price.toString().split('.');
      this.intPart = priceParts[0];
      if (priceParts[1]) {
        this.decPart = priceParts[1];
      } else {
        this.decPart = '00';
      }
    }
  }
}
