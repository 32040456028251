import { ChangeDetectorRef, NgZone, Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  GenresService,
  SongService,
  MessageBoardService,
  ProfileService,
  RecordingService,
  UtilService,
  HelperService,
  EventService,
} from '@app/core';
import { FullscreenModalComponent } from '@app/shared/fullscreen-modal/fullscreen-modal.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-home-new-releases',
  templateUrl: './home-new-releases.component.html',
  styleUrls: ['./home-new-releases.component.scss']
})
export class HomeNewReleasesComponent implements OnInit {
  @ViewChild('playlist') private playlistElem;
  @ViewChild('fsModal') fsModal: FullscreenModalComponent;
  items = [];
  itemsPerPage = 30;
  currentPage = 1;


  duets = [];
  artists = [];
  selectedLetter = '';
  lastSelectedLetter;
  recordingSortOptions = [];
  songSortOptions = [];
  songSortOptionsFavoritesFirst = [];
  totalPages = 0;
  artistSortOptions = [];
  genreSortOptions = this.util.recordingSortOptions;
  filterByDuet = false;
  selectedGenreIndex = 0;
  selectedDecadeIndex = 0;
  decadesArray = [];
  letters = [];
  genresArray = [{name: 'All', id: 90000}];

  genresData: any = {
    ...this.util.generateGridData(),
    sort: this.genreSortOptions[0],
    fetch: this.genreService.getGenres.bind(this.genreService),
  };

  releasedData: any = {
    ...this.util.generateGridData(),
    perPage: this.itemsPerPage,
    fetch: this.songService.getNewReleasesSeeMore.bind(this.songService),
  };


  constructor(
    private genreService: GenresService,
    private toastService: ToastrService,
    private recordingService: RecordingService,
    private songService: SongService,
    private helperService: HelperService,
    private messageBoardService: MessageBoardService,
    public profileService: ProfileService,
    private eventService: EventService,
    public util: UtilService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private location: Location
  ) {
 //   this.songSortOptions = this.util.songSortOptions;
    this.recordingSortOptions = this.util.recordingSortOptions;
    this.releasedData.sort = this.util.songSortOptions[0];

  }

  ngOnInit() {
    // this.fsModal.open('Welcome to the famility!', 'GET STARTED');

    // this.songService.getRecommended().subscribe(response => {
    //   this.trendingSongs = response['data'];
    // });
    // try {
    //   const result = await this.recordingService.getSnappenins().toPromise();
    //   this.snappenins = result.data;
    // } catch (err) {
    //   this.toastService.error('Failed to load snappenins.');
    // }

    // this.onScrollEndHandle(this.topRecordingData, null);
  
    this.decadesArray = this.util.getDecadeArray();
    this.letters = this.util.getLettersArray();

    this.route.queryParams.subscribe(params => {
      if(typeof params.page != 'undefined') {
        this.currentPage = params.page;
      }
    });

    this.route.queryParams.subscribe(params => {
      if(typeof params.letter != 'undefined') {
        this.selectedLetter = '';
        this.handleFilter( params.letter);
      }
    });

    this.route.queryParams.subscribe(params => {
      if(typeof params.decade != 'undefined') {
        this.releasedData.decade = params.decade;
        this.selectedDecadeIndex = this.decadesArray.findIndex(element => element.includes(params.decade))
      }
    });

    this.route.queryParams.subscribe(params => {
      if(typeof params.genre != 'undefined') {
       this.selectedGenreIndex = params.genre;
       this.genreClick(params.genre);
      }
    });   

    this.genresData.fetch().subscribe((response)=>{
      console.log(response)
      response.data.forEach(item => {
        this.genresArray.push(item)
      })
    });

    this.route.queryParams.subscribe(params => {
      if(typeof params.duet != 'undefined') {
       this.filterByDuet = params.duet;
       this.duetClick(true);
      }
    });   

    this.onScrollEndHandle();
  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  navSing(id){
    this.navigate([`/recorder/${id}`]);
  }

  navArtistProfile(id){
    this.navigate([`/d/artists/${id}`]);
  }
 
  back(){
    window.history.back();
  }

  setPageParam() {  
    this.location.replaceState(this.location.path().split('?')[0], '');
    this.location.replaceState(this.location.path() + `?page=${this.currentPage}`);
    if(this.selectedLetter.length >0){
      this.location.replaceState(this.location.path() + `&letter=${this.selectedLetter}`);
     }
     if(this.releasedData.decade && this.releasedData.decade.length>0){
       this.location.replaceState(this.location.path() + `&decade=${this.releasedData.decade}`);
     }
     if(this.releasedData.filter.genre_id && this.releasedData.filter.genre_id>0  ){
      this.location.replaceState(this.location.path() + `&genre=${this.releasedData.filter.genre_id}`);
    }

    if(this.releasedData.filter.duet && this.releasedData.filter.duet === true){
      this.location.replaceState(this.location.path() + `&duet=true`);
    }

  }

  decadeClick(event) {
    this.releasedData.decade = this.decadesArray[event].substring(0, this.decadesArray[event].length - 2)
    if(event == 0) {
      this.releasedData.decade = '';
    }
    this.resetDataObj(this.releasedData);
    //this.onScrollEndHandle(this.releasedData, null);
    this.onScrollEndHandle();
  }

  genreClick(event) {
    if(this.genresArray[event].name == 'All' && typeof this.releasedData.filter.genre_id != 'undefined') {
      delete this.releasedData.filter.genre_id;
    } else {
      this.releasedData.filter.genre_id = this.genresArray[event].id;
      //this.selectedGenreIndex = this.favoriteData.filter.genre_id;
    }
    this.resetDataObj(this.releasedData);
   // this.onScrollEndHandle(this.releasedData, null);
    this.onScrollEndHandle();
  }

  duetClick(value){
    this.filterByDuet=value;
    this.releasedData.filter.duet = this.filterByDuet;
    this.resetDataObj(this.releasedData);
   // this.onScrollEndHandle(this.releasedData, null);
    this.onScrollEndHandle();

  }

  handleFilter(filter) {
    if(typeof this.releasedData.filter == 'undefined') {
      this.releasedData.filter = {};
    }
    if(this.releasedData.filter.title == filter) {
      delete this.releasedData.filter.title
      this.selectedLetter = '';
    } else {
      this.selectedLetter = filter;
      this.releasedData.filter.title = filter
    }
    this.resetDataObj(this.releasedData);
    //this.onScrollEndHandle(this.releasedData, null);
    this.onScrollEndHandle();
  }


  favSong(song) {
    this.songService.fav(song.id).subscribe(response => {
      if (response.success) {
        song.favorited = true;
        this.detectChanges();
        this.toastService.success('Song was added to favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  checkMine(item){
    return item.favorited;
  }
  
  unFavSong(song) {
    this.songService.unfav(song.id).subscribe(response => {
      if (response.success) {
        song.favorited = false;
        this.detectChanges();
        this.toastService.success('Song was removed from favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  // favRecording(recording) {
  //   console.log(recording);
  //   this.recordingService.fav(recording.id).subscribe(response => {
  //     recording.favorited = true;
  //     this.detectChanges();
  //     if (response.success) {
  //       this.toastService.success('Recording was added to favorites.');
  //     } else {
  //       this.toastService.error('There was an error, try again later.');
  //     }
  //   });
  // }

  // unFavRecording(recording) {
  //   this.recordingService.unfav(recording.id).subscribe(response => {
  //     recording.favorited = false;
  //     this.detectChanges();
  //     if (response.success) {
  //       this.toastService.success('Recording was removed from favorites.');
  //     } else {
  //       this.toastService.error('There was an error, try again later.');
  //     }
  //   });
  // }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  setSortFields(sort){
    if (sort) {
      this.releasedData.sort = sort;
    }
  }

  onScrollEndHandle() { 
    let data = this.releasedData;

    console.log(data.filter)
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }
   
   
   
    // if (sort) {
    //   data.sort = sort;
    // }
 
    if (!data.loading) {
      data.loading = true;

      //this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade)
        .subscribe(
          response => {
            data.currentPage =  this.currentPage;
            data.items = response.data;
            data.lastPage = response.next_page_url;
            data.loading = false;
            this.detectChanges();
            this.setPageParam();
          },
          error => {
            this.toastService.error(error.message);
            data.loading = false;
          }
        );
    }
  }

  onScrollEndFreeSongsHandle(data, sort, reload = false) {
    if (reload || (!data.loading && data.lastPage)) {
      data.loading = true;
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, 'gold', data.freeSongsFirst)
        .subscribe(
          response => {
            data.currentPage =  this.currentPage;
            data.items = data.items.concat(response.data);
            data.lastPage = response.next_page_url;
            data.loading = false;
            this.detectChanges();
            this.setPageParam();
          },
          error => {
            this.toastService.error(error.message);
            data.loading = false;
          }
        );
    }
  }

  getNextSongs() {
    let data = this.releasedData;
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }

    if (!data.loading && data.lastPage) {
      data.loading = true;
      data.currentPage++;
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade)
        .subscribe(
          response => {
            this.currentPage++;
            data.items = response.data;
            data.lastPage = response.next_page_url;
            data.loading = false;
            window.scrollTo(0, 0);
            this.detectChanges();
            this.setPageParam();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  getPrevSongs() {
    let data = this.releasedData;
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }

    if (!data.loading && data.currentPage != 1) {
      data.loading = true;
      data.currentPage--;
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade)
        .subscribe(
          response => {
            this.currentPage--
            data.items = response.data;
            this.setPageParam()
            data.lastPage = response.next_page_url;
            data.loading = false;
            window.scrollTo(0, 0);
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  resetDataObj(data) {
    this.currentPage = 1;
    data.currentPage = 1;
    data.loading = false;
    data.items = [];
    data.lastPage = null;
    return data;
  }

  shareRecording(recording) {
    this.eventService.onSocialShare({ recording });
  }

  recordingToLegacy(recording) {
    this.recordingService.appearOnLegacy(recording.id).subscribe(
      response => {
        this.toastService.success('Recording will appear on legacy');
      },
      () => {
        this.toastService.success("There's an error, please try again later.");
      }
    );
  }

  onAddtoPlaylist(id) {
    this.playlistElem.open(id);
  }

  // async subscribeUser(recording) {
  //   this.helperService.subscribeUser(recording, [this.topRecordingData.items]);
  // }

  // async unsubscribeUser(recording) {
  //   this.helperService.unsubscribeUser(recording, [
  //     this.topRecordingData.items,
  //   ]);
  // }

  handleFreeSongsChange(data, gold) {
    console.log(data, gold);
    data.freeSongsFirst = gold;
    this.resetDataObj(data);
    this.onScrollEndFreeSongsHandle(data, '', true);
  }

  howOthersSingThis(id){
    this.router.navigate([`/d/ListenOthersSing/${id}`]);
  }
}
