
import {debounceTime, takeUntil} from 'rxjs/operators';
import {ChangeDetectorRef, Component, Injector, Input, Output, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReplyCommentComponent} from './reply-comment/reply-comment.component';
import {MessageBoardService, ProfileService, RecordingService, Data} from '@app/core';
import {Comment} from './comment/comment.model';
import {ActivatedRoute, Router} from '@angular/router';
//import {ScrollToConfigOptions, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import {CommentsHeaderComponent} from './comments-header/comments-header.component';
import { IfStmt } from '@angular/compiler';
import { UtilService } from '@app/core/services/util.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnDestroy {
  @Input() comments: Comment[] = [];
  @Input() commentsTotal;
  @Input() commentsPerPage;
  @Input() recordingId;
  @Input() replyDisabled = false;
  @Input() loading = false;
  @Input() user;
  @Input() placeholder = 'Add a Comment';
  @Input() text = 'Comments';
  @Input() type = 'comments';
  @Input() pagination = 'scroll';
  //@Input() scrollTopOnComments = true;
  @Input() goToLastReply = false;
  @Output() editorFocus = new EventEmitter<any>();
  @Output() editorBlur = new EventEmitter<any>();
  @Output() showReplyButton  = new EventEmitter<any>(); //Create emitter
  @Output() showCurrentCommentPage  = new EventEmitter<any>(); //Create emitter

  //@ViewChild('scrollRef') scrollRef;
  @ViewChild('errorModalRef', { static: true }) errorModalRef;
  @ViewChild('mainEditor') mainEditor: ReplyCommentComponent;
  @ViewChild('commentsHeader', { static: true }) commentsHeader: CommentsHeaderComponent;
  @ViewChild('blockedModal', { static: false }) blockedModalElem;
  private ngUnsubscribe: Subject<void> = new Subject();
  private debouncer: Subject<any> = new Subject();
  currentError: any;
  currentCommentPage = 1;
  throttle = 300;
  // scrollDistance = 0.5;
  // scrollUpDistance = 1;
  lastPage = 1;
  perPage = 20;
  page = 1;
  service: any;
  hflag = true;
  pageLoaded = false;
  sortAsc = false;
  sort = 'ASC';
  loggedInUser:any ;

  constructor(
    private router: Router,
    private injector: Injector,
    private profileService: ProfileService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    //private _scrollToService: ScrollToService,
    private cdr: ChangeDetectorRef,
    private data: Data,
    private utilService: UtilService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.recordingId = params['recordingId'];
    });
  }

  ngOnInit() {
    console.log(this.pagination)
    if (this.type === 'message-board') {
      this.text = 'Replies';
      this.service = this.injector.get<any>(MessageBoardService);
      this.pagination = 'paginated';
      this.perPage = 50;
    } else {
      this.service = this.injector.get<any>(RecordingService);
    }
    console.log(this.type, this.pagination);

    if (this.pagination === 'paginated') {
      // add this.goToLastReply to go to last page
      this.getPaginatedComments(this.currentCommentPage, this.goToLastReply);
    } else {
      //if (this.comments.length === 0) {
      this.getComments();
      //}
    }


    this.debouncer.pipe(
      debounceTime(400))
      .subscribe(page => {
        this.getPaginatedComments(page);
      });

    this.updateHeaderText();

    this.loggedInUser = this.utilService.getloggedUserData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onCommentCreateHandle({content, recordingId, commentId, onComplete}) {
    //added to fix error on second reply or quote
    let currentLastPage=this.lastPage;
    let page = null;
    if (this.type === 'message-board') {
      page = this.page;
    }


    this.service.sendComment(content, recordingId, commentId, page)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          console.log('send comment data', data);
          if (!commentId) {

          }

          if (this.type === 'message-board') {
            const model = data.model;
            this.comments = model as Comment[];
            this.currentCommentPage = this.currentCommentPage + 1;
            this.lastPage = currentLastPage;

            this.data.msgBrdTopicReply = this.data.msgBrdTopicReply + 1;
            
            //create mentions list
            // let mentionsIds = this.data.getMentionsIds(content);
            // if(mentionsIds.length){
            //   for(var i=0;i<mentionsIds.length;i++) {
            //     let emailFound = this.data.msgBrdReplyList.find((value) => {
            //       if (value.topic_reply_recipient_email === mentionsIds[i].dataset.email) return true;
            //     });
            //     if (emailFound){
            //         this.data.msgBrdReplyList.find(value => value.topic_reply_recipient_email === mentionsIds[i].dataset.email).reply_mention++;     
            //       } else {
            //         this.data.msgBrdReplyList.push(
            //             {
            //                 'topic_reply_recipient_email': mentionsIds[i].dataset.email,
            //                 'topic_reply_recipient_name': mentionsIds[i].dataset.value,
            //                 'newsletter': mentionsIds[i].dataset.newsletter,
            //                 'topic_fav': false,
            //                 'topic_love': false,
            //                 'reply_count': 0,
            //                 'reply_mention': 1,
            //                 'topic_reply_quote': 0,
            //                 'topic_reply_love': 0,
            //                 'topic_reply_reaction': 0,
            //                 'topic_reply_emoji': []
            //             }
            //         );                
            //     }  
            //   }
            // }

          } else {
            this.commentsTotal = this.commentsTotal + 1;
            this.currentCommentPage = 1;
            this.lastPage = 1;
            this.comments = [];
            this.getComments(this.sort)


            //create mentions list  RECORDINGS
            // let mentionsIds = this.data.getMentionsIds(content);
            // if(mentionsIds.length){
            //   for(var i=0;i<mentionsIds.length;i++) {
            //     let emailFound = this.data.recordingMentionList.find((value) => {
            //       if (value.topic_reply_recipient_email === mentionsIds[i].dataset.email) return true;
            //     });
            //     if (emailFound){
            //         this.data.recordingMentionList.find(value => value.topic_reply_recipient_email === mentionsIds[i].dataset.email).reply_mention++;     
            //       } else {
            //         this.data.recordingMentionList.push(
            //             {
            //                 'topic_reply_recipient_email': mentionsIds[i].dataset.email,
            //                 'topic_reply_recipient_name': mentionsIds[i].dataset.value,
            //                 'newsletter': mentionsIds[i].dataset.newsletter,
            //                 'reply_mention': 1,
            //                 'source': 'recording'
            //             }
            //         );                
            //       }  
            //   }
            // }


            //const comment = data.model;
            //this.comments.push(comment);
             //this.commentsTotal = data.comment.recording.stats.comments;
            //this.currentCommentPage = this.currentCommentPage + 1;
          }
          let mentionsIds = this.data.getMentionsIds(content);
            if(mentionsIds.length){
              for(var i=0;i<mentionsIds.length;i++) {
                let emailFound = this.data.mentionList.find((value) => {
                  if (value.topic_reply_recipient_email === mentionsIds[i].dataset.email) return true;
                });
                if (emailFound){
                    this.data.mentionList.find(value => value.topic_reply_recipient_email === mentionsIds[i].dataset.email).reply_mention++;     
                  } else {
                    this.data.mentionList.push(
                        {
                            'topic_reply_recipient_email': mentionsIds[i].dataset.email,
                            'topic_reply_recipient_name': mentionsIds[i].dataset.value,
                            'newsletter': mentionsIds[i].dataset.newsletter,
                            'reply_mention': 1,
                            'source': this.type
                        }
                    );                
                  }  
              }
            }

          // this.lastPage = model.last_page;
          // this.page = model.current_page; // for paginated pagination
          onComplete();

        }, error => {
          if (error.error.reason === 'UNAUTHENTICATED'){
            this.whenLoggedOut();
            }
          if (error.status === 403) {
            // show blocked modal
           this.blockedModalElem.open( error.error.message);
          }
        });
        if(this.type === 'message-board') {
          this.commentsTotal = this.commentsTotal + 1;
          //this.currentCommentPage = 1; //remove so REPLY BOX shows after posting in MB
          //this.lastPage = 1;           //remove so REPLY BOX shows after posting in MB
          this.comments = [];
          //this.getComments(this.sort)
        }
  }


  getComments(sort = 'ASC') {

    if (this.recordingId) {
      if (!this.loading && this.lastPage) {
        this.loading = true;
        this.service.getComments(this.recordingId, this.currentCommentPage, 20, sort)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(response => {
            //this.currentCommentPage++;
            const comments = response.data as Comment[];
            //this.comments = this.comments.concat(comments);
            this.comments = comments;

            //this.commentsTotal = response.total;
            this.lastPage = response.next_page_url;
            this.loading = false;
            this.pageLoaded = true;
            this.updateHeaderText();
          }, err => {
            this.onErrorHandle(err);
            this.loading = false;
          });
      }

    } else {
      this.loading = false;
      this.onErrorHandle({message: 'Please pass recordingId as url param'});
    }
  }

  getNextComments(sort = 'ASC') {
    if (this.recordingId) {
      if (!this.loading && this.lastPage) {
        this.currentCommentPage++, scrollTo(0, 500);
        this.loading = true;
        this.service.getComments(this.recordingId, this.currentCommentPage, 20, sort)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(response => {
            console.log(response)
            const comments = response.data as Comment[];
            //this.comments = this.comments.concat(comments);
            this.comments = comments;

            //this.commentsTotal = response.total;
            this.lastPage = response.next_page_url, scrollTo(0, 0);
            this.loading = false;
            this.updateHeaderText();
          }, err => {
            this.currentCommentPage--;
            this.onErrorHandle(err);
            this.loading = false;
          });
      }

    } else {
      this.loading = false;
      this.onErrorHandle({message: 'Please pass recordingId as url param'});
    }
  }

  getPrevComments(sort = 'ASC') {
    if (this.recordingId) {
      if (!this.loading && this.currentCommentPage != 1) {
        this.currentCommentPage--, scrollTo(0, 500);
        this.loading = true;
        this.service.getComments(this.recordingId, this.currentCommentPage, 20, sort)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(response => {
            console.log(response)
            const comments = response.data as Comment[];

            //this.comments = this.comments.concat(comments);
            this.comments = comments;

            //this.commentsTotal = response.total;
            this.lastPage = response.next_page_url, scrollTo(0, 0);
            this.loading = false;
            this.updateHeaderText();
          }, err => {
            this.currentCommentPage++;
            this.onErrorHandle(err);
            this.loading = false;
          });
      }

    } else {
      this.loading = false;
      this.onErrorHandle({message: 'Please pass recordingId as url param'});
    }
  }

  onEditorFocused(e) {
    if (this.editorFocus) {
      this.editorFocus.emit(e);
    }
  }

  onEditorBlurred(e) {
    if (this.editorBlur) {
      this.editorBlur.emit(e);
    }
  }

  sortAscHandle($event: any) {
    console.log('sorted');
    this.currentCommentPage = 1;
    this.lastPage = 1;
    this.getComments('ASC');
    this.sort = 'ASC'
    this.sortAsc = false;
    this.comments = [];
  }

  sortDescHandle($event: any) {
    console.log('sorted');
    this.currentCommentPage = 1;
    this.lastPage = 1;
    this.getComments('DESC');
    this.sort = 'DESC';
    this.sortAsc = true;
    this.comments = [];
  }

  getPaginatedComments(page, loadLastPage = false) {
    this.showCurrentCommentPage.emit(page);
    if (this.recordingId) {
      this.loading = true;
      this.service.getComments(this.recordingId, page, 50)
        .pipe(debounceTime(1000))
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
          const comments = data.data as Comment[];
          this.currentCommentPage = data.current_page, scrollTo(0, 0);
          this.comments = comments;
          this.commentsTotal = data.total;
          this.commentsPerPage = data.per_page;
          console.log(data)
          if(data.last_page_url != null) {
            this.lastPage = Number(this.getLastPageFromURL(data.last_page_url));
          } else {
            this.lastPage = data.current_page
          }
          //this.lastPage = Number(data.last_page_url.charAt(data.last_page_url.length - 1));

          this.loading = false;
          this.updateHeaderText();
          if (loadLastPage && this.lastPage !== this.currentCommentPage) {
            this.getPaginatedComments(this.lastPage);
          }
          //show topic info on top of page first time
          // if (this.scrollTopOnComments) {
          //   document.querySelector('#commentsHeader').scrollIntoView(false);
          //  }

         // this.scrollTopOnComments = true;
        }, err => {
          this.onErrorHandle(err);
          this.loading = false;
        });
    }
    //go to reply on last page
    if(this.goToLastReply){
      this.onReplyHandle();
    }
  }

  getLastPageFromURL(url){
    let pos = url.lastIndexOf("=");
    let result =  url.substr(pos + 1);
    if( this.currentCommentPage != result){
        this.showReplyButton.emit(true); //Set Value to emitter
      } else {
        this.showReplyButton.emit(false);
      }
    return result;
  }

  onPageChangeHandle(page) {
    this.debouncer.next(page);
  }

  trackByName(index, comment) {
    return comment.id;
  }

  updateHeaderText() {
    if (this.type === 'message-board') {
      this.text = this.commentsTotal === 1 ? 'Reply' : 'Replies';
    } else {
      this.text = this.commentsTotal === 1 ? 'Comment' : 'Comments';
    }
    this.cdr.detectChanges();
  }

  onErrorHandle(event: any) {
     
    //Stop big yellow ERROR box from showing
    // if (event instanceof Error) {
    //   this.currentError = event.message;
    // } else {
    //   this.currentError = JSON.stringify(event);
    // }
    // setTimeout(() => {
    //   this.modalService.open(this.errorModalRef);
    // }, 0);
  }

  onQuoteHandle(event: any) {
 
    if (this.type === 'message-board') {
        // hack to re-render the box for autofocus
        this.hflag = false;
        setTimeout(() => {
          this.hflag = true;
        }, 0);

      // need to navigate to last page before trying to insert.
      this.toLastPage().then(() => {
        setTimeout(() => {
          this.mainEditor.insertQuote(event);
        }, 100);
      });
    } else {
      setTimeout(() => {
        this.mainEditor.insertQuote(event);
      }, 100);
    }

    this.profileService.getUser(event.author.id).subscribe(user => {
        this.data.msgBrdReplyQuoted = this.data.msgBrdReplyQuoted + 1;
        let recipient = user;
        this.data.msgBrdTopicReply = this.data.msgBrdTopicReply + 1;
        if (this.type === 'message-board' && recipient.email != this.loggedInUser.email) {
          let emailFound = this.data.msgBrdReplyList.find((value, i) => {
              if (value.topic_reply_recipient_email === recipient.email) return true;
          });
          if (emailFound){
              this.data.msgBrdReplyList.find(value => value.topic_reply_recipient_email === recipient.email).topic_reply_quote++;  
              
          } else {
              this.data.msgBrdReplyList.push(
                  {
                      'topic_reply_recipient_email': recipient.email,
                      'topic_reply_recipient_name': event.author.screen_name,
                      'newsletter': recipient.newsletter,
                      'topic_reply_quote': 1,
                      'topic_reply_love': 0,
                      'topic_reply_reaction': 0,
                      'topic_reply_emoji': []
                  }
              );                
          }
        }
        //for Post reply
        if (this.type === 'message-board' && recipient.email != this.loggedInUser.email) {
          let emailFound = this.data.msgBrdPost_Reply_List.find((value, i) => {
              if (value.topic_reply_recipient_email === recipient.email) return true;
          });
          if (emailFound){
              this.data.msgBrdPost_Reply_List.find(value => value.topic_reply_recipient_email === recipient.email).topic_reply_quote++;  
              
          } else {
              this.data.msgBrdPost_Reply_List.push(
                  {
                      'topic_reply_recipient_email': recipient.email,
                      'topic_reply_recipient_name': event.author.screen_name,
                      'newsletter': recipient.newsletter,
                      'topic_reply_quote': 1,
                      'topic_reply_love': 0,
                      'topic_reply_reaction': 0,
                      'topic_reply_emoji': []
                  }
              );                
          }
        }




      });





  }

  onReplyHandle() {
    if (this.type === 'message-board') {
      // hack to re-render the box for autofocus
      this.hflag = false;
      setTimeout(() => {
        this.hflag = true;
      }, 0);
      // need to navigate to last page before trying to insert.
      this.toLastPage().then(() => {
        document.querySelector('#mainEditor').scrollIntoView();
        this.goToLastReply = false;
      }, () => {
        console.log('error');
      });
    }
  }

  toLastPage() {
    return new Promise((resolve, reject) => {
      this.service.getComments(this.recordingId, this.lastPage, 50)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
          console.log('tolastpage', data);
          const comments = data.data as Comment[];
          this.currentCommentPage = data.current_page, scrollTo(0, 0);
          this.page = data.current_page;
          this.comments = comments;
          this.commentsTotal = data.total;
          this.commentsPerPage = data.per_page;
          this.lastPage = Number(this.getLastPageFromURL(data.last_page_url));
          this.loading = false;
          this.updateHeaderText();
          resolve(data);
        }, err => {
          this.onErrorHandle(err);
          this.loading = false;
          reject();
        });
    });
  }


  onCommentDeleteHandle(comment){
    if(!(<any>window).confirm('This will delete the comment permanently. Are you sure you want to proceed?')) {
      return;
    }
    this.service.deleteComment(comment.comment_id).subscribe( response => {
            if(response.success) {

              //this.toast.error('Comment deleted!');
              this.initialCommentsLoad();
              this.cdr.detectChanges();
             // this.toast.success('Comment deleted!');
            } else {
              this.onErrorHandle(response);
            }
          }, error => {
              //this.toast.error('Comment not deleted!');
              this.initialCommentsLoad();
          });
  }

  initialCommentsLoad() {
    this.comments = [];
    this.lastPage = 1;
    this.currentCommentPage = 1;
    this.commentsTotal = 0;
    this.loading = false;
    // setTimeout(() => {
    //   document.querySelector('#my-comments-header').scrollIntoView();
    // });
    this.getComments();
  }

  whenLoggedOut(){
       // this.router.navigate(['/login']);
       // window.location.href = '/#/login?url=' + this.router.url;
       this.router.navigateByUrl("/login?url=" + this.router.url );
  }

}
