import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {UserProfileComponent} from './user-profile.component';
import {UserInfoComponent} from './user-info/user-info.component';
import {UserProfileRecordingsComponent} from './user-profile-recordings/user-profile-recordings.component';
import {UserProfileOpenDuetsComponent} from './user-profile-open-duets/user-profile-open-duets.component';
import {UserProfileFeaturedOnComponent} from './user-profile-featured-on/user-profile-featured-on.component';
import {UserProfileInnerCircleComponent} from './user-profile-inner-circle/user-profile-inner-circle.component';
import {UserProfileBadgesComponent} from './user-profile-badges/user-profile-badges.component';
import {UserProfileGalleryComponent} from './user-profile-gallery/user-profile-gallery.component';
import {UserProfilePlaylistsComponent} from './user-profile-playlists/user-profile-playlists.component';
import {UserProfileFansComponent} from './user-profile-fans/user-profile-fans.component';
import {UserProfileFavoritesComponent} from './user-profile-favorites/user-profile-favorites.component';
import {UserProfileFavoritesResolver} from './user-profile-favorites/user-profile-favorites.resolver';
import {UserMeInfoComponent} from './user-me-info/user-me-info.component';
import { UserProfileResolver } from './user-profile.resolver';

const routes: Routes = [
    {
        path: 'me',
        component: UserProfileComponent,
        children: [
            {path: 'info', component: UserMeInfoComponent},
            {path: 'recordings', component: UserProfileRecordingsComponent},
            {path: 'open-duets', component: UserProfileOpenDuetsComponent},
            {path: 'featured-on', component: UserProfileFeaturedOnComponent},
            {path: 'inner-circle', component: UserProfileInnerCircleComponent},
            {path: 'badges', component: UserProfileBadgesComponent},
            {path: 'gallery', component: UserProfileGalleryComponent},
            {path: 'playlists', component: UserProfilePlaylistsComponent},
            {path: 'fans', component: UserProfileFansComponent},
            {path: 'favorites', component: UserProfileFavoritesComponent},
        ]
    },
    {
        path: ':id',
        component: UserProfileComponent,
        resolve: {
            canResolve: UserProfileResolver
        },
        children: [
            {path: 'info', component: UserInfoComponent, resolve: {
                // canResolve: UserProfileResolver
            }},
            {path: 'recordings', component: UserProfileRecordingsComponent},
            {path: 'open-duets', component: UserProfileOpenDuetsComponent},
            {path: 'featured-on', component: UserProfileFeaturedOnComponent},
            {path: 'inner-circle', component: UserProfileInnerCircleComponent},
            {path: 'badges', component: UserProfileBadgesComponent},
            {path: 'gallery', component: UserProfileGalleryComponent},
            {path: 'playlists', component: UserProfilePlaylistsComponent},
            {path: 'fans', component: UserProfileFansComponent},
            {
                path: 'favorites', component: UserProfileFavoritesComponent,
                resolve: {
                    canResolve: UserProfileFavoritesResolver
                }
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [UserProfileFavoritesResolver, UserProfileResolver]
})
export class UserProfileRoutingModule {
}
