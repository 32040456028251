
import {debounceTime, map} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-multi-search',
    templateUrl: './multi-search.component.html',
    styleUrls: ['./multi-search.component.scss']
})
export class MultiSearchComponent implements OnInit {
    public searchkeyUp = new Subject<any>();
    isCollapsed = true;

    constructor(private route: ActivatedRoute, private router: Router) {
        this.route.url.pipe(
            map(segments => segments.join('')))
            .subscribe( url => {
                // console.log(url);
            });
    }

    ngOnInit() {

        // debounces
        this.searchkeyUp.pipe(
            debounceTime(500))
            .subscribe((event) => {
                console.log(event);
                this.multiSearch(event.target.value);
            })
    }

    displaySearch() {
        this.isCollapsed = !this.isCollapsed;

    }

    multiSearch(value?) {
        console.log(value);
    }
}
