import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  SongService,
  MessageBoardService,
  ProfileService,
  RecordingService,
  UtilService,
  HelperService,
  EventService,
} from '@app/core';

@Component({
  selector: 'app-home-announcements',
  templateUrl: './home-announcements.component.html',
  styleUrls: ['./home-announcements.component.scss']
})
export class HomeAnnouncementsComponent implements OnInit {
  @ViewChild('playlist') private playlistElem;
  // @ViewChild('fsModal') fsModal: FullscreenModalComponent;
  items = [];
 // trendingSongs = [];
 // snappenins: any = [];
 // recommendedSongs$: any;
  itemsPerPage = 30;
  currentPage = 1;

  announcementData = {
    ...this.util.generateGridData(),
    sort: {},
    fetch: this.messageBoardService.getAnnouncements.bind(
      this.messageBoardService
    ),
  };

  constructor(
    private toastService: ToastrService,
    private helperService: HelperService,
    private messageBoardService: MessageBoardService,
    public profileService: ProfileService,
    private eventService: EventService,
    public util: UtilService,
    private cdr: ChangeDetectorRef
  ) {

  }

  async ngOnInit() {

    this.onScrollEndHandle(this.announcementData, null);
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  // onScrollEndHandle(data, sort) {
  //   if (sort) {
  //     data.sort = sort;
  //   }

  //   if (!data.loading && data.lastPage) {
  //     data.loading = true;
  //     data.currentPage++;
  //     this.detectChanges();
  //     data
  //       .fetch(data.currentPage, data.perPage, data.sort.field, data.sort.asc)
  //       .subscribe(
  //         response => {
  //           data.items = data.items.concat(response.data);
  //           data.lastPage = response.next_page_url;
  //           data.loading = false;
  //           this.detectChanges();
  //         },
  //         error => {
  //           this.toastService.error(error.message);
  //           data.loading = false;
  //         }
  //       );
  //   }
  // }


  onScrollEndHandle(data, sort) { 
    if (sort) {
      data.sort = sort;
    }
 
    if (!data.loading) {
      data.loading = true;

      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc)
        .subscribe(
          response => {
            data.currentPage =  this.currentPage;
            data.items = response.data;
            data.lastPage = response.next_page_url;
            data.loading = false;
            this.detectChanges();
            //this.setPageParam();
          },
          error => {
            this.toastService.error(error.message);
            data.loading = false;
          }
        );
    }
  }


  getNextSongs() {
    let data = this.announcementData;
    if (!data.loading && data.lastPage) {
      data.loading = true;
      data.currentPage++;
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage)
        .subscribe(
          response => {
            this.currentPage++;
            data.items = response.data;
            data.lastPage = response.next_page_url;
            data.loading = false;
            window.scrollTo(0, 0);
            this.detectChanges();
            //this.setPageParam();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  getPrevSongs() {
    let data = this.announcementData;

    if (!data.loading && data.currentPage != 1) {
      data.loading = true;
      data.currentPage--;
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage)
        .subscribe(
          response => {
            this.currentPage--
            data.items = response.data;
           // this.setPageParam()
            data.lastPage = response.next_page_url;
            data.loading = false;
            window.scrollTo(0, 0);
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }


/*
  onScrollEndFreeSongsHandle(data, sort, reload = false) {
    if (reload || (!data.loading && data.lastPage)) {
      data.loading = true;
      data.currentPage++;
      this.detectChanges();
      data
        .fetch(data.currentPage, data.perPage, 'gold', data.freeSongsFirst)
        .subscribe(
          response => {
            data.items = data.items.concat(response.data);
            data.lastPage = response.next_page_url;
            data.loading = false;
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
            data.loading = false;
          }
        );
    }
  }
*/
  resetDataObj(data) {
    data.currentPage = 0;
    data.loading = false;
    data.items = [];
    data.lastPage = 1;
    return data;
  }

}
