import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'truncateText'})
export class TruncateTextPipe implements PipeTransform {
    transform(value: string, length: number, symbol: string = ''): string {
        if(typeof value == 'undefined' || value == null) {
            return
        }
        if (value.length > length) {
            value = value.split('').slice(0, length).join('') + symbol;
        }
        return value;
    }
}
