import { Component, OnInit, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-ss-announcement',
  templateUrl: './ss-announcement.component.html',
  styleUrls: ['./ss-announcement.component.scss']
})
export class SsAnnouncementComponent implements OnInit {
  @Input() announcement = null;
  @Input() loading = false;

  constructor(private ngZone: NgZone, private router: Router) {}

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  ngOnInit() {

  }
  loadPage(){
    this.navigate([`/d/message-board/topics/${this.announcement.id}`])
  }

}
