<div class="form-submit-success">
  <div class="icon-success">
    <div class="icon-inner circle-ripple circle-green">
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <div class="icon-holder">
        <app-icon-check color="white" size="30"></app-icon-check>
      </div>
    </div>
  </div>
  <h4>Check your mail</h4>
  <h6>We've sent a reset code tot he email address associated with your account.</h6>
  <a routerLink="/reset-code" class="btn btn-primary">ENTER RESET CODE</a>
</div>