<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icons/Play" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M7.0676382,10.833376 L8.06141261,11.4891988 L8.06140925,11.6561282 L7.28575068,12.1679646 C5.99691004,13.0185847 5.22754821,14.4480943 5.22754821,15.9922028 L5.12754821,16.0922028 L4.03294372,16.0922028 L3.93294372,15.9922028 C3.93294372,14.011927 4.91980317,12.1782502 6.5726154,11.0874941 L6.9574573,10.8333895 L7.0676382,10.833376 Z M14.0670563,4.33684981 L14.0670563,4.52993776 C14.0670563,6.51023327 13.0801628,8.34394778 11.4273578,9.43477084 L11.0424091,9.68876866 L10.9322571,9.68876589 L9.93858951,9.03304994 L9.93858275,8.86612444 L10.714254,8.35417285 C12.0030911,7.50365959 12.7724518,6.07415204 12.7724518,4.52993776 L12.7724518,4.33684981 L11.6842818,5.42501976 L11.5428605,5.42501976 L10.7687974,4.65095664 L10.7687974,4.50953529 L13.3490434,1.92928932 L13.4904647,1.92928932 L16.0707107,4.50953529 L16.0707107,4.65095664 L15.2966476,5.42501976 L15.1552262,5.42501976 L14.0670563,4.33684981 Z M3.93294372,4.33684981 L2.8447738,5.42501976 L2.70335244,5.42501976 L1.92928932,4.65095664 L1.92928932,4.50953529 L4.50953529,1.92928932 L4.65095664,1.92928932 L7.23120261,4.50953529 L7.23120261,4.65095664 L6.45713952,5.42501976 L6.31571816,5.42501976 L5.22754821,4.33684981 L5.22754821,4.52993776 C5.22754821,6.07415204 5.99690894,7.50365959 7.28574759,8.35417392 L11.4273644,11.087374 C13.0801769,12.1781303 14.0670563,14.0119032 14.0670563,15.9922028 L13.9670563,16.0922028 L12.8724518,16.0922028 L12.7724518,15.9922028 C12.7724518,14.4480943 12.00309,13.0185847 10.7142524,12.1679666 L6.57263537,9.43476636 C4.91979083,8.34398893 3.93294372,6.51030555 3.93294372,4.52993776 L3.93294372,4.33684981 Z"
        id="Shape" [attr.stroke]="color" stroke-width="0.2" [attr.fill]="color" fill-rule="nonzero"
        transform="translate(9.000000, 8.996101) rotate(90.000000) translate(-9.000000, -8.996101) "></path>
    </g>
  </svg>
</div>