<div class="ssc-wrapper">
  <div class="ssc-list-content">
    <div class="ssc-search-holder">
      <app-search-bar-input (onSearch)="onSearch($event)"></app-search-bar-input>
    </div>
    <app-ss-featured-grid
      title="Playlist"
      cols="1"
      [list]="data.items"
      [hasSeeMore]="true"
      seeMorePage="MyListenMore:profile-pl"
      [userIdFromProfile]="userId"
      [sortOptions]="playlistSortOptions"
      [loading]="data.loading"
      (onScrollEnd)="onScrollEndHandle(data, $event)"
      (onOrderBy)="resetDataObj(data);onScrollEndHandle(data, $event)"
    >
      <ng-template #itemContent let-item>
        <!-- <div class="ssc-list-item"  class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }"></div> -->
        <div class="ssc-list-item"  [ngClass]="{ 'is-mine': checkMine(item) }">
          <app-ss-playlist style="display:flex; width: 42%;"[playlist]="item" noTime="true" showDescription="true"></app-ss-playlist>
          <div class="song-date">
            <app-time-stamp [time]="item.created_at" [needWatch]="false" [format]="'MMM d, yyyy hh:mma'">
            </app-time-stamp>
          </div>
          <div class="song-actions">
            <app-icon-button size="40px" color="#58c7c9" (click)="onPlay(item.id)">
              <app-icon-play color="white" size="18"></app-icon-play>
            </app-icon-button>
            <app-more-dot-menu title="Options">
              <div class="dropdown-menu-content">
                <div (click)="onFavorite(item)" *ngIf="!item.favorited">Add to Favorites</div>
                <div (click)="onUnfavorite(item)" *ngIf="item.favorited">Remove from Favorites</div>
                <div (click)="onEdit(item)" *ngIf="isOwn">Edit</div>
                <div (click)="onDelete(item)" *ngIf="isOwn">Delete</div>
              </div>
            </app-more-dot-menu>
          </div>
        </div>
      </ng-template>
    </app-ss-featured-grid>
    <app-confirmation-modal #confirmation></app-confirmation-modal>
  </div>
  <div class="ssc-sidebar">
  </div>
</div>
