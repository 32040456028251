import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-explicit-tag',
  templateUrl: './explicit-tag.component.html',
  styleUrls: ['./explicit-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExplicitTagComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
