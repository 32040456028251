import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageBoardListItem } from '../../message-board/message-board.model';
import { MessageBoardService, EventService, UtilService } from '@app/core';
import { InnerCircleService } from '../../core/services/inner-circle.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-message-board-list',
  templateUrl: './message-board-list.component.html',
  styleUrls: ['./message-board-list.component.scss'],
})
export class MessageBoardListComponent implements OnInit {
  @Input() list: MessageBoardListItem[];
  @Input() type: string;
  @Input() loading: boolean = false;
  @Input() showOrder: boolean = true;
  @Input() skeletonType = "";
  @Output() onOrder: EventEmitter<any> = new EventEmitter();
  @Output() onDoneAction: EventEmitter<any> = new EventEmitter();

  orderByName = '';
  routeBase = '';
  routeUrl;
  prefixUrl = '';
  showInFeedAds = true;

  constructor(
    private messageBoardService: MessageBoardService,
    private innerCircleService: InnerCircleService,
    private eventService: EventService,
    private toastr: ToastrService,
    private router: Router,
    private util: UtilService
  ) {}

  ngOnInit() {
    this.routeUrl = this.router.url;
    this.prefixUrl = '/d/';
    console.log('type', this.type);
    if (this.type === 'category') {
      this.routeBase = this.prefixUrl + 'message-board/categories/';
    } else if (this.type === 'subcategory') {
      this.routeBase = this.prefixUrl + 'message-board/subcategories/';
    } else if (this.type === 'topic' || this.type === 'favorite' || this.type === 'announcements') {
      this.routeBase = this.prefixUrl + 'message-board/topics/';
    } else if (this.type === 'contest') {
      this.routeBase = this.prefixUrl + 'message-board/contests/';
    }

    let loggedIn = this.util.getloggedUserData();
    this.showInFeedAds = !loggedIn.gold;

  }

   getRouteBaseLastReply(item) {
    if (item.sub_categories_count === 0) {
      return this.prefixUrl + 'message-board/topics/';
    }
    return this.routeBase;
  }

  get isCategory() {
    return this.type !== 'topic' && this.type !== 'favorite' && this.type !== 'recent' && this.type !== 'contest' && this.type !=='announcements';
  }

  get isNotSortable(){
    // return this.type === 'recent' || this.type ==='topic' || this.type ==='contest';
    return this.type === 'recent' || this.type ==='contest' ||  this.type ==='announcements';
  }

  getRouteBase(item) {
    if (item.sub_categories_count === 0) {
      return this.prefixUrl + 'message-board/subcategories/';
    }
    return this.routeBase;
  }

  routeToLastReply(item){
    this.router.navigate([`/d/message-board/topics/${item.id}`], { queryParams: { lastreply: '1' } } );
  }
  
  toggleFavorite(item) {
    if (item.favorited) {
      this.messageBoardService.unfavoriteTopic(item.id).subscribe(response => {
        if (response.success) {
          item.favorited = false;
          this.onDoneAction.emit({ success: true, type: 'favorite' });
        }
      });
    } else {
      this.messageBoardService.favoriteTopic(item.id).subscribe(response => {
        if (response.success) {
          item.favorited = true;
          this.onDoneAction.emit({ success: true, type: 'favorite' });
        }
      });
    }
  }

  onOrderClick(type) {
    if (type === this.orderByName) {
      if (type.indexOf('-') > 0) {
        this.orderByName = type.replace('-', '');
      } else {
        this.orderByName = `-${type}`;
      }
    } else {
      this.orderByName = type;
    }
     this.onOrder.emit(this.orderByName);
  }

  leaveIC(ic) {
    this.innerCircleService.leaveGroup(ic.id).subscribe(
      response => {
        this.toastr.success(response.message, 'Inner Circle');
        this.router.navigate(['/d/message-board/innercircles'], {
    queryParamsHandling: 'preserve'
});
        this.onDoneAction.emit({ success: true });
      },
      error => {
        console.log(error);
        this.toastr.error(error.message, 'Inner Circle');
        this.onDoneAction.emit({ success: false });
      }
    );
  }

  deleteIC(ic) {
    this.innerCircleService.deleteGroup(ic.id).subscribe(
      response => {
        this.toastr.success(response.message, 'Inner Circle');
        this.router.navigate(['/d/message-board/innercircles'], {
    queryParamsHandling: 'preserve'
});
        this.onDoneAction.emit({ success: true });
      },
      error => {
        console.log(error);
        this.toastr.error(error.message, 'Inner Circle');
        this.onDoneAction.emit({ success: false });
      }
    );
  }

  shareThread(thread) {
    this.eventService.onSocialShare({ thread, type: this.type });
  }

  menuClicked(event) {
    event.preventDefault();
    event.stopPropagation();
  }


   timeAgoInWords(date){
    let  dateNow: any;
    dateNow = new Date();
    let  datePast: any;
    datePast = new Date(date);
    const localTime = dateNow.getTime();
    const pastTime = datePast;
      let seconds = Math.floor((localTime - pastTime) / 1000);
      let intervalType;
      let interval = Math.floor(seconds / 31536000);
      if (interval >= 1) {
        intervalType = 'year';
      } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
          intervalType = 'month';
        } else {
          interval = Math.floor(seconds / 86400);
          if (interval >= 1) {
            intervalType = 'day';
          } else {
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
              intervalType = "hour";
            } else {
              interval = Math.floor(seconds / 60);
              if (interval >= 1) {
                intervalType = "minute";
              } else {
                interval = seconds;
                intervalType = "second";
              }
            }
          }
        }
      }
      if (interval > 1 || interval === 0) {
        intervalType += 's';
      }
      return interval + ' ' + intervalType + ' ago';
    };
   }


