<div class='badBrowser' #badBrowser *ngIf='badBrowser'>
  <button (click)='hideBadBrowser()' class='close'><svg xmlns="http://www.w3.org/2000/svg"  width="15.748" height="15.748" viewBox="0 0 15.748 15.748">
    <g id="Symbol_85_1" data-name="Symbol 85 – 1" transform="translate(-1064 -310)">
      <path id="Union_3" data-name="Union 3" d="M7.874,9.305,1.431,15.748,0,14.315,6.442,7.874,0,1.431,1.431,0,7.874,6.442,14.315,0l1.432,1.431L9.305,7.874l6.442,6.442-1.432,1.432Z" transform="translate(1064 310)" fill="#21243c"/>
    </g>
  </svg></button>
  <div class='textContainer'>
    <h2>Uh oh!</h2>
    <p>It looks like you're using an unsupported browser. For best results please use Firefox or Chrome.</p>
  </div>
</div>
<!-- <div class='badBrowser' #firefox *ngIf='firefox' style="height: auto;">
  <button (click)='hideFirefox()' class='close' style="padding-top: 8px;"><svg xmlns="http://www.w3.org/2000/svg"  width="15.748" height="15.748" viewBox="0 0 15.748 15.748">
    <g id="Symbol_85_1" data-name="Symbol 85 – 1" transform="translate(-1064 -310)">
      <path id="Union_3" data-name="Union 3" d="M7.874,9.305,1.431,15.748,0,14.315,6.442,7.874,0,1.431,1.431,0,7.874,6.442,14.315,0l1.432,1.431L9.305,7.874l6.442,6.442-1.432,1.432Z" transform="translate(1064 310)" fill="#21243c"/>
    </g>
  </svg></button>
  <div class='textContainer firefoxBanner'>
    <p>For optimal recording results, please use Edge, Chrome, Brave, or any other chromium-based browser to access our new state-of-the-art recorder.</p>
  </div>
</div> -->
<div id='banners'>
<div *ngFor='let banner of message; let j = index' >
  <div id='{{banner.id}}' class='{{banner.className}} banner' [ngStyle]="{'color': message[j]['text-color'] }" style='background-image: url({{banner.background}}); background-size: contain; background-repeat: repeat;'>
  <button *ngIf='!banner.className.search("sheer-terror") && !banner.className.search("info") && !banner.className.search("march-promo")' (click)='hideBannerDynamic(banner.id)' class='close'><svg xmlns="http://www.w3.org/2000/svg"  width="15.748" height="15.748" viewBox="0 0 15.748 15.748">
      <g id="Symbol_85_1" data-name="Symbol 85 – 1" transform="translate(-1064 -310)">
        <path id="Union_3" data-name="Union 3" d="M7.874,9.305,1.431,15.748,0,14.315,6.442,7.874,0,1.431,1.431,0,7.874,6.442,14.315,0l1.432,1.431L9.305,7.874l6.442,6.442-1.432,1.432Z" transform="translate(1064 310)" fill="#21243c"/>
      </g>
    </svg></button>

    <button *ngIf='banner.type == "announcement" || banner.type == "info"' (click)='hideBannerDynamic(banner.id)'  style='padding: 2px 9px;' class='close'><svg xmlns="http://www.w3.org/2000/svg"  width="15.748" height="15.748" viewBox="0 0 15.748 15.748">
      <g id="Symbol_85_1" data-name="Symbol 85 – 1" transform="translate(-1064 -310)">
        <path id="Union_3" data-name="Union 3" d="M7.874,9.305,1.431,15.748,0,14.315,6.442,7.874,0,1.431,1.431,0,7.874,6.442,14.315,0l1.432,1.431L9.305,7.874l6.442,6.442-1.432,1.432Z" transform="translate(1064 310)" fill="#21243c"/>
      </g>
    </svg></button>
  <a *ngIf='banner.title != ""' href='{{banner.link}}'>
    <div class='textContainer'>
      <h2  >{{banner.title}}</h2>
      <p  *ngIf='banner.subtitle != "" && banner.type=="announcement" '>{{banner.subtitle}}</p>
    </div>
</a>
<a *ngIf='banner.title == ""' href='{{banner.link}}'>
  <div class='textImage march-promo' style='background-image: url({{banner.textImage}});'>
  </div>
</a>
</div>
</div>
</div>

