<div id='seeMoreContainer'>
    <div class='headerContainer'>
        <div class='title'>
            <app-icon-button color="white" (click)="back()" >
            <app-icon-arrow-left color="gray"></app-icon-arrow-left>
            </app-icon-button>
            <h4>{{pageTitle}}</h4>
        </div>
        <div class='moreFilters'>
            <span>Duets Only:
                <input type="checkbox"  #filterByDuetsCheckBox [checked]="filterByDuets" (change)="duetsClick(filterByDuetsCheckBox.checked)"  >
            </span>
            <span style="margin-left: 40px;">
                <app-search-bar-input [query]="term" (onSearch)="onSearchHandle($event)"></app-search-bar-input>
            </span>
        </div>
    </div>
  
    <ng-container *ngIf="!filterByDuets">
        <app-ss-featured-grid 
            [scrollable]="false"
            title="{{headerTitle}}"
            [cols]="3"
            [colsLg]="3"
            [colsMd]="2"
            [colsSm]="1"
            [extendedSize] = "true"
            [hasSeeMore] = "false"
            [sortOptions]="playlistSortOptions"
            [list]="songData.items"
            [loading]="songData.loading"
            (onOrderBy)="resetDataObj(songData);onScrollEndHandle(songData, $event)"
        >
        <ng-template #itemContent let-item>
            <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
            <a (click)="handleSing(item)">
                <app-ss-song [song]="item"></app-ss-song>
            </a>
            <div class="song-actions">
                <app-icon-button (click)="handleSing(item)" size="40px" color="#58c7c9">
                    <app-icon-mic color="white" size="16"></app-icon-mic>
                </app-icon-button>
                <app-more-dot-menu title="Options">
                <div class="dropdown-menu-content">
                    <div *ngIf="!item?.favorited" (click)="favSong(item)">Add song to Favorites</div>
                    <div *ngIf="item?.favorited" (click)="unFavSong(item)">Remove song from Favorites</div>
                    <div (click)="howOthersSingThis(item.id)">How Others Sing This</div>
                </div>
                </app-more-dot-menu>
            </div>
            </div>
        </ng-template>
        </app-ss-featured-grid>
        <div  id="pageNavContainer"  style="margin-bottom: 13px;">
            <button *ngIf='songData.currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
            <p >{{currentPage}}</p>
            <button *ngIf='songData.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
        </div>
    </ng-container>

    <ng-container *ngIf="filterByDuets">
        <app-ss-featured-grid 
        [scrollable]="false"
        title="{{headerTitle}}"
        [cols]="3"
        [colsLg]="3"
        [colsMd]="2"
        [colsSm]="1"
        [extendedSize] = "true"
        [hasSeeMore] = "false"
        [sortOptions]="playlistSortOptions"
        [list]="duetData.items"
        [loading]="duetData.loading"
        (onOrderBy)="resetDataObj(duetData);onScrollEndHandle(duetData, $event)"
        >
        <ng-template #itemContent let-item>
        <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
            <a (click)="handleSing(item)">
            <app-ss-song [song]="item"></app-ss-song>
            </a>
            <div class="song-actions">
                <app-icon-button (click)="handleSing(item)" size="40px" color="#58c7c9">
                  <app-icon-mic color="white" size="16"></app-icon-mic>
                </app-icon-button>
            <app-more-dot-menu title="Options">
                <div class="dropdown-menu-content">
                <div *ngIf="!item?.favorited" (click)="favSong(item)">Add song to Favorites</div>
                <div *ngIf="item?.favorited" (click)="unFavSong(item)">Remove song from Favorites</div>
                <div (click)="howOthersSingThis(item.id)">How Others Sing This</div>
                </div>
            </app-more-dot-menu>
            </div>
        </div>
        </ng-template>
        </app-ss-featured-grid>
        <div  id="pageNavContainer"  style="margin-bottom: 13px;">
        <button *ngIf='duetData.currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
        <p >{{currentPage}}</p>
        <button *ngIf='duetData.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
        </div>
    </ng-container>
</div>