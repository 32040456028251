<section class="container container-ss notifications">
  <div class="row">
    <div class="col-9">
      <div class="ssc-list-header">
        <h1 class="notification-title">Notifications 
          <!-- <span>+10</span> -->
        </h1>
        <div class="ssc-search-holder">
          <app-search-bar-input (onSearch)="onSearch($event)"></app-search-bar-input>
        </div>
      </div>
      <div class="notifications-container">
        <h3 class="notifications-header">
         <span (click)="readAll()">Mark all as read</span>
         <span (click)="clearAll()">Clear all notifications</span>
        </h3>
        <app-ss-featured-grid 
          [showHeader]="false"
          cols="1"
          [hasSeeMore]="false"
          [list]="data.items"
          [loading]="data.loading"
          (onScrollEnd)="onScrollEndHandle(data)"
          (onOrderBy)="resetData();onScrollEndHandle(data)"
        >
          <div class="notification-list">
            <ng-template #itemContent let-item>
              <div
                class="notification-item"
                [ngClass]="{'notification-item--unread': !item.read_at}"
                (click)="onClick(item)"
                >
                <div class="notification-icon">
                  <img [src]="item.data.icon" *ngIf="item.data.icon">
                </div>
                <a class="notification-body" (click)="onClick(item)" routerLink="{{getRoute(item)}}">
                  {{item.data.message}}
                </a>
                <span class="notification-time">{{formatTime(item.created_at)}}</span>
              </div>
            </ng-template>
          </div>
        </app-ss-featured-grid>
      </div>
    </div>
  </div>
</section>