import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from '@app/core';

@Component({
  selector: 'app-ss-user',
  templateUrl: './ss-user.component.html',
  styleUrls: ['./ss-user.component.scss'],
})
export class SsUserComponent implements OnInit {
  @Input() user: any = {};
  @Input() showLevel = false;
  @Input() showStatus = true;
  @Input() isSmall = false;
  @Input() timestamp: string;
  @Input() showName = false;
  @Input() noLink = false;
  @Input() disabled = false;
  @Input() showPhoto = true;

  constructor(private util: UtilService) {}

  ngOnInit() {
    this.user.photo = this.util.addWidthIfImgIx(this.user.photo, 100);
  }

  getSnapicons() {
    if (!this.user) {
      return [];
    }
    const { snapicons = {} } = this.user;
    return Object.keys(snapicons);
  }
}
