import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingsComponent } from './settings.component';
import { SettingsProfileComponent } from './settings-profile/settings-profile.component';
import { SettingsGeneralComponent } from './settings-general/settings-general.component';
import { SettingsSubscriptionHistoryComponent } from './settings-subscription-history/settings-subscription-history.component';
import { SettingsLyricComponent } from './settings-lyric/settings-lyric.component';
import { SettingsUnblockComponent } from './settings-unblock/settings-unblock.component';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    children: [
      { path: 'general', component: SettingsGeneralComponent },
      { path: 'profile', component: SettingsProfileComponent },
      { path: 'lyrics', component: SettingsLyricComponent },
      {
        path: 'payment/history/:id',
        component: SettingsSubscriptionHistoryComponent,
      },
      {
        path: 'subscription',
        loadChildren:
          () => import('./settings-subscription/subscription.module').then(m => m.SubscriptionModule),
      },
      {
        path: 'blocked',
        component: SettingsUnblockComponent,
      },
      { path: '', pathMatch: 'full', redirectTo: 'general' },
      { path: '**', pathMatch: 'full', redirectTo: 'general' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
