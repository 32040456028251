import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() duration: any = 10;
  @Input() initial: any = 0;
  @Input() current: any = 0;
  @Input() isStep = false;
  @Input() recorder = false;
  @Input() noSeek = false;
  @Output() seek = new EventEmitter<number>();
  @Output() range = new EventEmitter<number>();


  constructor() { }

  ngOnInit() {
  }

  getProgress() {
    return this.current / this.duration * 100;
  }

  onSeek(e) {
    if (this.noSeek) {
      return;
    }
    this.seek.emit(e.target.value);
    this.current = e.target.value;
  }
  onChange(e) {
    if (this.noSeek) {
      return;
    }
    this.current = e.target.value;
    this.range.emit(e.target.value);

  }
}
