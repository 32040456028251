import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-loading',
  templateUrl: './icon-loading.component.html',
  styleUrls: ['./icon-loading.component.scss']
})
export class IconLoadingComponent implements OnInit {
  @Input() isSmall: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
