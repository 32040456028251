import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlaylistService } from '@app/core/services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-playlist',
  templateUrl: './new-playlist.component.html',
  styleUrls: ['./new-playlist.component.scss']
})
export class NewPlaylistComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  @Output() created: EventEmitter<any> = new EventEmitter();
  title = '';
  description = '';
  isPrivate = false;

  constructor(
    private modalService: NgbModal,
    private playlistService: PlaylistService,
    private toastr: ToastrService
  ) { }

  open(recordingId) {
    this.modalService.open(this.contentElem, {
      windowClass: 'ssc-modal',
      backdrop: 'static',
    }).result.then((result) => {

    }, async (reason) => {
      try {
        const result = await this.playlistService.createNewPlaylist(this.title, this.description, this.isPrivate).toPromise();
        if (recordingId) {
          await this.playlistService.addRecordingToParticularPlaylist(result.model.id, recordingId).toPromise();
          this.toastr.success('Added recording to the new playlist.');
        }

        if (this.created) {
          this.created.emit();
        }
      } catch (err) {
        console.log(err);
      }
      this.title = '';
      this.description = '';
      this.isPrivate = false;
    });
  }

  close() {

  }

  ngOnInit() {
  }

}
