<div class="notice payment-success">
  <div class="notice-header">
    <div class="icon-success">
      <div class="icon-inner circle-ripple circle-green">
        <span class="ripple-effect"></span>
        <span class="ripple-effect"></span>
        <span class="ripple-effect"></span>
        <div class="icon-holder">
          <app-icon-check color="white" size="36"></app-icon-check>
        </div>
      </div>
    </div>
  </div>

  <div class="notice-body">
    <h4>{{title}}</h4>
  </div>

  <div class="notice-footer" [ngClass]="{'notice-footer-double': btnText2}">
    <button class="btn btn-success" (click)="handleClick($event)">
      {{btnText}}
    </button>
    <button class="btn btn-success" (click)="handleClick2($event)" *ngIf="btnText2">
      {{btnText2}}
    </button>
  </div>
</div>
