import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-megaphone',
  templateUrl: './icon-megaphone.component.html',
  styleUrls: ['./icon-megaphone.component.scss']
})
export class IconMegaphoneComponent implements OnInit {
  @Input() color = 'rgb(208, 208, 208)';
  @Input() size = 18;

  constructor() { }

  ngOnInit() {
  }

}
