import { ChangeDetectorRef, Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import {
  GenresService,
  UtilService,
  EventService,
  RecordingService,
  SongService,
  ProfileService,
  HelperService,
  Data
} from '@app/core';
import { ArtistService } from '@app/core/services/artist.service';

declare var $: any;

@Component({
  selector: 'app-my-sing-more',
  templateUrl: './my-sing-more.component.html',
  styleUrls: ['./my-sing-more.component.scss']
})
export class MySingMoreComponent implements OnInit {
  recording;
  titleLength = 24;
  duets = [];
  artists = [];
  currentPage = 1;
  //letterFIlter = 'A'
  trending$;
  selectedLetter = '';
  lastSelectedLetter;
  recordingSortOptions = [];
  songSortOptions = [];
  songSortOptionsFavoritesFirst = [];
  totalPages = 0;
  artistSortOptions = [];
  genreSortOptions = this.util.recordingSortOptions;
  @ViewChild('goldcontent') private goldModal;
  itemsPerPage = 30;
  filterByDuet = false;
  selectedGenreIndex = 0;
  selectedDecadeIndex = 0;
  filterByDemo=false;
  decadesArray = [];
  letters = [];
  genresArray = [{name: 'All', id: 90000}];
  user: any;
  genresData: any = {
    ...this.util.generateGridData(),
    sort: this.genreSortOptions[0],
    fetch: this.genreService.getGenres.bind(this.genreService),
  };

  pageTitle = "";
  getDataAPI: any;
  profileFavsType = "";
  userIDfromProfieFavs = 0;
  userNamefromProfieFavs = "";
  showSeeMorePageType = "";
  playlistSortOptions = this.util.playlistSortOptions;
  profileUserName="";
  pageHeader="";
  
  favoriteData: any = {
    ...this.util.generateGridData(),
    fetch: this.profileService.getFavoriteSongs.bind(this.profileService),
  };

  profileFavSong: any = {
    ...this.util.generateGridData(),
    fetch: this.profileService.getUserFavorites.bind(this.profileService),
  };
  
  data = {
    song: {
      ...this.util.generateGridData(),
      sort: this.playlistSortOptions[0],
     // sort: this.recordingSortOptions[0],
    },
  };

  constructor(
    private recordingService: RecordingService,
    private songService: SongService,
    private genreService: GenresService,
    private router: Router,
    private artistService: ArtistService,
    private toastService: ToastrService,
    public profileService: ProfileService,
    private helperService: HelperService,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    private util: UtilService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private location: Location,
    private dataService: Data
  ) {
    this.recordingSortOptions = this.util.recordingSortOptions;
    this.songSortOptions = this.util.songSortOptions;
    this.artistSortOptions = this.util.artistSortOptions;
    this.favoriteData.sort = this.util.songSortOptions[0];
    this.playlistSortOptions = this.util.playlistSortOptions;
  }

  ngOnInit() { 
   // this.user = this.util.getloggedUserData();

   this.profileUserName = this.dataService.profileUserName;
    this.route.queryParams.subscribe(params => {
      if(typeof params.u != 'undefined') {
        this.userIDfromProfieFavs = params.u;
      }
    });

    this.decadesArray = this.util.getDecadeArray();
    this.letters = this.util.getLettersArray();

    this.route.queryParams.subscribe(params => {
      if(typeof params.page != 'undefined') {
        this.currentPage = params.page;
      }
    });

    this.route.queryParams.subscribe(params => {
      if(typeof params.letter != 'undefined') {
        this.selectedLetter = '';
        this.handleFilter( params.letter);
      }
    });

    this.route.queryParams.subscribe(params => {
      if(typeof params.decade != 'undefined') {
        this.favoriteData.decade = params.decade;
        this.selectedDecadeIndex = this.decadesArray.findIndex(element => element.includes(params.decade))
      }
    });

    this.route.queryParams.subscribe(params => {
      if(typeof params.genre != 'undefined') {
       this.selectedGenreIndex = params.genre;
       this.genreClick(params.genre);
      }
    });   

    this.genresData.fetch().subscribe((response)=>{
      response.data.forEach(item => {
        this.genresArray.push(item)
      })
    })

    this.route.queryParams.subscribe(params => {
      if(typeof params.duet != 'undefined') {
       this.filterByDuet = params.duet;
       this.duetClick(true);
      }
    });   


    this.route.queryParams.subscribe(params => {
      if(typeof params.p != 'undefined') {
        switch (params.p)
        {
          case 'fav-songs':
            this.pageTitle = "My Favorites - Favorite Songs";
            this.getDataAPI = this.favoriteData;
            this.profileFavsType = "song";
          break;
          case 'profile-fav-songs':
            this.pageTitle = "Profile - Favorite Songs";
            this.pageHeader = "(" + this.profileUserName +  ") - Favorite Songs";
            this.getDataAPI = this.profileFavSong;
            this.profileFavsType = "song";
          break;
        }
        this.showSeeMorePageType = params.p;
      }
    });

    if ( this.showSeeMorePageType == 'profile-fav-songs' ){
      this.profileFavSong.sort = this.util.playlistSortOptions[0];
       this.onScrollEndHandleProfileFavs(this.profileFavSong, null);
       
    } else {
      this.onScrollEndHandle();
    }

    //this.onScrollEndHandle();
  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  back(){
    window.history.back();
  }

  setPageParam() {  
    this.location.replaceState(this.location.path().split('?')[0], '');
    this.location.replaceState(this.location.path() + `?page=${this.currentPage}`);
    this.location.replaceState(this.location.path() + `&p=${this.showSeeMorePageType}`);
    this.location.replaceState(this.location.path() + `&u=${this.userIDfromProfieFavs}`);
    if(this.selectedLetter.length >0){
      this.location.replaceState(this.location.path() + `&letter=${this.selectedLetter}`);
     }
     if(this.favoriteData.decade && this.favoriteData.decade.length>0){
       this.location.replaceState(this.location.path() + `&decade=${this.favoriteData.decade}`);
     }
     if(this.favoriteData.filter.genre_id && this.favoriteData.filter.genre_id>0  ){
      this.location.replaceState(this.location.path() + `&genre=${this.favoriteData.filter.genre_id}`);
    }

    if(this.favoriteData.filter.duet && this.favoriteData.filter.duet === true){
      this.location.replaceState(this.location.path() + `&duet=true`);
    }

  }

  decadeClick(event) {
    this.favoriteData.decade = this.decadesArray[event].substring(0, this.decadesArray[event].length - 2)
    if(event == 0) {
      this.favoriteData.decade = '';
    }
    this.resetDataObj(this.favoriteData);
    this.onScrollEndHandle();
  }

  genreClick(event) {
    if(this.genresArray[event].name == 'All' && typeof this.favoriteData.filter.genre_id != 'undefined') {
      delete this.favoriteData.filter.genre_id;
    } else {
      this.favoriteData.filter.genre_id = this.genresArray[event].id;
      //this.selectedGenreIndex = this.favoriteData.filter.genre_id;
    }
    this.resetDataObj(this.favoriteData);
    this.onScrollEndHandle();
  }

  handleFilter(filter) {
    if(typeof this.favoriteData.filter == 'undefined') {
      this.favoriteData.filter = {};
    }
    if(this.favoriteData.filter.title == filter) {
      delete this.favoriteData.filter.title
      this.selectedLetter = '';
    } else {
      this.selectedLetter = filter;
      this.favoriteData.filter.title = filter
    }
    this.resetDataObj(this.favoriteData);
    this.onScrollEndHandle();
  }

  checkMine(item){
    return item.favorited;
  }

  favRecording(recording) {
    this.recordingService.fav(recording.id).subscribe(response => {
      if (response.success) {
        recording = response.model;
        this.toastService.success('Recording was added to favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  unFavRecording(recording) {
    this.recordingService.unfav(recording.id).subscribe(response => {
      if (response.success) {
        recording = response.model;
        this.toastService.success('Recording was removed from favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  handleSing(song) {
    if (song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    //this.router.navigate(['/recorder', song.id]);
    this.navigate([`/recorder/${song.id}`]);
  }

  handleDuet(duet) {
    if (duet.song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    //this.router.navigate(['/duet-recorder', duet.id]);
    this.navigate([`/duet-recorder/${duet.id}`]);
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  setSortFields(sort){
    if (sort) {
      this.favoriteData.sort = sort;
    }
  }

  onScrollEndHandle() {
    let data = this.favoriteData;

    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }
   
    if (!data.loading) {
      data.loading = true;
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade, data.demo)
        .subscribe(
          response => {
            data.items = response.data;
            data.lastPage = response.next_page_url;
            data.loading = false;
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }
  getNextSongs() {
    let data = this.favoriteData;
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }

    if (!data.loading && data.lastPage) {
      data.loading = true;
      data.currentPage++;
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade, data.demo)
        .subscribe(
          response => {
            this.currentPage++;
            data.items = response.data;
            data.lastPage = response.next_page_url;
            data.loading = false;
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }
  getPrevSongs() {
    let data = this.favoriteData;
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }

    if (!data.loading && data.currentPage != 1) {
      data.loading = true;
      data.currentPage--;
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade, data.demo)
        .subscribe(
          response => {
            this.currentPage--;
            data.items = response.data;
            data.lastPage = response.next_page_url;
            data.loading = false;
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }
  handleFreeSongsChange(data, gold) {
    data.freeSongsFirst = gold;
    this.resetDataObj(data);
   // this.onScrollEndHandle(data, { field: 'gold', asc: gold }, true);
  }

  onScrollEndHandleProfileFavs(data, sort = null) { 
    if (!data.fetch) {
      return; 
    } 
    if (sort) {
      data.sort = sort;
    }       
    if (!data.loading && data.lastPage) { 
      data.loading = true; 
      this.detectChanges();
      data.fetch(this.userIDfromProfieFavs, this.profileFavsType, this.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc).subscribe(
        response => {
          data.items = data.items.concat(response.data);
          data.lastPage = response.next_page_url;
          data.loading = false;
          this.setPageParam();
          this.detectChanges();
        },
        error => {
          this.toastService.error(error.message);
        }
      );
    }
  }

  getNextSongsProfileFavs() { 
    let dataMyFav = this.getDataAPI;
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.filter = {};
    }
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.decade = '';
    }

    if (!dataMyFav.loading && dataMyFav.lastPage) {
      dataMyFav.loading = true;
      this.currentPage++; 
      this.detectChanges();
      dataMyFav.fetch(this.userIDfromProfieFavs, this.profileFavsType, this.currentPage, this.itemsPerPage, dataMyFav.sort.field, dataMyFav.sort.asc).subscribe(
          response => {
            dataMyFav.currentPage = this.currentPage;
            dataMyFav.items = response.data;
            dataMyFav.lastPage = response.next_page_url;
            dataMyFav.loading = false;
            window.scrollTo(0, 0);
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  getPrevSongsProfileFavs() {
    let dataMyFav = this.getDataAPI;
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.filter = {};
    }
    if(typeof dataMyFav.filter == 'undefined') {
      dataMyFav.decade = '';
    }
    if (!dataMyFav.loading && dataMyFav.currentPage != 1) {
      dataMyFav.loading = true;
      this.currentPage--;
      this.detectChanges();
      dataMyFav.fetch(this.userIDfromProfieFavs, this.profileFavsType, this.currentPage, this.itemsPerPage, dataMyFav.sort.field, dataMyFav.sort.asc).subscribe(
          response => {
            dataMyFav.currentPage = this.currentPage;
            dataMyFav.items = response.data;
            dataMyFav.lastPage = response.next_page_url;
            dataMyFav.loading = false;
            window.scrollTo(0, 0);
            this.setPageParam();
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }



  resetDataObj(data) {
    this.currentPage = 1;
    data.currentPage = 1;
    data.loading = false;
    data.items = [];
    data.lastPage = 1;
  }

  favoriteSong(song) {
    this.songService.fav(song.id).subscribe(() => {
      song.favorited = true;
      this.toastService.success('Added song to favorites');
    });
  }

  unfavoriteSong(song) {
    this.songService.unfav(song.id).subscribe(() => {
      song.favorited = false;
      this.toastService.success('Removed song from favorites');
    });
  }

  openLink(url) {
    window.open(window.location.origin + '/#' + url);
  }

  onArtist(artist) {
    //this.router.navigate(['/d/artists', artist.id]);
    this.navigate([`/d/artists/${artist.id}`]);
  }

  onFavArtist(artist) {
    this.artistService.favorite(artist.id).subscribe(result => {
      artist.favorited = true;
      this.toastService.success('Added song to favorites');
    });
  }

  onUnfavArtist(artist) {
    this.artistService.unfavorite(artist.id).subscribe(result => {
      artist.favorited = false;
      this.toastService.success('Removed artist from favorites');
    });
  }

  shareRecording(recording) {
    this.eventService.onSocialShare({ recording });
  }

  duetClick(value){
    this.filterByDuet=value;
    this.favoriteData.filter.duet = this.filterByDuet;
    this.resetDataObj(this.favoriteData);
    this.onScrollEndHandle();

  }

  demoClick(value){
    this.resetDataObj(this.favoriteData);
    this.filterByDemo=value;
    this.favoriteData.demo = this.filterByDemo;
    this.onScrollEndHandle();
  }

  howOthersSingThis(id){
    this.navigate([`/d/ListenOthersSing/${id}`]);
  }
}
 