import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-privacy-memberonly',
  templateUrl: './icon-privacy-memberonly.component.html',
  styleUrls: ['./icon-privacy-memberonly.component.scss']
})
export class IconPrivacyMemberonlyComponent implements OnInit {
  @Input() color = '#D0D0D0';
  @Input() size = 18;

  constructor() { }

  ngOnInit() {
  }

}
