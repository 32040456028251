import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DateFormatPipe} from './date-format.pipe';
import { DateAmpmPipe } from './date-ampm.pipe';
import {GenreFormatPipe} from './genre-format.pipe';
import {OrderByPipe} from './order-by.pipe';
import {RemainingTimePipe} from './remaining-time.pipe';
import {RoundPipe} from './round.pipe';
import {SortPipe} from './sort.pipe';
import {TextSlicePipe} from './text-slice.pipe';
import {MarkdownPipe} from './markdown.pipe';
import {SafeHtmlPipe} from './safe-html.pipe';
import {TruncateTextPipe} from './truncate-text.pipe';
import {StripHtmlPipe} from './strip-html.pipe';
import {CentsPipe} from '@app/shared/pipes/cents.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    DateFormatPipe,
    DateAmpmPipe,
    GenreFormatPipe,
    OrderByPipe,
    RemainingTimePipe,
    RoundPipe,
    SortPipe,
    TextSlicePipe,
    MarkdownPipe,
    SafeHtmlPipe,
    TruncateTextPipe,
    StripHtmlPipe,
    CentsPipe
  ],
  exports: [
    DateFormatPipe,
    DateAmpmPipe,
    GenreFormatPipe,
    OrderByPipe,
    RemainingTimePipe,
    RoundPipe,
    SortPipe,
    TextSlicePipe,
    MarkdownPipe,
    SafeHtmlPipe,
    TruncateTextPipe,
    StripHtmlPipe,
    CentsPipe
  ],
  providers: [
    SafeHtmlPipe,
  ]
})
export class PipesModule {
}
