import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-favorite-outline',
  templateUrl: './icon-favorite-outline.component.html',
  styleUrls: ['./icon-favorite-outline.component.scss']
})
export class IconFavoriteOutlineComponent implements OnInit {
  @Input() color: string = '#262628';
  @Input() size: number = 18;

  constructor() { }

  ngOnInit() {
  }

}
