import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {ShoppingCartService} from '../services/shopping-cart.service';
import {CategoriesService} from '@app/core';

@Component({
  selector: 'app-shop-product',
  templateUrl: './shop-product.component.html',
  styleUrls: ['./shop-product.component.scss']
})
export class ShopProductComponent implements OnInit {

  showCategory:boolean = false;
  id: number;
  product: any = {};

  constructor(private route: ActivatedRoute,
              private categoriesService: CategoriesService,
              private cartService: ShoppingCartService) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = +params['id'];
    });
    this.getProductDetails();
  }

  getProductDetails() {
    this.categoriesService.getProductDetails(this.id)
        .subscribe(
            response => this.product = response,
            err => console.error(err)
        );
  }

  showHide() {
    this.showCategory == false ? this.showCategory = true : this.showCategory = false;
  }

  addToCart(item) {
    this.cartService.setShoppingCard(item);
  }

  addColor(color) {
    this.product.color = color;
  }

  addSize(size) {
    this.product.size = size;
  }

}
