
import { Component, AfterViewInit, OnDestroy, Input, ElementRef, ViewChild} from '@angular/core';
import {
  ProfileService,
} from '@app/core';
import { Cookie } from 'ng2-cookies';

@Component({
  selector: 'app-top-message',
  templateUrl: './top-message.component.html',
  styleUrls: ['./top-message.component.css']
})
export class TopMessageComponent implements AfterViewInit {
  @ViewChild('banner', { static: true }) private banner: ElementRef;
  @ViewChild('banner4', { static: true }) private banner4: ElementRef;
  @ViewChild('badBrowser', { static: true }) private badBrowserElement: ElementRef;
  @ViewChild('firefox', { static: true }) private firefoxElement: ElementRef;
  @Input('system') systemAlerts = false;

  badBrowser = false;
  message;
  preMessage;
  database;
  completeBannerArray;
  firefox = false;
  observer;
  config = { attributes: true, childList: true, subtree: true };
  cookiesArray = document.cookie.split(';');
  constructor(private service: ProfileService) { }

  ngAfterViewInit(): void {
    let array;


    this.service.getCheck().subscribe((res)=>{
      if(this.systemAlerts) {
        array = res['system']
      } else {
        array = res['announcement']

      }
      //array = res //stupid javascript
      this.cookiesArray.forEach((cookie)=>{
        //here we go through the banners being served and check if our cookies currently match them
        let keep = false;
        if(cookie.includes('hide')) {
          array.forEach((banner, i) => {
            if(cookie.includes(banner.id)) {
              keep = true;
              //remove banners we don't want to show
              array.splice(i, 1)
            }
          });
          let cookieName = cookie.substr(1,36);
          if(!keep) {
            //we erase the cookie if it doesn't match any of the current banners being served
            document.cookie = cookieName+'=; Max-Age=-99999999;';  
          }
        }
      })
      this.checkSystemBanners(array)

    });
    this.checkBrowser();
    if(localStorage.getItem('noBadBrowser')) {
      this.badBrowser = false;
    }
    if(localStorage.getItem('noFirefox')) {
      this.firefox = false;
    }

   
  }
  checkSystemBanners(array) {

      array.forEach((banner, i)=>{

        if(this.systemAlerts) {
          if((i+1) % 2 ) {
            banner.className += ' altSystem'
          }
        }
      })
      this.message = array
      console.log(this.message)

    }

  ngOnDestroy(){
    //this.observer.disconnect();
  }
  hideBannerDynamic(id) {
    document.cookie = `${id}=hide`;
    document.getElementById(id).style.display = 'none'
  }
  checkBrowser(){
    let userAgent = window.navigator.userAgent.toLowerCase()
    if (userAgent.indexOf('firefox',0) !== -1) {
      console.log('firefox')
      if(this.systemAlerts) {
        this.firefox = true;
      }

      return
    }
  // safari and chrome
  if (userAgent.indexOf('webkit',0) !== -1) {
      let vendor = window.navigator.vendor.toLowerCase(); // not a standard property

      if(vendor.search('apple') >= 0){
      } else if(vendor.search('google') >= 0) {
        console.log('chrome')
        return
      }

  }
    // if(navigator.userAgent.indexOf("Chrome") != -1 )
    // {
    //     console.log('chrome')
    //     return
    // }
    // else if(navigator.userAgent.indexOf("Firefox") != -1 )
    // {
    //      return
    // }
    if(this.systemAlerts) {
      this.badBrowser = true;

    }
  }
  hideBanner(){
    this.banner.nativeElement.style.display = 'none'
    localStorage.setItem('noBanner_promo', 'true');
  }

  hideBanner4(){
    this.banner4.nativeElement.style.display = 'none'
    localStorage.setItem('noBanner4', 'true');
  }

  hideBadBrowser(){
    this.badBrowser = false
    localStorage.setItem('noBadBrowser', 'true');
  }
  hideFirefox(){
    this.firefox = false
    localStorage.setItem('noFirefox', 'true');
  }
  action() {
    window.location.href = '/play/3801459'
  }

}
