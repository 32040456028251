import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-headphone',
  templateUrl: './icon-headphone.component.html',
  styleUrls: ['./icon-headphone.component.scss']
})
export class IconHeadphoneComponent implements OnInit {
  @Input() color = '#262628';
  @Input() size = 18;

  constructor() { }

  ngOnInit() {
  }

}
