<div class="app-icon">
  <svg [attr.width]="size"  viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <polygon id="path-1" points="0 0 0 14 11 7"></polygon>
    </defs>
    <g id="ic_play_arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g>
        <g id="Icon-24px" transform="translate(8.000000, 5.000000)">
          <mask id="mask-2" fill="white">
            <use xlink:href="#path-1"></use>
          </mask>
          <use id="Mask" [attr.fill]="color" xlink:href="#path-1"></use>
        </g>
        <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
      </g>
    </g>
  </svg>
</div>