import {ChangeDetectorRef, Component, Injector, Input, OnInit} from '@angular/core';
import {ProfileService, MessageBoardService, RecordingService, Data} from '@app/core';
import {ToastrService} from 'ngx-toastr';

type LoveType = 'comment' | 'reply' | 'thread' | 'recording';

const ERROR_LOVE = 'There was an error. Try again later.';
const ERROR_UNLOVE = 'There was an error try again later.';

@Component({
    selector: 'app-love-button',
    templateUrl: './love-button.component.html',
    styleUrls: ['./love-button.component.scss']
})
export class LoveButtonComponent implements OnInit {

    @Input() type: LoveType;
    @Input() id: any;
    @Input() enabled = true;
    @Input() counter = 0;
    @Input() loved = false;
    @Input() replyUser: any;
    private heartColor = '#D0D0D0';
    private service;
    replyUserFromProfile: any;
    //model.stats.loved

    constructor(private injector: Injector,
                private toast: ToastrService,
                private cdr: ChangeDetectorRef,
                private data: Data,
                private profileService: ProfileService) {
    }

    ngOnInit() {
        switch (this.type) {
            case 'reply':
            case 'thread':
                this.service = this.injector.get<any>(MessageBoardService);
                break;
            case 'comment':
            case 'recording':
                this.service = this.injector.get<any>(RecordingService);
                break;
        }
        if (this.replyUser){
            this.profileService.getUser(this.replyUser.id).subscribe(response => {
            this.replyUserFromProfile = response; 
            });
        }
    }

    love() {
        if (!this.enabled) {
            return;
        }

        switch (this.type) {
            case 'reply':
                this.service.favoriteComment(this.id).subscribe(response => {
                    if (response.success) {
                        this.loved = true;
                        this.counter = response.model.stats.love;
                        this.data.msgBrdReplyLoved = this.data.msgBrdReplyLoved + 1;
                        //for topic
                        let emailFound = this.data.msgBrdReplyList.find((value) => {
                            if (value.topic_reply_recipient_email === this.replyUserFromProfile.email) return true;
                        });
                        if (emailFound){
                            var loveDataValue = this.data.msgBrdReplyList.find(value => value.topic_reply_recipient_email === this.replyUserFromProfile.email).topic_reply_love++;                         
                        } else {
                            this.data.msgBrdReplyList.push(
                                {
                                    'topic_reply_recipient_email': this.replyUserFromProfile.email,
                                    'topic_reply_recipient_name': this.replyUserFromProfile.screen_name,
                                    'newsletter': this.replyUserFromProfile.newsletter,
                                    'topic_reply_quote': 0,
                                    'topic_reply_love': 1,
                                    'topic_reply_reaction': 0,
                                    'topic_reply_emoji': []
                                }
                            );                
                        }
                        //for post reply
                        emailFound = this.data.msgBrdPost_Reply_List.find((value) => {
                            if (value.topic_reply_recipient_email === this.replyUserFromProfile.email) return true;
                        });
                        if (emailFound){
                            var loveDataValue = this.data.msgBrdPost_Reply_List.find(value => value.topic_reply_recipient_email === this.replyUserFromProfile.email).topic_reply_love++;                         
                        } else {
                            this.data.msgBrdPost_Reply_List.push(
                                {
                                    'topic_reply_recipient_email': this.replyUserFromProfile.email,
                                    'topic_reply_recipient_name': this.replyUserFromProfile.screen_name,
                                    'newsletter': this.replyUserFromProfile.newsletter,
                                    'topic_reply_quote': 0,
                                    'topic_reply_love': 1,
                                    'topic_reply_reaction': 0,
                                    'topic_reply_emoji': []
                                }
                            );                
                        }
                    } else {
                        this.loved = false;
                        this.handleError(ERROR_LOVE);
                    }
                    this.detectChanges();
                }, () => this.handleError(ERROR_LOVE));
                break;
            case 'thread':
                this.service.loveThread(this.id).subscribe(response => {
                    if (response.success) {
                        this.loved = true;
                        this.counter = response.model.stats.love;
                    } else {
                        this.loved = false;
                        this.handleError(ERROR_LOVE);
                    }
                    this.detectChanges();
                }, () => this.handleError(ERROR_LOVE));
                break;
            case 'comment':
                this.service.favoriteComment(this.id).subscribe(response => {
                    if (response.success) {
                        this.loved = true;
                        this.counter = response.model.stats.love;
                        //sendgrid email
                        let emailFound = this.data.recordingCommentReplies.find((value) => {
                        if (value.recording_reply_recipient_email === this.replyUser.email) return true;
                        });
                        if (emailFound){
                            this.data.recordingCommentReplies.find(value => value.recording_reply_recipient_email === this.replyUser.email).recording_reply_loved++;
                        } else {
                            this.data.recordingCommentReplies.push(
                                {
                                    'recording_reply_recipient_email': this.replyUser.email,
                                    'recording_reply_recipient_name': this.replyUser.screen_name,
                                    'newsletter': this.replyUser.newsletter,
                                    'recording_reply_replies': 0,
                                    'recording_reply_loved': 1,
                                    'recording_reply_reaction': 0,
                                    'recording_reply_emoji': []
                                }
                            );
                        }
                    
                    } else {
                        this.loved = false;
                        this.handleError(ERROR_LOVE);
                    }
                    this.detectChanges();
                }, () => this.handleError(ERROR_LOVE));
                break;
            case 'recording':
                this.service.love(this.id).subscribe(response => {
                    if (response.success) {
                        this.loved = true;
                        this.counter = response.model.stats.love;
                    } else {
                        this.loved = false;
                        this.handleError(ERROR_LOVE);
                    }
                    this.detectChanges();
                }, () => this.handleError(ERROR_LOVE));
                break;
        }
    }

    unlove() {
        if (!this.enabled) {
            return;
        }

        switch (this.type) {
            case 'comment':
                this.service.unFavoriteComment(this.id).subscribe(response => {
                    if (response.success) {
                        this.loved = false;
                        this.counter = response.model.stats.love;
                    } else {
                        this.loved = true;
                        this.handleError(ERROR_UNLOVE);
                    }
                    this.detectChanges();
                }, () => this.handleError(ERROR_UNLOVE));
                break;
            case 'thread':
                this.service.unLoveThread(this.id).subscribe(response => {
                    if (response.success) {
                        this.loved = false;
                        this.counter = response.model.stats.love;
                    } else {
                        this.loved = true;
                        this.handleError(ERROR_UNLOVE);
                    }
                    this.detectChanges();
                }, () => this.handleError(ERROR_UNLOVE));
                break;
            case 'reply':
                this.service.unFavoriteComment(this.id).subscribe(response => {
                    if (response.success) {
                        this.loved = false;
                        this.counter = response.model.stats.love;
                    } else {
                        this.loved = true;
                        this.handleError(ERROR_UNLOVE);
                    }
                    this.detectChanges();
                }, () => this.handleError(ERROR_UNLOVE));
                break;
            case 'recording':
                this.service.unlove(this.id).subscribe(response => {
                    if (response.success) {
                        this.loved = false;
                        this.counter = response.model.stats.love;
                    } else {
                        this.loved = true;
                        this.handleError(ERROR_UNLOVE);
                    }
                    this.detectChanges();
                }, () => this.handleError(ERROR_UNLOVE));
                break;
        }
    }

    onHover() {
        if (!this.enabled) {
            return;
        }
        if (!this.loved) {
            this.heartColor = 'rgb(249, 70, 120)';
        }
    }

    onLeave() {
        if (!this.enabled) {
            return;
        }
        this.heartColor = '#D0D0D0';
    }

    handleError(message = '') {
        this.toast.error(message);
    }

    detectChanges() {
        this.cdr.markForCheck();
        this.cdr.detectChanges();
    }
}
