<ng-template #content>
  <div class="modal-header align-items-center">
    <h4 class="modal-title" id="modal-basic-title">SET STATUS MESSAGE</h4>
    <h4 class="modal-close" (click)="closeModal()">CLOSE</h4>
  </div>
  <div class="modal-body" *ngIf="!finished">
    <div class="modal-body-content">
      <div class="input-group">
        <textarea class="form-control" cols="6" maxlength="100" placeholder="Your new status message..." [(ngModel)]="status"></textarea>
      </div>
      <div class="status-length">{{status.length}}/100</div>
    </div>
  </div>
  <div class="modal-footer" *ngIf="!finished">
    <button type="button" class="btn btn-primary" (click)="updateStatus()">SAVE STATUS</button>
  </div>
  <div class="modal-body" *ngIf="finished">
    <div class="update-completed">
      <div class="icon-success">
        <div class="icon-inner">
          <app-icon-check color="white" size="30"></app-icon-check>
        </div>
      </div>
      <h4>Updated!</h4>
      <h6>Your status will reset in 7 days,<br>unless updated first</h6>
    </div>
  </div>
</ng-template>
