
import {takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, ElementRef, ViewChild, Output} from '@angular/core';
import {States} from './player.states';
import {VgApiService, VgFullscreenApiService} from '@videogular/ngx-videogular/core';
import {ProfileService} from '@app/core';
import {RecordingService, SongService, Data, UtilService} from '@app/core';
import {Subject, timer} from 'rxjs';
import { RosesComponent } from '../roses/roses.component';
import { initiateLyricPlayer } from '../../../assets/build-lyrics/super-lyrics'
import { EmailNotificationsService } from '@app/core/services/email-notifications.service';
declare var gtag;
declare const require: any;


@Component({
    selector: 'app-recording-player',
    templateUrl: './recording-player.component.html',
    styleUrls: ['./recording-player.component.scss']
})
export class RecordingPlayerComponent implements OnInit, OnDestroy {
    @HostListener('window:keydown', ['$event'])
    keyEvent(event: KeyboardEvent) {
      if(event.keyCode == 32){
        console.log(document.activeElement)
        if(document.activeElement.classList[0] == 'ql-editor' || document.activeElement.tagName == 'INPUT') {
            console.log('do nothing')
        }
      else if(document.activeElement.tagName == 'BODY' || document.activeElement.classList[0] == 'scrubBar' || document.activeElement.classList[0] == 'ss-progress-input') {
          console.log('toggle play')
        event.preventDefault();
        this.togglePlay();
      }
      // e.stopImmediatePropagation();
      // e.preventDefault();
        // Your row selection code
        console.log(event);
      } 
    }
    @Input() rec;
    @Input() converting = false;
    @Input() volume;
    @Input() isFirst = false;
    @Input() isLast = false;
    @Input() poster;
    @Input() shuffle = false;
    @Input() showReplay = false;
    @Input() showHeader = true;
    @Input() continuousPlay = false;
    @Input() showControl = false;
    @Output() onLove = new EventEmitter<any>();
    @Output() onPrev = new EventEmitter<any>();
    @Output() onNext = new EventEmitter<any>();
    @Output() onReplay = new EventEmitter<any>();
    @Output() onShuffle = new EventEmitter<any>();
    @ViewChild('lyric', { static: true }) private lyricDom: ElementRef;
    @ViewChild(RosesComponent) private roses: RosesComponent;
    

    @ViewChild('lyricWrapper', { static: true }) private lyricWrapper: ElementRef;
    noLyrics = false;
    vgPlayer = document.getElementById('vgPlayer')
    user: any;
    id;
    recorder: any;
    destroyed = false;
    favoriting: any;
    //rec: any;
    artist: any;
    recordingUri: any;
    song: any;
    recording = false;
    legacyDuet = false;
    step: any;
    pitch = 0;
    previewOn = false;
    lyricPreview = null;
    states = States;
    firstPlay = true;
    state = this.states.START;
    preload = 'auto';
    lyrics = false;
    scriptTag;
    type = 'video/mp4';
    fullscreen = false;
    loaded = false;
    api: VgApiService;
    playing = false;
    fsAPI: VgFullscreenApiService;
    oldLyricPos;
    nativeFs = true;
    showAvatarOverlay = false;
    finishedLast = false;
    duetHeight = 0
    public processing = true;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    recipientsEmails=[];
    recipientsNames=[];
    isMe = false;
    recHasLayers = false;
    //Timer
    timeElapsedSeconds = 0;
    private timeElapsed = 0;
    private timer = null;
    private subscription = null;

    constructor(private profileService: ProfileService,
                private recordingService: RecordingService,
                private songService: SongService,
                private emailNotificationsService: EmailNotificationsService,
                private data: Data,
                private util: UtilService) {
    }

    async loadLyrics(id){
        const RESULT = await this.songService.loadLyrics(id).toPromise();
        console.log(RESULT)
        this.loaded = true;
        if(RESULT.lyrics.length <= 1) {
            this.noLyrics = true;

        } else {
            let duetData = {
                duet: this.rec.song.duet,
                parts: this.rec.song.parts
            }
            this.lyricPreview = initiateLyricPlayer(RESULT.lyrics, RESULT.song.word_timings, duetData);

        }
    }
    sendRose() {
        this.roses.togglePopup()
       // this.roses.generateRose(this.user.screen_name)
    }
    toggleLyrics(){
        this.lyrics = !this.lyrics

    }
    start(){

        //console.log(this.rec)
        this.id = this.rec.song.id;


        this.user = this.profileService.user;
        // this.recordingService.get(this.id).pipe(
        //     takeUntil(this.ngUnsubscribe))
        //     .subscribe(data => {
                
                this.loadLyrics(this.id)
                console.log(this.rec)


                console.log(this.rec)
                // if(data.users.length == 2) {
                //     console.log('users is ' + data.users.length)
                //     document.getElementById('listenMedia').classList.add('duet')
                //     document.getElementById('listenMedia').style.height = '100%';
                //     //(<HTMLElement>document.querySelector('vg-player')).style.minHeight = '0px'


                // } else {
                //     document.getElementById('listenMedia').classList.remove('duet')
                //     document.getElementById('listenMedia').style.removeProperty('height')
                // }
                if(!this.volume){
                    this.volume = localStorage.getItem('listenVolume')
                }
                this.volumeChange(this.volume);

                //this.rec = data;
                 this.processing = this.rec.processing;
                this.poster = this.rec.user.photo;
                if (!this.processing) {
                    this.song = this.rec.song;
                    this.recordingUri = this.rec.uri;
                    this.showAvatarOverlay = !this.rec.webcam;
                }

            // }, error => {
            //     if(error.status == '425') {
            //         this.converting = true;
            //         console.log(error)
            //         let conversionCheck = setInterval(()=>{
            //             //checks every 5 seconds if the recording is done being converted
            //             this.recordingService.conversionCheck(error.url).pipe(
            //                 takeUntil(this.ngUnsubscribe))
            //                 .subscribe((data) => {
            //                     if(data.status == '200') {
            //                         //reloads once the recording is converted and ready to play
            //                         clearInterval(conversionCheck)
            //                         location.reload();
            //                     }
            //                 })

            //         }, 5000)
            //     }
            //     console.log(error);
            //  });
    }

    ngOnInit() {
          this.start()
          document.addEventListener('fullscreenchange', ()=>{
            let video = document.getElementById('listenMedia')
            if(this.fullscreen == true) {
                this.fullscreen = false;
                video.style.maxWidth = '835px'
                if(this.rec.song.duet && !this.legacyDuet) {
                    video.style.width = '835px'
                }
                console.log('duet height is ' + this.duetHeight)
                if(!this.legacyDuet) {

                    if(this.duetHeight == 2){
                        video.style.height = '835px'
                        video.style.marginTop = '-138px'
                    } else if(this.duetHeight == 1) {
                        video.style.height = '835px'
                        video.style.marginTop = '-209px'
                    }
                } 
            } else {
                if(!this.legacyDuet) {
                    if(this.duetHeight == 1 || this.duetHeight == 2) {
                            video.style.removeProperty('height')
                            video.style.removeProperty('margin-top')
                        }
                }
                this.fullscreen = true;
                video.style.removeProperty('max-width')
            }

        });

        var recLayers = {};
        for(var i=0;i<this.rec.users.length;i++) {
                var users = recLayers[i]  = {}; //define first if it a object otherwise it say undefined.
                users['email'] = this.rec.users[i].email;
                users['name'] = this.rec.users[i].screen_name;
                users['isme'] = (this.user.id === this.rec.users[i].id) ? true : false;  //this.isMe
                users['newsletter'] = this.rec.users[i].newsletter;
               
            }

        this.data.commentSent = false;
        this.data.loveSent = false;
        this.data.roseSent = false;
        this.data.recLayers = recLayers;
        this.data.commentSentCount = 0;
        this.data.loveSentBool = 0;
        this.data.roseSentCount = 0;
        this.data.reactionSentEmoji = '';
        this.data.reactionSent = false;
        this.data.commentText ='';

        this.data.mentionList = [];

        this.data.sendCommentData = {
            'email': this.rec.user.email, 'recipient_name': this.rec.user.screen_name, 'sender_name': this.user.screen_name, 'recording_song_name': this.rec.song.title, 'share_url': this.rec.share_url, 'recipient_wants_newsletter': this.rec.user.newsletter
        }

    }
    volumeChange(value) {
      if(typeof this == 'undefined' || this.destroyed == true) {
        return
      }
      console.log(value)
        if(typeof value == 'undefined' || value == null) {
            value = 0.5
        };
        console.log('changing volume ' + value)
        this.volume = value;
        console.log(document.getElementsByTagName('video')[0])
        document.getElementsByTagName('video')[0].volume = this.volume;
        localStorage.setItem('listenVolume', this.volume.toString())
    }
    ngOnDestroy() {

          let spic = this.data.removeHTTP(this.user.photo);
          let vpic = this.data.removeHTTP(this.rec.cover);
          let vrec = this.data.removeHTTP(this.rec.uri);
          let rcom = this.data.commentText;          

            if(this.data.commentSentCount != 0 || this.data.roseSentCount != 0 || this.data.reactionSent  || this.data.loveSentBool != 0){
             // send email
               if(Object.keys(this.data.recLayers).length > 1){
                let recLayerList = '';
                this.recHasLayers = true;
                for(var i=0;i<Object.keys(this.data.recLayers).length;i++) {
                  if( i!= Object.keys(this.data.recLayers).length){
                    recLayerList += this.data.recLayers[i].name + ', ';
                  }
                }
                let newRecordingName = this.data.sendCommentData.recording_song_name + " featuring " + recLayerList.slice(0, -2);
                newRecordingName = newRecordingName.substring(0, newRecordingName.lastIndexOf(', ')) + ' &' + newRecordingName.substring(newRecordingName.lastIndexOf(', ') + 1);
                for(var i=0;i<Object.keys(this.data.recLayers).length;i++) {
                  if(!this.data.recLayers[i].isme && this.data.recLayers[i].newsletter){
                  this.emailNotificationsService.emailRecInteractionsV2(this.data.recLayers[i].email, this.data.recLayers[i].name, this.user.screen_name, this.user.id, this.rec.song.title, this.rec.share_url, this.recHasLayers,this.data.commentSentCount, this.data.roseSentCount, this.data.loveSentBool, this.data.reactionSentEmoji, this.data.reactionSent,
                    spic, vpic, vrec, rcom);
                }
                }
              } else {
                  if(this.data.sendCommentData.recipient_wants_newsletter){
                      this.emailNotificationsService.emailRecInteractionsV2(this.rec.user.email, this.rec.user.screen_name, this.user.screen_name, this.user.id, this.rec.song.title, this.rec.share_url, this.recHasLayers, this.data.commentSentCount, this.data.roseSentCount, this.data.loveSentBool, this.data.reactionSentEmoji, this.data.reactionSent,
                        spic, vpic, vrec, rcom);
                 }
              }
            }

            if(Object.keys(this.data.mentionList).length){
              if (this.data.mentionList[0] && this.data.mentionList[0].reply_mention > 0 ){
                for(var i=0;i<Object.keys(this.data.mentionList).length;i++) {
                  if(this.data.mentionList[i].newsletter){
                    this.emailNotificationsService.emailMentions(
                      this.data.mentionList[i].topic_reply_recipient_email, 
                      this.data.mentionList[i].topic_reply_recipient_name, 
                      this.user.screen_name, 
                      this.user.id,
                      this.rec.song.title, 
                      this.rec.id,
                      this.data.mentionList[i].reply_mention,
                      'rec'
                    )
                  }
                }
              }
            }

        
            if(Object.keys(this.data.recordingCommentReplies).length){
                for(var i=0;i<Object.keys(this.data.recordingCommentReplies).length;i++) {
                    if(this.data.recordingCommentReplies[i].newsletter){
                        this.emailNotificationsService.emailRecordingCommentReplies(
                        this.data.recordingCommentReplies[i].recording_reply_recipient_email, 
                        this.data.recordingCommentReplies[i].recording_reply_recipient_name, 
                        this.user.screen_name, 
                        this.user.id,
                        this.rec.song.title, 
                        this.rec.id,
                        this.data.recordingCommentReplies[i].recording_reply_replies,
                        this.data.recordingCommentReplies[i].recording_reply_loved,
                        this.data.recordingCommentReplies[i].recording_reply_reaction,
                        this.data.recordingCommentReplies[i].recording_reply_emoji
                        )
                    }
                }
            }


        this.legacyDuet = false;
        this.duetHeight = 0
        this.destroyed = true;
        if(this.playing) {
            this.pause();
        }
        this.lyricPreview = null;
        if(this.state != "playing" && !this.firstPlay) {
            this.recordingService.stop(this.rec.id)
        }
        
        this.stopTimer();
        
        this.rec = null;
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.data.recordingCommentReplies = [];


    }
    togglePlay(){
        if(this.playing) {
            this. pause();    
        } else {
            this.play();
        }
    }
    seek(){
        if(this.lyricPreview) {
            this.lyricPreview.seek(this.api.currentTime * 1000)
        }
        if(this.playing){
        }
        this.roses.seek(this.api.currentTime)
    }
    onPlayerReady(api: VgApiService) {
        let video = document.getElementById('listenMedia')
        video.addEventListener("loadedmetadata", () => {
            let video = document.getElementById('listenMedia')
            let vgPlayer = document.getElementById('vgPlayer')
            if ((parseInt(video['videoWidth']) / parseInt(video['videoHeight'])) != 1) {
                this.legacyDuet = true;
            }
            console.log('ar is ' + (parseInt(video['videoWidth']) / parseInt(video['videoHeight'])))
            if(!this.rec.legacy && !this.legacyDuet) {
                this.recordingService.getDuetVideos(this.rec.id).subscribe((res)=>{
                    let count = res['count']

                                if(count == 2) { //set duet height     
                                    this.duetHeight = 1
                                    video.style.height = '835px'
                                    video.style.marginTop = '-209px'
                                }
                                if(count == 6 || count == 5) {
                                    this.duetHeight = 2
                                    video.style.height = '835px'
                                    video.style.marginTop = '-138px'
                                    vgPlayer.style.maxHeight = '559px'
                                    vgPlayer.style.height = '559px'
                                }
                        })
                    }  else {
                        video.style.width = '100vw'
                    }
            vgPlayer.style.removeProperty('height')
            setTimeout(()=>{
                video.style.removeProperty('opacity')
            },300)

        });
        console.log(api)
        this.api = api;
        this.fsAPI = this.api.fsAPI;
        this.nativeFs = this.fsAPI.nativeFullscreen;
        this.api.subscriptions.timeUpdate.subscribe(data => {
            //send current time to roses
                this.roses.changeTime(data.srcElement.currentTime)
                if(this.lyricPreview) {
                    console.log(data.srcElement.currentTime)
                    this.lyricPreview.updateLyrics(data.srcElement.currentTime * 1000)
                }
              
              });
        setTimeout(() => {
            this.api.getDefaultMedia().subscriptions.loadedData.pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(
                    () => {
                        this.api.getDefaultMedia().currentTime = 0;
                        if (this.continuousPlay) {
                            this.play();
                        }
                    }
                );

            this.api.getDefaultMedia().subscriptions.ended.pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(
                    () => {
                        this.state = this.states.PAUSED;
                        if (this.isLast) {
                            this.finishedLast = true;
                        } else {
                            this.onNextClick(true);
                        }
                    }
                );
        }, 0)
    }

    toggleShuffle() {
        this.shuffle = !this.shuffle;
        this.onShuffle.emit(this.shuffle);
    }

    getTitle() {
        return this.song && this.song.title;
    }

    play() {
        if(this.firstPlay) {
            this.recordingService.play(this.rec.id)
            this.firstPlay = false;
        }
        console.log(this.api)
        if(this.lyricPreview) {
            
        }
        this.playing = true;
        this.state = this.states.PLAYING;
        console.log(this.state)
        this.api.play();
        gtag("event", "listen", {
            action: 'Play Recording',
            recId: this.rec.id.toString(),
            recSongId: this.rec.song.id.toString(),
            songId: this.rec.song.id.toString()
        });
        this.startTimer();

    }

    pause() {
      if(this.lyricPreview) {

      }
        this.playing = false;
        this.state = this.states.PAUSED;
        this.api.pause();
        
        this.pauseTimer();

    }
    stop(){
      if(this.lyricPreview) {

      }
        this.recordingService.stop(this.rec.id)
        this.pauseTimer();
    }

    toggleFullscreen() {
        if(this.fullscreen) {
            document.exitFullscreen()

          } else {
              document.getElementById('vgPlayer').requestFullscreen()

          }
    }

    stateChangeHandler(state) {
        this.toState(state);
    }

    isState(state) {
        return this.states[state.toUpperCase()] === this.state;
    }

    /**
     * Change player state
     * @param state
     */
    toState(state) {
        console.log(state)
        this.state = this.states[state.toUpperCase()];
    }

    toggleLove() {
        this.favoriting = true;
        (this.rec.loved ? this.unLoveRecording() : this.loveRecording()).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.rec.loved = data.model.loved;
                this.rec.stats.love = data.model.stats.love;
                this.favoriting = false;
                if (data.success) {
                }
                this.onLove.emit({
                    success: true,
                    data: data
                });
            });
    }

    loveRecording() {

            // if(!this.data.loveSent){
            //    // send email
            //    if(Object.keys(this.data.recLayers).length > 1){
            //     let recLayerList = '';
            //     for(var i=0;i<Object.keys(this.data.recLayers).length;i++) {
            //       if( i!= Object.keys(this.data.recLayers).length){
            //         recLayerList += this.data.recLayers[i].name + ', ';
            //       }
            //     }
            //     let newRecordingName = this.data.sendCommentData.recording_song_name + " featuring " + recLayerList.slice(0, -2);
            //     newRecordingName = newRecordingName.substring(0, newRecordingName.lastIndexOf(', ')) + ' &' + newRecordingName.substring(newRecordingName.lastIndexOf(', ') + 1);
            //     for(var i=0;i<Object.keys(this.data.recLayers).length;i++) {
            //       if(!this.data.recLayers[i].isme && this.data.recLayers[i].newsletter){
            //         this.emailNotificationsService.emailLoveFeaturedOnNotification (this.data.recLayers[i].email, this.data.recLayers[i].name, this.data.sendCommentData.sender_name, newRecordingName, this.data.sendCommentData.share_url);
            //       }
            //     }
            //   } else {
            //       if(this.data.sendCommentData.recipient_wants_newsletter){
            //         this.emailNotificationsService.emailLoveNotification (this.data.sendCommentData.email, this.data.sendCommentData.recipient_name, this.data.sendCommentData.sender_name, this.data.sendCommentData.recording_song_name, this.data.sendCommentData.share_url);
            //       }
            //   }
            //   this.data.loveSent = true;
            // }

            this.data.loveSentBool = 1;

        return this.recordingService.love(this.rec.id);
    }

    unLoveRecording() {
        //this.emailNotificationsService.emailUnLoveNotification(this.rec.user.email, this.rec.user.screen_name, this.user.screen_name   , this.rec.song.title, this.rec.share_url);
        //this.data.loveSentBool = 0;
        return this.recordingService.unlove(this.rec.id);
    }

    getFavoriteTooltip(recording) {
        return (recording && recording.favorited) ? 'Unlove this recording.' : 'Love this recording.';
    }

    onPrevClick() {
        if (this.onPrev) {
            this.onPrev.emit();
        }
    }

    onNextClick(flag = true) {
        if (this.onNext) {
            this.onNext.emit(flag);
        }
    }

    replay() {
        if (this.onReplay) {
            this.onReplay.emit();
        }
    }


    startTimer() {
        this.timer = timer(1000, 1000);
        this.subscription = this.timer.subscribe(() => {
        this.timeElapsed = (this.timeElapsed + 1000);
        this.timeElapsedSeconds = this.timeElapsed / 1000;
 
        });
    }

    pauseTimer() {
        if (this.timer ) {
          this.subscription.unsubscribe();
          this.timer = null;
        } else {
          this.startTimer();
        }
      }
    
     stopTimer() {
        this.recordingService.sendPlayTime(this.rec.id, this.timeElapsedSeconds)
        .subscribe(response => {
        });
        if (this.timer) {
          this.subscription.unsubscribe();
          this.subscription = null;
          this.timer = null;
          this.timeElapsed = 0;
        
        }
      }


     uniqueFilter(value, index, self) {
        return self.indexOf(value) === index;
     }




}
