<div 
  class="user-snapicon-carousel" 
  [style.width]="dimension + 'px'"
  [style.height]="dimension + 'px'"
  [style.right]="posRight + 'px'"
  [style.bottom]="posBottom + 'px'"
>
  <ngb-carousel>
    <ng-template ngbSlide *ngFor="let icon of icons">
      <img [src]="icon" />
    </ng-template>
  </ngb-carousel>
</div>