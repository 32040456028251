import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-user-badge-level',
    templateUrl: './user-badge-level.component.html',
    styleUrls: ['./user-badge-level.component.scss']
})
export class UserBadgeLevelComponent implements OnInit {
    @Input() user;
    @Input() showBadge = true;
    @Input() showLevel = false;
    @Input() showOnline = true;
    constructor() {
    }

    ngOnInit() {
    }

}
