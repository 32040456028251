
import {switchMap, distinctUntilChanged, debounceTime,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject ,  Observable } from 'rxjs';
import { Topic } from '@app/models/topic.model';



import { InnerCircle } from '@app/models/innercircle.model';
@Injectable()
export class InnerCircleService extends HttpService {
  public currentInnerCircle: InnerCircle;

  constructor(private http: HttpClient) {
    super();
  }

  getInnerCircles(page = 1, perPage = 10, sortBy?, term = 'NULL') {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/user/inner-circles?page=${page}&per_page=${perPage}`
    );
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
  getShareableInnerCircles(page = 1, perPage = 10, sortBy?, term = 'NULL') {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/user/inner-circles/can-share?page=${page}&per_page=${perPage}`
    );
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getInnerCirclesIOwn(page = 1, perPage = 10, sortBy?, asc = false, term = 'NULL') {
    const options = this.getHeaders();
    const direction = asc ? 'ASC' : 'DESC';
    const url = this.makeUrl(
      `/user/inner-circles/owned?page=${page}&per_page=${perPage}&sorters={"${sortBy}": "${direction}"}`
    );
    let params = new HttpParams();
    if (term != 'NULL') {
      params = params.append('q', `{"title":"${term}"}`);
    }
    options['params'] = params;
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getInnerCirclesIAdmin(
    page = 1,
    perPage = 10,
    sortBy?,
    asc = false,
    term = 'NULL'
  ) {
    const options = this.getHeaders();
    const direction = asc ? 'ASC' : 'DESC';
    const url = this.makeUrl(
      `/user/inner-circles/admin-of?page=${page}&per_page=${perPage}&sorters={"${sortBy}": "${direction}"}`
    );
    let params = new HttpParams();
    if (term != 'NULL') {
      params = params.append('q', `{"title":"${term}"}`);
    }
    options['params'] = params;
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getInnerCirclesIMemberOf(
    page = 1,
    perPage = 10,
    sortBy?,
    asc = false,
    term = 'NULL'
  ) {
    const options = this.getHeaders();
    const direction = asc ? 'ASC' : 'DESC';
    const url = this.makeUrl(
      `/user/inner-circles/member-of?page=${page}&per_page=${perPage}&sorters={"${sortBy}": "${direction}"}`
    );
    let params = new HttpParams();
    if (term != 'NULL') {
      params = params.append('q', `{"title":"${term}"}`);
    }
    options['params'] = params;
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getSharedInnerCircles(id, page = 1, perPage = 10) {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/users/${id}/inner-circles/shared?page=${page}&per_page=${perPage}`
    );
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getInnerCircle(id) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/inner-circles/${id}`);
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getICPendingInvites(id, page = 1, perPage = 10) {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/user/inner-circles/${id}/invites?page=${page}&per_page=${perPage}`
    );
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  updateInnerCircle(id, innerCircle) {
    const options = this.getHeaders();

    const payload = {} as any;

    if (innerCircle.title) {
      payload.title = innerCircle.title;
    }

    if (innerCircle.title) {
      payload.description = innerCircle.description;
    }

    payload.make_category = innerCircle.make_category;
    payload.make_playlist = innerCircle.make_playlist;
    // flag should be reversed
    payload.members_list_visible = !innerCircle.members_list_visible;
    payload.share_recordings = innerCircle.share_recordings;

    const url = this.makeUrl(`/user/inner-circles/${id}`);
    return this.http
      .put(url, payload, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  createInnerCircle(ic: InnerCircle) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/inner-circles`);
    return this.http
      .post(url, ic, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  deleteGroup(icId) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/inner-circles/${icId}`);
    return this.http
      .delete(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  leaveGroup(icId) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/inner-circles/${icId}/leave`);
    return this.http
      .post(url, {}, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getMembers(id, page = 1, perPage = 10) {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/user/inner-circles/${id}/members?page=${page}&per_page=${perPage}`
    );
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getAdmins(id, page = 1, perPage = 10) {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/user/inner-circles/${id}/admins?page=${page}&per_page=${perPage}`
    );
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getRecordings(id, page = 1, perPage = 10) {
    //user/inner-circles/1/recordings
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/user/inner-circles/${id}/recordings?page=${page}&per_page=${perPage}`
    );
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getThreads(id, page = 1, perPage = 10) {
    const options = this.getHeaders();
    // const url = this.makeUrl(`/user/inner-circles/${id}/recordings?page=${page}&per_page=${perPage}`);
    const url = this.makeUrl(
      `/message-board/categories/${id}/threads?page=${page}&per_page=${perPage}`
    );
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  searchNonMembersDebounced(
    id,
    terms: Observable<string>,
    page = 1,
    perPage = 10
  ) {
    return terms.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      switchMap(term => this.searchNonMembers(id, term, page, perPage)),);
  }

  searchMembersDebounced(
    id,
    terms: Observable<string>,
    page = 1,
    perPage = 10
  ) {
    return terms.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      switchMap(term => this.searchMembers(id, term, page, perPage)),);
  }

  searchAdminsDebounced(id, terms: Observable<string>, page = 1, perPage = 10) {
    return terms.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      switchMap(term => this.searchAdmins(id, term, page, perPage)),);
  }

  searchNonMembers(id, query, page = 1, perPage = 10) {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/user/inner-circles/${id}/users-lookup?q=${query}&page=${page}&per_page=${perPage}`
    );
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  searchMembers(id, query, page = 1, perPage = 10) {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/user/inner-circles/${id}/members-lookup?q=${query}&page=${page}&per_page=${perPage}`
    );
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  searchAdmins(id, query, page = 1, perPage = 10) {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/user/inner-circles/${id}/members-lookup?q=${query}&gold=true&page=${page}&per_page=${perPage}`
    );
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  addMember(id, memberId) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/inner-circles/${id}/members/${memberId}`);
    return this.http
      .post(url, {}, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  addMembers(id, usersIds: Array<number>) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/inner-circles/${id}/members/`);
    return this.http
      .post(
        url,
        {
          users: usersIds,
        },
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  addAdmins(id, usersIds: Array<number>) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/inner-circles/${id}/admins/`);
    return this.http
      .post(
        url,
        {
          users: usersIds,
        },
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  removeAdmin(id, memberId) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/inner-circles/${id}/admins/${memberId}`);
    return this.http
      .delete(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  removeMember(id, memberId) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/inner-circles/${id}/members/${memberId}`);
    return this.http
      .delete(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  leaveMember(id) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/inner-circles/${id}/leave`);
    return this.http
      .post(url, {}, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
}
