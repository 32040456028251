import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-env-switcher',
    templateUrl: './env-switcher.component.html',
    styleUrls: ['./env-switcher.component.scss']
})
export class EnvSwitcherComponent implements OnInit {
    form: UntypedFormGroup;
    env: string;

    constructor(private fb: UntypedFormBuilder) {
        this.form = this.fb.group({
            env: [this.currentEnv, Validators.required]
        });
    }

    ngOnInit() {
    }

    get currentEnv(): any {
        return localStorage.getItem('ss-env') || 'dev';
    }

    set currentEnv(env) {
        localStorage.setItem('ss-env', env || 'dev');
    }

    onSubmit() {
        if (this.form.valid) {
            this.currentEnv = this.form.value.env;
        }
    }
}
