<div class="user-info"
     [class.user-info--gold]="user?.gold">
    <div class="comment__avatar" *ngIf="showPhoto">
        <app-avatar-thumb *ngIf="user?.photo" [user]="user">
        </app-avatar-thumb>
    </div>
    <div class="user-info-details">
        <div class="user-name-badges">
            <app-ss-user-name [user]="user"></app-ss-user-name>
            <!--<app-user-badge-level [user]="user" [showBadge]="showBadge" [showLevel]="showLevel">
            </app-user-badge-level>-->
        </div>
        <div class="user-bio" *ngIf="user?.status && showBio">
            {{user.status}}
        </div>
        <div *ngIf="timestamp">
            <app-time-stamp *ngIf="timestamp" [time]="timestamp" [needWatch]="false" [format]="'MMM d, yyyy hh:mma'">
            </app-time-stamp>
        </div>
    </div>
</div>
