import {Popup} from '@app/shared/comments/ss-editor/modules/popup';

export const link = function (value) {
    if (value) {
        const popup = new Popup();
        const range = this.quill.getSelection();
        let preview, linkName;

        if (range == null || range.length == 0) {
            popup.prompt({
                title: 'Enter your link',
                buttons: {
                    okButton: 'Ok',
                    cancelButton: 'Cancel'
                },
                inputs: {
                    textInput: 'Text:',
                    urlInput: 'Url:'
                }
            }, (result) => {

                if (result.confirm === 'okButton') {
                    linkName = result.textInput;
                    preview = absoluteUrl(result.urlInput);
                    console.log(linkName, preview);
                    this.quill.insertText(range ? range.index : 0, linkName, 'link', preview, 'user');
                }
            });
            return;
        }

        popup.prompt({
            title: 'Enter your link',
            buttons: {
                okButton: 'Ok',
                cancelButton: 'Cancel'
            },
            inputs: {
                urlInput: 'Url:'
            }
        }, (result) => {

            if (result.confirm === 'okButton') {
                const url = absoluteUrl(result.urlInput || 'https://singsnap.com');
                this.quill.format('link', url);
            }
        });

    } else {
        this.quill.format('link', false);
    }
};

function absoluteUrl(url) {
    if (!/^https?:\/\//i.test(url)) {
        url = 'http://' + url;
    }
    return url;
}
