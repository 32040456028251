import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ss-user-photo',
  templateUrl: './ss-user-photo.component.html',
  styleUrls: ['./ss-user-photo.component.scss']
})
export class SsUserPhotoComponent implements OnInit {
  @Input() user = null;
  @Input() size = 55;
  @Input() iconsize = 20;

  constructor() { }

  ngOnInit() {
  }

  get snapicons() {
    if (!this.user) {
      return [];
    }
    const { snapicons = {} } = this.user;
    return Object.keys(snapicons);
  }
}
