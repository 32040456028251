import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
    selector: '[scrollableContainer]'
})
export class ScrollableContainerDirective {
    @Input() height: number;
    private element: HTMLInputElement;

    constructor(private elRef: ElementRef) {
        this.element = elRef.nativeElement;

    }

    ngOnInit() {
        this.element.style.overflow = 'auto';
        this.element.style.maxHeight = this.height + 'px';
    }
}
