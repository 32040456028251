<div *ngIf='database.length != 0 && !panelClosed' id='uploadsFailedOpen'>
    <div class='flex'>
        <h5 id='bigTitle'>Queued Uploads</h5>
        <button (click)='deleteAll()'>Delete all</button>
        <app-icon-angle-down (click)='togglePanel()' color='white' size='32'></app-icon-angle-down>
    </div>
    <div id='failedRecordingList' >
        <div *ngFor='let recording of database' class='failedRecording'>
            <p class='recordingTitle'>{{recording.song.title}}</p>
            <p class='recordingDate'>{{recording.date}}</p>
            <button (click)='retryUpload(recording.id)'>Upload</button>
            <button (click)='delete(recording.id)'>Delete</button>

            
        </div>
    </div>
</div>
<div *ngIf='database.length != 0 && panelClosed' id='uploadsFailedClosed'>
    <div class='flex'>
        <h5>Queued Uploads</h5>
        <app-icon-angle-down id='openArrow' color='white' (click)='togglePanel()' size='32'></app-icon-angle-down>
    </div>
</div>