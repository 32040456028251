<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 24 24" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path
        d="M21.4990728,5.56454921 L19.2501416,3.36895232 C17.023433,1.19557807 14.0544882,0 10.8855396,0 C7.71659108,0 4.74764629,1.19557807 2.5209377,3.36895232 L0.272006469,5.56454921 C-0.230225209,6.11122617 0.0808917597,6.69345936 0.272006469,6.86235143 L2.5209377,9.05794832 C4.74764629,11.2313226 7.71659108,12.4269006 10.8855396,12.4269006 C14.0544882,12.4269006 17.023433,11.2313226 19.2501416,9.05794832 L21.4990728,6.86235143 C21.6768539,6.69345936 22.0190826,6.13344881 21.4990728,5.56454921 Z M10.8855396,9.31573095 C9.17439631,9.31573095 7.783259,7.92459365 7.783259,6.21345032 C7.783259,4.50230699 9.17439631,3.11116969 10.8855396,3.11116969 C12.596683,3.11116969 13.9878203,4.50230699 13.9878203,6.21345032 C13.9878203,7.92459365 12.596683,9.31573095 10.8855396,9.31573095 Z M2.2053762,6.21345032 L3.79207274,4.66675453 C4.99209534,3.49339911 6.44990056,2.65782782 8.04548616,2.20448595 C6.79212923,3.0978361 5.97433605,4.56008586 5.97433605,6.20900579 C5.97433605,7.85792573 6.79212923,9.32462001 8.04548616,10.2135256 C6.44990056,9.76018376 4.99209534,8.92461248 3.79207274,7.75570158 L2.2053762,6.21345032 Z M17.9834511,7.76014611 C16.7834285,8.92905701 15.3256232,9.76462829 13.7300376,10.2179702 C14.9833946,9.32462001 15.8011877,7.86237025 15.8011877,6.21345032 C15.8011877,4.56008586 14.9833946,3.0978361 13.7300376,2.20893048 C15.3256232,2.66227235 16.7834285,3.49784364 17.9834511,4.67119906 L19.5657031,6.21789485 L17.9834511,7.76014611 Z"
        id="icon-eye"></path>
    </defs>
    <g id="eye" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(1.000000, 6.000000)">
        <mask id="mask-2" fill="white">
          <use xlink:href="#icon-eye"></use>
        </mask>
        <use id="Mask" [attr.fill]="color" fill-rule="nonzero" xlink:href="#icon-eye"></use>
      </g>
    </g>
  </svg>
</div>