<div class="app-icon">
    <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <path d="M11,11 L7,11 L7,8 L5,8 L9,4 L13,8 L11,8 L11,11 M2,12 L2,2 L16,2 L16,12 L12,12 C12,13.6568542 10.6568542,15 9,15 C7.34314575,15 6,13.6568542 6,12 L2,12 M16,0 L2,0 C0.89,0 0,0.9 0,2 L0,16 C0,17.1045695 0.8954305,18 2,18 L16,18 C17.1045695,18 18,17.1045695 18,16 L18,2 C18,0.8954305 17.1045695,0 16,0" id="path-sent"></path>
        </defs>
        <g id="inbox-arrow-up" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g>
                <mask id="mask-sent" fill="white">
                    <use xlink:href="#path-sent"></use>
                </mask>
                <g id="Black" mask="url(#mask-sent)" [attr.fill]="color">
                    <polygon id="Shape" points="0 0 400 0 400 400 0 400"></polygon>
                </g>
            </g>
        </g>
    </svg>
</div>
