import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProfileService} from '@app/core';
import {ShopService} from '@app/core/services/shop.service';
import {Charge} from '@app/models/charge.model';
import {environment} from '@env/environment';

declare var Stripe: any;

const stripe = Stripe(environment.stripeKey);
const elements = stripe.elements();

const style = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};

const cardBrandToPfClass = {
  'visa': 'pf pf-visa',
  'mastercard': 'pf pf-mastercard',
  'amex': 'pf pf-american-express',
  'discover': 'pf pf-discover',
  'diners': 'pf pf-diners',
  'jcb': 'pf pf-jcb',
  'unknown': 'pf pf-credit-card',
};

function getBrandClass(brand: any): string {
  let pfClass = 'pf pf-credit-card';
  if (brand in cardBrandToPfClass) {
    pfClass = cardBrandToPfClass[brand];
  }
  return pfClass;
}

@Component({
  selector: 'app-cc-checkout',
  templateUrl: './cc-checkout.component.html',
  styleUrls: ['./cc-checkout.component.scss']
})
export class CcCheckoutComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('cardNumberElement', { static: true }) cardNumberElement: ElementRef;
  @ViewChild('cardExpiryElement', { static: true }) cardExpiryElement: ElementRef;
  @ViewChild('cardCvcElement', { static: true }) cardCvcElement: ElementRef;

  cardNameOn = "";
  stripe;
  cardNumber;
  cardExpiry;
  cardCvc;
  cardErrors;
  brandClass = 'pf pf-credit-card';
  isEnteringInfo = false;

  loading = false;
  validCreditCard = false;
  confirmation;

  constructor(private profiles: ProfileService, private shop: ShopService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.buildStripeElements();
  }

  ngOnDestroy(): void {
    this.cardNumber.unmount();
    this.cardExpiry.unmount();
    this.cardCvc.unmount();

    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  }

  private buildStripeElements() {
    this.loading = true;

    this.cardNumber = elements.create('cardNumber', {style});
    this.cardNumber.mount(this.cardNumberElement.nativeElement);

    this.cardExpiry = elements.create('cardExpiry', {style});
    this.cardExpiry.mount(this.cardExpiryElement.nativeElement);

    this.cardCvc = elements.create('cardCvc', {style});
    this.cardCvc.mount(this.cardCvcElement.nativeElement);

    this.cardNumber.addEventListener('change', ({error, brand, complete}) => {
      this.cardErrors = error && error.message;
      if (brand) {
        this.brandClass = getBrandClass(brand);
      }
      this.isEnteringInfo = true;
      this.validCreditCard = complete;

    });

    [this.cardExpiry, this.cardCvc]
      .forEach(el => {
        el.addEventListener('change', ({error}) => {
          this.cardErrors = error && error.message;
        });
      });

    [this.cardNumber, this.cardExpiry, this.cardCvc].forEach(el => {
      el.on('ready', () => {
        this.loading = false;
      });
    });
  }

  public async chargeCreditCard(charge: Charge) {
    this.loading = true;
    const {source, error} = await stripe.createSource(this.cardNumber, {owner: {name : this.cardNameOn}});
    const token = source.id;
    const processor = 'stripe';
    const anonymous = charge.anonymous || false;

    if (error) {
      const cardErrors = error.message;
      console.log(cardErrors);
      this.loading = false;

    } else {
      await this.profiles.getUserProfile().toPromise();

      charge = {
        ...charge,
        processor,
        token,
        anonymous,
      };

      const orderPaid = await this.shop.createAndChargeOrder(charge).toPromise() as any;

      this.loading = false;

      if (orderPaid.success) {
        return true;
      } else {
        throw new Error(orderPaid.message);
      }
    }
  }
}
