<div class="app-icon">
    <svg width="16px" height="16px" viewBox="0 0 16 12" version="1.1"
         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <path
                d="M15,3 L3,3 C2.1675,3 1.5075,3.6675 1.5075,4.5 L1.5,13.5 C1.5,14.3325 2.1675,15 3,15 L15,15 C15.8325,15 16.5,14.3325 16.5,13.5 L16.5,4.5 C16.5,3.6675 15.8325,3 15,3 L15,3 Z M6.375,11.25 L5.475,11.25 L3.5625,8.625 L3.5625,11.25 L2.625,11.25 L2.625,6.75 L3.5625,6.75 L5.4375,9.375 L5.4375,6.75 L6.375,6.75 L6.375,11.25 L6.375,11.25 Z M10.125,7.695 L8.25,7.695 L8.25,8.535 L10.125,8.535 L10.125,9.48 L8.25,9.48 L8.25,10.3125 L10.125,10.3125 L10.125,11.25 L7.125,11.25 L7.125,6.75 L10.125,6.75 L10.125,7.695 L10.125,7.695 Z M15.375,10.5 C15.375,10.9125 15.0375,11.25 14.625,11.25 L11.625,11.25 C11.2125,11.25 10.875,10.9125 10.875,10.5 L10.875,6.75 L11.8125,6.75 L11.8125,10.1325 L12.66,10.1325 L12.66,7.4925 L13.5975,7.4925 L13.5975,10.125 L14.4375,10.125 L14.4375,6.75 L15.375,6.75 L15.375,10.5 L15.375,10.5 Z"
                id="t-path-new"></path>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-923.000000, -267.000000)">
                <g transform="translate(902.000000, 125.000000)">
                    <g transform="translate(20.000000, 139.000000)">
                        <g>
                            <g>
                                <polygon id="a" points="0 0 18 0 18 18 0 18"></polygon>
                                <mask fill="white">
                                    <use xlink:href="#t-path-new"></use>
                                </mask>
                                <use fill="#D0D0D0" xlink:href="#t-path-new"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</div>
