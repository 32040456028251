import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RecordingService } from './recording.service';
import { ArtistService } from './artist.service';
import { ProfileService } from './profile.service';
import { PlaylistService } from './playlist.service';
import { ContestService } from './contest.service';
import { UsersService } from './users.service';
import { Recording } from '@app/models/recording.model';
import { EmailNotificationsService } from '@app/core/services/email-notifications.service';

@Injectable()
export class HelperService {
  constructor(
    private toastr: ToastrService,
    private recordingService: RecordingService,
    private playlistService: PlaylistService,
    private artistService: ArtistService,
    private profileService: ProfileService,
    private usersService: UsersService,
    private contests: ContestService,
    private emailNotificationsService: EmailNotificationsService,
  ) {}

  // recording utils
  favoriteRecording(recording) {
    this.recordingService.fav(recording.id).subscribe(response => {
      if (response.success) {
        recording.favorited = true;
        this.toastr.success('Recording was added to favorites.');
      } else {
        this.toastr.error('There was an error, try again later.');
      }
    });
  }

  unfavoriteRecording(recording) {
    this.recordingService.unfav(recording.id).subscribe(response => {
      if (response.success) {
        recording.favorited = false;
        this.toastr.success('Recording was removed from favorites.');
      } else {
        this.toastr.error('There was an error, try again later.');
      }
    });
  }

  updateSubscribedUsers(userId, flag, recordingList = []) {
    recordingList.forEach(list => {
      list.forEach(item => {
        if (item.users[0].id === userId) {
          item.users[0].notified = flag;
        }
      });
    });
  }

  async subscribeUser(recording, recordingList) {
    return new Promise(async (resolve, reject) => {
      try {
        await this.usersService.subscribeUser(recording.users[0]).toPromise();
        recording.users[0].notified = true;
        this.updateSubscribedUsers(recording.users[0].id, true, recordingList);
        this.toastr.success('Subscribed to the user.');
        if(recording.users[0].newsletter){
         // this.emailNotificationsService.emailFavoriteNotification(recording.users[0].email, recording.users[0].screen_name, this.profileService.user.screen_name)
          this.emailNotificationsService.emailFavoriteNotificationV2(recording.users[0].email, recording.users[0].screen_name, this.profileService.user.screen_name, this.profileService.user.id,this.profileService.user.photo.replace('https://', ''))
        }
        resolve(recording.users[0]);
      } catch (err) {
        this.toastr.error('Failed to subscribe to the user.');
        reject();
      }
    });
  }

  async unsubscribeUser(recording, recordingList) {
    try {
      await this.usersService.unsubscribeUser(recording.users[0]).toPromise();
      recording.users[0].notified = false;
      this.updateSubscribedUsers(recording.users[0].id, false, recordingList);
      this.toastr.success('Unsubscribed from the user.');
    } catch (err) {
      this.toastr.error('Failed to unsubscribe to the user.');
    }
  }

  toggleFavRecording(recording: Recording) {
    if (recording.favorited) {
      this.unfavoriteRecording(recording);
    } else {
      this.favoriteRecording(recording);
    }
  }

  favoriteArtist(artist) {
    this.artistService.favorite(artist.id).subscribe(response => {
      if (response.success) {
        artist.favorited = true;
        this.toastr.success('Artist was added to favorites.');
      } else {
        this.toastr.error('There was an error, try again later.');
      }
    });
  }

  unfavoriteArtist(artist) {
    this.artistService.unfavorite(artist.id).subscribe(response => {
      if (response.success) {
        artist.favorited = false;
        this.toastr.success('Artist was removed from favorites.');
      } else {
        this.toastr.error('There was an error, try again later.');
      }
    });
  }

  favoriteMember(member) {
    this.profileService.favoriteMember(member.id).subscribe(response => {
      if (response.success) {
        member.favorited = true;
        this.toastr.success('Member was added to favorites.');
      } else {
        this.toastr.error('There was an error, try again later.');
      }
    });
  }

  unfavoriteMember(member) {
    this.profileService.unfavoriteMember(member.id).subscribe(response => {
      if (response.success) {
        member.favorited = false;
        this.toastr.success('Member was removed from favorites.');
      } else {
        this.toastr.error('There was an error, try again later.');
      }
    });
  }

  favoriteMemberWithFrom(member, from_member) {
    this.profileService.favoriteMember(member.id).subscribe(response => {
      if (response.success) {
        member.favorited = true;
        this.toastr.success('Member was added to favorites.');
        if(member.newsletter){
         // this.emailNotificationsService.emailFavoriteNotification(member.email, member.screen_name, from_member.screen_name)
          this.emailNotificationsService.emailFavoriteNotificationV2(member.email, member.screen_name, from_member.screen_name, from_member.id,from_member.photo.replace('https://', ''))
        }
      } else {
        this.toastr.error('There was an error, try again later.');
      }
    });
  }

  // playlist utils
  favoritePlaylist(playlist) {
    this.playlistService.favorite(playlist.id).subscribe(response => {
      if (response.success) {
        playlist.favorited = true;
        this.toastr.success('Playlist was added to favorites.');
      } else {
        this.toastr.error('There was an error, try again later.');
      }
    });
  }

  unfavoritePlaylist(playlist) {
    this.playlistService.unfavorite(playlist.id).subscribe(response => {
      if (response.success) {
        playlist.favorited = false;
        this.toastr.success('Playlist was removed from favorites.');
      } else {
        this.toastr.error('There was an error, try again later.');
      }
    });
  }

  acceptContestCandidate(id) {
    return this.contests.acceptCandidate(id).subscribe(response => {
      if (response.success) {
        this.toastr.success('Candidate was accepted.');
        this.contests.updateAcceptCandidate(response);
      } else {
        this.toastr.success('Candidate was rejected.');
      }
    });
  }

  rejectContestCandidate(id) {
    return this.contests.rejectCandidate(id).subscribe(response => {
      if (response.success) {
        this.toastr.success('Candidate was rejected.');
        this.contests.updateRejectCandidate(response);
      } else {
        this.toastr.success('Candidate was accepted.');
      }
    });
  }
}
