<div class="ssb-header">
    <div class="ssb-navbar">
                <span class="singsnap_logo" [routerLink]="['']">
                        <img src="../../../../../assets/pictures/logo.png" alt="logo" class="logo"><span class="_singsnap">SingSnap</span>
                    </span>
        <span class="ssb-actions pull-right"><img (click)="isHamburgerVisible = !isHamburgerVisible"
                                                  [src]="isHamburgerVisible ? '../../../../../assets/pictures/webSite/menu_black.png' : '../../../../../assets/pictures/webSite/menu.png'"
                                                  alt="burger" class="burger_menu"></span>
        <!-- <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                                                                     [routerLink]="['/sign-up']">GET ACCESS</a></span>
        <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                                                                     [routerLink]="['/login']">LOGIN</a></span> -->
    </div>
    <div (mouseout)="hideHamburger($event)" class="hamburger" *ngIf="isHamburgerVisible">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="ssb-navbar">

                    <span class="ssb-actions pull-right"><img (click)="isHamburgerVisible = !isHamburgerVisible"
                                                              [src]="isHamburgerVisible ? '../../../../../assets/pictures/webSite/menu_black.png' : '../../../../../assets/pictures/webSite/menu.png'"
                                                              alt="burger" class="burger_menu"></span>
                    <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                                                                                 [routerLink]="['/sign-up']">SIGN UP</a></span>
                    <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                                                                                 [routerLink]="['/login']">LOGIN</a></span>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 menu_content">
                <span [routerLink]="['/main/home']"><span class="fat_line"></span> home</span><br>
                <span [routerLink]="['/main/aboutUs']"><span class="fat_line"></span> about us</span><br>
                <span [routerLink]="['/main/pricing']"><span class="fat_line"></span> pricing</span><br>
                <span [routerLink]="['/main/blog']"><span class="fat_line"></span> blog</span><br>
                <span [routerLink]="['/dashboard']"><span class="fat_line"></span> app</span><br>
                <!-- hidding store from website -->
                <!-- <span [routerLink]="['/main/shop']"><span class="fat_line"></span> store</span><br> -->
                <span [routerLink]="['/main/support']"><span class="fat_line"></span> support</span><br>
                <span [routerLink]="['/main/influencer']"><span class="fat_line"></span> influencer</span><br>
                <span (click)="onLogOut()" *ngIf="userIsLoged"><span class="fat_line"></span> log out</span><br>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 social-links">
                <img src="../../../../../assets/pictures/webSite/hamburger_facebook.png" alt="">
                <img src="../../../../../assets/pictures/webSite/hamburger_youtube.png" alt="">
                <img src="../../../../../assets/pictures/webSite/hamburger_twitter.png" alt="">
                <img src="../../../../../assets/pictures/webSite/hamburger_instagram.png" alt="">
            </div>
        </div>
    </div>
    <!-- <div class="container ssb-heading col-md-6 col-md-offset-6" [@routeAnimation]=""> -->
    <div class="container ssb-heading col-md-6 col-md-offset-6">
        <p>SingSnap for influencers & artists</p>
        <h1>YOU +</h1>
        <h1>SingSnap</h1>
        <p class="your_influence">Your influence gets rewarded on our amazing platform</p>
        <button type="submit"
                class="btn cl_sing_now" [routerLink]="['/main/home']">START NOW
        </button>
    </div>
</div>
<div class="bring-the-fans">
    <div class="padding text-center cl_animateHeder">
        <!-- <div  [scrollimate]="influencerAnimations.firstBlock" [@showFromTop]="influencerAnimations.firstBlock.currentState"> -->
        <div>
            <h1>You bring the fans,</h1>
            <h1>We'll do everything else</h1>
        </div>
    </div>
    <!-- <div class="padding row width-fix" [scrollimate]="influencerAnimations.secondBlock" [@showFromLeft]="influencerAnimations.secondBlock.currentState">         -->
    <div class="padding row width-fix">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 clAnimateImgCont">
            <img class="img-fluid cl_animate" src="../../../../assets/pictures/webSite/Influencer-picture1.png"/>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 be-famous clAnimateImgCont">
            <div class="cl_animate">
                <h2>Be &#8220;famous&#8221;</h2>
                <h2 class="not-bold">In the SingSnap world</h2>
                <h2 class="not-bold">and beyond</h2>
                <p class="title-space">Connect with your fans, see who's loving your SingSnap and grow your social presence big time with expoert help from our music team.</p>
            </div>
        </div>
    </div>
    <!-- <div class="padding row width-fix" [scrollimate]="influencerAnimations.thirdBlock" [@showFromLeft]="influencerAnimations.thirdBlock.currentState"> -->
    <div class="padding row width-fix">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 behind-influencer clAnimateImgCont">
            <div class="cl_animate">
                <h2>Behind every great influencer</h2>
                <h2 class="not-bold">There's an even better team</h2>
                <p class="title-space">As you grow, you'll have a one-on-one relationship with our music team.
                    You'll be taken care of - from monitoring your activity to being apart of product
                    development and spikes in SingSnap activity.</p>
            </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 clAnimateImgCont">
            <img class="img-fluid cl_animate" src="../../../../assets/pictures/webSite/Influencer-picture2.png"/>
        </div>
    </div>
    <div class="padding text-center awesome cl_animateHeder">
            <!-- <div [scrollimate]="influencerAnimations.forthBlock" [@showFromTop]="influencerAnimations.forthBlock.currentState"> -->
            <div>
                <h1 class="not-bold">So tell us if this sounds</h1>
                 <h1>awesome ...</h1>
            </div>
    </div>
    <!-- <div class="padding row width-fix" [scrollimate]="influencerAnimations.fifthBlock" [@showFromLeft]="influencerAnimations.fifthBlock.currentState"> -->
    <div class="padding row width-fix">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 clAnimateImgCont">
            <img class="img-fluid cl_animate" src="../../../../assets/pictures/webSite/Influencer-picture3.png"/>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 awesome be-famous clAnimateImgCont">
            <div class="cl_animate">
                <p class="highlighted">Putting yourself smack dab in front of thousand of music lovers</p>
                <p>Reaping lucrative rewards for your time</p>
                <p>Being an ambasador for an awesome & diverse community</p>
            </div>
        </div>
    </div>
</div>
<!-- <div class="row artists" [scrollimate]="influencerAnimations.sixthBlock"> -->
<div class="row artists">
    <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" [@fadeIn]="influencerAnimations.sixthBlock.currentState"> -->
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <h1>Who's reppin SingSnap?</h1>
        <button type="submit"
                class="btn cl_sing_now" [routerLink]="['/main/home']">APPLY TO BE A SINGSNAP ARTIST
        </button>
    </div>
    <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" [@fadeIn]="influencerAnimations.sixthBlock.currentState"> -->
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <p class="text">SingSnap influencers are some of the most creative people around -
            they've got style, influence and a unique vibe that makes them the
            perfect fit for our community.</p>
    </div>
</div>
<!-- <div class="row artists-pictures" [scrollimate]="influencerAnimations.imagesBlock" [@rollIn]="influencerAnimations.imagesBlock.currentState"> -->
<div class="row artists-pictures">
    <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 artist-holder">
        <div class="artists-content">
            <img class="artist-image" src="../../../../assets/pictures/webSite/timb_19.png"/>
            <h6>Justin Timberlake</h6>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 artist-holder artists-space-sm">
        <div class="artists-content">
            <img class="artist-image" src="../../../../assets/pictures/webSite/rihanna_19.png"/>
            <h6>Rihanna</h6>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 artist-holder artists-space">
        <div class="artists-content">
            <img class="artist-image" src="../../../../assets/pictures/webSite/kate_19.png"/>
            <h6>Katty Perry</h6>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 artist-holder artists-space">
        <div class="artists-content">
            <img class="artist-image" src="../../../../assets/pictures/webSite/rag_19.png"/>
            <h6>Rag'n'Bone</h6>
        </div>
    </div>
</div>
<!-- <div class="padding text-center next-level" [scrollimate]="influencerAnimations.seventhBlock"> -->
<div class="padding text-center next-level">
    <!-- <div [@fadeInUp]="influencerAnimations.seventhBlock.currentState"> -->
    <div>
        <h1>Your SingSnap Profile is Taken</h1>
        <h1>To The Next Level</h1>
        <h1 class="not-bold">Upload your own hits, your albums, EP's and whatever else you want!</h1>
    </div>
</div>
<!-- <div class="fourth"  [scrollimate]="influencerAnimations.sliderBlock"> -->
<div class="fourth">
    <div class="row">
        <!-- <div class="carousel_container col-xl-12 col-lg-12 col-md-12 col-sm-12" [@flipInY]="influencerAnimations.sliderBlock.currentState"> -->
        <div class="carousel_container col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="clSubCont">
                <div class="carousel_body_about_us">

                    <div class="clBtnCont">
                        <a class="slider-left" href="javascript:void(0);"><i class="fa fa-angle-left"
                                                                             aria-hidden="true"></i></a>
                        <a class="slider-left" href="javascript:void(0);"><i class="fa fa-angle-left"
                                                                             aria-hidden="true"></i></a>

                        <a class="slider-right" href="javascript:void(0);"><i class="fa fa-angle-right"
                                                                              aria-hidden="true"></i></a>
                    </div>

                    <div class="slider-content carousel_pictures">

                        <div class="slider-single">
                            <img class="slider-single-image"
                                 src="../../../../assets/pictures/webSite/Influencer-carousel1.png"
                                 alt="1"/>
                        </div>

                        <div class="slider-single">
                            <img class="slider-single-image"
                                 src="../../../../assets/pictures/webSite/Influencer-carousel1.png"
                                 alt="1"/>
                        </div>

                        <div class="slider-single">
                            <img class="slider-single-image"
                                 src="../../../../assets/pictures/webSite/Influencer-carousel1.png"
                                 alt="2"/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="apply_container text-center" [scrollimate]="influencerAnimations.lastBlock"> -->
<div class="apply_container text-center">
    <!-- <div [@fadeInUp]="influencerAnimations.lastBlock.currentState"> -->
    <div>
        <h1>Ready to take center stage?</h1>
        <h2 class="not-bold">Apply to be our next Singfluencer in a few easy steps</h2>
        <button type="submit"
                class="btn cl_sing_now" [routerLink]="['/main/home']">APPLY TO BE A SINGSNAP ARTIST
        </button>
    </div>
</div>
