<div *ngIf='blueLimitReached' class='blueLimitContainer'>
  <div class='blueFlexContainer'>
    <p>You've reached your maximum number of free recordings as a blue member! Upgrade to GOLD now for unlimited recording!</p>
    <button (click)='purchaseGold()'>Purchase GOLD</button>
    <button (click)='backClicked()'>Go Back</button>

  </div>
</div>
<main  id='recorder' #recorderContainer>
    <main id='loading' *ngIf='!loaded'>
        <article id='singsnapContainer' style='padding-right: 2.5rem; display: flex; flex-direction: row; align-items: center; justify-content: center;'>
          <img src='../../assets/images/recorderlogo.png'> <h2>SINGSNAP</h2>
        </article>
        <div class="vl"></div>
        <h2 class="recording-loading" >Loading... <i class="fa fa-spinner fa-pulse fa-fw" style="font-size: 47px; text-shadow: none;"></i></h2>

    </main>

    <main id='recorderContainer' #recorderContainer>
        <div *ngIf='loaded' #topUI id='topUI'>
            <article id='left' class='left'>
              <button *ngIf="UI.saveOrTryAgainButtons" class="btn btn-lg btn-primary topButtons"  style='background-color: #47cca7; display: flex; align-items: center; justify-content: center;' (click)="tryAgain()">TRY AGAIN</button>
                <app-icon-button color="rgba(255, 255, 255, 0.3)" (click)="backClicked()" *ngIf="!finished">
                    <app-icon-arrow-left color="white"></app-icon-arrow-left>
                </app-icon-button>

            </article>
            <article  id='center'>
                <div  class="ss-r-title-content" >
                    <h2 class="ss-r-title" (mousedown)="toAdmin(song.id)">{{title}}</h2>
                </div>
            </article>
            <article  id='right' class='right'>

                <app-btn-camera [status]="!recorder.recorder.noCam" *ngIf="UI.cameraButton" class="toggleVideo" (click)="toggleCamera()"></app-btn-camera>
                <div class="mmsb-switch ss-switch-holder" *ngIf="UI.mmsbButton">
                  <span class="ss-label">MMSB</span>
                  <label class="ss-custom-switch">
                    <input type="checkbox" class="no-disable-color" [(ngModel)]="mmsb" (change)="onChangeMMSB($event)" [disabled]="disableMMSB" />
                    <span class="ss-slider"></span>
                  </label>
                </div>
                <button *ngIf="UI.saveOrTryAgainButtons" class="btn btn-lg btn-primary topButtons" style='background-color: white; display: flex; align-items: center; justify-content: center;' (click)="upload()">SAVE</button>
                <div class="cam-dropdown" *ngIf="UI.cameraButton && sources">
                  <span (click)='toggleVideoDropDown()'>
                    <app-icon-angle-down color="#a5a5a5" size="18"></app-icon-angle-down>
                  </span>
                  <div class='dropDownMenu' *ngIf='videoDropDown'>
                    <div class="dropdown-item" *ngFor="let cam of sources.video" (click)="changeCamSource(cam.deviceId)">
                      {{cam.label}}
                    </div>
                  </div>
                </div>
            </article>

          </div>
        <section id='videoSection'>
          <main #countdown  style='display: none;' class='countdown'>
            <h2  >{{count}}</h2>
          </main>
          <main #errorMessageContainer style='display: none; color: red; text-align: center;' class='countdown'>
            <h3  #errorMessage ></h3>
          </main>         
            <!-- <canvas #lyricsCanvas id='lyrics' height='300' width='600'>
            </canvas> -->
            <div id='lyrics-container'>
              <div id='grave' class='hidden'></div>
              <main id='lines-container'>
                  <div id='the-grave' class='lyrics'></div>
                  <div id='swipe' class='lyrics'></div>
                  <div id='next-up' class='lyrics'></div>
                  <div id='initial' class='lyrics'></div>
                  <div id='the-womb' class='lyrics'></div>
              </main>
              <div id='womb' class='hidden'></div>
          </div>
          <div class="parts__selector" *ngIf="partSelector">
            <h3>
              SELECT A PART TO SING:
            </h3>
            <p *ngFor="let part of parts | keyvalue" class="duet__part selectable" (click)="selectPart(part.key)"><span [style.color]="part.value">{{part.key}}</span></p>   
            <p class="both-part">LYRICS WHEN <span style="color: Green;">BOTH</span> PEOPLE SING WILL SHOW IN Green</p>
            
          </div>
          <!-- <div #filterForm id='filterMaker' *ngIf='filterMenu'>
              <p *ngFor='let item of filterItems'>{{item.nameStart}}<input (input)="changeFilterItems()" style='width: 34px;' type="text" id="{{item.id}}" value='{{item.value}}'>{{item.nameEnd}}</p>
          </div> -->
            <div id='videoContainer'>
              <app-icon-fullscreen color="white" id='fullscreenButton' (click)="toggleFullscreen()"></app-icon-fullscreen>
              <p id='version'>{{version}}</p>
              <p id='step' style='z-index: 600000'>{{step}}</p>
                <div id='actualVideoContainer'>

                    <canvas #filterCanvas crossOrigin="anonymous" *ngIf='filters' id='filters' height='600' width='600'>
                    </canvas>

                <video #recVideo
                crossOrigin="anonymous"
                id="videoRecorder"
                autoplay="true"
                style='
                  background-image: url(../../assets/images/audio_only.png);
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                  height: 68vh !important;
                  width: 68vh !important;
                  opacity: 0;'
                ></video>
                <video #duetVideo style='display: none; height: 68vh; width: 68vh;'
                poster='../../assets/images/audio_only.png'
                id="duetVideo"
                autoplay="false"
                ></video>
                </div>
            </div>

            <div class="mic-dropdown" *ngIf="UI.micButton" >
                <div class="ss-r-mic-bar-value">
                  <canvas id='micMeter' width="4" height="550"></canvas>
                </div>
                <div id='micButton' (click)='toggleMicDropDown()'>
                  <!-- <app-icon-mic *ngIf="recorder && recorder.micOn" color="#FF5656" size="18"></app-icon-mic> -->
                  <app-icon-mic color="#a5a5a5" size="18"></app-icon-mic>
                  <span >
                    <app-icon-angle-down color="#a5a5a5" size="18"></app-icon-angle-down>
                  </span>
                </div>
                <div class='dropDownMenu' *ngIf='micDropDown'>
                  <div class="dropdown-item" *ngFor="let mic of sources.audio" (click)="changeMicSource(mic.deviceId)">
                    {{mic.label}}
                  </div>
                </div>
              </div>
            <div class="playerOverlay">
              <!-- RECORD SCREEN CONTROL -->
              <div class="playerOverlayButtonContainer">
                <app-icon-button *ngIf="UI.pauseButton" color="white" size="40px" (click)="pause()">
                  <app-icon-pause></app-icon-pause>
                </app-icon-button>
                <app-icon-button *ngIf="UI.resumePracticeButton" color="white" size="40px" (click)="play()">
                  <app-icon-play-line style="margin-left: 3px;"></app-icon-play-line>
                </app-icon-button>
                <app-icon-button *ngIf="UI.stopRecordingButton" color="white" size="40px" (click)="stopRecording()">
                  <h2 style='margin-top: 0.3rem; font-size: 1.7rem'>■</h2>
                </app-icon-button>
              </div>

              <!-- RECORDER TIMELINE -->
              <div class="clock">
                <span class="currentTime">{{msToTime(playhead)}}</span>
                <span class="duration"> / {{msToTime(duration)}}</span>

              </div>
            </div>
            <app-progress-bar *ngIf='loaded' [recorder]='true' [duration]="duration" [noSeek]="recorder.recording" [current]="playhead" (seek)="seek($event)" (range)='changeRangeTime($event)'></app-progress-bar>
        </section>
        <div id='bottomUI' #bottomUI>
          <section id='adjustBarsContainer' *ngIf='containerOpen'>
            <div class="ss-r-adjust-bar" *ngIf='volumeBar'>
              <div class="ss-bar">
                <div class="ss-bar-item" id='micBar' *ngIf="finished">
                  <app-icon-mic size="18" color="white" [style.margin]="'0 3px'"></app-icon-mic>
                  <app-adjust-bar
                    [value]="micVolume"
                    [min]="0" [max]="2" [step]="0.02" [multiply]="5" [noFraction]="true"
                    (changed)="onMicVolumeChange($event)">
                  </app-adjust-bar>
                </div>
                <div class="ss-bar-item">
                  <app-icon-music size="24" color="white"></app-icon-music>
                  <app-adjust-bar
                    [value]="musicVolume"
                    [min]="0" [max]="2" [step]="0.02" [multiply]="5" [noFraction]="true"
                    (changed)="onMusicVolumeChange($event)">
                  </app-adjust-bar>
                </div>
              </div>
              <app-icon-button color="#4FEAAE" size="60px" [style.marginLeft]="'30px'" (click)="onVolumeClose()">
                <app-icon-check size="27" color="white"></app-icon-check>
              </app-icon-button>
            </div>
            <!-- SYNC BAR -->
            <div  id="syncContainer" *ngIf='syncBar'>
            <div class="ss-r-adjust-bar">
              <div class="ss-bar">
                <div class="ss-bar-item">
                  <app-recorder-sync-bar
                    #syncBar
                    [newRecorder] = 'true'
                    style="width: 100%;"
                    [value]="sync"
                    (changed)="onSyncChange($event)">
                  </app-recorder-sync-bar >
                </div>
              </div>
              <app-icon-button color="#4FEAAE" size="60px" [style.marginLeft]="'30px'" (click)="onSyncClose()">
                <app-icon-check size="27" color="white"></app-icon-check>
              </app-icon-button>
            </div>
          </div>
            <div class="ss-r-adjust-bar" *ngIf='pitchBar'>
              <div class="ss-bar">
                <div class="ss-bar-item">
                  <app-icon-pitch size="18" color="white" [style.margin]="'0 3px'"></app-icon-pitch>
                  <app-adjust-bar
                    [value]="pitch"
                    [stepCount]="12"
                    [min]="-6" [max]="6" [step]="1"
                    (changed)="onPitchChange($event)">
                  </app-adjust-bar>
        
                </div>
                </div>
                <app-icon-button color="#4FEAAE" size="60px" [style.marginLeft]="'30px'" (click)="onPitchClose()">
                  <app-icon-check size="27" color="white"></app-icon-check>
                </app-icon-button>
              </div>


              <div class="ss-r-adjust-bar" *ngIf='filterMenu' #filterUI>

                <!-- <div ngbDropdown class="d-inline-block">
                  <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>{{recorder.filters[recorder.filterIndex].name}}</button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button *ngFor='let filter of recorder.filters; let j = index' (click)='changeFilter(j)' ngbDropdownItem>{{filter.name}}</button>
                  </div>
                </div> -->
                <div class='filterMenu'>
                  <button (click)='prevSlide()' style='margin-right: 10px;'>
                    <svg style='transform: rotate(180deg);' xmlns="http://www.w3.org/2000/svg" width="15.162" height="28.253" viewBox="0 0 20.162 33.253">
                      <g id="Component_7_1" data-name="Component 7 – 1" transform="translate(3.536 3.536)">
                        <g id="Group_1" data-name="Group 1" transform="translate(-104.01 430.5) rotate(-90)">
                          <line id="Line_1" data-name="Line 1" x2="13.091" y2="13.091" transform="translate(404.318 104.01)" fill="none" stroke="#292c3e" stroke-linecap="round" stroke-width="5"/>
                          <line id="Line_2" data-name="Line 2" x1="13.091" y2="13.091" transform="translate(417.409 104.01)" fill="none" stroke="#292c3e" stroke-linecap="round" stroke-width="5"/>
                        </g>
                      </g>
                    </svg>
                    
                  </button>
                  <ngx-glide class='filterNgx' [showArrows]='false' [showBullets]='false' [perView]='5' [listenToEvents]='true' (moved)='detectChanges()'>
                    <div *ngFor="let filter of recorder.filters; let j = index" class='filterIcons'>
                      <div>
                        <div [style.border-color]='filter.selected'>
                          <img (click)='changeFilter(j, filter.name)' src='https://singsnap.imgix.net/assets/images/video_fx_preview.jpeg?w=150'
                          style.filter={{filter.preview}}>
                        </div>
                        <p>{{filter.name}}</p>
                      </div>
                    </div>
                  </ngx-glide>
                  <button (click)='nextSlide()' style='padding: 0 0 0 0.5rem;'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.162" height="28.253" viewBox="0 0 20.162 33.253">
                      <g id="Component_7_1" data-name="Component 7 – 1" transform="translate(3.536 3.536)">
                        <g id="Group_1" data-name="Group 1" transform="translate(-104.01 430.5) rotate(-90)">
                          <line id="Line_1" data-name="Line 1" x2="13.091" y2="13.091" transform="translate(404.318 104.01)" fill="none" stroke="#292c3e" stroke-linecap="round" stroke-width="5"/>
                          <line id="Line_2" data-name="Line 2" x1="13.091" y2="13.091" transform="translate(417.409 104.01)" fill="none" stroke="#292c3e" stroke-linecap="round" stroke-width="5"/>
                        </g>
                      </g>
                    </svg>
                    
                  </button>
                </div>

                <app-icon-button color="#4FEAAE" size="60px" [style.marginLeft]="'30px'" (click)="closeFilterMenu()">
                  <app-icon-check size="27" color="white"></app-icon-check>
                </app-icon-button>
              </div>
              <div class="ss-r-adjust-bar" *ngIf='framesMenu' #framesUI>

                <!-- <div ngbDropdown class="d-inline-block">
                  <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>{{recorder.filters[recorder.filterIndex].name}}</button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button *ngFor='let filter of recorder.filters; let j = index' (click)='changeFilter(j)' ngbDropdownItem>{{filter.name}}</button>
                  </div>
                </div> -->
                <div class='filterMenu'>
                  <button (click)='prevSlide()' style='margin-right: 10px;'>
                    <svg style='transform: rotate(180deg);' xmlns="http://www.w3.org/2000/svg" width="15.162" height="28.253" viewBox="0 0 20.162 33.253">
                      <g id="Component_7_1" data-name="Component 7 – 1" transform="translate(3.536 3.536)">
                        <g id="Group_1" data-name="Group 1" transform="translate(-104.01 430.5) rotate(-90)">
                          <line id="Line_1" data-name="Line 1" x2="13.091" y2="13.091" transform="translate(404.318 104.01)" fill="none" stroke="#292c3e" stroke-linecap="round" stroke-width="5"/>
                          <line id="Line_2" data-name="Line 2" x1="13.091" y2="13.091" transform="translate(417.409 104.01)" fill="none" stroke="#292c3e" stroke-linecap="round" stroke-width="5"/>
                        </g>
                      </g>
                    </svg>
                    
                  </button>
                  <ngx-glide class='framesNgx' [showArrows]='false' [showBullets]='false' [perView]='5' [listenToEvents]='true' (moved)='detectChanges()'>
                    <div *ngFor="let frame of framesPaths" class='filterIcons'>
                      <div *ngIf="recorder.frames[frame.name]">
                        <div [style.border-color]='recorder.frames[frame.name].selected'>
                          <img (click)='changeFrame(frame.name)' src='https://singsnap.imgix.net/assets/images/video_fx_preview.jpeg?w=150'>
                          <img class='framesPreview' (click)='changeFrame(frame.name)' src='{{recorder.frames[frame.name].src}}'>
                        </div>
                        <p>{{frame.name}}</p>
                      </div>
                    </div>
                  </ngx-glide>
                  <button (click)='nextSlide()' style='padding: 0 0 0 0.5rem;'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.162" height="28.253" viewBox="0 0 20.162 33.253">
                      <g id="Component_7_1" data-name="Component 7 – 1" transform="translate(3.536 3.536)">
                        <g id="Group_1" data-name="Group 1" transform="translate(-104.01 430.5) rotate(-90)">
                          <line id="Line_1" data-name="Line 1" x2="13.091" y2="13.091" transform="translate(404.318 104.01)" fill="none" stroke="#292c3e" stroke-linecap="round" stroke-width="5"/>
                          <line id="Line_2" data-name="Line 2" x1="13.091" y2="13.091" transform="translate(417.409 104.01)" fill="none" stroke="#292c3e" stroke-linecap="round" stroke-width="5"/>
                        </g>
                      </g>
                    </svg>
                    
                  </button>
                </div>

                <app-icon-button color="#4FEAAE" size="60px" [style.marginLeft]="'30px'" (click)="closeFramesMenu()">
                  <app-icon-check size="27" color="white"></app-icon-check>
                </app-icon-button>
              </div>

              <div class="ss-r-adjust-bar" *ngIf='lyricBar'>
                <div class="ss-bar">
                  <div class="ss-bar-item">
                    <app-adjust-bar
                      [value]="lyricSpeed"
                      [stepCount]="20"
                      [min]="-10" [max]="10" [step]="1"
                      (changed)="onLyricSpeedChange($event)">
                    </app-adjust-bar>
                  </div>
                </div>
                <app-icon-button color="#4FEAAE" size="60px" [style.marginLeft]="'30px'" (click)="onLyricSpeedClose()">
                  <app-icon-check size="27" color="white"></app-icon-check>
                </app-icon-button>
              </div>
          </section>
          <section id='buttonsContainer' *ngIf='!containerOpen'>
            <article  class='buttonsSection left'>
              <div *ngIf='UI.volumeButton' class="ss-r-btn noMargin">
                  <app-icon-button color="#e2fbf4" size="60px" (click)="onVolumeClick()">
                    <app-icon-volumn size="27"></app-icon-volumn>
                  </app-icon-button>
                  <h6 class="ss-r-btn-text">VOLUME</h6>
                </div>
                <div class="ss-r-btn" *ngIf='UI.pitchButton && !jam'>
                  <app-icon-button color="#e2fbf4" size="60px" (click)="onPitchClick()">
                    <app-icon-pitch size="27"></app-icon-pitch>
                  </app-icon-button>
                  <h6 class="ss-r-btn-text">PITCH</h6>
                </div>

                <div class="ss-r-btn" *ngIf='UI.syncButton'>
                  <app-icon-button color="#e2fbf4" size="60px" (click)="onSyncClick()">
                    <app-icon-sync size="27"></app-icon-sync>
                  </app-icon-button>
                  <h6 class="ss-r-btn-text">SYNC</h6>
                </div>
                <div class="ss-r-btn" *ngIf='UI.lyricButton'>
                  <app-icon-button color="#e2fbf4" size="60px" (click)="onLyricSpeedClick()">
                    <svg id="notes_black_24dp" xmlns="http://www.w3.org/2000/svg" width="35.47" height="35.47" viewBox="0 0 35.47 35.47">
                      <path id="Path_29" data-name="Path 29" d="M0,0H35.47V35.47H0Z" fill="none"/>
                      <path id="Path_30" data-name="Path 30" d="M28.124,13.39H4.478a1.478,1.478,0,0,0,0,2.956H28.124a1.478,1.478,0,0,0,0-2.956ZM4.478,23.735H19.257a1.478,1.478,0,0,0,0-2.956H4.478a1.478,1.478,0,0,0,0,2.956ZM28.124,6H4.478A1.482,1.482,0,0,0,3,7.478v.015A1.482,1.482,0,0,0,4.478,8.971H28.124A1.482,1.482,0,0,0,29.6,7.493V7.478A1.482,1.482,0,0,0,28.124,6Z" transform="translate(1.434 2.867)" fill="#121622"/>
                    </svg>
                    
                  </app-icon-button>
                  <h6 style="width: max-content;" class="ss-r-btn-text">LYRIC SPEED</h6>
                </div>
                <div class="ss-r-btn ss-r-btn--record" *ngIf='UI.filterButton && cam'>
                  <app-icon-button color="#e2fbf4" size="60px" (click)="openFilterMenu()">
                    <svg id="Component_1_1" width="27" data-name="Component 1 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.76 28.76"><defs><style>.cls-1{fill:none;}.cls-2{fill:#121622;}</style></defs><g id="filter_none_black_24dp" data-name="filter none black 24dp"><path class="cls-1" d="M-.65.66V29.42H28.11V.66ZM26.91,21a2.39,2.39,0,0,1-2.4,2.4H7.74A2.39,2.39,0,0,1,5.34,21V4.26a2.39,2.39,0,0,1,2.4-2.4H24.51a2.39,2.39,0,0,1,2.4,2.4Z" transform="translate(0.65 -0.66)"/><path id="Path_7" data-name="Path 7" class="cls-2" d="M21.28,25.36H4.5a1.2,1.2,0,0,1-1.2-1.2V7.39a1.2,1.2,0,1,0-2.39,0v18a2.39,2.39,0,0,0,2.4,2.4h18a1.2,1.2,0,0,0,0-2.4Z" transform="translate(0.65 -0.66)"/><path class="cls-2" d="M24.51,1.86H7.74a2.39,2.39,0,0,0-2.4,2.4V21a2.39,2.39,0,0,0,2.4,2.4H24.51a2.39,2.39,0,0,0,2.4-2.4V4.26A2.39,2.39,0,0,0,24.51,1.86ZM17,13.3l-1.78.81a2.11,2.11,0,0,0-1.06,1.06L13.39,17a.72.72,0,0,1-1.31,0l-.81-1.78a2.14,2.14,0,0,0-1.06-1.06L8.42,13.3a.72.72,0,0,1,0-1.31l1.79-.81a2.14,2.14,0,0,0,1.06-1.06l.81-1.79a.72.72,0,0,1,1.31,0l.81,1.79a2.11,2.11,0,0,0,1.06,1.06L17,12A.72.72,0,0,1,17,13.3Zm6.56,5.37-.6.27a2.38,2.38,0,0,0-1.2,1.2l-.26.58a.41.41,0,0,1-.74,0l-.27-.59a2.38,2.38,0,0,0-1.2-1.2l-.58-.27a.4.4,0,0,1,0-.73l.59-.27a2.38,2.38,0,0,0,1.2-1.2l.26-.59a.41.41,0,0,1,.74,0l.27.59a2.42,2.42,0,0,0,1.2,1.2l.59.27A.4.4,0,0,1,23.6,18.67Zm0-11.31L23,7.63a2.4,2.4,0,0,0-1.2,1.19l-.27.59a.41.41,0,0,1-.74,0l-.27-.59a2.38,2.38,0,0,0-1.2-1.2l-.58-.27a.4.4,0,0,1,0-.73l.59-.27a2.38,2.38,0,0,0,1.2-1.2l.27-.59a.4.4,0,0,1,.73,0l.27.59A2.42,2.42,0,0,0,23,6.36l.59.27A.4.4,0,0,1,23.6,7.36Z" transform="translate(0.65 -0.66)"/></g></svg>
                  </app-icon-button>
                  <h6 class="ss-r-btn-text">FILTERS</h6>
                </div>
                <div class="ss-r-btn ss-r-btn--record" *ngIf='UI.framesButton && cam && framesLoaded'>
                  <app-icon-button color="#e2fbf4" size="60px" (click)="openFramesMenu()">
                    <svg style="
                    margin-left: 0.05rem;
                    margin-bottom: 0.2rem;
                " id="Component_6_1" data-name="Component 6 – 1" xmlns="http://www.w3.org/2000/svg" width="27" viewBox="0 0 24 32">
                      <g id="Rectangle_7" data-name="Rectangle 7" transform="translate(0 8)" fill="none" stroke="#121622" stroke-width="3">
                        <rect width="24" height="24" rx="4" stroke="none"/>
                        <rect x="1.5" y="1.5" width="21" height="21" rx="2.5" fill="none"/>
                      </g>
                      <g id="Polygon_1" data-name="Polygon 1" transform="translate(7)" fill="none">
                        <path d="M3.179,4.006a2,2,0,0,1,3.641,0L8.715,8.172A2,2,0,0,1,6.894,11H3.106A2,2,0,0,1,1.285,8.172Z" stroke="none"/>
                        <path d="M 4.99998950958252 4.833210945129395 L 4.999999523162842 4.833220481872559 L 3.106009483337402 9 C 3.106009483337402 9 3.106009960174561 9 3.106009483337402 9 L 6.893989086151123 9 C 6.893989086151123 9 6.893989562988281 9 6.893989562988281 9 L 4.99998950958252 4.833210945129395 M 4.999999523162842 2.833217620849609 C 5.732729434967041 2.833217620849609 6.465459823608398 3.224015235900879 6.82072925567627 4.005610466003418 L 8.714719772338867 8.172389984130859 C 9.316629409790039 9.496590614318848 8.348569869995117 11 6.893989562988281 11 L 3.106009483337402 11 C 1.651429176330566 11 0.6833696365356445 9.496590614318848 1.285279273986816 8.172389984130859 L 3.179269313812256 4.005610466003418 C 3.534539699554443 3.224015235900879 4.267269611358643 2.833217620849609 4.999999523162842 2.833217620849609 Z" stroke="none" fill="#121622"/>
                      </g>
                    </svg>
                  </app-icon-button>
                  <h6 class="ss-r-btn-text">FRAMES</h6>
                </div>
            </article>
            <article  class='buttonsSection right'>
                  <div class="ss-r-btn ss-r-btn--record" *ngIf='UI.restartButton' (click)="tryAgain()">
                    <app-icon-button color="#e2fbf4" size="60px">
                      <app-icon-restart size="27"></app-icon-restart>
                    </app-icon-button>
                    <h6 class="ss-r-btn-text">RESTART</h6>
                  </div>

              <div class="ss-r-btn ss-r-btn--record" *ngIf='UI.recordButton'>
                  <app-icon-button color="#F94678" size="60px" (click)="startCountdown()">
                    <span class="icon-record-dot"></span>
                  </app-icon-button>
                  <h6 class="ss-r-btn-text">RECORD</h6>
                </div>

                <div class="ss-r-btn ss-r-btn--play" *ngIf='UI.reviewButton'>
                  <app-icon-button color="#56a26d" size="60px" (click)="stopRecording()">
                    <app-icon-arrow-right color="#04071c" size="27"></app-icon-arrow-right>
                  </app-icon-button>
                  <h6 class="ss-r-btn-text">REVIEW</h6>
                </div>
                <div class="ss-r-btn ss-r-btn--play" *ngIf='UI.practiceButton' style='position: relative;'>
                  <app-icon-button color="#3aa7a7" size="60px" (click)="play()">
                    <app-icon-play-line size="24" style="margin-left: 5px;"></app-icon-play-line>
                  </app-icon-button>
                  <h6 class="ss-r-btn-text">PRACTICE</h6>
                  <div (click)='onOtherSingers()' id='otherSingers'>
                    <span>Hear how others sing this</span>
                    <app-icon-play color="white"></app-icon-play>
                  </div>
                </div>

          </article>
          </section>
        </div>
        <app-other-singers #otherSingers></app-other-singers>
    </main>

    
</main>