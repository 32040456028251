<div *ngIf="thread" class="ss-thread">
  <app-message-board-thumb [thumbnailSrc]="thread.cover">
  </app-message-board-thumb>
  <div class="ss-thread-item">
    <h3>{{ thread.name || thread.title }}</h3>
    <p
      class="ss-truncate"
      *ngIf="
        type !== 'topic' &&
          type !== 'favorite' &&
          type !== 'recent' &&
          type !== 'innercircle';
        else replyTemplate
      "
    >
      {{ thread.description }}
    </p>
    <ng-template #replyTemplate>
      <p class="ss-truncate">{{ thread.threads_count || thread.replies_count || 0 }} Replies</p>
    </ng-template>
  </div>
</div>
