import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-headphone-on',
  templateUrl: './icon-headphone-on.component.html',
  styleUrls: ['./icon-headphone-on.component.scss']
})
export class IconHeadphoneOnComponent implements OnInit {
  @Input() color = '#262628';
  @Input() size = 18;

  constructor() { }

  ngOnInit() {
  }

}
