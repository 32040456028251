<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header align-items-center">
    <h4 class="modal-title" id="modal-basic-title">
    {{title}}
      <i class="fa fa-spinner fa-pulse" *ngIf="loading"></i>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
      <span aria-hidden="true" [inlineSVG]="'/assets/svgs/close.svg'"></span>
    </button>
  </div>
  <div class="modal-body">
    <app-search-bar-input (onSearch)="onSearch($event)"></app-search-bar-input>
    <div class="users-list-holder" *ngIf="members?.length != 0; else noResults ">
      <div class="users-list" [perfectScrollbar]="config">
        <div
          *ngFor="let user of members" class="user-info"
          [ngClass]="{'user-invalid': isInvalidUser(user)}"
          (click)="selectUser(user)"
        >
          <app-ss-user 
            [user]="user" [showStatus]="false" [showLevel]="false" [noLink]="true"
            [isSmall]="true" [disabled]="true"
          >
          </app-ss-user>
          <div class="ss-check-icon" [ngClass]="{'active': selectedIds.includes(user.id)}">
            <app-icon-check color="white" size="10" *ngIf="selectedIds.includes(user.id)"></app-icon-check>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noResults>
      <div class="no-results" >
        <p>No results found.</p>
      </div>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" [disabled]="selectedIds.length === 0" (click)="d('Save click')">{{titleButton}}</button>
  </div>
</ng-template>

<app-warning-modal #confirmation></app-warning-modal>