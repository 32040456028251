<div class="session-page">
  <div class="session-form">
    <h3>Password Reset</h3>
    <div class="session-help-text">
      <span>Enter the address associated with your SingSnap Account. You will receive an email with a link to reset your password.</span>
    </div>
    <form [formGroup]="pwdResetForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="email">EMAIL</label>
        <input name="email" type="email" class="form-control" id="email" formControlName="email" required>
        <span class="input-error" *ngIf="errMsg != ''">{{errMsg}}</span>
        <span class="input-error" style = 'color: green;' *ngIf="sccssMsg != ''">{{sccssMsg}}</span>

      </div>
      <div class="session-contact">
        <a class="backtologin" [routerLink]="'/login'">Back to login</a> 
      </div>
      <button *ngIf='success == false' type="submit" class="btn btn-primary">SEND</button>

    </form>

    <div class="session-contact">
      Contact <a href="mailto:support@singsnap.com">support@singsnap.com</a> if you forgot the email address.
    </div>

  </div>
  <div class="site-info-slider">
    <app-session-carousel></app-session-carousel>
  </div>
</div>
