import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-social-fb-messenger',
  templateUrl: './icon-social-fb-messenger.component.html',
  styleUrls: ['./icon-social-fb-messenger.component.scss']
})
export class IconSocialFbMessengerComponent implements OnInit {
  @Input() size = 58;

  constructor() { }

  ngOnInit() {
  }

}
