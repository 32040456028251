import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry,  } from 'ngx-file-drop';

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss']
})
export class DropzoneComponent implements OnInit {
  public coverFiles: NgxFileDropEntry[] = [];
  coverFile = null;
  coverChanged = false;
  coverUrlTemp: any = '';
  @ViewChild('container') private container: ElementRef;

  @Input() cover = '';
  @Input() title = 'Cover Image';
  @Input() preview = false;
  @Output() onFileDropped = new EventEmitter<any>();
  @Output() onFilePicked = new EventEmitter<any>();

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  get style() {
    const cover = this.cover || this.coverUrlTemp;
    if (cover) {
      return {'background-image': 'url(' + cover + ')'};
    } else {
      return {};
    }
  }

  public dropped(files: NgxFileDropEntry[]) {
    console.log('dropped');
    this.coverFiles = files;
    this.coverFile = files[0];

    // Is it a file?
    if (this.coverFile.fileEntry.isFile) {
      this.coverChanged = true;
      const fileEntry = this.coverFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.getFileUrl(file)
          .then(fileUrl => {
            this.onFileDropped.emit({file: file, fileUrl: fileUrl});
          });
      });
    } else {
      // It was a directory (empty directories are added, otherwise only files)
      //const fileEntry = this.coverFile.fileEntry as FileSystemDirectoryEntry;
    }
  }

  openFilePicker() {
    console.log('openFilePicker');
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    this.coverChanged = true;
    // Listen upload local image and save to server
    input.onchange = () => {
      console.log('onChange', input.files);
      const file = input.files[0];
      this.getFileUrl(file)
        .then(fileUrl => {
          this.onFilePicked.emit({file: file, fileUrl: fileUrl});
        });
    };
  }

  getFileUrl(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      console.log('filereader promise');
      reader.onload = (e) => {
        console.log('onload e', e);
        this.coverUrlTemp = reader.result;
        console.log('onload', this.coverUrlTemp);

        if (this.preview) {
          this.showPreview();
        }
        console.log(this.cover);
        resolve(this.coverUrlTemp);

      };

      reader.onerror = (e) => {
        console.log('reader error', e);
      };

      reader.readAsDataURL(file);
    });
  }

  showPreview() {
    this.cover = this.coverUrlTemp;
    this.container.nativeElement.style.backgroundImage = 'url(' + this.cover + ')';
    this.cdr.detectChanges();
    console.log('showing preview', this.cover, this.coverUrlTemp);
  }

  clearPreview() {
    console.log('clear preview ?');
    this.cover = '';
    this.container.nativeElement.style.removeProperty('background-image')
    this.cdr.detectChanges();
    
  }

  clearFile() {
    this.clearPreview();
    this.onFileDropped.emit({file: null, fileUrl: null});
    this.onFilePicked.emit({file: null, fileUrl: null});
  }
}
