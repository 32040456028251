import {Component, OnInit, HostBinding} from '@angular/core';
import {AuthenticationService} from '@app/core';
import { Cookie } from 'ng2-cookies';


// import { trigger, state, style, animate, transition } from '@angular/animations';
// import { ScrollimateService } from "ng2-scrollimate";

@Component({
    selector: 'app-support-page',
    templateUrl: './support-page.component.html',
    styleUrls: ['./support-page.component.scss']
    // animations: [
    //     trigger('routeAnimation', [
    //         state('*', style({transform: 'translateY(0)', opacity: 1})),
    //         transition('void => *', [
    //             style({transform: 'translateY(100px)', opacity: 0}),
    //             animate('0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000)')
    //         ]),
    //         transition('* => void',
    //             animate('0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000)', style({
    //                 transform: 'translateX(100px)',
    //                 opacity: 0
    //             }))
    //         )
    //     ])
    // ]
})
export class SupportPageComponent implements OnInit {
    isHamburgerVisible = false;
    faq: string[] = [
        'Lorem ipsum dolor sit amet, consecteuer adipiscing',
        'Claritas est etiam processus dynamicus, qui sequitur mutatuionem',
        'Mirum est notare quam littera gothica, quam nunc putamus parum',
        'Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum',
        'Duis autem vel eum iriure dolor in hendrerit',
        'Et wisi enim ad minim veniam, quis nostrud exerci'
    ];
    userIsLoged = false;
    active: number;

    //animations
    // @HostBinding('@routeAnimation') routeAnimation = true;
    // supportAnimations: any = {};


    // constructor(private authService: AuthenticationService, private scrollimateService: ScrollimateService) {
    constructor(private authService: AuthenticationService) {

        let is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
        const is_explorer = navigator.userAgent.indexOf('MSIE') > -1;
        const is_firefox = navigator.userAgent.indexOf('Firefox') > -1;
        let is_safari = navigator.userAgent.indexOf("Safari") > -1;
        const is_opera = navigator.userAgent.toLowerCase().indexOf("op") > -1;
        if ((is_chrome) && (is_safari)) {
            is_safari = false;
        }
        if ((is_chrome) && (is_opera)) {
            is_chrome = false;
        }
        // if (!is_safari) {
            // this.supportAnimations = {
            //     defaultStates: [
            //         {
            //             method: "pxElement",
            //             value: 200,
            //             state: "active",
            //         },
            //         {
            //             method: "default",
            //             state: "inactive",
            //         }
            //     ]
            // };
        // }
       // scrollimateService.setDefaultStates(this.supportAnimations.defaultStates);
    }

    ngOnInit() {
        const token = Cookie.get('AuthToken');
        this.userIsLoged = !!token;
    }

    selectQuestion(index: number) {
        this.active = index;
    }

    onLogOut() {
        this.authService.onLogOut();
    }

    hideHamburger(event) {
        const currentTarget = event.currentTarget;
        let toElement = event.toElement;
        if (navigator.userAgent.indexOf('Firefox') !== -1) {
            toElement = event.relatedTarget;
        }
        if (currentTarget !== toElement && !this.isDescendant(currentTarget, toElement)) {
            this.isHamburgerVisible = false;
        }

    }

    isDescendant(parent, child) {
        let node = child && child.parentNode;
        while (node != null) {
            if (node === parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

}
