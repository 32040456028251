import SuperPlayer from "./superpoweredPlayer26829.js";
import SSRecorder from "./recorder26829.js";
import LyricsPlayer from "./LyricsPlayer26829.js";
export default class SSKaraoke {
  constructor(workletPath, wasmPath, video, duetVideo, song, filterCanvas, framesPaths) {
    this.workletPath = workletPath;
    this.filterCanvas = filterCanvas;
    this.wasmPath = wasmPath;
    let duetData = {duet: song.duet, parts: song.parts};
    this.lyricsPlayer = new LyricsPlayer(song.lyrics, !song.isCS, duetData);
    this.video = video;
    this.song = song;
    this.duetVideo = duetVideo;
    this.duetVideo.muted = true;
    this.jam = song.jam;
    this.selectedFrame = {
      name: "None",
      path: "nadda",
      promo: false,
      selected: "#4FEAAE"
    };
    this.frames = {};
    framesPaths.forEach((frame) => {
      this.frames[frame.name] = {};
      this.frames[frame.name].name = frame.name;
      if (frame.name == "None") {
        this.frames[frame.name].selected = "#4FEAAE";
        this.frames[frame.name].promo = frame.promo;
      } else {
        this.frames[frame.name].image = new Image();
        this.frames[frame.name].image.crossOrigin = "anonymous";
        this.frames[frame.name].image.src = frame.path;
        this.frames[frame.name].src = frame.path;
        this.frames[frame.name].selected = "#292C3E";
        this.frames[frame.name].promo = frame.promo;
      }
    });
    console.log("recorder frames will follow:");
    console.log(this.frames);
    this.filters = [
      {name: "None", value: "none", selected: "#4FEAAE"},
      {name: "Beautify", value: "blur(2px) brightness(0.8)", preview: "blur(0.3px) brightness(1.4)", selected: "#292C3E"},
      {name: "Art Deco", value: "blur(0.6px) brightness(1.2) contrast(160%) saturate(90%) hue-rotate(20deg)", preview: "blur(0.1px) brightness(1.2) contrast(160%) saturate(90%) hue-rotate(20deg)", selected: "#292C3E"},
      {name: "Alien", value: "saturate(400%) contrast(100%) grayscale(0%) hue-rotate(90deg) invert(0%) brightness(1.2) sepia(0%)", preview: "saturate(500%) contrast(100%) grayscale(0%) hue-rotate(180deg) invert(0%) brightness(1.2) sepia(0%)", selected: "#292C3E"},
      {name: "Stagecoach", value: "blur(0.8px) brightness(1.1) contrast(130%) grayscale(100%) sepia(70%)", preview: "blur(0.13px) brightness(1.1) contrast(130%) grayscale(100%) sepia(70%)", selected: "#292C3E"},
      {name: "Electrified", value: "blur(1px) brightness(4) contrast(500%) saturate(100%) grayscale(100%) sepia(100%) invert(100%)", preview: "blur(0.16px) brightness(4) contrast(500%) saturate(100%) grayscale(100%) sepia(100%) invert(100%)", selected: "#292C3E"},
      {name: "Morning Glow", value: "blur(1.5px) brightness(1.2) contrast(110%) saturate(150%) hue-rotate(700deg) sepia(70%)", preview: "blur(0.25px) brightness(1.2) contrast(110%) saturate(150%) hue-rotate(700deg) sepia(70%)", selected: "#292C3E"},
      {name: "Gothic Comic", value: "blur(1.2px) brightness(1.3) contrast(900%) saturate(40%) hue-rotate(900deg) grayscale(40%) sepia(60%) invert(20%)", preview: "blur(0.2px) brightness(1.3) contrast(900%) saturate(40%) hue-rotate(900deg) grayscale(40%) sepia(60%) invert(20%)", selected: "#292C3E"},
      {name: "Threshold", value: "saturate(300%) contrast(500%) grayscale(0%) hue-rotate(0deg) invert(0%) brightness(1.5) sepia(0%)", preview: "saturate(300%) contrast(500%) grayscale(0%) hue-rotate(0deg) invert(0%) brightness(1.5) sepia(0%)", selected: "#292C3E"},
      {name: "Dark Romantic", value: "blur(1.6px) brightness(1.0) contrast(250%) saturate(100%) hue-rotate(15deg) grayscale(60%) invert(5%)", preview: "blur(0.25px) brightness(1.0) contrast(250%) saturate(100%) hue-rotate(15deg) grayscale(60%) invert(5%)", selected: "#292C3E"},
      {name: "Antiqued Black and White", value: "blur(1.5px) brightness(1.7) contrast(100%) saturate(20%) hue-rotate(900deg) grayscale(20%) sepia(30%)", preview: "blur(0.25px) brightness(1.7) contrast(100%) saturate(20%) hue-rotate(900deg) grayscale(20%) sepia(30%)", selected: "#292C3E"}
    ];
    this.filterIndex = 0;
    this.filterString = "saturate(100%) contrast(100%) grayscale(0%) hue-rotate(0deg) invert(0%) brightness(1) sepia(0%)";
    this.pitch = 0;
    this.musicVolume = 1;
    this.vocalVolume = 1;
    this.playing = false;
    this.loaded = false;
    this.sources = {};
    this.part;
    this.playing = false;
    this.superPlayer;
    this.events = {};
    this.duration;
    this.recording = false;
    this.mode = "recorder";
    this.sampleRate;
    this.filterInterval;
  }
  init() {
    let ctx = new AudioContext();
    this.sampleRate = ctx.sampleRate;
    ctx = null;
    this.video.muted = true;
    this.superPlayer = new SuperPlayer(this.workletPath, this.wasmPath, this.sampleRate);
    this.recorder = new SSRecorder(this.video, this.filterCanvas);
    this.hookUpEvents();
    this.superPlayer.init();
    this.lyricsPlayer.init();
    this.recorder.getMedia();
    if (this.song.duet) {
      if (this.song.parts) {
        this.dispatchEvent("parts_selected");
      } else {
        this.dispatchEvent("parts_selected");
      }
    } else {
    }
  }
  hookUpEvents() {
    this.superPlayer.addEventListener("superPlayerReady", () => {
      if (this.song.jam) {
        this.duetVideo.src = this.song.uri;
        this.superPlayer.loadWebAudio(this.duetVideo.src);
        this.duetVideo.addEventListener("loadeddata", (event) => {
          this.duetVideo.pause();
        });
        this.duetVideo.load();
      } else {
        this.superPlayer.loadWebAudio(this.song.uri);
      }
    });
    this.superPlayer.addEventListener("durationGet", () => {
      this.dispatchEvent("initDom");
    });
    this.superPlayer.addEventListener("playheadUpdate", (event, message) => {
      this.dispatchEvent("playheadUpdate");
      this.lyricsPlayer.updateLyrics(parseInt(message));
    });
    this.superPlayer.addEventListener("updateDuration", () => {
      this.dispatchEvent("durationUpdate");
    });
    this.superPlayer.addEventListener("recordReady", () => {
      this.startRecording();
      this.dispatchEvent("recordReady");
    });
    this.recorder.addEventListener("blobDone", () => {
      this.superPlayer.loadVoice(this.recorder.blob);
    });
    this.recorder.addEventListener("filtersOn", () => {
      this.startCanvas();
    });
    this.recorder.addEventListener("hide_canvas", () => {
      this.dispatchEvent("hide_canvas");
    });
    this.recorder.addEventListener("filtersOff", () => {
      clearInterval(this.filterInterval);
    });
    this.recorder.addEventListener("clearCanvas", () => {
      if (typeof this.filterCtx != "undefined") {
        this.filterCtx.clearRect(0, 0, 600, 600);
      }
    });
    this.superPlayer.addEventListener("voiceReady", () => {
      this.initReview();
    });
    this.recorder.addEventListener("show_message", (event, message) => {
      this.dispatchEvent("show_message", message.message);
    });
    this.recorder.addEventListener("no_permissions", (event, message) => {
      this.dispatchEvent("no_permissions", message);
    });
    this.recorder.addEventListener("input_sources_ready", (r) => {
      this.sources = this.recorder.sources;
      this.dispatchEvent("input_sources_ready", r);
    });
  }
  changePitch(value) {
    if (this.mode == "review")
      return;
    this.superPlayer.changePitch(value);
    this.pitch = value;
  }
  startCanvas() {
    if (!this.recorder.noCam) {
      this.filterCtx = this.filterCanvas.getContext("2d");
      this.computeFrame();
      this.filterInterval = setInterval(() => {
        this.computeFrame();
      }, 1e3 / this.recorder.frameRate);
      this.recorder.filtersOn();
    }
  }
  computeFrame() {
    this.width = this.video.videoWidth;
    this.height = this.video.videoHeight;
    this.filterCtx.filter = this.filters[this.filterIndex].value;
    let ratio = this.width / this.height;
    this.filterCtx.drawImage(this.recorder.video, 0, 0, this.height, this.height, 0, 0, 600, 600);
    if (this.filtersIndex != 0) {
      if (this.filters[this.filterIndex].name == "Beautify") {
        this.filterCtx.globalCompositeOperation = "overlay";
        this.filterCtx.filter = "none";
        this.filterCtx.drawImage(this.recorder.video, 0, 0, this.width, this.height, 0, 0, 600, 600);
        this.filterCtx.globalCompositeOperation = "source-over";
      } else {
        this.filterCtx.globalCompositeOperation = "source-over";
      }
      if (this.selectedFrame.name != "None") {
        this.filterCtx.drawImage(this.selectedFrame.image, 0, 0, 600, 600, 0, 0, 600, 600);
      }
    }
  }
  seek(e) {
    let noScrub;
    let value;
    if (typeof e == "object") {
      noScrub = false;
      value = e.target.valueAsNumber;
    } else {
      noScrub = true;
      value = e;
    }
    if (this.mode == "review") {
      this.video.pause();
      this.video.currentTime = (value + this.superPlayer.voiceOffset) / 1e3;
    }
    if (this.jam) {
      this.duetVideo.pause();
      this.duetVideo.currentTime = (value + this.superPlayer.musicOffset) / 1e3;
    }
    this.superPlayer.seek(value);
    this.lyricsPlayer.seek(value);
    if (noScrub) {
      this.dispatchEvent("playheadUpdate");
    }
    if (this.superPlayer.playing) {
      if (this.mode == "review") {
        this.video.play();
      }
      if (this.jam) {
        this.duetVideo.play();
      }
      this.dispatchEvent("startScrubber");
    }
  }
  record() {
    this.duetVideo.curentTime = 0;
    this.lyricsPlayer.seek(0);
    this.recorder.start();
    this.superPlayer.prepareForRecording();
    this.duetVideo.play();
    this.playing = true;
    this.recording = true;
  }
  stopRecording() {
    if (this.recording) {
      this.recorder.stop();
      clearInterval(this.filterInterval);
      this.pause();
      this.seek(0);
      this.recording = false;
    }
  }
  play() {
    if (this.mode == "review") {
      this.superPlayer.play(this.video.currentTime * 1e3 - this.superPlayer.voiceOffset);
      this.video.play();
    } else {
      this.superPlayer.play();
    }
    if (this.duetVideo) {
      this.duetVideo.play();
    }
    this.playing = true;
  }
  pause() {
    if (this.mode == "review") {
      this.video.pause();
    }
    if (this.duetVideo) {
      this.duetVideo.pause();
    }
    this.superPlayer.pause();
    this.playing = false;
  }
  changeMusicVolume(e) {
    let value = e;
    this.superPlayer.changeMusicVolume(value);
  }
  changeVocalVolume(e) {
    let value = e;
    this.superPlayer.changeVocalVolume(value);
  }
  initReview() {
    this.video.srcObject = null;
    this.video.src = window.URL.createObjectURL(this.recorder.blob);
    this.video.pause();
    this.mode = "review";
    this.seek(0);
    this.dispatchEvent("loadReview");
  }
  sync(value) {
    this.superPlayer.sync(value);
  }
  addEventListener(eventName, cb) {
    if (!this.events[eventName]) {
      this.events[eventName] = new Array();
    }
    this.events[eventName].push(cb);
  }
  dispatchEvent(event, args) {
    if (!this.events[event])
      return;
    this.events[event].forEach((cb) => cb(event, args));
  }
}
