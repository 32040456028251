import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import {
  ProfileService,
  RecordingService,
  PlaylistService,
  HelperService,
  ArtistService,
  SongService,
  UtilService,
  UsersService,
  EventService,
} from '@app/core';

@Component({
  selector: 'app-my-favorites',
  templateUrl: './my-favorites.component.html',
  styleUrls: ['./my-favorites.component.scss'],
})
export class MyFavoritesComponent implements OnInit {
  @ViewChild('otherSingers', { static: true }) private otherSingersElem;
  @ViewChild('playlist', { static: true }) private playlistElem;
  user: any;
  playlistOpened = false;
  playlistId: number;
  favVisible: false;
  @ViewChild('goldcontent', { static: true }) private goldModal;

  memberSortOptions = this.util.memberSortOptions;
  recordingSortOptions = this.util.recordingSortOptions;
  playlistSortOptions = this.util.playlistSortOptions;
  artistSortOptions = this.util.artistSortOptions;

  data = {
    artist: {
      fetch: this.profileService.getFavoriteArtists.bind(this.profileService),
      ...this.util.generateGridData(),
      sort: this.artistSortOptions[0],
    },
    member: {
      fetch: this.profileService.getFavoriteUsers.bind(this.profileService),
      ...this.util.generateGridData(),
      sort: this.memberSortOptions[0],
    },
    song: {
      fetch: this.profileService.getFavoriteSongs.bind(this.profileService),
      ...this.util.generateGridData(),
      sort: this.playlistSortOptions[0],
    },
    recording: {
      fetch: this.profileService.getFavoriteRecordings.bind(
        this.profileService
      ),
      ...this.util.generateGridData(),
      sort: this.recordingSortOptions[0],
    },
    playlist: {
      fetch: this.profileService.getFavoritePlaylists.bind(this.profileService),
      ...this.util.generateGridData(),
      sort: this.playlistSortOptions[0],
    },
  };

  constructor(
    public profileService: ProfileService,
    private ngZone: NgZone,
    private recordingService: RecordingService,
    private playlistService: PlaylistService,
    private artistService: ArtistService,
    private songService: SongService,
    private router: Router,
    private toastService: ToastrService,
    private usersService: UsersService,
    private helperService: HelperService,
    private eventService: EventService,
    private util: UtilService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.profileService.getUserProfile().subscribe(user => {
      this.user = user;
      this.favVisible = user['public_favorites'];
    });

    this.onScrollEndHandle(this.data.member, null);
    this.onScrollEndHandle(this.data.recording, null);
    this.onScrollEndHandle(this.data.playlist, null);
    this.onScrollEndHandle(this.data.artist, null);
    this.onScrollEndHandle(this.data.song, null);
  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  handleSing(song) {
    if (song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    this.navigate([`/recorder/${song.id}`]);
  }

  handleDuet(duet) {
    if (duet.song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    this.navigate([`/duet-recorder/${duet.id}`]);
  }

  resetDataObj(data) {
    data.currentPage = 0;
    data.loading = false;
    data.items = [];
    data.lastPage = 1;
  }

  onCheckboxChange($event) {
    this.user.settings.public_favorites = !this.user.settings.public_favorites;
    this.profileService
      .alterSettings({
        public_favorites: this.user.settings.public_favorites,
      })
      .subscribe(user => {
        console.log('updated', user);
      });
  }

  onScrollEndHandle(data, sort) {
    if (sort) {
      data.sort = sort;
    }

    if (!data.loading && data.lastPage) {
      data.loading = true;
      data.currentPage++;
      this.detectChanges();
      data
        .fetch(data.currentPage, data.perPage, data.sort.field, data.sort.asc)
        .subscribe(
          response => {
            data.items = data.items.concat(response.data);
            data.lastPage = response.next_page_url;
            data.loading = false;
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  refreshData(type) {
    this.data[type] = Object.assign(
      this.data[type],
      this.util.generateGridData()
    );
    this.onScrollEndHandle(this.data[type], null);
  }

  openLink(url) {
    window.open(window.location.origin + '/#' + url);
  }

  onArtist(artist) {
    this.openLink('/d/artists/' + artist.id);
 }

  onAddtoPlaylist(id) {
    this.playlistElem.open(id);
  }

  unfavoriteMember(profile) {
    this.profileService.unfavoriteMember(profile.id).subscribe(() => {
      this.refreshData('member');
      this.refreshData('recording');
    });
  }

  unfavoriteRecording(recording) {
    this.recordingService.unfav(recording.id).subscribe(() => {
      this.refreshData('recording');
    });
  }

  unfavoritePlaylist(playlist) {
    this.playlistService.unfavorite(playlist.id).subscribe(() => {
      this.refreshData('playlist');
    });
  }

  unfavoriteArtist(artist) {
    this.artistService.unfavorite(artist.id).subscribe(() => {
      this.refreshData('artist');
    });
  }

  favoriteArtist(artist) {
    this.artistService.favorite(artist.id).subscribe(() => {
      this.refreshData('artist');
    });
  }

  unfavoriteSong(song) {
    this.songService.unfav(song.id).subscribe(() => {
      this.refreshData('song');
    });
  }

  openOtherSingers(song) {
    this.otherSingersElem.open(song.id);
  }

  async subscribeUser(recording) {
    const recordingList = [this.data.recording.items];
    await this.helperService.subscribeUser(recording, recordingList);
    this.refreshData('member');
  }

  async unsubscribeUser(recording) {
    const recordingList = [this.data.recording.items];
    this.helperService.unsubscribeUser(recording, recordingList);
  }

  shareRecording(recording) {
    this.eventService.onSocialShare({ recording });
  }


  navProfile(id){
    this.navigate([`/d/profile/${id}/info`]);
  }

  navMessage(id){
    this.navigate([`/d/private-message/inbox/${id}`]);
  }


  navListen(id){
    this.navigate([`/d/listen/${id}`]);
  }

  navPlay(id){
   this.navigate([`/d/play/${id}`]);
  }

  navArtists(id){
   this.navigate([`/d/artists/${id}`]);
  }
  
  howOthersSingThis(id){
    this.navigate([`/d/ListenOthersSing/${id}`]);
  }

}
