import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-flame',
  templateUrl: './icon-flame.component.html',
  styleUrls: ['./icon-flame.component.scss']
})
export class IconFlameComponent implements OnInit {
  @Input() color: string = '#262628';
  @Input() size: number = 18;

  constructor() { }

  ngOnInit() {
  }

}
