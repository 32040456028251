<a class="ss-recording" *ngIf="!loading; else skeleton">
  <div class="ss-recording--photo" title="Play">
    <img [src]="recording.cover + '?h=100'">
   
   <!-- Add small play icon -->
    <app-icon-button-small class="btn" size="12px" color="#58c7c9"> 
           <app-icon-play  color="white" size="12"></app-icon-play>
    </app-icon-button-small>
      <app-icon-camera  id='cam' *ngIf='recording.webcam' color="white" size="12"></app-icon-camera>
 
    <app-explicit-tag *ngIf="recording.offensive"></app-explicit-tag>
  </div>
  <div class="ss-recording--info">
    
    <div *ngIf="isProcessing; else processingFalse" > 
      <a style="color:#ACACAC" class="info-title ss-truncate" >
        <!-- MAIN REC TITLE-->
        {{recording.song.title |  truncateText:getTruncateValue(truncateLength):'...'}}
        <div  class="rec-tags" [class.tags-pill]="pill" *ngIf="recording.badges  != 0" [ngStyle]="getStyle()" > {{ getRecordingTag(truncateLength)}}  </div>
      </a> 
    </div>
    <ng-template #processingFalse>
      <a [routerLink]="recordingLink"  (click)='navigate(recordingLink)' class="info-title ss-truncate" >
          <!-- MAIN REC TITLE-->
        {{recording.song.title |  truncateText:getTruncateValue(truncateLength):'...'}}
        <div  class="rec-tags" [class.tags-pill]="pill" *ngIf="recording.badges  != 0" [ngStyle]="getStyle()" > {{ getRecordingTag(truncateLength)}}  </div>
       </a> 
    </ng-template>

    <a class="info-artist" >
      {{recording.song.artist_name |  truncateText:truncateLength:'...'}}
    </a>

    <app-ss-user-name
      *ngFor = "let user of users"
      [user]="user"
      [showLevel]="showLevel"
      [noLink] = "false"
      [disabled] = "true"
    ></app-ss-user-name>
  </div>
</a>


<ng-template  #skeleton>
  <div class="skeleton-ss-recording">
    <div class="skeleton-ss-recording-profile"></div>
    
    <div class="skeleton-ss-recording-info">
      <div class="skeleton-info-title"></div>
      <div class="skeleton-info-artist"></div>
      <div class="skeleton-info-extra"></div>
    </div>
  </div>
  <div class="skeleton-stats">
    <div class="skeleton-stat1"></div>
    <div class="skeleton-stat2"></div>
  </div>
</ng-template>