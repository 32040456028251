<div class="subscription-content">
  <div class="container container-ss">
    <div class="row">
      <div class="col-12 col-md-9">
        <div class="search-input">
          <app-search-bar-input
            (onSearch)="onSearch($event)"
            (onClear)="onSearchClear()"
          ></app-search-bar-input>
        </div>
        <div class="searh-result" *ngIf="members.length || searchTerm">
          <h4>Members</h4>
          <ng-container *ngIf="members.length; else noSearchResult">
            <div
              class="subscription-block"
              *ngFor="let item of members"
            >
              <div class="subscription-block-sub">
                <app-ss-user [user]="item" [showStatus]="false"></app-ss-user>
              </div>
              <div class="subscription-block-sub">
                <button class="btn btn-danger" *ngIf="item.unblocked" (click)="block(item)" [disabled]="item.loading">
                  <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="item.loading"></i>
                  BLOCK
                </button>
              </div>
            </div>
          </ng-container>
          <ng-template #noSearchResult>
            <div class="no-data">No results found.</div>
          </ng-template>
        </div>

        <h4>Blocked Members</h4>
        <ng-container *ngIf="blockedMembers.length; else noData">
          <div
            class="subscription-block"
            *ngFor="let item of blockedMembers"
          >
            <div class="subscription-block-sub">
              <app-ss-user [user]="item" [showStatus]="false"></app-ss-user>
            </div>
            <div class="subscription-block-sub">
              <button class="btn btn-primary" *ngIf="!item.unblocked" (click)="unblock(item)" [disabled]="item.loading">
                <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="item.loading"></i>
                UNBLOCK
              </button>
              <!-- <button class="btn btn-danger" *ngIf="item.unblocked" (click)="block(item)" [disabled]="item.loading">
                <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="item.loading"></i>
                BLOCK
              </button> -->
            </div>
          </div>
        </ng-container>
        <ng-template #noData>
          <div class="no-data">No results found.</div>
        </ng-template>
        <div class="page-actions">
          <button class="btn btn-primary" (click)="goBack()">BACK</button>
        </div>
        <app-ss-confirm-modal
          #confirmModal
          (confirmed)="handleConfirmed()"
        ></app-ss-confirm-modal>
      </div>
    </div>
  </div>
</div>
