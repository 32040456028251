import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-recorder-sync-bar',
  templateUrl: './recorder-sync-bar.component.html',
  styleUrls: ['./recorder-sync-bar.component.scss']
})
export class RecorderSyncBarComponent implements OnInit {
  @Input() value: any = 0;
  @Input() classes: any = [];
  @Input() newRecorder = false;
  @Output() changed = new EventEmitter<number>();
  min = -1000;
  max = 1000;
  step = 10;
  sync = 0;

  constructor() { }

  ngOnInit() {
    console.log(this.getClasses());
    this.sync = this.value;
  }

  getClasses() {
    return [...this.classes, 'ss-progress-input'].join(' ');
  }

  getProgress() {
    return (this.value - this.min) / (this.max - this.min) * 100;
  }

  getValue() {
    let result = '';
    if (this.sync > 0) {
      result = '+';
    }

    return result + this.sync + 'ms';
  }

  adjust(value) {
    this.value += parseInt(value);
    if (this.value > 1000) {
      this.value = 1000;
    }
    if (this.value < -1000) {
      this.value = -1000;
    }
    this.sync = this.value;
    this.changed.emit(this.value);
  }

  onSeek(e) {
    this.changeValue(e.target.value);
  }
  onChange(e) {
    this.sync = parseInt(e.target.value);

  }

  changeValue(value) {
    this.sync = parseInt(value);
    this.changed.emit(this.sync);
  }
}
