import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import {ProfileService, UsersService} from '@app/core';
import {tap, map} from 'rxjs/operators';
import {Subject, of} from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from "@angular/common";
function isEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

@Component({
  selector: 'app-ss-search-users',
  templateUrl: './ss-search-users.component.html',
  styleUrls: ['./ss-search-users.component.scss']
})
export class SsSearchUsersComponent implements OnInit {

  @Input('label') label = 'Membership for';
  @Input('recipients') recipients = [];
  @Input('matcher') matcher: any;
  @Input('noRecurring') noRecurring = false;
  @Input('noColors') noColors = false;
  @Input('userType') userType = 'me';
  @Input('single') single = false;
  @Output('onUserSelect') onUserSelect = new EventEmitter<any>();
  @Output('typeChanged') typeChanged = new EventEmitter();
  @ViewChild('searchModal', { static: true }) private searchModal;
  @ViewChild('confirmation', { static: true }) private confirmationModal;
  @Input('default') default = null;
  @Input('planType') planType = '';

  selectedUsers = [];
  search$ = new Subject<string>();
  page = 1;
  total = 1;
  perPage = 10;
  searchTerm = '';
  userOwnText = 'me';
  me = null;
  userId = this.profiles.userId;
  planType21 = 'youth';
  planType65 = 'senior';
  alertDobMessage = "";
  alertMessaage21 = "You do not qualify for the youth membership.";
  alertMessaage65 = "You do not qualify for senior membership.";

  users$ = this.users.searchUsers(this.search$).pipe(
    tap(
      response => {
        this.page = response.current_page;
        this.total = response.total;
      }
    ),
    map(response => response.data),
    map(users => {
      if (this.matcher) {
        return this.matcher(users);
      } else {
        return users;
      }
    }),
  );

  constructor(
    private users: UsersService,
    private toastr: ToastrService,
    private profiles: ProfileService
  ) {
  }

  async ngOnInit() {
    this.me = await this.profiles.getUserProfile().toPromise();
    this.userOwnText = this.me.screen_name + ' (me)';

    if (this.userType === 'other') {
      this.selectedUsers = this.recipients;
    } else {
      if (this.isInvalidUser(this.me)) {
        this.userType = 'other';
      } else {
        this.onUserSelect.emit([this.me]);
      }
    }
  }

  handleUserSelected(users) {
    console.log(users);
    this.selectedUsers = users;
    this.onUserSelect.emit(users);
  }

  isInvalidUser(user) {
    return (this.noRecurring && user.has_recurring_subscription) ||
      (this.noColors && user.colors);
  }

  changeUserType(type) {
    if (type === 'me' ) {

      if (this.noColors && this.me.colors) {
        this.toastr.error(`You already have a colored username`);
        return;
      }
      if (this.noRecurring && this.me.has_recurring_subscription) {
        this.toastr.error(`You already have a recurring subscription`);
        return;
      }

      if (this.planType && this.planType === this.planType21 && !this.dobChecked(this.me.dob, this.planType21)){
        this.confirmationModal.open('Alert!', this.alertDobMessage + this.alertMessaage21).then(result => {
          if (result === 'ok') {return;}  });
        return;
      }

      if (this.planType && this.planType === this.planType65 && !this.dobChecked(this.me.dob, this.planType65)){
        this.confirmationModal.open('Alert!', this.alertDobMessage  + this.alertMessaage65).then(result => {
          if (result === 'ok') {return;}  });
        return;
      }
    }
    this.userType = type;
    this.typeChanged.emit(type);
    if (this.userType === 'me') {
      this.onUserSelect.emit([this.me]);
    } else {
     
      this.onUserSelect.emit(this.selectedUsers);
    }
  }

  deleteUser(user) {
    this.selectedUsers = this.selectedUsers.filter(u => u.id !== user.id);
    this.onUserSelect.emit(this.selectedUsers);
  }

  // selectUser(user) {
  //   if (this.noRecurring && user.has_recurring_subscription) {
  //     if (this.me.id !== user.id) {
  //       this.toastr.error(`${user.screen_name} already has a recurring subscription`);
  //     } else {
  //       this.selectedUser = user;
  //       this.onUserSelect.emit(user);
  //     }
  //   } else if (this.noColors && user.colors) {
  //     if (this.me.id !== user.id) {
  //       this.toastr.error(`${user.screen_name} already has a colored username`);
  //     } else {
  //       this.selectedUser = user;
  //       this.onUserSelect.emit(user);
  //     }
  //   } else {
  //     this.selectedUser = user;
  //     this.onUserSelect.emit(user);
  //   }
  // }

  dobChecked(d, plan){

    if (d == undefined){
      this.alertDobMessage = "Date of birth not found!\n";
        return false;
    } else {
      this.alertDobMessage = "";
    }

    const date1 = new Date();
    const age21 = new Date(date1.getFullYear() - 21, date1.getMonth(), date1.getDate(), 0, 0, 0);

    const date2 = new Date();
    const age65 = new Date(date2.getFullYear() - 65, date2.getMonth(), date2.getDate(), 0, 0, 0);

    let under21 = formatDate(age21,'yyyy-MM-dd','en_US');
    let over65 = formatDate(age65,'yyyy-MM-dd','en_US');

    if(plan === this.planType65){
      if(over65 >= d){
        return true;
       } else {
         return false;
       }
    }
    
    if(plan === this.planType21){
      if(under21 < d){
        return true;
       } else {
         return false;
       }
    }

  }

  openSearch() {
    this.searchModal.open(this.noRecurring, this.noColors);
  }
}
