<!-- <div class="container container-ss ngucarousel-custom snappenin-carousel"> -->
<div >   
  <div class="top-controls" [ngClass]="{'showcase-container': showcase === true}">
    <h1>{{title}}</h1>
  </div>
  
  
    <!-- <div class="horizontal-layout items" *ngIf="items"> -->
  <div class="horizontal-layout items" *ngIf="!loading; else skeleton" [ngClass]="{'showcase-container': showcase === true}">
    <div *ngFor="let item of items.slice(0, amount); let j = index">
      <div class="recommended-item" *ngIf="mode==1">
        <app-recording-snappenin-item [ngClass]="{'showcase-container': showcase === true}" [item]="items[j]" [showStats]="showStats" title="Song: {{item.song.title}}&#013;Artist: {{item.song.artist_name}}"></app-recording-snappenin-item>
      </div>
      <div class="recommended-item" *ngIf="mode==2">
        <app-featured-image [item]="items[j]" [showStats]="showStats"></app-featured-image>
      </div> 
    </div> 
  </div>
      

  <ng-template  #skeleton>
    <div class="horizontal-layout items"  style="padding-left: 15px; height: 264px;">
      <div *ngFor="let e of [].constructor(6)">
        <div class = "snappenin-item-image skeleton">
          <div class = "snappenin-item-line1 skeleton"></div>
          <div class = "snappenin-item-line2 skeleton"></div>
          <div class = "snappenin-item-line3 skeleton"></div>
        </div>
      </div>
    </div>
  </ng-template>
  <a href="#/d/{{pageType}}" style='color: #58c7c9; bottom:0; ' class="featured-list--more" >See More</a>
</div>
  

           
            
