<div class="ssb-footer">
    <div class="footer-upper">
        <div class="footer-upper-left">
            <div class="singsnap-logo">
                <span [routerLink]="['']">
                    <img src="/assets/icons/logotext.png" alt="logo" class="footer-logo">
                </span>
            </div>
            
        </div>
        <div class="footer-social">
            <span><a href="https://www.facebook.com/singsnap/"><i class="fa fa-facebook"></i></a></span>
            <span><a href="https://www.youtube.com/c/SingSnap/"><i class="fa fa-youtube"></i></a></span>
            <span><a href="https://twitter.com/singsnap"><i class="fa fa-twitter"></i></a></span>
            <span><a href="https://www.instagram.com/singsnapapp/"><i class="fa fa-instagram"></i></a></span>
        </div>
    </div>
    <div class="footer-lower">

        <div class="footer-lower-left">
            <a href="https://www.singsnap.com/privacy" class="footer-link">Privacy</a>
            <a href="https://www.singsnap.com/copyright" class="footer-link">Copyright</a>
        </div>

        <div class="footer-lower-right">
            <span><i class="fa fa-copyright" aria-hidden="true"></i>{{year}} SingSnap.com</span>
        </div>
    </div>
</div>
