<div class="body_home cl_fullWidth" [ngClass]="{ 'blur' : isCartVisible }">

    <div class="ssb-navbar" [ngClass]="{'hamburger-colors': isHamburgerVisible}">
        <div class="row">
            <div class="col-lg-3 col-md-3">
                <span class="singsnap_logo" [routerLink]="['/main/shop/home']">
                    <img src="../../../assets/pictures/logo.png" alt="logo" class="logo"><span class="_singsnap">SingSnap</span>
                </span>
            </div>

            <div class="col-lg-9 col-md-9 shop-navbar-right">
                <span class="ssb-actions pull-right"><img (click)="isHamburgerVisible = !isHamburgerVisible" src="../../../assets/pictures/webSite/menu_black.png"
                                                                  alt="burger" class="burger_menu"></span>
                <span class="ssb-actions pull-right" *ngIf="!userInfo"><a [routerLink]="['/sign-up']">SIGN UP</a></span>
                <span class="ssb-actions pull-right" *ngIf="!userInfo"><a [routerLink]="['/login']">LOGIN</a></span>

                <span class="ssb-actions pull-right" *ngIf="userInfo"><img class="cl_userAvatar" src="{{userInfo?.photo}}"/></span>
                <span class="ssb-actions pull-right" *ngIf="userInfo">{{userInfo?.name}}</span>

                <span (click)="isCartVisible = true" class="ssb-actions pull-right user-basket" *ngIf="userInfo"><a>
                    <i class="fa fa-shopping-cart" aria-hidden="true"><span class="badge" *ngIf="cartItems.length > 0">{{totalCartCount}}</span></i></a></span>
                <span class="ssb-actions pull-right" *ngIf="userInfo"><a [routerLink]="['orders']">MY ORDERS</a></span>
            </div>

            <div class="col-lg-9 col-md-9 shop-navbar-left">

                <span class="ssb-actions" *ngIf="userInfo"><a [routerLink]="['orders']">MY ORDERS</a></span>
                <span (click)="isCartVisible = true" class="ssb-actions user-basket" *ngIf="userInfo"><a>
                    <i class="fa fa-shopping-cart" aria-hidden="true"><span class="badge" *ngIf="cartItems.length > 0">{{totalCartCount}}</span></i></a></span>
                <span class="ssb-actions" *ngIf="userInfo">{{userInfo?.name}}</span>
                <span class="ssb-actions" *ngIf="userInfo"><img class="cl_userAvatar" src="{{userInfo?.photo}}"/></span>

                <span class="ssb-actions" *ngIf="!userInfo"><a [routerLink]="['/login']">LOGIN</a></span>
                <span class="ssb-actions" *ngIf="!userInfo"><a [routerLink]="['/sign-up']">SIGN UP</a></span>

                <span class="ssb-actions"><img (click)="isHamburgerVisible = !isHamburgerVisible" src="../../../assets/pictures/webSite/menu_black.png"
                                                          alt="burger" class="burger_menu"></span>
            </div>

        </div>
    </div>
    <div (mouseout)="hideHamburger($event)" class="hamburger" *ngIf="isHamburgerVisible">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="ssb-navbar">

                    <span class="ssb-actions pull-right"><img (click)="isHamburgerVisible = !isHamburgerVisible"
                                                              [src]="isHamburgerVisible ? '../../../assets/pictures/webSite/menu_black.png' : '../../../assets/pictures/webSite/menu.png'"
                                                              alt="burger" class="burger_menu"></span>
                    <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                                                                                 [routerLink]="['/sign-up']">SIGN UP</a></span>
                    <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                                                                                 [routerLink]="['/login']">LOGIN</a></span>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 menu_content">
                <span [routerLink]="['/main/home']"><span class="fat_line"></span> home</span><br>
                <span [routerLink]="['/main/aboutUs']"><span class="fat_line"></span> about us</span><br>
                <span [routerLink]="['/main/pricing']"><span class="fat_line"></span> pricing</span><br>
                <!-- <span [routerLink]="['/main/blog']"><span class="fat_line"></span> blog</span><br> -->
                <span [routerLink]="['/dashboard']"><span class="fat_line"></span> app</span><br>
                <!-- hidding store from website -->
                <!-- <span [routerLink]="['/main/shop']"><span class="fat_line"></span> store</span><br> -->
                <span [routerLink]="['/main/support']"><span class="fat_line"></span> support</span><br>
                <span [routerLink]="['/main/influencer']"><span class="fat_line"></span> influencer</span><br>
                <span (click)="onLogOut()" *ngIf="userIsLoged"><span class="fat_line"></span> log out</span><br>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 social-links">
                <img src="../../../assets/pictures/webSite/hamburger_facebook.png" alt="">
                <img src="../../../assets/pictures/webSite/hamburger_youtube.png" alt="">
                <img src="../../../assets/pictures/webSite/hamburger_twitter.png" alt="">
                <img src="../../../assets/pictures/webSite/hamburger_instagram.png" alt="">
            </div>
        </div>
    </div>

    <router-outlet></router-outlet>

</div>
<div class="your-cart" *ngIf="isCartVisible">
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 mask">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 my-order">
        <div class="your-order-container">
            <h1 class="title">YOUR ORDER</h1>
            <span class="close" (click)="isCartVisible = false"><i class="fa fa-times" aria-hidden="true"></i></span>
        </div>

        <div class="row order-products" *ngIf="cartItems.length > 0">
            <div class="col-lg-12 my-order-product" *ngFor="let item of cartItems; let e = index">
                <span class="delete-product" (click)="removeFromCart(e)"><i class="fa fa-times" aria-hidden="true"></i></span>
                <div class="row">
                    <div class="col-lg-4 my-order-image">
                        <img src="{{item.images[0].src}}" alt="" height="100px">
                    </div>
                    <div class="col-lg-8">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 my-order-name">
                                {{item.name}}
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 my-order-price">
                                ${{item.price}} x{{item.ammount}}
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 my-order-color">
                            <i class="fa fa-circle" aria-hidden="true" [style.color]="item.color"></i> | <span *ngIf="item.size">{{item.size}}</span><span *ngIf="!item.size">42</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="your-order-container total-price" *ngIf="cartItems.length > 0">
            <h1 class="title">TOTAL</h1>
            <span class="price">${{totalPrice}}</span>
        </div>
        <div class="check-out" *ngIf="cartItems.length > 0">
            <button type="submit" class="btn" [routerLink]="['/main/shop/payment']" (click)="isCartVisible = false">CHECK OUT<i class="fa fa-angle-right cart-arrow" aria-hidden="true"></i></button>
        </div>
        <div class="your-order-container total-price" *ngIf="cartItems.length == 0">
            <h1 class="title">You don't have any order</h1>
        </div>
    </div>
</div>
