import Quill from 'quill';

const Embed = Quill.import('blots/block/embed');

export default class QuoteBlot extends Embed {
    static blotName: string;
    static tagName: string;
    static className: string;
    private domNode: any;
    static create(value) {
        const node = super.create();

        return node;
    }

    static formats(node) {
        // We still need to report unregistered embed formats
        return {
            quoteContent: node.innerHTML
        };
    }

    static value(node) {
        return node.innerHTML;
    }

    format(name, value) {
        // Handle unregistered embed formats
        if (name === 'quoteContent') {
            this.domNode.innerHTML = value;
        } else {
            super.format(name, value);
        }
    }
}

QuoteBlot.blotName = 'quote';
QuoteBlot.tagName = 'div';
QuoteBlot.className = 'ql-quote';
