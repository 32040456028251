import { ChangeDetectorRef, NgZone, Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import {
  GenresService,
  UtilService,
  EventService,
  RecordingService,
  SongService,
  ProfileService,
  HelperService,
} from '@app/core';
import { ArtistService } from '@app/core/services/artist.service';

declare var $: any;

@Component({
  selector: 'app-sing-duets',
  templateUrl: './sing-duets.component.html',
  styleUrls: ['./sing-duets.component.scss']
})
export class SingDuetsComponent implements OnInit {
  recording;
  titleLength = 24;
  duets = [];
  artists = [];
  trending$;
  recordingSortOptions = [];
  songSortOptions = [];
  genreSortOptions = this.util.recordingSortOptions;
  @ViewChild('goldcontent') private goldModal;
  itemsPerPage = 30;
  
  selectedLetter = '';
  filterByDemo = false;
  selectedGenreIndex = 0;
  selectedDecadeIndex = 0;
  currentPage = 1;
  decadesArray=[];
  letters = [];
  genresArray = [{name: 'All', id: 90000}];
  demoOptions = ["All", "Performance", "Demo Only"];

  genresData: any = {
    ...this.util.generateGridData(),
    sort: this.genreSortOptions[0],
    fetch: this.genreService.getGenres.bind(this.genreService),
  };


 
  duetsData: any = {
    ...this.util.generateGridData(),
    perPage: this.itemsPerPage,
    fetch: this.songService.getDuetsSeeMore.bind(this.songService),
  };

  constructor(
    private recordingService: RecordingService,
    private songService: SongService,
    private genreService: GenresService,
    private router: Router,
    private artistService: ArtistService,
    private toastService: ToastrService,
    public profileService: ProfileService,
    private helperService: HelperService,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    private util: UtilService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.recordingSortOptions = this.util.recordingSortOptions;
    this.songSortOptions = this.util.songSortOptions;
    this.duetsData.sort = this.util.songSortOptions[0];
  }

  ngOnInit() {
   // console.log('users gold', this.profileService.user.gold);
    /* set default free first songs for blue users */
    // if (!this.profileService.user.gold) {
    //   this.duetsData.freeSongsFirst = true;
    //   this.onScrollEndHandle(this.duetsData, { field: 'gold', asc: true });
    // } else {
    //    this.onScrollEndHandle(this.duetsData, null);
    // }
    this.genresData.fetch().subscribe((response)=>{
      response.data.forEach(item => {
        this.genresArray.push(item)
      })
    })

    this.decadesArray = this.util.getDecadeArray();
    this.letters = this.util.getLettersArray();

    this.route.queryParams.subscribe(params => {
      if(typeof params.page != 'undefined') {
         this.currentPage = params.page;
      }
    });

    this.route.queryParams.subscribe(params => {
      if(typeof params.letter != 'undefined') {
        this.selectedLetter = '';
        this.handleFilter(params.letter);
      }
    });

    this.route.queryParams.subscribe(params => {
      if(typeof params.decade != 'undefined') {
        this.duetsData.decade = params.decade;
        this.selectedDecadeIndex = this.decadesArray.findIndex(element => element.includes(params.decade))
      }
    });

    this.route.queryParams.subscribe(params => {
      if(typeof params.genre != 'undefined') {
       this.selectedGenreIndex = params.genre;
       this.genreClick(params.genre);
      }
    });   

    this.route.queryParams.subscribe(params => {
      if(typeof params.demo != 'undefined') {
       this.filterByDemo = params.demo;
       this.genreClick(true);
      }
    });   


    this.onScrollEndHandle();
  }

  checkMine(item){
    return item.favorited;
  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  handleSing(song) {
    if (song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    this.navigate([`/recorder/${song.id}`]);
  }

  handleDuet(duet) {
    if (duet.song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    // this.router.navigate(['/duet-recorder', duet.id]);
    this.navigate([`/duet-recorder/${duet.id}`]);
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  back(){
    window.history.back(); 
  }

  setPageParam() { 
    this.location.replaceState(this.location.path().split('?')[0], '');
    this.location.replaceState(this.location.path() + `?page=${this.currentPage}`);
    if(this.selectedLetter.length >0){
      this.location.replaceState(this.location.path() + `&letter=${this.selectedLetter}`);
    }
    if(this.duetsData.decade && this.duetsData.decade.length>0){
      this.location.replaceState(this.location.path() + `&decade=${this.duetsData.decade}`);
    }
    if(this.duetsData.filter.genre_id && this.duetsData.filter.genre_id>0){
      this.location.replaceState(this.location.path() + `&genre=${this.duetsData.filter.genre_id}`);
    }
    if(this.duetsData.filter.demo && this.duetsData.filter.demo === true){
      this.location.replaceState(this.location.path() + `&demo=true`);
    }
  }


  setSortFields(sort){
    if (sort) {
      this.duetsData.sort = sort;
    }
  }

  onScrollEndHandle() {
  this.duetsData.demo = this.filterByDemo;
  let data = this.duetsData;
  let reload = false;
  if(typeof data.filter == 'undefined') {
    data.filter = {};
  }

    if (reload || (!data.loading && data.lastPage)) {
      data.loading = true;
      this.detectChanges();
      data
        .fetch(this.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade, data.demo)
        .subscribe(
          response => {
            data.currentPage = this.currentPage;
            data.items = data.items.concat(response.data);
            this.setPageParam();
            data.lastPage = response.next_page_url;
            data.loading = false;
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  getNextSongs() {
    let data = this.duetsData;
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }
    if (!data.loading && data.lastPage) {
      data.loading = true;
      data.currentPage++;
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade, data.demo)
        .subscribe(
          response => {
            this.currentPage++
            data.items = response.data;
            this.setPageParam();
            data.lastPage = response.next_page_url;
            data.loading = false;
            window.scrollTo(0, 0);
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  getPrevSongs() {
    let data = this.duetsData;
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }

    if (!data.loading && data.currentPage != 1) {
      data.loading = true;
      data.currentPage--;
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade, data.demo)
        .subscribe(
          response => {
            this.currentPage--
            data.items = response.data;
            this.setPageParam();
            data.lastPage = response.next_page_url;
            data.loading = false;
            window.scrollTo(0, 0);
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  handleFreeSongsChange(data, gold) {
    data.freeSongsFirst = gold;
    this.resetDataObj(data);
    this.onScrollEndHandle();
  }

  resetDataObj(data) {
    data.currentPage = 1;
    data.loading = false;
    data.items = [];
    data.lastPage = 1;
    this.currentPage = 1;
    this.filterByDemo = false;
  }

  favoriteSong(song) {
    this.songService.fav(song.id).subscribe(() => {
      song.favorited = true;
      this.toastService.success('Added song to favorites');
    });
  }

  unfavoriteSong(song) {
    this.songService.unfav(song.id).subscribe(() => {
      song.favorited = false;
      this.toastService.success('Removed song from favorites');
    });
  }

  openLink(url) {
    window.open(window.location.origin + '/#' + url);
  }

  onArtist(artist) {
    this.navigate([`/d/artists/${artist.id}`]);
  }

  shareRecording(recording) {
    this.eventService.onSocialShare({ recording });
  }


  decadeClick(event) {
    this.duetsData.decade = this.decadesArray[event].substring(0, this.decadesArray[event].length - 2)
    if(event == 0) {
      this.duetsData.decade = '';
    }
    this.resetDataObj(this.duetsData);
    this.onScrollEndHandle();
  }

  genreClick(event) {
    if(this.genresArray[event].name == 'All' && typeof this.duetsData.filter.genre_id != 'undefined') {
      delete this.duetsData.filter.genre_id;
    } else {
      this.duetsData.filter.genre_id = this.genresArray[event].id
    }
    this.resetDataObj(this.duetsData);
    this.onScrollEndHandle();
  }
  handleFilter(filter) { 
    if(this.duetsData.filter.title == filter) {
      delete this.duetsData.filter.title;
      this.selectedLetter = '';
    } else {
      this.selectedLetter = filter;
      this.duetsData.filter.title = filter;
    }
    this.resetDataObj(this.duetsData);
    this.onScrollEndHandle();
  }

  demoClick(value){
    
    this.duetsData.demo = this.filterByDemo;
    this.resetDataObj(this.duetsData);
    this.filterByDemo=value;
    this.onScrollEndHandle();
  }

  howOthersSingThis(id){
    this.navigate([`/d/ListenOthersSing/${id}`]);
  }
}
