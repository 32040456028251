import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ShoppingCartService } from '../services/shopping-cart.service';
import { OrdersService } from '@app/core';
import { ProfileService } from '@app/core';

@Component({
  selector: 'app-shop-payments',
  templateUrl: './shop-payments.component.html',
  styleUrls: ['./shop-payments.component.scss'],
})
export class ShopPaymentsComponent implements OnInit {
  shippingOrder: UntypedFormGroup;

  cartItems: any[];
  submitAttempt = false;
  totalPrice: number;
  errorMsg: any;
  serverResponse: any;
  user: any;
  step = 1;
  userPayment: any;
  stripeOverlay = false;
  stripeResponse: any;

  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  cvc: string;
  message: string;

  constructor(
    private cartService: ShoppingCartService,
    private fb: UntypedFormBuilder,
    private ordersService: OrdersService,
    private profileService: ProfileService,
    private router: Router
  ) {
    this.shippingOrder = fb.group({
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      country: ['', Validators.required],
      city: ['', Validators.required],
      zip: ['', Validators.required],
      state: ['', Validators.required],
      address: ['', Validators.required],
    });
  }

  getToken() {
    this.message = 'Loading...';

    (<any>window).Stripe.card.createToken(
      {
        number: this.cardNumber,
        exp_month: this.expiryMonth,
        exp_year: this.expiryYear,
        cvc: this.cvc,
      },
      (status: number, response: any) => {
        if (status === 200) {
          this.ordersService
            .chargeStripe(this.totalPrice, this.serverResponse.id)
            .subscribe(
              response => (this.stripeResponse = response),
              err => console.error(err),
              () => this.obtainedStripePayment()
            );
        } else {
          this.message = response.error.message;
        }
      }
    );
  }

  obtainedStripePayment() {
    if (this.stripeResponse.success) {
      this.cartService.emptyCart();
      this.step = 4;
    } else {
      this.step = 3;
    }
  }

  ngOnInit() {
    this.cartService.getShoppingCard().subscribe(response => {
      this.cartItems = response;
      this.totalPrice = this.cartService.getTotalPrice();
    });
  }

  removeFromCart(index) {
    this.cartService.removeFromCart(index);
  }

  submitOrder(order) {
    this.submitAttempt = true;
    if (order.valid) {
      this.errorMsg = '';
      let i = 0;
      let count = this.cartItems.length;
      let shoppingCard = [];
      for (i; i < count; i++) {
        shoppingCard.push({ product_id: this.cartItems[i].id, quantity: 1 });
      }
      this.ordersService
        .submitOrder(order.value, shoppingCard)
        .subscribe(
          response => (this.serverResponse = response),
          err => (this.errorMsg = err),
          () => this.obtainedOrder()
        );
    }
  }

  obtainedOrder() {
    if (this.serverResponse && this.serverResponse.id) {
      this.profileService
        .getUserProfile()
        .subscribe(
          response => (this.user = response),
          err => console.error(err),
          () => (this.step = 2)
        );
    }
  }

  checkStripe() {
    this.ordersService
      .checkStripe()
      .subscribe(
        response => (this.userPayment = response),
        err => console.error(err),
        () => this.obtainedStripe()
      );
  }

  obtainedStripe() {
    if (this.userPayment.user.stripe_id) {
      this.ordersService
        .chargeStripe(this.totalPrice, this.serverResponse.id)
        .subscribe(
          response => (this.stripeResponse = response),
          err => console.error(err),
          () => this.obtainedStripePayment()
        );
    } else {
      this.stripeOverlay = true;
    }
  }

  myOrders() {
    this.router.navigate(['/main/shop/orders']);
  }

  paymentDeclined() {
    this.step = 1;
  }

  backStep() {
    this.step = this.step - 1;
  }
}
