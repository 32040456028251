import {Component, OnInit} from '@angular/core';
import {ShoppingCartService} from './services/shopping-cart.service';
import {ProfileService} from '@app/core';
import {AuthenticationService} from '@app/core';
import { Cookie } from 'ng2-cookies';


@Component({
    selector: 'app-shop',
    templateUrl: './shop.component.html',
    styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {

    public isCartVisible = false;
    isHamburgerVisible = false;
    cartItems: any[];
    totalPrice: number;
    userInfo: any;
    totalCartCount: number;
    userIsLoged = false;

    constructor(private cartService: ShoppingCartService,
                private profileService: ProfileService,
                private authService: AuthenticationService) {
    }

    ngOnInit() {
        const token = Cookie.get('AuthToken');
        this.userIsLoged = !!token;
        this.cartService.getShoppingCard().subscribe(response => {
            this.cartItems = response;
            this.totalPrice = this.cartService.getTotalPrice();
            this.updateCart(response);
        });
        this.getUser();
    }

    updateCart(cart) {
        this.totalCartCount = 0;
        for (let i = 0; i < cart.length; i++) {
            this.totalCartCount += cart[i].ammount;
        }
    }

    removeFromCart(index) {
        this.cartService.removeFromCart(index);
    }

    onLogOut() {
        this.authService.onLogOut();
    }

    getUser() {
        this.profileService.getUserProfile()
            .subscribe(data => {
                this.userInfo = data;
            });
    }

    hideHamburger(event) {
        const currentTarget = event.currentTarget;
        let toElement = event.toElement;
        if (navigator.userAgent.indexOf('Firefox') !== -1) {
            toElement = event.relatedTarget;
        }
        if (currentTarget !== toElement && !this.isDescendant(currentTarget, toElement)) {
            this.isHamburgerVisible = false;
        }

    }

    isDescendant(parent, child) {
        let node = child && child.parentNode;
        while (node != null) {
            if (node === parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }
}
