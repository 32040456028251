<div class="top-section">
  <div class="container container-ss" style="min-height: 297px;">
    <div class="row-top">
      <app-top-horizontal-list  title="Trending" pageType="SeeMore/Trending-Songs" [amount]='6' [mode]='2' [items]="trendingSongs"  [loading]="trendingLoading"></app-top-horizontal-list>
    </div>
  </div>
</div>

<!-- MultiAds -->
<div *ngIf="!loggedInIsGold" class="placeAdLeft">
  <ng-adsense
  [adClient]="'ca-pub-4776037543768273'"
  [adSlot]="6875544661"
  ></ng-adsense>
</div>
<div *ngIf="!loggedInIsGold" class="placeAdRight">
  <ng-adsense
  [adClient]="'ca-pub-4776037543768273'"
  [adSlot]="6875544661"
  ></ng-adsense>
</div>

<div class="bottom-section">
  <div class="container container-ss">
    <div class="row">
      <div class="col-12 col-lg-8">
        <app-ss-featured-grid
          title="Featured Songs"
          seeMorePage="SingFeaturedSongs"
          [cols]="2"
          [colsLg]="1"
          [colsMd]="2"
          [colsSm]="1"
          [sortOptions]="songSortOptionsFavoritesFirst"
          [hasSeeMore] = "true"
          [scrollable] = "false"
          [halfSize]="false"
          [list]="featuredData.items"
          [loading]="featuredData.loading"
          [freeSongFilter]="false"
          (freeSongChanged)="handleFreeSongsChange(featuredData, $event)"
          (onScrollEnd)="onScrollEndHandle(featuredData, $event)"
          (onOrderBy)="resetDataObj(featuredData);onScrollEndHandle(featuredData, $event)"
        >
          <ng-template #itemContent let-item >  <!-- Add pink color for favorite songs-->
            <div class="ssc-list-item"  class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
              <app-ss-song [song]="item"></app-ss-song>
              <div class="song-actions">
                <app-icon-button (click)="handleSing(item)" [shadow]="true" role="button" size="40px" color="#58c7c9" title="Record">
                  <app-icon-mic color="white" size="16"></app-icon-mic>
                </app-icon-button>
                <app-more-dot-menu title="Options">
                  <div class="dropdown-menu-content">
                    <div (click)="favoriteSong(item)" *ngIf="!item.favorited">Add to Favorites</div>
                    <div (click)="unfavoriteSong(item)" *ngIf="item.favorited">Remove from Favorites</div>
                    <div (click)="onArtist(item.artist)">View Artist Profile</div>
                    <div (click)="howOthersSingThis(item.id)">How Others Sing This</div>
                  </div>
                </app-more-dot-menu>
              </div>
            </div>
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
      </div>
      <div class="col-12 col-lg-4">
        <app-ss-featured-grid
          title="Favorite Songs"
          seeMorePage="SingFavoriteSongs"
          [hasSeeMore] = "true"
          [scrollable] = "false"
          [cols]="1"
          [colsLg]="1"
          [colsMd]="1"
          [colsSm]="1"
          [sortOptions]="songSortOptions"
          [halfSize]="false"
          [list]="favoriteData.items"
          [loading]="favoriteData.loading"
          [freeSongFilter]="false"
          (freeSongChanged)="handleFreeSongsChange(favoriteData, $event)"
          (onScrollEnd)="onScrollEndHandle(favoriteData, $event)"
          (onOrderBy)="resetDataObj(favoriteData);onScrollEndHandle(favoriteData, $event)"
        >
          <ng-template #itemContent let-item>
            <div class="ssc-list-item"  class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
              <app-ss-song [song]="item"></app-ss-song>
              <div class="song-actions">
                <app-icon-button (click)="handleSing(item)" [shadow]="true" role="button" size="40px" color="#58c7c9" title="Record">
                  <app-icon-mic color="white" size="16"></app-icon-mic>
                </app-icon-button>
                <app-more-dot-menu title="Options">
                  <div class="dropdown-menu-content">
                    <div (click)="favoriteSong(item)" *ngIf="!item.favorited">Add to Favorites</div>
                    <div (click)="unfavoriteSong(item)" *ngIf="item.favorited">Remove from Favorites</div>
                    <div (click)="onArtist(item.artist)">View Artist Profile</div>
                    <div (click)="howOthersSingThis(item.id)">How Others Sing This</div>
                  </div>
                </app-more-dot-menu>
              </div>
            </div>
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <app-ss-featured-grid
          title="Genres"
          [cols]="3"
          [colsLgm]="2"
          [colsMd]="3"
          [colsSm]="2"
          [hasSeeMore] = "true"
          [scrollable] = "false"
          seeMorePage="SeeMore/Genres-Songs"
          [list]="genresData.items"
          [loading]="genresData.loading"
          (onScrollEnd)="onScrollEndHandle(genresData, $event)"
          (onOrderBy)="resetDataObj(genresData);onScrollEndHandle(genresData, $event)"
        >
          <ng-template #itemContent let-item>
            <div [routerLink]="['/d/genres/' + item?.id + '/songs']" [queryParams]="{genre: item?.id}">
              <app-genre-item [genre]="item" [height]="150" [addHeight]="true" [showSong]="true"></app-genre-item>
            </div>
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
      </div>
      <div class="col-12 col-lg-6">
        <app-ss-featured-grid
          title="Artists"
          seeMorePage="SingArtists"
          [hasSeeMore] = "true"
          [scrollable] = "false"
          cols="1"
          [list]="artistsData.items"
          [sortOptions]="artistSortOptions"
          [loading]="artistsData.loading"
          (onScrollEnd)="onScrollEndHandle(artistsData, $event)"
          (onOrderBy)="resetDataObj(artistsData);onScrollEndHandle(artistsData, $event)"
        >
          <ng-template #itemContent let-item>
            <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
              <app-ss-artist [artist]="item" [spread]="true"></app-ss-artist>
              <div class="song-actions col-auto">
                <div class="featured-list--item-action align-items-center"  (click)="navigateArtist(item?.id)">
                  <app-icon-button [shadow]="true"  (click)="navigateArtist(item?.id)" role="button" title="Go to Artist Profile">
                    <app-icon-arrow-right></app-icon-arrow-right>
                  </app-icon-button>
                </div>
                <app-more-dot-menu title="Options">
                  <div class="dropdown-menu-content">
                    <div (click)="onFavArtist(item)" *ngIf="!item.favorited">Add to Favorites</div>
                    <div (click)="onUnfavArtist(item)" *ngIf="item.favorited">Remove from Favorites</div>
                   </div>
                </app-more-dot-menu>
              </div>
            </div>
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
      </div>
    </div>
      <!-- ads bottom -->
      <div *ngIf="!loggedInIsGold" style="text-align:center;">
        <ng-adsense 
        [adClient]="'ca-pub-4776037543768273'" 
        [adSlot]="4625033266" 
        [display]="'inline-block'"
        [width]="800"
        [height]="180"
        ></ng-adsense></div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <app-ss-featured-grid
          title="Open Duets"
          seeMorePage="SingOpenDuets"
          [hasSeeMore] = "true"
          [scrollable] = "false"
          cols="1"
          [checkOwnRecording]="true"
          [userId]="profileService.userId"
          [sortOptions]="recordingSortOptions"
          [list]="openDuetsData.items"
          [loading]="openDuetsData.loading"
          (onScrollEnd)="onScrollEndHandle(openDuetsData, $event)"
          (onOrderBy)="resetDataObj(openDuetsData);onScrollEndHandle(openDuetsData, $event)"
        >
          <ng-template #itemContent let-item>
            <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
              <app-ss-recording [recording]="item"></app-ss-recording>
              <div class="song-actions">
                <app-icon-button (click)="handleDuet(item)" [shadow]="true" role="button" size="40px" color="#58c7c9"
                  title="Record">
                  <app-icon-mic color="white" size="16"></app-icon-mic>
                </app-icon-button>
                <app-more-dot-menu title="Options">
                  <div class="dropdown-menu-content">
                    <div *ngIf="!item.favorited" (click)="favRecording(item)">Add to Favorites</div>
                    <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Favorites</div>
                    <div *ngIf="!item.users[0].notified && profileService.userId != item.user_id" (click)="subscribeUser(item)">Notify Me Next Time User Sings</div>
                    <div *ngIf="item.users[0].notified" (click)="unsubscribeUser(item)">Unsubscribe from User</div>
                    <!-- <div [routerLink]="['/d/profile/', item?.users[0]?.id]">Member Profile</div> -->
                    <div [routerLink]="['/d/listen/', item?.id]">Listen to Recording</div>
                    <div *ngIf="profileService.userId == item.user_id" [routerLink]="['/d/my-recordings', item.id]">Edit Recording Info</div>
                    <!-- <div (click)="shareRecording(item)">Share this recording</div> -->
                    <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
                  </div>
                </app-more-dot-menu>
              </div>
            </div>
            <!-- <app-recording-stat [recording]="item"></app-recording-stat> -->
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
      </div>
      <div class="col-12 col-lg-8">
        <app-ss-featured-grid
          title="Duets"
          seeMorePage="SingDuets"
          [hasSeeMore] = "true"
          [scrollable] = "false"
          [cols]="2"
          [colsSm]="1"
          [checkOwnRecording]="true"
          [userId]="profileService.userId"
          [sortOptions]="songSortOptions"
          [list]="duetsData.items"
          [loading]="duetsData.loading"
          [freeSongFilter]="false"
          (freeSongChanged)="handleFreeSongsChange(duetsData, $event)"
          (onScrollEnd)="onScrollEndHandle(duetsData, $event)"
          (onOrderBy)="resetDataObj(duetsData);onScrollEndHandle(duetsData, $event)"
        >
          <ng-template #itemContent let-item>
            <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
              <a (click)="handleSing(item)">
                <app-ss-song [song]="item"></app-ss-song>
              </a>
              <div class="song-actions">
                <app-icon-button (click)="handleSing(item)" [shadow]="true" role="button" size="40px" color="#58c7c9" title="Record">
                  <app-icon-mic color="white" size="16"></app-icon-mic>
                </app-icon-button>
                <app-more-dot-menu title="Options">
                  <div class="dropdown-menu-content">
                    <div (click)="favoriteSong(item)" *ngIf="!item.favorited">Add to Favorites</div>
                    <div (click)="unfavoriteSong(item)" *ngIf="item.favorited">Remove from Favorites</div>
                    <div (click)="onArtist(item.artist)">View Artist Profile</div>
                    <div (click)="howOthersSingThis(item.id)">How Others Sing This</div>
                  </div>
                </app-more-dot-menu>
              </div>
            </div>
            <!-- <app-recording-stat [recording]="item"></app-recording-stat> -->
          </ng-template>
        </app-ss-featured-grid>
        <app-vert-space height="40"></app-vert-space>
      </div>
    <app-purchase-gold-modal #goldcontent type="2"></app-purchase-gold-modal>
  </div>
      <!-- ads bottom -->
      <div *ngIf="!loggedInIsGold" style="text-align:center;">
        <ng-adsense 
        [adClient]="'ca-pub-4776037543768273'" 
        [adSlot]="4625033266" 
        [display]="'inline-block'"
        [width]="800"
        [height]="180"
        ></ng-adsense></div>
      </div> 
</div>
