import {Round} from '@app/models/round.model';
import {User} from '@app/models/user.model';
import { Playlist } from './playlist.model';

export enum ContestState {
  INACTIVE = 0,      // Upcoming, users can't join
  OPEN = 1,          // Users can Join
  ACTIVE = 2,        // Rounds running
  COMPLETE = 3,      // Finished
}

export const ContestUserState = {
  canJoin: {
    button: 'Join Contest',
    action: true,
    name: 'canJoin'
  },
  canJoinWithRecording: {
    button: 'Join Contest',
    action: true,
    name: 'canJoinWithRecording'
  },
  canSubmitRecording: {
    button: 'Submit Recording',
    action: true,
    name: 'canSubmitRecording'
  },
  notAcceptingJoin: {
    button: 'Currently not accepting entries',
    action: false,
    name: 'notAcceptingJoin'
  },
  notAcceptingSubmit: {
    button: 'Thank you for your submission - check back soon!',
    action: false,
    name: 'notAcceptingSubmit'
  },
  noActiveRound: {
    button: 'Check back when the next round begins',
    action: false,
    name: 'noActiveRound'
  },
  ownerPostRound: {
    button: 'Post Round to Start Contest!',
    action: false,
    name: 'ownerPostRound'
  },
}
class Stats {
  rounds: number;
  views: number;
  participants: number;
  shares: number;
  popularity: number;
}

export class Contest {
  contest_id: string;
  name: string;
  description: string;
  cover: string;
  criteria: string;
  rules: string;
  prize: string;
  current_round_id: string;
  user_id: number;
  cohost_id: number;
  start_at: string;
  min_users: number;
  submit_recording: boolean;
  new_recordings_only: boolean;
  status: ContestState;
  rounds_count: number;
  participants_count: number;
  category_id: number;
  created_at: string;
  updated_at: string;
  popularity: number;
  rounds_length: number;
  thread_id: number;
  multiple_submissions: boolean;
  is_host: boolean;
  is_cohost: boolean;
  is_owner: boolean;
  ended_at?: string;
  winner_id?: string;
  stats?: Stats;
  can_edit: boolean;
  joined: boolean;
  can_submit: boolean;
  user?: User;
  cohost: number;
  current_round: Round;
}

export interface SearchCriteria {
  term: string;
}

export interface ContestsSearchResult {
  enteredContests: Contest[];
  completedContests: Contest[];
  hostingContests: Contest[];
  inProgressContests: Contest[];
  openContests: Contest[];
  upcomingContests: Contest[];
}

export interface ContestSearchResult {
  rounds: Round[];
  participants: User[];
  playlists: Playlist[];
}
