import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Router } from '@angular/router';
import { HttpService } from './http.service';

declare const gapi: any;

@Injectable()
export class AuthenticationService extends HttpService {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  onLogin(body: any) {
    console.log(body);
    const headers = this.getHeaders();
    console.log('thing')
    //headers['observe'] = 'response';
    return this.http.post(this.makeUrl('/auth/login'), body, headers);
  }

  async onLogOut() {
    document.cookie = `AuthToken=bar; expires=0; domain=.singsnap.com`
    Cookie.deleteAll();
    localStorage.removeItem('token');
    return this.router.navigate(['/login']);
  }

  onResetRequest (body: any) {
    return this.http.post(this.makeUrl('/auth/password/forgot'), body, this.getHeaders());
  }

  onRegister(body: any) {
    const options = this.getHeaders();
    console.log('body', body);
    return this.http
      .post(this.makeUrl('/auth/register'), body, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
  checkUnique(body: any) {
    const options = this.getHeaders();
    return this.http
      .post(this.makeUrl('/users/unique'), body, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));

  }

  // onFacebookSignUp(data) {
  //   const headers = this.getHeaders();
  //   return this.http
  //     .post(this.makeUrl('/auth/socials/facebook'), data, headers)
  //     .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  // }

  // onGoogleSignUp(data: any) {
  //   const headers = this.getHeaders();
  //   return this.http
  //     .post(this.makeUrl('/auth/socials/googleplus'), data, headers)
  //     .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  // }
}
