<div class="announcement" *ngIf="!loading; else skeleton"  (click)="loadPage()" >
  <div class="announcement-image">
    <img [src]="announcement?.cover">
  </div>
  <div class="announcement-info">
    <h6>{{announcement?.title}}</h6>
    <span>{{announcement?.body | stripHtml}}</span>
  </div>
</div>

<ng-template  #skeleton>
  <div class="announcementSkeleton" >
    <div class="announcementSkeleton-image">
      
    </div>
    <div class="announcementSkeleton-info">
      <div class="sk-title"></div>
      <div class="sk-info"></div>
    </div>
  </div>
</ng-template> 