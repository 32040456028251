import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  title = '';
  description = '';
  modalReference: any;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  open(title = 'WARNING', description = '') {
    this.title = title;
    this.description = description;
    this.modalReference = this.modalService.open(this.contentElem, {
      windowClass: 'ssc-modal ssc-modal-sm',
      backdrop: 'static',
    });
  }

  closeModal() {
    this.modalReference.close();
    if (this.closed) {
      this.closed.emit();
    }
  }
}
