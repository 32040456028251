import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
    transform(input: any, config: any) {
        switch (config) {
            case 'TITLE':
                return input.sort(function(a, b) {return (a['song']['title'].trim().toLowerCase( ) > b['song']['title'].trim().toLowerCase( )) ? 1 : ((b['song']['title'].trim().toLowerCase( ) > a['song']['title'].trim().toLowerCase( )) ? -1 : 0); } );
            case 'ARTIST':
                return input.sort(function(a, b) {return (a['song']['artist']['name'].trim().toLowerCase( ) > b['song']['artist']['name'].trim().toLowerCase( )) ? 1 : ((b['song']['artist']['name'].trim().toLowerCase( ) > a['song']['artist']['name'].trim().toLowerCase( )) ? -1 : 0); } );
            case 'DATE':
                return input.sort(function(a, b) {return (a['song']['date_added'] > b['song']['date_added']) ? 1 : ((b['song']['date_added'] > a['song']['date_added']) ? -1 : 0); } );
            case 'GENRE':
                return input.sort(function(a, b) {return (a['song']['genre_id'] > b['song']['genre_id']) ? 1 : ((b['song']['genre_id'] > a['song']['genre_id']) ? -1 : 0); } );
            case 'DUET':
                return input.sort(function(a, b) {return (a['song']['duet'] > b['song']['duet']) ? 1 : ((b['song']['duet'] > a['song']['duet']) ? -1 : 0); } );
            case 'selected':
                return input;
            default:
                return input;
        }
    }
}

