<div class="body_home cl_boxCont">
    <div class="main">
        <div class="ssb-navbar">
                <span class="singsnap_logo" [routerLink]="['']">
                        <img src="../../../../assets/pictures/logo.png" alt="logo" class="logo"><span class="_singsnap">SingSnap</span>
                    </span>
            <span class="ssb-actions pull-right"><img (click)="isHamburgerVisible = !isHamburgerVisible"
                                                      [src]="isHamburgerVisible ? '../../../../assets/pictures/webSite/menu_black.png' : '../../../../assets/pictures/webSite/menu.png'"
                                                      alt="burger" class="burger_menu"></span>
            <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a
                [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                [routerLink]="['/sign-up']">SIGN UP</a></span>
            <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a
                [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                [routerLink]="['/login']">LOGIN</a></span>
        </div>
        <div (mouseout)="hideHamburger($event)" class="hamburger" *ngIf="isHamburgerVisible">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="ssb-navbar">

                    <span class="ssb-actions pull-right"><img (click)="isHamburgerVisible = !isHamburgerVisible"
                                                              [src]="isHamburgerVisible ? '../../../../assets/pictures/webSite/menu_black.png' : '../../../../assets/pictures/webSite/menu.png'"
                                                              alt="burger" class="burger_menu"></span>
                        <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a
                            [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                            [routerLink]="['/sign-up']">SIGN UP</a></span>
                        <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a
                            [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                            [routerLink]="['/login']">LOGIN</a></span>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 menu_content">
                    <span [routerLink]="['/main/home']"><span class="fat_line"></span> home</span><br>
                    <span [routerLink]="['/main/aboutUs']"><span class="fat_line"></span> about us</span><br>
                    <span [routerLink]="['/main/pricing']"><span class="fat_line"></span> pricing</span><br>
                    <!-- <span [routerLink]="['/main/blog']"><span class="fat_line"></span> blog</span><br> -->
                    <span [routerLink]="['/dashboard']"><span class="fat_line"></span> app</span><br>
                    <!-- hidding store from website -->
                    <!-- <span [routerLink]="['/main/shop']"><span class="fat_line"></span> store</span><br> -->
                    <span [routerLink]="['/main/support']"><span class="fat_line"></span> support</span><br>
                    <span [routerLink]="['/main/influencer']"><span class="fat_line"></span> influencer</span><br>
                    <span (click)="onLogOut()" *ngIf="userIsLoged"><span class="fat_line"></span> log out</span><br>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 social-links">
                    <img src="../../../../assets/pictures/webSite/hamburger_facebook.png" alt="">
                    <img src="../../../../assets/pictures/webSite/hamburger_youtube.png" alt="">
                    <img src="../../../../assets/pictures/webSite/hamburger_twitter.png" alt="">
                    <img src="../../../../assets/pictures/webSite/hamburger_instagram.png" alt="">
                </div>
            </div>
        </div>
        <div class="main_content" >
            <!-- <div [@routeAnimation]=""> -->
            <div>
                <span class="the_songs_that">The songs that</span>
                <span class="sets_your_stage">set your stage...</span>
                <span class="scroll_main"><i class="fa fa-long-arrow-down" aria-hidden="true"></i></span>
            </div>

        </div>
    </div>
    <!-- <div class="second" [scrollimate]="aboutUSAnimations.secondBlock"> -->
    <div class="second">
        <div class="row">
            <!-- <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 left_text" [@fadeInUp]="aboutUSAnimations.secondBlock.currentState"> -->
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 left_text">
                <div class="left_text_container">
                    <span class="every_tune">Every tune for</span>
                    <span class="every_kind">every kind of singer</span>
                    <span class="blue_underline"></span>
                    <span class="every_mood">We.ve got the tune for your every mood and<br>your every day. You pick the music and set<br>the stage - then, rock it out in your own<br>special way.</span>
                </div>
            </div>
            <!-- <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 laptop" [@slideFromLeft]="aboutUSAnimations.secondBlock.currentState"> -->
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 laptop">
                <div class="laptop_mac_contanier">
                    <img src='../../../../assets/pictures/webSite/AboutUsMac.png' alt="mac"
                         class="laptop_mac"/>
                </div>
            </div>
        </div>

    </div>
    <!-- <div class="third" [scrollimate]="aboutUSAnimations.thirdBlock" > -->
    <div class="third">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 collage">
                <div class="collage_contanier clearfix">

                    <!-- <div class="clCont" [@slideFromRight]="aboutUSAnimations.thirdBlock.currentState"> -->
                    <div class="clCont">
                        <div class="collage_item">
                            <img src="../../../../assets/pictures/webSite/aboutUsCollage1.png" alt="item"
                                 class="item">
                        </div>
                        <div class="collage_item">
                            <img src="../../../../assets/pictures/webSite/aboutUsCollage2.png" alt="item"
                                 class="item">
                        </div>
                        <div class="collage_item">
                            <img src="../../../../assets/pictures/webSite/aboutUsCollage3.png" alt="item"
                                 class="item">
                        </div>
                        <div class="collage_item">
                            <img src="../../../../assets/pictures/webSite/aboutUsCollage4.png" alt="item"
                                 class="item">
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 right_text" [@fadeInUp]="aboutUSAnimations.thirdBlock.currentState"> -->
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 right_text">
                <div class="text_container animateChild animated">
                    <span class="over_songs animateChild animated" >Over 6,000+ Songs.</span>
                    <span class="you_name_it animateChild animated">You name it, we've sung it</span>
                    <span class="blue_underline animateChild animated"></span>
                    <span class="choose_from animateChild animated">Choose from the latest and the greatest, to<br> the oldest and the boldest.</span>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="fourth" [scrollimate]="aboutUSAnimations.fourthBlock"> -->
    <div class="fourth">
        <div class="row">
            <!-- <div class="clTxt  col-xl-12 col-lg-12 col-md-12 col-sm-12 animated" [@fadeInUp]="aboutUSAnimations.fourthBlock.currentState"> -->
            <div class="clTxt  col-xl-12 col-lg-12 col-md-12 col-sm-12 animated">
                <span class="plug_in">Plug in, pick the song and</span>
                <span class="lose_yourself">lose yourself in the music</span>
                <p class="hit_share">Then hit 'share' for the world to see it.</p>
                <button type="submit"
                        class="btn cl_sing_now" [routerLink]="['/sign-up']">SING NOW
                </button>
            </div>
            <!-- <div class="carousel_container col-xl-12 col-lg-12 col-md-12 col-sm-12" [scrollimate]="aboutUSAnimations.fourthBlockSlider" [@flipInY]="aboutUSAnimations.fourthBlockSlider.currentState"> -->
            <div class="carousel_container col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="clSubCont">
                    <div class="carousel_body_about_us">

                        <div class="clBtnCont">
                            <a class="slider-left" href="javascript:void(0);"><i class="fa fa-angle-left"
                                                                                 aria-hidden="true"></i></a>
                            <a class="slider-left" href="javascript:void(0);"><i class="fa fa-angle-left"
                                                                                 aria-hidden="true"></i></a>

                            <a class="slider-right" href="javascript:void(0);"><i class="fa fa-angle-right"
                                                                                  aria-hidden="true"></i></a>
                        </div>

                        <div class="slider-content carousel_pictures">

                            <div class="slider-single">
                                <img class="slider-single-image"
                                     src="../../../../assets/pictures/webSite/About-Us-carousel.png"
                                     alt="1"/>
                            </div>

                            <div class="slider-single">
                                <img class="slider-single-image"
                                     src="../../../../assets/pictures/webSite/About-Us-carousel.png"
                                     alt="1"/>
                            </div>

                            <div class="slider-single">
                                <img class="slider-single-image"
                                     src="../../../../assets/pictures/webSite/About-Us-carousel.png"
                                     alt="2"/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="fifth" [scrollimate]="aboutUSAnimations.fifthBlock"> -->
    <div class="fifth">
        <div class="text_container">
            <div>
                <!-- <div [@fadeInUp]="aboutUSAnimations.fifthBlock.currentState"> -->
                <div>
                <span class="share_talent">Share your talent and meet friends</span>
                <span
                    class="from_shower">From shower singers, to professionals, SingSnap was created for every one.<br/>If you love music, you'll love SingSnap!</span>
                <p class="sing_stars">3,000+ Sing Stars</p>
                <button type="submit"
                        class="btn cl_sing_now" [routerLink]="['/sign-up']">JOIN THE STAGE
                </button>
                </div>
                <!-- <div class="cl_related_artists" [scrollimate]="aboutUSAnimations.fifthBlockImages" [@rollIn]="aboutUSAnimations.fifthBlockImages.currentState"> -->
                <div class="cl_related_artists">
                    <img src="../../../../assets/pictures/ArtistsCircle/Artist1.png" alt="">
                    <img src="../../../../assets/pictures/ArtistsCircle/Artist2.png" alt="">
                    <img src="../../../../assets/pictures/ArtistsCircle/Artist4.png" alt="">
                    <img src="../../../../assets/pictures/ArtistsCircle/Artist5.png" alt="">
                    <img src="../../../../assets/pictures/ArtistsCircle/Artist6.png" alt="">
                    <img src="../../../../assets/pictures/ArtistsCircle/Artist8.png" alt="">
                    <img src="../../../../assets/pictures/ArtistsCircle/Artist9.png" alt="">
                    <img src="../../../../assets/pictures/ArtistsCircle/Artist12.png" alt="">
                </div>
            </div>
        </div>
        <img src="../../../../assets/pictures/webSite/aboutUsWave1.png" alt="wave"/>
        <img src="../../../../assets/pictures/webSite/aboutUsWave2.png" alt="wave"/>
    </div>
</div>
