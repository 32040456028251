import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService} from 'ngx-toastr';
import { PlaylistService, ProfileService, UtilService } from '@app/core/services';

@Component({
  selector: 'app-add-to-playlist',
  templateUrl: './add-to-playlist.component.html',
  styleUrls: ['./add-to-playlist.component.scss']
})
export class AddToPlaylistComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  @ViewChild('newPlaylist', { static: true }) private newPlaylistElem;
  @ViewChild('goldcontent', { static: true }) private goldModal;
  // @Output() created: EventEmitter<any> = new EventEmitter();
  playlist = [];
  page = 1;
  total = 0;
  PAGE_SIZE = 15;
  recordingId: any;
  loading = false;
  loadedAll = false;
  selected = [];
  config = {};
  modalRef;
  sort = this.util.playlistSortOptions[0];

  constructor(
    private modalService: NgbModal,
    private playlistService: PlaylistService,
    private profileService: ProfileService,
    private util: UtilService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService
  ) { }

  async loadPlaylist() {
    if (this.loading || this.loadedAll) {
      return;
    }
    this.loading = true;
    this.detectChanges();
    try {
      const result = await this.playlistService.getPlaylists(this.page, this.PAGE_SIZE, this.sort.field, this.sort.asc).toPromise();
      this.playlist.push(...result.data);
      this.page ++;
      this.total = result.total;
      this.loading = false;
      this.loadedAll = !result.next_page_url;
    } catch (err) {

    } finally {
      this.loading = false;
      this.detectChanges();
    }
  }

  scrollEndHandle(event) {
    this.loadPlaylist();
    console.log('ended');
  }

  onToggle(id) {
    const index = this.selected.indexOf(id);
    if (index !== -1) {
      this.selected.splice(index, 1);
    } else {
      this.selected.push(id);
    }
  }

  isSelected(id) {
    return this.selected.indexOf(id) !== -1;
  }

  open(id) {
    this.loadPlaylist();
    this.recordingId = id;
    this.openModal();
  }

  openModal() {
    this.modalRef = this.modalService.open(this.contentElem, {
      windowClass: 'ssc-modal',
      backdrop: 'static',
    });

    this.modalRef.result.then((result) => {
      this.clearData();
    }, (reason) => {
      this.selected.forEach(async pId => {
        const item = this.playlist.find(x => x.id === pId);
        try {
          await this.playlistService.addRecordingToParticularPlaylist(pId, this.recordingId).toPromise();
          this.toastr.success(`Added a song to playlist - ${item.title}`);
        } catch (err) {
          this.toastr.success(`Failed to add a song to playlist - ${item.title}`);
        }
      });
      this.clearData();
    });
  }

  clearData() {
    this.loadedAll = false;
    this.page = 1;
    this.playlist = [];
    this.selected = [];
  }

  close() {

  }

  ngOnInit() {
  }

  onNewPlaylist() {
    this.modalRef.close();
    if (!this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    this.newPlaylistElem.open(this.recordingId);
  }

  newPlaylistCreated() {
    // this.openModal();
    // this.page = 1;
    // this.loadPlaylist();
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }
}
