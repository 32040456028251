import {Component, Input, OnInit} from '@angular/core';

type ReadingTypes = 'gold' | 'premium';
const typeNameMap = {
    gold: 'G',
    premium: 'P'
};

@Component({
    selector: 'app-status-badge',
    templateUrl: './status-badge.component.html',
    styleUrls: ['./status-badge.component.scss']
})
export class StatusBadgeComponent implements OnInit {
    @Input() type: ReadingTypes;
    @Input() name?: string;

    constructor() {
    }

    ngOnInit() {
        this.name = typeNameMap[this.type];
    }
}
