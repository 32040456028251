
  <main  id='recorder'>
    <main id='loading' *ngIf='!loaded'>
      <article id='singsnapContainer' style='padding-right: 2.5rem; display: flex; flex-direction: row; align-items: center; justify-content: center;'>
        <img src='../../assets/images/recorderlogo.png'> <h2>SINGSNAP</h2>
      </article>
      <div class="vl"></div>
      <h2 class="recording-loading" >Loading... <i class="fa fa-spinner fa-pulse fa-fw" style="font-size: 47px; text-shadow: none;"></i></h2>

  </main>
  
      <main id='recorderContainer'>
  
          <section *ngIf='loaded'#topUI id='topUI'>
              <article id='left' class='left'>
                  <app-icon-button color="rgba(255, 255, 255, 0.3)" (click)="backClicked()" *ngIf="!finished">
                      <app-icon-arrow-left color="white"></app-icon-arrow-left>
                  </app-icon-button>
  
              </article>
              <article  id='center'>
                  <div  class="ss-r-title-content">
                      <h2 class="ss-r-title">{{remix.title}}</h2>
                  </div>
              </article>
              <article  id='right' class='right'>
                  <div class="mmsb-switch ss-switch-holder" *ngIf="UI.mmsbButton">
                    <span class="ss-label">MMSB</span>
                    <label class="ss-custom-switch">
                      <input type="checkbox" class="no-disable-color" [(ngModel)]="mmsb" (change)="onChangeMMSB($event)" [disabled]="disableMMSB" />
                      <span class="ss-slider"></span>
                    </label>
                  </div>
                  <button *ngIf="UI.saveOrTryAgainButtons" class="btn btn-lg btn-primary topButtons" style='background-color: white;' (click)="upload()">SAVE</button>
              </article>
  
          </section>
          <section id='videoSection'>
            <main #errorMessageContainer style='display: none; color: red;' class='countdown'>
              <h3  #errorMessage ></h3>
            </main>         
              <div id='lyrics-container'>
                <div id='grave' class='hidden'></div>
                <main id='lines-container'>
                    <div id='the-grave' class='lyrics'></div>
                    <div id='swipe' class='lyrics'></div>
                    <div id='next-up' class='lyrics'></div>
                    <div id='initial' class='lyrics'></div>
                    <div id='the-womb' class='lyrics'></div>
                </main>
                <div id='womb' class='hidden'></div>
            </div>
              <div id='videoContainer'>
                <p id='version'>{{version}}</p>
  
                <div id='actualVideoContainer'>
                  <video #recVideo
                  id="videoRecorder"
                  autoplay="true"
                  style='
                    background-image: url(../../assets/images/audio_only.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    height: 68vh;
                    width: 68vh;'
                  ></video>
                  <video #duetVideo style='display: none; height: 68vh; width: 68vh;'
                  id="duetVideo"
                  poster='../../assets/images/audio_only.png'
                  autoplay="false"
                  ></video>
                  </div>
              </div>
  
              <div class="playerOverlay">
                <!-- RECORD SCREEN CONTROL -->
                <div class="playerOverlayButtonContainer">
                  <app-icon-button *ngIf="UI.pauseButton" color="white" size="40px" (click)="pause()">
                    <app-icon-pause></app-icon-pause>
                  </app-icon-button>
                  <app-icon-button *ngIf="UI.resumePracticeButton" color="white" size="40px" (click)="play()">
                    <app-icon-play-line style="margin-left: 3px;"></app-icon-play-line>
                  </app-icon-button>
                </div>
                <!-- RECORDER TIMELINE -->
                <div class="clock">
                  <span class="currentTime">{{msToTime(playhead)}}</span>
                  <span class="duration"> / {{msToTime(duration)}}</span>
                </div>
              </div>
              <app-progress-bar *ngIf='loaded' [recorder]='true' [duration]="duration" [current]="playhead" (seek)="seek($event)" (range)='changeRangeTime($event)'></app-progress-bar>
          </section>
          <div id='bottomUI' #bottomUI>
            <section id='adjustBarsContainer' *ngIf='containerOpen'>
              <div class="ss-r-adjust-bar" *ngIf='volumeBar'>
                <div class="ss-bar">
                  <div class="ss-bar-item" id='micBar'>
                    <app-icon-mic size="18" color="white" [style.margin]="'0 3px'"></app-icon-mic>
                    <app-adjust-bar
                      [value]="micVolume"
                      [min]="0" [max]="2" [step]="0.02" [multiply]="5" [noFraction]="true"
                      (changed)="onMicVolumeChange($event)">
                    </app-adjust-bar>
                  </div>
                  <div class="ss-bar-item">
                    <app-icon-music size="24" color="white"></app-icon-music>
                    <app-adjust-bar
                      [value]="musicVolume"
                      [min]="0" [max]="2" [step]="0.02" [multiply]="5" [noFraction]="true"
                      (changed)="onMusicVolumeChange($event)">
                    </app-adjust-bar>
                  </div>
                </div>
                <app-icon-button color="#4FEAAE" size="60px" [style.marginLeft]="'30px'" (click)="onVolumeClose()">
                  <app-icon-check size="27" color="white"></app-icon-check>
                </app-icon-button>
              </div>
              <!-- SYNC BAR -->
              <div  id="syncContainer" *ngIf='syncBar'>
              <div class="ss-r-adjust-bar">
                <div class="ss-bar">
                  <div class="ss-bar-item">
                    <app-recorder-sync-bar
                      #syncBar
                      [newRecorder] = 'true'
                      style="width: 100%;"
                      [value]="sync"
                      (changed)="onSyncChange($event)">
                    </app-recorder-sync-bar >
                  </div>
                </div>
                <app-icon-button color="#4FEAAE" size="60px" [style.marginLeft]="'30px'" (click)="onSyncClose()">
                  <app-icon-check size="27" color="white"></app-icon-check>
                </app-icon-button>
              </div>
            </div>
                <div class="ss-r-adjust-bar" *ngIf='lyricBar'>
                  <div class="ss-bar">
                    <div class="ss-bar-item">
                      <app-adjust-bar
                        [value]="lyricSpeed"
                        [stepCount]="20"
                        [min]="-10" [max]="10" [step]="1"
                        (changed)="onLyricSpeedChange($event)">
                      </app-adjust-bar>
                    </div>
                  </div>
                  <app-icon-button color="#4FEAAE" size="60px" [style.marginLeft]="'30px'" (click)="onLyricSpeedClose()">
                    <app-icon-check size="27" color="white"></app-icon-check>
                  </app-icon-button>
                </div>
            </section>
            <section id='buttonsContainer' *ngIf='!containerOpen'>
              <article  class='buttonsSection left'>
                <div *ngIf='UI.volumeButton' class="ss-r-btn noMargin">
                    <app-icon-button color="#e2fbf4" size="60px" (click)="onVolumeClick()">
                      <app-icon-volumn size="27"></app-icon-volumn>
                    </app-icon-button>
                    <h6 class="ss-r-btn-text">VOLUME</h6>
                  </div>
                  <div class="ss-r-btn" *ngIf='UI.syncButton'>
                    <app-icon-button color="#e2fbf4" size="60px" (click)="onSyncClick()">
                      <app-icon-sync size="27"></app-icon-sync>
                    </app-icon-button>
                    <h6 class="ss-r-btn-text">SYNC</h6>
                  </div>
                  <div class="ss-r-btn" *ngIf='UI.lyricButton'>
                    <app-icon-button color="#e2fbf4" size="60px" (click)="onLyricSpeedClick()">
                      <p style="
                      font-size: 33px;
                      margin-top: 15px;
                      ">A</p>
                    </app-icon-button>
                    <h6 style="width: max-content;" class="ss-r-btn-text">LYRIC SPEED</h6>
                  </div>
              </article>

            </section>
          </div>
  
      </main>
  
      
  </main>