<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path
        d="M17,-0.00015 L17,-0.00015 C17.5522847,-0.00015 18,0.44756525 18,0.99985 L18,16.99985 C18,17.5521347 17.5522847,17.99985 17,17.99985 L17,17.99985 C16.4477153,17.99985 16,17.5521347 16,16.99985 L16,0.99985 C16,0.44756525 16.4477153,-0.00015 17,-0.00015 Z M2,2.7321 L2,15.2681 L12.856,9.0001 L2,2.7321 Z M1,18.0001 C0.827,18.0001 0.654,17.9561 0.5,17.8661 C0.19,17.6881 8.8817842e-16,17.3571 8.8817842e-16,17.0001 L8.8817842e-16,1.0001 C8.8817842e-16,0.6431 0.19,0.3131 0.5,0.1341 C0.809,-0.0449 1.191,-0.0449 1.5,0.1341 L15.356,8.1341 C15.666,8.3131 15.856,8.6431 15.856,9.0001 C15.856,9.3571 15.666,9.6881 15.356,9.8661 L1.5,17.8661 C1.346,17.9561 1.173,18.0001 1,18.0001 L1,18.0001 Z"
        id="icon-play-next"></path>
    </defs>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icons/Play">
        <mask id="mask-2" fill="white">
          <use xlink:href="#icon-play-next"></use>
        </mask>
        <use id="Fill-1" [attr.fill]="color" xlink:href="#icon-play-next"></use>
      </g>
    </g>
  </svg>
</div>