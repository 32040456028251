import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@env/environment';

@Component({
  selector: 'app-settings-lyric',
  templateUrl: './settings-lyric.component.html',
  styleUrls: ['./settings-lyric.component.scss']
})
export class SettingsLyricComponent implements OnInit {
  @Output() changed = new EventEmitter();

  colors = [
    {
      title: 'Male part',
      key: 'male',
      color: '#64a4e8'
    },
    {
      title: 'Together part',
      key: 'female',
      color: '#ff6a71'
    },
    {
      title: 'Second singer',
      key: 'together',
      color: '#f7c346'
    },
    {
      title: 'Female part',
      key: 'part1',
      color: '#4cff9d'
    },
    {
      title: 'First singer',
      key: 'part2',
      color: '#bc0dff'
    },
    {
      title: 'Other singer',
      key: 'partall',
      color: '#be5b04'
    }
  ];

  constructor(
    private toastService: ToastrService,
    private router: Router,
  ) { }

  ngOnInit() {
    const colors = Cookie.get(environment.settings.lyricColor);
    if (colors) {
      this.colors = JSON.parse(colors);
    }
  }

  handleChange() {
    this.changed.emit({ colors: this.colors });
  }

  handleSave() {
    Cookie.set(environment.settings.lyricColor, JSON.stringify(this.colors));
    this.toastService.success('Updated lyrics color.');
    this.goBack();
  }

  goBack() {
    this.router.navigate(['/d/settings/general']);
  }
}
