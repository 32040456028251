import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-icon-button-small',
  templateUrl: './icon-button-small.component.html',
  styleUrls: ['./icon-button-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonSmallComponent implements OnInit {

  @Input() color = '#fff';
  @Input() border = null;
  @Input() size = '40px';
  @Input() shadow = false;
  constructor() { }

  ngOnInit() {
  }

}
