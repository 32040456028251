import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MessageBoardService, ProfileService, RecordingService, SongService} from '@app/core';
import {ToastrService} from 'ngx-toastr';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BlockModalComponent} from '@app/shared/block-modal/block-modal.component';

@Component({
  selector: 'app-report-modal-content',
  templateUrl: './report-modal-content.component.html',
  styleUrls: ['./report-modal-content.component.scss']
})
export class ReportModalContentComponent implements OnInit {
  reportGroup: UntypedFormGroup;
  reasons: Array<any> = [];
  showReportThanks = false;
  reportModalRef = null;
  reportThankyouModalRef = null;
  user$ = this.profiles.getUserProfile();
  user;
  @Input() id: string;
  @Input() type: string = '';
  @Input() comment;
  @Input() commentUserId;
  @ViewChild('thankReportContent', { static: true }) thankReportContent;
  @ViewChild('blockModal', { static: true }) blockModal: BlockModalComponent;

  constructor(private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private messageboardService: MessageBoardService,
              private songService: SongService,
              private recordingService: RecordingService,
              private profiles: ProfileService,
              private modalService: NgbModal,
              public activeModal: NgbActiveModal) {
    this.reasons = [
      {
        value: 'offensive',
        name: 'Offensive'
      },
      {
        value: 'harassment',
        name: 'Harassment'
      }
    ];
  }

  ngOnInit() {
    this.createReportForm();

  }

  hasSubReasons() {
    return this.reportGroup
      && this.reportGroup.value
      && this.reportGroup.value.reason
      && Array.isArray(this.reportGroup.value.reason.value);
  }

  createReportForm() {

    if (this.type === 'message-board') {
      this.reasons.push({
        value: 'spam',
        name: 'Spam'
      });
    }

    if (this.type === 'user') {
      this.reasons = [
        {
          value: 'spam',
          name: 'Spam'
        },
        {
          value: 'harassment',
          name: 'Harassment'
        }
      ];
    }

    if (this.type === 'song') {
      this.reasons = [
        {
          value: 'lyrics',
          name: 'Lyrics'
        },
        {
          value: 'copyright',
          name: 'Copyright'
        }
      ];
    }

    if (this.type === 'recording') {
      this.reasons = [
        {
          value: 'underage',
          name: 'This user is underage'
        },
        {
          name: 'Inappropriate Content/Conduct',
          value: [
            {
              value: 'nudity',
              name: 'Nudity'
            },
            {
              value: 'drugs-violence-selfharm',
              name: 'Drugs, Violence or Self-Harm'
            },
            {
              value: 'offensive',
              name: 'Offensive'
            }
          ]
        },
        {
          value: 'harassment',
          name: 'Harassment'
        },
        {
          value: 'copyright',
          name: 'Copyright'
        }
      ];
    }

    this.reportGroup = this.fb.group({
      reason: [this.reasons, Validators.required],
      subreason: [null],
      message: [''],
    });
    this.reportGroup.controls['reason'].setValue(null, {onlySelf: true});
  }

  report(thankReportContent) {
    if (this.reportGroup.valid) {
      const report = {} as any;
      report.reason = this.reportGroup.value.reason.value;
      report.message = this.reportGroup.value.message;
      this.reportMethod(report, thankReportContent);
    } else {
      if (!this.reportGroup.value.reason) {
        this.toastr.error('Please select a report reason.');
      }
    }
  }

  reportMethod(report, thankReportContent) {
    this.closeReportModal();
    if (this.type === 'message-board') {
      this.messageboardService.reportComment(this.id, report).subscribe(response => {
        if (response.success) {
          this.toastr.success(response.message);
          this.showReportThanks = true;
          this.reportThankyouModalRef = this.modalService.open(thankReportContent, {windowClass: 'report-thankyou-modal'});
        } else {
          this.toastr.error('There\'s an error, please try again.');
        }
      }, response => {
        this.toastr.error('There\'s an error, please try again later.');
      });
    }

    if (this.type === 'comments') {
      this.recordingService.reportComment(this.id, report).subscribe(response => {
        if (response.success) {
          this.toastr.success(response.message);
          this.showReportThanks = true;
          this.reportThankyouModalRef = this.modalService.open(thankReportContent, {windowClass: 'report-thankyou-modal'});
        } else {
          this.toastr.error('There\'s an error, please try again.');
        }
      }, response => {
        this.toastr.error('There\'s an error, please try again later.');
      });
    }

    if (this.type === 'thread') {
      this.messageboardService.reportThread(this.id, report).subscribe(response => {
        if (response.success) {
          this.toastr.success(response.message);
          this.showReportThanks = true;
          this.reportThankyouModalRef = this.modalService.open(thankReportContent, {windowClass: 'report-thankyou-modal'});
        } else {
          this.toastr.error('There\'s an error, please try again.');
        }
      }, response => {
        this.toastr.error('There\'s an error, please try again later.');
      });
    }

    if (this.type === 'song') {
      this.songService.reportSong(this.id, report).subscribe(response => {
        if (response.success) {
          this.toastr.success(response.message);
          this.showReportThanks = true;
          this.reportThankyouModalRef = this.modalService.open(thankReportContent, {windowClass: 'report-thankyou-modal'});
        } else {
          this.toastr.error('There\'s an error, please try again.');
        }
      }, response => {
        this.toastr.error('There\'s an error, please try again later.');
      });
    }

    if (this.type === 'recording') {

      this.recordingService.reportRecording(this.id, report).subscribe(response => {
        if (response.success) {
          this.toastr.success(response.message);
          this.showReportThanks = true;
          this.reportThankyouModalRef = this.modalService.open(thankReportContent, {windowClass: 'report-thankyou-modal'});
        } else {
          this.toastr.error('There\'s an error, please try again.');
        }
      }, response => {
        this.toastr.error('There\'s an error, please try again later.');
      });
    }

    if (this.type === 'user') {
      
      this.profiles.reportUser(this.id, report).subscribe(response => {
        if (response.success) {
          this.toastr.success(response.message);
          this.showReportThanks = true;
          this.reportThankyouModalRef = this.modalService.open(thankReportContent, {windowClass: 'report-thankyou-modal'});
        } else {
          this.toastr.error('There\'s an error, please try again.');
        }
      }, response => {
        this.toastr.error('There\'s an error, please try again later.');
      });
    }

  }

  closeReportModal() {
    this.activeModal.close('cancel');
  }

  closeThankyouReportModal() {
    this.reportThankyouModalRef.close('cancel');
  }

  block() {
     if (this.type === 'comments') {
        this.profiles.getProfile(this.commentUserId).subscribe(user => {
        this.user = user;
        this.blockModal.open('Block user', this.user);
        this.reportThankyouModalRef.close('cancel');
     });
    } else if (this.type === 'recording') {
      this.recordingService.get(this.id).subscribe(recording => {
        this.user = recording.user;
        this.blockModal.open('Block user', this.user);
        this.reportThankyouModalRef.close('cancel');
      });
    } else if (this.type === 'thread') {
      this.messageboardService.getTopic(this.id).subscribe(topic => {
        this.user = topic.user;
        this.blockModal.open('Block user', this.user);
        this.reportThankyouModalRef.close('cancel');
      });
    }
  }
}
