<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M2,4.99961498 L2,13.000385 C2,13.5559714 2.44721709,14 3.0085302,14 L10.9914698,14 C11.5478977,14 12,13.5503221 12,13.000385 L12,4.99961498 C12,4.44402857 11.5527829,4 10.9914698,4 L3.0085302,4 C2.45210227,4 2,4.44967787 2,4.99961498 Z M13.987423,4.72236785 L16.7574644,4.0298575 C17.3886111,3.87207082 18,4.34942885 18,5 L18,13 C18,13.6505712 17.3886111,14.1279292 16.7574644,13.9701425 L13.9873001,13.2776014 C13.8467184,14.8045162 12.5565056,16 10.9914698,16 L3.0085302,16 C1.34583835,16 0,14.663757 0,13.000385 L0,4.99961498 C0,3.3426939 1.34993292,2 3.0085302,2 L10.9914698,2 C12.5606906,2 13.8476814,3.19022755 13.987423,4.72236785 Z M14,6.78077641 L14,11.2192236 L16,11.7192236 L16,6.28077641 L14,6.78077641 Z"
        id="path-icon-video"></path>
    </defs>
    <g id="Icons/Video" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlink:href="#path-icon-video"></use>
      </mask>
      <use id="Combined-Shape" [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-icon-video"></use>
    </g>
  </svg>
</div>