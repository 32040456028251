<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" stroke="currentColor" stroke-linecap="round"
    stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path [attr.stroke]="color" fill="transparent" d="M3 18v-6a9 9 0 0 1 18 0v6" id="path1249" />
    <path [attr.stroke]="color" fill="transparent"
      d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"
      id="path1251" />
    <text xml:space="preserve"
      style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:30.50445175px;line-height:1.25;font-family:Arial;-inkscape-font-specification:Arial;text-align:center;letter-spacing:0px;word-spacing:0px;text-anchor:middle;fill:#000000;fill-opacity:1;stroke:none;stroke-width:2"
      x="-3.2721627" y="24.941448" id="text1285" transform="matrix(0.48356343,-0.6226874,0.99679894,0.78439732,0,0)">
      <tspan [attr.fill]="color" sodipodi:role="line" id="tspan1283" x="-3.2721624" y="24.941448" style="stroke-width:2">I</tspan>
    </text>
    <text xml:space="preserve"
      style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:26.66666603px;line-height:1.25;font-family:Arial;-inkscape-font-specification:Arial;text-align:center;letter-spacing:0px;word-spacing:0px;text-anchor:middle;fill:#000000;fill-opacity:1;stroke:none"
      x="6.8507462" y="1.4328358" id="text1289">
      <tspan [attr.fill]="color" sodipodi:role="line" id="tspan1287" x="6.8507462" y="25.452953" /></text>
  </svg>
</div>
