import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuillModule } from 'ngx-quill';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ClickOutsideModule } from 'ng-click-outside';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { SharedModule } from '../shared';
import { SharedIconsModule } from '../shared/icons/icons.module';
import { SingReComponent } from './sing-re.component';
import { SingRecorderComponent } from './sing-recorder/sing-recorder.component';
import { RemixRecordingComponent } from './remix-recording/remix-recording.component';

import { SingUploaderComponent } from './sing-uploader/sing-uploader.component';
import { SingUploadSuccessComponent } from './sing-upload-success/sing-upload-success.component';
import { SingFeaturedSongsComponent } from './sing-featured-songs/sing-featured-songs.component';
import { SingFavoriteSongsComponent } from './sing-favorite-songs/sing-favorite-songs.component';
import { SingArtistsComponent } from './sing-artists/sing-artists.component';
import { SingOpenDuetsComponent } from './sing-open-duets/sing-open-duets.component';
import { SingDuetsComponent } from './sing-duets/sing-duets.component';
import { AdsenseModule } from 'ng2-adsense';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollY: true,
    suppressScrollX: false,

};
@NgModule({
    imports: [
        NgbModule,
        FormsModule,
        InfiniteScrollModule,
        CommonModule,
        RouterModule,
        QuillModule,
        ClickOutsideModule,
        SharedModule,
        SharedIconsModule,
        PerfectScrollbarModule,
        AdsenseModule.forRoot({
            // adClient: 'ca-pub-4776037543768273', 
            // adSlot: 9989671476,
           }),
    ],
    declarations: [
        SingReComponent,
        SingRecorderComponent,
        SingUploaderComponent,
        SingUploadSuccessComponent,
        RemixRecordingComponent,
        SingFeaturedSongsComponent,
        SingFavoriteSongsComponent,
        SingArtistsComponent,
        SingOpenDuetsComponent,
        SingDuetsComponent
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SingReModule {
}
