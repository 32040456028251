<div class="row">
    <div class="col-12 col-sm-8">
        <app-content-box title="About" *ngIf="infoItems.length">
            <div [innerHtml]="user?.bio"></div>
        </app-content-box>

        <app-content-box title="About" *ngIf="!infoItems.length">
            <div class="skeleton-about skeleton-color"></div>
        </app-content-box>

    </div>
    <div class="col-12 col-sm-4">
        <div style="margin-bottom: 30px;">
            <app-simple-list title="Info" *ngIf="infoItems.length" [list]="infoItems">
            </app-simple-list>

            <app-content-box title="Info" *ngIf="!infoItems.length">
                <div *ngFor="let e of [].constructor(6)">
                    <div class="skeleton-list">
                        <div class="skeleton-text skeleton-color"></div>
                        <div class="skeleton-value skeleton-color"></div>
                    </div>
                </div>
            </app-content-box>
        </div>

    <app-featured-list
        *ngIf="fans.length"
        [userIdFromProfile]="userId" 
        seeMorePage="MyListenMore:fans" 
        [hasSeeMore] = "true" 
        title="Fans" [featuredList]="fans" 
        noRow="true" 
        [showSort]="false"
        >
            <ng-template #itemContent let-item>
                <div class="ssc-list-item">
                    <app-ss-user [user]="item"></app-ss-user>
                </div>
                <!-- <app-user-message-info [user]="item" [showBio]="false"></app-user-message-info>                 -->
            </ng-template>
    </app-featured-list>

    <app-featured-list
        *ngIf="!fans.length"
        [userIdFromProfile]="userId" 
        seeMorePage="MyListenMore:fans" 
        [hasSeeMore] = "true" 
        title="Fans" [featuredList]="fans" 
        noRow="true" 
        [loading]="loadingFans"
        [showSort]="false"
        >   <ng-template #itemContent let-item>
                <div class="ssc-list-item">
                    <app-ss-user [user]="item"></app-ss-user>
                </div>
            </ng-template>
    </app-featured-list>
    </div>
</div>

<div class="row" style="margin-top: 40px;">
    <div class="col-12 col-sm-8">
        <app-featured-grid
            *ngIf="recordings"
            title="Recordings"
            cols="1"
            [showAction]="false"
            [hasSeeMore]="false"
            [list]="recordings">
            <ng-template #itemContent let-item>
                <div class="col-auto px-0 align-items-center">
                    <div class="featured-list--item--poster rounded-circle"
                         [ngStyle]="{'background-image': 'url('+item?.song?.photo+')'}">
                        <img [src]="item?.song?.photo" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="col featured-item--body align-items-center">
                    <h1>{{item?.song?.title}}</h1>
                </div>
                <div class="col featured-item--body align-items-center">
                    <app-user-badge-level [user]="item?.user">
                    </app-user-badge-level>
                </div>
                <div class="col-auto featured-list--item-action align-items-center">
                    <a class="btn btn-primary fa fa-chevron-right" [routerLink]="['/d/listen/', item?.id]" role="button">
                    </a>
                </div>
            </ng-template>
        </app-featured-grid>
    </div>
</div>


<ng-template #skeleton>





</ng-template>