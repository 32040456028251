import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-new',
  templateUrl: './icon-new.component.html'
})
export class IconNewComponent implements OnInit {
  @Input() color = '#262628';
  @Input() size = 16;

  constructor() { }

  ngOnInit() {
  }

}
