<div id='seeMoreContainer'>
  <div class='headerContainer'>
    <div class='title'>
      <app-icon-button color="white" (click)="back()" >
      <app-icon-arrow-left color="gray"></app-icon-arrow-left>
      </app-icon-button>
      <h4>Listen - Favorite Members</h4>
    </div>
  </div>

    <app-ss-featured-grid 
    [scrollable]="false"
    title="Listen - Favorite Members"
    seeMorePage="ListenFavoriteMembers"
    [hasSeeMore] = "false"
    [list]="byFavData.items" 
    [loading]="byFavData.loading" 
    [cols]="3"
    [colsLg]="3"
    [colsMd]="2"
    [colsSm]="1"
    [checkOwnRecording]="true"
    [extendedSize] = "true"
    [sortOptions]="recordingSortOptions"
    (onScrollEnd)="onScrollEndHandle()" 
    (onOrderBy)="resetDataObj(byFavData);setSortFields($event);onScrollEndHandle()">
    <ng-template #itemContent let-item>
      <div class="ssc-list-item" class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
        <app-ss-recording [hideUser]="true" [linkActive]="false" [recording]="item" (click)="navListen(item?.id)" ></app-ss-recording>
        <div class="song-actions">
          <app-more-dot-menu title="Options">
            <div class="dropdown-menu-content">
              <div *ngIf="!item.favorited" (click)="favRecording(item)">Add Recording to Favorites</div>
              <div *ngIf="item.favorited" (click)="unFavRecording(item)">Remove Recording from Favorites</div>
              <div (click)="onAddtoPlaylist(item.id)">Add to Playlist</div>
              <div (click)="navProfile(item.user_id)">Go to Profile Page</div>
              <!-- <div (click)="handleDuet(item)">Duet / Jam</div> -->
              <!-- <div (click)="shareRecording(item)">Share this recording</div> -->
              <div (click)="howOthersSingThis(item.song.id)">How Others Sing This</div>
            </div>
          </app-more-dot-menu>
        </div>
      </div>
      <app-recording-stat  (click)="navListen(item?.id)" [recording]="item"></app-recording-stat>
    </ng-template>
    </app-ss-featured-grid>
    <app-vert-space height="40"></app-vert-space>
    <app-add-to-playlist #playlist></app-add-to-playlist>


    
  <div  id="pageNavContainer"  style="margin-bottom: 13px;">
    <button *ngIf='byFavData.currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
    <p >{{currentPage}}</p>
    <button *ngIf='byFavData.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
  </div>


</div>