import {Component, OnInit, ChangeDetectorRef, NgZone} from '@angular/core';
import {RecordingService, ProfileService} from '@app/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
@Component({
    selector: 'app-user-profile-featured-on',
    templateUrl: './user-profile-featured-on.component.html',
    styleUrls: ['./user-profile-featured-on.component.scss']
})
export class UserProfileFeaturedOnComponent implements OnInit {
    recordingItem; //for harmony tag
    featuredRecordings;
    userId;
    data = {
        items: [],
        rqing: false,
        sort: 'created_at',
        lastPage: 1,
        currentPage: 1,
        perPage: 20,
        fetch: this.recordingService.getUsersFeaturedRecordingsV2.bind(this.recordingService)
    };

    currentPage=1;
    orderBy = {sorttype: 'created_at', direction: 'DESC'};
    orderDirection = 'ASC';

    constructor(private recordingService: RecordingService,
        private profileService: ProfileService,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private toastService: ToastrService,
        private ngZone: NgZone,
        private router: Router,
        private cdr: ChangeDetectorRef) {
        this.activatedRoute.parent.params.subscribe(params => {
            console.log(params)
            this.userId = params.id;
        });
        this.activatedRoute.queryParams.subscribe(params => {
          if(typeof params.page != 'undefined') {
            this.currentPage = params.page
            this.data.currentPage = params.page
          }
        });
    }

    public navigate(commands: any[]): void {
        this.ngZone.run(() => this.router.navigate(commands)).then();
      }

    ngOnInit() {
        if(this.userId) {
            this.data.fetch(this.userId, this.data.currentPage, this.data.perPage).subscribe(response => {
                this.featuredRecordings = response.data;
            });
        } else {
            this.profileService.getUserProfile().subscribe(user => {
                console.log(user['id'])
                this.userId = user['id'];
                this.data.fetch(this.userId, this.data.currentPage, this.data.perPage).subscribe(response => {
                    this.featuredRecordings = response.data;
                });
            });
        }
        this.getFirstPageRecordings();
    }

    getFirstPageRecordings(){
        if (!this.data.rqing && this.data.lastPage) {
            this.data.rqing = true;
            this.detectChanges();
            console.log(this.userId)
            if (!this.userId) {
                setTimeout(() => {
                    this.data.fetch(this.userId, this.currentPage, this.data.perPage, this.orderBy)
                    .subscribe(response => {
                    this.data.currentPage = this.data.currentPage + 1;
                    this.setPageParam()
                    this.data.items = response.data;
                    console.log(this.data.items)
                    this.data.lastPage = response.next_page_url;
                    this.data.rqing = false;
                    this.detectChanges();
                    }, error => {
                        this.toastService.error(error.message);
                    });
                }, 1000)
            } else {
                this.data.fetch(this.userId, this.currentPage, this.data.perPage, this.orderBy)
                .subscribe(response => {
                this.data.currentPage = this.data.currentPage + 1;
                this.setPageParam()
                this.data.items = response.data;
                console.log(this.data.items)
                this.data.lastPage = response.next_page_url;
                this.data.rqing = false;
                this.detectChanges();
                }, error => {
                    this.toastService.error(error.message);
                });
            }
        }
    }

    getPrevRecordings(){
        if(this.currentPage<=1){
            return;
        }
        this.currentPage--, scrollTo(0, 500);
        if (!this.data.rqing) {
            this.currentPage = 1;
            this.data.rqing = true;
            this.detectChanges();
            console.log(this.userId)
            if (!this.userId) {
                setTimeout(() => {
                    this.data.fetch(this.userId, this.currentPage, this.data.perPage, this.orderBy)
                    .subscribe(response => {
                    this.data.currentPage = this.data.currentPage + 1;
                    this.setPageParam()
                    this.data.items = response.data;
                    console.log(this.data.items)
                    this.data.lastPage = response.next_page_url;
                    this.data.rqing = false;
                    this.detectChanges();
                    }, error => {
                        this.toastService.error(error.message);
                    });
                }, 1000)
            } else {
                this.data.fetch(this.userId, this.currentPage, this.data.perPage, this.orderBy)
                .subscribe(response => {
                this.data.currentPage = this.data.currentPage + 1;
                this.setPageParam()
                this.data.items = response.data;
                console.log(this.data.items)
                this.data.lastPage = response.next_page_url;
                this.data.rqing = false;
                this.detectChanges();
                }, error => {
                    this.toastService.error(error.message);
                });
            }
        }
    }
    setPageParam() {
      this.location.replaceState(this.location.path().split('?')[0], '');
      this.location.replaceState(this.location.path() + `?page=${this.currentPage}`);
    }
    checkMine(item){
        return item.favorited;
      }


    getNextRecordings(){
        if (!this.data.rqing && this.data.lastPage) {
            if(this.currentPage==this.data.lastPage){
                return;
            }
            this.currentPage++, scrollTo(0, 500);
            this.data.rqing = true;
            this.detectChanges();
            console.log(this.userId)
            if (!this.userId) {
                setTimeout(() => {
                    this.data.fetch(this.userId, this.currentPage, this.data.perPage, this.orderBy)
                    .subscribe(response => {
                    this.data.currentPage = this.data.currentPage + 1;
                    this.data.items = response.data;
                    console.log(this.data.items)
                    this.data.lastPage = response.next_page_url;
                    this.data.rqing = false;
                    this.detectChanges();
                    }, error => {
                        this.toastService.error(error.message);
                    });
                }, 1000)
            } else {
                this.data.fetch(this.userId, this.currentPage, this.data.perPage, this.orderBy)
                .subscribe(response => {
                    this.data.currentPage = this.data.currentPage + 1;
                    this.data.items = response.data;
                console.log(this.data.items)
                this.data.lastPage = response.next_page_url;
                this.data.rqing = false;
                this.detectChanges();
                }, error => {
                    this.toastService.error(error.message);
                });
            }
        }


    }


    handleOrderBy(orderBy) {
        //orderBy = {sorttype: 'created_at', direction: 'DESC'};
        this.orderBy = orderBy;
        this. getFirstPageRecordings();
    }
    resetObj() {
        this.data.rqing = false;
        this.data.items = [];
        this.data.currentPage = 1;
        this.data.lastPage = 1;
        this.currentPage=1;
    }

    getItems() {
        console.log
        this.recordingService.getUsersFeaturedRecordings(this.userId)
            .subscribe( response => {
                this.featuredRecordings = response.data;
                console.log(this.featuredRecordings)
        });
    }


    onScrollEndHandle(data, sort = 'created_at') {
        if (typeof sort !== 'object') {
            if (sort) {
                sort = this.cleanSort(sort);
                data.sort = sort;
            }
        }
        console.log('onScrollEndHandle');

        if (!data.rqing && data.lastPage) {
            data.rqing = true;
            this.detectChanges();
            console.log(this.userId)
            if (!this.userId) {
                setTimeout(() => {
                    data.fetch(this.userId, data.currentPage, data.perPage, data.sort)
                    .subscribe(response => {
                    data.currentPage = data.currentPage + 1;
                    data.items = data.items.concat(response.data);
                    console.log(data.items)
                    data.lastPage = response.next_page_url;
                    data.rqing = false;
                    this.detectChanges();
                    }, error => {
                        this.toastService.error(error.message);
                    });
                }, 1000)
            } else {
                data.fetch(this.userId, data.currentPage, data.perPage, data.sort)
                .subscribe(response => {
                data.currentPage = data.currentPage + 1;
                data.items = data.items.concat(response.data);
                console.log(data.items)
                data.lastPage = response.next_page_url;
                data.rqing = false;
                this.detectChanges();
                }, error => {
                    this.toastService.error(error.message);
                });
            }


        }
    }

    resetDataObj(data) {
        data.currentPage = 1;
        data.rqing = false;
        data.items = [];
        return data;
    }

    cleanSort(sort) {
        if (typeof sort === 'string') {
            if (sort === 'popular') {
                return 'popularity';
            } else {
                return 'created_at';
            }
        } else {
            return 'created_at';
        }
    }

    detectChanges() {
        this.cdr.markForCheck();
        this.cdr.detectChanges();
    }

    favRecording(recording) {
        console.log(recording);
        this.recordingService.fav(recording.id).subscribe(response => {
            recording.favorited = response.model.favorited;
            if (response.success) {
                this.toastService.success('Recording was added to favorites.');
            } else {
                this.toastService.error('There was an error, try again later.');
            }
        });
    }

    unFavRecording(recording) {
        this.recordingService.unfav(recording.id).subscribe(response => {
            recording = response.model;
            if (response.success) {
                this.toastService.success('Recording was removed from favorites.');
            } else {
                this.toastService.error('There was an error, try again later.');
            }
        });
    }


 //harmony Tags
 setRecording(item){
    this.recordingItem = item;

 }
 getRecordingTag(recordingItem, currentTruncateTextLength) {

     if( !recordingItem.badges.name){return ;}
     this.setRecording(recordingItem);

     let titleLength = recordingItem.song.title.length;
     let tagTypeName = recordingItem.badges.name;
     let tagTypeAmount : number ;
     let tagNameLong = "";
     let tagName = "";
     let truncateLength: number;

     tagTypeAmount = recordingItem.badges.qty;
     tagNameLong = tagTypeName + (tagTypeAmount>2?  ":" + tagTypeAmount : '');

     if ( (titleLength + tagNameLong.length)  <= currentTruncateTextLength  ){
         tagName = tagNameLong;
     } else{
         tagName = tagTypeName[0] + (tagTypeAmount>2?  ":" + tagTypeAmount : '');
     }
     return tagName ;
   }

   setColor(firstLetter){
     let color: any;
     switch(firstLetter){
       case 'h':
       case 'd':
         color='#58C7C9';
         break;
       case 'j':
         color='#cd00cd';
         break;
     }
     return color;
   }

   getStyle(recording){
     let outColor: string;
     if(recording.badges.name){
       outColor = this.setColor(recording.badges.name[0]);
       return {'color': outColor, 'border-color': outColor  };
     }
     return ;
   }

   getTruncateValue(recording, currentTruncateTextLength){
     if( !recording.badges.name){return currentTruncateTextLength;}
     let titleLength = recording.song.title.length;
     let tagTypeName = recording.badges.name;
     let tagTypeAmount : number ;
     let tagNameLong = "";
     let tagName = "";
     let truncateLength: number;

     tagTypeAmount = recording.badges.qty;
     tagNameLong = tagTypeName + (tagTypeAmount>2?  ":" + tagTypeAmount : '');

     if ( (titleLength + tagNameLong.length)  <= currentTruncateTextLength  ){
         tagName = tagNameLong;
     } else{
         tagName = tagTypeName[0] + (tagTypeAmount>2?  ":" + tagTypeAmount : '');
     }
     return truncateLength = (currentTruncateTextLength - tagName.length);
   };
   formatViews(views) {
        if (views > 999) {
            views = views / 1000;
            views = views.toFixed(1).replace(/[.,]0$/, "");
            // console.log(views + 'K', "panda");
            return views + 'K';
        } else {
            return views;
        };
    };

    formatLove(love) {
        if (love > 999) {
            love = love / 1000;
            love = love.toFixed(1).replace(/[.,]0$/, "");
            // console.log(love + 'K', "cupid");
            return love + 'K';
        } else {
            return love;
        };
    };
    formatComments(comments) {
        if (comments > 999) {
            comments = comments / 1000;
            comments = comments.toFixed(1).replace(/[.,]0$/, "");
            // console.log(comments + 'K', "cupid");
            return comments + 'K';
        } else {
            return comments;
        };
    };

    howOthersSingThis(id){
        this.navigate([`/d/ListenOthersSing/${id}`]);
    }
}
