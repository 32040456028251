import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { HttpService } from './http.service';
import { environment } from '@env/environment';

@Injectable()
export class SingService extends HttpService {
  sortGenresSongs = new EventEmitter();
  private reloadArtists = new Subject<any>();
  reloadArtistsData$ = this.reloadArtists.asObservable();
  private reloadSongs = new Subject<any>();
  reloadSongsData$ = this.reloadSongs.asObservable();
  private reloadDuets = new Subject<any>();
  reloadDuetsData$ = this.reloadDuets.asObservable();
  private reloadGenres = new Subject<any>();
  reloadGenresData$ = this.reloadGenres.asObservable();

  constructor(private http: HttpClient) {
    super();
  }

  addSongToParticularPlaylist(playlistId, recordId) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/playlists/${playlistId}/songs/${recordId}`);
    return this.http.post(url, {}, options);
  }
  getFrames() {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/frames`);
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
  getSongs(nextPage) {
    const options = this.getHeaders();
    return this.http
      .get(nextPage, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getArtists(nextPage) {
    const options = this.getHeaders();
    return this.http
      .get(nextPage, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getArtistSongs(artistId, query) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/artists/${artistId}/songs`, { query });
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getSingGenres(url) {
    const options = this.getHeaders();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getPlaylists(nextPage) {
    const options = this.getHeaders();
    return this.http
      .get(nextPage, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  reloadAllArtists(data: any) {
    this.reloadArtists.next(data);
  }

  reloadAllSongs(data: any) {
    this.reloadSongs.next(data);
  }

  reloadAllDuets(data: any) {
    this.reloadDuets.next(data);
  }

  reloadAllGenres(data: any) {
    this.reloadGenres.next(data);
  }

  checkIfSongIsAlreadyFavorite(songId) {
    const options = this.getHeaders();
    //options.headers.append('Site-Host', 'As)_(ko;8as^65r');
    return this.http
      .get(this.root + '/songs/' + songId + '/favorited', options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
  removeSongFromFavorite(songId) {
    const options = this.getHeaders();
    const url = this.root + '/songs/' + songId + '/favorite';
    return this.http
      .delete(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
  getUsersFollowersForOpenDuet() {
    const options = this.getHeaders();
    return this.http
      .get(this.root + '/user/following', options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  deletePlaylist(url) {
    const options = this.getHeaders();
    return this.http
      .delete(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getCarouselData() {
    const options = this.getHeaders();
    return this.http
      .get(this.root + '/songs/featured', options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
  removeSongFromPlaylist(playlistId, songId) {
    const options = this.getHeaders();
    const url =
      this.root + '/user/playlists/' + playlistId + '/songs/' + songId;
    return this.http
      .delete(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
  favoriteSong(songId) {
    const options = this.getHeaders();
    const url = this.root + '/songs/' + songId + '/favorite/';
    return this.http
      .post(url, {}, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  startRecording(id, duet = false) {
    const options = this.getHeaders();
    const branch = duet ? 'recordings' : 'songs';
    const url = `${this.root}/${branch}/${id}/start/`;
    return this.http
      .post(url, {}, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  stopRecording(id, uuid, duet = false) {
    const options = this.getHeaders();
    const branch = duet ? 'recordings' : 'songs';
    const url = `${this.root}/${branch}/${id}/stop`;
    return this.http
      .post(url, { uuid }, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  uploadRecording(payload) {
    const options = this.getHeaders();
    const url = environment.uploaderAPIUrl;
    return this.http
      .post(url, payload, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getDefaultArtworks(per_page = 50) {
    const options = this.getHeaders();
    return this.http
      .get(this.root + `/media/artwork?per_page=${per_page}`, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
}
