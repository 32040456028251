<ng-template #content let-modal>
  <div class="modal-body">
    <div class="big-success-icon">
      <span>
        <span class="fa fa-check"></span>
      </span>
    </div>
    <h4 class="text-center">{{ title }}</h4>
    <p class="text-center message">
      {{ message }}
    </p>
    <p class="text-center">
      <button
        class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray"
        (click)="cancel()"
      >
        CANCEL
      </button>
      <button class="btn ss-btn-primary" (click)="close()">OK</button>
    </p>
  </div>
</ng-template>
