<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path
        d="M14.1055728,12.4472136 C14.0361451,12.3083582 14,12.1552451 14,12 L14,6.009763 C14,4.34200706 12.6618557,3 11.0068977,3 L4.99310226,3 C3.33978474,3 2,4.3398874 2,5.99310226 L2,12.0068977 C2,13.6600414 3.34110174,15 4.99620271,15 L15.381966,15 L14.1055728,12.4472136 Z M17.8944272,15.5527864 C18.2268777,16.2176875 17.743382,17 17,17 L4.99620271,17 C2.23691386,17 0,14.7649929 0,12.0068977 L0,5.99310226 C0,3.2353522 2.23518094,1 4.99310226,1 L11.0068977,1 C13.7677178,1 16,3.23872605 16,6.009763 L16,11.763932 L17.8944272,15.5527864 Z"
        id="icon-comment"></path>
    </defs>
    <g id="Icons/Bubble" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlink:href="#icon-comment"></use>
      </mask>
      <use id="Rectangle-6" [attr.fill]="color" fill-rule="nonzero" xlink:href="#icon-comment"></use>
    </g>
  </svg>
</div>