import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { ApplicationRef } from "@angular/core";
import { enableDebugTools } from "@angular/platform-browser";
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import {AppModule} from './app/app.module';

// if (environment.production) {
//  enableProdMode();
// }
if (environment.production) {
    enableProdMode();
    // HACK: Don't log to console in production environment.
    if(window){
        window.console.log=function(){};
        // Don't log anything.
      };
    }

platformBrowserDynamic().bootstrapModule(AppModule)
    .then(module => {
        console.log(`Bootstrap success`);
        let applicationRef = module.injector.get(ApplicationRef);
        let appComponent = applicationRef.components[0];
        enableDebugTools(appComponent);      
    })
    .catch(err => console.error(err));
