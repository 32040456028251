import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  tags = {
    g: '#B6974B',
    staff: '#DF013A',
    new: '#62d8ff',
    patron: '#2ecc71',
    chat: '#7d3c98',
    events: '#da3287',
    rockstar: '#ff4f00',
    snapstar: '#04B4AE',
    lyrics: '#3ADF00',
    cs: '#084B8A',
    duet: '#58C7C9',
    jam: '#cd00cd',
    harmony: '#58C7C9',
    free: '#3F8EFB',
    'Contest Entry': '#8B79D8',
  };

  tagNames = {
    g: 'Gold',
    staff: 'Staff',
    new: 'New',
    patron: 'Patron',
    chat: 'Chat',
    events: 'Events',
    rockstar: 'Rockstar',
    snapstar: 'Snapstar',
    lyrics: 'Lyrics',
    cs: 'Community Songbook',
    duet: 'Duet',
    jam: 'Jam',
    harmony: 'Harmony',
    free: 'Free',
    'Contest Entry': 'Contest Entry',
  };

  @Input() tag: string;
  @Input() color: string;
  @Input() pill: false;

  constructor() {
  }

  ngOnInit() {
  }

  getStyle() {
    const tagColor = this.color ? this.color : this.tags[this.tag];
    if (this.pill) {
      return {
        'background-color': tagColor,
        'color': 'white'
      };
    }
    return {
      'color': tagColor,
      'border-color': tagColor
    };
  }

}
