<div class="navbar-wrapper" *ngIf="!isHome">
  <div class="navbar-menu">
    <div class="nav-logo nav-item">
      <img src="/assets/pictures/sing-snap-logo.png" width="35" />
    </div>
    <div class="nav-items">
      <!-- <a routerLink="/listen" routerLinkActive="active" class="nav-item">
        <span>Listen</span>
      </a> -->
      <a href="/listen" class="nav-item">
        <span>Listen</span>
      </a>
      <a href="/sing" class="nav-item">
        <span>Sing</span>
      </a>
      <a href="/help" class="nav-item">
        <span>Help</span>
      </a>
      <a href="/blog" class="nav-item">
        <span>Blog</span>
      </a>
      
      <!-- <a routerLink="/help" routerLinkActive="active" class="nav-item">
        <span>Help</span>
      </a> -->
    </div>
  </div>
  <div class="navbar-menu">
    <div class="nav-items">
      <a href="https://apps.apple.com/us/app/singsnap-karaoke-ii/id1328547792" class="social-nav">
        <img src="/assets/pictures/icon-appstore.png" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.ss.singsnap&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" class="social-nav social-nav-last">
        <img src="/assets/pictures/icon-playstore.png" />
      </a>
      <!-- <a routerLink="/login" class="nav-item active">
        <span>Login</span>
      </a> -->
      <!-- <a routerLink="/sign-up" class="nav-item active">
        <span>Signup</span>
      </a> -->
    </div>
  </div>
</div>
