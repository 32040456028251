import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-ss-image',
  templateUrl: './ss-image.component.html',
  styleUrls: ['./ss-image.component.scss']
})
export class SsImageComponent implements OnInit {

  @Input() url = '';
  @Input() height = 100;
  @Input() round = false;
  @Input() setWidthAuto = false;

  constructor() {
  }

  ngOnInit() {
  }

  get style() {
    const s = {'background-image': 'url(' + this.url + '?h=' + this.height + ')'} as any;

    if (this.height < 54) {
      s.height = this.height + 'px';
     if(this.setWidthAuto){
      s.width = "auto";
      }else{
        s.width = this.height + 'px';
      }
    }

    return s;
  }
}
