<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header align-items-center">
    <h4 class="modal-title" id="modal-basic-title">
      PLAYLIST
      <i class="fa fa-spinner fa-pulse" *ngIf="loading"></i>
    </h4>
    <app-icon-close class="pull-right" (click)="close()"></app-icon-close>
  </div>
  <div class="modal-body">
    <div class="modal-body-content">
      <div class="playlist-content">
        <div class="playlist-item"  *ngIf="playlist">
          <app-ss-playlist [playlist]="playlist" wide="true"></app-ss-playlist>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" [class.grayout]="playlist?.stats.recordings < 1">
    <button type="button" class="btn btn-primary" [disabled]="playlist?.stats.recordings < 1" (click)="close()" [routerLink]="['/d/play/' + playlist?.id ]">PLAY</button>
  </div>
</ng-template>