import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileService } from './profile.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class GoldGuardService implements CanActivate {
  constructor(
    private router: Router,
    private profileService: ProfileService,
    private toast: ToastrService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return new Observable<boolean>(observer => {
      console.log('=== Gold Guard ===');
      this.profileService.getUserProfile().subscribe(
        user => {
          if (user['gold']) {
            observer.next(true);
          } else {
            observer.next(false);
            this.toast.error('Only available for gold members.');
          }
          console.log('GOLD:', user['gold']);
          observer.complete();
        },
        () => {
          observer.next(false);
          observer.complete();
        }
      );
    });
  }
}
