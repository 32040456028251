import { EventEmitter, Injectable, Output } from '@angular/core';
//import { Response, RequestOptions, Headers } from '@angular/http';

import { HttpService } from './http.service';
import { map } from 'rxjs/operators';

import { HttpParams, HttpClient } from '@angular/common/http';
import { UtilService } from './util.service';

@Injectable()
export class ArtistService extends HttpService {
  constructor(private http: HttpClient, private utilService: UtilService) {
    super();
  }

  get(id) {
    const options = this.getHeaders();
    return this.http
      .get(this.makeUrl(`/artists/${id}`), options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getArtists(
    page = 1,
    perPage = 10,
    sort = 'created_at',
    asc = true,
    query = null,
    queryField = 'name'
  ) {
    const options = this.getHeaders();
    const params = this.utilService.buildParams(
      page,
      perPage,
      sort,
      asc,
      query,
      queryField
    );
    delete params.sorters;
    options['params'] = params;
    const url = `/artists`;
    return this.http
      .get(this.makeUrl(url), options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getArtistsSeeMore(
    page = 1,
    perPage = 10,
    sort = 'created_at',
    asc = true,
    query,
    decade = ''
  ) {
    let url;
    if(decade != '') {
      url = this.makeUrl('/artists=' + decade);
    } else {
      url = this.makeUrl('/artists');

    }
    const options = this.getHeaders();
    const params = this.utilService.buildParams(
      page,
      perPage,
      sort,
      asc,
    );
    if(typeof query == 'object') {
      if(Object.keys(query).length != 0) {
        params.q = '{'
        Object.keys(query).forEach((key) => {
          let value = query[key]
          params.q += `"${key}":"${value}",`
        })
        params.q = params.q.substring(0, params.q.length - 1) + '}'
        console.log(params.q)
      }
    }

    options['params'] = params;
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }





  getArtistGenres(id, page = 1, perPage = 10) {
    const options = this.getHeaders();
    return this.http
      .get(
        this.makeUrl(`/artists/${id}/genres?page=${page}&per_page=${perPage}`),
        options
      ).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getArtistRelated(id, page = 1, perPage = 10) {
    const options = this.getHeaders();
    return this.http
      .get(
        this.makeUrl(`/artists/${id}/related?page=${page}&per_page=${perPage}`),
        options
      ).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getArtistSongs(
    id,
    page = 1,
    perPage = 10,
    sort = '',
    sortDirection = true,
    term = '',
    freeSongsFirst = false
  ) {
    const options = this.getHeaders();
    const params: any = {};
    params.page = page;
    params.per_page = perPage;
    if (term) {
      params.q = `{"title":"${term}"}`;
    }
    const sorters = {} as any;
    if (freeSongsFirst) {
      sorters.gold = 'ASC';
    }
    sorters[sort] = sortDirection ? 'asc' : 'desc';
    params.sorters = JSON.stringify(sorters);
    options['params'] = params;
    return this.http
      .get(this.makeUrl(`/artists/${id}/songs`), options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getArtistDuets(
    id,
    page = 1,
    perPage = 10,
    sort = '',
    sortDirection = 'DESC',
    term = '',
    freeSongsFirst = false
  ) {
    const options = this.getHeaders();
    const params: any = {};
    params.page = page;
    params.per_page = perPage;
    if (term) {
      params.q = `{"title":"${term}"}`;
    }
    const sorters = {} as any;
    if (freeSongsFirst) {
      sorters.gold = 'ASC';
    }
    sorters[sort] = sortDirection ? 'asc' : 'desc';
    params.sorters = JSON.stringify(sorters);
    options['params'] = params;
    return this.http
      .get(this.makeUrl(`/artists/${id}/duets`), options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getArtistsSearchResult(searchString) {
    const options = this.getHeaders();
    return this.http
      .get(
        this.makeUrl(
          '/artists?page=1&per_page=10&q={"name":"' + searchString + '"}'
        ),
        options
      ).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  favorite(id: string | number) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/artists/${id}/favorite`);
    return this.http.post(url, {}, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  unfavorite(id: string | number) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/artists/${id}/favorite`);
    return this.http.delete(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
}
 