import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class ShoppingCartService {

  shopCartArray: any[] = [];
  private shopCart:BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  totalPrice:number;

  constructor() {
    if(localStorage.getItem('singSnapCart') !== null) {
      let cart = JSON.parse(atob(localStorage.getItem('singSnapCart')));
      for(let i = 0; i < cart.length; i++) {
        this.shopCartArray.push(cart[i]);
      }
      this.shopCart.next(this.shopCartArray);
    }
  }
  
  getShoppingCard(): Observable<Array<any>> {
    return this.shopCart.asObservable();
  }
  
  setShoppingCard(item:any) {
    let count = this.shopCartArray.length;
    if(count > 0) {
      let existInCart = false;
      for(let i = 0; i < count; i++) {
        if(item.id === this.shopCartArray[i].id) {
          this.shopCartArray[i].ammount += 1;
          existInCart = true;
        }
      }
      if(existInCart == false) {
        item.ammount = 1;
        this.shopCartArray.push(item);
      }
    } else {
      item.ammount = 1;
      this.shopCartArray.push(item);
    }
    this.shopCart.next(this.shopCartArray);
    localStorage.removeItem('singSnapCart');
    localStorage.setItem('singSnapCart', btoa(JSON.stringify(this.shopCartArray)));
  }

  removeFromCart(index) {
    this.shopCartArray.splice(index,1);
    this.shopCart.next(this.shopCartArray);
    localStorage.removeItem('singSnapCart');
    localStorage.setItem('singSnapCart', btoa(JSON.stringify(this.shopCartArray)));
  }
  
  getTotalPrice() {
    this.totalPrice = 0;
    for(let i = 0; i < this.shopCartArray.length; i++) {
      this.totalPrice += Number(this.shopCartArray[i].price * this.shopCartArray[i].ammount);
    }
    return this.totalPrice;
  }

  emptyCart() {
    this.shopCartArray = [];
    this.shopCart.next(this.shopCartArray);
    localStorage.removeItem('singSnapCart');
    localStorage.setItem('singSnapCart', btoa(JSON.stringify(this.shopCartArray)));
  }


}
