import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { ProfileService } from './profile.service';
import { Observable } from 'rxjs';

@Injectable()
export class LoginAuthGuardService {
  constructor(private router: Router, private profileService: ProfileService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    console.log('loading...');
    return new Observable<boolean>(observer => {
      this.profileService.checkUser().subscribe(
        response => {
          console.log(response['status'])
          if (response['status'] === 200) {
            this.router.navigate(['d/home']);
            observer.next(true);
          } else {
            let allCookies = document.cookie.split(';');
            for (let i = 0; i < allCookies.length; i++) {
                document.cookie = allCookies[i] + "=;expires="
                + new Date(0).toUTCString();
            }
            observer.next(true);
          }
          observer.complete();
        },
        (err) => {
          let allCookies = document.cookie.split(';');
          for (let i = 0; i < allCookies.length; i++) {
              document.cookie = allCookies[i] + "=;expires="
              + new Date(0).toUTCString();
          }
          console.log(err)
          observer.next(true);
          observer.complete();
        }
      );
    });
  }
}
