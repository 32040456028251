import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { UserService, ProfileService, EventService } from '../../core/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('searchInput') private searchInputElem: ElementRef;
  @Output() toggleSidenav = new EventEmitter();

  user: any = {
    photo: 'assets/images/img-user.png',
    level: '-'
  };
  selectedUser: object = null;
  pageType: string = null;
  showBackButton = false;
  showSearch = false;
  query = '';

  // search input change debounce
  _searchInput: Subscription = new Subscription();
  searchTextChanging: Subject<any> = new Subject<any>();

  private _unsubscribeAll: Subject<void> = new Subject();

  constructor(
    private userService: UserService,
    private profileService: ProfileService,
    private eventService: EventService,
    private router: Router
  ) { }

  ngOnInit() {
    //     document.addEventListener('keyup', event => {
    //   if (event.code === 'Space') {
    //     event.preventDefault()
    //     event.stopImmediatePropagation();
    //   }
    // })
    // window.addEventListener('keydown', (e) => {
    //   if(e.keyCode == 32) {
    //     e.stopImmediatePropagation();
    //     e.preventDefault();
    //   }
    // })
    this.user = this.profileService.user;

    this.eventService.evtSelectUser.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(user => {
      if (user) {
        this.selectedUser = user.user;
        this.pageType = user.page;
      } else {
        this.selectedUser = null;
      }
      this.showBackButton = user && user.id !== -1;
    });

    // compose button clicked on mobile
    this.eventService.evtComposeClick.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(user => {
      this.query = '';
      this.onSearchClick();
    });

    this._searchInput = this.searchTextChanging
      .pipe(debounceTime(500))
      .subscribe(x => {
        this.onChangeSearchText();
      });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this._searchInput.unsubscribe();
  }

  onChangeSearchText() {
    console.log('search text changed')
    this.eventService.onHeaderSearchChanged(this.query);
  }

  onBackClick() {
    this.router.navigate(['/d/private-message/' + this.pageType + '/all']);
    this.eventService.onShowContactList();
    if (this.showSearch) {
      this.showSearch = false;
    }
  }

  onSearchClick() {
    this.showSearch = true;
    setTimeout(() => {
      console.log(typeof this.searchInputElem)
      if(typeof this.searchInputElem != 'undefined') {
        this.searchInputElem.nativeElement.focus();
      }
    });
  }
}
