import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-simple-list',
    templateUrl: './simple-list.component.html',
    styleUrls: ['./simple-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleListComponent implements OnInit {

    @Input() list: Array<{
        text: string;
        secondaryText: any;
    }>;

    @Input() title: string;

    constructor() {
    }

    ngOnInit() {
    }

}
