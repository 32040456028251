import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { map ,  tap } from 'rxjs/operators';


import { UtilService } from './util.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends HttpService {
  user: any;

  constructor(private http: HttpClient, private httpService: HttpService, private utilService: UtilService) {
    super();
  }

  getUserInfo() {
    if (this.user) {
      return of(this.user);
    } else {
      const url = this.makeUrl('/user');
      const headers = this.httpService.getHeaders();
      return this.http.get(url, headers).pipe(tap(user => (this.user = user)));
    }
  }

  getRecipientList(recipient = '', perPage = 15): Observable<any> {
    const url = this.makeUrl('/users');
    let params = new HttpParams();
    params = params.append('per_page', perPage.toString());
    if (recipient) {
      params = params.append('q', `{"screen_name":"${recipient}"}`);
    }
    const headers = this.httpService.getHeaders();
    headers['params'] = params
    return this.http.get(url, headers).pipe(map((res) => JSON.parse(JSON.stringify(res))));;
  }
  getBannerMessages() {
    let message = [
      {
        "title": "An update from Trevor",
        "background": "https://imgix.singsnap.com/banner-background-4.5f2e6df9c82dd720407f.png",
        "subtitle": "(Click here to view)",
        "link": "https://www.singsnap.com/#/d/listen/9492106",
        "type": "announcement",
        "priority": "NULL",
        "id": "0"
      }, {
        "title": "We are experiencing an issue with our Storage provider",
        "background": "NULL",
        "link": "https://status.singsnap.com",
        "subtitle": "Click here to see our status updates",
        "type": "system",
        "priority": "error", // info | warning | error
        "id": "1"
      }
    ]
    return JSON.parse(JSON.stringify(message));

  }
  getUser(userId) {
    const url = this.makeUrl(`/users/${userId}`);
    const headers = this.httpService.getHeaders();
    return this.http.get(url, headers);
  }

  getInteractedUserList(query: string) {
    const url = this.utilService.buildUrl('messages/in');
    let params = new HttpParams();
    if (query) {
      params = params.append('q', `{"screen_name":"${query}"}`);
    }

    const headers = this.httpService.getHeaders();
    headers['params'] = params
    return this.http.get(url, headers);
  }

  getIsAdmin() {
    const url = this.makeUrl(`/user/is-admin`);
    const headers = this.httpService.getHeaders();
    return this.http.get(url, headers).pipe(map((res) => JSON.parse(JSON.stringify(res))));;
  }

}
