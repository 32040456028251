
import {switchMap, debounceTime, distinctUntilChanged,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Subject ,  Observable } from 'rxjs';
import { Topic } from '@app/models/topic.model';
import { SplashModalComponent } from '@app/shared/splash-modal/splash-modal.component';

import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { ResponsePaginated } from '@app/models/responses.model';
import { Category } from '@app/message-board/message-board-categories/category.model';

//declare var jQuery: any;

class BoardInfo {
  title?: string;
  description?: string;
  image?: string;
  hide?: boolean;
}

class BoardNavState {
  title?: string;
  showPrev?: boolean;
  id?: any;
  hide?: any;
  toRoute?: string;
}

@Injectable()
export class MessageBoardService extends HttpService {
  private boardInfoChangedSource = new Subject<BoardInfo>();
  private breadCrumbChangedSource = new Subject<any>();
  private navStateChangedSource = new Subject<BoardNavState>();
  navStateChanged$ = this.navStateChangedSource.asObservable();
  private actionsButtonsShowedSource = new Subject<boolean>();
  actionsButtonsShowed$ = this.actionsButtonsShowedSource.asObservable();

  constructor(private http2: HttpClient) {
    super();
  }

  getBreadCrumb() {
    return this.breadCrumbChangedSource;
  }

  getBoardInfo() {
    return this.boardInfoChangedSource;
  }

  changeBoardInfo(boardInfo: BoardInfo) {
    this.boardInfoChangedSource.next(boardInfo);
  }

  changeBreadcrumb(breadcrumb) {
    this.breadCrumbChangedSource.next(breadcrumb);
  }

  changeNavState(navState: BoardNavState) {
    this.navStateChangedSource.next(navState);
  }

  changeActionButtonsShow(show: boolean) {
    this.actionsButtonsShowedSource.next(show);
  }

  getCategories(page = 1, perPage = 15, sortBy?) {
    const headers = this.getHttpHeaders();
    const sorters = this.getSorters(sortBy);
    const url = this.makeUrl(
      `/message-board/categories?page=${page}&per_page=${perPage}${
        sorters ? '&sorters=' + JSON.stringify(sorters) : ''
      }`
    );
    return this.http2.get<ResponsePaginated>(url, headers );
  }

  getCategory(categoryId) {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/message-board/categories/${categoryId}`);
    return this.http2.get<Category>(url, headers );
  }

  getSubcategories(categoryId, page = 1, perPage = 15, sortBy?) {
    const headers = this.getHttpHeaders();
    const sorters = this.getSorters(sortBy);
    const url = this.makeUrl(
      `/message-board/categories/${categoryId}/sub-categories?page=${page}&per_page=${perPage}${
        sorters ? '&sorters=' + JSON.stringify(sorters) : ''
      }`
    );
    return this.http2.get<ResponsePaginated>(url, headers );
  }

  getTopics(categoryId, page = 1, perPage = 15, sortBy?) {
    const headers = this.getHttpHeaders();
    const sorters = this.getSorters(sortBy);

    const url = this.makeUrl(
      `/message-board/categories/${categoryId}/threads?page=${page}&per_page=${perPage}${
        sorters ? '&sorters=' + JSON.stringify(sorters) : ''
      }`
    );
    return this.http2.get<ResponsePaginated>(url, headers);
  }

  // getFavoriteTopics(page = 1, perPage = 15, sort = '') {
  //   const headers = this.getRawHeaders();

  //   const params = new HttpParams();
  //   params.append('page', page.toString());
  //   params.append('per_page', perPage.toString());
  //   if (typeof sort !== 'string') {
  //     params.append('sorters', JSON.stringify(this.getSorters(sort)));
  //   } else if (sort) {
  //     params.append('sorters', JSON.stringify(this.getSorters(sort)));

  //   }

  //   const url = this.makeUrl(`/user/favorites/thread`);


  //   return this.http2
  //     .get(url, {headers: headers, params: params})
  //     .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  // }

  getFavoriteTopics(page = 1, perPage = 15, sortBy?) {
    const headers = this.getHttpHeaders();
    const sorters = this.getSorters(sortBy);
    const url = this.makeUrl(
      `/user/favorites/thread?page=${page}&per_page=${perPage}${
        sorters ? '&sorters=' + JSON.stringify(sorters) : ''
      }`
    );
    return this.http2.get<ResponsePaginated>(url, headers );
  }

  getNewTopics(page = 1, perPage = 15, startingAt, sortBy) {
    const headers = this.getHttpHeaders();
    const sorters = this.getSorters(sortBy);
    const url = this.makeUrl(
      `/message-board/threads/latest?page=${page}&per_page=${perPage}&starting_at=${startingAt}${
        sorters ? '&sorters=' + JSON.stringify(sorters) : ''
      }`
    );
    return this.http2.get<ResponsePaginated>(url, headers );
  }

  getHotTopics(page = 1, perPage = 15) {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(
      `/message-board/search?page=${page}&per_page=${perPage}&sorters={"replies_count": "DESC"}`
    );
    return this.http2.get<ResponsePaginated>(url, headers );
  }

  getTopic(topicId) {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(
      `/message-board/thread/${topicId}?per_page=10&sorters={"created_at":"ASC"}`
    );
    return this.http2
      .get<ResponsePaginated>(url, headers ).pipe(
      map((response: any) => {
        response.body = response.body.replace(
          /href="(#)" data-id="([0-9]+)"/g,
          function(match, hash, id) {
            return (
              'href="/#/d/profile/' + id + '/info"' + ' data-id="' + id + '"'
            );
          }
        );

        return response;
      }));
  }

  createTopic(topic) {
    const headers = this.getHttpHeaders();
    const fd = new FormData();
    const mentionsIds = [...new Set(this.getMentionsIds(topic.body))];

    if (mentionsIds.length > 0) {
      mentionsIds.forEach(val => {
        fd.append('cited[]', val);
      });
    }

    fd.append('body', topic.body);
    fd.append('category_id', topic.category_id + '');
    fd.append('title', topic.title);

    if (topic.tags) {
      fd.append('tags', topic.tags);
    }

    if (topic.cover) {
      fd.append('cover', topic.cover);
    }

    fd.append('notifications', topic.notifications);

    const url = this.makeUrl(
      `/message-board/categories/${topic.category_id}/threads`
    );
    return this.http2.post(url, fd, headers );
  }

  search(terms: Observable<string>) {
    return terms.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(term => this.searchTopics(term)),);
  }

  searchTopics(term = '') {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/message-board/threads/?q=${term}&page=1&per_page=10`
    );
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getQuotedIds(text) {
    if (typeof text !== 'string') {
      throw TypeError('getQuotedIds text must be string');
    }
    //const $text = jQuery.parseHTML(text);
    console.log('getting quoted ids')
    let domparser = new DOMParser()​​
    const $text = domparser.parseFromString(text, 'text/html')
    //const quoteAuthors = jQuery($text).find('.ql-quote-author:first');
    const quoteAuthors = $text.querySelectorAll('.ql-quote-author')
    const quoteAuthorsIds = [];
    quoteAuthors.forEach((idx, el) => {
      console.log(idx, el);
      console.log(el)
      quoteAuthorsIds.push(idx.id);
    });
    return quoteAuthorsIds;
  }

  getMentionsIds(text: string) {
    console.log('getting mentions by id')
    if (typeof text !== 'string') {
      throw TypeError('getMentionsIds text must be string');
    }
    let domparser = new DOMParser()​​
    const $text = domparser.parseFromString(text, 'text/html')
    //const mentions = jQuery($text).find('> .mention');
    const mentions = $text.querySelectorAll('.mention')
    const mentionsIds = [];
    mentions.forEach((idx, el) => {
      console.log(idx, el);
      //mentionsIds.push(jQuery(el).data('id'));
      mentionsIds.push(idx.id);
    });

    return mentionsIds;
  }

  sendComment(comment: string, topicId, replyToId?, page?) {
    const options = this.getHeaders();
    const quoteAuthorsIds = [...new Set(this.getQuotedIds(comment))];
    const mentionsIds = [...new Set(this.getMentionsIds(comment))];

    const url = `/message-board/thread/${topicId}/replies?per_page=50&sorters={"created_at":"ASC"}${
      page ? '&page=' + page : ''
    }`;
    const payload: any = { body: comment };

    if (quoteAuthorsIds.length > 0) {
      payload.quoted = quoteAuthorsIds;
    }

    if (mentionsIds.length > 0) {
      payload.cited = mentionsIds;
    }

    if (replyToId) {
      payload.reply_to = replyToId;
    }

    return this.http2
      .post(this.makeUrl(url), payload, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      map((response: any) => {
        const data = response.model;

        for (let i = 0; i < data.length; i++) {
          data[i].body = data[i].body.replace(
            /href="(#)" data-id="([0-9]+)"/g,
            function(match, hash, id) {
              return (
                'href="/#/d/profile/' + id + '/info"' + ' data-id="' + id + '"'
              );
            }
          );
        }

        response.model = data;
        return response;
      }));
  }

  updateComment(commentBody: string, commentId) {
    const options = this.getHeaders();
    const payload: any = { body: commentBody };
    return this.http2
      .put(this.makeUrl(`/message-board/reply/${commentId}`), payload, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      map((response: any) => {
        const data = response.model;

        data.body = data.body.replace(
          /href="(#)" data-id="([0-9]+)"/g,
          function(match, hash, id) {
            return (
              'href="/#/d/profile/' + id + '/info"' + ' data-id="' + id + '"'
            );
          }
        );

        response.model = data;
        return response;
      }));
  }
getComment
  (recordingId, commentId) {
    const options = this.getHeaders();
    return this.http2
      .get(
        this.makeUrl(`/recordings/${recordingId}/comments/${commentId}`),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getComments(topicId, page = 1, perPage = 20) {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/message-board/thread/${topicId}/replies?page=${page}&per_page=${perPage}&sorters={"created_at":"ASC"}`
    );
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      map((response: any) => {
        const data = response.data;

        for (let i = 0; i < data.length; i++) {
          data[i].body = data[i].body.replace(
            /href="(#)" data-id="([0-9]+)"/g,
            function(match, hash, id) {
              return (
                'href="/#/d/profile/' + id + '/info"' + ' data-id="' + id + '"'
              );
            }
          );
        }

        response.data = data;
        return response;
      }));
  }

  getReplies(commentId, page = 1, perPage = 15) {
    const options = this.getHeaders();
    return this.http2
      .get(
        this.makeUrl(
          `/comments/${commentId}/replies?page=${page}&per_page=${perPage}`
        ),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res)))).pipe(
      map((response: any) => {
        const data = response.data;

        for (let i = 0; i < data.length; i++) {
          data[i].body = data[i].body.replace(
            /href="(#)" data-id="([0-9]+)"/g,
            function(match, hash, id) {
              return (
                'href="/#/d/profile/' + id + '/info"' + ' data-id="' + id + '"'
              );
            }
          );
        }

        response.data = data;
        return response;
      }));
  }

  reaction(recordingId, reactionId) {
    const options = this.getHeaders();
    const payload: any = { reaction_id: reactionId };
    return this.http2
      .post(this.makeUrl(`/recordings/${recordingId}/react`), payload, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  reactionComment(commentId, reactionId) {
    const options = this.getHeaders();
    const payload: any = { reaction_id: reactionId };
    return this.http2
      .post(
        this.makeUrl(`/message-board/reply/${commentId}/react`),
        payload,
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  favoriteTopic(topicId) {
    const options = this.getHeaders();
    return this.http2
      .post(
        this.makeUrl(`/message-board/thread/${topicId}/favorite`),
        {},
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  unfavoriteTopic(topicId) {
    const options = this.getHeaders();
    return this.http2
      .delete(
        this.makeUrl(`/message-board/thread/${topicId}/favorite`),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  loveThread(threadId) {
    const options = this.getHeaders();
    return this.http2
      .post(this.makeUrl(`/message-board/thread/${threadId}/love`), {}, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  unLoveThread(threadId) {
    const options = this.getHeaders();
    return this.http2
      .delete(this.makeUrl(`/message-board/thread/${threadId}/love`), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  favoriteComment(commentId) {
    const options = this.getHeaders();
    return this.http2
      .post(this.makeUrl(`/message-board/reply/${commentId}/love`), {}, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  unFavoriteComment(commentId) {
    const options = this.getHeaders();
    return this.http2
      .delete(this.makeUrl(`/message-board/reply/${commentId}/love`), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  turnOnNotifications(topicId) {
    const options = this.getHeaders();
    return this.http2
      .post( this.makeUrl(`/message-board/thread/${topicId}/notifications`), {},  options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  turnOffNotifications(topicId) {
    const options = this.getHeaders();
    return this.http2
      .delete(this.makeUrl(`/message-board/thread/${topicId}/notifications`), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getTags(query) {
    const options = this.getHeaders();
    query = encodeURIComponent(query);
    return this.http2
      .get(this.makeUrl(`/message-board/threads/tag?q=${query}`), options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  uploadMedia(threadId = null, file) {
    const options = this.getHeaders();
    const fd = new FormData();
    fd.append('file', file);
    let url = this.makeUrl(`/message-board/thread/${threadId}/upload-media`);
    if (!threadId) {
      url = this.makeUrl(`/message-board/thread/upload-media`);
    }
    console.log(url)
    console.log(file)
    return this.http2
      .post(url, fd, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getAnnouncements(page = 1, perPage = 15, sort?) {
    const options = this.getHeaders();
    let sorters = '';
    if (typeof sort !== 'string') {
      sorters = JSON.stringify(this.getSorters(sort));
    } else {
      sorters = `{"${sort}": "DESC"}`;
    }
    const url = this.makeUrl(
      `/announcements?page=${page}&per_page=${perPage}&sorters=${sorters}`
    );
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  // getIcCategories(page = 1, perPage = 15, sort?) {
  //   const headers = this.getRawHeaders();


  //   const params = new HttpParams();
  //   params.append('page', page + '');
  //   params.append('per_page', perPage + '');
  //   if (typeof sort !== 'string') {
  //     params.append('sorters', JSON.stringify(this.getSorters(sort)));
  //   } else if (sort) {
  //     params.append('sorters', JSON.stringify(this.getSorters(sort)));

  //   }
  //   const url = this.makeUrl('/message-board/categories/ics');
  //   return this.http2
  //     .get(url, {headers: headers, params: params })
  //     .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  // }

  getIcCategories(page = 1, perPage = 15, sortBy?) {
    const headers = this.getHttpHeaders();
    const sorters = this.getSorters(sortBy);
    const url = this.makeUrl(
      `/message-board/categories/ics?page=${page}&per_page=${perPage}${
        sorters ? '&sorters=' + JSON.stringify(sorters) : ''
      }`
    );
    return this.http2.get<ResponsePaginated>(url, headers );
  }




  reportThread(id, reason) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/message-board/thread/${id}/report`);
    return this.http2
      .post(url, reason, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  reportComment(id, reason) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/message-board/reply/${id}/report`);
    return this.http2
      .post(url, reason, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  updateTopic(topic: Topic) {
    const options = this.getHeaders();

    const fd = new FormData();

    const mentionsIds = [...new Set(this.getMentionsIds(topic.body))];

    if (mentionsIds.length > 0) {
      mentionsIds.forEach(val => {
        fd.append('cited[]', val);
      });
    }

    fd.append('body', topic.body);
    fd.append('category_id', topic.category_id + '');
    fd.append('title', topic.title);
    fd.append('notifications', topic.notifications.toString());
    fd.append('_method', 'PUT');

    if (topic.tags) {
      fd.append('tags', topic.tags);
    }

    if (topic.cover === null) {
      fd.append('cover', 'NULL');

    } else {
      fd.append('cover', topic.cover);
    }

    const url = this.makeUrl(`/message-board/thread/${topic.id}`);
    return this.http2
      .post(url, fd, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getContestCategories(page = 1, perPage = 10) {
    const options = this.getHeaders();

    return this.http2
      .get(
        this.makeUrl(
          `/message-board/categories/contests?page=${page}&per_page=${perPage}`
        ),
        options
      )
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  handleSuccessAndTopicNavigation(
    data,
    route,
    splashModal: SplashModalComponent
  ) {
    if (data.success) {
      splashModal.open('Your thread has been created.').then(
        () => {
          route.navigate(['/d/message-board/topics/', data.model.id], {
            preserveQueryParams: true,
            queryParamsHandling: 'merge',
          });
        },
        () => {
          route.navigate(['/d/message-board/topics/', data.model.id], {
            preserveQueryParams: true,
            queryParamsHandling: 'merge',
          });
        }
      );
    } else {
      console.log('error', data);
    }
  }

  handleTopicCreateError(error, splashModal: SplashModalComponent) {
    console.log('error', error);
  }

  getByTags(tag, page = 1, perPage = 15) {
    const options = this.getHeaders();
    const url = this.makeUrl(
      `/tag/${tag}/threads?page=${page}&per_page=${perPage}`
    );
    return this.http2
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
}
