<div class="app-icon">
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M12,16.39 L8.24,18.66 L9.23,14.38 L5.91,11.5 L10.29,11.13 L12,7.09 L13.71,11.13 L18.09,11.5 L14.77,14.38 L15.76,18.66 L12,16.39 Z M22,10.24 L14.81,9.63 L12,3 L9.19,9.63 L2,10.24 L7.45,14.97 L5.82,22 L12,18.27 L18.18,22 L16.54,14.97 L22,10.24 Z"
        id="path-star-o"
      ></path>
    </defs>
    <g
      id="ic_star_out"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="ic_clear">
        <g id="Icon-24px">
          <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
          <mask id="mask-star-o" fill="white">
            <use xlink:href="#path-star-o"></use>
          </mask>
          <use
            id="Shape"
            fill="#D0D0D0"
            fill-rule="nonzero"
            xlink:href="#path-star-o"
          ></use>
          <g id="Black" mask="url(#mask-star-o)" fill="#262628"></g>
        </g>
      </g>
    </g>
  </svg>
</div>
