import {ChangeDetectorRef, Component, OnInit, ViewChild, NgZone} from '@angular/core';
import {Data, RecordingService, ProfileService, UtilService} from '@app/core';
import { Location } from '@angular/common';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {FeaturedListComponent} from '@app/shared/featured-list';

@Component({
    selector: 'app-my-recordings',
    templateUrl: './my-recordings.component.html',
    styleUrls: ['./my-recordings.component.scss']
})
export class MyRecordingsComponent implements OnInit {
    @ViewChild('playlist', { static: true }) private playlistElem;
    @ViewChild('pageDropdown', { static: true }) private pageDropdownElem;

    @ViewChild('myRecordingsList', { static: true }) myRecordingsList: FeaturedListComponent;
    @ViewChild('confirmation', { static: true }) private confirmationModal;
    // @ViewChild('confirmModal', { static: true }) confirmModal;

    user;
    groupIc = false;
    groupContest = false;
    recordings = [];
    active = 'upload';
    data = {
        items: [],
        rqing: false,
        lastPage: 1,
        currentPage: 1,
        perPage: 20,
        page: 1,
        total: 1
    };

    searchTerm = '';
    filter = {};
    filterType = '';
    pageArray= [];
    groupType = '';
    filterMessage = '';
    getRecordings = this.recordingService.searchMyRecordingsV2.bind(this.recordingService);

    orderBy = {type: 'created_at', direction: 'DESC'};
    orderDirection = 'ASC';
    activeColor = 'rgb(95, 191, 196)';
    iconColor = '#FFF';

    totalPages=1;
    currentPage=1;
    yearsArray = ["All", "2024","2023","2022","2021","2020","2019","2018","2017","2016","2015","2014","2013","2012","2011","2010","2009","2008","2007","2006"];
    year='All';
    actionsArray = ["Action", "Set To Public", "Set To Members Only", "Set to Private", "Allow Loves", "Disable Loves", "Allow Comments",
    "Disable Comments", "Allow View Tracking", "Disable View Tracking", "Allow Duets/Jams", "Disable Duets/Jams", "Show on Open Duets/Jams Tab",
    "Hide from Open Duets/Jams Tab", "Allow Sharing", "Disable Sharing", "Delete"];
    actions="Action";
    songSelect: any;
    selectAll = false;
    totalRecordingsSelectedForActions='';
    totalRecordingsForFilter='';
    totalRecordingsShowingForFilter= '';
    showActions=true;
    showYears=true;
    allowComments = this.actionsArray["Allow Comments"] + true;
    disallowComments = this.actionsArray["Disable Comments"] + false;
    id: any;
    updating = false;
    //checkbox
    private allRecordingsDisplayed = [];
    selectedRecordings = [];
    single = false;
    selectedAction='';
    selectedActionSetTo='';
    selectedActionNames = '';
    recordingPrivacy='';
    selectedOption = 'Deselect All';
    selectedAllOption = 'Select All';
    confirmModal: boolean;
    pluralRecordings = '';
    isSearching = false;
    hasInitParams = false;
    loggedInIsGold = true;

    constructor(private recordingService: RecordingService,
                private profileService: ProfileService,
                private util: UtilService,
                private toastService: ToastrService,
                private cdr: ChangeDetectorRef,
                private location: Location,
                private dataUpd: Data,
                private ngZone: NgZone,
                private route: ActivatedRoute,
                private router: Router) {
    }


    public navigate(commands: any[]): void {
        this.ngZone.run(() => this.router.navigate(commands)).then();
      }

    ngOnInit() {

        let loggedIn = this.util.getloggedUserData();
        this.loggedInIsGold = loggedIn.gold;

        this.route.queryParams.subscribe(params => {
         if(typeof params.page != 'undefined') {
          this.currentPage = params.page;
          this.isSearching = false;
          this.hasInitParams= true;
         }

         if(typeof params.searchTerm != 'undefined') {
            this.searchTerm = params.searchTerm;
            this.isSearching = false;
            this.hasInitParams= true;
         }

         if(typeof params.year != 'undefined') {
            this.year = params.year;
            this.isSearching = false;
         }

        if(typeof params.type != 'undefined') {
            this.orderBy = {type: params.type, direction: params.direction};
         }
        });

        this.profileService.getUserProfile().subscribe(user => {
            this.user = user;
        });
       // this.onPageChangeHandle(this.currentPage);
        this.clearMessage();
        //make an array for all the page numbers
        this.onSearchHandle(this.searchTerm);
    }




    checkMine(item){
        return item.favorited;
      }

    checkProcessing(item){
        return item.processing;
    }

    showProcessingMessage(){
        this.toastService.error('Your recording is still processing - please refresh the page and try again.');
    }

    displayUploaded() {
        this.active = 'upload';
    }

    displayDraft() {
        this.active = 'draft';
    }

    detectChanges() {
        this.cdr.markForCheck();
        this.cdr.detectChanges();
    }

    getPrevRecordings(){
        if (!this.data.rqing ) {
            if(this.currentPage<=1){
                return;
            }
            this.currentPage--;
            this.data.rqing = true;
            this.detectChanges();
            let obs = [];
            this.getRecordings(this.currentPage, this.data.perPage, this.searchTerm, this.orderBy, this.filter, 'song.title',this.year)
                .subscribe((response) => {
                    this.data.currentPage = this.data.currentPage - 1;
                    this.setPageParam();
                    response.data = response.data.map(rec => {
                        rec.icons = this.util.getRecordingPrivacyIcons(rec);
                        return rec;
                    });
                    //this.data.items = this.data.items.concat(response.data);
                    this.data.items = response.data;
                    //console.log(this.data)
                    this.allRecordingsDisplayed = [];
                    response.data.map(rec => {
                        this.allRecordingsDisplayed.push(rec.id);
                    });
                    this.data.lastPage = response.next_page_url;
                    console.log(this.data.currentPage)
                    this.pageDropdownElem.nativeElement.selectedIndex = (this.currentPage - 1)
                    window.scrollTo(0, 0);
                    this.totalPages =  Number(this.getLastPageFromURL(response.last_page_url));
                    this.totalRecordingsForFilter =  response.total;
                    //this.totalRecordingsShowingForFilter = this.showingRecordings(response.from,response.to);
                    this.showingRecordings(response.from,response.to);
                    this.data.rqing = false;
                    this.detectChanges();
                    if (this.data.total === 0) {
                        this.toastService.success('No results for your search.');
                    }
                }, error => {
                    this.toastService.error(error.message);
                });

        }
    }
    checkYear(item) {
        if(parseInt(item.created_at.substr(0,4)) >= 2021) {
            return true;
        }
        return false;
    }
    getNextRecordings(){

        if (!this.data.rqing && this.data.lastPage) {
            if(this.currentPage==this.totalPages){
                return;
            }
            this.currentPage++;
            this.data.rqing = true;
            this.detectChanges();
            let obs = [];
            this.getRecordings(this.currentPage, this.data.perPage, this.searchTerm, this.orderBy, this.filter, 'song.title',this.year)
                .subscribe((response) => {
                    this.data.currentPage = this.data.currentPage + 1;
                    this.setPageParam();
                    response.data = response.data.map(rec => {
                        rec.icons = this.util.getRecordingPrivacyIcons(rec);
                        return rec;
                    });
                    //this.data.items = this.data.items.concat(response.data);
                    this.data.items = response.data;
                    this.allRecordingsDisplayed = [];
                    response.data.map(rec => {
                        this.allRecordingsDisplayed.push(rec.id);
                    });
                    console.log(this.currentPage)
                    this.pageDropdownElem.nativeElement.selectedIndex = (this.currentPage - 1)
                    window.scrollTo(0, 0);
                    //console.log(this.data)
                    this.data.lastPage = response.next_page_url;
                    this.totalPages =  Number(this.getLastPageFromURL(response.last_page_url));
                    this.totalRecordingsForFilter =  response.total;
                    //this.totalRecordingsShowingForFilter = this.showingRecordings(response.from,response.to);
                    this.showingRecordings(response.from,response.to);
                    this.data.rqing = false;
                    this.detectChanges();
                    if (this.data.total === 0) {
                        this.toastService.success('No results for your search.');
                    }
                }, error => {
                    this.toastService.error(error.message);
                });

        }
    }


    showingRecordings(fromRecording,toRecording){

        if(fromRecording != null){
            this.totalRecordingsSelectedForActions = fromRecording +'-'+ toRecording +'  of ';
            this.showYears=true;
        }else{

            this.totalRecordingsSelectedForActions = '';
            this.showYears=false;
        }

    }

    yearsClick(value){
        window.scroll(0, 0);
        window.scrollTo(0, 0);
        this.data.rqing=true;
        this.resetForSearch();
        this.year=value;
        this.currentPage=1;
        this.setPageParam();
        this.onPageChangeHandle(1);

    }

    selectRecordings(fromRecording,toRecording){

        if(fromRecording != null){
            this.totalRecordingsShowingForFilter = fromRecording +'-'+ toRecording +'  of ';
            this.showActions=true;
        }else{

            this.totalRecordingsShowingForFilter = '';
            this.showActions=false;
        }

    }

    onPageChangeHandle(page = 0, term = '') {
        if (!this.data.rqing) {
            this.data.rqing = true;
            this.detectChanges();
            let obs = [];
            term = term ? term : this.searchTerm;
            if(!page) {
              page = this.data.currentPage
            }
            this.getRecordings(page, this.data.perPage, term, this.orderBy, this.filter, 'song.title',this.year)
                .subscribe((response) => {
                    this.data.currentPage = page + 1;
                    this.currentPage = page;
                    this.setPageParam()
                    response.data = response.data.map(rec => {
                        rec.icons = this.util.getRecordingPrivacyIcons(rec);
                        return rec;
                    });
                    this.data.items = response.data;
                    //console.log(this.data)
                    this.data.lastPage = response.next_page_url;
                    this.totalPages =  Number(this.getLastPageFromURL(response.last_page_url));
                    window.scrollTo(0, 0);

                    this.pageArray = [];
                    for(let i=1; i<=this.totalPages; i++){
                      this.pageArray.push(i)
                    }
                    this.allRecordingsDisplayed = [];
                    response.data.map(rec => {
                        this.allRecordingsDisplayed.push(rec.id);
                    });

                    if(typeof page == 'string') {
                      page = parseInt(page)
                    }
                    setTimeout(()=>{
                      this.pageDropdownElem.nativeElement.selectedIndex = (page - 1)
                      console.log(this.pageDropdownElem.nativeElement.selectedIndex)
                    }, 200)

                    this.totalRecordingsForFilter =  response.total;
                    //this.totalRecordingsShowingForFilter = this.showingRecordings(response.from,response.to);
                    this.showingRecordings(response.from,response.to);
                    this.data.rqing = false;
                    this.detectChanges();
                    if (this.data.total === 0) {
                        this.toastService.success('No results for your search.');
                    }
                }, error => {
                    this.toastService.error(error.message);
                });

        }
    }

    getLastPageFromURL(url){
        let pos = url.lastIndexOf("=");
        let result =  url.substr(pos + 1);
        return result;
      }

    handleOrderBy(orderBy, dir) {
        this.orderBy = {type: orderBy, direction: dir};
        let currentSelectYear = this.year;
        this.resetForSearch();
        this.year = currentSelectYear ;
        this.onPageChangeHandle(1);
        console.log(this.orderBy, 'ordering type');
    }

    onSearchHandle(term) {
        //console.log(term, this.searchTerm);
        if (term.length>0 && term.length<3){
            return;
        }
        this.searchTerm = term;
        if (typeof term === 'string' && term.length) {
            this.isSearching = true;
            this.resetForSearch();
        }
        this.onPageChangeHandle(this.currentPage, term);
    }

    resetForSearch() {
        this.data.rqing = false;
        this.data.items = [];

        this.data.lastPage = 1;
        //this.year='All';
        if(this.isSearching && !this.hasInitParams){
          this.data.currentPage = 1;
          this.currentPage=1;
          this.setPageParam();
        }
        this.hasInitParams = false;
    }
    setPageParam() {
      this.location.replaceState(this.location.path().split('?')[0], '');
      if(this.searchTerm){
        this.location.replaceState(this.location.path() + `?page=${this.currentPage}&year=${this.year}&type=${this.orderBy.type}&direction=${this.orderBy.direction}&searchTerm=${this.searchTerm}`);
      } else {
        this.location.replaceState(this.location.path() + `?page=${this.currentPage}&year=${this.year}&type=${this.orderBy.type}&direction=${this.orderBy.direction}`);
      }
    }
    setFilter(type) {
        if (type === this.filterType) {
            if (this.groupIc || this.groupContest) {
                if(this.groupIc){
                this.groupIc = !this.groupIc;
                this.setGroup(this.groupIc);
                }
                if(this.groupContest){
                this.groupContest = !this.groupContest;
                this.setGroup(this.groupContest);
                }
                // return
            } else {
            // if same filter type reset to default
            this.filterType = '';
            this.filter = {};
            }
            this.filterType = '';
        } else {
            this.filterType = type;
            this.updateSearchOptions();
        }
        this.calculateMessage();
        this.resetForSearch();
        this.onPageChangeHandle(1);
        this.myRecordingsList.scrollToTop();
    }

    setGroup(group) {
        let debug; //Lets us check what the value of the group filter is
        this.groupType = group;
        switch (group) {
            case 'ic':
                this.groupIc = !this.groupIc;
                debug = this.groupIc;
                break;
            case 'contest':
                this.groupContest = !this.groupContest;
                debug = this.groupContest;
                break;

            default:
                break;
        }
        this.updateSearchOptions();
        this.calculateMessage();
        //console.log('filters group', group + ' ' + debug);
        this.resetForSearch();
        this.onPageChangeHandle(1);
        this.myRecordingsList.scrollToTop();
    }

    updateSearchOptions() {
        switch (this.filterType) {
             case 'public':

                this.filter = {public: true};

                if (this.groupIc) {
                    this.filter['ic'] = true;
                }

                if (this.groupContest) {
                    this.filter['contest'] = true;
                }

                this.getRecordings = this.recordingService
                    .searchMyRecordingsV2.bind(this.recordingService);
                break;
            case 'private':
                this.filter = {private: true, members_only: false};

                if (this.groupIc) {
                    this.filter['ic'] = true;
                }

                //added
                if (this.groupContest) {
                    this.filter['contest'] = true;
                }

                this.getRecordings = this.recordingService
                    .searchMyRecordingsV2.bind(this.recordingService);
                break;
            case 'member':
                this.filter = {members_only: true};

                if (this.groupIc) {
                    this.filter['ic'] = true;
                }

                if (this.groupContest) {
                    this.filter['contest'] = true;
                }

                this.getRecordings = this.recordingService
                    .searchMyRecordingsV2.bind(this.recordingService);
                break;
            default:
                if (this.groupIc) {
                    this.filter['ic'] = true;
                } else {
                    this.filter['ic'] = false;
                }

                if (this.groupContest) {
                    this.filter['contest'] = true;
                }else {
                    this.filter['contest'] = false;
                }

                // this.filterType = 'public';
                // this.updateSearchOptions();
                this.getRecordings = this.recordingService
                .searchMyRecordingsV2.bind(this.recordingService);
            break;
        }
        //console.log(this.filter)
    }


    clearMessage() {
        this.filterMessage = 'Viewing all Recordings';
    }

    calculateMessage() {
        if (this.filterType === ''){
            this.filterMessage = 'Viewing all Recordings';
        }
        if (this.filterType === 'public') {
            this.filterMessage = 'Viewing all Public Recordings';
            if (this.groupIc) {
                this.filterMessage = 'Viewing all Public Recordings shared with Inner Circles';
            }
            if (this.groupContest) {
                this.filterMessage = 'Viewing all Public Recordings entered in Contests';
            }
            if (this.groupIc && this.groupContest) {
                this.filterMessage = 'Viewing all Public Recordings entered in Contests & shared with Inner Circles';
            }
        }
        if (this.filterType === 'member') {
            this.filterMessage = 'Viewing all Members Only Recordings';
            if (this.groupIc) {
                this.filterMessage = 'Viewing all Members Only Recordings shared with Inner Circles';
            }
            if (this.groupContest) {
                this.filterMessage = 'Viewing all Members Only Recordings entered in Contests';
            }
            if (this.groupIc && this.groupContest) {
                this.filterMessage = 'Viewing all Members Only Recordings entered in Contests & shared with Inner Circles';
            }
        }

        if (this.filterType === 'private') {
            this.filterMessage = 'Viewing all Private Recordings';
            if (this.groupIc) {
                this.filterMessage = 'Viewing all Private Recordings shared with Inner Circles';
            }
        }

        if (this.groupIc && this.filterType === '') {
            this.filterMessage = 'Viewing all Recordings shared with Inner Circles';
        }

        if (this.groupContest && this.filterType === '') {
            this.filterMessage = 'Viewing all Recordings entered in Contests';
        }

        if (this.groupContest && this.filterType === 'private') {
            this.filterMessage = 'Private Recordings cannot be entered in Contests';
        }


        if (this.groupIc && this.groupContest) {
            this.filterMessage = 'Viewing all recordings entered in Contests and shared with Inner Circles';
        }
        return this.filterMessage;
    }

    onEdit(recording) {
        this.router.navigate(['/d/my-recordings', recording.id]);
    }

    favRecording(recording) {
        this.recordingService.fav(recording.id).subscribe(response => {
            if (response.success) {
                recording.favorited = true;
                this.toastService.success('Recording was added to favorites.');
            } else {
                this.toastService.error('There was an error, try again later.');
            }
        });
    }

    unFavRecording(recording) {
        this.recordingService.unfav(recording.id).subscribe(response => {
            if (response.success) {
                recording.favorited = false;
                this.toastService.success('Recording was removed from favorites.');
            } else {
                this.toastService.error('There was an error, try again later.');
            }
        });
    }

    deleteRecording(recording) {
        return this.confirmationModal.open('WARNING', 'Are you sure you want to delete this recording?').then(result => {
            if (result === 'ok') {
                this.recordingService.deleteRecording(recording.id).subscribe(response => {
                    if (response.success) {
                        this.resetForSearch();
                        this.onPageChangeHandle(1);
                        this.myRecordingsList.scrollToTop();
                        this.toastService.success('Recording was deleted.');
                    } else {
                        this.toastService.error('There was an error, try again later.');
                    }
                });
            }
        });
    }

    onAddtoPlaylist(id) {
        this.playlistElem.open(id);
    }

    formatViews(views) {

        if (views > 999) {
          views = views / 1000;
          views = views.toFixed(1).replace(/[.,]0$/, "");
        //   console.log(views + 'K', "panda");
          return views + 'K';
        } else {
          return views;
        };

      };

      formatLove(love) {
        if (love > 999) {
            love = love / 1000;
            love = love.toFixed(1).replace(/[.,]0$/, "");
            // console.log(love + 'K', "cupid");
            return love + 'K';
        } else {
            return love;
        };
    };

    formatComments(comments) {
        if (comments > 999) {
            comments = comments / 1000;
            comments = comments.toFixed(1).replace(/[.,]0$/, "");
            // console.log(comments + 'K', "cupid");
            return comments + 'K';
        } else {
            return comments;
        };
    };

    actionsClick(selectedActionFromDropdown){

        switch (selectedActionFromDropdown) {
            case 'Set To Public':
                this.recordingPrivacy = 'public';
                this.selectedAction= 'private';                // private - false     members_only false
                this.selectedActionNames = 'Public';
                this.selectedActionSetTo = 'false';
               break;
           case 'Set to Private':
                this.recordingPrivacy = 'private';
                this.selectedAction= 'private';                   // private - true     members_only false
                this.selectedActionNames = 'Private';
                this.selectedActionSetTo = 'true';
             break;
           case 'Set To Members Only':
                this.recordingPrivacy = 'members_only';
                this.selectedAction= 'members_only';            // private - false     members_only true
                this.selectedActionNames = 'Members Only';
                this.selectedActionSetTo = 'true';
             break;



           case 'Allow Loves':
                this.selectedAction= 'allow_loves';
                this.selectedActionNames = 'Allow Loves';
                this.selectedActionSetTo = 'true';
               break;
           case 'Disable Loves':
                this.selectedAction= 'allow_loves';
                this.selectedActionNames = 'Disable Loves';
                this.selectedActionSetTo = 'false';
             break;

           case 'Allow Comments':
                this.selectedAction= 'allow_comments';
                this.selectedActionNames = 'Allow Comments';
                this.selectedActionSetTo = 'true';
               break;
           case 'Disable Comments':
                this.selectedAction= 'allow_comments';
                this.selectedActionNames = 'Disable Comments';
                this.selectedActionSetTo = 'false';
             break;


           case 'Allow View Tracking':
                this.selectedAction= 'track_views';
                this.selectedActionNames = 'Allow View Tracking';
                this.selectedActionSetTo = 'true';
               break;
           case 'Disable View Tracking':
                this.selectedAction= 'track_views';
                this.selectedActionNames = 'Disable View Tracking';
                this.selectedActionSetTo = 'false';
             break;

           case 'Allow Duets/Jams':
                this.selectedAction= 'allow_duets';
                this.selectedActionNames = 'Allow Duets/Jams';
                this.selectedActionSetTo = 'true';
               break;
           case 'Disable Duets/Jams':
                this.selectedAction= 'allow_duets';
                this.selectedActionNames = 'Disable Duets/Jams';
                this.selectedActionSetTo = 'false';
             break;


           case 'Show on Open Duets/Jams Tab':
                this.selectedAction= 'view_on_profile';
                this.selectedActionNames = 'Show on Open Duets/James Tab';
                this.selectedActionSetTo = 'true';
               break;
           case 'Not on Open Duets/Jams Tab':
                this.selectedAction= 'view_on_profile';
                this.selectedActionNames = 'Not on Open Duets/Jams Tab';
                this.selectedActionSetTo = 'false';
             break;

           case 'Allow Sharing':
                this.selectedAction= 'allow_share';
                this.selectedActionNames = 'Allow Sharing';
                this.selectedActionSetTo = 'true';
               break;
           case 'Disable Sharing':
                this.selectedAction= 'allow_share';
                this.selectedActionNames = 'Disable Sharing';
                this.selectedActionSetTo = 'false';
             break;

           case 'Delete':
                this.selectedAction= 'delete';
                this.selectedActionNames = 'Delete';
                this.selectedActionSetTo = 'true';
             break;
        }
    }

    get selectedIds() {
        return this.selectedRecordings.map(u => u.id);
    }

    selectRecording(id) {
        if (this.selectedRecordings.includes(id,0)) {
         this.removeRecording(id);
         return;
        }
        this.addRecording(id);
    }

    addRecording(id) {
        if (this.single) {
        this.selectedRecordings = [id];
        } else {
        this.selectedRecordings.push(id);
        }
    }

    removeRecording(id) {
        const recIndex = this.selectedRecordings.indexOf(id);
        if (recIndex > -1) {
        this.selectedRecordings.splice(recIndex, 1);
        }
    }

    selected(id){
        this.selectRecording(id);
    }

    selectedAll(selectAll) {
        if(selectAll){
            this.selectedRecordings.push.apply(this.selectedRecordings, this.allRecordingsDisplayed);
        } else {
            this.selectedRecordings = [];
        }
        this.selectAll = selectAll;
    }

    prepareSongData() {
        const data: FormData = new FormData();

        for (var i = 0; i < this.selectedRecordings.length; i++) {
            data.append('ids[]', this.selectedRecordings[i]);
        }

        data.append('properties['+this.selectedAction+']', this.selectedActionSetTo );

        if (this.recordingPrivacy == 'public' || this.recordingPrivacy == 'private'){
            data.append('properties[members_only]', 'false' );
        }
        if (this.recordingPrivacy == 'members_only'){
            data.append('properties[private]', 'false' );
        }
        return data;
      }

    openConfirmationModal() {
        if(  this.selectedAction == '' ||  this.selectedAction == 'Action' ) {
            confirm("Please select an Action");
            return;
        }
        if(  !this.selectedRecordings.length  ) {
            confirm("Please select a recordng");
            return;
        }

        this.confirmModal = true;

        if( this.selectedRecordings.length > 1) {
            this.pluralRecordings = 'Recordings';
            // console.log(this.pluralRecordings, 'neeneer');
            return;
        } else {
            this.pluralRecordings = 'Recording';
            // console.log(this.pluralRecordings, 'hahahahah');
            return;
        }
    }

    closeConfirmationModal() {
        this.confirmModal = false;
    }

    setPluralRecordingVal() {
        if( this.selectedRecordings.length > 1) {
            this.pluralRecordings = 'Recordings';
            // console.log(this.pluralRecordings, 'neeneer');
            return;
        } else {
            this.pluralRecordings = 'Recording';
            // console.log(this.pluralRecordings, 'hahahahah');
            return;
        }
    }

    confirmActions() {
        this.updating = true;
        if(this.selectedAction == 'delete')
        {
            this.recordingService.deleteRecordings(this.selectedRecordings).subscribe(result => {
                this.updating = false;
                window.location.reload();
            });
        } else{
            const data = this.prepareSongData();
            data.append('_method', 'PUT');

            this.recordingService.updateRecordings(data).subscribe(result => {
                this.updating = false;
                window.location.reload();
            });
        }
    }

    howOthersSingThis(id){
        this.navigate([`/d/ListenOthersSing/${id}`]);
    }
 }
