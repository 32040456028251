import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-icon-star',
  templateUrl: './icon-star.component.html',
  styleUrls: ['./icon-star.component.scss']
})
export class IconStarComponent implements OnInit, OnChanges {
  @Input() color: string = '#262628';
  @Input() size: number = 18;
  @Input() hoverColor: string = null;
  mainColor: string;

  constructor() { }

  ngOnInit() {
    this.mainColor = this.color;

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['color'].currentValue) {
      this.mainColor = this.color;
    }
  }

  onHover() {
    if (this.hoverColor) {
      this.mainColor = this.hoverColor;
    }
  }

  onLeave() {
    this.mainColor = this.color;
  }

}
