import { Component, Input, OnInit, ChangeDetectionStrategy, NgZone } from '@angular/core';
import { uniqBy } from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recording-snappenin-item',
  templateUrl: './recording-snappenin-item.component.html',
  styleUrls: ['./recording-snappenin-item.component.scss']
})
export class RecordingSnappeninItemComponent implements OnInit {
  @Input() item;
  @Input() showStats = false;

  constructor(private ngZone: NgZone, private router: Router) {
  }
  public navigate(commands): void {
    commands = [`/d/listen/${commands}`]
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }
  ngOnInit() {
    if (this.item.users) {
      this.item.users = uniqBy(this.item.users, 'id');
    }
  }


  getRecordingTag(currentTruncateTextLength) {
    if( !this.item.badges.name){return ;}
    let titleLength = this.item.song.title.length;
    let tagTypeName = this.item.badges.name;
    let tagTypeAmount : number ;
    let tagNameLong = "";
    let tagName = ""; 
    let truncateLength: number;

    tagTypeAmount = this.item.badges.qty;
    tagNameLong = tagTypeName + (tagTypeAmount>2?  ":" + tagTypeAmount : '');
    
    if ( (titleLength + tagNameLong.length)  <= currentTruncateTextLength  ){
        tagName = tagNameLong;
    } else{
        tagName = tagTypeName[0] + (tagTypeAmount>2?  ":" + tagTypeAmount : '');
    }
    return tagName ;
  }
 
  setColor(firstLetter){
    let color: any;
    switch(firstLetter){
      case 'h':
      case 'd':
        color='#58C7C9';
        break;
      case 'j':
        color='#cd00cd';
        break;
    }
    return color;
  }

  getStyle(){
    let outColor: string;
    if(this.item.badges.name){
      outColor = this.setColor(this.item.badges.name[0]);
      return {'color': outColor, 'border-color': outColor  }; 
    }
    return ;
  }
 
  getTruncateValue(currentTruncateTextLength){
    if( !this.item.badges.name){return currentTruncateTextLength;}
    let titleLength = this.item.song.title.length;
    let tagTypeName = this.item.badges.name;
    let tagTypeAmount : number ;
    let tagNameLong = "";
    let tagName = ""; 
    let truncateLength: number;

    tagTypeAmount = this.item.badges.qty;
    tagNameLong = tagTypeName + (tagTypeAmount>2?  ":" + tagTypeAmount : '');
    
    if ( (titleLength + tagNameLong.length)  <= currentTruncateTextLength  ){
        tagName = tagNameLong;
    } else{
        tagName = tagTypeName[0] + (tagTypeAmount>2?  ":" + tagTypeAmount : '');
    }
    return truncateLength = (currentTruncateTextLength - tagName.length);
  };





}
