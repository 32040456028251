<div class="session-carousel">
  <!-- <ngu-carousel #carousel [inputs]="carouselTile" [dataSource]="carouselTileItems">
    <ngu-tile *nguCarouselDef="let item; let j = index" >
      <div class="session-item">
        <div class="session-text">
          <h4>{{items[j].title}}</h4>
          <p>{{items[j].description}}</p>
        </div>
        <div class="session-item-image" [style.backgroundImage]="'url(' + items[j].image + ')'"></div>
      </div>
    </ngu-tile>
    <app-icon-angle-down size="36" color="#58C7C9" NguCarouselPrev [style.opacity]="carousel.isFirst ? 0.5:1" class="page-arrow page-left"></app-icon-angle-down>
    <app-icon-angle-down size="36" color="#58C7C9" NguCarouselNext [style.opacity]="carousel.isLast ? 0.5:1"class="page-arrow page-right"></app-icon-angle-down>
  </ngu-carousel> -->
</div>

<ngx-glide [arrowLeftLabel]="'<'"  [arrowRightLabel]='">"'>
  
  <div *ngFor="let item of items; let j = index" >
    <div class="session-item">
      <div class="session-text">
        <h4>{{items[j].title}}</h4>
        <p>{{items[j].description}}</p>
      </div>
      <div class="session-item-image" [style.backgroundImage]="'url(' + items[j].image + ')'"></div>
    </div>
  </div>

</ngx-glide>