import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, HostListener, EventEmitter, Output } from '@angular/core';
import { ShopService } from '@app/core/services/shop.service';
import { ResponseBrainTreeToken } from '@app/models/responses.model';
import { Charge } from '@app/models/charge.model';
import {EventService, ProfileService} from '@app/core';
import { environment } from '@env/environment';

declare var require: any;
declare var paypal: any;

const client = require('braintree-web/client');
const paypalCheckout = require('braintree-web/paypal-checkout');

@Component({
  selector: 'app-paypal-button-checkout',
  templateUrl: './paypal-button-checkout.component.html',
  styleUrls: ['./paypal-button-checkout.component.scss']
})
export class PaypalButtonCheckoutComponent implements OnInit, OnChanges {

  @Input() charge: Charge;
  @Output() authorized = new EventEmitter<boolean>();
  @Output() loading = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<any>();
  @Output() error = new EventEmitter<any>();
  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;


  async onPaypalAuthorize(payload) {
    console.log(payload);
    const token = payload.nonce;
    const processor = 'braintree';
    await this.profiles.getUserProfile().toPromise();
    const _charge = {
      ...this.charge,
      processor,
      token
    };
    try {
      this.loading.emit(true);

      const orderPaid = await this.shop.createAndChargeOrder(_charge).toPromise() as any;
      if (orderPaid.success) {
        this.authorized.emit(true);
        const user = await this.profiles.getUserProfile().toPromise();
        this.events.onUpdateUserProfile(user);
      } else {
        this.authorized.emit(false);
      }
      this.loading.emit(false);
    } catch (error) {
      this.error.emit(error);
      this.loading.emit(false);
    }
  }
  constructor(
    private shop: ShopService,
    private profiles: ProfileService,
    private events: EventService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['charge'].currentValue !== changes['charge'].previousValue) {
      console.log(changes['charge'].currentValue);
      this.charge = changes['charge'].currentValue;
      this.renderPaypal(changes['charge'].currentValue);
    }
  }

  async renderPaypal(charge: Charge) {
    console.log(charge);
    const res = await this.shop.getBraintreeToken().toPromise() as ResponseBrainTreeToken;
    if (!res.success) {
      console.log('not success');
      this.error.emit(new Error('Failed to load paypal button - braintree token'));
      return;
    }

    const token = res.token;

    //braintree setup
    const clientInstance = await client.create({ authorization: token });

    //paypal braintree link
    const paypalCheckoutInstance = await paypalCheckout.create({ client: clientInstance });

    paypal.Button.render({
      env: environment.PAYPAL_MODE, // sandbox on dev
      payment() {
        return paypalCheckoutInstance.createPayment({
          flow: 'vault',
          amount: charge.amount,
          currency: 'USD',
          displayName: 'SingSnap',
          billingAgreementDescription: charge.message,
          enableShippingAddress: false,
          shippingAddressEditable: false
        });
      },

      onAuthorize: (data, actions) => {
        console.log(data, actions);
        paypalCheckoutInstance.tokenizePayment(data).then(payload => {
          this.onPaypalAuthorize(payload);
        });
      },

      onCancel(data) {
        console.log('checkout.js payment cancelled', JSON.stringify(data));
        this.cancel.emit(data);
      },

      onError(err) {
        console.error('checkout.js error', err);
        this.error.emit(err);
      }
    }, this.paypalElement.nativeElement);
  }
}
