import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-char-counter',
  templateUrl: './char-counter.component.html',
  styleUrls: ['./char-counter.component.scss']
})
export class CharCounterComponent implements OnInit {

  @Input() count: number = 0;
  @Input() total: number = 0;
  constructor() { }

  ngOnInit() {
  }

}
