<div #icon class="app-icon">
    <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <path d="M2,5.44575236 L9,9.82075236 L16,5.44575236 L16,4 L2,4 L2,5.44575236 Z M1,2 L17,2 C17.5522847,2 18,2.44771525 18,3 L18,6 C18,6.34479003 17.8223803,6.66525995 17.5299989,6.8479983 L9.52999894,11.8479983 C9.20572866,12.0506672 8.79427134,12.0506672 8.47000106,11.8479983 L0.47000106,6.8479983 C0.177619697,6.66525995 0,6.34479003 0,6 L0,3 C0,2.44771525 0.44771525,2 1,2 Z M2,14 L16,14 L16,10 C16,9.44771525 16.4477153,9 17,9 C17.5522847,9 18,9.44771525 18,10 L18,15 C18,15.5522847 17.5522847,16 17,16 L1,16 C0.44771525,16 0,15.5522847 0,15 L0,10 C0,9.44771525 0.44771525,9 1,9 C1.55228475,9 2,9.44771525 2,10 L2,14 Z"
                id="path-icon-notification"></path>
        </defs>
        <g id="Icons/Mail" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <mask id="mask-2" [attr.fill]="color">
                <use xlink:href="#path-icon-notification"></use>
            </mask>
            <use id="Combined-Shape" [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-icon-notification"></use>
        </g>
    </svg>
</div>