import { Component, AfterViewInit, ElementRef, ViewChild} from '@angular/core';
import { UtilService } from '../core/services/util.service';
import { environment } from '../../environments/environment';



@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements AfterViewInit {
  get root() {
    return environment.baseAPIUrl
  }

  @ViewChild('help') help: ElementRef;
  html = '';
  scriptTag;
  constructor(private util: UtilService) { }
  
  ngAfterViewInit(): void {
    let url = this.root;
    url = url.replace('/v1', '')

    fetch(`${url}/help`).then(response => response.text()).then(data => {
    // fetch(`https://dev-www.singsnap.com/help`).then(response => response.text()).then(data => {

      console.log(data)
      this.help.nativeElement.innerHTML = data;
      



    })
    


  //   this.util.getHelpSection().subscribe((data) => {
  //     console.log(data)
  //     this.help.nativeElement.innerHTML = data.data;
  // });
  
  }


}
