import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-user-message-info',
    templateUrl: './user-message-info.component.html',
    styleUrls: ['./user-message-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMessageInfoComponent implements OnInit {
    @Input('user') user;
    @Input('showPhoto') showPhoto = true;
    @Input('showBio') showBio = true;
    @Input('showBadge') showBadge = true;
    @Input('showLevel') showLevel = false;
    @Input('timestamp') timestamp = '';

    constructor() {
    }

    ngOnInit() {
    }

}
