<div class="session-page">
  <div class="session-form">
    <h3>Enter reset code</h3>
    <div class="session-help-text">
      <span>Enter the reset code that we sent you in the mail address associated with your SingSnap account.</span>
    </div>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="email">RESET CODE</label>
        <input name="code" type="text" class="form-control" formControlName="code" required>
      </div>
      <button type="submit" class="btn btn-primary">RESET</button>
    </form>
  </div>
  <div class="site-info-slider">
    <app-session-carousel></app-session-carousel>
  </div>
</div>