import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-lock',
  templateUrl: './icon-lock.component.html',
  styleUrls: ['./icon-lock.component.scss']
})
export class IconLockComponent implements OnInit {
  @Input() color: string = 'rgb(208, 208, 208)';
  @Input() size: number = 18;

  constructor() { }

  ngOnInit() {
  }

}
