import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from '@app/core';

@Component({
  selector: 'app-recording-stat',
  templateUrl: './recording-stat.component.html',
  styleUrls: ['./recording-stat.component.scss']
})
export class RecordingStatComponent implements OnInit {
  @Input() recording = null;
  icons = null;
  track_views = 0;

  constructor(
    private util: UtilService
  ) { }

  ngOnInit() {
    if (this.recording) {
      this.icons = this.util.getRecordingPrivacyIcons(this.recording);
    }
  }

  formatViews(views) {
    
    if (views > 999) {
      views = views / 1000;
      views = views.toFixed(1).replace(/[.,]0$/, "");
      // console.log(views + 'K', "panda");
      return views + 'K';
    } else {
      return views;
    };

  };

  formatLove(love) {
    if (love > 999) {
        love = love / 1000;
        love = love.toFixed(1).replace(/[.,]0$/, "");
        // console.log(love + 'K', "cupid");
        return love + 'K';
    } else {
        return love;
    };
  };

  formatComments(comments) {
    if (comments > 999) {
        comments = comments / 1000;
        comments = comments.toFixed(1).replace(/[.,]0$/, "");
        // console.log(comments + 'K', "cupid");
        return comments + 'K';
    } else {
        return comments;
    };
  };

}
