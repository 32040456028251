export class Popup {
    type = 'alert';

    // Set default params
    params = {} as any;
    wrap;

    constructor() {
        this.setParams();
        this.wrap = document.createElement('div');
        this.wrap.id = 'purePopupWrap';
        document.body.appendChild(this.wrap);
        this.wrap.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (e.target === this.wrap) {
                this.close('noActionCancel');
            }

            // TODO: settings: close on click
            if (e.target.className.indexOf('purePopupButton') != -1) {
                this.close(e.target.className.match(/_(.*)_/)[1]);
            }
        });
    }

    setParams(params?: any, callback?: any) {
        this.params.title = document.title;
        this.params.callback = null;
        this.params.buttons = (this.type === 'alert') ? {ok: 'Ok'} : {ok: 'Ok', cancel: 'Cancel'};
        this.params.inputs = {name: 'Please, enter your name'};

        if (params) {
            if (typeof params === 'object') {
                params = params;
                if (callback && typeof callback === 'function') {
                    params.callback = callback;
                }
            } else if (typeof params === 'function') {
                params = {callback: params};
            }
        } else {
            params = {};
        }

        for (let p in params) {
            if (this.params.hasOwnProperty(p)) {
                this.params[p] = params[p];
            }
        }
    }

    show() {
        this.wrap.className = 'open';
        setTimeout(() => {
            this.wrap.className = 'open pop';
        }, 20);
    }

    close(confirm) {
        this.wrap.className = 'open';
        setTimeout(() => {
            this.wrap.className = '';
            const result = {confirm: confirm};

            const inputs = this.wrap.getElementsByTagName('input');
            for (let i = inputs.length; --i >= 0;) {
                result[inputs[i].name] = inputs[i].value;
            }

            if (this.params.callback) {
                this.params.callback.call(this, result);
            }
        }, 300);
    }

    prompt(p, c) {
        this.type = 'prompt';
        this.setParams(p, c);

        let inputsHtml = '', tabIndex = 999;
        for (let i in this.params.inputs) {
            inputsHtml += '<div class="input-row"><label for="purePopupInputs_' + i + '">' + this.params.inputs[i] + '</label><input id="purePopupInputs_' + i + '" name="' + i + '" type="text" tabindex="' + (tabIndex++) + '"></div>';
        }

        let buttonsHtml = '';
        for (let i in this.params.buttons) {
            buttonsHtml += '<a class="purePopupButton _' + i + '_" tabindex="' + (tabIndex++) + '">' + this.params.buttons[i] + '</a>';
        }

        this.wrap.innerHTML = '<div>' +
            '<div>' +
            '<div class="purePopupTitle">' + this.params.title + '</div>' +
            inputsHtml +
            buttonsHtml +
            '</div>' +
            '</div>';

        this.show();
        this.wrap.getElementsByTagName('input')[0].focus();
    }
}
