<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header align-items-center">
    <h5 class="modal-title">

      <app-icon-warning color="red"></app-icon-warning>
      <span>{{title}}</span>

    </h5>
    <button type="button" class="close" aria-label="Close" (click)="c('cancel')">
      <span aria-hidden="true" [inlineSVG]="'/assets/svgs/close.svg'"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body-content" style="white-space: pre-line">
      {{description}}
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-custom-step" (click)="d('ok')">{{acceptText}}</button>
  </div>
</ng-template>
