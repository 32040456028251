import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  @Input('message') message: string;
  @Input('title') title: string;
  @ViewChild('content', { static: true }) content;
  modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  open(title, message?): Promise<any> {
    this.title = title;
    this.message = message;
    this.modalRef = this.modalService.open(this.content, {windowClass: 'report-thankyou-modal'});
    return this.modalRef.result;
  }

  cancel() {
    this.modalRef.close('cancel');
  }

  close() {
    this.modalRef.close('ok');
  }
}
