import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Cookie } from 'ng2-cookies';
import { filter } from 'rxjs/operators';
declare var gtag;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})

export class AppComponent {
    constructor(private router: Router) {

          router.events.subscribe(event => {
                    if (event instanceof NavigationStart) {
                        window.scroll(0, 0);
                    }
                });

          this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
          ).subscribe((event: NavigationEnd) => {
            /** START : Code to Track Page View  */
            let title = event.url.toString();
            title = title.replace('/d/','');
            gtag('event', 'page_view', {
                'page_title' : title,
                'page_path': event.urlAfterRedirects.toString()
             })
             console.log('event', 'page_view', {
              'page_title' : title,
              'page_path': event.urlAfterRedirects.toString()
           })
            /** END */
          });   
        }
}