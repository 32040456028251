/**
 * List of states for the player, should ideally map 1:1 to each player state.
**/
export const States = {
    START: 'start',
    PAUSED: 'paused',
    PLAYING: 'playing',
    RECORDING: 'recording',
    PRACTICE: 'practice',
    PREVIEW: 'preview',
    PITCH: 'pitch',
    VOLUME: 'volume',
    VOLUME_PREVIEW: 'volume_preview',
    UPLOAD: 'upload',
};
