import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {InnerCircleService, ProfileService} from '@app/core';
import {ToastrService} from 'ngx-toastr';
import {InnercircleDataService} from '@app/innercircles/innercircle-data.service';
import {SplashModalComponent} from '../splash-modal/splash-modal.component';

@Component({
    selector: 'app-add-ic-users',
    templateUrl: './add-ic-users.component.html',
    styleUrls: ['./add-ic-users.component.scss']
})
export class AddIcUsersComponent implements OnInit {
    public innerCircle = null;
    public user = null;
    public loggedUser = null;
    public owner = false;
    public id;
    members = [];
    foundMembers = [];
    inviteList = [];
    inviteAdminsList = [];
    stickyMembers = [];
    total = 0;
    totalAllMembers = 0;
    currentPage = 0;
    pageSize = 10;
    searchTerm = '';
    splashMessage = '';
    @ViewChild('content', { static: true }) content;
    @ViewChild('splashModal', { static: true }) splashModal: SplashModalComponent;
    // @ViewChild('searchInput') searchInput: ElementRef;
    modalRef: NgbModalRef;

    constructor(private route: Router,
                private modalService: NgbModal,
                private innerCircleService: InnerCircleService,
                private profileService: ProfileService,
                private toastr: ToastrService,
                public icData: InnercircleDataService) {
    }

    ngOnInit() {

    }

    open(icId) {
        console.log(icId);
        this.id = icId;
        this.modalRef = this.modalService.open(this.content, {windowClass: 'add-member-modal'});
        this.innerCircleService.getInnerCircle(this.id).subscribe(innerCircle => {
            this.innerCircle = innerCircle;
            this.profileService.getUser(this.innerCircle.user_id).subscribe(response => {
                this.user = response;
                this.profileService.getUserProfile()
                    .subscribe(res => {
                        this.loggedUser = res;
                        this.owner = this.user.id === this.loggedUser.id;
                    });
            });
        });

        this.modalRef.result.then(() => {
            this.reset();
            this.icData.membersInvited$.next(true);
            this.icData.adminsInvited$.next(true);
        }, () => {
            this.reset();
            this.icData.membersInvited$.next(true);
            this.icData.adminsInvited$.next(true);
        });


        this.innerCircleService.searchNonMembersDebounced(this.id, this.icData.search$).subscribe(users => {
            this.foundMembers = users.data;
            this.totalAllMembers = users.total;
            this.currentPage = users.current_page;
        });

        this.innerCircleService.searchAdminsDebounced(this.id, this.icData.searchAdmin$).subscribe(users => {
            this.foundMembers = users.data;
            this.totalAllMembers = users.total;
            this.currentPage = users.current_page;
        });
    }


    getNonMembersPaged(page) {
        this.currentPage = page;
        this.innerCircleService.searchNonMembers(this.id, this.searchTerm, page, this.pageSize).subscribe(users => {
            this.foundMembers = users.data;
            this.totalAllMembers = users.total;
            this.currentPage = users.current_page;
        });
    }

    getMembersPaged(page) {
        this.currentPage = page;
        this.innerCircleService.searchMembers(this.id, this.searchTerm, page, this.pageSize).subscribe(users => {
            this.foundMembers = users.data;
            this.totalAllMembers = users.total;
            this.currentPage = users.current_page;
        });
    }

    addMember(memberId) {
        return this.innerCircleService.addMember(this.id, memberId);
    }

    addMembers(membersId: Array<number>) {
        return this.innerCircleService.addMembers(this.id, membersId);
    }

    addAdmins(membersId: Array<number>) {
        return this.innerCircleService.addAdmins(this.id, membersId);
    }

    addToInviteList(member) {
        const memberIndex = this.inviteList.indexOf(member.id);
        if (memberIndex > -1) {
            this.inviteList.splice(memberIndex, 1);
            this.stickyMembers.splice(memberIndex, 1);
            this.removeFromAdminList(member);
        } else {
            this.inviteList.push(member.id);
            this.stickyMembers.push(member);
        }
    }

    addToAdminList(member) {
        const memberIndex = this.inviteAdminsList.indexOf(member.id);
        if (memberIndex > -1) {
            this.inviteAdminsList.splice(memberIndex, 1);
            // this.stickyMembers.splice(memberIndex, 1);
        } else {
            this.inviteAdminsList.push(member.id);
            // this.stickyMembers.push(member);
        }
    }

    removeFromAdminList(member) {
        const memberIndex = this.inviteAdminsList.indexOf(member.id);
        if (memberIndex > -1) {
            this.inviteAdminsList.splice(memberIndex, 1);
        }
    }

    inviteMembers() {
        this.addMembers(this.inviteList).subscribe(response => {
            this.toastr.success('An invite has been sent!', 'Success');
            this.innerCircleService.getMembers(this.id)
                .subscribe(members => {
                    this.members = members.data;
                    this.total = members.total;
                    if (this.inviteAdminsList.length > 0) {
                        this.inviteAdmins(this.inviteAdminsList);
                    } else {
                        this.splash('Member(s) Invited!');
                        this.icData.membersInvited$.next(true);
                    }
                    this.icData.search$.next('');
                    this.icData.addIcUsers$.next(true);
                    this.searchTerm = '';
                    // this.searchInput.nativeElement.value = '';
                });
        });
    }

    inviteAdmins(list = this.inviteList) {
        this.addAdmins(list).subscribe(response => {
            this.toastr.success(response.message, 'Success');
            this.innerCircleService.getMembers(this.id)
                .subscribe(members => {
                    this.members = members.data;
                    this.total = members.total;
                    this.splash('A notification has been sent.');
                    this.icData.adminsInvited$.next(true);
                    this.icData.addIcUsers$.next(true);
                });
        });
    }

    belongsToInviteList(member) {
        const memberIndex = this.inviteList.indexOf(member.id);
        return memberIndex > -1;
    }

    /*onPageChangeHandle(page: number) {
      this.getOwnMembersPaged(page);
    }*/

    onPageAddMembersChangeHandle(page: number) {
        this.getMembersPaged(page);
    }

    /*onPageAddAdminsChangeHandle(page: number) {
      this.getAdminsPaged(page);
    }*/

    onPageAddNonMembersChangeHandle(page: number) {
        this.getNonMembersPaged(page)
    }

    reset() {
        this.members = [];
        this.foundMembers = [];
        this.inviteList = [];
        this.stickyMembers = [];
        this.total = 0;
        this.totalAllMembers = 0;
        this.currentPage = 0;
        this.searchTerm = '';
    }

    splash(msg = '') {
        this.splashModal.open('Invited!', msg);
        this.close();
    }

    closeThankyouReportModal() {
        this.splashModal.close();
    }

    close() {
        this.modalRef.close('cancel');
    }
}
