import { Component, OnInit, ChangeDetectorRef, NgZone, HostListener, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { initiateRemixer } from '../../assets/build-remix/super-remix8773.js'
import { ActivatedRoute, Router } from '@angular/router';
import { Data, SongService, ProfileService, SingService, RecordingService, UtilService } from '@app/core';
import { ToastrService } from 'ngx-toastr';
import Bugsnag from '@bugsnag/js';

@Component({
  selector: 'app-superremixer',
  templateUrl: './superremixer.component.html',
  styleUrls: ['./superremixer.component.scss']
})
export class SuperremixerComponent implements OnInit, OnDestroy {
  @HostListener('window:keydown', ['$event'])
  keyEvent(e: KeyboardEvent) {
    if(e.keyCode == 32) {
      if(this.loaded = true && !this.uploaded) {
        console.log('something')
        switch(this.UIState) {
          case 'review':
            this.pause()
            break
          case 'reviewPaused':
            this.play()
            break
        }
        e.stopImmediatePropagation();
        e.preventDefault();
      }


    }

  }
  @ViewChild('recVideo') private recVideo: ElementRef;
  @ViewChild('duetVideo') private duetVideo: ElementRef;
  @ViewChild('topUI') private topUI: ElementRef;
  @ViewChild('bottomUI') private bottomUI: ElementRef;
  @ViewChild('countdown') private countdown: ElementRef;
  @ViewChild('errorMessage') private errorMessage: ElementRef;
  @ViewChild('errorMessageContainer') private errorMessageContainer: ElementRef;
  @ViewChild('lyricsCanvas') private canvas: ElementRef;
  id;
  wasmPath = '/assets/superpowered.wasm';
  workletPath = '/assets/scripts/workletProcessor.js';

  blueLimitReached = false;
  pitchDisabled = false;
  remixer;
  loaded = false;
  duration = 0;
  playhead = 0;
  interval = null;
  uuid;
  UIState = 'reviewPaused'
  containerOpen = false;
  sources = {}
  videoDropDown = false;
  micDropDown = false;
  finished = false;
  scrubberOn = false;
  volumeBar = false;
  pitchBar = false;
  syncBar = false;
  lyricBar = false;
  lyricSpeed = 0;
  duet = false;
  uploaded = false
  sync = 0;
  mmsb = true;
  pitch = 0
  micVolume = 1;
  count = 5;
  musicVolume = 1;
  params;
  part;
  jam = false
  remix;
  urlChanges = false;
  version = '7.0'
  UI = {
    volumeButton: true,
    lyricButton: false,
    syncButton: true,
    mmsbButton: true,
    saveOrTryAgainButtons: true,
    pauseButton: false,
    resumePracticeButton: true
  }


  constructor(    
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private singService: SingService,
    private remixService: SongService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private recordingService: RecordingService,
    private profileService: ProfileService,
    private data: Data,
    private router: Router,) { 
    
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = parseInt(params.id)
      let userAgent = window.navigator.userAgent.toLowerCase()
      // if (userAgent.indexOf('firefox',0) !== -1) {
      //   this.router.navigate(['/d/remix/' + this.id]) //go to old remixer if it's firefox
      //   return;
      // }
      this.loadRemixData()
    });
  }
  async loadRemixData() {
    try {
      const requests = [];
      requests.push(this.recordingService.getRemixData(this.id).toPromise());
      let results = await Promise.all(requests);
      this.remix = results[0];
      console.log(this.remix)
      if(typeof this.remix.parent_uri != 'undefined') {
        if(this.remix.parent_uri != null) {
          this.jam = true;
          this.duetVideo.nativeElement.style.removeProperty('display')
          //this.router.navigate(['/d/remix/' + this.id])
        }
      }
      if(this.remix.users.length > 1) {
        this.duet = true;
      }

      this.mmsb = this.remix.mmsb;
      this.micVolume = this.remix.meta.mic_vol
      this.musicVolume = this.remix.meta.base_vol
      this.sync = parseInt(this.remix.meta.latency);
      console.log(this.remix)
      this.remixer = initiateRemixer(
        this.workletPath,
        this.wasmPath,
        this.recVideo.nativeElement,
        this.duetVideo.nativeElement,
        this.remix,
        //this.canvas.nativeElement,
        this.jam
      );
      console.log('hooking up events')
      this.hookUpEvents()
      this.remixer.init()
    } catch (err) {
      console.log(err)
      this.errorMessageContainer.nativeElement.style.display = 'flex'
      this.errorMessage.nativeElement.innerText = err
      this.UIState = 'noPermissions'
      this.changeUIState()
      let errMsg = err.toString();
      Bugsnag.notify('Remixer loadRemixData error : ' + errMsg);
    }
  }
  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  hookUpEvents(){
    //remix is loaded!
    this.remixer.addEventListener('initDom', ()=>this.initDom())
    this.remixer.addEventListener('playheadUpdate', ()=>this.playheadUpdate()) // playhead is in a new spot, update the scrubber
    this.remixer.addEventListener('show_message', (event, error)=>{
      this.toastr.info(error)
      this.detectChanges()
      Bugsnag.notify('Remixer hookUpEvents error: ' + error);
    })
    this.remixer.addEventListener('no_permissions', (event, error)=>{
      this.errorMessageContainer.nativeElement.style.display = 'flex'
      this.errorMessage.nativeElement.innerText = error
      this.UIState = 'noPermissions'
      this.changeUIState()
      Bugsnag.notify('Remixer hookUpEvents-no permissions: ' + error);
    })


    //this.remixer.addEventListener('startScrubber', ()=>this.startScrubber())
    this.remixer.addEventListener('durationUpdate', ()=>this.durationUpdate())

  }

  initDom() {
    console.log('init dom')
    this.duration = this.remixer.superPlayer.duration
    this.loaded = true;
    if(this.mmsb){
      this.remixer.superPlayer.mmsbOn()
    } else {
      this.remixer.superPlayer.mmsbOff()
    }
    this.onSyncChange(this.sync);
    this.detectChanges()
    if(this.jam) {
      this.changeDuetStyles()
    }
  }
  changeDuetStyles(){
    if(this.remix.webcam){
      this.duetVideo.nativeElement.poster = '';
    }
    this.canvas.nativeElement.style.left = '14%';
    this.canvas.nativeElement.style.bottom = '28%';
    this.canvas.nativeElement.style.width = '101vh';
    this.topUI.nativeElement.style.width = '136vh'
    this.duetVideo.nativeElement.style.height = '68vh'
    this.bottomUI.nativeElement.style.width = '136vh';
    this.detectChanges()
  }
  backClicked(){
    window.history.back();
  }
  playheadUpdate(){
    if(!this.scrubberOn) {
      return
    }
    this.playhead = this.remixer.superPlayer.playhead;
    console.log(this.playhead, this.duration )
    if(this.playhead >= (this.duration - 500)) {
        this.pause()
        this.remixer.seek(0)
        this.playhead = 0
    }
    this.detectChanges()
  }
  msToTime(s) {
    let pad = (n, z = 2) => ('00' + n).slice(-z);
    return pad((s%3.6e6)/6e4 | 0) + ':' + pad((s%6e4)/1000|0);
  }
  startScrubber(){

    this.scrubberOn = true;
      // if(typeof this.interval != 'undefined') {
      //   this.stopScrubber()
      // }
      this.interval = setInterval(()=>{
        this.remixer.superPlayer.getPosition();
      }, 100);
      console.log('starting scrubber')
  }
  stopScrubber(){
    console.log('scrubber off')
      this.scrubberOn = false;
      clearInterval(this.interval);
      this.interval = null;
  }
  changeRangeTime(e) {
    console.log('change range time')
    if(this.scrubberOn) {
      this.stopScrubber()

    }
    this.playhead = parseFloat(e)
    this.detectChanges()
}
  seek(e) {
    if(this.UIState == 'review') {
      this.pause();
    }
    e = parseFloat(e)
    this.remixer.seek(e)
    this.playhead = e
    if(this.remixer.superPlayer.playing && !this.scrubberOn) {
      this.startScrubber()
    }
    this.detectChanges()
  }
    pause(){
      this.remixer.pause()
      this.UIState = 'reviewPaused'
      this.stopScrubber();
      this.changeUIState()
    }
    play(){
      this.remixer.play()
      this.UIState = 'review'
      this.changeUIState()
      this.startScrubber()
  }
  durationUpdate() {
    this.duration = this.remixer.superPlayer.duration
    this.seek(0)
    this.playhead = 0
    let sync = parseInt(localStorage.getItem("sync"))
    if (sync) {
      this.sync = sync
      this.remixer.sync(this.sync);
    }
    this.toastr.success('Review loaded!')
    this.detectChanges()
  }
  onSyncClick() {
    this.containerOpen = true;
    this.syncBar = true;
    this.detectChanges()
  }
  onSyncChange(e) {
    console.log('sync changed to ' + e)
    if(this.remixer.superPlayer.playing) {
      this.pause()
      this.UIState = 'reviewPaused'
      this.changeUIState()
    }
    this.sync = e
    this.remixer.sync(e)
  }
  onSyncClose() {
    this.containerOpen = false;
    this.syncBar = false;
    this.detectChanges()
  }
  onVolumeClick() {
    this.containerOpen = true;
    this.volumeBar = true;
    this.detectChanges()
  }
  onMicVolumeChange(e) {
    let value = parseFloat(e)
    this.micVolume = value;
    this.remixer.changeVocalVolume(value)
  }
  onMusicVolumeChange(e) {
    let value = parseFloat(e)
    this.musicVolume = value;
    this.remixer.changeMusicVolume(value)
  }
  onVolumeClose() {
    this.containerOpen = false;
    this.volumeBar = false;
    this.detectChanges()
  }
  onLyricSpeedClick() {
    this.containerOpen = true;
    this.lyricBar = true;
    this.detectChanges()
  }
  onLyricSpeedChange(e) {
    let value =  Math.round((e * 0.6) * 10) / 10 //rounds to one digit place
    if(this.remixer.superPlayer.playing) {
      this.pause();
      if(this.remixer.mode == 'review') {
        this.UIState = 'reviewPaused'
      } else if (this.UIState != 'default'){
        this.UIState = 'practicePaused'
      }
      this.changeUIState()
    }
    this.lyricSpeed = value / 0.6;
    this.remixer.lyricsPlayer.changeSpeed(value)
  }
  onLyricSpeedClose() {
    this.lyricBar = false;
    this.containerOpen = false;
    this.detectChanges()
  }

  onChangeMMSB() {
    if(this.mmsb) {
      this.remixer.superPlayer.mmsbOn()
    } else {
      this.remixer.superPlayer.mmsbOff()
    }
    console.log('changing MMSB')
    //this.mmsb = !this.mmsb

    this.detectChanges()
  }
  async upload() {
    if(this.uploaded) {
      return
    }
    let payload = {
      meta: {latency: this.sync, mic_volume: this.micVolume, base_volume: this.musicVolume},
      mmsb: this.mmsb,
      webcam: this.remix.webcam
    }
    try {
      await this.recordingService.saveRemix(this.id, payload).toPromise();
      this.data.storage = { recordingId: this.id };
      this.uploaded = true;
      this.router.navigate(['/d/remix/success']);
    } catch (err) {
      console.log(err);
      let errMsg = err.toString();
      Bugsnag.notify('Remixer  upload error: ' + errMsg);
    } 
  }
  ngOnDestroy() {
    if(typeof this.remixer != 'undefined') {
      this.pause()
      this.remixer = null;
      if(!this.uploaded && this.loaded) {
        window.location.reload();
      }
    }

  }

changeUIState() {
  //behold, the grand switch statement!
  switch(this.UIState) {
    case 'noPermissions':
      this.UI.volumeButton = false;
      this.UI.lyricButton = false;
      this.UI.syncButton = false;
      this.UI.mmsbButton = false;
      this.UI.saveOrTryAgainButtons = false;
      this.UI.pauseButton = false;
      this.UI.resumePracticeButton = false;
      break;
    case 'review':
      this.UI.volumeButton = true;
      this.UI.lyricButton = false;
      this.UI.syncButton = true;
      this.UI.mmsbButton = true;
      this.UI.saveOrTryAgainButtons = true;
      this.UI.pauseButton = true;
      this.UI.resumePracticeButton = false;
      break;
    case 'reviewPaused':
      this.UI.volumeButton = true;
      this.UI.lyricButton = false;
      this.UI.syncButton = true;
      this.UI.mmsbButton = true;
      this.UI.saveOrTryAgainButtons = true;
      this.UI.pauseButton = false;
      this.UI.resumePracticeButton = true;
      break;
  }
  
  console.log(this.UIState)
  this.detectChanges();
}
}









