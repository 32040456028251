import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pp-checkout',
  templateUrl: './pp-checkout.component.html',
  styleUrls: ['./pp-checkout.component.scss']
})
export class PpCheckoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
