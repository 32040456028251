<router-outlet></router-outlet>

<!-- <section class="container-fluid settings">
    <div class="row">
        <div class="col-6 col-md-3">
            <div class="card" style="width: 100%;">
                <div class="card-body">
                    <div class="">
                        <div class="avatar-left">
                            <app-avatar-thumb [user]="user">
                            </app-avatar-thumb>
                        </div>
                        <div class="avatar-right">
                            <span>{{user?.name}}</span>
                            <app-user-badge-level [user]="user">
                            </app-user-badge-level>
                        </div>
                    </div>
                    <hr>
                    <button class="dropdown-item" [routerLink]="['/d/settings/profile']">
                        My Profile
                    </button>
                    <button class="dropdown-item" [routerLink]="['/d/settings/lyric']">
                        Lyrics
                    </button>
                </div>
            </div>
        </div>
        <div class="col-6 col-md-9">
            <div class="card">
                <div class="card-body">
                    
                </div>
            </div>
        </div>
    </div>
</section> -->
