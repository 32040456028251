<div class="ss-contest" *ngIf="contest"
  [routerLink]="['/d/contests/', contest.contest_id]"
  [ngbTooltip]="tipContent" container="body">
  <div class="ss-contest--profile">
    <img [src]="contest.cover + '?h=100'">
  </div>
  <div class="ss-contest--info">
    <div class="info-name">{{contest.name}}</div>
    <div class="info-description ss-truncate">{{contest.description}}</div>
    <div class="contest-author">
      <app-ss-user-name [user]="contest.user"></app-ss-user-name>
    </div>
  </div>
</div>
<ng-template #tipContent>
  <span class="contest-stat"><strong>Participants:</strong> {{this.contest.stats.participants}}</span><br>
</ng-template>