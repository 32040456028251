<div class="flex-container">
    <div class="container-fluid top-section">
        <h2 class="main-heading"><br>Welcome to the SingSnap Chat Community</h2>
        <!-- <br> -->
        <p class="main-body">If you're in the mood to sing live or chat with friends across the world, you've come to the right place. SingSnap is committed to providing the best experience for our community. We are currently working on developing our very own chat room that will be hosted right here on SingSnap! We are hoping to have it completed soon - in the mean time, our chat family will be hanging out on discord! Follow the instructions below to join us!</p>

        <ol class="text-body">
            <li class="text-body">Go to <a href="https://discord.com" class="link-tag" target="_blank">discord.com</a> and download the Discord App. There is a web-based version as well, so downloading the app is not required. However, the app does work better than the browser version for most people. (There is also an app available for both Android and iOS devices)</li>
            <li class="text-body">Once you install the app, create a username. We recommend selecting a username close to your name on SingSnap!</li>
            <li class="text-body">Click <a href="https://www.singsnap.com/#/d/message-board/topics/162402" class="link-tag" target="_blank">this page</a> to get the invite link. (It is Dynamic and may change occassionally) Once you click the link from that page you will  arrive in the #Welcome-Lobby. Drop a comment there with your SingSnap Username.</li>
            <li class="text-body">Then send a Private Message on SingSnap to <a href="https://www.singsnap.com/#/d/profile/1075580/info" class="link-tag" target="_blank">Chat Help</a> and let them know you have sent a request in the SingSnap Discord.</li>
            <li class="text-body">Please allow up to 24 hours for approval but we hope to get it added much sooner!</li>
            <li class="text-body">Once you are in, have a look around! A good place to start is the #Help-to-text-chat and the #help-to-sing channel.</li>
            <li class="text-body">We realize there will be a learning curve and our admin team will be there, ready and willing to help!</li>
            <li class="text-body">Lastly, don't forget to join our Facebook Group <a href="https://www.facebook.com/groups/singsnapchatevents" class="link-tag" target="_blank">@SingSnap Chat Events!</a></li>
            <li class="text-body">Check out the <a href="https://www.singsnap.com/#/d/message-board/topics/162402" class="link-tag" target="_blank">Discord Tutorial</a> for more assistance!</li>
        </ol>
    </div>
</div>