import { Component, OnInit, OnDestroy, ViewChild, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Data, RecordingService } from '@app/core';

@Component({
  selector: 'app-sing-upload-success',
  templateUrl: './sing-upload-success.component.html',
  styleUrls: ['./sing-upload-success.component.scss']
})
export class SingUploadSuccessComponent implements OnInit {
  @ViewChild('report', { static: true }) private reportElem;
  title = '';
  artist = '';
  image = null;
  song: any;
  recording: any;
  reporting = false;
  isRemix = false;
  remixRecordingId = null;

  constructor(
    private data: Data,
    private ngZone: NgZone,
    private toast: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private recordingService: RecordingService
  ) {
    this.activatedRoute.data.subscribe(data => {
      if (data && data.remix) {
        this.isRemix = true;
        this.remixRecordingId = this.data.storage.recordingId;
      }
    });
  }
  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }
  ngOnInit() {
    this.recording = this.data.storage.recording;
    if (this.recording) {
      this.title = this.recording.song.title;
      this.artist = this.recording.song.artist.name;
      this.image = this.recording.cover;
    }
  }

  ngOnDestroy() {
    //refresh only if it's a new recording
    // if(typeof this.data.storage.song.previouslyFailed != 'undefined') {
    //   if(!this.data.storage.song.previouslyFailed) {
    //     window.location.reload();
    //   }
    // } else {
      window.location.reload();
    //}

  }

  notifyReady() {
    const recordingId = this.isRemix ? this.remixRecordingId : this.recording.id;
    this.recordingService.notifyReady(recordingId).subscribe(result => {
      this.toast.success('You will be notified when it\'s ready.');
    });
  }

  singAgain() {
    this.router.navigate(['/d/sing']);
  }

  gotoHome() {
    this.router.navigate(['/d/home']);
  }

  onReport() {
    this.reportElem.popup('Recording');
  }

  reportRecording(reason) {
    this.reporting = true;
    this.recordingService.reportRecording(this.recording.id, reason).subscribe(result => {
      this.reporting = false;
      this.reportElem.onReportSuccess();
    });
  }
}
