<div class="main">
    <div class="ssb-navbar">
                <span class="singsnap_logo" [routerLink]="['']">
                        <img src="../../../../assets/pictures/logo.png" alt="logo" class="logo"><span class="_singsnap">SingSnap</span>
                    </span>
        <span class="ssb-actions pull-right"><img (click)="isHamburgerVisible = !isHamburgerVisible"
                                                  [src]="isHamburgerVisible ? '../../../../assets/pictures/webSite/menu_black.png' : '../../../../assets/pictures/webSite/menu.png'"
                                                  alt="burger" class="burger_menu"></span>
        <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a
            [ngClass]="{'hamburger-colors': isHamburgerVisible}"
            [routerLink]="['/sign-up']">SIGN UP</a></span>
        <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a
            [ngClass]="{'hamburger-colors': isHamburgerVisible}"
            [routerLink]="['/login']">LOGIN</a></span>
    </div>
    <div (mouseout)="hideHamburger($event)" class="hamburger" *ngIf="isHamburgerVisible">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="ssb-navbar">

                    <span class="ssb-actions pull-right"><img (click)="isHamburgerVisible = !isHamburgerVisible"
                                                              [src]="isHamburgerVisible ? '../../../../assets/pictures/webSite/menu_black.png' : '../../../../assets/pictures/webSite/menu.png'"
                                                              alt="burger" class="burger_menu"></span>
                    <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a
                        [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                        [routerLink]="['/sign-up']">SIGN UP</a></span>
                    <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a
                        [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                        [routerLink]="['/login']">LOGIN</a></span>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 menu_content">
                <span [routerLink]="['/main/home']"><span class="fat_line"></span> home</span><br>
                <span [routerLink]="['/main/aboutUs']"><span class="fat_line"></span> about us</span><br>
                <span [routerLink]="['/main/pricing']"><span class="fat_line"></span> pricing</span><br>
                <span [routerLink]="['/main/blog']"><span class="fat_line"></span> blog</span><br>
                <span [routerLink]="['/dashboard']"><span class="fat_line"></span> app</span><br>
                <!-- hidding store from website -->
                <!-- <span [routerLink]="['/main/shop']"><span class="fat_line"></span> store</span><br> -->
                <span [routerLink]="['/main/support']"><span class="fat_line"></span> support</span><br>
                <span [routerLink]="['/main/influencer']"><span class="fat_line"></span> influencer</span><br>
                <span (click)="onLogOut()" *ngIf="userIsLoged"><span class="fat_line"></span> log out</span><br>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 social-links">
                <img src="../../../../assets/pictures/webSite/hamburger_facebook.png" alt="">
                <img src="../../../../assets/pictures/webSite/hamburger_youtube.png" alt="">
                <img src="../../../../assets/pictures/webSite/hamburger_twitter.png" alt="">
                <img src="../../../../assets/pictures/webSite/hamburger_instagram.png" alt="">
            </div>
        </div>
    </div>
    <div class="main_content" >
        <div>
            <span class="cl-policy-name">Code Of Conduct</span>
        </div>
    </div>
</div>
<div class="text-container">
    <p>First and foremost practice the philosophy "Treat others how you would like to be treated". Below are more specific rules to follow that will help you interact with others peacefully on SingSnap.</p>
    <ul class="dash">
        <li>Keep it real</li>
        <li>Always have correct information about yourself in your account.</li>
        <li>Don't pick a fight</li>
        <li>Do not single out other members and discuss in a negative context unless they are already party to the discussion. When in disagreements, always keep the discussion civil by not ridiculing, mocking, humiliating, insulting or referring to another member in a derogatory, threatening or malicious manner.</li>
        <li>Private means private</li>
        <li>Do not publicly post any sensitive private message content that you have sent or received. The posting of personal contact information for other users without their permission is strictly prohibited. Do not discuss specific blocks or bans made by yourself, other members or staff. Lastly, do not discuss previous violations of SingSnap rules.</li>
        <li>Leave the suit at the office</li>
        <li>Do not advertise your own commercial endeavors or promote competing websites anywhere on SingSnap.</li>
        <li>Keep the kids safe</li>
        <li>Always have a parent or guardian visible or audible in the recordings if children under the age of 13 record on your account.</li>
        <li>Keep us safe</li>
        <li>Only use the backing tracks provided by SingSnap or music you own the rights to. Do not violate copyright laws by including video you do not have the rights to. Do not post licensing related material or information involving legal matters related to our song catalog.</li>
        <li>Don't be a hacker</li>
        <li>Do not post any code that collects information from visitors without their knowledge. Do not try to cheat the system or interact with it in abnormal ways.</li>
        <li>Use your judgment</li>
        <li>Do not post (or link to) offensive, inflammatory, obscene, vulgar, profane, racist or sexually explicit text, images and video. Do not promote or provide instructional information pertaining to illegal activity. Do not encourage others to violate any SingSnap rules.</li>
        <li>Don't be a hater</li>
        <li>Do not use other websites or email to spread false information or posture against SingSnap, its staff and its community.</li>
    </ul>
</div>
