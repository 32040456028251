<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 11 11" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="arrow-pointing-to-right-2"
        transform="translate(5.000000, 5.500000) rotate(-270.000000) translate(-5.000000, -5.500000) "
        [attr.fill]="color" fill-rule="nonzero">
        <path
          d="M9.79637557,4.65256658 L5.34739171,0.203582721 C5.07594809,-0.0678609069 4.63572113,-0.0678609069 4.36427751,0.203582721 C4.09277827,0.47508196 4.09277827,0.915197688 4.36427751,1.18669693 L7.62655053,4.44896995 L0.726188736,4.44896995 C0.342297041,4.44896995 0.0310350082,4.76023199 0.0310350082,5.14412368 C0.0310350082,5.52795976 0.342297041,5.83927741 0.726188736,5.83927741 L7.62655053,5.83927741 L4.36438873,9.10155043 C4.09288949,9.37304967 4.09288949,9.8131654 4.36438873,10.0846646 C4.50008274,10.220303 4.67804209,10.2882613 4.85594583,10.2882613 C5.03384958,10.2882613 5.21175332,10.220303 5.34750294,10.0846646 L9.79637557,5.63568078 C10.0678748,5.36418154 10.0678748,4.92406582 9.79637557,4.65256658 Z"
          id="Shape"></path>
      </g>
    </g>
  </svg>
</div>