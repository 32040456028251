<ng-template #content let-modal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="icon-inner circle-ripple circle-green">
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <div class="icon-holder">
        <i [class]="iconClass"></i>
      </div>
    </div>
    <h4>{{title}}</h4>
    <p class="block-message">{{description}}</p>
    <div class="modal-actions">
      <button class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray" (click)="c('ok')">{{cancelText}}</button>
      <button class="btn btn-primary btn-custom" (click)="d()">{{confirmText}}</button>
    </div>
  </div>
</ng-template>
