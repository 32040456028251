<div class="app-icon">
    <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <path d="M13,7 L11,7 L11,4 L7,4 L7,7 L5,7 L9,11 L13,7 Z M16,12 L12,12 C12,13.6568542 10.6568542,15 9,15 C7.34314575,15 6,13.6568542 6,12 L2,12 L2,2 L16,2 L16,12 Z M16,0 L2,0 C0.89,0 0,0.9 0,2 L0,16 C0,17.1045695 0.8954305,18 2,18 L16,18 C17.1045695,18 18,17.1045695 18,16 L18,2 C18,0.8954305 17.1045695,0 16,0 Z"
                id="path-arrow-down"></path>
        </defs>
        <g id="inbox-arrow-down" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g>
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-arrow-down"></use>
                </mask>
                <use id="Mask" [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-arrow-down"></use>
            </g>
        </g>
    </svg>
</div>