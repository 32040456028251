import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SplashModalComponent} from '@app/shared/splash-modal/splash-modal.component';
import {ContestService, RecordingService} from '@app/core';
import {ToastrService} from 'ngx-toastr';
import {Contest} from '@app/models/contest.model';
import Paginator from '@app/contests/paginator';
import {Recording} from '@app/models/recording.model';
import {SearchBarInputComponent} from '@app/shared/search-bar-input/search-bar-input.component';

@Component({
  selector: 'app-audition-recording-to-contest',
  templateUrl: './audition-recording-to-contest.component.html',
  styleUrls: ['./audition-recording-to-contest.component.scss']
})
export class AuditionRecordingToContestComponent implements OnInit {
  modalRef: NgbModalRef;
  id;
  contest: Contest;
  paginator: Paginator;
  auditionRecording: Recording = null;
  term = '';
  @ViewChild('content', { static: true }) content;
  @ViewChild('splashModal', { static: true }) splashModal: SplashModalComponent;
  @ViewChild('searchInput') searchInput: SearchBarInputComponent;

  constructor(private contests: ContestService,
              private modalService: NgbModal,
              private recordings: RecordingService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
  }

  open(contest: Contest) {
    this.contest = contest;
    this.id = contest.contest_id;
    this.modalRef = this.modalService.open(this.content, {windowClass: 'add-member-modal'});
    this.getRecordings();
    this.modalRef.result.then(() => {
      this.paginator = null;
      //this.searchInput.clear && this.searchInput.clear();
    }, () => {
      this.paginator = null;
      // this.searchInput.clear && this.searchInput.clear();
    });
  }

  getRecordings() {
    this.paginator = new Paginator({
      page: 0,
      perPage: 15,
      isInfiniteScroll: true,
      nextPage: true,
      get: (page) => {
        if(this.contest.new_recordings_only) {
          //TODO: search after current round start_date
          return this.recordings.getAvailableRecordingsForContest({contestId: this.id, page, perPage: 15, term: this.term, sort: 'created_at'});
        } else {
          return this.recordings.searchMyRecordings(page, 15, this.term);
        }
      },
      handleSuccess: (response) => {
        this.paginator.items = this.paginator.items.concat(response.data);
        this.paginator.total = response.total || 30;
        this.paginator.page = response.current_page;
        this.paginator.nextPage = response.next_page_url;
      },
      handleError: (error) => {
        console.log('error', error);
      },
      items: []
    });

    this.paginator.request(0);
  }

  close() {
    this.modalRef.close('cancel');
  }

  onSearchHandle(term) {
    console.log(term);
    this.term = term;
    this.getRecordings();
  }

  selectRecording(recording) {
    console.log(recording);
    recording.submit_audition = !recording.submit_audition;
    if (recording.submit_audition) {
      this.clearRecordings();
      recording.submit_audition = true;
      this.auditionRecording = recording;
    }
  }

  clearRecordings() {
    this.paginator.items = this.paginator.items.map(item => {
      item.submit_audition = false;
      return item;
    });
  }

  auditionToContest() {
    console.log(this.auditionRecording);
    this.contests.join(this.contest.contest_id, this.auditionRecording.id).subscribe(response => {
      if (response.success) {
        this.toastr.success('Audition', 'Auditioned to contest');
        this.contests.updateJoinContest(response);
      } else {
        this.toastr.error('Audition', 'There was an error trying to join this contest');
      }
      this.close();
    });
  }
}
