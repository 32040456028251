


<ngx-loading-bar></ngx-loading-bar>

<router-outlet></router-outlet>

<!-- <ng-adsense   [width]="320"
  [height]="108"></ng-adsense> -->


  <!-- <ng-adsense
  [adClient]="'ca-pub-4776037543768273'"
  [adSlot]="9989671476"
></ng-adsense> -->