<div class="main">
    <div class="ssb-navbar">
                <span class="singsnap_logo" [routerLink]="['']">
                        <img src="../../../../assets/pictures/logo.png" alt="logo" class="logo"><span class="_singsnap">SingSnap</span>
                    </span>
        <span class="ssb-actions pull-right"><img (click)="isHamburgerVisible = !isHamburgerVisible"
                                                  [src]="isHamburgerVisible ? '../../../../assets/pictures/webSite/menu_black.png' : '../../../../assets/pictures/webSite/menu.png'"
                                                  alt="burger" class="burger_menu"></span>
        <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a
            [ngClass]="{'hamburger-colors': isHamburgerVisible}"
            [routerLink]="['/sign-up']">SIGN UP</a></span>
        <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a
            [ngClass]="{'hamburger-colors': isHamburgerVisible}"
            [routerLink]="['/login']">LOGIN</a></span>
    </div>
    <div (mouseout)="hideHamburger($event)" class="hamburger" *ngIf="isHamburgerVisible">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="ssb-navbar">

                    <span class="ssb-actions pull-right"><img (click)="isHamburgerVisible = !isHamburgerVisible"
                                                              [src]="isHamburgerVisible ? '../../../../assets/pictures/webSite/menu_black.png' : '../../../../assets/pictures/webSite/menu.png'"
                                                              alt="burger" class="burger_menu"></span>
                    <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a
                        [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                        [routerLink]="['/sign-up']">SIGN UP</a></span>
                    <span class="ssb-actions pull-right" *ngIf="!userIsLoged"><a
                        [ngClass]="{'hamburger-colors': isHamburgerVisible}"
                        [routerLink]="['/login']">LOGIN</a></span>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 menu_content">
                <span [routerLink]="['/main/home']"><span class="fat_line"></span> home</span><br>
                <span [routerLink]="['/main/aboutUs']"><span class="fat_line"></span> about us</span><br>
                <span [routerLink]="['/main/pricing']"><span class="fat_line"></span> pricing</span><br>
                <span [routerLink]="['/main/blog']"><span class="fat_line"></span> blog</span><br>
                <span [routerLink]="['/dashboard']"><span class="fat_line"></span> app</span><br>
                <!-- hidding store from website -->
                <!-- <span [routerLink]="['/main/shop']"><span class="fat_line"></span> store</span><br> -->
                <span [routerLink]="['/main/support']"><span class="fat_line"></span> support</span><br>
                <span [routerLink]="['/main/influencer']"><span class="fat_line"></span> influencer</span><br>
                <span (click)="onLogOut()" *ngIf="userIsLoged"><span class="fat_line"></span> log out</span><br>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 social-links">
                <img src="../../../../assets/pictures/webSite/hamburger_facebook.png" alt="">
                <img src="../../../../assets/pictures/webSite/hamburger_youtube.png" alt="">
                <img src="../../../../assets/pictures/webSite/hamburger_twitter.png" alt="">
                <img src="../../../../assets/pictures/webSite/hamburger_instagram.png" alt="">
            </div>
        </div>
    </div>
    <div class="main_content" >
        <div>
            <span class="cl-policy-name">Privacy Policy</span>
            <span class="cl-policy-desc">Last updated: April 7, 2022</span>
        </div>
    </div>
</div>
<div class="text-container">
    <p class="cl-paragraf-title">Introduction</p>
    <p>SingSnap respects the privacy of all of its users and customers and is committed to protecting such privacy in accordance with this Privacy Policy. This policy explains SingSnap's treatment of your personally identifiable information collected in connection with your activities on or related to SingSnap and any of its services. We urge you to read this policy carefully and to check back periodically for changes, which will indicate the latest revision date. This policy does not apply to the practices of companies or websites that are not owned or controlled by SingSnap. Your access or use of any SingSnap site indicates your understanding of, and agreement to be bound by, this policy and the SingSnap website Terms and Conditions.</p>
    <p class="cl-paragraf-title">What Personal Information Do We Collect?</p>
    <p>In the course of operating SingSnap services, we may collect two types of information about our users and customers: Personal Information and Non-Personal Information.
        "Personal Information" is personally identifiable information, such as your name, address, email address, birth date, gender, and credit card information. Personal Information is collected by us when you choose to provide it to us when registering for, or ordering a SingSnap service, such as when you choose to subscribe to a SingSnap service or newsletter, complete a SingSnap survey, or enter a SingSnap promotion. In addition, if you choose to contact a customer service representative of ours or provide us with feedback, we may keep a record of that correspondence so that we may fully address the subject matter. We may also receive Personal Information from our business partners and other third parties, provided that such third parties confirm to us that you have permitted them to share such information. Business contact information such as the name, title, business address, email address or telephone number of a business or professional person or an employee of an organization is not considered Personal Information.
        "Non-Personal Information" is information that is not linked to a specific person. This includes technical information such as your Internet Protocol Address, the domain you may have used to access a SingSnap service, the type and version of browser or operating system you may be using, and the website you may have come from and visit next. Aggregate information, such as the demographic statistics of our users and customers (e.g. average age or geographical allocation of our users), number of visitors and average time spent on a SingSnap service, is also considered Non-Personal Information. Non-Personal Information is collected or derived by us in the course of operating a SingSnap service. For example, our web servers may automatically collect Non-Personal Information that is provided through your browser or stored on a Cookie or through a Web Beacon when you choose to visit a SingSnap service.</p>
    <p class="cl-paragraf-title">How Do We Use Your Personal Information?</p>
    We may use Personal Information for the following purposes;
    <ol type="a">
        <li> Providing you with a SingSnap service and information you have requested from us,including your use of a SingSnap site or service. We may from time to time use your Personal Information to contact you about changes, enhancements or similar notices related to a SingSnap service;</li>
        <li>If you opted-into receiving offers or information from us or certain of our selected sponsors or advertisers, we may send you email materials and other mailings or social media contacts about products and services that we feel may be of interest to you. If at any time you would like to end your subscription to any such email materials, follow the directions posted at the bottom of the email or visit the SingSnap site;</li>
        <li>If you enter a contest or other promotion offered on a SingSnap site, we may, under the rules of the promotion, use your name and certain other information to administer the promotion, such as contacting, announcing and promoting the winners of the promotion; and</li>
        <li>We may also use Personal Information if we have reasonable grounds to believe the information could be useful when investigating a contravention of a federal, provincial, foreign or other law or in order to protect or defend a legal interest.</li>
    </ol>
    <p class="cl-paragraf-title">Disclosure of Your Personal Information</p>
    We will not sell or rent your Personal Information to any third party.<br/>
    We will only disclose your Personal Information to third parties under the following circumstances:
    <ol type="a">
        <li>Where you have specifically given us your consent to disclose your Personal Information for a designated purpose;</li>
        <li>To persons acting on our behalf as our agents, suppliers or service providers, solely to enable us to more efficiently provide you with a SingSnap service. Such entities act under strict contractual controls, requiring them to maintain the confidentiality of all such Personal Information and to use such information solely for purposes related to the provision of SingSnap services;</li>
        <li>As may be set out in the Terms and Conditions applicable to SingSnap services to facilitate the provision of such service, such as fulfilling legal requirements;</li>
        <li>As required by law, including by an order of any court, institution or body with jurisdiction or authority to compel the production of information, or in order to protect or defend a legal interest; or</li>
        <li>In connection with a SingSnap corporate re-organization, merger, amalgamation or similar transaction with another entity, or a sale of all or a substantial portion of the assets of SingSnap, provided that the Personal Information disclosed continues to be used for the purposes permitted by this policy by the entity acquiring the information.</li>
    </ol>
    <p class="cl-paragraf-title">How Do We Use Non-Personal Information?</p>
    <p>Non-Personal Information is collected or derived by us in the course of operating SingSnap services. In addition to our practices related to IP addresses, Cookies or Web Beacons, Non-Personal Information is used generally by us to monitor and improve the operation of our services, to perform statistical analyses of the collective characteristics and behavior of our users and customers, to measure user demographics and interests regarding specific SingSnap services, to describe our services to third parties such as prospective business partners and advertisers, and to analyze how and where best to use our resources. No Personal Information will be disclosed during these activities.
        We may also use Non-Personal Information to inform our sponsors and advertisers as to the performance of a particular sponsorship (such as a contest) or advertisement. In any such case, we may disclose to those third party sponsors and advertisers the overall aggregate demographics available regarding, for example, who participated in a contest or who saw and "clicked" on an advertisement. No Personal Information will be disclosed to these sponsors and advertisers as part of this process; only information in an anonymous, aggregate form will be disclosed.</p>
    <p class="cl-paragraf-title">How Do We Use Cookies and Web Beacons?</p>
    <p>A cookie is a small text file containing a unique identification number that is transferred (through your browser) from a web site to the hard drive of your computer. The cookie identifies your browser and will not let a web site know any personal information about you, such as your name and address. Since cookies are only text files, they cannot run on your computer, search your computer for other information, or transmit any information to anyone. Cookies are used on many major web sites. Most browsers are initially set up to accept cookies. If you prefer, you can reset your browser either to notify you when you have received a cookie, or to refuse to accept cookies. You should understand that while we do not require you to use cookies, certain of our services will not function properly if you set your browser to not accept cookies</p>
    SingSnap may use cookies to:
    <ol type="a">
        <li>Monitor website usage and improve the operation and performance of our and to make our services easier and more convenient to use. For example, cookies help us to remember who you are through your browser settings so that we can offer and provide more personalized services (e.g. language preference). Cookies also allow us to save passwords and preferences for you so that you will not have to re-enter them the next time you visit a SingSnap service;</li>
        <li>Measure aggregate user traffic and demographic statistics related to SingSnap services and advertisements (e.g. number of users, average time spent, average age and similar statistics). This information helps us to better understand when to update, change or offer new services and provides us with details as to the performance of third party advertisements and content. This information may be reported, in aggregate form only, to our current and prospective sponsors, advertisers and business partners. No Personal Information will be disclosed in such reports; and</li>
        <li>Displaying advertisements. SingSnap and third party advertising providers used by us may use cookies when serving advertisements.</li>
    </ol>
    Certain SingSnap services may contain electronic images known as Web Beacons - sometimes called single-pixel gifs - that allow us to count users and customers who have visited those pages and to deliver co-branded services. Web Beacons are not used to access your Personal Information; they are a technique used to compile aggregated statistics about SingSnap service usage. Web Beacons collect only a limited set of information, including a cookie number, time and date of a page view, and a description of the page on which the Web Beacon resides. Certain SingSnap service web pages may also contain Web Beacons placed there by our advertising partners to help us determine the effectiveness of our Internet advertising campaigns.
    <p class="cl-paragraf-title">What IP Address Information is Collected and How is It Used?</p>
    <p>An IP address is the unique number that is assigned to every direct Internet access point. Many IP addresses can generally be associated with certain Internet service providers or large organizations. When you, through your browser, request a web page or similar Internet information from another computer on the Internet, your browser automatically provides that computer with an IP address to which it will send the requested information.
        Whenever you request a SingSnap web page or certain other similar SingSnap content, your browser automatically provides our computers with your IP address, to which our computers will send the requested information. Each such request is logged by our computers with information including the IP address of the computer that requested the page and is stored by our computers as needed for the purposes described in this policy.
        We may use your IP address to display more appropriate and relevant content and advertising, such as content and advertising based on your geographic area. Aggregate information derived from IP addresses may be reported by us to our advertisers and partners. For example, we may report to our advertisers the estimated total number of users accessing a particular SingSnap’s site from a specific state or province. We may also use your IP address to facilitate the diagnoses and remedy of a technical problem reported by you or our technical team.</p>
    <p class="cl-paragraf-title">What Privacy Applies to Message Boards on the Site?</p>
    <p>SingSnap may feature message boards or forums that enable our users to communicate with each other. SingSnap does not moderate and is not responsible for the content that you or any user posts on any message board or forum. All Personal Information you post in a message board or forum becomes public information and may be viewed, collected and used by any visitor to the site, and may expose you to unsolicited messages. SingSnap strongly recommends that you do not post on any message board or forum private information about yourself or other individuals.</p>
    <p class="cl-paragraf-title">What Privacy Applies to Business Partners' and Third Party Web Sites?</p>
    <p>The SingSnap site contains links from and to sites operated by our business partners and other independent third parties. Once you leave the site, you may or may not see a new URL in the location bar. Please review the linked site's and third party content provider's privacy policies, and if you do not agree to be bound by the terms of the policies governing that provider/site, terminate your use of that service or your visit to the site. The linked sites are not under our influence or control, and we are not responsible for the privacy policies and practices or the content of the sites operated by our business partners or other third parties or the privacy policies and practices or contents of any linked site or subsequent links from that site.</p>
    <p class="cl-paragraf-title">How Do We Secure Your Information?</p>
    <p>Any unauthorized use of the SingSnap site may result in criminal and/or civil prosecution. We have policies and procedures in place to protect the privacy and confidentiality of your information that we collect and maintain, including contracts with third parties that manage the information provided by you. All Personal Information is stored on secured servers, behind a firewall at a data centre with access to data strictly controlled. Only individuals specifically involved in the analysis and other functions set out herein have access to your Personal Information. These individuals have been provided the requisite training on our privacy policies and practices.
        To help ensure the privacy of your Personal Information, we urge you not to share your user name and password with anyone and recommend that you change your password frequently and store it in a safe place. If you are asked to create a user name or password, please select words that do not personally identify you. We may offer "auto login" which allows users to enter member areas of our site without re-entering password information. You may want to disable auto login on your "Set Up" page if you are not the only person using your computer.</p>
    <p class="cl-paragraf-title">Who May Not Use the Site?</p>
    <p>SingSnap is not directed to persons under the age of 13. If you become aware that your child has provided us with Personal Information without your consent, please contact us. We do not knowingly collect Personal Information from children under 13. If we become aware that a child under 13 has provided us with Personal Information, we take steps to delete such information and terminate the child's account.</p>
    <p class="cl-paragraf-title">How Can We Be Contacted About Our Privacy Policy and Practices?</p>
    <p>If you have any concerns about privacy procedures at our site, wish to find out further details about how your Personal Information may be used, want to update your Personal Information, or want to have your personal information deleted write to us at support@singsnap.com.</p>
</div>
