import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService, UtilService } from '@app/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {
  pwdResetForm: UntypedFormGroup;
  errMsg = '';
  sccssMsg = '';
  success = false;
  constructor(
    private service: AuthenticationService,
    private util: UtilService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private toast: ToastrService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.pwdResetForm = new UntypedFormGroup({
      email: new UntypedFormControl()
    })
  }

  onSubmit() {
    if(!this.success){
      const result = this.service
        .onResetRequest(this.pwdResetForm.value)
        .toPromise().then((res)=>{
          this.errMsg = '';
          this.success = true;
          this.sccssMsg = res['message']
        }).catch((err)=>{
          this.sccssMsg = '';
          this.errMsg = err.error.errors.email
        })
            
    }
    // this.formGroup.controls.email.markAsDirty();
    // console.log("Sending")
    // console.log(this.formGroup)
    // if (this.formGroup.valid) {
    //   console.log("Sending")
    //   // submit
    //   // redirect to login
    // }
  }
}
