import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-actions-button',
    templateUrl: './actions-button.component.html',
    styleUrls: ['./actions-button.component.scss']
})
export class ActionsButtonComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
