import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileService } from './profile.service';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(private router: Router, private profileService: ProfileService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    console.log('can activate ? loading...');
    return new Observable<boolean>(observer => {
      this.profileService.checkUser().subscribe(
        response => {
          if (response['status'] === 200) {
            //this.router.navigate([state.url])
            observer.next(true);
          //observer.complete();
          } else {
            let allCookies = document.cookie.split(';');
            for (let i = 0; i < allCookies.length; i++) {
                document.cookie = allCookies[i] + "=;expires="
                + new Date(0).toUTCString();
            }
            this.whenLoggedOut(state.url)
            observer.next(true);
          }
          observer.complete();
        },
        (err) => {
          let allCookies = document.cookie.split(';');
          for (let i = 0; i < allCookies.length; i++) {
              document.cookie = allCookies[i] + "=;expires="
              + new Date(0).toUTCString();
          }
          console.log("ERR", err)
          console.log("ROUTE", route)
          console.log("STATE", state)
          this.whenLoggedOut(state.url)
          observer.next(true);
          observer.complete();
        }
      );
    });
  }

  canActivateChild( route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    console.log('can activate ? loading...');
    return new Observable<boolean>(observer => {
      this.profileService.checkUser().subscribe(
        response => {
          console.log( response['status'])
          if (response['status'] === 200) {
            //this.router.navigate([state.url])
            observer.next(true);
          //observer.complete();
          } else {
            let allCookies = document.cookie.split(';');
            for (let i = 0; i < allCookies.length; i++) {
                document.cookie = allCookies[i] + "=;expires="
                + new Date(0).toUTCString();
            }
            this.whenLoggedOut(state.url)
            observer.next(true);
          }
          observer.complete();
        },
        (err) => {
          let allCookies = document.cookie.split(';');
          for (let i = 0; i < allCookies.length; i++) {
              document.cookie = allCookies[i] + "=;expires="
              + new Date(0).toUTCString();
          }
          console.log("ERR", err)
          console.log("ROUTE", route)
          console.log("STATE", state)
          this.whenLoggedOut(state.url)
          observer.next(true);
          observer.complete();
        }
      );
    });
  }

  whenLoggedOut(argURL){
  //   if(argURL && argURL.includes('d/listen/')){
  //       let newArgURL= argURL.replace('d/listen/', 'play/');
  //       window.location.href = window.location.origin + newArgURL;
  //   }
    
  //  // let toGoToURL =  this.router.url ; 
  //  if ( toGoToURL.includes('login')  || toGoToURL === '/' || toGoToURL.includes('/home')  ){
     //   this.router.navigateByUrl("/login");
     // } else if ( toGoToURL.includes('sign-up')){
        //do nothing
      //} else {
      this.router.navigateByUrl("/login?url=" + argURL );
    //}

  }

}


