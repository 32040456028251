<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
    <title>Icons/Home</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M8,12 L11,12 C11.5522847,12 12,11.5522847 12,11 C12,10.4477153 11.5522847,10 11,10 L7,10 C6.44771525,10 6,10.4477153 6,11 L6,16 L2,16 L2,9.41421356 L9,2.41421356 L16,9.41421356 L16,16 L11,16 C10.4477153,16 10,16.4477153 10,17 C10,17.5522847 10.4477153,18 11,18 L17,18 C17.5522847,18 18,17.5522847 18,17 L18,9 C18,8.73478351 17.8946432,8.4804296 17.7071068,8.29289322 L9.70710678,0.292893219 C9.31658249,-0.0976310729 8.68341751,-0.0976310729 8.29289322,0.292893219 L0.292893219,8.29289322 C0.10535684,8.4804296 0,8.73478351 0,9 L0,17 C0,17.5522847 0.44771525,18 1,18 L7,18 C7.55228475,18 8,17.5522847 8,17 L8,12 Z"
        id="path-icon-home"></path>
    </defs>
    <g id="Icons/Home" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlink:href="#path-icon-home"></use>
      </mask>
      <use id="Combined-Shape" [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-icon-home"></use>
    </g>
  </svg>
</div>