<div class="notice payment-success">
  <div>
    <div class="icon-success">
      <div class="icon-inner circle-ripple">
        <span class="ripple-effect"></span>
        <span class="ripple-effect"></span>
        <span class="ripple-effect"></span>
        <div class="icon-holder">
          <app-icon-cancel color="white" size="36"></app-icon-cancel>
        </div>
      </div>
    </div>
  </div>
  <div class="notice-body">
    <h4>{{title}}</h4>
    <p>Sorry, there was a problem processing your payment. Please double check your payment details and try again. If you continue to have trouble please send us a message from the link below.</p>
  </div>
  <button class="btn ss-btn-primary-inverse btn-custom-step-gray" (click)="buttonClick($event)">
    {{btnText}}
  </button>
</div>
