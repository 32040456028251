<div class="cc-checkout">
  <div class="card-number-container">
    <div class="card-name">
        <label for="card-name">Name On Card</label>
        <input  type="text" class="card-name-box" [(ngModel)]="cardNameOn" placeholder="Enter Name on Card"  #name="ngModel" required  />
           <div *ngIf="name.invalid && isEnteringInfo" class="card-errors">
             <div *ngIf="name.errors?.['required']">
              *NAME ON CARD is required.
             </div>
           </div>
    </div>
    <label for="card-number">Credit card number</label>
    <div class="card-number-container-brand">
      <div id="card-number" #cardNumberElement></div>
      <span class="brand">
        <em [class]="brandClass"></em>
      </span>
      <span class="card-number-valid" *ngIf="validCreditCard">
        <app-icon-check color="#58C7C9" size="14px"></app-icon-check>
      </span>
    </div>
  </div>
  <div class="card-number-expiry-cvc">
    <div>
      <label for="card-expiry">Expiration date</label>
      <div id="card-expiry" #cardExpiryElement></div>
    </div>
    <div>
      <label for="card-cvc">CVC code</label>
      <div id="card-cvc" #cardCvcElement></div>
    </div>
  </div>

  <!-- display errors -->
  <p class="card-errors">{{cardErrors}}</p>
</div>
