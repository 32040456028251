import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-bubble',
  templateUrl: './icon-bubble.component.html'
})
export class IconBubbleComponent implements OnInit {
  @Input() color = '#262628';
  @Input() size = 16;

  constructor() { }

  ngOnInit() {
  }

}
