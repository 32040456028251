// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    staging: false,
    baseAPIUrl: 'https://dev-api.singsnap.com/v1',
    converterApiUrl: 'https://blob.valleyeyelet.com/api/v1',
    uploaderAPIUrl: 'https://dev-recorder.singsnap.com/uploader',
    publicVapidKey: 'BFxvayWb-yTLWbMunCypLo8fB1u7xKdF3urmhRMa22R7SNfBnYvOindVnRliRWxwHYQcqnqiTtfv807g0NxeAUI',
    privateVapidKey: 'gNMbsgJUMXBmaljoXjjJAStq0ppJPWayEZ_-o0gLuCY',
    stripeKey: 'pk_test_9pnii3BhCvjVFuYZwW3JayPe',
    imgixURL: 'https://dev-singsnap.imgix.net/assets/images/',
    wsURL: 'wss://dev-api.singsnap.com:8081/ws/',
    settings: {
        lyricColor: 'SINGSNAP-SETTINGS-LYRIC-COLOR'
    },
    social: {
        facebookAppId: '731881250586318',
        googleAuthClientId: '1050931350331-saili609lkelb2i9bbtlj6g373e8nck8.apps.googleusercontent.com'
    },
    PAYPAL_MODE: 'sandbox',
    bugSnagId: 'b70f9e80b3920a1efcbbfab8176adde1'
};


// export const environment = {
//     production: false,
//     staging: false,
//     baseAPIUrl: 'https://beta.singsnap.com/v1',
//     converterApiUrl: 'https://blob.valleyeyelet.com/api/v1',
//     uploaderAPIUrl: 'https://upload.mixer.singsnap.com/uploader',
//     publicVapidKey: 'BFxvayWb-yTLWbMunCypLo8fB1u7xKdF3urmhRMa22R7SNfBnYvOindVnRliRWxwHYQcqnqiTtfv807g0NxeAUI',
//     privateVapidKey: 'gNMbsgJUMXBmaljoXjjJAStq0ppJPWayEZ_-o0gLuCY',
//     stripeKey: 'pk_live_JX9QMvqFwRxJC6s079L8RM8M',
//     settings: {
//         lyricColor: 'SINGSNAP-SETTINGS-LYRIC-COLOR'
//     },
//     social: {
//         facebookAppId: '238831666919561',
//         googleAuthClientId: '1050931350331-7oak4663rt6e4vbe41nhtidipc416nl8.apps.googleusercontent.com'
//     },
//     PAYPAL_MODE: 'live'
// };



