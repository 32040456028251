import { Component, OnInit } from '@angular/core';





@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    // const MY_IFRAME = document.createElement('iframe');
    // MY_IFRAME.setAttribute(
    //   'allow',
    //   'microphone; camera; autoplay; display-capture'
    // );
    
    // const iframeProperties = { url: 'DAILY_ROOM_URL' };
    
    // document.body.appendChild(MY_IFRAME);
    
    // let callFrame = DailyIframe.wrap(MY_IFRAME, iframeProperties);
    
    // callFrame.join();
  }

}
