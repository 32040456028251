<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 24 24" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 52.5 (67469) - http://www.bohemiancoding.com/sketch -->
    <title>ic_music_note</title>
    <desc>Created with Sketch.</desc>
    <g id="ic_music_note" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icon-24px">
        <g>
          <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
          <path
            d="M12,3 L12,13.55 C11.41,13.21 10.73,13 10,13 C7.79,13 6,14.79 6,17 C6,19.21 7.79,21 10,21 C12.21,21 14,19.21 14,17 L14,7 L18,7 L18,3 L12,3 L12,3 Z"
            id="Shape" [attr.fill]="color"></path>
        </g>
      </g>
    </g>
  </svg>
</div>