<div class="ms-list mboard-list">
  <!-- <div class="ssc-list-header">
    <h1 class="title">Categories</h1>
    <div class="ssc-search-holder">
      <app-search-bar-input (onSearch)="onSearch($event)">
      </app-search-bar-input>
    </div>
  </div> -->
  <!-- <div class="ssc-list-content">
    Content here
  </div> -->
  <div class="d-none d-sm-block" *ngIf="showOrder">
    <div
      *ngIf="type !== 'innercircle'"
      class="ms-list-header mboard-list-header mboard-row"
    >
      <div *ngIf="isCategory">
        <a (click)="onOrderClick('name')">
          Category Name
          <app-sort-arrow [active]="orderByName === 'name'"></app-sort-arrow>
        </a>
      </div>
      <!-- Show or hide sorting arrows/funtionality -->
      <div *ngIf="!isCategory">
        <div *ngIf="isNotSortable; else elseBlock"><a>Thread Name</a></div>
        <ng-template #elseBlock>
          <a (click)="onOrderClick('title')">
            Thread Name
            <app-sort-arrow [active]="orderByName === 'title'"></app-sort-arrow>
          </a>      
        </ng-template>
      </div>
      <div>
        <div *ngIf="!isCategory">
          <div *ngIf="isNotSortable; else elseBlock2"><a>Replies</a></div>
          <ng-template #elseBlock2>       
              <a (click)="onOrderClick('replies_count')">
                Replies
                <app-sort-arrow
                  [active]="orderByName === 'replies_count'"></app-sort-arrow>
              </a>
          </ng-template>
        </div>
        <div *ngIf="isCategory"> 
          <div *ngIf="isNotSortable; else elseBlock3"><a>Thread</a></div>
          <ng-template #elseBlock3> 
              <a (click)="onOrderClick('threads_count')">
                Thread
                <app-sort-arrow
                  [active]="orderByName === 'threads_count'"></app-sort-arrow>
              </a>
          </ng-template>
        </div>
      </div>
      <div>
        <div *ngIf="isNotSortable; else elseBlock4"><a>Last Update</a></div>
        <ng-template #elseBlock4> 
        <a (click)="onOrderClick('updated_at')">
          Last Update
          <app-sort-arrow
            [active]="orderByName === 'updated_at'"
          ></app-sort-arrow>
        </a>
      </ng-template>
      </div>
    </div>
    <div
      *ngIf="type === 'innercircle'"
      class="mboard-list-header mboard-row ms-list-header mboard-two-cols"
    >
      <div *ngIf="isCategory">
        <a (click)="onOrderClick('name')">
          Name
          <app-sort-arrow [active]="orderByName === 'name'"></app-sort-arrow>
        </a>
      </div>
      <div>
        <a (click)="onOrderClick('-updated_at')">
          Creation Date
          <app-sort-arrow
            [active]="orderByName === '-updated_at'"
          ></app-sort-arrow>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="ms-list mboard-list"  *ngIf="!loading; else skeleton">
  <div class="backdrop-loading" [ngClass]="{ 'd-none': !loading }">
    <i class="fa fa-spinner" aria-hidden="true"></i>
  </div>

  <div *ngIf="showInFeedAds">
    <ng-adsense
    [adClient]="'ca-pub-4776037543768273'"
    [adSlot]="8177906096"
    [display]="'block'"
    [adFormat]="'fluid'"
    [layoutKey]="'-fb+5w+4e-db+86'"
    [height]="80"
    [fullWidthResponsive]="true"
    ></ng-adsense>
  </div>

  <div
    *ngIf="
      type !== 'topic' &&
      type !== 'favorite' &&
      type !== 'recent' &&
      type !== 'innercircle' &&
      type !== 'announcements'
    "
  >
    <a
      class="ms-list-item mboard-row hoverOverList"
      *ngFor="let item of list"
      [routerLink]="[getRouteBase(item), item.id]"
      [queryParamsHandling]="'merge'"
    >
     <div class="d-flex align-self-center px-0" >
        <app-message-board-thumb [thumbnailSrc]="item.cover">
        </app-message-board-thumb>
        <a
          class="align-self-center ms-list-item__name"
          [routerLink]="[getRouteBase(item), item.id]"
          [queryParams]="{ category: item.id }"
          [queryParamsHandling]="'merge'"
        >
          <h3 >{{ item.name || item.title }}</h3>
          <p class="d-block d-sm-none" *ngIf="!item?.contest_id">
            {{ item.threads_count }}
            <span *ngIf="item.threads_count == 1">thread</span
            ><span *ngIf="item.threads_count != 1">threads</span>
          </p>
          <p  class="d-block d-sm-none" *ngIf="item?.contest_id">
            {{ item.rounds_count }}
            <span *ngIf="item.threads_count == 1">round</span
            ><span *ngIf="item.threads_count != 1">rounds</span>
          </p>
          <p class="d-none d-sm-block">{{ item.description }}.</p>
        </a>
      </div>
      <div
        *ngIf="this.type === 'contest'"
        class="d-none d-sm-block ms-list-item__count"
      >
        <h3>{{ item.sub_categories_count || 0 }}</h3>
      </div>
      <div
        *ngIf="this.type !== 'contest'"
        class="d-none d-sm-block ms-list-item__count"
      >
        <h3 style="text-align:left;" >{{ item.threads_count || item.replies_count || 0 }}</h3>
      </div>

      <div class="d-none d-sm-block">
        <!-- <div *ngIf="isCategory ; else showUser"> {{ item.updated_at }}   </div> -->
        <app-time-stamp style="justify-content: normal;" [time]="item.updated_at" [needWatch]="false" [format]="'MMM d, yyyy hh:mma'"></app-time-stamp>
        <ng-template #showUser>
          <app-ss-user
          [user]="item.latest_user ? item.latest_user : item.user"
          [timestamp]="item.updated_at"
          [showLevel]="false"
          [showStatus]="false"
          [disabled]="false"
          ></app-ss-user>             
        </ng-template>
      </div>    
    </a>
  </div>
  <div *ngIf="type === 'topic'">
    <a
      class="ms-list-item mboard-row hoverOverList"
      *ngFor="let item of list"
    >
    <a [routerLink]="[getRouteBase(item), item.id]" [queryParamsHandling]="'merge'">
      <div class="d-flex align-self-center px-0">
        <div class="d-flex align-items-center"  [routerLink]="[getRouteBase(item), item.id]"
        [queryParamsHandling]="'merge'" style="margin-right: 8px;">
          <a (click)="toggleFavorite(item); $event.stopPropagation()">
            <app-icon-favorite
              [size]="24"
              color="#F9C546"
              *ngIf="item.favorited"
            ></app-icon-favorite>
            <app-icon-favorite-outline
              *ngIf="!item.favorited"
            ></app-icon-favorite-outline>
          </a>
        </div>
        <app-message-board-thumb [thumbnailSrc]="item?.cover">
        </app-message-board-thumb>
        <div
          class="align-self-center ms-list-item__name"
          [routerLink]="[getRouteBase(item), item.id]"
          [queryParamsHandling]="'merge'"
        >
          <h3>{{ item.title }}</h3>
          <p class="d-block d-sm-none">{{ item.replies_count }} replies.</p>
          <p>
            by
            <app-ss-user-name [user]="item.user" [showLevel]="false">
            </app-ss-user-name>
          </p>
        </div>
      </div>
    </a>
      <div class="d-none d-sm-block ms-list-item__count">
        <h3 style="text-align:left;">{{ item.replies_count }}</h3>
      </div>
      <div class="d-none d-sm-block" (click)="routeToLastReply(item)" title="Go to last reply">
        <app-ss-user
          [user]="item.latest_user ? item.latest_user : item.user"
          [timestamp]="item.updated_at"
          [showLevel]="false"
          [showStatus]="false"
          [showPhoto]="false"
        ></app-ss-user>
        <span class="lastReplyText">Go to last reply</span>
      </div>
      <!-- <div class="d-none d-sm-block" (click)="routeToLastReply(item)" title="Go to last reply">Go to last reply</div> -->
      <!-- <div class="hoverOverListReplyText" (click)="routeToLastReply(item)">Go to last reply</div> -->
      <!-- <a  (click)="routeToLastReply(item)">Go to last reply</a> -->
     
      <div class="mboard-list-menu" (click)="menuClicked($event)">
        <!-- Remove 3 dot menu - "Share Thread" -->
        <!-- <app-more-dot-menu>
          <div class="dropdown-menu-content">
            <div (click)="shareThread(item)">Share thread topic</div>
            <div (click)="routeToLastReply(item)">Go to last reply</div>
          </div>
        </app-more-dot-menu>    -->
      </div>
    </a> 
  </div>
  <div *ngIf="type === 'favorite'">
    <a
      class="ms-list-item mboard-row hoverOverList"
      *ngFor="let item of list"
    >
    <a [routerLink]="[getRouteBase(item), item.id]" [queryParamsHandling]="'merge'">
      <div class="d-flex align-self-center px-0">
        <div class="d-flex align-items-center" style="margin-right: 8px;">
          <a (click)="toggleFavorite(item); $event.stopPropagation()">
            <app-icon-favorite
              [size]="24"
              color="#F9C546"
              *ngIf="!item.favorite?.favorited"
            ></app-icon-favorite>
            <app-icon-favorite-outline
              *ngIf="item.favorite?.favorited"
            ></app-icon-favorite-outline>
          </a>
        </div>
        <app-message-board-thumb [thumbnailSrc]="item?.cover">
        </app-message-board-thumb>
        <div
          class="align-self-center ms-list-item__name"
          [routerLink]="[getRouteBase(item), item.id]"
          [queryParamsHandling]="'merge'"
        >
          <h3>{{ item.title }}</h3>
          <p class="d-block d-sm-none">{{ item.replies_count }} replies.</p>
          <p>
            by
            <app-ss-user-name [user]="item.user" [showLevel]="false">
            </app-ss-user-name>
          </p>
        </div>
      </div>
    </a>
      <div class="d-none d-sm-block ms-list-item__count">
        <h3>{{ item.replies_count }}</h3>
      </div>
      <div class="d-none d-sm-block"  (click)="routeToLastReply(item)" title="Go to last reply">
        <app-ss-user
          [user]="item.latest_user ? item.latest_user : item.user"
          [timestamp]="item.updated_at"
          [showLevel]="false"
          [showStatus]="false"
          [showPhoto]="false"
        ></app-ss-user>
        <span class="lastReplyText">Go to last reply</span>
      </div>
      <div class="mboard-list-menu" (click)="menuClicked($event)">
        <!-- Remove 3 dot menu - "Share Thread"
        <app-more-dot-menu>
          <div class="dropdown-menu-content">
            <div (click)="shareThread(item)">Share thread favorite</div>
          </div>
        </app-more-dot-menu>  -->
      </div>
    </a>    
  </div>
  <div *ngIf="type === 'announcements'">
    <a
      class="ms-list-item mboard-row hoverOverList"
      *ngFor="let item of list"
    >
    <a [routerLink]="[getRouteBase(item), item.id]" [queryParamsHandling]="'merge'">
      <div class="d-flex align-self-center px-0">
        <div class="d-flex align-items-center" style="margin-right: 8px;">
          <a (click)="toggleFavorite(item); $event.stopPropagation()">
            <app-icon-favorite
              [size]="24"
              color="#F9C546"
              *ngIf="!item.favorite?.favorited"
            ></app-icon-favorite>
            <app-icon-favorite-outline
              *ngIf="item.favorite?.favorited"
            ></app-icon-favorite-outline>
          </a>
        </div>
        <app-message-board-thumb [thumbnailSrc]="item?.cover">
        </app-message-board-thumb>
        <div
          class="align-self-center ms-list-item__name"
          [routerLink]="[getRouteBase(item), item.id]"
          [queryParamsHandling]="'merge'"
        >
          <h3>{{ item.title }}</h3>
          <p class="d-block d-sm-none">{{ item.replies_count }} replies.</p>
          <p>
            by
            <app-ss-user-name [user]="item.user" [showLevel]="false">
            </app-ss-user-name>
          </p>
        </div>
      </div>
    </a>
      <div class="d-none d-sm-block ms-list-item__count">
        <h3>{{ item.replies_count }}</h3>
      </div>
      <div class="d-none d-sm-block"  (click)="routeToLastReply(item)" title="Go to last reply">
        <app-ss-user
          [user]="item.latest_user ? item.latest_user : item.user"
          [timestamp]="item.updated_at"
          [showLevel]="false"
          [showStatus]="false"
          [showPhoto]="false"
        ></app-ss-user>
        <span class="lastReplyText">Go to last reply</span>
      </div>
      <div class="mboard-list-menu" (click)="menuClicked($event)">
        <!-- Remove 3 dot menu - "Share Thread"
        <app-more-dot-menu>
          <div class="dropdown-menu-content">
            <div (click)="shareThread(item)">Share thread favorite</div>
          </div>
        </app-more-dot-menu>  -->
      </div>
    </a>    
  </div>
  <div *ngIf="type === 'recent'">
    <a
      class="ms-list-item mboard-row hoverOverList"
      *ngFor="let item of list"
    >
    <a [routerLink]="['/d/message-board/topics/', item.id]" [queryParamsHandling]="'merge'">
      <div class="d-flex align-self-center px-0">
        <div class="d-flex align-items-center" style="margin-right: 8px;">
          <a (click)="toggleFavorite(item); $event.stopPropagation()">
            <app-icon-favorite
              [size]="24"
              color="#F9C546"
              *ngIf="item?.favorited"
            ></app-icon-favorite>
            <app-icon-favorite-outline
              *ngIf="!item?.favorited"
            ></app-icon-favorite-outline>
          </a>
        </div>
        <app-message-board-thumb
          [routerLink]="['/d/message-board/topics/', item.id]"
          [queryParamsHandling]="'merge'"
          [thumbnailSrc]="item?.cover"
        >
        </app-message-board-thumb>
        <div
          class="align-self-center ms-list-item__name"
          [routerLink]="['/d/message-board/topics/', item.id]"
          [queryParamsHandling]="'merge'"
        >
          <h3>{{ item.title }}</h3>
          <p class="d-block d-sm-none">{{ item.replies_count }} replies.</p>
          <p>
            by
            <app-ss-user-name [user]="item.user" [showLevel]="false">
            </app-ss-user-name>
          </p>
        </div>
      </div>
    </a>
      <div
        class="d-none d-sm-block ms-list-item__count"
        [routerLink]="['/d/message-board/topics/', item.id]"
        [queryParamsHandling]="'merge'"
      >
        <h3>{{ item.replies_count }}</h3>
      </div>
      <div class="d-none d-sm-block"  (click)="routeToLastReply(item)" title="Go to last reply">
        <app-ss-user
          [user]="item.latest_user ? item.latest_user : item.user"
          [timestamp]="item.updated_at"
          [showLevel]="false"
          [showStatus]="false"
          [showPhoto]="false"
        ></app-ss-user>
        <span class="lastReplyText">Go to last reply</span>
      </div>
      <div class="mboard-list-menu" (click)="menuClicked($event)">
         <!-- Remove 3 dot menu - "Share Thread"  
        <app-more-dot-menu>
          <div class="dropdown-menu-content">
            <div (click)="shareThread(item)">Share thread recent</div>
          </div>
        </app-more-dot-menu>  -->
      </div>
    </a>
  </div>
</div>


<ng-template  #skeleton>

  <div *ngIf="skeletonType === 'mBoard1'">
    <div *ngFor="let e of [].constructor(18)">
      <div class="skeleton-mboard-list">
        <div class="skeleton-mboard-list-image skeleton-color"></div>
          <div class="col-auto px-0 align-items-center justify-content-between" style="flex: 0 1 49%; margin-left: 10px;">
            <div class="skeleton-mboard-list-title skeleton-color"></div>
            <div class="skeleton-mboard-list-description skeleton-color"></div>
          </div>
          <div class="skeleton-mboard-number skeleton-color"  ></div>
        <div class="skeleton-mboard-list-date skeleton-color" style="flex: 0 1 16%;" ></div>
    <!--   <div class="skeleton-featured-list-stats">
            <div class="skeleton-featured-list-stat skeleton-color"></div>
            <div class="skeleton-featured-list-stat skeleton-color"></div>
            <div class="skeleton-featured-list-stat skeleton-color"></div>
        </div>
        <div  style="display: flex;  align-items: center; margin-left: -68px; " >
            <div class="skeleton-featured-list-button skeleton-color" style="margin-right: 6px;"></div>
            <div class="skeleton-featured-list-3dot skeleton-color"></div>
        </div> -->
      </div>
    </div>
  </div>

  <div *ngIf="skeletonType === 'mBoard2'">
    <div *ngFor="let e of [].constructor(18)">
      <div class="skeleton-mboard-list">
        <div class="skeleton-mboard-star skeleton-color"  ></div>
        <div class="skeleton-mboard-list-image skeleton-color"></div>
          <div class="col-auto px-0 align-items-center justify-content-between" style="flex: 0 1 49%; margin-left: 10px;">
            <div class="skeleton-mboard-list-title skeleton-color"></div>
            <div class="skeleton-mboard-list-short-text skeleton-color"></div>
            <div class="skeleton-mboard-list-description skeleton-color"></div>
          </div>
          <div class="skeleton-mboard-number2 skeleton-color"  ></div>
          <div class="col-auto px-0 align-items-center justify-content-between" style="flex: 0 1 28%; margin-left: 26px;">
            <div class="skeleton-mboard-list-description skeleton-color"></div>
            <div class="skeleton-mboard-list-date skeleton-color" ></div>
            <div class="skeleton-mboard-list-medium-text skeleton-color"></div>
          </div>
    <!--   <div class="skeleton-featured-list-stats">
            <div class="skeleton-featured-list-stat skeleton-color"></div>
            <div class="skeleton-featured-list-stat skeleton-color"></div>
            <div class="skeleton-featured-list-stat skeleton-color"></div>
        </div>
        <div  style="display: flex;  align-items: center; margin-left: -68px; " >
            <div class="skeleton-featured-list-button skeleton-color" style="margin-right: 6px;"></div>
            <div class="skeleton-featured-list-3dot skeleton-color"></div>
        </div> -->
      </div>
    </div>
  </div>


</ng-template>
