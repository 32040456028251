import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  NgZone,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import {
  GenresService,
  UsersService,
  RecordingService,
  UtilService,
  ProfileService,
  EventService,
  HelperService,
} from '@app/core';

@Component({
  selector: 'app-listen-re',
  templateUrl: './listen-re.component.html',
  styleUrls: ['./listen-re.component.scss'],
})
export class ListenReComponent implements OnInit, OnDestroy {
  @ViewChild('playlist', { static: true }) private playlistElem;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  recording;
  recommendedRecordings$;
  recordingSortOptions = [];
  genreSortOptions = this.util.recordingSortOptions;
  @ViewChild('goldcontent', { static: true }) private goldModal;
  loggedInIsGold = true;
  byFavData: any = {
    ...this.util.generateGridData(),
    perPage: 20,
    fetch: this.recordingService.getRecordingsFromFavMembers.bind(
      this.recordingService
    ),
  };

  featuredData: any = {
    ...this.util.generateGridData(),
    perPage: 20,
    fetch: this.recordingService.getFeaturedRecordings.bind(
      this.recordingService
    ),
  };

  favoriteData: any = {
    ...this.util.generateGridData(),
    perPage: 20,
    fetch: this.recordingService.getUserFavRecordingsV2.bind(
      this.recordingService
    ),
  };

  genresData: any = {
    ...this.util.generateGridData(),
    perPage: 20,
    sort: this.genreSortOptions[0],
    fetch: this.genreService.getGenres.bind(this.genreService),
  };

  byICData: any = {
    ...this.util.generateGridData(),
    perPage: 20,
    fetch: this.recordingService.getRecordingsFromIC.bind(
      this.recordingService
    ),
  };

  recentRecordedData: any = {
    ...this.util.generateGridData(),
    perPage: 20,
    fetch: this.recordingService.getRecentlyRecorded.bind(
      this.recordingService
    ),
  };

  newMemberData: any = {
    ...this.util.generateGridData(),
    perPage: 20,
    fetch: this.recordingService.getRecentByNewUsers.bind(
      this.recordingService
    ),
  };

  jamsData: any = {
    ...this.util.generateGridData(),
    perPage: 20,
    fetch: this.recordingService.getOpenJams.bind(this.recordingService),
  };

  openDuetsData: any = {
    ...this.util.generateGridData(),
    perPage: 20,
    fetch: this.recordingService.getDuets.bind(this.recordingService),
  };

  constructor(
    private recordingService: RecordingService,
    private genreService: GenresService,
    public profileService: ProfileService,
    private usersService: UsersService,
    private helperService: HelperService,
    private eventService: EventService,
    private toastService: ToastrService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private util: UtilService,
    private ngZone: NgZone
  ) {
    this.recordingSortOptions = this.util.recordingSortOptions;
    this.byFavData.sort = this.util.recordingSortOptions[0];
    this.featuredData.sort = this.util.recordingSortOptions[0];
    this.favoriteData.sort = this.util.recordingSortOptions[0];
    this.byICData.sort = this.util.recordingSortOptions[0];
    this.recentRecordedData.sort = this.util.recordingSortOptions[0];
    this.newMemberData.sort = this.util.recordingSortOptions[0];
    this.jamsData.sort = this.util.recordingSortOptions[0];
    this.openDuetsData.sort = this.util.recordingSortOptions[0];
  }

  ngOnInit() {
    this.onScrollEndHandle(this.featuredData, null);
    this.onScrollEndHandle(this.favoriteData, null);
    this.onScrollEndHandle(this.recentRecordedData, null);
    this.onScrollEndHandle(this.newMemberData, null);
    this.onScrollEndHandle(this.byFavData, null);
   // this.onScrollEndHandle(this.byICData, null);
    this.onScrollEndHandle(this.jamsData, null);
    this.onScrollEndHandle(this.openDuetsData, null);
    this.onScrollEndHandle(this.genresData, null);
    console.log(this.favoriteData.items)
    let loggedIn = this.util.getloggedUserData();
    this.loggedInIsGold = loggedIn.gold;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkMine(item){
    return item.favorited;
  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  navListen(id){
    this.navigate([`/d/listen/${id}`]);
  }

  navEditRec(id){
    this.navigate([`/d/my-recordings/${id}`]);
  }

  navProfile(id){
    this.navigate([`/d/profile/${id}`]);
  }

  navGenres(id){
    this.navigate([`/d/genres/${id}/recordings`]);
  }

  handleDuet(duet) {
    console.log(duet);
    if (duet.song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    this.router.navigate(['/duet-recorder', duet.id]);
  }

  favRecording(recording) {console.log("recording: ", recording)
    this.recordingService.fav(recording.id).subscribe(response => {
      if (response.success) {
        recording.favorited = true;
        this.toastService.success('Recording was added to favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  unFavRecording(recording) {
    this.recordingService.unfav(recording.id).subscribe(response => {
      if (response.success) {
        recording.favorited = false;
        this.toastService.success('Recording was removed from favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  onScrollEndHandle(data, sort) {

    if (!data.fetch) {
      return;
    }
    if (sort) {
      data.sort = sort;
    }

    if (!data.loading && data.lastPage) {
      data.loading = true;
      data.currentPage++;
      //this.detectChanges();
      data.fetch(data.currentPage, data.perPage, data.sort.field).subscribe(
        response => {
          data.items = data.items.concat(response.data);
          data.lastPage = response.next_page_url;
          data.loading = false;
          this.detectChanges();
        },
        error => {
          this.toastService.error(error.message);
        }
      );
    }
  }

  resetDataObj(data) {
    data.currentPage = 0;
    data.loading = false;
    data.items = [];
    data.lastPage = 1;
    return data;
  }

  recordingToLegacy(recording) {
    this.recordingService.appearOnLegacy(recording.id).subscribe(
      response => {
        this.toastService.success('Recording will appear on legacy');
      },
      () => {
        this.toastService.success("There's an error, please try again later.");
      }
    );
  }

  onAddtoPlaylist(id) {
    this.playlistElem.open(id);
  }

  async subscribeUser(recording) {
    const recordingList = [
      this.featuredData.items,
      this.favoriteData.items,
      this.byICData.items,
      this.newMemberData.items,
      this.jamsData.items,
      this.openDuetsData.items,
      this.recentRecordedData.items,
    ];
    this.helperService.subscribeUser(recording, recordingList);
  }

  async unsubscribeUser(recording) {
    const recordingList = [
      this.featuredData.items,
      this.favoriteData.items,
      this.byICData.items,
      this.newMemberData.items,
      this.jamsData.items,
      this.openDuetsData.items,
      this.recentRecordedData.items,
    ];
    this.helperService.unsubscribeUser(recording, recordingList);
  }

  shareRecording(recording) {
    this.eventService.onSocialShare({ recording });
  }

  howOthersSingThis(id){
    this.navigate([`/d/ListenOthersSing/${id}`]);
  }
}
