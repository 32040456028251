import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-restart',
  templateUrl: './icon-restart.component.html',
  styleUrls: ['./icon-restart.component.css']
})
export class IconRestartComponent implements OnInit {
  @Input() color: string = '#262628';
  @Input() size: number = 18;

  constructor() { }

  ngOnInit() {
  }

}
