import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-cancel',
  templateUrl: './icon-cancel.component.html',
  styleUrls: ['./icon-cancel.component.scss']
})
export class IconCancelComponent implements OnInit {
  @Input() color: string = '#262628';
  @Input() size: number = 18;

  constructor() { }

  ngOnInit() {
  }

}
