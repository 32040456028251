import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {CategoriesService} from '@app/core';

@Component({
  selector: 'app-shop-category',
  templateUrl: './shop-category.component.html',
  styleUrls: ['./shop-category.component.scss']
})
export class ShopCategoryComponent implements OnInit {

  products: any[] = [];
  selection:boolean = false;
  id: number;
  categories: any[] = [];
  category:any = {};
  serverResponse: any[] = [];
  emptyCategory: boolean = false;
  filter: {orderby:string, order:string, perPage:number, tag:string};
  categoriesResponse: any;

  constructor(private route: ActivatedRoute,
              private categoriesService:CategoriesService,
              private router: Router) {
    this.filter = {orderby:'date', order:'asc', perPage:6, tag: 'both'};
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = +params['id'];
    });
    this.getProductsFromCategory();
    this.selectedCategory();
  }

  selectedCategory() {
    this.emptyCategory = false;
    this.categoriesService.selectedCategory(this.id)
        .subscribe(
            response => this.category = response,
            err => console.error(err)
        )
  }

  getProductsFromCategory() {
    this.categoriesService.getProductsFromCategory(this.id, this.filter)
        .subscribe(
            response => this.serverResponse = response,
            err => console.error(err),
            () => this.obtainedProducts()
        )
  }

  obtainedProducts() {
    if(this.serverResponse.length > 0) {
      this.products = this.serverResponse
    } else {
      this.emptyCategory = true;
    }
  }

  loadMoreProducts() {
    this.filter.perPage += 6;
    this.getProductsFromCategory();
  }

  getCategories() {
    this.selection == true ? this.selection = false : this.selection = true;
    this.categoriesService.getCategories(0)
        .subscribe(
            response => this.categoriesResponse = response,
            err => console.error(err),
            () => this.obtainedCategories()
        )
  }

  obtainedCategories() {
    // if(this.categoriesResponse.content) {
    //   this.categories = this.categoriesResponse.content; ???
    // }
    if(this.categoriesResponse) {
      this.categories = this.categoriesResponse;
    }
  }

  selectCategory(id) {
    this.selection == true ? this.selection = false : this.selection = true;
    this.id = id;
    this.products = [];
    this.getProductsFromCategory();
    this.selectedCategory();
    this.router.navigate(['/main/shop/category', id]);
  }

  selectFilter(sorting) {
    this.filter.order = sorting;
    this.getProductsFromCategory();
    this.selection = false;
  }

  setTag(tag) {
    this.filter.tag = tag;
    this.filter.perPage = 6;
    this.selection = false;
    this.getProductsFromCategory();
  }

}
