<ng-template #content let-modal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="audition-title">
      <h4>Manage Co-Host</h4>
      <p>
        You can set the co-host
      </p>
    </div>
    <hr>
    <div class="search-input form-row">
      <app-search-bar-input #searchInput class="col" (onSearch)="onSearchHandle($event)"></app-search-bar-input>
    </div>
    <app-featured-list
      *ngIf="paginator"
      class="audition-list"
      [featuredList]="paginator.items"
      [showHeader]="false"
      [scrollable]="false"
      [staticHeight]="true"
      [loading]="paginator.loading"
      [flexible]="true"
      [isInfiniteScroll]="true"
      [noRow]="true"
      (onViewChange)="paginator.request(paginator.page)"
      >
      <ng-template #itemContent let-item>
        <div class="contest row">
          <div class="col">
            <app-ss-user [user]="item"></app-ss-user>
          </div>
          <div class="col-auto">
            <label class="check-circle">
              <span class="round round-xs" [ngClass]="{'active': item.selected}"
                  (click)="selectRecording(item)"></span>
            </label>
          </div>
        </div>
      </ng-template>
    </app-featured-list>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary btn-lg btn-block" [disabled]=""
            (click)="updateCoHost()">Add To Co-Host</button>
  </div>
</ng-template>
<app-splash-modal #splashModal></app-splash-modal>
