import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { ColorPickerModule } from 'ngx-color-picker';

import { SharedModule } from '../shared';
import { SharedIconsModule } from '@app/shared/icons/icons.module';
import { SettingsProfileComponent } from './settings-profile/settings-profile.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';
import { SettingsLyricComponent } from './settings-lyric/settings-lyric.component';
import { SettingsGeneralComponent } from './settings-general/settings-general.component';
import { SettingsSubscriptionHistoryComponent } from './settings-subscription-history/settings-subscription-history.component';
import { SettingsUnblockComponent } from './settings-unblock/settings-unblock.component';

@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        SettingsRoutingModule,
        FormsModule,
        ColorPickerModule,
        ReactiveFormsModule,
        FileUploadModule,
        SharedModule,
        SharedIconsModule
    ],
    declarations: [
        SettingsComponent,
        SettingsProfileComponent,
        SettingsLyricComponent,
        SettingsGeneralComponent,
        SettingsSubscriptionHistoryComponent,
        SettingsUnblockComponent
    ]
})
export class SettingsModule {
}
