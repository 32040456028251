<app-icon-time *ngIf="showIcon" size="14" color="#90919d"></app-icon-time>
<span *ngIf="!needWatch" class="not-watching">{{ timeago }}</span>
<span *ngIf="needWatch && !format" class="watching">{{
  time | dateFormat | amTimeAgo
}}</span>
<span *ngIf="needWatch && format" class="watching">{{
  time | dateFormat | date: format
}}</span>
<span *ngIf="!needWatch && format" class="watching">{{
  time | dateFormat | date: format
}}</span>
 