<div class="app-icon">
    <svg [attr.width]="size" [style.width]="size" viewBox="0 0 20 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <polygon id="path-favorite" points="18.18 21 16.545 13.971 22 9.244 14.809 8.627 12 2 9.191 8.627 2 9.244 7.455 13.971 5.82 21 12 17.272"></polygon>
        </defs>
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="ic_stars" transform="translate(-2.000000, -2.000000)">
                <g id="Icon-24px">
                    <g>
                        <polygon id="Shape-favorite" points="0 0 24 0 24 24 0 24"></polygon>
                        <mask id="mask-favorite" fill="white">
                            <use xlink:href="#path-favorite"></use>
                        </mask>
                        <g id="Black" mask="url(#mask-favorite)" [attr.fill]="color">
                            <g transform="translate(-188.000000, -188.000000)" id="Shape-favorite">
                                <polygon points="0 0 400 0 400 400 0 400"></polygon>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</div>