<div class="ss-song">
  <div class="ss-song--profile">
    <img [src]="song.photo + '?h=100'">
    <app-explicit-tag *ngIf="song?.explicit"></app-explicit-tag>
  </div>
  <div class="ss-song--info">
    <div class="info-title">
      <a (click)="navigate(['/recorder/' + song?.id])" [routerLink]="['/recorder/' + song?.id]" class="ss-truncate" title="{{song.title}}">{{song.title}}</a>
      <app-tags color="#084B8A"  tag="{{song.label}}" style="padding-left: 3px;"></app-tags>
      <app-tags tag="duet" *ngIf="song.duet"></app-tags>
    </div>
    <a class="info-artist ss-truncate" *ngIf="!noArtist" >{{song.artist.name}}</a>
    <div class="info-extra">
      <span *ngIf="song.year">{{song.year}}, </span>  <span>{{song.genre}}</span>
    </div>
  </div>
</div>

      <!-- REMOVE FREE tag -->
      <!-- <app-tags tag="free" *ngIf="!song.gold"></app-tags> -->

<ng-template  #skeleton>
  <div class="skeleton-ss-song">
    <div class="skeleton-ss-song-profile"></div>
    
    <div class="skeleton-ss-song-info">
      <div class="skeleton-info-title"></div>
      <div class="skeleton-info-artist"></div>
      <div class="skeleton-info-extra"></div>
    </div>
  </div>
</ng-template>