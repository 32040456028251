import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss']
})
export class TermsModalComponent implements OnInit {
  @Input() show: boolean = false;
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onCancel() {
    this.show = false;
    this.onClose.emit(false);
  }

  onAgree() {
    this.show = false;
    this.onClose.emit(true);
  }
}
