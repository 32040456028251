<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M3,17.25 L3,21 L6.75,21 L17.81,9.94 L14.06,6.19 L3,17.25 L3,17.25 Z M20.71,7.04 C21.1,6.65 21.1,6.02 20.71,5.63 L18.37,3.29 C17.98,2.9 17.35,2.9 16.96,3.29 L15.13,5.12 L18.88,8.87 L20.71,7.04 L20.71,7.04 Z"
        id="path-pencil"></path>
    </defs>
    <g id="ic_create" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g>
        <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
        <mask id="mask-2" fill="white">
          <use xlink:href="#path-pencil"></use>
        </mask>
        <use id="Mask" [attr.fill]="color" xlink:href="#path-pencil"></use>
      </g>
    </g>
  </svg>
</div>