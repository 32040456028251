import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-heart-full',
  templateUrl: './icon-heart-full.component.html',
  styleUrls: ['./icon-heart-full.component.scss']
})
export class IconHeartFullComponent implements OnInit {
  @Input() color = '#262628';
  @Input() size = 18;

  constructor() { }

  ngOnInit() {
  }

}
