<div 
  class="ss-user-name"
  *ngIf="user" 
>
<a *ngIf="!user.colors" (click)='onScreenName(user.id)' [routerLink]="['/d/profile/', user.id,'info']" 
  [class.no-link]="noLink" [class.disabled]="disabled" class="info-name" [ngClass]="{'gold-user': user.gold}" [ngStyle]="getStyle()">
    {{user.screen_name}}
  </a>
  <a *ngIf="user.colors" class="info-name colored-name" (click)='onScreenName(user.id)' [routerLink]="['/d/profile/', user.id,'info']"   [class.no-link]="noLink" [class.disabled]="disabled">
    <span *ngFor="let letter of letters" [style.color]="letter.color">{{letter.value}}</span>
  </a>
  <app-tags tag="g" *ngIf="!user.username_tag && user.gold"></app-tags>
  <app-tags [tag]="user.username_tag.label" [color]="user.username_tag.color" *ngIf="user.username_tag"></app-tags>
  <ng-container  *ngIf="showTrinkets">
  <span  *ngFor="let icon of getTrinkets()">
   <div *ngIf="getImageNameFromURL(icon.img) != 'founders_trinket.png' ">
     <span class="user-icons">
      <img [src]="icon.img" />
      <span  title={{icon.img}} *ngIf="profile && icon.value > 1">{{icon.value}}</span>
    </span>
    </div>
  </span>
  <div  class="user-icon-founder" *ngFor="let icon of getTrinkets()" > 
    <div  *ngIf="getImageNameFromURL(icon.img) === 'founders_trinket.png' && getTrinkets().length < 4 ">
      <img  [src]="icon.img" />
    </div>
  </div>
  <!-- <span class="info-point" [ngClass]="{'user-offline': !user.online}" *ngIf="showStatus"><span *ngIf="showLevel">{{user.level}}</span></span> -->
</ng-container>
</div>
