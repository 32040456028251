<div class="app-icon">
  <svg class='icon-heart' [attr.width]="size" [style.width]="size" version="1.1" viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M16.4396068,2.56039322 C18.520166,4.64095239 18.520166,8.01527877 16.4396593,10.0945543 L9.00055252,17.5347661 L8.29339322,16.8276068 L1.56060251,10.094816 C-0.520165956,8.01527877 -0.520165956,4.64095239 1.56060239,2.56018411 C3.61105535,0.510943714 6.91482814,0.480333078 9.00001867,2.46800056 C11.0860721,0.480306012 14.3903838,0.511170226 16.4396068,2.56039322 Z"
        id="path-heart-filled"></path>
    </defs>
    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
      <mask fill="white" id="mask-heart-filled">
        <use xlink:href="#path-heart-filled"></use>
      </mask>
      <use xlink:href="#path-heart-filled" [attr.fill]="color" fill-rule="nonzero"></use>
    </g>
  </svg>
</div>