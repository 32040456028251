<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header align-items-center">
    <h5 class="modal-title">
      <app-icon-warning color="red"></app-icon-warning>
      <span>{{title}}</span>
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="c('cancel')">
      <span aria-hidden="true" [inlineSVG]="'/assets/svgs/close.svg'"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body-content">
      <app-ss-user [user]="user" [showStatus]="false" size="xs"></app-ss-user>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="d('cancel')"
            class="btn ss-btn-primary-inverse btn-custom-step btn-custom-step-gray">{{cancelText}}</button>
    <button type="button" class="btn btn-primary btn-custom-step"
            (click)="block(thankReportContent)">{{acceptText}}</button>
  </div>
</ng-template>

<ng-template #thankReportContent let-modal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="icon-inner circle-ripple circle-green">
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <div class="icon-holder">
        <app-icon-check color="white" size="36"></app-icon-check>
      </div>
    </div>
    <p class="block-message">You have blocked {{user?.screen_name}}. To adjust, go to Settings -> Blocked Members.</p>
    <button class="btn btn-primary btn-custom" (click)="c('ok')">OK</button>
  </div>
</ng-template>
