<ng-template #content let-modal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="icon-inner circle-ripple">
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <span class="ripple-effect"></span>
      <div class="icon-holder">
        <app-icon-close color="white" size="36"></app-icon-close>
      </div>
    </div>
    <p class="block-message">{{description}}</p>
    <button class="btn btn-primary btn-custom" (click)="c('ok')">OK</button>
  </div>
</ng-template>
