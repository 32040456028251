import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService, ProfileService } from '@app/core/services';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from "@angular/common";

@Component({
  selector: 'app-search-member-modal',
  templateUrl: './search-member-modal.component.html',
  styleUrls: ['./search-member-modal.component.scss']
})
export class SearchMemberModalComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  @ViewChild('confirmation', { static: true }) private confirmationModal;
  @Input() noRecurring = false;
  @Input() noColors = false;
  @Input() selectedUsers = [];
  @Input() single = false;
  @Input() planType = '';
  @Input() title = 'SELECT RECIPIENT';
  @Input() titleButton = 'SELECT RECIPIENTS';
  @Output() selectionChanged = new EventEmitter();
  modalRef;
  loading = false;
  query = '';
  members = [];
  selected = [];
  me;
  config = {};
  planType21 = 'youth';
  planType65 = 'senior';
  alertDobMessage = "";
  alertMessaage21 = " does not qualify for the youth membership.";
  alertMessaage65 = " does not qualify for the senior membership.";
  constructor(
    private usersService: UsersService,
    private profileService: ProfileService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    ) {
    this.me = this.profileService.user;
  }

  get selectedIds() {
    return this.selected.map(u => u.id);
  }

  ngOnInit() {
  }

  isInvalidUser(user) {
    return (this.noRecurring && user.has_recurring_subscription) ||
      (this.noColors && user.colors);
  }

  open() {
    this.selected = [...this.selectedUsers];
    this.openModal();
  }

  openModal() {
    this.modalRef = this.modalService.open(this.contentElem, {
      windowClass: 'ssc-modal',
      backdrop: 'static',
    });

    this.modalRef.result.then((result) => {
      this.clearData();
    }, (reason) => {
      this.selectionChanged.emit(this.selected);
      this.clearData();
    });
  }

  async onSearch(query) {
    this.query = query;
    this.members = [];
    try {
      this.loading = true;
      const result = await this.usersService.getUsers(query).toPromise();
      this.members = result.data;
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  clearData() {
    this.query = '';
    this.members = [];
    this.selected = [];
    this.loading = false;
  }

  close() {
    this.modalRef.close();
  }

  selectUser(user) {
    if (this.selectedIds.includes(user.id)) {
      this.selected = this.selected.filter(u => u.id !== user.id);
      return;
    }

    
    if (this.noRecurring && user.has_recurring_subscription) {
      if (this.me.id !== user.id) {
        this.toastr.error(`${user.screen_name} already has a recurring subscription`);
      } else if  (this.planType && this.planType === this.planType21 && !this.dobChecked(user.dob, this.planType21)) {
        this.confirmationModal.open('Alert!', this.alertDobMessage + user.screen_name + this.alertMessaage21).then(result => {
          if (result === 'ok') {return;}  });
      } else if  (this.planType && this.planType === this.planType65 && !this.dobChecked(user.dob, this.planType65)) {
        this.confirmationModal.open('Alert!', this.alertDobMessage + user.screen_name + this.alertMessaage65).then(result => {
          if (result === 'ok') {return;}  });
      } else {
      this.addUser(user);
      }
    } else if (this.noColors && user.colors) {
      if (this.me.id !== user.id) {
        this.toastr.error(`${user.screen_name} already has a colored username`);
      } else if  (this.planType && this.planType === this.planType21 && !this.dobChecked(user.dob, this.planType21)) {
        this.confirmationModal.open('Alert!', this.alertDobMessage + user.screen_name + this.alertMessaage21).then(result => {
          if (result === 'ok') {return;}  });
      } else if  (this.planType && this.planType === this.planType65 && !this.dobChecked(user.dob, this.planType65)) {
        this.confirmationModal.open('Alert!', this.alertDobMessage + user.screen_name + this.alertMessaage65).then(result => {
          if (result === 'ok') {return;}  });
      } else {
      this.addUser(user);
      }
    } else if  (this.planType && this.planType === this.planType21 && !this.dobChecked(user.dob, this.planType21)) {
      this.confirmationModal.open('Alert!', this.alertDobMessage + user.screen_name + this.alertMessaage21).then(result => {
        if (result === 'ok') {return;}  });
    } else if  (this.planType && this.planType === this.planType65 && !this.dobChecked(user.dob, this.planType65)) {
      this.confirmationModal.open('Alert!', this.alertDobMessage + user.screen_name + this.alertMessaage65).then(result => {
        if (result === 'ok') {return;}  });
    } else {
      this.addUser(user);
    }
  }

  addUser(user) {
    if (this.single) {
      this.selected = [user];
    } else {
      this.selected.push(user);
    }
  }

  dobChecked(d, plan){
    if (d == undefined){
      this.alertDobMessage = "Date of birth not found!\n";
        return false;
    } else {
      this.alertDobMessage = "";
    }

    const date1 = new Date();
    const age21 = new Date(date1.getFullYear() - 21, date1.getMonth(), date1.getDate(), 0, 0, 0);

    const date2 = new Date();
    const age65 = new Date(date2.getFullYear() - 65, date2.getMonth(), date2.getDate(), 0, 0, 0);

    let under21 = formatDate(age21,'yyyy-MM-dd','en_US');
    let over65 = formatDate(age65,'yyyy-MM-dd','en_US');

    if(plan === this.planType65){
      if(over65 >= d){
        return true;
       } else {
         return false;
       }
    }
    
    if(plan === this.planType21){
      if(under21 < d){
        return true;
       } else {
         return false;
       }
    }
  }
}
