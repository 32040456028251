<div class="social-signups" *ngIf="hideForm">
  <div class="social-signup social-facebook" (click)="handleFBSignup()">
    <i class="fa fa-facebook"></i>
    <span>Login with Facebook</span>
  </div>
  <div class="social-signup social-gplus" (click)="handleGoogleSignup()">
    <i class="fa fa-google-plus"></i>
    <span>Login with Google Plus</span>
  </div>
  <!-- <button (click)="signout()">Signout</button> -->
</div>
<form [formGroup]="formGroup" *ngIf="!hideForm">
  <div class="form-group">
    <label for="email">EMAIL</label>
    <input name="email" type="email" class="form-control" formControlName="email" required readonly>
  </div>
  <div class="form-group">
    <label for="name">NAME</label>
    <input name="username" type="text" class="form-control" formControlName="name" required>
    <span class="input-error" *ngIf="!formGroup.controls['name'].pristine && !formGroup.controls['name'].valid">
      User name is required.
    </span>
  </div>
  <div class="form-group">
    <label for="name">SCREEN NAME</label>
    <input name="screen_name" type="text" class="form-control" formControlName="screen_name" required>
    <span class="input-error" *ngIf="!formGroup.controls['screen_name'].pristine && !formGroup.controls['screen_name'].valid">
      Screen name is required.
    </span>
  </div>
  <div class="form-group">
    <label for="password">PASSWORD (Optional)</label>
    <input name="password" type="password" class="form-control" formControlName="password">
    <span class="input-error"
      *ngIf="formGroup.controls['password'].value && !formGroup.controls['password'].valid">Password should be at
      least 6 characters.</span>
  </div>
  <button type="submit" class="btn btn-primary" (click)="onSubmit()">SIGNUP</button>
</form>