import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilService } from '@app/core';

@Component({
  selector: 'app-ss-report-modal',
  templateUrl: './ss-report-modal.component.html',
  styleUrls: ['./ss-report-modal.component.scss']
})
export class SsReportModalComponent implements OnInit {
  @Output() report: EventEmitter<any> = new EventEmitter();
  @Input() reporting = false;
  showType = false;
  reason = 'Harassment';
  reportText = '';
  quillModules: Object = { toolbar: [] }
  show = false;
  user: any = {};
  message: any = {};
  showSuccess = false;
  type = '';
  reasons = ['Offensive', 'Harassment'];

  constructor(
    public utilService: UtilService
  ) { }

  ngOnInit() {
  }

  onTypeToggle(value) {
    this.showType = value;
  }

  onSelectReason(value) {
    this.reason = value;
    this.showType = false;
  }

  popup(type) {
    this.showSuccess = false;
    this.reportText = '';
    this.show = true;
    this.type = type;
  }

  onReportSuccess() {
    this.showSuccess = true;
  }

  onCancel() {
    this.show = false;
  }

  onSend() {
    if (this.report) {
      this.report.emit({
        reason: this.reason.toLowerCase(),
        message: this.reportText
      });
    }
  }

  onClose() {
    this.show = false;
  }

}
