<div class="ss-featured-list--container">
    <div class="ssc-list-title">
        <h2>{{title}}
            <span *ngIf="!data.loading && data.total !== undefined">({{data.total}})</span>
            <span *ngIf="data.loading">(<i class="fa fa-pulse fa-spinner"></i>)</span>
        </h2>
        <div ngbDropdown class="ssc-title-options" *ngIf="sortKeys.length">
            <span class="d-flex align-items-center" ngbDropdownToggle>
                <app-icon-time *ngIf="orderBy === 'updated_at' || orderBy === 'created_at'" color="white"></app-icon-time>
                <app-icon-flame *ngIf="orderBy === 'popularity' || orderBy === 'songs_count'" color="white"></app-icon-flame>
                <app-icon-long-arrow-down *ngIf="orderBy === 'screen_name' || orderBy === 'name' || orderBy === 'title'" color="white" [style.transform]="data.orderByList[orderBy].asc === true ? 'rotate(0)' : 'rotate(180deg)'"></app-icon-long-arrow-down>
                <app-icon-arrow-down color="#90919D" size="24"></app-icon-arrow-down>
            </span>
            <div ngbDropdownMenu class="dropdown-orderby">
                <div 
                    *ngFor="let order of sortKeys"
                    class="dropdown-item"
                    [ngClass]="{'selected': orderBy === order}" 
                    (click)="handleOrderBy(order)"
                >
                    <span>{{data.orderByList[order].text[data.orderByList[order].asc + '']}}</span>
                    <!-- <app-icon-long-arrow-down
                        [style.transform]="data.orderByList[order].asc === true ? 'rotate(0)' : 'rotate(180deg)'">
                    </app-icon-long-arrow-down> -->
                </div>
            </div>
        </div>
    </div>
    <div 
        class="ss-featured-list ps"
        [perfectScrollbar]="config"
        #ps
        (psYReachEnd)="onLoadMore($event)"
    >
        <div *ngFor="let item of data.items; let e = index" class="ss-featured-list--item" [ngClass]="{'last-one-temp': e === data.items.length - 1 && data.items.length !== 1}">
            <ng-container *ngTemplateOutlet="itemTmpl, context: { $implicit: item, indx: e}"></ng-container>
        </div>
        <div *ngIf="data.items && data.items.length === 0" class="ss-featured-list--no-results">
            <span>No results found.</span>
        </div>
    </div>
</div>
<ng-content select=".featured-list--more"></ng-content>
