<div class="ss-form-switch">
  <span class="ss-form-switch-label" [ngClass]="{'ss-disabled': !value || disabled}" *ngIf="label">
    {{label}}
    <app-tags tag="g" *ngIf="showGold"></app-tags>
  </span>
  <label class="ss-form-switch-input ss-custom-switch">
    <input type="checkbox" [(ngModel)]="value" [disabled]="disabled" (change)="handleChange()">
    <span class="ss-slider"></span>
  </label>
</div>
