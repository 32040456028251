<div class="app-icon">
  <svg [attr.width]="size" [attr.height.px]="size" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g  transform="translate(-696.000000, -381.000000)" 
            [attr.fill]="color"
            fill-rule="nonzero">
            <path d="M706,386 L705.285714,386 L705.285714,384.571429 C705.285714,382.6 703.685714,381 701.714286,381 C699.742857,381 698.142857,382.6 698.142857,384.571429 L698.142857,386 L697.428571,386 C696.642857,386 696,386.642857 696,387.428571 L696,394.571429 C696,395.357143 696.642857,396 697.428571,396 L706,396 C706.785714,396 707.428571,395.357143 707.428571,394.571429 L707.428571,387.428571 C707.428571,386.642857 706.785714,386 706,386 Z M699.571429,384.571429 C699.571429,383.385714 700.528571,382.428571 701.714286,382.428571 C702.9,382.428571 703.857143,383.385714 703.857143,384.571429 L703.857143,386 L699.571429,386 L699.571429,384.571429 Z M706,394.571429 L697.428571,394.571429 L697.428571,387.428571 L706,387.428571 L706,394.571429 Z M701.714286,392.428571 C702.5,392.428571 703.142857,391.785714 703.142857,391 C703.142857,390.214286 702.5,389.571429 701.714286,389.571429 C700.928571,389.571429 700.285714,390.214286 700.285714,391 C700.285714,391.785714 700.928571,392.428571 701.714286,392.428571 Z"></path>
        </g>
    </g>
  </svg>
</div>