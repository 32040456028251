<div class="ss-r-wrapper ss-remix" id="spaceContainer">
  <div class="ss-r-wrapper-inner">
    <div class="ss-r-header">
      <div class="ss-r-header-left">
        <!-- <app-icon-button color="rgba(255, 255, 255, 0.3)" (click)="backClicked()" *ngIf="!finished">
          <app-icon-arrow-left color="white"></app-icon-arrow-left>
        </app-icon-button> -->
        <div class="mmsb-switch ss-switch-holder">
          <span class="ss-label">MMSB</span>
          <label class="ss-custom-switch">
            <input type="checkbox" class="no-disable-color" [(ngModel)]="mmsb" (change)="onChangeMMSB($event)" />
            <span class="ss-slider"></span>
          </label>
        </div>
      </div>
      <div class="ss-r-title-content">
        <h2 class="ss-r-title" *ngIf="showControls">{{title}}</h2>
        <h6 class="ss-r-subtitle" *ngIf="!showControls">{{subtitle}}</h6>
      </div>
      <div class="ss-r-header-right">
        <button class="btn btn-lg btn-primary topButtons" style='background-color: white;' (click)="finishRemix()">SAVE</button>
        <div class="parts__selector" style='display: none; z-index: 50;'></div>

      </div>
    </div>
    <div class="ss-r-lyric-wrapper">
      <canvas  #lyric class="ss-r-lyric-canvas" width="1000" height="170"></canvas>
    </div>
    <div class="ss-r-content">
      <div class="ss-r-right-panel">
        <div class="ss-r-thumb-holder">
          <span class="ss-r-version">V {{remixerVersion}}</span>
          <div class="ss-r-thumb"></div>
          <audio #audioMusic class="audio-music"></audio>
          <audio #audioVoice class="audio-voice"></audio>
          <div class="ss-r-holder" #videoHolder>
            <div class="ss-r-item">
              <video [muted]="true" class="video ss-r-video ss-r-video-reviewer" #videoReviewer></video>
            </div>
            <div class="ss-r-item">
              <video [muted]="true" class="video ss-r-video ss-r-video-duet" #videoDuet></video>
            </div>
          </div>
          <div class="ss-r-play-info">
            <!-- RECORD SCREEN CONTROL -->
            <div class="ss-r-btn--record" *ngIf="loaded">
              <app-icon-button *ngIf="buttons.pause" color="white" size="40px" (click)="pause()">
                <app-icon-pause></app-icon-pause>
              </app-icon-button>
              <app-icon-button *ngIf="buttons.resume" color="white" size="40px" (click)="resume()">
                <app-icon-play-line style="margin-left: 3px;"></app-icon-play-line>
              </app-icon-button>
            </div>
            <!-- RECORDER TIMELINE -->
            <div class="ss-r-play-time">
              <span class="ss-r-time-active">{{getCurrentTime()}}</span>
              <span class="ss-r-time-total"> / {{getDuration()}}</span>
            </div>
          </div>
          <h2 class="recording-loading" *ngIf="!loaded && errorMessage.length == 0">Loading... <i class="fa fa-spinner fa-pulse fa-fw" style="font-size: 47px; text-shadow: none;" *ngIf="!loaded"></i></h2>
          <div class="errorContainer" *ngIf='errorMessage.length != 0'><h2 *ngFor='let i of errorMessage'>{{i}}</h2></div>

        </div>
        <app-progress-bar [duration]="duration"
          [current]="currentTime" (seek)="onSeek($event)"></app-progress-bar>
      </div>
    </div>
    <div class="ss-r-adjust-bar" [hidden]="!showVolumeBar">
      <div class="ss-bar">
        <div class="ss-bar-item">
          <app-icon-mic size="18" color="white" [style.margin]="'0 3px'"></app-icon-mic>
          <app-adjust-bar
            [value]="micVolume"
            [min]="0" [max]="1" [step]="0.01" [multiply]="10" [noFraction]="true"
            (changed)="onMicVolumeChange($event)"
          >
          </app-adjust-bar>
        </div>
        <div *ngIf='!duet' class="ss-bar-item">
          <app-icon-music size="24" color="white"></app-icon-music>
          <app-adjust-bar
            [value]="volume"
            [min]="0" [max]="1" [step]="0.01" [multiply]="10" [noFraction]="true"
            (changed)="onVolumeChange($event)"
          >
          </app-adjust-bar>
        </div>
      </div>
      <app-icon-button color="#F94678" size="60px" [style.marginLeft]="'30px'" (click)="onVolumeCancel()">
        <app-icon-close size="27" color="white"></app-icon-close>
      </app-icon-button>
      <app-icon-button color="#4FEAAE" size="60px" [style.marginLeft]="'30px'" (click)="onVolumeAccept()">
        <app-icon-check size="27" color="white"></app-icon-check>
      </app-icon-button>
    </div>
    <!-- SYNC BAR -->
    <div class="ss-r-adjust-bar" [hidden]="!showSyncBar">
      <div class="ss-bar">
        <div class="ss-bar-item">
          <app-recorder-sync-bar #syncBar style="width: 100%;" [value]="sync" (changed)="onSyncChange($event)">
          </app-recorder-sync-bar>
        </div>
      </div>
      <app-icon-button color="#F94678" size="60px" [style.marginLeft]="'30px'" (click)="onSyncCancel()">
        <app-icon-close size="27" color="white"></app-icon-close>
      </app-icon-button>
      <app-icon-button color="#4FEAAE" size="60px" [style.marginLeft]="'30px'" (click)="onSyncAccept()">
        <app-icon-check size="27" color="white"></app-icon-check>
      </app-icon-button>
    </div>
    <!-- MAIN CONTROL -->
    <div class="ss-r-control" *ngIf="showControls">
      <div class="ss-r-control--main">
        <div class="main-controls">
          <div class="ss-r-btn" *ngIf="buttons.volume">
            <app-icon-button color="white" size="60px" (click)="onVolumeClick()">
              <app-icon-volumn size="27"></app-icon-volumn>
            </app-icon-button>
            <h6 class="ss-r-btn-text">volume</h6>
          </div>
          <div class="ss-r-btn" *ngIf="buttons.sync">
            <app-icon-button color="white" size="60px" (click)="onSyncClick()">
              <app-icon-sync size="27"></app-icon-sync>
            </app-icon-button>
            <h6 class="ss-r-btn-text">sync</h6>
          </div>
        </div>
      </div>
    </div>
    <input type="hidden" placeholder="uuid" name="uuid" />
  </div>
</div>
