<div class="user-badge-level" [class.user-badge-level--gold]="user?.gold">
    <div class="user-name" [routerLink]="['/d/profile/', user?.id ,'info']">
        {{user?.screen_name}}
    </div>
    <div class="comment__badges" *ngIf="user?.gold && showBadge">
        <app-badge name="G" color="rgb(182, 151, 75)"></app-badge>
    </div>
    <div class="user-level">
        <span *ngIf="showOnline" [ngClass]="{'user-offline': !user?.online}">&#x25cf;</span> <span *ngIf="showLevel">{{user?.level || 0}}</span>
    </div>
</div>
