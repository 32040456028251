<div class="report-modal main-window" *ngIf="show">
  <div class="report-wrapper">
    <h1 class="report-modal-title">Report {{type}}</h1>
    <div class="report-msg">
      <ng-content selector=".report-content"></ng-content>
    </div>
    <div class="ss-custom-dropdown" (clickOutside)="onTypeToggle(false)">
      <div class="type-header" (click)="onTypeToggle(true)">
        <div>
          <label>Reporting Type</label>
          <span>{{reason}}</span>
        </div>
        <app-icon-arrow-down size="24"></app-icon-arrow-down>
      </div>
      <div class="dropdown-menu" *ngIf="showType">
        <div class="dropdown-item" (click)="onSelectReason(reason)" *ngFor="let reason of reasons">
          <p>{{reason}}</p>
        </div>
      </div>
    </div>
    <div class="report-text">
      <div class="write-content white-box reply-quill-box">
        <div class="user-image" [style.backgroundImage]="'url(https://dev-singsnap.imgix.net/placeholders/placeholder_logo.png?h=100)'"></div>
        <div class="custom-quill quill-sm">
          <quill-editor placeholder="Additional Information" [modules]="quillModules" [(ngModel)]="reportText">
          </quill-editor>
        </div>
      </div>
    </div>
    <div class="btn-ctas">
      <button class="btn btn-cancel" (click)="onCancel()">CANCEL</button>
      <button class="btn btn-primary" (click)="onSend()" [disabled]="reporting">
        SEND
      </button>
    </div>
  </div>

  <div class="report-success" *ngIf="showSuccess">
    <div class="success-content">
      <div class="icon-success">
        <div class="icon-inner">
          <app-icon-check color="white" size="30"></app-icon-check>
        </div>
      </div>
      <h1 class="report-modal-title">Report Received!</h1>
      <p>Your report has been received. Thank you for helping us ensure the best quality for our <strong>SingSnap</strong> family!</p>
      <button class="btn btn-custom ss-btn-primary" (click)="onClose()">OK</button>
    </div>
  </div>
</div>
