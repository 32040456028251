import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Location } from '@angular/common';
import { Data, UtilService, RecordingService } from '@app/core';
//import { initiateRemix } from "../../../../recorder-lib/start-remix.js"
import Bugsnag from '@bugsnag/js';
declare function initiateRemix(
  videoReviewer,
  videoDuet,
  lyricDom, // Lyric DOM
  audioMusic,
  audioVoice,
  songData, // Song Struct Data
  colors, // Lyric colors
  width, // Video width
  height, // Video height
  isCS,
  workerPath
)
let workerPath = '/assets/scripts/soundtouch-worklet.js';

declare var Done;



@Component({
  selector: 'app-remix-recording',
  templateUrl: './remix-recording.component.html',
  styleUrls: ['./remix-recording.component.scss']
})
export class RemixRecordingComponent implements OnInit, OnDestroy {
  id;
  errorMessage = [];
  @Input() backgroundColor = '#21243C';
  @ViewChild('syncBar', { static: true }) private syncBarElem;
  @ViewChild('videoReviewer', { static: true }) private videoReviewer: ElementRef;
  @ViewChild('videoDuet', { static: true }) private videoDuet: ElementRef;
  @ViewChild('audioMusic', { static: true }) private audioMusic: ElementRef;
  @ViewChild('audioVoice', { static: true }) private audioVoice: ElementRef;
  @ViewChild('videoHolder', { static: true }) private videoHolder: ElementRef;
  @ViewChild('lyric', { static: true }) private lyricDom: ElementRef;
  uploaded = false;
  remixerVersion = 4.0;
  duration: any = 0;
  currentTime: any = 0;
  showControls = true;
  showVolumeBar = false;
  showSyncBar = false;
  UUID: any = null;
  pitch: any = 0;
  volume: any = 1;
  micVolume: any = 1;
  sync = 0;
  mmsb = false;
  subtitle = '';
  temp: any = {};
  isSaving = false;
  title = '';
  loaded = false;
  scriptTag;
  webcam;
  duet = false;


  // REVIEWER
  remixer: any = null;

  finished = false;     // Review Mode

  buttons: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private recordingService: RecordingService,
    private location: Location,
    private toastr: ToastrService,
    private router: Router,
    private data: Data,
  ) {

  }
  loadScripts() {
    this.scriptTag = document.createElement('script');
    //this.scriptTag.addEventListener('onload', this.start());
    this.scriptTag.src = '../../../assets/scripts/start-remix.js' + '?v=' + Date.now();
    this.scriptTag.id = 'start-remix';
    this.scriptTag.type = 'text/javascript';
    this.scriptTag.async = false;
    this.scriptTag.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(this.scriptTag);

    return new Promise((res, rej) => {
      this.scriptTag.onload = function() {
        res(true);
      }
      this.scriptTag.onerror = function () {
        rej();
      }
    });
  // setTimeout(() => this.start(), 1000)
  }

  ngOnInit() {
    this.loadScripts().then(() => {
      let scriptCheck = setInterval(()=>{
        if(Done){
          console.log('scripts are loaded!')
          this.start();
          clearInterval(scriptCheck);
        }
      }, 500)

    })
    .catch(() => {
      this.errorMessage.push('Error: Failed to Load Scripts!')
      let msg = 'Script loading failed!';
      console.error(msg);
      Bugsnag.notify('remix-recording error: ' + msg);
    });
  }
  start() {
      this.id = this.activatedRoute.snapshot.paramMap.get('id');
      this.loadRemixData();
      this.resetButtons();
  }
  ngOnDestroy() {
    this.remixer = {};
    console.log(this.remixer)
    this.scriptTag.remove();
    if(!this.uploaded) {
      window.location.reload();
    }

  }

  resetButtons() {
    this.buttons = {
      volume: false,
      pause: false,             // Pause All
      resume: false,            // Resume Practicing
      sync: false,
    };
  }

  async loadRemixData() {

    try {
      const requests = [];
      requests.push(this.recordingService.getRemixData(this.id).toPromise());
      let results = await Promise.all(requests);
      const remix = results[0];
      console.log(remix)
      if(remix.users.length > 1) {
        this.duet = true;
      }

      this.mmsb = remix.mmsb;
      this.webcam = remix.webcam
      remix.meta.mic_vol = remix.meta.mic_vol;
      remix.meta.base_vol = remix.meta.base_vol;
      this.micVolume = remix.meta.mic_vol
      this.volume = remix.meta.base_vol
      console.log('sync is: ' + results[0].meta.latency )
      results[0].meta.latency = results[0].meta.latency;
      console.log('sync is now: ' + results[0].meta.latency )
      console.log(results[0].meta)

      this.sync = parseInt(results[0].meta.latency);
      // TODO: apply offset here
      this.syncBarElem.changeValue(this.sync);
      this.title = remix.song.title;

      const height = this.videoHolder.nativeElement.clientHeight;
      const width = this.videoHolder.nativeElement.clientWidth;

      let colors = Cookie.get('lyric-colors');
      if (colors) {
        colors = JSON.parse(colors);
      } else {
        colors = undefined;
      }

      this.remixer = initiateRemix(
        this.videoReviewer.nativeElement,
        this.videoDuet.nativeElement,
        this.lyricDom.nativeElement,
        this.audioMusic.nativeElement,
        this.audioVoice.nativeElement,
        results,
        colors,
        width,
        height,
        remix.song.word_timings,
        workerPath,
      );
      console.log(this.remixer.base)
      this.bindEvents();
    } catch (err) {
      this.errorMessage.push('Error: ' + err)
      console.log(err);
    }
  }

  async finishRemix() {
    const payload = this.remixer.finishRemix();
    payload.webcam = this.webcam;
    console.log(payload);
    this.isSaving = true;
    try {
      await this.recordingService.saveRemix(this.id, payload).toPromise();
      this.data.storage = { recordingId: this.id };
      this.uploaded = true;
      this.router.navigate(['/d/remix/success']);
    } catch (err) {
      console.log(err);
    } finally {
      this.isSaving = false;
    }
  }

  bindEvents() {
    this.remixer.addEventListener('REMIXER_READY', (event) => {
      this.onSyncChange(this.sync);
      this.loaded = true;
      console.log('remixer ready', this.remixer.duration);
      this.duration = this.remixer.duration;
      this.buttons.resume = true;
      this.buttons.volume = true;
      this.buttons.sync = true;
    });


    this.remixer.addEventListener('REMIXER_FILE_LOAD_ERROR', () => {
      console.log(this.remixer.errorMessage)
      if(this.remixer.errorMessage.includes('METADATA_ERR')) {
        this.errorMessage = [];
        this.errorMessage.push('Some recordings made on Chromium based browsers cannot be remixed on Firefox, please use a Chromium based browser to remix this recording (the new Microsoft Edge, Google Chrome, Brave, Vivaldi, Opera, etc)')
      } else {
        this.errorMessage.push(this.remixer.errorMessage)
      }
      //window.location.reload();
    });

    this.videoReviewer.nativeElement.addEventListener('timeupdate', e => {
      this.currentTime = e.target.currentTime;
      if (this.currentTime > this.duration) {
        this.currentTime = this.duration;
      }
    });

    this.videoReviewer.nativeElement.addEventListener('ended', e => {
      this.buttons.resume = true;
      this.buttons.pause = false;
    });
  }

  onChangeMMSB(event?) {
    console.log(this.mmsb);
    this.remixer.voice.toggleMMSB(this.mmsb);
    this.mmsb = this.remixer.voice.mmsb
  }

  getPoster() {
    return 'https://dev-singsnap.imgix.net/placeholders/placeholder_logo.png';
  }

  pause() {
    this.remixer.pause();
    this.paused();
  }

  paused() {
    this.buttons.pause = false;
    this.buttons.resume = true;
  }

  resume() {
    this.remixer.play();
    this.buttons.pause = true;
    this.buttons.resume = false;
  }

  getDuration() {
    return this.utilService.formatDuration(this.duration);
  }

  getCurrentTime() {
    return this.utilService.formatDuration(this.currentTime);
  }

  onSeek(value) {
    this.remixer.seek(parseInt(value));
    this.currentTime = parseInt(value);
    this.paused();
  }

  onVolumeClick() {
    this.showControls = false;
    this.showVolumeBar = true;
    this.subtitle = 'volume';
    this.temp.volume = this.volume;
    this.temp.micVolume = this.micVolume;
  }

  onVolumeChange(value) {
    value = parseFloat(value)
    this.volume = value
    this.remixer.changeBaseVolume(value);
  }

  onMicVolumeChange(value) {
    value = parseFloat(value)
    this.micVolume = value
    this.remixer.changeMicVolume(value);
  }

  onVolumeAccept() {
    this.showControls = true;
    this.showVolumeBar = false;
  }

  onVolumeCancel() {
    this.showControls = true;
    this.showVolumeBar = false;
    this.volume = this.temp.volume;
    this.micVolume = this.temp.micVolume;
    if (this.remixer) {
      this.remixer.changeMicVolume(this.micVolume);
      this.remixer.changeBaseVolume(this.volume);
    }
  }

  onSyncClick() {
    this.showControls = false;
    this.showSyncBar = true;
    this.subtitle = 'sync';
    this.temp.sync = this.sync;
    this.paused();
  }

  onSyncAccept() {
    this.showControls = true;
    this.showSyncBar = false;
    this.paused();
  }

  onSyncCancel() {
    this.showControls = true;
    this.showSyncBar = false;
    this.paused();
    if (this.sync !== this.temp.sync) {
      this.sync = this.temp.sync;
      this.syncBarElem.changeValue(this.sync);
      this.remixer.sync(this.sync);
    }
  }

  backClicked() {
    this.location.back();
  }

  onSyncChange(value) {
    this.sync = parseInt(value);
    if (this.remixer) {
      this.remixer.sync(this.sync);
    }
    this.paused();
  }
}
