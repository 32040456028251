import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SplashModalComponent} from '@app/shared/splash-modal/splash-modal.component';
import {ContestService, EventService, RecordingService, UsersService} from '@app/core';
import {ToastrService} from 'ngx-toastr';
import {Contest} from '@app/models/contest.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Round} from '@app/models/round.model';

@Component({
  selector: 'app-contest-create-next-round',
  templateUrl: './contest-create-next-round.component.html',
  styleUrls: ['./contest-create-next-round.component.scss']
})
export class ContestCreateNextRoundComponent implements OnInit {
  group: UntypedFormGroup;
  modalRef: NgbModalRef;
  id;
  contest: Contest;
  round = new Round();
  editorContent = '';
  submitted = false;
  @ViewChild('content', { static: true }) content;
  @ViewChild('splashModal', { static: true }) splashModal: SplashModalComponent;

  constructor(private fb: UntypedFormBuilder,
              private contests: ContestService,
              private users: UsersService,
              private modalService: NgbModal,
              private recordings: RecordingService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.group = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      description: ['', [Validators.required, Validators.maxLength(255)]],
    });
  }

  open(contest: Contest) {
    this.contest = contest;
    this.id = contest.contest_id;
    this.modalRef = this.modalService.open(this.content, {windowClass: 'add-member-modal'});
    this.modalRef.result.then(() => {

    }, () => {

    });
  }

  close() {
    this.modalRef.close('cancel');
  }

  onImageSelected($event) {
    console.log($event);
    this.round.cover = $event;
  }

  onSubmit() {
    this.submitted = true;
    this.round.name = this.group.controls['name'].value;
    this.round.description = this.group.controls['description'].value;
    this.validateFields();
    if (!this.round.name) {
      return this.toastr.error('Round name missing.', 'Error');
    }

    if (this.round.description.length > 255) {
      return this.toastr.error('Round description is too long.', 'Error');
    }

    this.postNextRound();
  }

  validateFields() {
    Object.keys(this.group.controls).forEach(field => { // {1}
      const control = this.group.get(field);            // {2}
      control.markAsTouched({onlySelf: true});       // {3}
    });
  }

  postNextRound() {
    this.contests.addRound(this.contest.contest_id, this.round).subscribe(response => {
      if (response.success) {
        this.toastr.success('Round', 'Next round created.');
        this.contests.updateNextRoundPosted(response);
      } else {
        this.toastr.error('Round', 'There was an error creating a new round.');
      }
      this.close();
    });
  }

  isFieldValid(field: string) {
    return !this.group.get(field).valid && this.group.get(field).touched;
  }
}
