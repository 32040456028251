<div class="app-icon">
  <svg id="email" xmlns="http://www.w3.org/2000/svg" [style.width]="size" [attr.height.px]="size" viewBox="0 0 58 58">
    <circle id="Oval" cx="29" cy="29" r="29" fill="#5dc5a6" />
    <g id="envelope" transform="translate(18 20)">
      <path id="Path"
        d="M2.208,5.793q.436.32,2.631,1.9T8.2,10.132q.128.094.545.407t.693.507q.276.193.668.434a3.806,3.806,0,0,0,.738.36,1.963,1.963,0,0,0,.642.12h.026a1.965,1.965,0,0,0,.642-.12,3.809,3.809,0,0,0,.738-.36q.391-.24.667-.434t.693-.507q.417-.314.546-.407l6.007-4.338a6.228,6.228,0,0,0,1.566-1.642A3.622,3.622,0,0,0,23,2.136,2.087,2.087,0,0,0,22.39.627,1.946,1.946,0,0,0,20.946,0H2.053A1.8,1.8,0,0,0,.533.694,2.767,2.767,0,0,0,0,2.429,3.19,3.19,0,0,0,.706,4.252,6.5,6.5,0,0,0,2.208,5.793Z"
        fill="#fff" />
      <path id="Path-2" data-name="Path"
        d="M21.716,1.175q-4.209,3-6.392,4.658-.732.567-1.187.884a7.2,7.2,0,0,1-1.213.648,3.54,3.54,0,0,1-1.412.331h-.026a3.541,3.541,0,0,1-1.412-.331,7.2,7.2,0,0,1-1.213-.648Q8.407,6.4,7.675,5.832,5.943,4.5,1.3,1.175A6.908,6.908,0,0,1,0,0V10.72a2.135,2.135,0,0,0,.6,1.526,1.93,1.93,0,0,0,1.45.635H20.947a1.93,1.93,0,0,0,1.45-.635A2.135,2.135,0,0,0,23,10.72V0A7.2,7.2,0,0,1,21.716,1.175Z"
        transform="translate(0 5.52)" fill="#fff" />
    </g>
  </svg>

</div>