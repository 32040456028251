import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { UtilService } from '@app/core';
//import { root } from 'rxjs/internal/util/root';

@Component({
  selector: 'app-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.scss']
})
export class InfiniteScrollComponent implements OnInit, AfterViewInit {

  @Input('root') root: string = 'body';
  @Output('onView') onView: EventEmitter<any> = new EventEmitter();
  id;

  constructor(private utilService: UtilService) { }

  ngOnInit() {
    this.id = this.utilService.generateAlphaNumeric(4);
  }

  ngAfterViewInit(): void {
    const options = {
      root: document.querySelector(this.root),
      threshold: 1.0
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          console.log('in view')
          this.onView.emit();
        }
      });
    }, options);

    observer.observe(document.querySelector('#ss-scroll-' + this.id));
  }
}
