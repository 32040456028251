<div class="app-icon">
  <svg [attr.width]="size" [attr.height.px]="size" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
          <path d="M11.13,10.773 C10.948,10.213 10.423,9.8 9.8,9.8 L9.1,9.8 L9.1,7.7 C9.1,7.31340068 8.78659932,7 8.4,7 L4.2,7 L4.2,5.6 L5.6,5.6 C5.98659932,5.6 6.3,5.28659932 6.3,4.9 L6.3,3.5 L7.7,3.5 C8.47319865,3.5 9.1,2.87319865 9.1,2.1 L9.1,1.813 C11.151,2.639 12.6,4.648 12.6,7 C12.6,8.456 12.04,9.779 11.13,10.773 L11.13,10.773 Z M6.3,12.551 C3.535,12.208 1.4,9.856 1.4,7 C1.4,6.566 1.456,6.146 1.547,5.747 L4.9,9.1 L4.9,9.8 C4.9,10.5731986 5.52680135,11.2 6.3,11.2 L6.3,12.551 Z M7,-1.33226763e-16 C3.13400675,-1.687539e-15 -1.33226763e-16,3.13400675 -4.4408921e-16,7 C-1.37667655e-15,8.85651543 0.737497883,10.6369928 2.05025253,11.9497475 C3.36300718,13.2625021 5.14348457,14 7,14 C8.85651543,14 10.6369928,13.2625021 11.9497475,11.9497475 C13.2625021,10.6369928 14,8.85651543 14,7 C14,5.14348457 13.2625021,3.36300718 11.9497475,2.05025253 C10.6369928,0.737497883 8.85651543,-2.88657986e-16 7,-1.33226763e-16 Z" id="path-globe"></path>
      </defs>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <mask id="mask-globe" fill="white">
                <use xlink:href="#path-globe"></use>
            </mask>
            <use [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-globe"></use>
      </g>
  </svg>  
</div>