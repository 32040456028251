<div class="app-icon">
  <svg id="twitter" xmlns="http://www.w3.org/2000/svg" [style.width]="size" [attr.height.px]="size" viewBox="0 0 58 58">
    <circle id="Oval" cx="29" cy="29" r="29" fill="#55acee" />
    <g id="twitter-2" data-name="twitter" transform="translate(17 19)">
      <path id="Path"
        d="M24.762,2.368a10.633,10.633,0,0,1-2.925.8A5.024,5.024,0,0,0,24.07.375,10.182,10.182,0,0,1,20.851,1.6,5.082,5.082,0,0,0,12.07,5.048,5.167,5.167,0,0,0,12.188,6.2,14.4,14.4,0,0,1,1.724.92a5.028,5.028,0,0,0,1.56,6.745A5.036,5.036,0,0,1,.99,7.043V7.1a5.077,5.077,0,0,0,4.067,4.958,5.1,5.1,0,0,1-1.331.166,4.514,4.514,0,0,1-.961-.086,5.121,5.121,0,0,0,4.743,3.515A10.239,10.239,0,0,1,1.215,17.8,9.564,9.564,0,0,1,0,17.735,14.353,14.353,0,0,0,7.788,20,14.3,14.3,0,0,0,22.236,5.64c0-.223-.008-.438-.019-.652A10.094,10.094,0,0,0,24.762,2.368Z"
        fill="#fff" />
    </g>
  </svg>

</div>