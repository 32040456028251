<div class="ss-sync-bar-holder" *ngIf='!newRecorder'>
  <span class="ss-btn-sync sync-small" (click)="adjust(-10)">-</span>
  <span class="ss-btn-sync" (click)="adjust(-20)">-</span>
  <div class="sync-control">
    <div class="seeker-holder">
      <span class="ss-seeker" #seeker [style.left]="getProgress() + '%'">
        Voice {{getValue()}}
      </span>
      <span class="ss-seeker ss-seeker-music" [style.left]="'50%'">
        Music
      </span>
    </div>
    <input type="range" [ngClass]="getClasses()" [step]="step" [min]="min" [max]="max" [(ngModel)]="value"
      (change)="onSeek($event)" (input)="onSeek($event)" />
  </div>
  <span class="ss-btn-sync" (click)="adjust(20)">+</span>
  <span class="ss-btn-sync sync-small" (click)="adjust(10)">+</span>
</div>

<div class="ss-sync-bar-holder" *ngIf='newRecorder'>
  <span class="ss-btn-sync sync-small" (click)="adjust(-10)">-</span>
  <span class="ss-btn-sync" (click)="adjust(-20)">-</span>
  <div class="sync-control">
    <div class="seeker-holder">
      <span class="ss-seeker" #seeker [style.left]="getProgress() + '%'">
        Voice {{getValue()}}
      </span>
      <span class="ss-seeker ss-seeker-music" [style.left]="'50%'">
        Music
      </span>
    </div>
    <input type="range" [ngClass]="getClasses()" [step]="step" [min]="min" [max]="max" [(ngModel)]="value"
      (change)="onSeek($event)" (input)="onChange($event)" />
  </div>
  <span class="ss-btn-sync" (click)="adjust(20)">+</span>
  <span class="ss-btn-sync sync-small" (click)="adjust(10)">+</span>
</div>