import "./Superpowered.js";
import audioBufferToWav from "./_snowpack/pkg/audiobuffer-to-wav.js";
export default class SuperPlayer {
  constructor(workletPath, wasmPath, sampleRate) {
    this.workletPath = workletPath;
    this.wasmPath = wasmPath;
    this.sampleRate = sampleRate;
    this.loaded = false;
    this.Superpowered;
    this.audioNode;
    this.webAudio;
    this.playing = false;
    this.voiceOffset = 0;
    this.musicOffset = 0;
    this.firstPlay = true;
    this.duration = 0;
    this.interval;
    this.playhead = 0;
    this.events = {};
    this.songURL;
    this.syncValue = 0;
    this.vocalVolume = 1;
    this.musicVolume = 1;
  }
  async init() {
    this.Superpowered = await SuperpoweredGlue.Instantiate("dTdHZU1FSFg3UzJ6WmYzMTE3NWEyYzQwMmQ0MzU2MmExNWEzZTFiMjBkZGEwMjRkZmM5OGQ1Y0M4Q2FMODVJTXpXcFpZWkph", "/assets/scripts/Superpowered.js");
    this.dispatchEvent("superPlayerReady");
  }
  onMessageFromAudioScope(message) {
    if (typeof message.init != "undefined") {
    }
    if (typeof message.duration != "undefined") {
      this.duration = message.duration;
      if (this.duration == 0) {
        this.audioNode.sendMessageToAudioScope({getDuration: true});
      } else {
        this.dispatchEvent("durationGet", message.duration);
      }
    }
    if (typeof message.voiceOffset != "undefined") {
      this.voiceOffset = message.voiceOffset;
    }
    if (typeof message.musicOffset != "undefined") {
      this.musicOffset = message.musicOffset;
    }
    if (typeof message.position != "undefined") {
      this.playhead = message.position;
      this.dispatchEvent("playheadUpdate", message.position);
    }
    if (typeof message.loaded != "undefined") {
      setTimeout(() => {
        this.audioNode.sendMessageToAudioScope({getDuration: true});
      }, 1e3);
    }
    if (typeof message.recordReady != "undefined") {
      this.dispatchEvent("recordReady");
    }
    if (typeof message.voiceReady != "undefined") {
      this.dispatchEvent("voiceReady");
    }
  }
  changePitch(value) {
    this.audioNode.sendMessageToAudioScope({pitchShift: value});
  }
  loadVoice(blob) {
    blob.arrayBuffer().then((buf) => {
      this.webAudio.audioContext.decodeAudioData(buf, (audioBuffer) => {
        let wav = audioBufferToWav(audioBuffer);
        let sampleRate = audioBuffer.sampleRate;
        this.duration = audioBuffer.duration * 1e3;
        this.dispatchEvent("updateDuration", this.duration);
        var blob2 = new window.Blob([new DataView(wav)], {
          type: "audio/wav"
        });
        var url = window.URL.createObjectURL(blob2);
        this.audioNode.sendMessageToAudioScope({loadVoice: {sampleRate, audio: url}});
      });
    });
  }
  async loadWebAudio(song2) {
    this.webAudio = new SuperpoweredWebAudio(this.sampleRate, this.Superpowered);
    this.audioNode = await this.webAudio.createAudioNodeAsync(this.workletPath, "MyProcessor", (message) => {
      this.onMessageFromAudioScope(message);
    });
    this.webAudio.audioContext.suspend();
    this.audioNode.connect(this.webAudio.audioContext.destination);
    this.audioNode.sendMessageToAudioScope({init: this.sampleRate});
    this.audioNode.sendMessageToAudioScope({load: song2});
    this.webAudio.audioContext.resume();
  }
  async loadDuetAudio(blob) {
    this.webAudio = new SuperpoweredWebAudio(this.sampleRate, this.Superpowered);
    this.audioNode = await this.webAudio.createAudioNodeAsync(this.workletPath, "MyProcessor", (message) => {
      this.onMessageFromAudioScope(message);
    });
    this.webAudio.audioContext.suspend();
    this.audioNode.connect(this.webAudio.audioContext.destination);
    this.audioNode.sendMessageToAudioScope({init: this.sampleRate});
    blob.arrayBuffer().then((buf) => {
      this.webAudio.audioContext.decodeAudioData(buf, (audioBuffer) => {
        let wav = audioBufferToWav(audioBuffer);
        this.dispatchEvent("updateDuration", this.duration);
        var blob2 = new window.Blob([new DataView(wav)], {
          type: "audio/wav"
        });
        var url = window.URL.createObjectURL(blob2);
        this.audioNode.sendMessageToAudioScope({load: url});
        this.webAudio.audioContext.resume();
      });
    });
  }
  seek(value) {
    this.audioNode.sendMessageToAudioScope({seek: value});
  }
  sync(value) {
    this.syncValue = value;
    this.audioNode.sendMessageToAudioScope({sync: value});
  }
  getPosition() {
    this.audioNode.sendMessageToAudioScope({getPosition: true});
  }
  play(time = 0) {
    this.audioNode.sendMessageToAudioScope({play: time});
    this.playing = true;
  }
  mmsbOn() {
    this.mmsb = true;
    this.audioNode.sendMessageToAudioScope({mmsb: true});
  }
  mmsbOff() {
    this.mmsb = false;
    this.audioNode.sendMessageToAudioScope({mmsb: false});
  }
  prepareForRecording() {
    this.playing = false;
    this.audioNode.sendMessageToAudioScope({record: true});
  }
  changeVocalVolume(value) {
    this.vocalVolume = value;
    this.audioNode.sendMessageToAudioScope({vocalVolume: value});
  }
  changeMusicVolume(value) {
    this.musicVolume = value;
    this.audioNode.sendMessageToAudioScope({musicVolume: value});
  }
  pause() {
    this.audioNode.sendMessageToAudioScope({pause: true});
    this.playing = false;
  }
  async makeNode(url) {
    this.audioNode = await this.webAudio.createAudioNodeAsync(url, "MyProcessor", (message) => {
      this.onMessageFromAudioScope(message);
      URL.revokeObjectURL(url);
    });
    this.webAudio.audioContext.suspend();
    this.audioNode.connect(this.webAudio.audioContext.destination);
    this.audioNode.sendMessageToAudioScope({init: this.sampleRate});
    this.audioNode.sendMessageToAudioScope({load: song});
    this.webAudio.audioContext.resume();
  }
  addEventListener(eventName, cb) {
    if (!this.events[eventName]) {
      this.events[eventName] = new Array();
    }
    this.events[eventName].push(cb);
  }
  dispatchEvent(event, args) {
    if (!this.events[event])
      return;
    this.events[event].forEach((cb) => cb(event, args));
  }
}
