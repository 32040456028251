<div class="reply-comment" [ngClass]="customClass" id="{{elId}}">
    <div class="reply-comment--loading" [ngClass]="{'d-none': !commentPosting}">
        <i class="fa fa-spinner" aria-hidden="true">
        </i>
    </div>
    <div class="reply-comment--loading" [ngClass]="{'d-none': !replyDisabled}">
    </div>
    <app-ss-editor #editor [(editorContent)]="editorContent"
                   (onCommentPost)="onCommentPostHandle($event)"
                   (onCancelCommentPost)="onCancelCommentPostHandle($event)"
                   (onUploadImage)="onUploadImageHandle($event)"
                   (editorFocus)="onEditorFocused($event)"
                   (editorBlur)="onEditorBlurred($event)"
                   [placeholder]="placeholder"
                   [state]="state"
                   [autofocus]="autofocus"
                   [showPostButton]="showPostButton">
    </app-ss-editor>   
</div>
