import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import {UtilService} from '@app/core';

@Component({
  selector: 'app-featured-list',
  templateUrl: './featured-list.component.html',
  styleUrls: ['../featured-grid/featured-grid.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturedListComponent implements OnInit, OnChanges {
  @Input() noRow = false;
  @Input() type = 'standard';
  @Input() title: string =  "";
  @Input() seeMorePage: string = null;
  @Input() hasSeeMore = false;
  @Input() userIdFromProfile;
  @Input() limit: any;
  @Input() featuredList: Array<any>;
  @Input() innerCircle: any;
  @Input() loggedUser: any;
  @Input() loading;
  @Input() customClass = '';
  @Input() scrollable = true;
  @Input() showHeader = true;
  @Input() showSort = true;
  @Input() flexible = false;
  @Input() isInfiniteScroll = false;
  @Input() staticHeight = true;
  @Input() skeletonType = "";
 //orderBy = 'recent';
  orderBy = {sorttype: 'created_at', direction: 'DESC'};
  @Output() onScrollEnd = new EventEmitter<any>();
  @Output() onOrderBy = new EventEmitter<any>();
  @Output() onViewChange = new EventEmitter<any>();
  @ViewChild('ps', { static: true }) ps;
  config = { wheelPropagation: false };
  loggedInIsGold = true;
  @ContentChild('itemContent', /* TODO: add static flag */ {}) itemTmpl: TemplateRef<any>;

  constructor(private util: UtilService) {}

  ngOnInit() {
    if (this.limit) {
      this.featuredList = this.featuredList.splice(0, this.limit);
    }
    let loggedIn = this.util.getloggedUserData();
    this.loggedInIsGold = loggedIn.gold;
  }

  ngOnChanges(changes: SimpleChanges) {}

  get customStyle() {
    const style: any = {
      'max-height': this.staticHeight ? '400px' : 'none',
    };
    if (!this.flexible) {
      style['min-height'] = this.staticHeight ? '400px' : '0';
      style['overflow-y'] = 'auto';
    } else {
      style['min-height'] = '0';
      style['overflow-y'] = 'auto';
    }
    return style;

  }

  scrollEndHandle(event) {
    this.onScrollEnd.emit(event);
  }

  handleOrderBy(orderBy, dir) {
    this.orderBy = {sorttype: orderBy, direction: dir};
    this.onOrderBy.emit(this.orderBy);
    this.scrollToTop();
  }

  scrollToTop() {
    this.ps.nativeElement.scrollTop = 0;
  }

  getSeeMoreURL(){
    let component = this.seeMorePage;
    let fields = component.split(/:/);
    let name = fields[0];
    let p:string = "";
    let u:string = "";
    if(component.includes(":")){
      p = '?p=' + fields[1];
      if(this.userIdFromProfile){
        u = '&u=' + this.userIdFromProfile;
      };
    };
 
    return '#/d/' + name + p + u;
  }
}
