import {Component, Input, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {Contest} from '@app/models/contest.model';
import {UtilService} from '@app/core';

@Component({
  selector: 'app-ss-contest',
  templateUrl: './ss-contest.component.html',
  styleUrls: ['./ss-contest.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsContestComponent implements OnInit {

  @Input('contest') contest: Contest;

  constructor(private util: UtilService) {
  }

  ngOnInit() {

  }

  getTooltip() {
    return `<span class="contest-stat"><strong>Participants:</strong> ${this.contest.stats.participants}</span><br>`
  }
}
