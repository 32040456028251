import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Charge } from '@app/models/charge.model';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { ResponseSimple } from '@app/models/responses.model';

type Processor = 'stripe' | 'braintree' | 'credits';

@Injectable()
export class ShopService extends HttpService {
  constructor(private http: HttpClient) {
    super();
  }
  getPaymentMethods() {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/shop/payment-methods`);
    return this.http.get(url, headers).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
  getCategories() {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/shop/categories`);
    return this.http.get(url, headers);
  }

  getProductOpts(productId) {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/shop/products/${productId}`);
    return this.http.get(url, headers);
  }

  getPlans() {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/shop/categories/plan`);
    return this.http.get(url, headers);
  }

  getDistribute() {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/shop/categories/distribute`);
    return this.http.get(url, headers);
  }

  getDistributables() {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/shop/categories/distribute`);
    return this.http.get(url, headers);
  }
  getQuote(productId, quantity, recurring){
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/user/quote?product_id=${productId}&quantity=${quantity}&recurring=${recurring.toString()}`);
    console.log(url)
    return this.http.get(url, headers).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
  distribute({
    product_id = -1 as any,
    recipients = [] as any,
    quantity = 1,
    message = '',
    anonymous = false,
  } = {}) {
    const headers = this.getHeaders();

    const payload = {} as any;
    payload.recipients = recipients.map(r => r.id);
    payload.quantity = quantity;
    if (message) {
      payload.message = message;
    }
    payload.anonymous = anonymous;
    console.log(payload)
    const url = this.makeUrl(`/user/distribute/${product_id}`);
    return this.http.post<ResponseSimple>(url, payload, headers);
  }

  getPromos() {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/shop/categories/promotion`);
    return this.http.get(url, headers);
  }

  getCredits() {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/shop/categories/credits`);
    return this.http.get(url, headers);
  }

  sendCredits(userId, amount, anonymous, message) {
    const headers = this.getHttpHeaders();

    const payload = {} as any;
    payload.amount = amount;
    if (message) {
      payload.message = message;
    }
    payload.anonymous = anonymous;
    const url = this.makeUrl(`/users/${userId}/snap`);
    return this.http.post(url, payload, headers);
  }

  getSnapIcons(page) {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/shop/categories/snapicon?page=${page}`);
    return this.http.get(url, headers).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getMerchandise() {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/shop/categories/merchandise`);
    return this.http.get(url, headers);
  }

  getGMG() {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/shop/categories/GMG`);
    return this.http.get(url, headers);
  }

  /* Orders */
  createAndChargeOrder(charge: Charge) {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/shop/orders/charge`);
    return this.http.post<ResponseSimple>(url, charge, headers);
  }

  changeRecipient(orderId, recipientId) {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/shop/orders/${orderId}`);
    return this.http.put(url, { recipient_id: recipientId }, headers);
  }

  updateOrder(orderId, productId) {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/shop/orders/${orderId}`);
    return this.http.put(url, { product_id: productId }, headers);
  }

  getBraintreeToken() {
    const headers = this.getHttpHeaders();
    const url = this.makeUrl(`/shop/processors/braintree/token`);
    return this.http.get(url, headers);
  }
}
