<form action="/">
  <input type="hidden" name="payment_method_nonce">
  <div class="cc-number">
    <div id="card-number">
      <label for="card-number">Card Number</label>
      <app-ss-input>
        <input id="cc-number" formControlName="creditCard" type="tel" autocomplete="cc-number" ccNumber>
      </app-ss-input>
    </div>
  </div>

  <div class="cc-security">
    <div id="cvv">
      <label for="cvv">CVV Code</label>
      <app-ss-input>
        <input id="cc-cvc" formControlName="cvc" type="tel" autocomplete="off" ccCVC>
      </app-ss-input>
    </div>

    <div id="expiration-date">
      <label for="expiration-date">Expiration Date</label>
      <app-ss-input>
        <input id="cc-exp-date" formControlName="expirationDate" type="tel" autocomplete="cc-exp" ccExp>
      </app-ss-input>
    </div>
  </div>
</form>
