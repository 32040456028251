import { Component, OnInit } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ActivatedRoute, Router } from '@angular/router';
import { FailedService } from '../../core/services/failed.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-upload-failed-list',
  templateUrl: './upload-failed-list.component.html',
  styleUrls: ['./upload-failed-list.component.css']
})
export class UploadFailedListComponent implements OnInit {
    database = [];
    panelClosed = true;
    failedSubscription: Subscription;
    constructor(private dbService: NgxIndexedDBService, private router: Router, private failed: FailedService) { }

  ngOnInit(): void {
    this.failedSubscription = this.failed.currentMessage.subscribe(message => this.refreshDB())
    //get db
    this.refreshDB()
    // setInterval(()=>{
    //   //refresh db every 30 seconds
    //   this.refreshDB()
    // }, 30000)
  }
  togglePanel() {
    this.panelClosed = !this.panelClosed;
  }
  refreshDB() {
    this.dbService.getAll('uploads').subscribe((db) => {
      this.database = db;
      console.log(this.database)
    });
  }
  deleteAll(){
    console.log('delete all')
    this.database.forEach((recording, index)=>{
      this.dbService.delete('uploads', recording.id).subscribe((db) => {
        this.refreshDB();
      });
    })
  }
  delete(id){
    console.log('delete')
      this.dbService.delete('uploads', id).subscribe((db) => {
        this.refreshDB();
      });
  }
  retryUpload(id) {
    this.router.navigate(['/d/sing-uploader'], {queryParams: { failedID: id }});
    
  }
}
