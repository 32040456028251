import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-full-screen',
  templateUrl: './icon-full-screen.component.html',
  styleUrls: ['./icon-full-screen.component.scss']
})
export class IconFullScreenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
