<div class="featured-image" *ngIf="item?.user || item?.users">
    <a class="featured-image--poster"
       [routerLink]="['/d/listen/', item?.id]"
       (click)='navigate("/d/listen/", item?.id)'
       [ngStyle]="{'background-image': 'url('+item?.cover+'?h=200)'}">
        <span class="featured-image--stats" *ngIf="showStats">
            <span>
                <i [inlineSVG]="'/assets/pictures/icons/heart.svg'"></i>
                {{formatLove(item?.stats?.love)}}
            </span>
            <span>
                <i [inlineSVG]="'/assets/pictures/icons/eye.svg'"></i>
                {{formatViews(item?.stats?.views)}}
            </span>
            <span>
                <i [inlineSVG]="'/assets/pictures/icons/bubble.svg'"></i>
                {{formatComments(item?.stats?.comments)}}
            </span>
        </span>
        <img [src]="item?.cover + '?h=200'" class="img-fluid rounded"/>
    </a>
    <h1 class="ss-carousel-caption">
        <span [routerLink]="['/recorder', item?.song?.id]" class="ss-truncate">
            {{item?.song?.title | truncateText:getTruncateValue(22):'...'}}
            <div  class="rec-tags" [class.tags-pill]="pill" *ngIf="item.badges.name" [ngStyle]="getStyle()" > {{ getRecordingTag(22)}}  </div>
        </span>

        <span class="artist-name">
            {{item?.song?.artist_name | truncateText:22:'...'}}   
        </span>
        <app-ss-user-name *ngFor="let user of item?.users | slice:0:2" [user]="user"></app-ss-user-name>
    </h1>
</div>
<div class="featured-image" *ngIf="item?.artist">
    <a class="featured-image--poster"
       [routerLink]="['/recorder/', item?.id]"
       (click)="navigate('/recorder/', item?.id)"
       [ngStyle]="{'background-image': 'url('+item?.photo+'?h=200)'}">
        <span class="featured-image--stats" *ngIf="showStats">
            <span>
                <i [inlineSVG]="'/assets/pictures/icons/heart.svg'"></i>
                {{item?.favorited}}
            </span>
            <span>
                <i [inlineSVG]="'/assets/pictures/icons/eye.svg'"></i>
                {{item?.views}}
            </span>
            <span>
                <i [inlineSVG]="'/assets/pictures/icons/mic.svg'"></i>
                {{item?.recordings}}
            </span>
        </span>
        <img [src]="item?.photo + '?h=200'" class="img-fluid rounded"/>
        <app-explicit-tag *ngIf="item?.explicit"></app-explicit-tag>
    </a>
    <h1>
        <span [routerLink]="['/recorder', item?.id]" title="{{item?.title}}" class="ss-truncate">{{item?.title | truncateText:26:'...'}}
            <app-tags color="#084B8A"  tag="{{item?.label}}" style="padding-left: 3px;"></app-tags>
        </span>
        <span [routerLink]="['/d/artists/', item?.artist?.id]" class="subtitle" title="{{item?.artist?.name}}">{{item?.artist?.name | truncateText:26:'...'}}</span>
        <div class="info-extra">
            <span *ngIf="item.year">{{item.year}}, </span>  <span>{{item.genre}}</span>
        </div>
    </h1>
</div>
<div class="featured-image" *ngIf="item?.songs > -1">
    <a class="featured-image--poster"
       [routerLink]="['/recorder/', item?.id]"
       [ngStyle]="{'background-image': 'url('+item?.photo+')'}">
        <img [src]="item?.photo" class="img-fluid rounded"/>
    </a>
    <h1>
        <span class="ss-truncate">{{item?.name}}</span>
        <span>{{item?.songs}} Songs</span>
    </h1>
</div>
