import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
import { UtilService } from './util.service';
import { HttpClient, HttpParams } from '@angular/common/http';


@Injectable()
export class SongService extends HttpService {
  constructor(private http: HttpClient, private utilService: UtilService) {
    super();
  }

  getSongs(page = 1, perPage = 10, sort = 'created_at') {
      
    const url = this.makeUrl(
      `/songs?page=${page}&per_page=${perPage}&sorters={"${sort}":"DESC"}`
    );
    const options = this.getHeaders();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }


/*original search songs*/
/*
searchSongs(
    page = 1,
    perPage = 10,
    term = '',
    sort = 'created_at',
    decade = 0,
    freeSongsFirst = false
  ) {
    const ascTxt = freeSongsFirst ? 'ASC' : 'DESC';
    let url = this.makeUrl(
      `/songs?page=${page}&per_page=${perPage}&q={"title": "${term}"}`
    );
    if ( decade > 1900){
      url += `&decade=${decade}`;
    }
    
    const options = this.getHeaders();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
*/

  /*new searchSongs*/
  searchSongs(
    page = 1,
    perPage = 10,
    term = '',
    sort = 'title',
    sortDirection = 'ASC',
    decade = 0,
    freeSongsFirst = false,
    genre = 0,
    searchDuet = false,
    ) {
   // const ascTxt = freeSongsFirst ? 'ASC' : 'DESC';
    let url = '';
    if( sortDirection != "DESC" ){
      sortDirection = 'ASC'
      }    
    
    if (genre && searchDuet){
      url = this.makeUrl(
        `/songs?page=${page}&per_page=${perPage}&q={"title":"${term}","genre_id":${genre},"duet":${searchDuet}}`
      );
    } else if(genre && !searchDuet ){
      url = this.makeUrl(
        `/songs?page=${page}&per_page=${perPage}&q={"title":"${term}","genre_id":${genre}}`
      );
    } else if(searchDuet && !genre){
      url = this.makeUrl(
        `/songs?page=${page}&per_page=${perPage}&q={"title":"${term}","duet":${searchDuet}}`
      );
    } else {
      url = this.makeUrl(
        `/songs?page=${page}&per_page=${perPage}&q={"title":"${term}"}`
      );
    }
    if ( decade > 1900){
      url += `&decade=${decade}`;
    }


    //add sorters
    //url += `&sorters={"${sort}":"${sortDirection}"}`;
    const options = this.getHeaders();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }



  getTrendingSongs(page = 1, perPage = 10, sort = 'created_at') {
    const url = this.makeUrl(
      `/songs/trending?page=${page}&per_page=${perPage}&sorters={"${sort}": "DESC"}`
    );
    const options = this.getHeaders();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getFeaturedSongs(
    page = 1,
    perPage = 10,
    sort = 'created_at',
    asc = true,
    query,
    decade = ''
  ) {
    let url;
    if(decade != '') {
      url = this.makeUrl('/songs/featured?decade=' + decade);
    } else {
      url = this.makeUrl('/songs/featured');

    }
    const options = this.getHeaders();
    const params = this.utilService.buildParams(
      page,
      perPage,
      sort,
      asc,
    );
    if(typeof query == 'object') {
      if(Object.keys(query).length != 0) {
        params.q = '{'
        Object.keys(query).forEach((key) => {
          let value = query[key]
          params.q += `"${key}":"${value}",`
        })
        params.q = params.q.substring(0, params.q.length - 1) + '}'
        console.log(params.q)
      }
    }

    options['params'] = params;
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getArtistSongs(id, page = 1, perPage = 10, sort = 'created_at') {
    const url = this.makeUrl(
      `/artists/${id}/songs?page=${page}&per_page=${perPage}&sorters={"${sort}": "DESC"}`
    );
    const options = this.getHeaders();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getRecentlyRecorded(page = 1, perPage = 10, sort = 'created_at') {
    const url = this.makeUrl(
      `/songs/recent?page=${page}&per_page=${perPage}&sorters={"${sort}": "DESC"}`
    );
    const options = this.getHeaders();
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  // TODO: replace with real endpoint
  getNewReleases(
    page = 1,
    perPage = 10,
    sort = 'created_at',
    asc = true,
    query = null,
    queryField = 'title'
  ) {
    const url = this.makeUrl(`/songs`);
    const options = this.getHeaders();
    const params = this.utilService.buildParams(
      page,
      perPage,
      sort,
      asc,
      query,
      queryField
    );
    const sorters = JSON.parse(params.sorters);
    sorters['created_at'] = 'DESC';
    params.sorters = JSON.stringify(sorters);
    options['params'] = params;
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }


  // TODO: replace with real endpoint
  getNewReleasesSeeMore(
    page = 1,
    perPage = 10,
    sort = 'created_at',
    asc = true,
    query,
    decade = ''
  ) {
    let url;
    if(decade != '') {
      url = this.makeUrl('/songs?decade=' + decade);
    } else {
      url = this.makeUrl('/songs');
    }
    const options = this.getHeaders();
    const params = this.utilService.buildParams(
      page,
      perPage,
      sort,
      asc,
    );
    if(typeof query == 'object') {
      if(Object.keys(query).length != 0) {
        params.q = '{'
        Object.keys(query).forEach((key) => {
          let value = query[key]
          params.q += `"${key}":"${value}",`
        })
        params.q = params.q.substring(0, params.q.length - 1) + '}'
        console.log(params.q)
      }
    }
    options['params'] = params;
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  // TODO: replace with real endpoint
  getRecommended() {
    const url = this.makeUrl(
      `/songs/featured?page=1&per_page=60&sorters={"title":"ASC"}`
    );
    const options = this.getHeaders();
    return this.http
      .get(url, options);
  }

  getById(id) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/songs/${id}`);
    return this.http.get(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  singASong(id) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/songs/${id}/play`);
    return this.http.get(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getUserUploadedSong(id) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/my-uploads/${id}`);
    return this.http.get(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  loadLyrics(id) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/songs/${id}/lyrics`);
    return this.http.get(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }
  

  getFavorites(
    page = 1,
    perPage = 10,
    sort = 'created_at',
    asc = true,
    query = null,
    queryField = 'title'
  ) {
    const url = this.makeUrl(`/user/favorites/song`);
    const options = this.getHeaders();
    const params = this.utilService.buildParams(
      page,
      perPage,
      sort,
      asc,
      query,
      queryField
    );
    options['params'] = params;
    return this.http.get(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getFavoritesSeeMore(
    page = 1,
    perPage = 10,
    sort = 'created_at',
    asc = true,
    query,
    decade = '',
    demo = false
  ) {
    let url;
    if(decade != '') {
      url = this.makeUrl('/user/favorites/song?decade=' + decade);
    } else {
      url = this.makeUrl('/user/favorites/song');

    }
    const options = this.getHeaders();
    const params = this.utilService.buildParams(
      page,
      perPage,
      sort,
      asc,
    );
    if(typeof query == 'object') {
      if(Object.keys(query).length != 0) {
        params.q = '{'
        Object.keys(query).forEach((key) => {
          let value = query[key]
          params.q += `"${key}":"${value}",`

        
        })
        if (demo == true){
          params.q += `"demo":false,`;    
        }
        params.q = params.q.substring(0, params.q.length - 1) + '}'
        console.log(params.q)
      }

      else if (demo == true) {
        params.q = `{"demo":false}`;    
      }




    }
    
    


    options['params'] = params;
    return this.http
      .get(url, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }




  getDuets(
    page = 1,
    perPage = 10,
    sort = 'created_at',
    asc = true,
    query = null,
    queryField = 'title'
  ) {
    const options = this.getHeaders();
    const params = this.utilService.buildParams(
      page,
      perPage,
      sort,
      asc,
      query,
      queryField
    );
    params.q = '{"duet":true}';
    options['params'] = params;
    const url = this.makeUrl(`/songs`);
    return this.http.get(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }



  getDuetsSeeMore(
    page = 1,
    perPage = 10,
    sort = 'created_at',
    asc = true,
    query,
    decade = '',
    demo = 0
  ) {
    let url;
    if(decade != '') {
      url = this.makeUrl('/songs?decade=' + decade);
    } else {
      url = this.makeUrl('/songs');

    }
    const options = this.getHeaders();
    const params = this.utilService.buildParams(
      page,
      perPage,
      sort,
      asc,
    );
   
    if(typeof query == 'object') {
      if(Object.keys(query).length != 0) {
        params.q = '{'
        Object.keys(query).forEach((key) => {
          let value = query[key]
          params.q += `"${key}":"${value}",`
        })
        params.q += `"duet":true,`
        if(demo == 1){
        params.q += `"demo":false,`;
        } else if (demo == 2){
        params.q += `"demo":true,`;
       }
       params.q = params.q.substring(0, params.q.length - 1) + '}'
      } else if (demo == 1) {
        params.q = `{"duet":true, "demo": false}`;
      } else if (demo == 2) { 
        params.q = `{"duet":true, "demo": true}`;
      } else {
        params.q = `{"duet":true}`;
      }

    } 
    options['params'] = params;
   // const url = this.makeUrl(`/songs`);
    return this.http.get(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getGenresSeeMore(
    page = 1,
    perPage = 10,
    sort = 'created_at',
    asc = true,
    query,
    decade = '',
    demo = 0
  ) {
    let url;
    if(decade != '') {
      url = this.makeUrl('/songs?decade=' + decade);
    } else {
      url = this.makeUrl('/songs');

    }
    const options = this.getHeaders();
    const params = this.utilService.buildParams(
      page,
      perPage,
      sort,
      asc,
    );
   
    if(typeof query == 'object') {
      if(Object.keys(query).length != 0) {
        params.q = '{'
        Object.keys(query).forEach((key) => {
          let value = query[key]
          params.q += `"${key}":"${value}",`
        })
        // params.q += `"duet":true,`
        if(demo == 0 ){
        params.q += `"demo":false,`;

        } else if (demo == 1){
        params.q += `"demo":true,`;

       }
       params.q = params.q.substring(0, params.q.length - 1) + '}'
      } 
      
      // else if (demo == 0) {
      //   params.q = `{ "demo": false}`;
      // } else if (demo == 1) { 
      //   params.q = `{ "demo": true}`;
      // }

    } 
    options['params'] = params;
   // const url = this.makeUrl(`/songs`);
    return this.http.get(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  fav(songId: string | number) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/songs/${songId}/favorite`);
    return this.http.post(url, {}, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  unfav(songId: string | number) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/songs/${songId}/favorite`);
    return this.http.delete(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getSongsSearchResult(searchString, isSingSelected, options?) {
    const { page = '1', perPage = '3' } = options;
    let url = '/songs';
    if (!isSingSelected) {
      url = '/recordings';
    }
    const headers = this.getRawHeaders();
    const params = new HttpParams();
    const q = isSingSelected
      ? JSON.stringify({ title: searchString })
      : JSON.stringify({ 'song.title': searchString });
    params.append('page', page);
    params.append('per_page', perPage);
    params.append('q', q);

    return this.http
      .get(
        this.makeUrl(url),{ headers: headers, params: params }
)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  reportSong(songId: string | number, reason) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/songs/${songId}/report`);
    return this.http
      .post(url, reason, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  uploadSong(data: any) {
    const options = this.getHeaders();
    const url = this.makeUrl('/user/songs');
    //return this.http.post(url, data, options);
    return this.http.post(url, data, options);
  }

  deleteUploaded(id) {
    const options = this.getHeaders();
    options.headers.set('Content-Type', "application/x-www-form-urlencoded");
    console.log(options)
    const url = this.makeUrl(`/user/songs/${id}`);
    return this.http.delete(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  updateUploaded(id, data) {
    const options = this.getHeaders();
    //options.headers.set('Content-Type', "multipart/form-data");

    console.log(...data)
    console.log(options)
    const url = this.makeUrl(`/user/songs/${id}`);
    return this.http.post(url, data, options);
  }

  getGenre() {
    const options = this.getHeaders();
    const url = this.makeUrl('/genres/?per_page=50&q={"name":""}');
    return this.http.get(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getUserSongs(page = 1, q = '', perPage = 5, sort = 'created_at', direction = 'DESC') {
    let url = this.makeUrl(
      `/user/my-uploads?page=${page}&per_page=${perPage}&sorters={"${sort}": "${direction}"}`
    );
    if (q) {
      url = url + `&q=${q}`;
    }
    const options = this.getHeaders();
    return this.http.get(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  uploadLRC(data) {
    const options = this.getHeaders();
    const url = this.makeUrl(`/user/songs/lrc`);
    return this.http
      .post(url, data, options)
      .pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }

  getRecordings(id, page = 1, perPage = 15) {
    const url = this.makeUrl(`/songs/${id}/recordings`);
    const options = this.getHeaders();
    return this.http.get(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }


  getRecordingsV2(id, page = 1, perPage = 15, sort = 'created_at') {
    const url = this.makeUrl(`/songs/${id}/recordings?page=${page}&per_page=${perPage}&sorters={"${sort}":"DESC"}`);
    const options = this.getHeaders();
    return this.http.get(url, options).pipe(map((res) => JSON.parse(JSON.stringify(res))));
  }


}
 