import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-blocked-notify-modal',
  templateUrl: './blocked-notify-modal.component.html',
  styleUrls: ['./blocked-notify-modal.component.scss'],
})
export class BlockedNotifyModalComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  @Output() closed = new EventEmitter();
  modalRef;
  description = 'Apologies! You are blocked from viewing this user profile.';

  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  open(description = '') {
    if (description) {
      this.description = description;
    }
    this.openModal();
  }

  openModal() {
    this.modalRef = this.modalService.open(this.contentElem, {
      windowClass: 'ss-warning-modal',
      backdrop: 'static',
    });

    this.modalRef.result.then(
      result => {
        if (this.closed) {
          this.closed.emit();
        }
      },
      async reason => {
        if (this.closed) {
          this.closed.emit();
        }
      }
    );
  }
}
