 
<div id='seeMoreContainer'>
  <div class='headerContainer'>
    <div class='title'>
      <app-icon-button color="white" (click)="back()" >
      <app-icon-arrow-left color="gray"></app-icon-arrow-left>
      </app-icon-button>
      <h4>Home - New Releases</h4>
    </div>     

    <div class='moreFilters'>
      <span>Duet:
        <input type="checkbox"  #filterByDuetCheckBox [checked]="filterByDuet" (change)="duetClick(filterByDuetCheckBox.checked)"  >
      </span>
      <span>Decade: 
        <select style="width: 4.5rem;" name="decade-dropdown" id="decade-dropdown" (change)="decadeClick($event.target.value)">
        <option *ngFor="let item of decadesArray;let e = index" [selected]= "e==selectedDecadeIndex" [value]="e">{{item}}</option>
        </select>
      </span>
      <span *ngIf='genresArray.length != 1'>Genre:
        <select  name="genre-dropdown" id="genre-dropdown" (change)="genreClick( $event.target.value)">
        <option *ngFor="let item of genresArray;let e = index" [selected]= "e==selectedGenreIndex" [value]="e">{{item.name}}</option>
        </select>
      </span>
    </div>
  </div>   
  
  <ul class='letterList' >
    <li *ngFor='let letter of letters'>
      <button *ngIf='selectedLetter == letter' class='letterButton selectedLetter' (click)='handleFilter(letter)'>{{letter}}</button>
      <button *ngIf='selectedLetter != letter' class='letterButton' (click)='handleFilter( letter)'>{{letter}}</button>
    </li>
  </ul>
  
  <app-ss-featured-grid
    title="New Releases"
    [hasSeeMore] = "false"
    [scrollable] = "false"
    [cols]="3"
    [colsLg]="3"
    [colsMd]="2"
    [colsSm]="1"
    [extendedSize] = "true"
    [sortOptions]="recordingSortOptions"
    [list]="releasedData.items"
    [loading]="releasedData.loading"
    [freeSongFilter]="false"
    (onScrollEnd)="onScrollEndHandle()"
    (onOrderBy)="resetDataObj(releasedData);setSortFields($event);onScrollEndHandle()"
  >
    <ng-template #itemContent let-item>
      <div class="ssc-list-item"  class="ss-list--item {{ customClass }}" [ngClass]="{ 'is-mine': checkMine(item) }">
        <app-ss-song [linkActive]="false" [song]="item" (click)='navSing(item?.id)'></app-ss-song>
        <div class="song-actions">
          <app-more-dot-menu title="Options">
            <div class="dropdown-menu-content">
              <div *ngIf="!item.favorited" (click)="favSong(item)">Add Song to Favorites</div>
              <div *ngIf="item.favorited" (click)="unFavSong(item)">Remove Song from Favorites</div>
              <div (click)="navArtistProfile(item.artist.id)">View Artist Profile</div>
              <div (click)="howOthersSingThis(item.id)">How Others Sing This</div>
            </div>
          </app-more-dot-menu>
        </div>
      </div>
    </ng-template>
  </app-ss-featured-grid>
  <app-vert-space height="40"></app-vert-space>
  
  
  <div  id="pageNavContainer"  style="margin-bottom: 13px;">
    <button *ngIf='releasedData.currentPage != 1' (click)="getPrevSongs()">&lt; PREV</button>
    <p >{{currentPage}}</p>
    <button *ngIf='releasedData.lastPage' (click)="getNextSongs()">NEXT &gt;</button>
  </div>


</div>


