import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'cents',
  pure: true
})
export class CentsPipe implements PipeTransform {

  transform(value: number): string {
    return (value / 100).toFixed(2);
  }

}
