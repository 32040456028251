<div class="app-icon">
    <svg [attr.width]="size" [style.width]="size" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <polygon id="path-arrow-down" points="7 10 12 15 17 10"></polygon>
        </defs>
        <g id="ic_arrow_drop_down" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g>
                <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-arrow-down"></use>
                </mask>
                <use id="Mask" [attr.fill]="color" xlink:href="#path-arrow-down"></use>
            </g>
        </g>
    </svg>
</div>