import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {UtilService} from '@app/core';

@Component({
    selector: 'app-message-board-thumb',
    templateUrl: './message-board-thumb.component.html',
    styleUrls: ['./message-board-thumb.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageBoardThumbComponent implements OnInit {
    src: string = '';

    @Input('thumbnailSrc')
    set thumbnailSrc(src: string) {
        this.src = this.util.addWidthIfImgIx(src, 100);
    }

    get thumbnailSrc(): string {
        return this.src;
    }

    constructor(private util: UtilService) {
    }

    ngOnInit() {
    }

}
