<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="Credit-Icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="icon-privacy-private" transform="translate(-87.000000, -65.000000)" [attr.fill]="color"  fill-rule="nonzero">
        <path
          d="M99,71 L98.1428571,71 L98.1428571,69.2857143 C98.1428571,66.92 96.2228571,65 93.8571429,65 C91.4914286,65 89.5714286,66.92 89.5714286,69.2857143 L89.5714286,71 L88.7142857,71 C87.7714286,71 87,71.7714286 87,72.7142857 L87,81.2857143 C87,82.2285714 87.7714286,83 88.7142857,83 L99,83 C99.9428571,83 100.714286,82.2285714 100.714286,81.2857143 L100.714286,72.7142857 C100.714286,71.7714286 99.9428571,71 99,71 Z M91.2857143,69.2857143 C91.2857143,67.8628571 92.4342857,66.7142857 93.8571429,66.7142857 C95.28,66.7142857 96.4285714,67.8628571 96.4285714,69.2857143 L96.4285714,71 L91.2857143,71 L91.2857143,69.2857143 Z M99,81.2857143 L88.7142857,81.2857143 L88.7142857,72.7142857 L99,72.7142857 L99,81.2857143 Z M93.8571429,78.7142857 C94.8,78.7142857 95.5714286,77.9428571 95.5714286,77 C95.5714286,76.0571429 94.8,75.2857143 93.8571429,75.2857143 C92.9142857,75.2857143 92.1428571,76.0571429 92.1428571,77 C92.1428571,77.9428571 92.9142857,78.7142857 93.8571429,78.7142857 Z"
          id="private"></path>
      </g>
    </g>
  </svg>
</div>