import { ChangeDetectorRef, NgZone, Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import {
  GenresService,
  UtilService,
  EventService,
  RecordingService,
  SongService,
  ProfileService,
  HelperService,
} from '@app/core';
import { ArtistService } from '@app/core/services/artist.service';

declare var $: any;

@Component({
  selector: 'app-sing-re',
  templateUrl: './sing-re.component.html',
  styleUrls: ['./sing-re.component.scss'],
})
export class SingReComponent implements OnInit {
  recording;
  titleLength = 24;
  duets = [];
  artists = [];
//  trending$;
  trendingSongs = [];
  trendingLoading = false;
  recordingSortOptions = [];
  songSortOptions = [];
  songSortOptionsFavoritesFirst = [];
  artistSortOptions = [];
  genreSortOptions = this.util.recordingSortOptions;
  @ViewChild('goldcontent', { static: true }) private goldModal;
  loggedInIsGold = true;
  artistsData: any = {
    ...this.util.generateGridData(),
    fetch: this.artistService.getArtists.bind(this.artistService),
  };

  genresData: any = {
    ...this.util.generateGridData(),
    sort: this.genreSortOptions[0],
    fetch: this.genreService.getGenres.bind(this.genreService),
  };

  featuredData: any = {
    ...this.util.generateGridData(),
    perPage: 20,
    fetch: this.songService.getFeaturedSongs.bind(this.songService),
  };

  favoriteData: any = {
    ...this.util.generateGridData(),
    perPage: 20,
    fetch: this.songService.getFavorites.bind(this.songService),
  };

  openDuetsData: any = {
    ...this.util.generateGridData(),
    perPage: 20,
    fetch: this.recordingService.getOpenDuets.bind(this.recordingService),
  };

  checkMine(item){
     return item.favorited;
  }
  
  duetsData: any = {
    ...this.util.generateGridData(),
    perPage: 20,
    fetch: this.songService.getDuets.bind(this.songService),
  };

  constructor(
    private recordingService: RecordingService,
    private songService: SongService,
    private genreService: GenresService,
    private router: Router,
    private artistService: ArtistService,
    private toastService: ToastrService,
    public profileService: ProfileService,
    private helperService: HelperService,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    private util: UtilService,
    private ngZone: NgZone
  ) {
    this.recordingSortOptions = this.util.recordingSortOptions;
    this.songSortOptions = this.util.songSortOptions;
    this.songSortOptionsFavoritesFirst = this.util.songSortOptionsFavoritesFirst;
    this.artistSortOptions = this.util.artistSortOptions;
    //this.featuredData.sort = this.util.songSortOptions[0];
    this.featuredData.sort = this.util.songSortOptionsFavoritesFirst[0];
    this.favoriteData.sort = this.util.songSortOptions[0];
    this.openDuetsData.sort = this.util.recordingSortOptions[0];
    this.duetsData.sort = this.util.songSortOptions[0];
    this.artistsData.sort = this.util.artistSortOptions[0];
  }

  ngOnInit() {

    console.log('users gold', this.profileService.user.gold);
    /* set default free first songs for blue users */
    if (!this.profileService.user.gold) {
      this.featuredData.freeSongsFirst = true;
      this.favoriteData.freeSongsFirst = true;
      this.duetsData.freeSongsFirst = true;

      this.onScrollEndHandle(this.featuredData, { field: 'gold', asc: true });
      this.onScrollEndHandle(this.duetsData, { field: 'gold', asc: true });
      this.onScrollEndHandle(this.favoriteData, { field: 'created_at', asc: true });
    } else {
      this.onScrollEndHandle(this.featuredData, null);
      this.onScrollEndHandle(this.duetsData, null);
      this.onScrollEndHandle(this.favoriteData, null);
    }

   // this.trending$ = this.songService.getTrendingSongs(1, 60);
    this.trendingLoading = true;
    this.songService.getTrendingSongs().subscribe(response => {
      this.trendingSongs = response['data'];
      this.trendingLoading = false; 
    });

    this.onScrollEndHandle(this.openDuetsData, null);
    this.onScrollEndHandle(this.genresData, null);
    this.onScrollEndHandle(this.artistsData, null);

    let loggedIn = this.util.getloggedUserData();
    this.loggedInIsGold = loggedIn.gold;
  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  navigateArtist(id){
    this.navigate([`/d/artists/${id}`])
  }


  favRecording(recording) {
    
    this.recordingService.fav(recording.id).subscribe(response => {
      if (response.success) {
        //recording = response.model;
        recording.favorited = true;
        this.toastService.success('Recording was added to favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  unFavRecording(recording) {
    this.recordingService.unfav(recording.id).subscribe(response => {
      if (response.success) {
        //recording = response.model;
        recording.favorited = false;
        this.toastService.success('Recording was removed from favorites.');
      } else {
        this.toastService.error('There was an error, try again later.');
      }
    });
  }

  handleSing(song) {
    if (song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    // this.router.navigate(['/d/sing', song.id]);

    this.navigate([`/recorder/${song.id}`])

  }

  handleDuet(duet) {
    if (duet.song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
    // this.router.navigate(['/duet-recorder', duet.id]);
    this.navigate([`/duet-recorder/${duet.id}`])
  }

  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  onScrollEndHandle(data, sort, reload = false) {
    if (sort) {
      console.log(data.sort)
      data.sort = sort;
    }

    if (reload || (!data.loading && data.lastPage)) {
      data.loading = true;
      data.currentPage++;
    //  this.detectChanges();
      data
        .fetch(data.currentPage, data.perPage, data.sort.field, data.sort.asc)
        .subscribe(
          response => {
            data.items = data.items.concat(response.data);
            data.lastPage = response.next_page_url;
            data.loading = false;
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  handleFreeSongsChange(data, gold) {
    data.freeSongsFirst = gold;
    this.resetDataObj(data);
    this.onScrollEndHandle(data, { field: 'gold', asc: gold }, true);
  }

  resetDataObj(data) {
    data.currentPage = 0;
    data.loading = false;
    data.items = [];
    data.lastPage = 1;
  }

  recordingToLegacy(recording) {
    this.recordingService.appearOnLegacy(recording.id).subscribe(
      response => {
        this.toastService.success('Recording will appear on legacy');
      },
      () => {
        this.toastService.success("There's an error, please try again later.");
      }
    );
  }

  favoriteSong(song) {
    this.songService.fav(song.id).subscribe(() => {
      song.favorited = true;
      this.toastService.success('Added song to favorites');
    });
  }

  unfavoriteSong(song) {
    this.songService.unfav(song.id).subscribe(() => {
      song.favorited = false;
      this.toastService.success('Removed song from favorites');
    });
  }

  openLink(url) {
    window.open(window.location.origin + '/#' + url);
  }

  onArtist(artist) {
    //this.openLink('/d/artists/' + artist.id);
    // this.router.navigate(['/d/artists/', artist.id]);
    this.navigate([`/d/artists/${artist.id}`])
  }
  
  onFavArtist(artist) {
    this.artistService.favorite(artist.id).subscribe(result => {
      artist.favorited = true;
      this.toastService.success('Added artist to favorites');
    });
  }

  onUnfavArtist(artist) {
    this.artistService.unfavorite(artist.id).subscribe(result => {
      artist.favorited = false;
      this.toastService.success('Removed artist from favorites');
    });
  }

  async subscribeUser(recording) {
    // const recordingList = [this.openDuetsData.items, this.duetsData.items];
    const recordingList = [this.openDuetsData.items];
    this.helperService.subscribeUser(recording, recordingList);
  }

  async unsubscribeUser(recording) {
    // const recordingList = [this.openDuetsData.items, this.duetsData.items];
    const recordingList = [this.openDuetsData.items];
    this.helperService.unsubscribeUser(recording, recordingList);
  }

  shareRecording(recording) {
    this.eventService.onSocialShare({ recording });
  }

  howOthersSingThis(id){
     this.navigate([`/d/ListenOthersSing/${id}`]);
  }
}
