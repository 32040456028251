import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
  @Input() tag: string;
  @Input() noRemove = false;
  @Input() color = null;
  @Output() remove = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onRemove() {
    this.remove.emit();
  }
}
