<div class="col-12 featured-list--container px-0 featured-list--{{type}}">
    <h2 *ngIf="showHeader">{{title}} <i [ngClass]="{'d-none': !loading}" class="fa fa-spinner fa-spin-an"></i>
        <span ngbDropdown class="d-inline-block pull-right dropdown-orderby" *ngIf="showOrderOptions">
            <span class="d-inline-block" ngbDropdownToggle>
                <i *ngIf="orderBy === 'recent'" [inlineSVG]="'/assets/pictures/icons/time.svg'"></i>
                <i *ngIf="orderBy === 'popular'" [inlineSVG]="'/assets/pictures/icons/flame.svg'"></i>
            </span>
            <div ngbDropdownMenu>
                <div class="dropdown-item" [ngClass]="{'selected': orderBy === 'recent'}" (click)="handleOrderBy('recent')">Most Recent</div>
                <div class="dropdown-item" [ngClass]="{'selected': orderBy === 'popular'}" (click)="handleOrderBy('popular')">Most Popular</div>
            </div>
        </span>
    </h2>
    <ul class="featured-list row ps" [disabled]="!scrollable" [perfectScrollbar]="config"
        #ps
        (psYReachEnd)="scrollEndHandle($event)">
        <li *ngFor="let item of list" class="featured-list--item {{customClass}}" [ngClass]="getColClass()">
            <div [ngClass]="{'row': !noRow}">
                <ng-container *ngTemplateOutlet="itemTmpl, context: { $implicit: item }"></ng-container>
            </div>
        </li>
        <li *ngIf="list && list.length === 0" class="featured-list--no-results">
            <span>No results found.</span>
        </li>
    </ul>
</div>

<a href="#" style='color: #58c7c9;' class="featured-list--more" *ngIf="hasSeeMore">See More</a>

