<div class="app-icon">
  <svg [attr.width]="size" [style.width]="size" viewBox="0 0 18 18" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
    <title>Falme</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M9.03391304,14.8695652 C7.64086957,14.8695652 6.52173913,13.766087 6.52173913,12.4121739 C6.52173913,11.1443478 7.32782609,10.2521739 8.71304348,9.97043478 C10.0982609,9.68869565 11.5304348,9.02347826 12.3286957,7.95130435 C12.633913,8.96086957 12.7826087,10.0252174 12.7826087,11.1130435 C12.7826087,13.1791304 11.1078261,14.8695652 9.03391304,14.8695652 L9.03391304,14.8695652 Z M10.4347826,0.524347826 C10.4347826,0.524347826 11.013913,2.59826087 11.013913,4.28086957 C11.013913,5.89304348 9.9573913,7.2 8.34521739,7.2 C6.72521739,7.2 5.50434783,5.89304348 5.50434783,4.28086957 L5.52782609,3.99130435 C3.94695652,5.86956522 3,8.30347826 3,10.9565217 C3,14.4143045 5.80308678,17.2173913 9.26086957,17.2173913 C12.7186523,17.2173913 15.5217391,14.4143045 15.5217391,10.9565217 C15.5217391,6.73043478 13.4947826,2.97391304 10.4347826,0.524347826 Z"
        id="path-icon-flame"></path>
    </defs>
    <g id="Falme" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use xlink:href="#path-icon-flame"></use>
      </mask>
      <use id="Shape" [attr.fill]="color" fill-rule="nonzero" xlink:href="#path-icon-flame"></use>
    </g>
  </svg>
</div>