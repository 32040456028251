import Quill from 'quill';

const Embed = Quill.import('blots/embed');


class MentionBlot extends Embed {
    static blotName: string;
    static tagName: string;
    static className: string;
    static create(data) {
        const node = super.create();
        /* const denotationChar = document.createElement('span');
        denotationChar.className = 'ql-mention-denotation-char';
        denotationChar.innerHTML = data.denotationChar + data.value; */
        // node.appendChild(denotationChar);
        node.innerHTML += data.denotationChar + data.value;
        //node.href = '/#/d/profile/' + data.id + '/info';
        node.href = '#';
        node.dataset.id = data.id;
        node.dataset.value = data.value;
        node.dataset.email = data.email;
        node.dataset.newsletter = data.newsletter;
        node.dataset.denotationChar = data.denotationChar;
        return node;
    }

    static value(domNode) {
        return {
            id: domNode.dataset.id,
            value: domNode.dataset.value,
            email: domNode.dataset.email,
            newsletter: domNode.dataset.newsletter,
            denotationChar: domNode.dataset.denotationChar,
        };
    }
}

MentionBlot.blotName = 'mention';
MentionBlot.tagName = 'a';
MentionBlot.className = 'mention';

Quill.register(MentionBlot);
