import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-reaction',
    templateUrl: './reaction.component.html',
    styleUrls: ['./reaction.component.scss']
})
export class ReactionComponent implements OnInit {
    private emojiSource = new Subject<any>();
    emoji$ = this.emojiSource.asObservable();
    show = false;
    @Input() reactionList: Array<any>;
    @Input() reactions: Array<any>;
    @Input() placement = 'left';

    constructor() {
    }

    ngOnInit() {
        setTimeout(() => { this.show = true }, 200);
    }

    reactionSelect(reactionEmoji) {
        this.emojiSource.next(reactionEmoji);
    }

}
