import { ChangeDetectorRef,  NgZone, Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import {
  GenresService,
  UtilService,
  EventService,
  RecordingService,
  SongService,
  ProfileService,
  HelperService,
} from '@app/core';
import { ArtistService } from '@app/core/services/artist.service';

declare var $: any;

@Component({
  selector: 'app-sing-artists',
  templateUrl: './sing-artists.component.html',
  styleUrls: ['./sing-artists.component.scss']
})
export class SingArtistsComponent implements OnInit {
  recording;
  titleLength = 24;
  duets = [];
  artists = [];
  trending$;
  recordingSortOptions = [];
  songSoOptrtions = [];
  artistSortOps = [];
  genreSortOptions = this.util.recordingSortOptions;
  @ViewChild('goldcontent') private goldModal;
  itemsPerPage = 30;
  filterByDuet = false;
  selectedGenreIndex = 0;
  selectedDecadeIndex = 0;
  currentPage = 1;
  //letterFIlter = 'A'
  selectedLetter = '';
  lastSelectedLetter;
  totalPages = 0;
  songSortOptions;
  artistSortOptions;

  letters = [];

  artistsData: any = {
    ...this.util.generateGridData(),
    perPage: this.itemsPerPage,
    fetch: this.artistService.getArtistsSeeMore.bind(this.artistService),
  };

  checkMine(item){
    return item.favorited;
  }



  constructor(
    private recordingService: RecordingService,
    private songService: SongService,
    private genreService: GenresService,
    private router: Router,
    private artistService: ArtistService,
    private toastService: ToastrService,
    public profileService: ProfileService,
    private helperService: HelperService,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    private util: UtilService,
    private location: Location,
    private ngZone: NgZone,
    private route: ActivatedRoute
  ) {
    this.recordingSortOptions = this.util.recordingSortOptions;
    this.songSortOptions = this.util.songSortOptions;
    this.artistSortOptions = this.util.artistSortOptions;
    this.artistsData.sort = this.util.artistSortOptions[0];
  }

  ngOnInit() {
    console.log('users gold', this.profileService.user.gold);
    /* set default free first songs for blue users */
    if (!this.profileService.user.gold) {
    } else {
    }

    this.letters = this.util.getLettersArray();

    this.route.queryParams.subscribe(params => {
      if(typeof params.page != 'undefined') {
         this.currentPage = params.page;
      }
    });

    this.route.queryParams.subscribe(params => {
      if(typeof params.letter != 'undefined') {
        this.selectedLetter = '';
        this.handleFilter(params.letter);
      }
    });

    this.onScrollEndHandle();
  }

  public navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  back(){
    window.history.back();
  }

  navigateArtist(id){
    this.navigate([`/d/artists/${id}`]);
  }

 
  handleSing(song) {
    if (song.gold && !this.profileService.user.gold) {
      this.goldModal.open();
      return;
    }
   // this.router.navigate(['/recorder', song.id]);
    this.navigate([`/d/recorder/${song.id}`]);
  }


  detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  setPageParam() {
    this.location.replaceState(this.location.path().split('?')[0], '');
    this.location.replaceState(this.location.path() + `?page=${this.currentPage}`);
    if(this.selectedLetter.length >0){
     this.location.replaceState(this.location.path() + `&letter=${this.selectedLetter}`);
    }
  }

  handleFilter(filter) {

   if(this.artistsData.filter.name == filter) {
      delete this.artistsData.filter.name;
      this.selectedLetter = '';
    } else {
      this.selectedLetter = filter;
      this.artistsData.filter.name = filter;
    }
    this.resetDataObj(this.artistsData);
    this.onScrollEndHandle();
  }


  setSortFields(sort){
    if (sort) {
      this.artistsData.sort = sort;
    }
  }

  onScrollEndHandle() {
    let data = this.artistsData;
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }
    
    if (!data.loading) {
      data.loading = true;
      this.detectChanges();
      data
        .fetch(this.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade)
        .subscribe(
          response => {
            data.currentPage = this.currentPage
            console.log(response)
            console.log(this.totalPages)
            this.setPageParam()
            data.items = response.data;
            data.lastPage = response.next_page_url;
            data.loading = false;
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }
  getNextSongs() {
    let data = this.artistsData;
    console.log(data.filter)
    console.log(data.currentPage)
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }

    if (!data.loading && data.lastPage) {
      data.loading = true;
      data.currentPage++;
      console.log(data.currentPage)
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade)
        .subscribe(
          response => {
            this.currentPage++
            data.items = response.data;
            this.setPageParam()
            data.lastPage = response.next_page_url;
            data.loading = false;
            window.scrollTo(0, 0);
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }
  getPrevSongs() {
    let data = this.artistsData;
    console.log(data.filter)
    if(typeof data.filter == 'undefined') {
      data.filter = {};
    }
    if(typeof data.filter == 'undefined') {
      data.decade = '';
    }

    if (!data.loading && data.currentPage != 1) {
      data.loading = true;
      data.currentPage--;
      this.detectChanges();
      data
        .fetch(data.currentPage, this.itemsPerPage, data.sort.field, data.sort.asc, data.filter, data.decade)
        .subscribe(
          response => {
            this.currentPage--
            data.items = response.data;
            this.setPageParam()
            data.lastPage = response.next_page_url;
            data.loading = false;
            window.scrollTo(0, 0);
            this.detectChanges();
          },
          error => {
            this.toastService.error(error.message);
          }
        );
    }
  }

  handleFreeSongsChange(data, gold) {
    data.freeSongsFirst = gold;
    this.resetDataObj(data);
    //this.onScrollEndHandle(data, { field: 'gold', asc: gold }, true);
  }

  resetDataObj(data) {
    data.currentPage = 1;
    data.loading = false;
    data.items = [];
    data.lastPage = 1;
    this.currentPage = 1;
  }

  openLink(url) {
    window.open(window.location.origin + '/#' + url);
  }

  onArtist(artist) {
    // this.router.navigate(['/d/artists', artist.id]);
     this.navigate([`/d/artists/${artist.id}`]);
  }

  onFavArtist(artist) {
    this.artistService.favorite(artist.id).subscribe(result => {
      artist.favorited = true;
      this.toastService.success('Added artist to favorites');
    });
  }

  onUnfavArtist(artist) {
    this.artistService.unfavorite(artist.id).subscribe(result => {
      artist.favorited = false;
      this.toastService.success('Removed artist from favorites');
    });
  }

  shareRecording(recording) {
    this.eventService.onSocialShare({ recording });
  }
}
 