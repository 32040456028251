<div class="ss-round" *ngIf="round">
  <div class="ss-round--profile">
    <img [src]="round.cover">
  </div>
  <div class="ss-round--info">
    <span class="ss-round--info-title ss-truncate">{{round.name}}</span>
    <span class="ss-round--info-status ss-truncate">{{round.thread?.replies_count}} replies</span>
    <div class="ss-round--info-timestamp">
        <app-time-stamp [time]="round.updated_at"
                        [needWatch]="false"
                        [format]="'MMM d, yyyy hh:mma'"></app-time-stamp>
    </div>
  </div>
</div>
