<div class="app-icon">
  <svg [attr.width]="size" [attr.height.px]="size" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-718.000000, -551.000000)"
            [attr.fill]="color"
            fill-rule="nonzero">
            <path d="M728.85,551.7 C728.85,551.63 728.78,551.56 728.78,551.49 L728.64,551 L721.36,551 L721.22,551.49 C721.22,551.56 721.15,551.63 721.15,551.7 L718,551.7 L718,554.29 C718,555.62 718.56,556.88 719.47,557.86 C720.38,558.77 721.57,559.26 722.83,559.26 C723.25,559.61 723.74,559.89 724.3,559.96 L724.3,561.5 C722.97,561.71 721.78,562.62 721.22,563.95 L720.8,565 L729.2,565 L728.78,564.02 C728.22,562.69 727.03,561.78 725.7,561.57 L725.7,560.03 C726.26,559.89 726.75,559.68 727.17,559.33 C728.43,559.33 729.62,558.77 730.53,557.93 C731.44,556.95 732,555.69 732,554.29 L732,551.7 L728.85,551.7 Z M719.4,554.29 L719.4,553.1 L720.87,553.1 C720.8,553.66 720.8,554.15 720.8,554.5 C720.8,555.62 721.08,556.67 721.5,557.58 C721.15,557.44 720.8,557.16 720.52,556.88 C719.75,556.18 719.4,555.27 719.4,554.29 Z M723.18,563.6 C723.67,563.18 724.3,562.9 725,562.9 C725.7,562.9 726.33,563.18 726.82,563.6 L723.18,563.6 Z M725,558.7 C723.46,558.7 722.2,556.81 722.2,554.5 C722.2,553.87 722.27,553.03 722.41,552.4 L727.59,552.4 C727.73,553.03 727.8,553.94 727.8,554.5 C727.8,556.81 726.54,558.7 725,558.7 Z M730.6,554.29 C730.6,555.27 730.25,556.18 729.55,556.88 C729.27,557.16 728.92,557.37 728.57,557.58 C728.99,556.67 729.27,555.69 729.27,554.57 C729.27,554.22 729.27,553.66 729.2,553.17 L730.6,553.17 L730.6,554.29 Z"></path>
        </g>
    </g>
  </svg>
</div>