import {Component, OnInit, Input} from '@angular/core';
import {UtilService} from '@app/core';
import {Round} from '@app/models/round.model';

@Component({
  selector: 'app-ss-round',
  templateUrl: './ss-round.component.html',
  styleUrls: ['./ss-round.component.scss']
})
export class SsRoundComponent implements OnInit {
  @Input() round: Round;

  constructor(private util: UtilService) {
  }

  ngOnInit() {
    this.round.cover = this.util.addWidthIfImgIx(this.round.cover, 100);
  }

}
