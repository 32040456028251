import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Data } from '@app/core';
import {ToastrService} from 'ngx-toastr';
import { ProfileService, UtilService } from '@app/core';

@Component({
  selector: 'app-settings-subscription-history',
  templateUrl: './settings-subscription-history.component.html',
  styleUrls: ['./settings-subscription-history.component.scss']
})
export class SettingsSubscriptionHistoryComponent implements OnInit {
  id = null;
  loading = false;
  data = [];
  subscription: any = null;
  @ViewChild('confirmation', { static: true }) private confirmationModal;
  proceed = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private profileService: ProfileService,
    public util: UtilService,
    private dataService: Data,
    private toastr: ToastrService,
    private router: Router,
    ) {
    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
    });
  }

  ngOnInit() {
    if (!this.dataService.storage || !this.dataService.storage.subscription) {
      this.router.navigate(['/d/settings/subscription/active']);
      return;
    }
    this.subscription = this.dataService.storage.subscription;
    console.log(this.subscription)
    this.loadData();
  }

  async loadData() {
    try {
      const result = await this.profileService.getSubscriptionHistory(this.subscription.subscription.id).toPromise();
      this.data = result.data;
    } catch (err) {
      console.log(err);
    }
  }

  handleCancelCheck(){
    this.confirmationModal.open('Confirm Cancellation', 'Are you sure you want to cancel this subscription?').then(result => {
      if (result === 'ok') { 
        this.proceed = true;
        this.handleCancel();
      }});
  }

  async handleCancel() {
      if(this.proceed){
        this.loading = true;
        try {
          await this.profileService.cancelSubscription(this.subscription.subscription.id).toPromise();
          this.toastr.success('Subscription Cancelled');
          this.router.navigate(['/d/settings/subscription/active']);
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      }
  }
}