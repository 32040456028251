import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {
  Data,
  EventService,
  ShopService,
  ProfileService,
} from '@app/core/services';
import { EmailNotificationsService } from '@app/core/services/email-notifications.service';
import {formatDate} from '@angular/common';
@Component({
  selector: 'app-send-gift-modal',
  templateUrl: './send-gift-modal.component.html',
  styleUrls: ['./send-gift-modal.component.scss'],
})
export class SendGiftModalComponent implements OnInit {
  @ViewChild('content', { static: true }) private contentElem: ElementRef;
  modalRef = null;
  items = [];
  loggedInUser: any;
  user: any = null;
  giftItem: any = null;
  giftNumber = 1;
  anonymous = false;
  message = '';
  giftSent = false;
  isSending = false;
  gmgDuration = '';
  membershipEndDate = '';
  add_s = '';
  productName_and_Amount = '';

  constructor(
    private modalService: NgbModal,
    private shopService: ShopService,
    private toastr: ToastrService,
    private router: Router,
    private profileService: ProfileService,
    private dataService: Data,
    private events: EventService,
    private emailNotificationsService: EmailNotificationsService
  ) {}

  ngOnInit() {
    this.loggedInUser = this.profileService.user;
  }

  get quantities() {
    if (!this.giftItem) {
      return [];
    }
    if (this.giftItem.product.target == 'colors') {
      return [1];
    }
    const list = [];
    for (let i = 0; i < this.giftItem.quantity; i++) {
      list.push(i + 1);
    }
    return list;
  }

  get trinkets() {
     return this.items.filter(
      item =>  item.product.with_recurring == true && item.product.target != 'colors'
      );
  }

  get coloredUsernames() {
    if (this.user.colors) {
      return [];
    }
    return this.items.filter(
      item => item.product.target == 'colors'
        
    );
  }

 get gmgs() {
    if (this.user.has_recurring_subscription == true) {
      return [];
    }
    return this.items.filter(item => item.product.with_recurring  == false );
  }

  get showCommentBox() {
    return (
      !this.anonymous
    );
  }

  async loadItems() {
    this.items = [];
    const result: any = await this.shopService.getDistributables().toPromise();
    this.items = result.data;
  }

  open(user) {
    this.user = user;
    this.giftItem = null;
    this.giftNumber = 1;
    this.anonymous = false;
    this.message = '';
    this.giftSent = false;
    this.openModal();
    this.loadItems();
  }

  close() {
    this.modalRef.close();
  }

  openModal() {
    this.modalRef = this.modalService.open(this.contentElem, {
      windowClass: 'ssc-modal send-gift-modal',
      backdrop: 'static',
    });

    this.modalRef.result.then(result => {}, async reason => {});
  }

  changeGiftNumber(value) {
    this.giftNumber = value;
  }

  setMessage(message) {
    this.message = message;
  }

  handleGoldMembership() {
    this.router.navigate(['/d/payments/membership']);
    this.dataService.storage = { giftUser: this.user };
    this.close();
  }

  handleSnapCredits() {
    this.close();
    this.events.onSendSnapCredit(this.user);
  }

  handleSnapicon() {
    this.router.navigate(['/d/payments/snapicons']);
    this.dataService.storage = { giftUser: this.user };
    this.close();
  }

  handleDistribute(gift) {
    this.giftItem = gift;
  }

  handleCurrentPromotions() {
    this.router.navigate(['/d/payments/promos']);
    this.close();
  }

  changeAnonymous(value) {
    this.anonymous = value;
    this.message = '';
  }

  async handleSubmit() {
    console.log(this.giftNumber, this.message, this.anonymous);
    console.log("this.user", this.user)
    console.log("this.loggedInUser",this.loggedInUser)


    const data: any = {
      product_id: this.giftItem.product_id,
      quantity: this.giftNumber,
      recipients: [this.user],
      anonymous: this.anonymous,
    };
    if (this.message) {
      data.message = this.message;
    }
    try {
      this.isSending = true;
      await this.shopService.distribute(data).toPromise();
      const user = await this.profileService.getUserProfile().toPromise();
      this.events.onUpdateUserProfile(user);
      this.giftSent = true;
     
      if(this.giftItem.product.attrs.target == 'gmg'){   
        const numbersName = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten']
        let numOf: number = this.giftItem.product.quantity.replace(/[^0-9]/g, '');
        let letterOf = this.giftItem.product.quantity.replace(/[^a-zA-Z]/g, '');
        switch (letterOf) {
          case 'y':
            this.gmgDuration = numOf +' Year';
            if(numOf > 1){this.gmgDuration = this.gmgDuration};
            break;
          case 'm':
            this.gmgDuration = numOf + ' Month';
            if(numOf > 1){this.gmgDuration = this.gmgDuration};     
            break;
          case 'w':
            this.gmgDuration = numOf + ' Week';
            if(numOf > 1){this.gmgDuration = this.gmgDuration};  
            break;
          case 'd':
            this.gmgDuration = numOf + ' Day';
            if(numOf > 1){this.gmgDuration = this.gmgDuration};      
            break;
          default:
              break;
        }
      
        if( (Number(numOf) + Number(this.giftNumber)) > 2){this.add_s = 's';} else {this.add_s = '';}
        this.productName_and_Amount = numbersName[this.giftNumber]+', '+ this.gmgDuration + ' Gold membership' + this.add_s;

        this.profileService.getUser(this.user.id).subscribe(res=>{

            let tempUserRecipient = res;

            this.membershipEndDate = tempUserRecipient.membership_ends_at;  
            if(this.membershipEndDate && this.membershipEndDate.length){
              this.membershipEndDate = formatDate(this.membershipEndDate,'yyyy-MM-dd','en_US');
            } else {
              this.membershipEndDate = '';
            }

            if(this.user.newsletter && this.loggedInUser.id != this.user.id){
              this.emailNotificationsService.emailDistributeGMG(
                this.user.email,
                this.user.screen_name,
                this.loggedInUser.screen_name,
                this.loggedInUser.id,
                this.giftItem.product.name,
                this.giftItem.product.photo,  
                this.giftNumber,
                this.productName_and_Amount,
                this.membershipEndDate,
                this.anonymous
              )
            }
        })
      } else {
        if(this.user.newsletter && this.loggedInUser.id != this.user.id){
          this.emailNotificationsService.emailDistributeTrinkets(
                  this.user.email,
                  this.user.screen_name,
                  this.loggedInUser.screen_name,
                  this.loggedInUser.id,
                  this.giftItem.product.name,
                  this.giftItem.product.assignable_icon,
                  this.giftItem.product.photo,  
                  this.giftNumber,
                  this.anonymous
                )
              }
        }
    } catch (err) {
      console.log(err);
    } finally {
      this.isSending = false;

      console.log("give item: ", data)
    }
  }
}
