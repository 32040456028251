export default class LyricsPlayer {
  constructor(lyrics, wordTimings, duetData) {
    this.duetData = duetData;
    this.lyricLines = document.getElementById("lines-container").children;
    this.lyrics = lyrics;
    this.wordTimings = wordTimings;
    this.lyrics.forEach((line) => {
      let s = line[0];
      let wordCount;
      s = s.replace(/(^\s*)|(\s*$)/gi, "");
      s = s.replace(/[ ]{2,}/gi, " ");
      s = s.replace(/\n /, "\n");
      wordCount = s.split(" ").filter(function(str) {
        return str != "";
      }).length;
      if (wordCount != line[3].length) {
        this.wordTimings = false;
      }
    });
    this.lyrics.unshift([" ", 0, lyrics[0][1], [[0, lyrics[0][1]]], "blank"]);
    this.currentLine = 0;
    this.currentWord = 0;
    this.seeking = false;
  }
  init() {
    this.changeLyricPosition(this.currentLine);
  }
  updateLyrics(ms) {
    if (typeof this.lyrics[this.currentLine + 1] != "undefined") {
      if (ms >= this.lyrics[this.currentLine + 1][1] && !this.seeking) {
        if (this.currentLine != this.lyrics.length - 1) {
          this.currentLine += 1;
          if (this.wordTimings) {
            this.currentWord = 0;
          }
        }
        this.changeLyricPosition(this.currentLine);
      }
      if (this.wordTimings) {
        if (this.currentWord < this.lyrics[this.currentLine][3].length) {
          if (ms >= this.lyrics[this.currentLine][3][this.currentWord][0] && !this.seeking) {
            this.changeWordPosition();
          }
        }
      }
    } else {
      if (this.wordTimings) {
        if (this.currentWord < this.lyrics[this.currentLine][3].length) {
          if (ms >= this.lyrics[this.currentLine][3][this.currentWord][0] && !this.seeking) {
            this.changeWordPosition();
          }
        }
      }
    }
  }
  splitWords() {
    let lineText = this.lyrics[this.currentLine][0];
    let splitLine = "";
    let div = this.lyricLines.namedItem("swipe");
    lineText.split(" ").forEach((word, i) => {
      if (word != "" && word != " ") {
        let delay = this.lyrics[this.currentLine][3][i][1] - this.lyrics[this.currentLine][3][i][0];
        let transition = delay / 1e3;
        splitLine += `<span id="word${i}" style='transition: background-position ${transition}s'>${word} </span>`;
      } else {
        splitLine += `<span id="word${i}">${word} </span>`;
      }
    });
    div.innerHTML = splitLine;
  }
  changeWordPosition() {
    let currentWordSpan = document.getElementById(`word${this.currentWord}`);
    if (this.currentWord == 0) {
      setTimeout(() => {
        currentWordSpan.style.backgroundPosition = "0%";
        this.currentWord += 1;
      }, 10);
    } else {
      currentWordSpan.style.backgroundPosition = "0%";
      this.currentWord += 1;
    }
  }
  changeLyricPosition(line) {
    if (this.lyricLines.namedItem("swipe")) {
      let div = this.lyricLines.namedItem("swipe");
      div.id = "the-grave";
      div.innerHTML = div.innerText;
      setTimeout(() => {
        div.remove();
      }, 250);
    }
    if (this.lyricLines.namedItem("next-up")) {
      let div = this.lyricLines.namedItem("next-up");
      div.id = "swipe";
      if (this.duetData.duet) {
        if (typeof this.lyrics[line] != "undefined") {
          if (typeof this.lyrics[line][4] != "undefined" && typeof div != "undefined") {
            div.className = "lyrics";
            let part = this.lyrics[line][4].replace(/\s/g, "").toUpperCase();
            div.classList.add(part);
          }
        }
      }
      if (typeof this.lyrics[line] != "undefined")
        div.innerText = this.lyrics[line][0];
      if (this.wordTimings) {
        this.splitWords();
      } else {
        if (typeof div != "undefined") {
          div.style.color = "#58c7c9";
        }
      }
    }
    if (this.lyricLines.namedItem("initial")) {
      let div = this.lyricLines.namedItem("initial");
      div.id = "next-up";
      if (this.duetData.duet) {
        if (typeof this.lyrics[line + 1] != "undefined") {
          if (typeof this.lyrics[line + 1][4] != "undefined" && typeof div != "undefined") {
            div.className = "lyrics";
            let part = this.lyrics[line + 1][4].replace(/\s/g, "").toUpperCase();
            div.classList.add(part);
          }
        }
      }
      if (typeof this.lyrics[line + 1] != "undefined")
        div.innerText = this.lyrics[line + 1][0];
    }
    if (this.lyricLines.namedItem("the-womb")) {
      let div = this.lyricLines.namedItem("the-womb");
      div.id = "initial";
      if (typeof this.lyrics[line + 2] != "undefined")
        div.innerText = this.lyrics[line + 2][0];
      if (this.duetData.duet) {
        if (typeof this.lyrics[line + 2] != "undefined") {
          if (typeof this.lyrics[line][4] != "undefined" && typeof div != "undefined") {
            div.className = "lyrics";
            div.classList.add("lyrics");
            let part = this.lyrics[line + 2][4].replace(/\s/g, "").toUpperCase();
            div.classList.add(part);
          }
        }
      }
      let newLine = document.createElement("div");
      newLine.id = "the-womb";
      if (this.duetData.duet) {
        if (typeof this.lyrics[line + 3] != "undefined") {
          if (typeof this.lyrics[line + 3][4] != "undefined") {
            let part = this.lyrics[line + 3][4].replace(/\s/g, "").toUpperCase();
            newLine.classList.add(part);
          }
        }
      }
      newLine.classList.add("lyrics");
      if (typeof this.lyrics[line + 3] != "undefined")
        newLine.innerText = this.lyrics[line + 3][0];
      document.getElementById("lines-container").appendChild(newLine);
    }
  }
  seek(ms) {
    this.seeking = true;
    this.currentLine = this.lyrics.findIndex((line) => ms <= line[1]);
    if (this.wordTimings) {
      this.currentWord = 0;
    }
    if (this.currentLine == -1) {
      this.currentLine = this.lyrics.length - 1;
    }
    this.changeLyricPosition(this.currentLine);
    this.seeking = false;
  }
}
