<section class="profile-header" *ngIf="user" [ngStyle]="{'background-image': 'url('+user?.cover+')'}">
  <div class="profile-header-backdrop">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-10">
          <div class="profile__portrait" [ngStyle]="{'background-image': 'url(' + user.photo + ')'}">
            <app-user-snapicon-carousel [icons]="getSnapicons()" *ngIf="getSnapicons().length"></app-user-snapicon-carousel>
            <app-user-online-icon [online]="user.online" size="big" ></app-user-online-icon> 
          </div>
          <div class="profile__name">
            <div class="backgroundWhite">
              <app-ss-user-name [user]="user" *ngIf="user" [showStatus]="false" profile="true" ></app-ss-user-name>
            </div>
            <div class="profile-header-status">
              <div class="user-status" *ngIf="me">
                <span>{{status || 'Set status message'}} </span>
                <app-icon-pencil  color="#D0D0D0" size="18" (click)="onEditStatus()"></app-icon-pencil>
              </div>
              <div class="user-status" *ngIf="!me"><span>{{status}}</span></div>
              <!-- <div class="ss-snap-credit-icon" (click)="openSnapModal()">
                <img src="/assets/pictures/ic_gold_snap.png" /> 
              </div> -->

              <button *ngIf="!me" class="SnapATip" (click)="openSnapModal()">  <img class="snapIcon" width="16px"src="/assets/pictures/ic_gold_snap.png" />SNAP A TIP!</button>


            </div>
          </div>
        </div>
        <div class="col-12 col-sm-2">
          <div class="action-buttons" *ngIf="!me">
            <app-icon-button class="action-button" (click)="toggleFavorite()" color="transparent" border="rgb(151, 151, 151)" size="60px">
              <app-icon-star [color]="user?.favorited ? 'yellow' : 'white'" size="28px">
              </app-icon-star>
            </app-icon-button>
            <button class="action-button action-message" (click)="gotoPM()"></button>
            <app-icon-button color="transparent" border="rgb(151, 151, 151)" size="60px">
              <app-more-dot-menu color="white" class="user-more-menu">
                <div class="dropdown-menu-content">
                  <button class="dropdown-item" (click)="notify()"><span *ngIf="user?.notified">Unsubscribe From User</span><span *ngIf="!user?.notified">Notify Me Next Time Member Sings</span></button>
                </div>
                <div class="dropdown-menu-content">
                  <button class="dropdown-item" (click)="block()">Block</button>
                </div>
                <div class="dropdown-menu-content">
                  <button class="dropdown-item" (click)="report()">Report</button>
                </div>
                <div class="dropdown-menu-content">
                  <button class="dropdown-item" (click)="sendGift()">Send member a gift</button>
                </div>
              </app-more-dot-menu>
            </app-icon-button>
          </div>
          <div class="action-buttons" *ngIf="me">
            <button class="action-button action-edit" (click)="openEditModal(content)"></button>
          </div>
        </div>
        <app-update-user-status #updateStatus (updated)="updatedStatusMessage($event)"></app-update-user-status>
      </div>
    </div>
  </div>
</section>

<section class="profile-menu">
  <div class="container">
    <div class="row">
      <div class="col-2"></div>
      <div class="col-10 px-0">
        <ul class="profile__tab-nav">
          <li [routerLinkActive]="'active'">
            <a [routerLink]="['/d/profile/', userId ,'info']">Profile</a>
          </li>
          <li [routerLinkActive]="'active'"> <!-- use user.id instead of having 'me' -->
            <a [routerLink]="['/d/profile/', userId ,'recordings']">Recordings</a>
          </li>
          <li [routerLinkActive]="'active'"> <!-- use user.id instead of having 'me' -->
            <a [routerLink]="['/d/profile/', userId ,'open-duets']">Open Jams & Duets</a>
          </li>
          <li [routerLinkActive]="'active'" *ngIf="showFeaturedOn">
            <a [routerLink]="['/d/profile/', userId ,'featured-on']">Featured On</a>
          </li>
          <li [routerLinkActive]="'active'" *ngIf="!me">
            <a [routerLink]="['/d/profile/', userId ,'inner-circle']">Inner Circles</a>
          </li>
          <li [routerLinkActive]="'active'">
            <a [routerLink]="['/d/profile/', userId ,'badges']">Badges</a>
          </li>
          <!--<li [routerLinkActive]="'active'">
            <a [routerLink]="['/d/profile/', userId ,'gallery']">Gallery</a>
          </li>-->
          <li [routerLinkActive]="'active'">
            <a [routerLink]="['/d/profile/', userId ,'playlists']">Playlists</a>
          </li>
          <li [routerLinkActive]="'active'">
            <a [routerLink]="['/d/profile/', userId ,'fans']">Fans</a>
          </li>
          <li [routerLinkActive]="'active'" *ngIf="publicFavorites">
            <a [routerLink]="['/d/profile/', userId ,'favorites']">Favorites</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<section class="profile-content">
  <!-- ads side -->
  <div *ngIf="!loggedInIsGold" class="placeAdLeft">
    <ng-adsense
    [adClient]="'ca-pub-4776037543768273'"
    [adSlot]="9024407308"
    ></ng-adsense>
  </div>
  <div *ngIf="!loggedInIsGold" class="placeAdRight">
    <ng-adsense
    [adClient]="'ca-pub-4776037543768273'"
    [adSlot]="9024407308"
    ></ng-adsense>
  </div>
  <div class="container">
    <router-outlet></router-outlet>
  </div>
</section>

  <div *ngIf="!loggedInIsGold" style="text-align:center;">
    <ng-adsense 
    [adClient]="'ca-pub-4776037543768273'" 
    [adSlot]="4474696313" 
    [display]="'block'"
    [adFormat]="'autorelaxed'"
    ></ng-adsense></div>
 <!-- ad profile
 <ng-adsense
 [adClient]="'ca-pub-4776037543768273'"
 [adSlot]="9989671476"
 [fullWidthResponsive]="true"
 ></ng-adsense> -->


<!-- <ng-adsense
[adClient]="'ca-pub-4776037543768273'"
[adSlot]="9989671476"
[display]="'inline-block'"
[width]="320" [height]="108"
></ng-adsense> -->


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="ss-bold-header" id="modal-basic-title">EDIT PROFILE</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalEditRef.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editGroup">
      <div class="row">
        <div class="col col-sm-6">
          <div class="form-group">
            <label for="avatar" class="d-block">Avatar</label>
            <app-avatar-thumb id="avatar" [user]="user" [big]="true" [showOnline]="false">
            </app-avatar-thumb>
            <button class="btn ss-btn-ghost ss-btn-big"
                style="color: rgb(88, 199, 201);font-weight: 400;"
                (click)="openAvatarFilePicker()">UPLOAD</button>
            <button class="btn ss-btn-ghost ss-btn-big"
            style="color: red; font-weight: 400; margin-left: 14px"
            (click)="removeProfilePic()">REMOVE</button>
          </div>
        </div>
        <div class="col col-sm-6">
          <div class="form-group">
            
            <app-dropzone
              [cover]="user?.cover"
              (onFileDropped)="dropped($event)"
              (onFilePicked)="openFilePicker($event)">
            </app-dropzone>
            
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-sm-6">
          <div class="form-group">
            <div style="color: red;"><label for="name">First & Last Name</label>*</div>
            <input id="name" formControlName="name" class="form-control ss-input">
          </div>
        </div>
        <!-- <div class="col col-sm-6">
          <div class="form-group">
            <label for="privacy">Name privacy</label>
            <select class="form-control ss-input custom-select" id="privacy" name="privacy" formControlName="privacy">
              <option [ngValue]="null">Select one</option>
              <option *ngFor="let c of privacies" [ngValue]="c">{{ c.name }}</option>
            </select>
          </div>
        </div> -->
        <div class="col col-sm-6">
          <div class="form-group">
            <label for="gender">Gender</label>
            <select class="form-control ss-input custom-select" id="gender" name="gender" formControlName="gender">
              <option [ngValue]="null">Select one</option>
              <option *ngFor="let c of genders" [ngValue]="c">{{ c.name }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-sm-6">
          <div class="form-group">
            <div style="color: red;"><label for="birth">Birth Date</label>*</div>
            <!-- <input id="birth" class="form-control ss-input"
                 placeholder="mm/dd/YYYY"
                 name="birth"
                 formControlName="dob"
                 (click)="d.toggle()" type="button"
                 ngbDatepicker #d="ngbDatepicker"
                 [minDate]="minDate"> -->
                 <input
                 class="form-control"
                 name="birth"
                 [minDate]="{year: 1900, month: 1, day: 1}"
                 formControlName="dob"
                 placeholder="yyyy-mm-dd"
                 (click)="d.toggle()" 
                 ngbDatepicker
                 #d="ngbDatepicker">
          </div>

        </div>
        <!-- <div class="col col-sm-6">
          <div class="form-group">
            <label for="birthPrivacy">Birth Date Privacy</label>
            <select class="form-control ss-input custom-select" id="birthPrivacy" name="birthPrivacy" formControlName="birthPrivacy">
              <option [ngValue]="null">Select one</option>
              <option *ngFor="let c of privacies" [ngValue]="c">{{ c.name }}</option>
            </select>
          </div>
        </div> -->
        <div class="col col-sm-6">
          <div class="form-group">
            <div style="color: red;"><label for="country">Country</label>*</div>
            <select class="form-control ss-input custom-select" id="country" name="country" formControlName="country">
              <option [ngValue]="null">Select a country</option>
              <option *ngFor="let c of countries" [ngValue]="c">{{ c.name }}</option>
            </select>
          </div>
        </div>
      </div>
      <!-- <div class="row">  MOVE ABOVE
        <div class="col col-sm-6">
          <div class="form-group">
            <label for="gender">Gender</label>
            <select class="form-control ss-input custom-select" id="gender" name="gender" formControlName="gender">
              <option [ngValue]="null">Select one</option>
              <option *ngFor="let c of genders" [ngValue]="c">{{ c.name }}</option>
            </select>
          </div>
        </div>
        <div class="col col-sm-6">
          <div class="form-group">
            <label for="country">Country</label>
            <select class="form-control ss-input custom-select" id="country" name="country" formControlName="country">
              <option [ngValue]="null">Select a country</option>
              <option *ngFor="let c of countries" [ngValue]="c">{{ c.name }}</option>
            </select>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col col-sm-12">
          <div class="form-group">
            <label for="bio">Bio</label>
              <textarea rows="10" id="bio" formControlName="bio" class="form-control ss-input"></textarea>
          </div>
        </div>
      </div>
      <div class="required">*required</div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn ss-btn-ghost ss-btn-big" (click)="modalEditRef.close('Save click')">Cancel</button>
    <button type="button" class="btn ss-btn-primary ss-btn-big" (click)="submitEdit()">Save</button>
  </div>
</ng-template>
<app-block-modal #blockModal></app-block-modal>
<app-snap-credit-modal #snap></app-snap-credit-modal>
